import { MemberType, OrganizationMemberOverview } from "src/types/organization/OrganizationMember"
import { Result } from "src/utils/Result"
import { listNotJoinMembers, listTeamMember } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type GetListNotJoinMembersRequestParams = {
  accessToken: string
  accountId: string
  organizationId: string
  page: number
  pageSize: number
}

type GetListNotJoinMembersSuccess = OrganizationMemberOverview[]

type GetListNotJoinMembersFailure = {
  message: string
}

export const getListNotJoinMembers = async (
  params: GetListNotJoinMembersRequestParams
): Promise<Result<GetListNotJoinMembersSuccess, GetListNotJoinMembersFailure>> => {
  return execApi(
    () => listNotJoinMembers(params.accessToken, {
      input: {
        teamId: params.organizationId,
        notJoin: true,
        page: params.page,
        pageSize: params.pageSize
      },
      appInfo
    }),
    res => {
      return (res.listNotJoinMembers?.notJoinMembers ?? []).map(item => ({
        id: item.email ?? item.id,
        nickname: item.nickname,
        memberNumber: 0,
        memberType: memberTypeMapping[item.role as keyof typeof memberTypeMapping],
        isLeader: false,
        isInGroup: false,
        isAdministrator: item.role === 'manager',
        groupIds: [],
        joinFlg: item.joinFlg,
        imageUrl: item.image.url,
        email: item.email
      })) || []
    }
  )
}

const memberTypeMapping ={
  "general": MemberType.General,
  "mailSender": MemberType.GeneralWithContactAuthorization,
  "manager": MemberType.Representative,
}
