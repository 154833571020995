import { AnswerType } from "src/constants/AnswerType"
import { ContactType } from "src/constants/ContactType"
import { TemplateModel } from "src/types/contact/TemplateModel"
import { Result } from "src/utils/Result"
import { getTemplate as getTemplateApi } from "src/aws/customAPI"
import { AnswerSelectType, MailType } from "src/aws/API"
import { EventDateCandidate, getEventDateCandidate } from "src/types/contact/EventDateCandidate"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

export type GetTemplateRequestParams = {
  accessToken: string
  templateId: string
}

export type GetTemplateSuccess = TemplateModel

export type GetTemplateFailure = {
  message: string
}

export const getTemplate = async (params: GetTemplateRequestParams): Promise<Result<GetTemplateSuccess, GetTemplateFailure>> => {
  const { accessToken, templateId } = params
  return execApi(
    () => getTemplateApi(accessToken, {
      id: templateId,
      appInfo
    }),
    res => {
      const t = res.getTemplate
      return {
        id: t.id,
        contactType: mailTypeMapping[t.mailType],
        isReceiversAllMembers: t.sendTo.allMembersFlg,
        isReceiversAllLeaders: t.sendTo.managersFlg,
        memberIds: t.sendTo.memberIds,
        groupIds: t.sendTo.groupIds,
        attachmentFileNames: [],
        title: t.title,
        body: t.body,
        isAddToOtherAdminsHistory: t.shareManagerFlg,
        isPublishAnswersToMembers: t.showAnswerFlg,
        eventDateCandidates: t.candidateDatetimes?.map(item => convertEventCandidate(item)),
        questions: t.questionnaires?.map((q, i) => ({
          title: q.question,
          answerType: answerTypeMapping[q.answerSelectType],
          choices: q.choices,
        })),
      }
    },
  )
}


const mailTypeMapping: {[key in MailType]: ContactType} = {
  [MailType.normal]: ContactType.Normal,
  [MailType.attendance]: ContactType.Attendance,
  [MailType.questionnaire]: ContactType.Survey,
  [MailType.schedule]: ContactType.ScheduleAdjustment,
}

const convertEventCandidate = (candidate: {
  startDate: string
  startTime?: string | null
  endDate?: string | null
  endTime?: string | null
}): EventDateCandidate => {
  const result = getEventDateCandidate({
    startDate: new Date(candidate.startDate),
    startTime: candidate.startTime || undefined,
    endDate: candidate.endDate ? new Date(candidate.endDate) : undefined,
    endTime: candidate.endTime || undefined,
  })
  return result || {
    type: "AllDay",
    startDate: new Date(candidate.startDate)
  }
}

const answerTypeMapping: {[key in AnswerSelectType]: AnswerType} = {
  [AnswerSelectType.single]: "Single",
  [AnswerSelectType.multiple]: "Multiple",
}
