/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type Account = {
  __typename: "Account"
  id: string
  email: string
  lastName: string
  firstName: string
  lastNameKana: string
  firstNameKana: string
  phoneNumber?: string | null
  gender?: Gender | null
  birthday?: string | null
  job?: JobMaster | null
  school?: SchoolMaster | null
  schoolDepartment?: SchoolDepartmentMaster | null
  schoolGraduationYear?: number | null
  prefecture?: PrefectureMaster | null
  city?: CityMaster | null
  questionAnswers?: ModelAccountQuestionAnswerConnection | null
  termsAgreedAt?: string | null
  agreedTermsVersion?: string | null
  matchingTermsAgreedAt?: string | null
  agreedMatchingTermsVersion?: string | null
  signupStatus: SignupStatus
  accountDevices?: ModelAccountDeviceConnection | null
  teamMembers?: ModelTeamMemberConnection | null
  preTeamMembers?: ModelPreTeamMemberConnection | null
  teamNotices?: ModelTeamNoticeConnection | null
  haveOldRa9TeamNotJoin: boolean
  migrateAccountId?: number | null
  createdAt: string
  updatedAt: string
  accountJobId?: string | null
  accountSchoolId?: string | null
  accountSchoolDepartmentId?: string | null
  accountPrefectureId?: string | null
  accountCityId?: string | null
}

export enum Gender {
  male = "male",
  female = "female",
  unspecified = "unspecified",
}

export type JobMaster = {
  __typename: "JobMaster"
  id: string
  label: string
  type: JobType
  displayNumber: number
  displayFlg: number
  createdAt: string
  updatedAt: string
}

export enum JobType {
  none = "none",
  university = "university",
}

export type SchoolMaster = {
  __typename: "SchoolMaster"
  id: string
  label: string
  labelKana: string
  type: string
  code?: string | null
  displayNumber: number
  displayFlg: number
  departments?: ModelSchoolDepartmentMasterConnection | null
  createdAt: string
  updatedAt: string
}

export type ModelSchoolDepartmentMasterConnection = {
  __typename: "ModelSchoolDepartmentMasterConnection"
  items: Array<SchoolDepartmentMaster | null>
  nextToken?: string | null
}

export type SchoolDepartmentMaster = {
  __typename: "SchoolDepartmentMaster"
  id: string
  label: string
  displayNumber: number
  displayFlg: number
  createdAt: string
  updatedAt: string
  schoolMasterDepartmentsId?: string | null
}

export type PrefectureMaster = {
  __typename: "PrefectureMaster"
  id: string
  label: string
  displayNumber: number
  displayFlg: number
  cities?: ModelCityMasterConnection | null
  createdAt: string
  updatedAt: string
}

export type ModelCityMasterConnection = {
  __typename: "ModelCityMasterConnection"
  items: Array<CityMaster | null>
  nextToken?: string | null
}

export type CityMaster = {
  __typename: "CityMaster"
  id: string
  label: string
  displayNumber: number
  displayFlg: number
  createdAt: string
  updatedAt: string
  prefectureMasterCitiesId?: string | null
}

export type ModelAccountQuestionAnswerConnection = {
  __typename: "ModelAccountQuestionAnswerConnection"
  items: Array<AccountQuestionAnswer | null>
  nextToken?: string | null
}

export type AccountQuestionAnswer = {
  __typename: "AccountQuestionAnswer"
  id: string
  question?: AccountQuestionMaster | null
  answer?: AccountQuestionChoiceMaster | null
  createdAt: string
  updatedAt: string
  accountQuestionAnswersId?: string | null
  accountQuestionAnswerQuestionId?: string | null
  accountQuestionAnswerAnswerId?: string | null
}

export type AccountQuestionMaster = {
  __typename: "AccountQuestionMaster"
  id: string
  label: string
  displayNumber: number
  displayFlg: number
  choices?: ModelAccountQuestionChoiceMasterConnection | null
  createdAt: string
  updatedAt: string
}

export type ModelAccountQuestionChoiceMasterConnection = {
  __typename: "ModelAccountQuestionChoiceMasterConnection"
  items: Array<AccountQuestionChoiceMaster | null>
  nextToken?: string | null
}

export type AccountQuestionChoiceMaster = {
  __typename: "AccountQuestionChoiceMaster"
  id: string
  label: string
  displayNumber: number
  displayFlg: number
  isDefault: boolean
  question?: AccountQuestionMaster | null
  createdAt: string
  updatedAt: string
  accountQuestionMasterChoicesId?: string | null
}

export enum SignupStatus {
  inProgress = "inProgress",
  completed = "completed",
}

export type ModelAccountDeviceConnection = {
  __typename: "ModelAccountDeviceConnection"
  items: Array<AccountDevice | null>
  nextToken?: string | null
}

export type AccountDevice = {
  __typename: "AccountDevice"
  id: string
  accountID?: string | null
  deviceID?: string | null
  account?: Account | null
  device?: Device | null
  createdAt: string
  updatedAt: string
}

export type Device = {
  __typename: "Device"
  id: string
  kind: DeviceKind
  clientUUID: string
  endpointArn: string
  accountDevices?: ModelAccountDeviceConnection | null
  createdAt: string
  updatedAt: string
}

export enum DeviceKind {
  ios = "ios",
  android = "android",
  web = "web",
}

export type ModelTeamMemberConnection = {
  __typename: "ModelTeamMemberConnection"
  items: Array<TeamMember | null>
  nextToken?: string | null
}

export type TeamMember = {
  __typename: "TeamMember"
  id: string
  memberSeq: number
  nickname: string
  nicknameKana: string
  role: string
  showEmailFlg?: boolean | null
  phoneNumber?: string | null
  showPhoneNumberFlg?: boolean | null
  belongs?: string | null
  showBelongsFlg?: boolean | null
  grade?: number | null
  account?: Account | null
  team?: Team | null
  teamIdGsi: string
  teamTeamMembersId?: string | null
  notificationSetting?: NotificationSetting | null
  teamNotice?: ModelTeamNoticeConnection | null
  groups?: ModelGroupMemberConnection | null
  teamMemberMails?: ModelTeamMemberMailConnection | null
  reservedMails?: ModelReservedMailConnection | null
  drafts?: ModelDraftConnection | null
  templates?: ModelTemplateConnection | null
  migratedMails?: ModelMigratedMailConnection | null
  mailMigratedFlg?: boolean | null
  favoriteMatchingOffer?: ModelFavoriteMatchingOfferConnection | null
  deleteFlg?: boolean | null
  accountTeamMembersId: string
  migrateTeamMemberId?: number | null
  orderHistories?: ModelOrderHistoryConnection | null
  paidVersionOrders?: ModelPaidVersionOrderConnection | null
  transferPaidVersionOrders?: ModelPaidVersionOrderConnection | null
  createdAt: string
  updatedAt: string
  teamMemberNotificationSettingId?: string | null
}

export type Team = {
  __typename: "Team"
  id: string
  name: string
  category?: TeamCategoryMaster | null
  activity?: TeamActivityMaster | null
  prefecture?: PrefectureMaster | null
  city?: CityMaster | null
  description: string
  requirementForMemberProfile?: string | null
  customQuestions?: Array<TeamCustomQuestion> | null
  showMemberListFlg: boolean
  invitationCode?: string | null
  teamMembers?: ModelTeamMemberConnection | null
  preTeamMembers?: ModelPreTeamMemberConnection | null
  invitationEmailStatuses?: ModelInvitationEmailStatusConnection | null
  groups?: ModelGroupConnection | null
  matchingOffers?: ModelMatchingOfferConnection | null
  matchingOfferDrafts?: ModelMatchingOfferDraftConnection | null
  matchingApplications?: ModelMatchingApplicationConnection | null
  blockingMatchingTeams?: ModelBlockingMatchingTeamConnection | null
  paidVersionOrders?: ModelPaidVersionOrderConnection | null
  blockingMatchingTeamsByBlockingTeamId?: ModelBlockingMatchingTeamConnection | null
  migrationId?: number | null
  paidFunctionEnabled?: boolean | null
  showAdsFlg?: boolean | null
  createdAt: string
  updatedAt: string
  teamCategoryId?: string | null
  teamActivityId?: string | null
  teamPrefectureId?: string | null
  teamCityId?: string | null
}

export type TeamCategoryMaster = {
  __typename: "TeamCategoryMaster"
  id: string
  label: string
  displayNumber: number
  displayFlg: number
  activities?: ModelTeamCategoryActivityConnection | null
  createdAt: string
  updatedAt: string
}

export type ModelTeamCategoryActivityConnection = {
  __typename: "ModelTeamCategoryActivityConnection"
  items: Array<TeamCategoryActivity | null>
  nextToken?: string | null
}

export type TeamCategoryActivity = {
  __typename: "TeamCategoryActivity"
  id: string
  teamCategoryMasterID: string
  teamActivityMasterID: string
  teamCategoryMaster: TeamCategoryMaster
  teamActivityMaster: TeamActivityMaster
  createdAt: string
  updatedAt: string
}

export type TeamActivityMaster = {
  __typename: "TeamActivityMaster"
  id: string
  label: string
  displayNumber: number
  displayFlg: number
  categories?: ModelTeamCategoryActivityConnection | null
  createdAt: string
  updatedAt: string
}

export enum TeamCustomQuestion {
  phoneNumber = "phoneNumber",
  belongs = "belongs",
  grade = "grade",
}

export type ModelPreTeamMemberConnection = {
  __typename: "ModelPreTeamMemberConnection"
  items: Array<PreTeamMember | null>
  nextToken?: string | null
}

export type PreTeamMember = {
  __typename: "PreTeamMember"
  id: string
  nickname: string
  nicknameKana: string
  showEmailFlg?: boolean | null
  phoneNumber?: string | null
  showPhoneNumberFlg?: boolean | null
  belongs?: string | null
  showBelongsFlg?: boolean | null
  grade?: number | null
  account?: Account | null
  team?: Team | null
  createdAt: string
  updatedAt: string
  accountPreTeamMembersId?: string | null
  teamPreTeamMembersId?: string | null
}

export type ModelInvitationEmailStatusConnection = {
  __typename: "ModelInvitationEmailStatusConnection"
  items: Array<InvitationEmailStatus | null>
  nextToken?: string | null
}

export type InvitationEmailStatus = {
  __typename: "InvitationEmailStatus"
  id: string
  email: string
  status: EmailStatus
  repicaDeliverId: string
  repicaKeyField: string
  repicaSentDate: string
  errorMessage?: string | null
  createdAt: string
  teamInvitationEmailStatusesId: string
  updatedAt: string
}

export enum EmailStatus {
  sending = "sending",
  sent = "sent",
  bounce = "bounce",
  error = "error",
}

export type ModelGroupConnection = {
  __typename: "ModelGroupConnection"
  items: Array<Group | null>
  nextToken?: string | null
}

export type Group = {
  __typename: "Group"
  id: string
  name?: string | null
  groupMembers?: ModelGroupMemberConnection | null
  migrationId?: number | null
  createdAt: string
  updatedAt: string
  teamGroupsId?: string | null
}

export type ModelGroupMemberConnection = {
  __typename: "ModelGroupMemberConnection"
  items: Array<GroupMember | null>
  nextToken?: string | null
}

export type GroupMember = {
  __typename: "GroupMember"
  id: string
  leaderFlg?: boolean | null
  teamMemberID?: string | null
  groupID?: string | null
  teamMember?: TeamMember | null
  group?: Group | null
  createdAt: string
  updatedAt: string
}

export type ModelMatchingOfferConnection = {
  __typename: "ModelMatchingOfferConnection"
  items: Array<MatchingOffer | null>
  nextToken?: string | null
}

export type MatchingOffer = {
  __typename: "MatchingOffer"
  id: string
  purpose: MatchingOfferPurpose
  title: string
  body: string
  date?: string | null
  startTime?: string | null
  endTime?: string | null
  prefecture?: PrefectureMaster | null
  city?: CityMaster | null
  place?: string | null
  placeURL?: string | null
  expense?: number | null
  expensePurpose?: string | null
  ageFrom?: number | null
  ageTo?: number | null
  offerCount?: number | null
  publishEndDate: string
  team?: Team | null
  attachmentFileNames?: Array<string> | null
  activity?: TeamActivityMaster | null
  teamId: string
  teamName: string
  applications?: ModelMatchingApplicationConnection | null
  deleteFlg?: boolean | null
  createdAt: string
  updatedAt: string
  teamMatchingOffersId?: string | null
  matchingOfferPrefectureId?: string | null
  matchingOfferCityId?: string | null
  matchingOfferActivityId?: string | null
}

export enum MatchingOfferPurpose {
  jointPracticeGame = "jointPracticeGame",
}

export type ModelMatchingApplicationConnection = {
  __typename: "ModelMatchingApplicationConnection"
  items: Array<MatchingApplication | null>
  nextToken?: string | null
}

export type MatchingApplication = {
  __typename: "MatchingApplication"
  id: string
  applicant?: Team | null
  offer?: MatchingOffer | null
  messages?: ModelMatchingMessageConnection | null
  messagesIncludeDeleted?: ModelMatchingMessageConnection | null
  offerTeamName: string
  offerTitle: string
  offerBody: string
  createdAt: string
  updatedAt: string
  teamMatchingApplicationsId?: string | null
  matchingOfferApplicationsId?: string | null
}

export type ModelMatchingMessageConnection = {
  __typename: "ModelMatchingMessageConnection"
  items: Array<MatchingMessage | null>
  nextToken?: string | null
}

export type MatchingMessage = {
  __typename: "MatchingMessage"
  id: string
  messageSenderTeamId: string
  text?: string | null
  unreadFlg: number
  deleteFlg: number
  contentUpdatedAt: string
  createdAtForSort: string
  createdAtForSortIncludeDeleted: string
  applicationIdMessageReceiverGsi: string
  matchingApplicationMessagesId: string
  matchingApplicationMessagesIncludeDeletedId: string
  createdAt: string
  updatedAt: string
}

export type ModelMatchingOfferDraftConnection = {
  __typename: "ModelMatchingOfferDraftConnection"
  items: Array<MatchingOfferDraft | null>
  nextToken?: string | null
}

export type MatchingOfferDraft = {
  __typename: "MatchingOfferDraft"
  id: string
  purpose?: MatchingOfferPurpose | null
  title: string
  body?: string | null
  date?: string | null
  startTime?: string | null
  endTime?: string | null
  prefecture?: PrefectureMaster | null
  city?: CityMaster | null
  place?: string | null
  placeURL?: string | null
  expense?: number | null
  expensePurpose?: string | null
  ageFrom?: number | null
  ageTo?: number | null
  offerCount?: number | null
  publishEndDate?: string | null
  team?: Team | null
  createdAt: string
  updatedAt: string
  teamMatchingOfferDraftsId?: string | null
  matchingOfferDraftPrefectureId?: string | null
  matchingOfferDraftCityId?: string | null
}

export type ModelBlockingMatchingTeamConnection = {
  __typename: "ModelBlockingMatchingTeamConnection"
  items: Array<BlockingMatchingTeam | null>
  nextToken?: string | null
}

export type BlockingMatchingTeam = {
  __typename: "BlockingMatchingTeam"
  id: string
  blockingTeamId: string
  createdAt: string
  updatedAt: string
  teamBlockingMatchingTeamsId?: string | null
}

export type ModelPaidVersionOrderConnection = {
  __typename: "ModelPaidVersionOrderConnection"
  items: Array<PaidVersionOrder | null>
  nextToken?: string | null
}

export type PaidVersionOrder = {
  __typename: "PaidVersionOrder"
  id: string
  orderNumber: string
  originalOrderId?: string | null
  orderStatus: string
  jobId?: string | null
  masterCode?: string | null
  zacStatus?: string | null
  supportedDate?: string | null
  address?: string | null
  orderType: string
  companyName?: string | null
  companyNameKana?: string | null
  corporateRepresentativeName?: string | null
  corporateRepresentativeNameKana?: string | null
  applicantPhone?: string | null
  applicantMail?: string | null
  applicantName?: string | null
  applicantPostcode?: string | null
  applicantPrefecture?: string | null
  applicantAddress?: string | null
  applicantBuildingName?: string | null
  applicantRemark?: string | null
  antiSocialCheck: string
  teamId?: string | null
  migrateTeamId?: number | null
  teamName?: string | null
  memberCount?: number | null
  applicantId?: string | null
  transferApplicantId?: string | null
  planId: string
  startDate: string
  endDate: string
  paymentStatus: string
  paymentDate?: string | null
  paymentDeadline?: string | null
  invoiceIssuedDate?: string | null
  paymentType: string
  paymentMethod: string
  invoiceAddressee?: string | null
  invoiceAddresseeKana?: string | null
  amount: number
  taxRate: number
  tax: number
  subTotal: number
  remark?: string | null
  team?: Team | null
  plan?: PaidVersionPlan | null
  applicant?: TeamMember | null
  applicantTransfer?: TeamMember | null
  orderHistories?: ModelOrderHistoryConnection | null
  transferStatus?: ModelTransferStatusConnection | null
  adminId?: string | null
  createdAt: string
  updatedAt: string
}

export type PaidVersionPlan = {
  __typename: "PaidVersionPlan"
  id: string
  planCode: string
  name: string
  type: string
  memberLimit?: number | null
  teamLimit?: number | null
  period: number
  amount: number
  planType: PlanType
  orders?: ModelPaidVersionOrderConnection | null
  createdAt: string
  updatedAt: string
}

export enum PlanType {
  normal = "normal",
  cs_support = "cs_support",
  end = "end",
}

export type ModelOrderHistoryConnection = {
  __typename: "ModelOrderHistoryConnection"
  items: Array<OrderHistory | null>
  nextToken?: string | null
}

export type OrderHistory = {
  __typename: "OrderHistory"
  id: string
  type: string
  orderId: string
  order: PaidVersionOrder
  teamMemberId?: string | null
  teamMember?: TeamMember | null
  adminId?: string | null
  content: string
  createdAt: string
  updatedAt: string
}

export type ModelTransferStatusConnection = {
  __typename: "ModelTransferStatusConnection"
  items: Array<TransferStatus | null>
  nextToken?: string | null
}

export type TransferStatus = {
  __typename: "TransferStatus"
  id: string
  orderId: string
  originalApplicantId: string
  newApplicantId: string
  status: string
  order: PaidVersionOrder
  createdAt: string
  updatedAt: string
}

export type NotificationSetting = {
  __typename: "NotificationSetting"
  id: string
  mailReceivedNotice: MailReceivedNotice
  threadMessageReceivedNotice: ThreadMessageReceivedNotice
  joinRequestNotice: JoinRequestNotice
  teamMatchingNotice: TeamMatchingNotice
  createdAt: string
  updatedAt: string
}

export type MailReceivedNotice = {
  __typename: "MailReceivedNotice"
  push: boolean
  email: boolean
}

export type ThreadMessageReceivedNotice = {
  __typename: "ThreadMessageReceivedNotice"
  push: boolean
  email: boolean
}

export type JoinRequestNotice = {
  __typename: "JoinRequestNotice"
  push: boolean
  email: boolean
}

export type TeamMatchingNotice = {
  __typename: "TeamMatchingNotice"
  email: boolean
}

export type ModelTeamNoticeConnection = {
  __typename: "ModelTeamNoticeConnection"
  items: Array<TeamNotice | null>
  nextToken?: string | null
}

export type TeamNotice = {
  __typename: "TeamNotice"
  id: string
  noticeType: string
  title: string
  readFlg: boolean
  url: string
  team?: Team | null
  createdAt: string
  createdYearMonth?: number | null
  createdDate?: string | null
  accountTeamNoticesId: string
  updatedAt: string
  teamMemberTeamNoticeId?: string | null
  teamNoticeTeamId?: string | null
}

export type ModelTeamMemberMailConnection = {
  __typename: "ModelTeamMemberMailConnection"
  items: Array<TeamMemberMail | null>
  nextToken?: string | null
}

export type TeamMemberMail = {
  __typename: "TeamMemberMail"
  id: string
  receivedFlg: boolean
  sentFlg: boolean
  readFlg: boolean
  readAt?: string | null
  favoriteFlgReceived: boolean
  favoriteFlgSent: boolean
  answerRequiredFlg: boolean
  responseDueDatetimeForFilter: string
  answeredFlg: boolean
  answeredAt?: string | null
  attendanceAnswer?: YesNoAnswer | null
  questionnaireAnswers?: Array<QuestionnaireAnswers> | null
  scheduleAnswers?: Array<YesNoAnswer> | null
  senderId: string
  mailType: string
  title: string
  body: string
  hasAttachment: boolean
  hasThread: boolean
  trashedFlgReceived: boolean
  deletedFlgReceived: boolean
  deletedFlgSent: boolean
  count: number
  teamMemberID?: string | null
  mailID?: string | null
  teamMember?: TeamMember | null
  mail?: Mail | null
  createdYearMonth?: number | null
  createdDate?: string | null
  createdAt: string
  updatedAt: string
}

export enum YesNoAnswer {
  yes = "yes",
  no = "no",
  tbd = "tbd",
}

export type QuestionnaireAnswers = {
  __typename: "QuestionnaireAnswers"
  answer: Array<number>
}

export type Mail = {
  __typename: "Mail"
  id: string
  teamId: string
  mailType: string
  title: string
  sender?: TeamMember | null
  body: string
  sendTo: SendTo
  attachmentFileNames?: Array<string> | null
  startDate?: string | null
  startTime?: string | null
  endDate?: string | null
  endTime?: string | null
  questionnaires?: Array<Questionnaires> | null
  candidateDatetimes?: Array<CandidateDatetimes> | null
  shareManagerFlg?: number | null
  showAnswerFlg?: boolean | null
  responseDueDatetime?: string | null
  remindDate: string
  remindDatetime?: string | null
  remindExecDatetime?: string | null
  sendScheduledAt?: string | null
  sentAt: string
  teamMemberMails?: ModelTeamMemberMailConnection | null
  threads?: ModelThreadConnection | null
  createdAt: string
  updatedAt: string
  mailSenderId?: string | null
}

export type SendTo = {
  __typename: "SendTo"
  allMembersFlg?: boolean | null
  managersFlg?: boolean | null
  groupIds?: Array<string> | null
  memberIds?: Array<string> | null
}

export type Questionnaires = {
  __typename: "Questionnaires"
  answerSelectType: AnswerSelectType
  question: string
  choices: Array<string>
}

export enum AnswerSelectType {
  single = "single",
  multiple = "multiple",
}

export type CandidateDatetimes = {
  __typename: "CandidateDatetimes"
  startDate: string
  startTime?: string | null
  endDate?: string | null
  endTime?: string | null
}

export type ModelThreadConnection = {
  __typename: "ModelThreadConnection"
  items: Array<Thread | null>
  nextToken?: string | null
}

export type Thread = {
  __typename: "Thread"
  id: string
  mailSender?: TeamMember | null
  mailRecipient?: TeamMember | null
  threadMailRecipientId?: string | null
  messages?: ModelThreadMessageConnection | null
  mail?: Mail | null
  mailThreadsId: string
  createdAt: string
  updatedAt: string
  threadMailSenderId?: string | null
}

export type ModelThreadMessageConnection = {
  __typename: "ModelThreadMessageConnection"
  items: Array<ThreadMessage | null>
  nextToken?: string | null
}

export type ThreadMessage = {
  __typename: "ThreadMessage"
  id: string
  messageSenderId: string
  messageRecipientAccountId: string
  teamId: string
  mailId: string
  text?: string | null
  unreadFlg: number
  deleteFlg?: boolean | null
  contentUpdatedAt: string
  createdAt: string
  createdYearMonth?: number | null
  createdDate?: string | null
  threadMessagesId: string
  updatedAt: string
}

export type ModelReservedMailConnection = {
  __typename: "ModelReservedMailConnection"
  items: Array<ReservedMail | null>
  nextToken?: string | null
}

export type ReservedMail = {
  __typename: "ReservedMail"
  id: string
  teamId: string
  mailType: string
  title: string
  teamMember?: TeamMember | null
  body: string
  sendTo: SendTo
  attachmentFileNames?: Array<string> | null
  startDate?: string | null
  startTime?: string | null
  endDate?: string | null
  endTime?: string | null
  questionnaires?: Array<Questionnaires> | null
  candidateDatetimes?: Array<CandidateDatetimes> | null
  shareManagerFlg?: number | null
  showAnswerFlg?: boolean | null
  responseDueDatetime?: string | null
  remindDatetime?: string | null
  sendScheduledDate: string
  sendScheduledAt: string
  createdAt: string
  updatedAt: string
  teamMemberReservedMailsId?: string | null
}

export type ModelDraftConnection = {
  __typename: "ModelDraftConnection"
  items: Array<Draft | null>
  nextToken?: string | null
}

export type Draft = {
  __typename: "Draft"
  id: string
  mailType: string
  title: string
  teamMember?: TeamMember | null
  body: string
  sendTo?: SendTo | null
  attachmentFileNames?: Array<string> | null
  startDate?: string | null
  startTime?: string | null
  endDate?: string | null
  endTime?: string | null
  questionnaires?: Array<Questionnaires> | null
  candidateDatetimes?: Array<CandidateDatetimes> | null
  shareManagerFlg?: number | null
  showAnswerFlg?: boolean | null
  responseDueDatetime?: string | null
  remindDatetime?: string | null
  sendScheduledAt?: string | null
  createdAt: string
  updatedAt: string
  teamMemberDraftsId?: string | null
}

export type ModelTemplateConnection = {
  __typename: "ModelTemplateConnection"
  items: Array<Template | null>
  nextToken?: string | null
}

export type Template = {
  __typename: "Template"
  id: string
  mailType: string
  title: string
  teamMember: TeamMember
  body: string
  sendTo: SendTo
  startDate?: string | null
  startTime?: string | null
  endDate?: string | null
  endTime?: string | null
  questionnaires?: Array<Questionnaires> | null
  candidateDatetimes?: Array<CandidateDatetimes> | null
  shareManagerFlg?: number | null
  showAnswerFlg?: boolean | null
  favoriteFlg?: boolean | null
  createdAt: string
  updatedAt: string
  teamMemberTemplatesId?: string | null
}

export type ModelMigratedMailConnection = {
  __typename: "ModelMigratedMailConnection"
  items: Array<MigratedMail | null>
  nextToken?: string | null
}

export type MigratedMail = {
  __typename: "MigratedMail"
  id: string
  migrationId: number
  title: string
  body: string
  sender: string
  favoriteFlg: boolean
  shareManagerFlg: boolean
  sentDatetime: string
  createdAt: string
  updatedAt: string
  teamMemberMigratedMailsId?: string | null
}

export type ModelFavoriteMatchingOfferConnection = {
  __typename: "ModelFavoriteMatchingOfferConnection"
  items: Array<FavoriteMatchingOffer | null>
  nextToken?: string | null
}

export type FavoriteMatchingOffer = {
  __typename: "FavoriteMatchingOffer"
  id: string
  offer: MatchingOffer
  createdAt: string
  updatedAt: string
  teamMemberFavoriteMatchingOfferId?: string | null
  favoriteMatchingOfferOfferId: string
}

export type RegisterAuthCodeInput = {
  email?: string | null
  phone_number?: string | null
  secret_code: string
  session_id: string
}

export type RegisterAuthCodeResponse = {
  __typename: "RegisterAuthCodeResponse"
  message?: string | null
  data?: RegisterAuthCodeData | null
}

export type RegisterAuthCodeData = {
  __typename: "RegisterAuthCodeData"
  auth?: InitiateAuthResponse | null
  user?: RegisterAuthCodeUser | null
}

export type InitiateAuthResponse = {
  __typename: "InitiateAuthResponse"
  ChallengeName?: string | null
  Session?: string | null
  ChallengeParameters?: string | null
  AuthenticationResult?: AuthenticationResultType | null
}

export type AuthenticationResultType = {
  __typename: "AuthenticationResultType"
  AccessToken?: string | null
  ExpiresIn?: string | null
  TokenType?: string | null
  RefreshToken?: string | null
  IdToken?: string | null
  NewDeviceMetadata?: string | null
}

export type RegisterAuthCodeUser = {
  __typename: "RegisterAuthCodeUser"
  id?: string | null
  lastName?: string | null
  firstName?: string | null
  lastNameKana?: string | null
  firstNameKana?: string | null
  email?: string | null
  signupStatus?: string | null
}

export type UpdateNotificationSettingInput = {
  id: string
  mailReceivedNotice?: MailReceivedNoticeInput | null
  threadMessageReceivedNotice?: ThreadMessageReceivedNoticeInput | null
  joinRequestNotice?: JoinRequestNoticeInput | null
  teamMatchingNotice?: TeamMatchingNoticeInput | null
}

export type MailReceivedNoticeInput = {
  push: boolean
  email: boolean
}

export type ThreadMessageReceivedNoticeInput = {
  push: boolean
  email: boolean
}

export type JoinRequestNoticeInput = {
  push: boolean
  email: boolean
}

export type TeamMatchingNoticeInput = {
  email: boolean
}

export type ModelTeamConnection = {
  __typename: "ModelTeamConnection"
  items: Array<Team | null>
  nextToken?: string | null
}

export type ModelJobMasterConnection = {
  __typename: "ModelJobMasterConnection"
  items: Array<JobMaster | null>
  nextToken?: string | null
}

export type SearchableTeamMemberMailFilterInput = {
  id?: SearchableIDFilterInput | null
  receivedFlg?: SearchableBooleanFilterInput | null
  sentFlg?: SearchableBooleanFilterInput | null
  readFlg?: SearchableBooleanFilterInput | null
  readAt?: SearchableStringFilterInput | null
  favoriteFlgReceived?: SearchableBooleanFilterInput | null
  favoriteFlgSent?: SearchableBooleanFilterInput | null
  answerRequiredFlg?: SearchableBooleanFilterInput | null
  responseDueDatetimeForFilter?: SearchableStringFilterInput | null
  answeredFlg?: SearchableBooleanFilterInput | null
  answeredAt?: SearchableStringFilterInput | null
  senderId?: SearchableIDFilterInput | null
  mailType?: SearchableStringFilterInput | null
  title?: SearchableStringFilterInput | null
  body?: SearchableStringFilterInput | null
  hasAttachment?: SearchableBooleanFilterInput | null
  hasThread?: SearchableBooleanFilterInput | null
  trashedFlgReceived?: SearchableBooleanFilterInput | null
  deletedFlgReceived?: SearchableBooleanFilterInput | null
  deletedFlgSent?: SearchableBooleanFilterInput | null
  count?: SearchableIntFilterInput | null
  teamMemberID?: SearchableIDFilterInput | null
  mailID?: SearchableIDFilterInput | null
  createdYearMonth?: SearchableIntFilterInput | null
  createdDate?: SearchableStringFilterInput | null
  createdAt?: SearchableStringFilterInput | null
  updatedAt?: SearchableStringFilterInput | null
  attendanceAnswer?: SearchableStringFilterInput | null
  scheduleAnswers?: SearchableStringFilterInput | null
  and?: Array<SearchableTeamMemberMailFilterInput | null> | null
  or?: Array<SearchableTeamMemberMailFilterInput | null> | null
  not?: SearchableTeamMemberMailFilterInput | null
}

export type SearchableIDFilterInput = {
  ne?: string | null
  gt?: string | null
  lt?: string | null
  gte?: string | null
  lte?: string | null
  eq?: string | null
  match?: string | null
  matchPhrase?: string | null
  matchPhrasePrefix?: string | null
  multiMatch?: string | null
  exists?: boolean | null
  wildcard?: string | null
  regexp?: string | null
  range?: Array<string | null> | null
}

export type SearchableBooleanFilterInput = {
  eq?: boolean | null
  ne?: boolean | null
}

export type SearchableStringFilterInput = {
  ne?: string | null
  gt?: string | null
  lt?: string | null
  gte?: string | null
  lte?: string | null
  eq?: string | null
  match?: string | null
  matchPhrase?: string | null
  matchPhrasePrefix?: string | null
  multiMatch?: string | null
  exists?: boolean | null
  wildcard?: string | null
  regexp?: string | null
  range?: Array<string | null> | null
}

export type SearchableIntFilterInput = {
  ne?: number | null
  gt?: number | null
  lt?: number | null
  gte?: number | null
  lte?: number | null
  eq?: number | null
  range?: Array<number | null> | null
}

export type SearchableTeamMemberMailConnection = {
  __typename: "SearchableTeamMemberMailConnection"
  items: Array<TeamMemberMail | null>
  nextToken?: string | null
  total?: number | null
  aggregateItems: Array<SearchableAggregateResult | null>
}

export type SearchableAggregateResult = {
  __typename: "SearchableAggregateResult"
  name: string
  result?: SearchableAggregateGenericResult | null
}

export type SearchableAggregateGenericResult = SearchableAggregateScalarResult | SearchableAggregateBucketResult

export type SearchableAggregateScalarResult = {
  __typename: "SearchableAggregateScalarResult"
  value: number
}

export type SearchableAggregateBucketResult = {
  __typename: "SearchableAggregateBucketResult"
  buckets?: Array<SearchableAggregateBucketResultItem | null> | null
}

export type SearchableAggregateBucketResultItem = {
  __typename: "SearchableAggregateBucketResultItem"
  key: string
  doc_count: number
}

export type UpdateTeamMemberMailInput = {
  id: string
  receivedFlg?: boolean | null
  sentFlg?: boolean | null
  readFlg?: boolean | null
  readAt?: string | null
  favoriteFlgReceived?: boolean | null
  favoriteFlgSent?: boolean | null
  answerRequiredFlg?: boolean | null
  responseDueDatetimeForFilter?: string | null
  answeredFlg?: boolean | null
  answeredAt?: string | null
  attendanceAnswer?: YesNoAnswer | null
  questionnaireAnswers?: Array<QuestionnaireAnswersInput> | null
  scheduleAnswers?: Array<YesNoAnswer> | null
  senderId?: string | null
  mailType?: string | null
  title?: string | null
  body?: string | null
  hasAttachment?: boolean | null
  hasThread?: boolean | null
  trashedFlgReceived?: boolean | null
  deletedFlgReceived?: boolean | null
  deletedFlgSent?: boolean | null
  count?: number | null
  teamMemberID?: string | null
  mailID?: string | null
  createdYearMonth?: number | null
  createdDate?: string | null
}

export type QuestionnaireAnswersInput = {
  answer: Array<number>
}

export type UpdateReservedMailInput = {
  id: string
  teamId?: string | null
  mailType?: string | null
  title?: string | null
  body?: string | null
  sendTo?: SendToInput | null
  attachmentFileNames?: Array<string> | null
  startDate?: string | null
  startTime?: string | null
  endDate?: string | null
  endTime?: string | null
  questionnaires?: Array<QuestionnairesInput> | null
  candidateDatetimes?: Array<CandidateDatetimesInput> | null
  shareManagerFlg?: number | null
  showAnswerFlg?: boolean | null
  responseDueDatetime?: string | null
  remindDatetime?: string | null
  sendScheduledDate?: string | null
  sendScheduledAt?: string | null
  teamMemberReservedMailsId?: string | null
}

export type SendToInput = {
  allMembersFlg?: boolean | null
  managersFlg?: boolean | null
  groupIds?: Array<string> | null
  memberIds?: Array<string> | null
}

export type QuestionnairesInput = {
  answerSelectType: AnswerSelectType
  question: string
  choices: Array<string>
}

export type CandidateDatetimesInput = {
  startDate: string
  startTime?: string | null
  endDate?: string | null
  endTime?: string | null
}

export type DeleteReservedMailInput = {
  id: string
}

export type UpdateDraftInput = {
  id: string
  mailType?: string | null
  title?: string | null
  body?: string | null
  sendTo?: SendToInput | null
  attachmentFileNames?: Array<string> | null
  startDate?: string | null
  startTime?: string | null
  endDate?: string | null
  endTime?: string | null
  questionnaires?: Array<QuestionnairesInput> | null
  candidateDatetimes?: Array<CandidateDatetimesInput> | null
  shareManagerFlg?: number | null
  showAnswerFlg?: boolean | null
  responseDueDatetime?: string | null
  remindDatetime?: string | null
  sendScheduledAt?: string | null
  teamMemberDraftsId?: string | null
}

export type DeleteDraftInput = {
  id: string
}

export type UpdateTemplateInput = {
  id: string
  mailType?: string | null
  title?: string | null
  body?: string | null
  sendTo?: SendToInput | null
  startDate?: string | null
  startTime?: string | null
  endDate?: string | null
  endTime?: string | null
  questionnaires?: Array<QuestionnairesInput> | null
  candidateDatetimes?: Array<CandidateDatetimesInput> | null
  shareManagerFlg?: number | null
  showAnswerFlg?: boolean | null
  favoriteFlg?: boolean | null
  teamMemberTemplatesId?: string | null
}

export type DeleteTemplateInput = {
  id: string
}

export type SearchableThreadMessageConnection = {
  __typename: "SearchableThreadMessageConnection"
  items: Array<ThreadMessage | null>
  nextToken?: string | null
  total?: number | null
  aggregateItems: Array<SearchableAggregateResult | null>
}

export type SearchableThreadMessageFilterInput = {
  id?: SearchableIDFilterInput | null
  messageSenderId?: SearchableIDFilterInput | null
  messageRecipientAccountId?: SearchableIDFilterInput | null
  teamId?: SearchableIDFilterInput | null
  mailId?: SearchableIDFilterInput | null
  text?: SearchableStringFilterInput | null
  unreadFlg?: SearchableIntFilterInput | null
  deleteFlg?: SearchableBooleanFilterInput | null
  contentUpdatedAt?: SearchableStringFilterInput | null
  createdAt?: SearchableStringFilterInput | null
  createdYearMonth?: SearchableIntFilterInput | null
  createdDate?: SearchableStringFilterInput | null
  threadMessagesId?: SearchableIDFilterInput | null
  updatedAt?: SearchableStringFilterInput | null
  and?: Array<SearchableThreadMessageFilterInput | null> | null
  or?: Array<SearchableThreadMessageFilterInput | null> | null
  not?: SearchableThreadMessageFilterInput | null
}

export type SearchableThreadMessageAggregationInput = {
  name: string
  type: SearchableAggregateType
  field: SearchableThreadMessageAggregateField
}

export enum SearchableAggregateType {
  terms = "terms",
  avg = "avg",
  min = "min",
  max = "max",
  sum = "sum",
}

export enum SearchableThreadMessageAggregateField {
  id = "id",
  messageSenderId = "messageSenderId",
  messageRecipientAccountId = "messageRecipientAccountId",
  teamId = "teamId",
  mailId = "mailId",
  text = "text",
  unreadFlg = "unreadFlg",
  deleteFlg = "deleteFlg",
  contentUpdatedAt = "contentUpdatedAt",
  createdAt = "createdAt",
  createdYearMonth = "createdYearMonth",
  createdDate = "createdDate",
  threadMessagesId = "threadMessagesId",
  updatedAt = "updatedAt",
}

export type ModelSchoolMasterConnection = {
  __typename: "ModelSchoolMasterConnection"
  items: Array<SchoolMaster | null>
  nextToken?: string | null
}

export type ModelPrefectureMasterConnection = {
  __typename: "ModelPrefectureMasterConnection"
  items: Array<PrefectureMaster | null>
  nextToken?: string | null
}

export type ModelTeamCategoryMasterConnection = {
  __typename: "ModelTeamCategoryMasterConnection"
  items: Array<TeamCategoryMaster | null>
  nextToken?: string | null
}

export type ModelAccountQuestionMasterConnection = {
  __typename: "ModelAccountQuestionMasterConnection"
  items: Array<AccountQuestionMaster | null>
  nextToken?: string | null
}

export type SearchableTeamMemberMailAggregationInput = {
  name: string
  type: SearchableAggregateType
  field: SearchableTeamMemberMailAggregateField
}

export enum SearchableTeamMemberMailAggregateField {
  id = "id",
  receivedFlg = "receivedFlg",
  sentFlg = "sentFlg",
  readFlg = "readFlg",
  readAt = "readAt",
  favoriteFlgReceived = "favoriteFlgReceived",
  favoriteFlgSent = "favoriteFlgSent",
  answerRequiredFlg = "answerRequiredFlg",
  responseDueDatetimeForFilter = "responseDueDatetimeForFilter",
  answeredFlg = "answeredFlg",
  answeredAt = "answeredAt",
  attendanceAnswer = "attendanceAnswer",
  scheduleAnswers = "scheduleAnswers",
  senderId = "senderId",
  mailType = "mailType",
  title = "title",
  body = "body",
  hasAttachment = "hasAttachment",
  hasThread = "hasThread",
  trashedFlgReceived = "trashedFlgReceived",
  deletedFlgReceived = "deletedFlgReceived",
  deletedFlgSent = "deletedFlgSent",
  count = "count",
  teamMemberID = "teamMemberID",
  mailID = "mailID",
  createdYearMonth = "createdYearMonth",
  createdDate = "createdDate",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}

export type SearchableMatchingOfferFilterInput = {
  id?: SearchableIDFilterInput | null
  title?: SearchableStringFilterInput | null
  body?: SearchableStringFilterInput | null
  date?: SearchableStringFilterInput | null
  startTime?: SearchableStringFilterInput | null
  endTime?: SearchableStringFilterInput | null
  place?: SearchableStringFilterInput | null
  placeURL?: SearchableStringFilterInput | null
  expense?: SearchableIntFilterInput | null
  expensePurpose?: SearchableStringFilterInput | null
  ageFrom?: SearchableIntFilterInput | null
  ageTo?: SearchableIntFilterInput | null
  offerCount?: SearchableIntFilterInput | null
  publishEndDate?: SearchableStringFilterInput | null
  attachmentFileNames?: SearchableStringFilterInput | null
  teamId?: SearchableStringFilterInput | null
  teamName?: SearchableStringFilterInput | null
  deleteFlg?: SearchableBooleanFilterInput | null
  createdAt?: SearchableStringFilterInput | null
  updatedAt?: SearchableStringFilterInput | null
  teamMatchingOffersId?: SearchableIDFilterInput | null
  matchingOfferPrefectureId?: SearchableIDFilterInput | null
  matchingOfferCityId?: SearchableIDFilterInput | null
  matchingOfferActivityId?: SearchableIDFilterInput | null
  purpose?: SearchableStringFilterInput | null
  and?: Array<SearchableMatchingOfferFilterInput | null> | null
  or?: Array<SearchableMatchingOfferFilterInput | null> | null
  not?: SearchableMatchingOfferFilterInput | null
}

export type SearchableMatchingOfferConnection = {
  __typename: "SearchableMatchingOfferConnection"
  items: Array<MatchingOffer | null>
  nextToken?: string | null
  total?: number | null
  aggregateItems: Array<SearchableAggregateResult | null>
}

export type UpdateMatchingOfferInput = {
  id: string
  purpose?: MatchingOfferPurpose | null
  title?: string | null
  body?: string | null
  date?: string | null
  startTime?: string | null
  endTime?: string | null
  place?: string | null
  placeURL?: string | null
  expense?: number | null
  expensePurpose?: string | null
  ageFrom?: number | null
  ageTo?: number | null
  offerCount?: number | null
  publishEndDate?: string | null
  attachmentFileNames?: Array<string> | null
  teamId?: string | null
  teamName?: string | null
  deleteFlg?: boolean | null
  teamMatchingOffersId?: string | null
  matchingOfferPrefectureId?: string | null
  matchingOfferCityId?: string | null
  matchingOfferActivityId?: string | null
}

export type UpdateMatchingOfferDraftInput = {
  id: string
  purpose?: MatchingOfferPurpose | null
  title?: string | null
  body?: string | null
  date?: string | null
  startTime?: string | null
  endTime?: string | null
  place?: string | null
  placeURL?: string | null
  expense?: number | null
  expensePurpose?: string | null
  ageFrom?: number | null
  ageTo?: number | null
  offerCount?: number | null
  publishEndDate?: string | null
  teamMatchingOfferDraftsId?: string | null
  matchingOfferDraftPrefectureId?: string | null
  matchingOfferDraftCityId?: string | null
}

export type DeleteMatchingOfferDraftInput = {
  id: string
}

export type CreateFavoriteMatchingOfferInput = {
  id?: string | null
  teamMemberFavoriteMatchingOfferId?: string | null
  favoriteMatchingOfferOfferId: string
}

export type DeleteFavoriteMatchingOfferInput = {
  id: string
}

export type SearchableMatchingApplicationFilterInput = {
  id?: SearchableIDFilterInput | null
  offerTeamName?: SearchableStringFilterInput | null
  offerTitle?: SearchableStringFilterInput | null
  offerBody?: SearchableStringFilterInput | null
  createdAt?: SearchableStringFilterInput | null
  updatedAt?: SearchableStringFilterInput | null
  teamMatchingApplicationsId?: SearchableIDFilterInput | null
  matchingOfferApplicationsId?: SearchableIDFilterInput | null
  and?: Array<SearchableMatchingApplicationFilterInput | null> | null
  or?: Array<SearchableMatchingApplicationFilterInput | null> | null
  not?: SearchableMatchingApplicationFilterInput | null
}

export type SearchableMatchingApplicationConnection = {
  __typename: "SearchableMatchingApplicationConnection"
  items: Array<MatchingApplication | null>
  nextToken?: string | null
  total?: number | null
  aggregateItems: Array<SearchableAggregateResult | null>
}

export type MultiGetApplicationInput = {
  offerIds: Array<string>
}

export type MultiGetApplicationResponse = {
  __typename: "MultiGetApplicationResponse"
  items: Array<MultiGetApplicationItem>
}

export type MultiGetApplicationItem = {
  __typename: "MultiGetApplicationItem"
  offerId: string
  applications: Array<MultiGetApplicationItemApplication>
}

export type MultiGetApplicationItemApplication = {
  __typename: "MultiGetApplicationItemApplication"
  id: string
  messages: Array<MultiGetApplicationMessage>
}

export type MultiGetApplicationMessage = {
  __typename: "MultiGetApplicationMessage"
  text: string
  messageSenderTeamId: string
  createdAt: string
}

export type ListTeamMemberMailByAccountInput = {
  limit?: number | null
}

export type ListTeamMemberMailByAccountResponse = {
  __typename: "ListTeamMemberMailByAccountResponse"
  accountId: string
  teamMemberMailByAccount: Array<TeamMemberMailByAccount>
}

export type TeamMemberMailByAccount = {
  __typename: "TeamMemberMailByAccount"
  teamId: string
  teamMemberMail: Array<TeamMemberMailItemByAccount>
}

export type TeamMemberMailItemByAccount = {
  __typename: "TeamMemberMailItemByAccount"
  id: string
  readFlg?: boolean | null
  favoriteFlgReceived: boolean
  favoriteFlgSent: boolean
  answeredFlg?: boolean | null
  answerRequiredFlg?: boolean | null
  mail?: MailItem | null
}

export type MailItem = {
  __typename: "MailItem"
  id: string
  mailType?: string | null
  title?: string | null
  sender?: Sender | null
  attachmentFileNames: Array<string>
  sentAt: string
  responseDueDatetime?: string | null
  createdAt: string
  updatedAt: string
}

export type Sender = {
  __typename: "Sender"
  id: string
  nickname?: string | null
  deleteFlg?: boolean | null
}

export type SearchableMigratedMailFilterInput = {
  id?: SearchableIDFilterInput | null
  migrationId?: SearchableIntFilterInput | null
  title?: SearchableStringFilterInput | null
  body?: SearchableStringFilterInput | null
  sender?: SearchableStringFilterInput | null
  favoriteFlg?: SearchableBooleanFilterInput | null
  shareManagerFlg?: SearchableBooleanFilterInput | null
  sentDatetime?: SearchableStringFilterInput | null
  createdAt?: SearchableStringFilterInput | null
  updatedAt?: SearchableStringFilterInput | null
  teamMemberMigratedMailsId?: SearchableIDFilterInput | null
  and?: Array<SearchableMigratedMailFilterInput | null> | null
  or?: Array<SearchableMigratedMailFilterInput | null> | null
  not?: SearchableMigratedMailFilterInput | null
}

export type SearchableMigratedMailConnection = {
  __typename: "SearchableMigratedMailConnection"
  items: Array<MigratedMail | null>
  nextToken?: string | null
  total?: number | null
  aggregateItems: Array<SearchableAggregateResult | null>
}

export type UpdateMigratedMailInput = {
  id: string
  migrationId?: number | null
  title?: string | null
  body?: string | null
  sender?: string | null
  favoriteFlg?: boolean | null
  shareManagerFlg?: boolean | null
  sentDatetime?: string | null
  teamMemberMigratedMailsId?: string | null
}

export type DeleteMigratedMailInput = {
  id: string
}

export type GetPermissionAccountInput = {
  teamId: string
}

export type GetPermissionAccountResponse = {
  __typename: "GetPermissionAccountResponse"
  teamId: string
  management: PermissionAccountManagementType
  list: PermissionAccountListType
  mail: PermissionAccountMailType
  order: PermissionOrderType
}

export type PermissionAccountManagementType = {
  __typename: "PermissionAccountManagementType"
  team: TeamManagementType
  member: MemberManagementType
  group: GroupManagementType
}

export type TeamManagementType = {
  __typename: "TeamManagementType"
  canSetting: boolean
  canDelete: boolean
}

export type MemberManagementType = {
  __typename: "MemberManagementType"
  canInviteTeam: boolean
  canApproveMember: boolean
  canRoleSetting: boolean
  canDeleteMember: boolean
  canUpdateMemberInfo: boolean
  canExportCsv: boolean
}

export type GroupManagementType = {
  __typename: "GroupManagementType"
  canCreateGroup: boolean
  canUpdateGroup: boolean
  canUpdateMemberInfo: boolean
  canUpdateGroupLeader: boolean
  canDeleteGroup: boolean
}

export type PermissionAccountListType = {
  __typename: "PermissionAccountListType"
  canVisibleListMember: boolean
  listGroup: AccessType
}

export type AccessType = {
  __typename: "AccessType"
  accessAll: boolean
  groupIds: Array<string>
}

export type PermissionAccountMailType = {
  __typename: "PermissionAccountMailType"
  make: AccessType
  recv: RecvType
}

export type RecvType = {
  __typename: "RecvType"
  memberListPublishOn: ShowMail
  memberListPublishOff: ShowMail
  send: SendRecvType
}

export type ShowMail = {
  __typename: "ShowMail"
  showRecipientCount: AccessType
  showRecipientListMembers: AccessType
  showReadCount: AccessType
  showReadListMembers: AccessType
  showAnsweredCount: AccessType
  showAnsweredListMembers: AccessType
  showAnswerResultCount: AccessType
  showAnswerResultListMembers: AccessType
}

export type SendRecvType = {
  __typename: "SendRecvType"
  memberListPublishOn: ShowMail
  memberListPublishOff: ShowMail
}

export type PermissionOrderType = {
  __typename: "PermissionOrderType"
  showOrder: boolean
  canTransferApplicant: boolean
  canCreateOrder: boolean
  canUpdateOrder: boolean
  canCancelOrder: boolean
  canExtendOrder: boolean
  canChangePlan: boolean
  canCreatePaidTeamInquiry: boolean
}

export type SignUpInput = {
  email: string
}

export type SignUpResponse = {
  __typename: "SignUpResponse"
  message?: string | null
  data?: InitiateAuthResponse | null
}

export type SignInInput = {
  email?: string | null
  phone_number?: string | null
}

export type SignInResponse = {
  __typename: "SignInResponse"
  message?: string | null
  data?: InitiateAuthResponse | null
}

export type UserAuthSimpleResponse = {
  __typename: "UserAuthSimpleResponse"
  message?: string | null
}

export type UpdateAuthMethodInput = {
  email?: string | null
  phone_number?: string | null
}

export type RegisterAuthMethodCodeInput = {
  code: string
}

export type ConfirmAuthTokenInput = {
  token?: string | null
  refresh_token?: string | null
}

export type ConfirmAuthTokenResponse = {
  __typename: "ConfirmAuthTokenResponse"
  message?: string | null
  data?: ConfirmAuthTokenData | null
}

export type ConfirmAuthTokenData = {
  __typename: "ConfirmAuthTokenData"
  user?: Account | null
  AccessToken?: string | null
  RefreshToken?: string | null
}

export type InitAccountInput = {
  lastName: string
  firstName: string
  lastNameKana: string
  firstNameKana: string
  gender?: Gender | null
  birthday?: string | null
  accountJobId?: string | null
  accountSchoolId?: string | null
  accountSchoolDepartmentId?: string | null
  schoolGraduationYear?: number | null
  accountPrefectureId?: string | null
  accountCityId?: string | null
  questionAnswers?: Array<QuestionAnswersInput> | null
  agreedTermsVersion?: string | null
  migrateAccountId?: number | null
}

export type QuestionAnswersInput = {
  questionId: string
  answerId: string
}

export type IdResponse = {
  __typename: "IdResponse"
  id: string
}

export type UpdateAccountInput = {
  lastName?: string | null
  firstName?: string | null
  lastNameKana?: string | null
  firstNameKana?: string | null
  gender?: Gender | null
  birthday?: string | null
  accountJobId?: string | null
  accountSchoolId?: string | null
  accountSchoolDepartmentId?: string | null
  schoolGraduationYear?: number | null
  accountPrefectureId?: string | null
  accountCityId?: string | null
  questionAnswers?: Array<QuestionAnswersInput> | null
  notificationSetting?: NotificationSettingInput | null
}

export type NotificationSettingInput = {
  mail: NotificationSettingMailInput
  joinRequest: NotificationSettingJoinRequestInput
}

export type NotificationSettingMailInput = {
  push: boolean
  email: boolean
}

export type NotificationSettingJoinRequestInput = {
  push: boolean
  email: boolean
}

export type CreateAccountDeviceInput = {
  kind: DeviceKind
  deviceToken: string
}

export type DeleteAccountDeviceInput = {
  deviceToken: string
}

export type DeleteAccountInput = {
  reason?: string | null
}

export type GetTempTokenInput = {
  accessToken: string
}

export type GetTempTokenResponse = {
  __typename: "GetTempTokenResponse"
  tempToken: string
}

export type GetAccessTokenByTempTokenInput = {
  tempToken: string
}

export type GetAccessTokenByTempTokenResponse = {
  __typename: "GetAccessTokenByTempTokenResponse"
  accessToken: string
}

export type CreateTeamInput = {
  tempTeamId: string
  name: string
  teamCategoryId: string
  teamActivityId: string
  teamPrefectureId: string
  teamCityId: string
  description: string
  showMemberListFlg: boolean
  customQuestions?: Array<TeamCustomQuestion> | null
}

export type UpdateTeamInput = {
  id: string
  name?: string | null
  teamCategoryId?: string | null
  teamActivityId?: string | null
  teamPrefectureId?: string | null
  teamCityId?: string | null
  description?: string | null
  requirementForMemberProfile?: string | null
  customQuestions?: Array<TeamCustomQuestion> | null
  showMemberListFlg?: boolean | null
}

export type DeleteTeamInput = {
  id: string
  reason?: string | null
}

export type ReportTeamInput = {
  teamId: string
  content: string
}

export type SendJoinRequestInput = {
  teamId: string
  nickname: string
  nicknameKana: string
  showEmailFlg: boolean
  phoneNumber?: string | null
  showPhoneNumberFlg: boolean
  belongs?: string | null
  showBelongsFlg: boolean
  grade?: number | null
}

export type ApprovePreTeamMemberInput = {
  teamId: string
  preMemberIds: Array<string>
  isProcessAll?: boolean | null
}

export type IdListResponse = {
  __typename: "IdListResponse"
  ids: Array<string>
}

export type RejectPreTeamMemberInput = {
  teamId: string
  preMemberIds: Array<string>
  isProcessAll?: boolean | null
}

export type SendInvitationEmailInput = {
  teamId: string
  emails: Array<string>
}

export type ResendInvitationEmailInput = {
  teamId: string
  invitationEmailStatusId: string
}

export type SendMigrationInvitationEmailInput = {
  teamId: string
  emails: Array<string>
}

export type SendMigrationInvitationEmailResponse = {
  __typename: "SendMigrationInvitationEmailResponse"
  id: string
  results?: Array<SendMigrationInvitationEmailResultType> | null
}

export type SendMigrationInvitationEmailResultType = {
  __typename: "SendMigrationInvitationEmailResultType"
  email: string
  status: string
  error?: string | null
  deliverId: string
}

export type UpdateTeamMemberInput = {
  id: string
  memberSeq?: number | null
  nickname?: string | null
  nicknameKana?: string | null
  role?: string | null
  showEmailFlg?: boolean | null
  phoneNumber?: string | null
  showPhoneNumberFlg?: boolean | null
  belongs?: string | null
  showBelongsFlg?: boolean | null
  grade?: number | null
  teamTeamMembersId?: string | null
  teamMemberNotificationSettingId?: string | null
}

export type DeleteTeamMemberInput = {
  id: string
}

export type IdListInput = {
  ids: Array<string>
}

export type CreateGroupInput = {
  teamId: string
  name: string
  members: Array<CreateUpdateGroupMemberInput>
  teamGroupsId?: string | null
}

export type CreateUpdateGroupMemberInput = {
  id: string
  leaderFlg: boolean
}

export type UpdateGroupInput = {
  id: string
  name?: string | null
  members?: Array<CreateUpdateGroupMemberInput> | null
  teamGroupsId?: string | null
}

export type UpdateNameGroupAndGroupMembersInput = {
  id: string
  name?: string | null
  members?: Array<UpdateGroupMemberInput> | null
}

export type UpdateGroupMemberInput = {
  id: string
  leaderFlg: boolean
  deleteFlg: boolean
}

export type DeleteGroupInput = {
  id: string
}

export type UpdateAttendanceAnswerInput = {
  mailId: string
  memberId: string
  attendanceAnswer: YesNoAnswer
}

export type UpdateQuestionnaireAnswerInput = {
  mailId: string
  memberId: string
  questionnaireAnswers: Array<UpdateQuestionnaireAnswerInputItem>
}

export type UpdateQuestionnaireAnswerInputItem = {
  answer: Array<number>
}

export type UpdateScheduleAnswerInput = {
  mailId: string
  memberId: string
  scheduleAnswers: Array<YesNoAnswer>
}

export type MarkMailAsReadInput = {
  mailId: string
  memberId: string
}

export type CreateMailInput = {
  teamId: string
  tempMailId: string
  mailType: MailType
  title: string
  body: string
  sendTo: SendToInput
  attachmentFileNames?: Array<string> | null
  startDate?: string | null
  startTime?: string | null
  endDate?: string | null
  endTime?: string | null
  questionnaires?: Array<QuestionnaireInput> | null
  candidateDatetimes?: Array<CandidateDatetimesInput> | null
  shareManagerFlg?: boolean | null
  showAnswerFlg?: boolean | null
  responseDueDatetime?: string | null
  remindDatetime?: string | null
  sendScheduledAt?: string | null
  mailSenderId?: string | null
}

export enum MailType {
  normal = "normal",
  attendance = "attendance",
  schedule = "schedule",
  questionnaire = "questionnaire",
}

export type QuestionnaireInput = {
  answerSelectType: AnswerSelectType
  question: string
  choices: Array<string>
}

export type CreateMailResponse = {
  __typename: "CreateMailResponse"
  id: string
  type: CreateMailResponseType
}

export enum CreateMailResponseType {
  normal = "normal",
  reserved = "reserved",
}

export type UpdateMailInput = {
  id: string
  title?: string | null
  body?: string | null
  attachmentFileNames?: Array<string> | null
  mailSenderId?: string | null
}

export type DeleteMailInput = {
  id: string
}

export type SendReminderInput = {
  mailId: string
}

export type GetOrCreateTempMailIdInput = {
  teamId: string
}

export type MultiMailInput = {
  teamMemberId: string
  mailIds: Array<string>
}

export type CreateDraftInput = {
  teamId: string
  tempMailId: string
  mailType: MailType
  title: string
  body: string
  sendTo: SendToInput
  attachmentFileNames?: Array<string> | null
  startDate?: string | null
  startTime?: string | null
  endDate?: string | null
  endTime?: string | null
  questionnaires?: Array<QuestionnaireInput> | null
  candidateDatetimes?: Array<CandidateDatetimesInput> | null
  shareManagerFlg?: number | null
  showAnswerFlg?: boolean | null
  responseDueDatetime?: string | null
  remindDatetime?: string | null
  sendScheduledAt?: string | null
  teamMemberDraftsId?: string | null
}

export type CreateTemplateInput = {
  mailId: string
  teamMemberTemplatesId?: string | null
}

export type CreateThreadMessageInput = {
  threadId: string
  messageSenderId: string
  messageText: string
}

export type UpdateThreadMessageInput = {
  id: string
  text?: string | null
  unreadFlg?: number | null
  deleteFlg?: boolean | null
}

export type DeleteTeamImageInput = {
  teamId: string
}

export type DeleteTeamMemberImageInput = {
  teamMemberId: string
}

export type DeleteMatchingTeamImageInput = {
  teamId: string
}

export type DeleteMatchingTeamBgImageInput = {
  teamId: string
}

export type MigrateTeamInput = {
  oldRa9Ra9AccessToken: string
  oldRa9Ra9UserId: number
  oldRa9Ra9TeamId: number
  oldRa9Ra9MemberId: number
}

export type MigrateTeamResponse = {
  __typename: "MigrateTeamResponse"
  newTeamId: string
  invitedMemberCount: number
  uninvitedMembers: Array<MigrateTeamUninvitedMember>
}

export type MigrateTeamUninvitedMember = {
  __typename: "MigrateTeamUninvitedMember"
  memberId: number
  nickname: string
  reason: string
}

export type MigrateMailInput = {
  teamMemberId: string
  oldRa9Ra9AccessToken: string
  oldRa9Ra9UserId: number
  oldRa9Ra9TeamId: number
  oldRa9Ra9MemberId: number
}

export type CreateTeamMemberFromPresetInput = {
  teamId: string
  email: string
}

export type GetOrCreateTempMatchingOfferIdInput = {
  teamId: string
}

export type CreateMatchingOfferInput2 = {
  teamId: string
  tempMatchingOfferId: string
  purpose: MatchingOfferPurpose
  title: string
  body: string
  date?: string | null
  startTime?: string | null
  endTime?: string | null
  prefectureId: string
  cityId?: string | null
  place?: string | null
  placeURL?: string | null
  expense?: number | null
  expensePurpose?: string | null
  ageFrom?: number | null
  ageTo?: number | null
  offerCount?: number | null
  publishEndDate: string
  attachmentFileNames?: Array<string> | null
}

export type CreateMatchingOfferDraftInput2 = {
  teamId: string
  tempMatchingOfferId: string
  purpose?: MatchingOfferPurpose | null
  title: string
  body?: string | null
  date?: string | null
  startTime?: string | null
  endTime?: string | null
  prefectureId?: string | null
  cityId?: string | null
  place?: string | null
  placeURL?: string | null
  expense?: number | null
  expensePurpose?: string | null
  ageFrom?: number | null
  ageTo?: number | null
  offerCount?: number | null
  publishEndDate?: string | null
}

export type CreateMatchingApplicationInput3 = {
  applicantTeamId: string
  matchingOfferId: string
  firstMessage: string
}

export type CreateMatchingMessageInput = {
  matchingApplicationId: string
  messageSenderTeamId: string
  text?: string | null
}

export type UpdateMatchingMessageInput = {
  id: string
  text?: string | null
  unreadFlg?: number | null
  deleteFlg?: number | null
}

export type MarkMatchingMessageAsReadInput = {
  matchingApplicationId?: string | null
  matchingMessageId?: string | null
  teamId: string
}

export type ReportMatchingTeamInput = {
  reporterTeamId: string
  teamId: string
  content?: string | null
}

export type CreateInquiryInput = {
  content?: string | null
  teamId?: string | null
  make?: string | null
  model?: string | null
  timezone?: string | null
  locale?: string | null
  appVersion?: string | null
  platform?: string | null
  platformVersion?: string | null
  userAgent?: string | null
  ipAddress?: string | null
  agreedToPrivacyPolicy: boolean
  deviceInfo?: string | null
}

export type AgreeToTermsInput = {
  type?: TermsType | null
  version: string
}

export enum TermsType {
  service = "service",
  matching = "matching",
}

export type CreateAccountAdminNoticeInput = {
  adminNoticeId: string
}

export type CreatePaidVersionOrderInput = {
  orderType: OrderType
  companyName?: string | null
  companyNameKana?: string | null
  corporateRepresentativeName?: string | null
  corporateRepresentativeNameKana?: string | null
  applicantPhone: string
  applicantMail: string
  applicantName: string
  applicantPostcode?: string | null
  applicantPrefecture?: string | null
  applicantAddress?: string | null
  applicantBuildingName?: string | null
  applicantRemark?: string | null
  teamId: string
  teamName?: string | null
  planId: string
  startDate: string
  paymentType: OrderPaymentType
  paymentMethod: OrderPaymentMethodType
  invoiceAddressee: string
  invoiceAddresseeKana: string
}

export enum OrderType {
  private = "private",
  corporation = "corporation",
}

export enum OrderPaymentType {
  prepaid = "prepaid",
  postpaid = "postpaid",
}

export enum OrderPaymentMethodType {
  BANK_TRANSFER = "BANK_TRANSFER",
}

export type ResendEmailPaidVersionOrderInput = {
  orderId: string
  type: ResendEmailOrderType
}

export enum ResendEmailOrderType {
  create = "create",
  extend = "extend",
  cancel = "cancel",
}

export type UpdatePaidVersionOrderInput = {
  teamId: string
  transferStatusId?: string | null
  corporateRepresentativeName?: string | null
  corporateRepresentativeNameKana?: string | null
  applicantPhone: string
  applicantPostcode: string
  applicantAddress: string
  applicantPrefecture: string
  applicantBuildingName: string
}

export type CancelPaidVersionOrderInput = {
  orderId: string
}

export type ChangePlanPaidVersionOrderInput = {
  orderId: string
  planId: string
  startDate: string
  applicantRemark?: string | null
}

export type ExtendPaidVersionOrderInput = {
  orderId: string
  planId: string
  invoiceAddressee: string
  invoiceAddresseeKana: string
  applicantRemark?: string | null
}

export type ApplicantAuthorityTransferInput = {
  teamId: string
  newApplicantId: string
}

export type ModelNotificationSettingConditionInput = {
  and?: Array<ModelNotificationSettingConditionInput | null> | null
  or?: Array<ModelNotificationSettingConditionInput | null> | null
  not?: ModelNotificationSettingConditionInput | null
}

export type DeleteTempTeamInput = {
  accountId: string
}

export type ModelTempTeamConditionInput = {
  tempTeamId?: ModelIDInput | null
  and?: Array<ModelTempTeamConditionInput | null> | null
  or?: Array<ModelTempTeamConditionInput | null> | null
  not?: ModelTempTeamConditionInput | null
}

export type ModelIDInput = {
  ne?: string | null
  eq?: string | null
  le?: string | null
  lt?: string | null
  ge?: string | null
  gt?: string | null
  contains?: string | null
  notContains?: string | null
  between?: Array<string | null> | null
  beginsWith?: string | null
  attributeExists?: boolean | null
  attributeType?: ModelAttributeTypes | null
  size?: ModelSizeInput | null
}

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}

export type ModelSizeInput = {
  ne?: number | null
  eq?: number | null
  le?: number | null
  lt?: number | null
  ge?: number | null
  gt?: number | null
  between?: Array<number | null> | null
}

export type TempTeam = {
  __typename: "TempTeam"
  accountId: string
  tempTeamId: string
  createdAt: string
  updatedAt: string
}

export type ModelTeamConditionInput = {
  name?: ModelStringInput | null
  description?: ModelStringInput | null
  requirementForMemberProfile?: ModelStringInput | null
  customQuestions?: ModelTeamCustomQuestionListInput | null
  showMemberListFlg?: ModelBooleanInput | null
  invitationCode?: ModelStringInput | null
  migrationId?: ModelIntInput | null
  paidFunctionEnabled?: ModelBooleanInput | null
  showAdsFlg?: ModelBooleanInput | null
  and?: Array<ModelTeamConditionInput | null> | null
  or?: Array<ModelTeamConditionInput | null> | null
  not?: ModelTeamConditionInput | null
  teamCategoryId?: ModelIDInput | null
  teamActivityId?: ModelIDInput | null
  teamPrefectureId?: ModelIDInput | null
  teamCityId?: ModelIDInput | null
}

export type ModelStringInput = {
  ne?: string | null
  eq?: string | null
  le?: string | null
  lt?: string | null
  ge?: string | null
  gt?: string | null
  contains?: string | null
  notContains?: string | null
  between?: Array<string | null> | null
  beginsWith?: string | null
  attributeExists?: boolean | null
  attributeType?: ModelAttributeTypes | null
  size?: ModelSizeInput | null
}

export type ModelTeamCustomQuestionListInput = {
  eq?: Array<TeamCustomQuestion | null> | null
  ne?: Array<TeamCustomQuestion | null> | null
  contains?: TeamCustomQuestion | null
  notContains?: TeamCustomQuestion | null
}

export type ModelBooleanInput = {
  ne?: boolean | null
  eq?: boolean | null
  attributeExists?: boolean | null
  attributeType?: ModelAttributeTypes | null
}

export type ModelIntInput = {
  ne?: number | null
  eq?: number | null
  le?: number | null
  lt?: number | null
  ge?: number | null
  gt?: number | null
  between?: Array<number | null> | null
  attributeExists?: boolean | null
  attributeType?: ModelAttributeTypes | null
}

export type UpdateTeamNoticeInput = {
  id: string
  noticeType?: string | null
  title?: string | null
  readFlg?: boolean | null
  url?: string | null
  createdAt?: string | null
  createdYearMonth?: number | null
  createdDate?: string | null
  accountTeamNoticesId?: string | null
  teamMemberTeamNoticeId?: string | null
  teamNoticeTeamId?: string | null
}

export type ModelTeamNoticeConditionInput = {
  noticeType?: ModelStringInput | null
  title?: ModelStringInput | null
  readFlg?: ModelBooleanInput | null
  url?: ModelStringInput | null
  createdAt?: ModelStringInput | null
  createdYearMonth?: ModelIntInput | null
  createdDate?: ModelStringInput | null
  accountTeamNoticesId?: ModelIDInput | null
  and?: Array<ModelTeamNoticeConditionInput | null> | null
  or?: Array<ModelTeamNoticeConditionInput | null> | null
  not?: ModelTeamNoticeConditionInput | null
  teamMemberTeamNoticeId?: ModelIDInput | null
  teamNoticeTeamId?: ModelIDInput | null
}

export type DeleteTempMailInput = {
  teamMemberId: string
}

export type ModelTempMailConditionInput = {
  tempMailId?: ModelIDInput | null
  and?: Array<ModelTempMailConditionInput | null> | null
  or?: Array<ModelTempMailConditionInput | null> | null
  not?: ModelTempMailConditionInput | null
}

export type TempMail = {
  __typename: "TempMail"
  teamMemberId: string
  tempMailId: string
  createdAt: string
  updatedAt: string
}

export type ModelTeamMemberMailConditionInput = {
  receivedFlg?: ModelBooleanInput | null
  sentFlg?: ModelBooleanInput | null
  readFlg?: ModelBooleanInput | null
  readAt?: ModelStringInput | null
  favoriteFlgReceived?: ModelBooleanInput | null
  favoriteFlgSent?: ModelBooleanInput | null
  answerRequiredFlg?: ModelBooleanInput | null
  responseDueDatetimeForFilter?: ModelStringInput | null
  answeredFlg?: ModelBooleanInput | null
  answeredAt?: ModelStringInput | null
  attendanceAnswer?: ModelYesNoAnswerInput | null
  scheduleAnswers?: ModelYesNoAnswerListInput | null
  senderId?: ModelIDInput | null
  mailType?: ModelStringInput | null
  title?: ModelStringInput | null
  body?: ModelStringInput | null
  hasAttachment?: ModelBooleanInput | null
  hasThread?: ModelBooleanInput | null
  trashedFlgReceived?: ModelBooleanInput | null
  deletedFlgReceived?: ModelBooleanInput | null
  deletedFlgSent?: ModelBooleanInput | null
  count?: ModelIntInput | null
  teamMemberID?: ModelIDInput | null
  mailID?: ModelIDInput | null
  createdYearMonth?: ModelIntInput | null
  createdDate?: ModelStringInput | null
  and?: Array<ModelTeamMemberMailConditionInput | null> | null
  or?: Array<ModelTeamMemberMailConditionInput | null> | null
  not?: ModelTeamMemberMailConditionInput | null
}

export type ModelYesNoAnswerInput = {
  eq?: YesNoAnswer | null
  ne?: YesNoAnswer | null
}

export type ModelYesNoAnswerListInput = {
  eq?: Array<YesNoAnswer | null> | null
  ne?: Array<YesNoAnswer | null> | null
  contains?: YesNoAnswer | null
  notContains?: YesNoAnswer | null
}

export type DeleteTeamMemberMailInput = {
  id: string
}

export type ModelReservedMailConditionInput = {
  teamId?: ModelIDInput | null
  mailType?: ModelStringInput | null
  title?: ModelStringInput | null
  body?: ModelStringInput | null
  attachmentFileNames?: ModelStringInput | null
  startDate?: ModelStringInput | null
  startTime?: ModelStringInput | null
  endDate?: ModelStringInput | null
  endTime?: ModelStringInput | null
  shareManagerFlg?: ModelIntInput | null
  showAnswerFlg?: ModelBooleanInput | null
  responseDueDatetime?: ModelStringInput | null
  remindDatetime?: ModelStringInput | null
  sendScheduledDate?: ModelStringInput | null
  sendScheduledAt?: ModelStringInput | null
  and?: Array<ModelReservedMailConditionInput | null> | null
  or?: Array<ModelReservedMailConditionInput | null> | null
  not?: ModelReservedMailConditionInput | null
  teamMemberReservedMailsId?: ModelIDInput | null
}

export type ModelDraftConditionInput = {
  mailType?: ModelStringInput | null
  title?: ModelStringInput | null
  body?: ModelStringInput | null
  attachmentFileNames?: ModelStringInput | null
  startDate?: ModelStringInput | null
  startTime?: ModelStringInput | null
  endDate?: ModelStringInput | null
  endTime?: ModelStringInput | null
  shareManagerFlg?: ModelIntInput | null
  showAnswerFlg?: ModelBooleanInput | null
  responseDueDatetime?: ModelStringInput | null
  remindDatetime?: ModelStringInput | null
  sendScheduledAt?: ModelStringInput | null
  and?: Array<ModelDraftConditionInput | null> | null
  or?: Array<ModelDraftConditionInput | null> | null
  not?: ModelDraftConditionInput | null
  teamMemberDraftsId?: ModelIDInput | null
}

export type ModelTemplateConditionInput = {
  mailType?: ModelStringInput | null
  title?: ModelStringInput | null
  body?: ModelStringInput | null
  startDate?: ModelStringInput | null
  startTime?: ModelStringInput | null
  endDate?: ModelStringInput | null
  endTime?: ModelStringInput | null
  shareManagerFlg?: ModelIntInput | null
  showAnswerFlg?: ModelBooleanInput | null
  favoriteFlg?: ModelBooleanInput | null
  and?: Array<ModelTemplateConditionInput | null> | null
  or?: Array<ModelTemplateConditionInput | null> | null
  not?: ModelTemplateConditionInput | null
  teamMemberTemplatesId?: ModelIDInput | null
}

export type ModelMigratedMailConditionInput = {
  migrationId?: ModelIntInput | null
  title?: ModelStringInput | null
  body?: ModelStringInput | null
  sender?: ModelStringInput | null
  favoriteFlg?: ModelBooleanInput | null
  shareManagerFlg?: ModelBooleanInput | null
  sentDatetime?: ModelStringInput | null
  and?: Array<ModelMigratedMailConditionInput | null> | null
  or?: Array<ModelMigratedMailConditionInput | null> | null
  not?: ModelMigratedMailConditionInput | null
  teamMemberMigratedMailsId?: ModelIDInput | null
}

export type CreateMatchingTeamProfileInput = {
  id?: string | null
  ageFrom: number
  ageTo: number
  introduction: string
  teamUrl?: string | null
}

export type ModelMatchingTeamProfileConditionInput = {
  ageFrom?: ModelIntInput | null
  ageTo?: ModelIntInput | null
  introduction?: ModelStringInput | null
  teamUrl?: ModelStringInput | null
  and?: Array<ModelMatchingTeamProfileConditionInput | null> | null
  or?: Array<ModelMatchingTeamProfileConditionInput | null> | null
  not?: ModelMatchingTeamProfileConditionInput | null
}

export type MatchingTeamProfile = {
  __typename: "MatchingTeamProfile"
  id: string
  ageFrom: number
  ageTo: number
  introduction: string
  teamUrl?: string | null
  createdAt: string
  updatedAt: string
}

export type UpdateMatchingTeamProfileInput = {
  id: string
  ageFrom?: number | null
  ageTo?: number | null
  introduction?: string | null
  teamUrl?: string | null
}

export type ModelMatchingOfferConditionInput = {
  purpose?: ModelMatchingOfferPurposeInput | null
  title?: ModelStringInput | null
  body?: ModelStringInput | null
  date?: ModelStringInput | null
  startTime?: ModelStringInput | null
  endTime?: ModelStringInput | null
  place?: ModelStringInput | null
  placeURL?: ModelStringInput | null
  expense?: ModelIntInput | null
  expensePurpose?: ModelStringInput | null
  ageFrom?: ModelIntInput | null
  ageTo?: ModelIntInput | null
  offerCount?: ModelIntInput | null
  publishEndDate?: ModelStringInput | null
  attachmentFileNames?: ModelStringInput | null
  teamId?: ModelStringInput | null
  teamName?: ModelStringInput | null
  deleteFlg?: ModelBooleanInput | null
  and?: Array<ModelMatchingOfferConditionInput | null> | null
  or?: Array<ModelMatchingOfferConditionInput | null> | null
  not?: ModelMatchingOfferConditionInput | null
  teamMatchingOffersId?: ModelIDInput | null
  matchingOfferPrefectureId?: ModelIDInput | null
  matchingOfferCityId?: ModelIDInput | null
  matchingOfferActivityId?: ModelIDInput | null
}

export type ModelMatchingOfferPurposeInput = {
  eq?: MatchingOfferPurpose | null
  ne?: MatchingOfferPurpose | null
}

export type DeleteMatchingOfferInput = {
  id: string
}

export type ModelMatchingOfferDraftConditionInput = {
  purpose?: ModelMatchingOfferPurposeInput | null
  title?: ModelStringInput | null
  body?: ModelStringInput | null
  date?: ModelStringInput | null
  startTime?: ModelStringInput | null
  endTime?: ModelStringInput | null
  place?: ModelStringInput | null
  placeURL?: ModelStringInput | null
  expense?: ModelIntInput | null
  expensePurpose?: ModelStringInput | null
  ageFrom?: ModelIntInput | null
  ageTo?: ModelIntInput | null
  offerCount?: ModelIntInput | null
  publishEndDate?: ModelStringInput | null
  and?: Array<ModelMatchingOfferDraftConditionInput | null> | null
  or?: Array<ModelMatchingOfferDraftConditionInput | null> | null
  not?: ModelMatchingOfferDraftConditionInput | null
  teamMatchingOfferDraftsId?: ModelIDInput | null
  matchingOfferDraftPrefectureId?: ModelIDInput | null
  matchingOfferDraftCityId?: ModelIDInput | null
}

export type DeleteTempMatchingOfferInput = {
  teamMemberId: string
}

export type ModelTempMatchingOfferConditionInput = {
  tempMatchingOfferId?: ModelIDInput | null
  and?: Array<ModelTempMatchingOfferConditionInput | null> | null
  or?: Array<ModelTempMatchingOfferConditionInput | null> | null
  not?: ModelTempMatchingOfferConditionInput | null
}

export type TempMatchingOffer = {
  __typename: "TempMatchingOffer"
  teamMemberId: string
  tempMatchingOfferId: string
  createdAt: string
  updatedAt: string
}

export type ModelFavoriteMatchingOfferConditionInput = {
  and?: Array<ModelFavoriteMatchingOfferConditionInput | null> | null
  or?: Array<ModelFavoriteMatchingOfferConditionInput | null> | null
  not?: ModelFavoriteMatchingOfferConditionInput | null
  teamMemberFavoriteMatchingOfferId?: ModelIDInput | null
  favoriteMatchingOfferOfferId?: ModelIDInput | null
}

export type UpdateMatchingApplicationInput = {
  id: string
  offerTeamName?: string | null
  offerTitle?: string | null
  offerBody?: string | null
  teamMatchingApplicationsId?: string | null
  matchingOfferApplicationsId?: string | null
}

export type ModelMatchingApplicationConditionInput = {
  offerTeamName?: ModelStringInput | null
  offerTitle?: ModelStringInput | null
  offerBody?: ModelStringInput | null
  and?: Array<ModelMatchingApplicationConditionInput | null> | null
  or?: Array<ModelMatchingApplicationConditionInput | null> | null
  not?: ModelMatchingApplicationConditionInput | null
  teamMatchingApplicationsId?: ModelIDInput | null
  matchingOfferApplicationsId?: ModelIDInput | null
}

export type CreateBlockingMatchingTeamInput = {
  id?: string | null
  blockingTeamId: string
  teamBlockingMatchingTeamsId?: string | null
}

export type ModelBlockingMatchingTeamConditionInput = {
  blockingTeamId?: ModelIDInput | null
  and?: Array<ModelBlockingMatchingTeamConditionInput | null> | null
  or?: Array<ModelBlockingMatchingTeamConditionInput | null> | null
  not?: ModelBlockingMatchingTeamConditionInput | null
  teamBlockingMatchingTeamsId?: ModelIDInput | null
}

export type DeleteBlockingMatchingTeamInput = {
  id: string
}

export type CreateTeamCategoryActivityInput = {
  id?: string | null
  teamCategoryMasterID: string
  teamActivityMasterID: string
}

export type ModelTeamCategoryActivityConditionInput = {
  teamCategoryMasterID?: ModelStringInput | null
  teamActivityMasterID?: ModelStringInput | null
  and?: Array<ModelTeamCategoryActivityConditionInput | null> | null
  or?: Array<ModelTeamCategoryActivityConditionInput | null> | null
  not?: ModelTeamCategoryActivityConditionInput | null
}

export type UpdateTeamCategoryActivityInput = {
  id: string
  teamCategoryMasterID?: string | null
  teamActivityMasterID?: string | null
}

export type DeleteTeamCategoryActivityInput = {
  id: string
}

export type AccountInfoResponse = {
  __typename: "AccountInfoResponse"
  id: string
  email: string
  lastName: string
  firstName: string
  lastNameKana: string
  firstNameKana: string
  phoneNumber?: string | null
  gender?: Gender | null
  birthday?: string | null
  accountJobId?: string | null
  accountSchoolId?: string | null
  accountSchoolDepartmentId?: string | null
  schoolGraduationYear?: number | null
  accountPrefectureId?: string | null
  accountCityId?: string | null
  questionAnswers?: Array<QuestionAnswer> | null
  termsAgreedAt?: string | null
  agreedTermsVersion?: string | null
  matchingTermsAgreedAt?: string | null
  agreedMatchingTermsVersion?: string | null
  signupStatus: SignupStatus
  createdAt: string
  updatedAt: string
}

export type QuestionAnswer = {
  __typename: "QuestionAnswer"
  question?: Label | null
  answer?: Label | null
}

export type Label = {
  __typename: "Label"
  id?: string | null
  label?: string | null
}

export type CanDeleteAccountResponse = {
  __typename: "CanDeleteAccountResponse"
  canDelete: boolean
  message: string
}

export type ListAllMigratedTeamsResponse = {
  __typename: "ListAllMigratedTeamsResponse"
  migratedTeams?: Array<MigratedTeamType | null> | null
}

export type MigratedTeamType = {
  __typename: "MigratedTeamType"
  id: string
  name: string
  invitationCode: string
  migrationId: number
  joinFlg: boolean
  image: GetTeamImageGetUrlResponse
  role: string
  createdAt: string
  updatedAt: string
}

export type GetTeamImageGetUrlResponse = {
  __typename: "GetTeamImageGetUrlResponse"
  url: string
  isDefault: boolean
}

export type ListNotJoinMembersInput = {
  teamId: string
  page?: number | null
  pageSize?: number | null
  notJoin?: boolean | null
}

export type ListNotJoinMembersResponse = {
  __typename: "ListNotJoinMembersResponse"
  notJoinMembers?: Array<NotJoinMemberType> | null
  total: number
  page: number
  pageSize: number
}

export type NotJoinMemberType = {
  __typename: "NotJoinMemberType"
  id: string
  email: string
  nickname: string
  nicknameKana: string
  role: string
  joinFlg: boolean
  image: GetTeamImageGetUrlResponse
}

export type ListAdminNoticeResponse = {
  __typename: "ListAdminNoticeResponse"
  items: Array<ListAdminNoticeResponseItem>
}

export type ListAdminNoticeResponseItem = {
  __typename: "ListAdminNoticeResponseItem"
  id: string
  label: string
  title: string
  priority?: AdminNoticePriority | null
  publishDatetime: string
  readFlg?: boolean | null
  createdAt: string
  updatedAt: string
}

export enum AdminNoticePriority {
  normal = "normal",
  important = "important",
}

export type GetTeamInvitationMessageInput = {
  teamId: string
}

export type GetTeamInvitationMessageResponse = {
  __typename: "GetTeamInvitationMessageResponse"
  title: string
  message: string
}

export type GetInvitationCodeByVolatileIdInput = {
  volatileInvitationId: string
}

export type GetInvitationCodeByVolatileIdResponse = {
  __typename: "GetInvitationCodeByVolatileIdResponse"
  invitationCode: string
}

export type GetTeamMemberCountInput = {
  teamId: string
}

export type GetTeamMemberCountResponse = {
  __typename: "GetTeamMemberCountResponse"
  memberCount: number
}

export type ListGroupInput = {
  teamId: string
}

export type ListGroupResponse = {
  __typename: "ListGroupResponse"
  items: Array<ListGroupItemResponse>
}

export type ListGroupItemResponse = {
  __typename: "ListGroupItemResponse"
  id: string
  name: string
}

export type GetUnreadReceivedMailExistenceInput = {
  teamMemberId: string
}

export type GetUnreadReceivedMailExistenceResponse = {
  __typename: "GetUnreadReceivedMailExistenceResponse"
  hasUnreadMail: boolean
}

export type ListReceivedTeamMemberMailInput = {
  mailId: string
}

export type ListReceivedTeamMemberMailResponse = {
  __typename: "ListReceivedTeamMemberMailResponse"
  mailType: MailType
  teamMemberMails: Array<ListTeamMemberMailItem>
}

export type ListTeamMemberMailItem = {
  __typename: "ListTeamMemberMailItem"
  showReadFlg: boolean
  readFlg?: boolean | null
  readAt?: string | null
  showAnsweredFlg: boolean
  answeredFlg?: boolean | null
  answeredAt?: string | null
  attendanceAnswer?: YesNoAnswer | null
  questionnaireAnswers?: Array<QuestionnaireAnswerItem> | null
  scheduleAnswers?: Array<YesNoAnswer> | null
  teamMember?: TeamMemberItem | null
}

export type QuestionnaireAnswerItem = {
  __typename: "QuestionnaireAnswerItem"
  answer: Array<number>
}

export type TeamMemberItem = {
  __typename: "TeamMemberItem"
  id: string
  nickname: string
  memberSeq: string
  deleteFlg?: boolean | null
}

export type ListReservedTeamMemberMailInput = {
  reservedMailId: string
}

export type ListReservedTeamMemberMailResponse = {
  __typename: "ListReservedTeamMemberMailResponse"
  mailType: MailType
  teamMemberMails: Array<ListTeamMemberMailItem>
}

export type ListThreadInput = {
  mailId: string
  memberType?: ThreadMemberType | null
}

export enum ThreadMemberType {
  mailSender = "mailSender",
  mailRecipient = "mailRecipient",
}

export type ListThreadResponse = {
  __typename: "ListThreadResponse"
  items?: Array<ListThreadResponseItem> | null
}

export type ListThreadResponseItem = {
  __typename: "ListThreadResponseItem"
  member?: ListThreadResponseMember | null
  lastMessage?: ListThreadResponseLastMessage | null
}

export type ListThreadResponseMember = {
  __typename: "ListThreadResponseMember"
  id: string
  nickname: string
  deleteFlg?: boolean | null
}

export type ListThreadResponseLastMessage = {
  __typename: "ListThreadResponseLastMessage"
  text: string
  createdAt?: string | null
}

export type GetTeamMemberIdInput = {
  mailId: string
}

export type GetEmptyThreadInput = {
  threadId: string
}

export type GetEmptyThreadResponse = {
  __typename: "GetEmptyThreadResponse"
  mail: ThreadMail
  mailSender: ThreadMember
  mailRecipient: ThreadMember
}

export type ThreadMail = {
  __typename: "ThreadMail"
  id: string
  title: string
}

export type ThreadMember = {
  __typename: "ThreadMember"
  id: string
  nickname: string
  deleteFlg?: boolean | null
}

export type GetAccountImageGetUrlInput = {
  thumbnail?: boolean | null
}

export type GetAccountImageGetUrlResponse = {
  __typename: "GetAccountImageGetUrlResponse"
  url: string
  isDefault: boolean
}

export type GetAccountImagePutUrlInput = {
  extension: ImageExtension
}

export enum ImageExtension {
  jpg = "jpg",
  jpeg = "jpeg",
  gif = "gif",
  png = "png",
}

export type GetAccountImagePutUrlResponse = {
  __typename: "GetAccountImagePutUrlResponse"
  url: string
  thumbnailUrl: string
}

export type GetTeamImageGetUrlInput = {
  teamId: string
  thumbnail?: boolean | null
}

export type MultiTeamImageGetUrlInput = {
  teamImages: Array<GetTeamImageGetUrlInput>
}

export type MultiTeamImageGetUrlResponse = {
  __typename: "MultiTeamImageGetUrlResponse"
  teamImages: Array<TeamImageResponse>
}

export type TeamImageResponse = {
  __typename: "TeamImageResponse"
  teamId: string
  thumbnail?: boolean | null
  image?: GetTeamImageGetUrlResponse | null
}

export type GetTeamImagePutUrlInput = {
  teamId: string
  extension: ImageExtension
}

export type GetTeamImagePutUrlResponse = {
  __typename: "GetTeamImagePutUrlResponse"
  url: string
  thumbnailUrl: string
}

export type GetTeamMemberImageGetUrlInput = {
  teamMemberId: string
  thumbnail?: boolean | null
  isPreTeamMember?: boolean | null
}

export type GetTeamMemberImageGetUrlResponse = {
  __typename: "GetTeamMemberImageGetUrlResponse"
  url: string
  isDefault: boolean
}

export type MultiTeamMemberImageGetUrlInput = {
  teamMemberImages: Array<GetTeamMemberImageGetUrlInput>
}

export type MultiTeamMemberImageGetUrlResponse = {
  __typename: "MultiTeamMemberImageGetUrlResponse"
  teamMemberImages: Array<TeamMemberImageResponse>
}

export type TeamMemberImageResponse = {
  __typename: "TeamMemberImageResponse"
  teamMemberId: string
  thumbnail?: boolean | null
  isPreTeamMember?: boolean | null
  image?: GetTeamMemberImageGetUrlResponse | null
}

export type GetTeamMemberImagePutUrlInput = {
  teamMemberId: string
  extension: ImageExtension
}

export type GetTeamMemberImagePutUrlResponse = {
  __typename: "GetTeamMemberImagePutUrlResponse"
  url: string
  thumbnailUrl: string
}

export type GetMailAttachmentUrlInput = {
  teamId: string
  mailId: string
  index: number
  fileName: string
}

export type MailAttachmentUrlResponse = {
  __typename: "MailAttachmentUrlResponse"
  url: string
}

export type GetMatchingTeamImageGetUrlInput = {
  teamId: string
  thumbnail?: boolean | null
}

export type GetMatchingTeamImageGetUrlResponse = {
  __typename: "GetMatchingTeamImageGetUrlResponse"
  url: string
  isDefault: boolean
}

export type GetMatchingTeamImagePutUrlInput = {
  teamId: string
  extension: ImageExtension
}

export type GetMatchingTeamImagePutUrlResponse = {
  __typename: "GetMatchingTeamImagePutUrlResponse"
  url: string
  thumbnailUrl: string
}

export type GetMatchingTeamBgImageGetUrlInput = {
  teamId: string
}

export type GetMatchingTeamBgImageGetUrlResponse = {
  __typename: "GetMatchingTeamBgImageGetUrlResponse"
  url: string
  isDefault: boolean
}

export type GetMatchingTeamBgImagePutUrlInput = {
  teamId: string
  extension: ImageExtension
}

export type GetMatchingTeamBgImagePutUrlResponse = {
  __typename: "GetMatchingTeamBgImagePutUrlResponse"
  url: string
}

export type GetMatchingOfferAttachmentUrlInput = {
  teamId: string
  offerId: string
  index: number
  fileName: string
}

export type MatchingOfferAttachmentUrlResponse = {
  __typename: "MatchingOfferAttachmentUrlResponse"
  url: string
}

export type getUnreadMatchingMessageForTeamCountInput = {
  teamId: string
}

export type getUnreadMatchingMessageForTeamCountResponse = {
  __typename: "getUnreadMatchingMessageForTeamCountResponse"
  matchingOffer: UnreadMatchingMessageCount
  matchingApplication: UnreadMatchingMessageCount
}

export type UnreadMatchingMessageCount = {
  __typename: "UnreadMatchingMessageCount"
  haveUnreadMessage: boolean
  unreadMatchingApplicationCount?: Array<UnreadMatchingApplicationCount> | null
}

export type UnreadMatchingApplicationCount = {
  __typename: "UnreadMatchingApplicationCount"
  id?: string | null
  count?: number | null
}

export type MultiAccountImageGetUrlInput = {
  accountIds: Array<string>
  thumbnail?: boolean | null
}

export type MultiAccountImageGetUrlResponse = {
  __typename: "MultiAccountImageGetUrlResponse"
  images?: Array<ImageAccount> | null
}

export type ImageAccount = {
  __typename: "ImageAccount"
  accountId: string
  url: string
}

export type GetMatchingTeamProfileInput = {
  teamId: string
  myTeamId: string
}

export type GetMatchingTeamProfileResponse = {
  __typename: "GetMatchingTeamProfileResponse"
  id: string
  ageFrom: number
  ageTo: number
  introduction: string
  teamUrl?: string | null
  name: string
  category: GetMatchingTeamProfileMasterType
  activity: GetMatchingTeamProfileMasterType
  prefecture: GetMatchingTeamProfileMasterType
  city: GetMatchingTeamProfileMasterType
  isBlocking: boolean
}

export type GetMatchingTeamProfileMasterType = {
  __typename: "GetMatchingTeamProfileMasterType"
  id: string
  label: string
}

export type MultiGetFavoriteOfferIdInput = {
  teamMemberId: string
  offerIds: Array<string>
}

export type MultiGetFavoriteOfferIdResponse = {
  __typename: "MultiGetFavoriteOfferIdResponse"
  favoriteOfferIds: Array<string>
}

export type GetLatestTermsResponse = {
  __typename: "GetLatestTermsResponse"
  service: TermsResponse
  matching: TermsResponse
}

export type TermsResponse = {
  __typename: "TermsResponse"
  version: string
  url: string
  releaseAt: string
}

export type MultiGetMigrationStatusInput = {
  oldRa9Ra9TeamIds: Array<number>
}

export type MultiGetMigrationStatusResponse = {
  __typename: "MultiGetMigrationStatusResponse"
  items: Array<MultiGetMigrationStatusItem>
}

export type MultiGetMigrationStatusItem = {
  __typename: "MultiGetMigrationStatusItem"
  oldRa9Ra9TeamId: number
  teamId?: string | null
  isMigrated?: boolean | null
}

export type ListNotificationSettingResponse = {
  __typename: "ListNotificationSettingResponse"
  items: Array<TeamNotificationSetting>
}

export type TeamNotificationSetting = {
  __typename: "TeamNotificationSetting"
  id: string
  teamId: string
  teamName?: string | null
  teamCategory?: TeamCategory | null
  teamProfileImage?: string | null
  role?: string | null
  mailReceivedNotice: MailReceivedNotice
  threadMessageReceivedNotice: ThreadMessageReceivedNotice
  joinRequestNotice: JoinRequestNotice
  teamMatchingNotice: TeamMatchingNotice
  createdAt: string
  updatedAt: string
}

export type TeamCategory = {
  __typename: "TeamCategory"
  id: string
  label?: string | null
}

export type GetTeamOrderInput = {
  teamId: string
}

export type GetTeamOrderResponse = {
  __typename: "GetTeamOrderResponse"
  usedOrder?: PaidVersionOrderDetail | null
  preOrder?: PaidVersionOrderDetail | null
}

export type PaidVersionOrderDetail = {
  __typename: "PaidVersionOrderDetail"
  order: PaidVersionOrderType
  plan: PlanItem
}

export type PaidVersionOrderType = {
  __typename: "PaidVersionOrderType"
  id: string
  orderNumber: string
  originalOrderId?: string | null
  orderStatus: string
  jobId?: string | null
  masterCode?: string | null
  zacStatus?: string | null
  supportedDate?: string | null
  address?: string | null
  orderType: string
  companyName?: string | null
  companyNameKana?: string | null
  corporateRepresentativeName?: string | null
  corporateRepresentativeNameKana?: string | null
  applicantPhone?: string | null
  applicantMail?: string | null
  applicantName?: string | null
  applicantPostcode?: string | null
  applicantPrefecture?: string | null
  applicantAddress?: string | null
  applicantBuildingName?: string | null
  applicantRemark?: string | null
  antiSocialCheck: string
  teamId?: string | null
  teamName?: string | null
  applicantId?: string | null
  transferApplicantId?: string | null
  planId: string
  startDate: string
  endDate: string
  paymentStatus: string
  paymentDate?: string | null
  paymentDeadline?: string | null
  invoiceIssuedDate?: string | null
  paymentType: string
  paymentMethod: string
  invoiceAddressee?: string | null
  invoiceAddresseeKana?: string | null
  amount: number
  taxRate: number
  tax: number
  subTotal: number
  remark?: string | null
  adminId?: string | null
  createdAt?: string | null
  updatedAt?: string | null
}

export type PlanItem = {
  __typename: "PlanItem"
  id: string
  planCode: string
  name: string
  type: string
  memberLimit?: number | null
  teamLimit?: number | null
  period: number
  amount: number
  planType: PlanType
}

export type GetNextPaidVersionOrderNumberResponse = {
  __typename: "GetNextPaidVersionOrderNumberResponse"
  nextNumber: string
}

export type listPlanResponse = {
  __typename: "listPlanResponse"
  items: Array<ListPlanItem>
}

export type ListPlanItem = {
  __typename: "ListPlanItem"
  id: string
  planCode: string
  name: string
  type: string
  memberLimit?: number | null
  teamLimit?: number | null
  period: number
  amount: number
  taxRate: number
  planType: PlanType
}

export type SearchTeamMemberInput = {
  teamId: string
  name?: string | null
  excludedIds: Array<string | null>
  excludedGroupId?: string | null
  excludedAllGroup?: boolean | null
  myGroup?: boolean | null
  limit: number
  page: number
  sortBy: TeamMemberSortBy
  orderBy: OrderBy
}

export enum TeamMemberSortBy {
  id = "id",
  memberSeq = "memberSeq",
  nickname = "nickname",
  nicknameKana = "nicknameKana",
  role = "role",
  showEmailFlg = "showEmailFlg",
  phoneNumber = "phoneNumber",
  showPhoneNumberFlg = "showPhoneNumberFlg",
  belongs = "belongs",
  showBelongsFlg = "showBelongsFlg",
  grade = "grade",
  teamIdGsi = "teamIdGsi",
  teamTeamMembersId = "teamTeamMembersId",
  teamMemberNotificationSettingId = "teamMemberNotificationSettingId",
  mailMigratedFlg = "mailMigratedFlg",
  deleteFlg = "deleteFlg",
  accountTeamMembersId = "accountTeamMembersId",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}

export enum OrderBy {
  ASC = "ASC",
  DESC = "DESC",
}

export type SearchTeamMemberResponse = {
  __typename: "SearchTeamMemberResponse"
  items: Array<TeamMemberType>
  total: number
  limit: number
  page: number
  sortBy: string
  orderBy: OrderBy
}

export type TeamMemberType = {
  __typename: "TeamMemberType"
  id?: string | null
  memberSeq: number
  nickname: string
  nicknameKana: string
  role: MemberRole
  showEmailFlg?: boolean | null
  phoneNumber?: string | null
  showPhoneNumberFlg?: boolean | null
  belongs?: string | null
  showBelongsFlg?: boolean | null
  grade?: number | null
  teamIdGsi: string
  teamTeamMembersId: string
  teamMemberNotificationSettingId?: string | null
  mailMigratedFlg?: boolean | null
  deleteFlg?: boolean | null
  accountTeamMembersId?: string | null
  createdAt?: string | null
  updatedAt?: string | null
  groups: Array<GroupTeamMemberType>
}

export enum MemberRole {
  general = "general",
  mailSender = "mailSender",
  manager = "manager",
}

export type GroupTeamMemberType = {
  __typename: "GroupTeamMemberType"
  id: string
  leaderFlg?: boolean | null
  teamMemberID?: string | null
  groupID?: string | null
  createdAt?: string | null
  updatedAt?: string | null
}

export type SearchGroupMemberInput = {
  teamId: string
  groupId: string
  name?: string | null
  limit: number
  page: number
  sortBy: GroupMemberSortBy
  orderBy: OrderBy
}

export enum GroupMemberSortBy {
  id = "id",
  memberSeq = "memberSeq",
  nickname = "nickname",
  nicknameKana = "nicknameKana",
  role = "role",
  leaderFlg = "leaderFlg",
}

export type SearchGroupMemberResponse = {
  __typename: "SearchGroupMemberResponse"
  items: Array<GroupMemberType>
  total: number
  limit: number
  page: number
  sortBy: string
  orderBy: OrderBy
}

export type GroupMemberType = {
  __typename: "GroupMemberType"
  id?: string | null
  memberSeq: number
  nickname: string
  nicknameKana: string
  accountTeamMembersId: string
  role: MemberRole
  leaderFlg?: boolean | null
}

export type SearchableInvitationEmailStatusFilterInput = {
  id?: SearchableIDFilterInput | null
  email?: SearchableStringFilterInput | null
  repicaDeliverId?: SearchableStringFilterInput | null
  repicaKeyField?: SearchableStringFilterInput | null
  repicaSentDate?: SearchableStringFilterInput | null
  errorMessage?: SearchableStringFilterInput | null
  createdAt?: SearchableStringFilterInput | null
  teamInvitationEmailStatusesId?: SearchableIDFilterInput | null
  updatedAt?: SearchableStringFilterInput | null
  status?: SearchableStringFilterInput | null
  and?: Array<SearchableInvitationEmailStatusFilterInput | null> | null
  or?: Array<SearchableInvitationEmailStatusFilterInput | null> | null
  not?: SearchableInvitationEmailStatusFilterInput | null
}

export type SearchableInvitationEmailStatusSortInput = {
  field?: SearchableInvitationEmailStatusSortableFields | null
  direction?: SearchableSortDirection | null
}

export enum SearchableInvitationEmailStatusSortableFields {
  id = "id",
  email = "email",
  repicaDeliverId = "repicaDeliverId",
  repicaKeyField = "repicaKeyField",
  repicaSentDate = "repicaSentDate",
  errorMessage = "errorMessage",
  createdAt = "createdAt",
  teamInvitationEmailStatusesId = "teamInvitationEmailStatusesId",
  updatedAt = "updatedAt",
}

export enum SearchableSortDirection {
  asc = "asc",
  desc = "desc",
}

export type SearchableInvitationEmailStatusAggregationInput = {
  name: string
  type: SearchableAggregateType
  field: SearchableInvitationEmailStatusAggregateField
}

export enum SearchableInvitationEmailStatusAggregateField {
  id = "id",
  email = "email",
  status = "status",
  repicaDeliverId = "repicaDeliverId",
  repicaKeyField = "repicaKeyField",
  repicaSentDate = "repicaSentDate",
  errorMessage = "errorMessage",
  createdAt = "createdAt",
  teamInvitationEmailStatusesId = "teamInvitationEmailStatusesId",
  updatedAt = "updatedAt",
}

export type SearchableInvitationEmailStatusConnection = {
  __typename: "SearchableInvitationEmailStatusConnection"
  items: Array<InvitationEmailStatus | null>
  nextToken?: string | null
  total?: number | null
  aggregateItems: Array<SearchableAggregateResult | null>
}

export type SearchableTeamMemberMailSortInput = {
  field?: SearchableTeamMemberMailSortableFields | null
  direction?: SearchableSortDirection | null
}

export enum SearchableTeamMemberMailSortableFields {
  id = "id",
  receivedFlg = "receivedFlg",
  sentFlg = "sentFlg",
  readFlg = "readFlg",
  readAt = "readAt",
  favoriteFlgReceived = "favoriteFlgReceived",
  favoriteFlgSent = "favoriteFlgSent",
  answerRequiredFlg = "answerRequiredFlg",
  responseDueDatetimeForFilter = "responseDueDatetimeForFilter",
  answeredFlg = "answeredFlg",
  answeredAt = "answeredAt",
  senderId = "senderId",
  mailType = "mailType",
  title = "title",
  body = "body",
  hasAttachment = "hasAttachment",
  hasThread = "hasThread",
  trashedFlgReceived = "trashedFlgReceived",
  deletedFlgReceived = "deletedFlgReceived",
  deletedFlgSent = "deletedFlgSent",
  count = "count",
  teamMemberID = "teamMemberID",
  mailID = "mailID",
  createdYearMonth = "createdYearMonth",
  createdDate = "createdDate",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}

export type SearchableThreadMessageSortInput = {
  field?: SearchableThreadMessageSortableFields | null
  direction?: SearchableSortDirection | null
}

export enum SearchableThreadMessageSortableFields {
  id = "id",
  messageSenderId = "messageSenderId",
  messageRecipientAccountId = "messageRecipientAccountId",
  teamId = "teamId",
  mailId = "mailId",
  text = "text",
  unreadFlg = "unreadFlg",
  deleteFlg = "deleteFlg",
  contentUpdatedAt = "contentUpdatedAt",
  createdAt = "createdAt",
  createdYearMonth = "createdYearMonth",
  createdDate = "createdDate",
  threadMessagesId = "threadMessagesId",
  updatedAt = "updatedAt",
}

export type SearchableMigratedMailSortInput = {
  field?: SearchableMigratedMailSortableFields | null
  direction?: SearchableSortDirection | null
}

export enum SearchableMigratedMailSortableFields {
  id = "id",
  migrationId = "migrationId",
  title = "title",
  body = "body",
  sender = "sender",
  favoriteFlg = "favoriteFlg",
  shareManagerFlg = "shareManagerFlg",
  sentDatetime = "sentDatetime",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  teamMemberMigratedMailsId = "teamMemberMigratedMailsId",
}

export type SearchableMigratedMailAggregationInput = {
  name: string
  type: SearchableAggregateType
  field: SearchableMigratedMailAggregateField
}

export enum SearchableMigratedMailAggregateField {
  id = "id",
  migrationId = "migrationId",
  title = "title",
  body = "body",
  sender = "sender",
  favoriteFlg = "favoriteFlg",
  shareManagerFlg = "shareManagerFlg",
  sentDatetime = "sentDatetime",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  teamMemberMigratedMailsId = "teamMemberMigratedMailsId",
}

export type SearchableMatchingOfferSortInput = {
  field?: SearchableMatchingOfferSortableFields | null
  direction?: SearchableSortDirection | null
}

export enum SearchableMatchingOfferSortableFields {
  id = "id",
  title = "title",
  body = "body",
  date = "date",
  startTime = "startTime",
  endTime = "endTime",
  place = "place",
  placeURL = "placeURL",
  expense = "expense",
  expensePurpose = "expensePurpose",
  ageFrom = "ageFrom",
  ageTo = "ageTo",
  offerCount = "offerCount",
  publishEndDate = "publishEndDate",
  attachmentFileNames = "attachmentFileNames",
  teamId = "teamId",
  teamName = "teamName",
  deleteFlg = "deleteFlg",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  teamMatchingOffersId = "teamMatchingOffersId",
  matchingOfferPrefectureId = "matchingOfferPrefectureId",
  matchingOfferCityId = "matchingOfferCityId",
  matchingOfferActivityId = "matchingOfferActivityId",
}

export type SearchableMatchingOfferAggregationInput = {
  name: string
  type: SearchableAggregateType
  field: SearchableMatchingOfferAggregateField
}

export enum SearchableMatchingOfferAggregateField {
  id = "id",
  purpose = "purpose",
  title = "title",
  body = "body",
  date = "date",
  startTime = "startTime",
  endTime = "endTime",
  place = "place",
  placeURL = "placeURL",
  expense = "expense",
  expensePurpose = "expensePurpose",
  ageFrom = "ageFrom",
  ageTo = "ageTo",
  offerCount = "offerCount",
  publishEndDate = "publishEndDate",
  attachmentFileNames = "attachmentFileNames",
  teamId = "teamId",
  teamName = "teamName",
  deleteFlg = "deleteFlg",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  teamMatchingOffersId = "teamMatchingOffersId",
  matchingOfferPrefectureId = "matchingOfferPrefectureId",
  matchingOfferCityId = "matchingOfferCityId",
  matchingOfferActivityId = "matchingOfferActivityId",
}

export type SearchableMatchingApplicationSortInput = {
  field?: SearchableMatchingApplicationSortableFields | null
  direction?: SearchableSortDirection | null
}

export enum SearchableMatchingApplicationSortableFields {
  id = "id",
  offerTeamName = "offerTeamName",
  offerTitle = "offerTitle",
  offerBody = "offerBody",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  teamMatchingApplicationsId = "teamMatchingApplicationsId",
  matchingOfferApplicationsId = "matchingOfferApplicationsId",
}

export type SearchableMatchingApplicationAggregationInput = {
  name: string
  type: SearchableAggregateType
  field: SearchableMatchingApplicationAggregateField
}

export enum SearchableMatchingApplicationAggregateField {
  id = "id",
  offerTeamName = "offerTeamName",
  offerTitle = "offerTitle",
  offerBody = "offerBody",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
  teamMatchingApplicationsId = "teamMatchingApplicationsId",
  matchingOfferApplicationsId = "matchingOfferApplicationsId",
}

export type SearchablePaidVersionOrderFilterInput = {
  id?: SearchableIDFilterInput | null
  orderNumber?: SearchableIDFilterInput | null
  originalOrderId?: SearchableIDFilterInput | null
  orderStatus?: SearchableStringFilterInput | null
  jobId?: SearchableIDFilterInput | null
  masterCode?: SearchableStringFilterInput | null
  zacStatus?: SearchableStringFilterInput | null
  supportedDate?: SearchableStringFilterInput | null
  address?: SearchableStringFilterInput | null
  orderType?: SearchableStringFilterInput | null
  companyName?: SearchableStringFilterInput | null
  companyNameKana?: SearchableStringFilterInput | null
  corporateRepresentativeName?: SearchableStringFilterInput | null
  corporateRepresentativeNameKana?: SearchableStringFilterInput | null
  applicantPhone?: SearchableStringFilterInput | null
  applicantMail?: SearchableStringFilterInput | null
  applicantName?: SearchableStringFilterInput | null
  applicantPostcode?: SearchableStringFilterInput | null
  applicantPrefecture?: SearchableStringFilterInput | null
  applicantAddress?: SearchableStringFilterInput | null
  applicantBuildingName?: SearchableStringFilterInput | null
  applicantRemark?: SearchableStringFilterInput | null
  antiSocialCheck?: SearchableStringFilterInput | null
  teamId?: SearchableIDFilterInput | null
  migrateTeamId?: SearchableIntFilterInput | null
  teamName?: SearchableStringFilterInput | null
  memberCount?: SearchableIntFilterInput | null
  applicantId?: SearchableIDFilterInput | null
  transferApplicantId?: SearchableIDFilterInput | null
  planId?: SearchableIDFilterInput | null
  startDate?: SearchableStringFilterInput | null
  endDate?: SearchableStringFilterInput | null
  paymentStatus?: SearchableStringFilterInput | null
  paymentDate?: SearchableStringFilterInput | null
  paymentDeadline?: SearchableStringFilterInput | null
  invoiceIssuedDate?: SearchableStringFilterInput | null
  paymentType?: SearchableStringFilterInput | null
  paymentMethod?: SearchableStringFilterInput | null
  invoiceAddressee?: SearchableStringFilterInput | null
  invoiceAddresseeKana?: SearchableStringFilterInput | null
  amount?: SearchableIntFilterInput | null
  taxRate?: SearchableIntFilterInput | null
  tax?: SearchableIntFilterInput | null
  subTotal?: SearchableIntFilterInput | null
  remark?: SearchableStringFilterInput | null
  adminId?: SearchableIDFilterInput | null
  createdAt?: SearchableStringFilterInput | null
  updatedAt?: SearchableStringFilterInput | null
  and?: Array<SearchablePaidVersionOrderFilterInput | null> | null
  or?: Array<SearchablePaidVersionOrderFilterInput | null> | null
  not?: SearchablePaidVersionOrderFilterInput | null
}

export type SearchablePaidVersionOrderSortInput = {
  field?: SearchablePaidVersionOrderSortableFields | null
  direction?: SearchableSortDirection | null
}

export enum SearchablePaidVersionOrderSortableFields {
  id = "id",
  orderNumber = "orderNumber",
  originalOrderId = "originalOrderId",
  orderStatus = "orderStatus",
  jobId = "jobId",
  masterCode = "masterCode",
  zacStatus = "zacStatus",
  supportedDate = "supportedDate",
  address = "address",
  orderType = "orderType",
  companyName = "companyName",
  companyNameKana = "companyNameKana",
  corporateRepresentativeName = "corporateRepresentativeName",
  corporateRepresentativeNameKana = "corporateRepresentativeNameKana",
  applicantPhone = "applicantPhone",
  applicantMail = "applicantMail",
  applicantName = "applicantName",
  applicantPostcode = "applicantPostcode",
  applicantPrefecture = "applicantPrefecture",
  applicantAddress = "applicantAddress",
  applicantBuildingName = "applicantBuildingName",
  applicantRemark = "applicantRemark",
  antiSocialCheck = "antiSocialCheck",
  teamId = "teamId",
  migrateTeamId = "migrateTeamId",
  teamName = "teamName",
  memberCount = "memberCount",
  applicantId = "applicantId",
  transferApplicantId = "transferApplicantId",
  planId = "planId",
  startDate = "startDate",
  endDate = "endDate",
  paymentStatus = "paymentStatus",
  paymentDate = "paymentDate",
  paymentDeadline = "paymentDeadline",
  invoiceIssuedDate = "invoiceIssuedDate",
  paymentType = "paymentType",
  paymentMethod = "paymentMethod",
  invoiceAddressee = "invoiceAddressee",
  invoiceAddresseeKana = "invoiceAddresseeKana",
  amount = "amount",
  taxRate = "taxRate",
  tax = "tax",
  subTotal = "subTotal",
  remark = "remark",
  adminId = "adminId",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}

export type SearchablePaidVersionOrderAggregationInput = {
  name: string
  type: SearchableAggregateType
  field: SearchablePaidVersionOrderAggregateField
}

export enum SearchablePaidVersionOrderAggregateField {
  id = "id",
  orderNumber = "orderNumber",
  originalOrderId = "originalOrderId",
  orderStatus = "orderStatus",
  jobId = "jobId",
  masterCode = "masterCode",
  zacStatus = "zacStatus",
  supportedDate = "supportedDate",
  address = "address",
  orderType = "orderType",
  companyName = "companyName",
  companyNameKana = "companyNameKana",
  corporateRepresentativeName = "corporateRepresentativeName",
  corporateRepresentativeNameKana = "corporateRepresentativeNameKana",
  applicantPhone = "applicantPhone",
  applicantMail = "applicantMail",
  applicantName = "applicantName",
  applicantPostcode = "applicantPostcode",
  applicantPrefecture = "applicantPrefecture",
  applicantAddress = "applicantAddress",
  applicantBuildingName = "applicantBuildingName",
  applicantRemark = "applicantRemark",
  antiSocialCheck = "antiSocialCheck",
  teamId = "teamId",
  migrateTeamId = "migrateTeamId",
  teamName = "teamName",
  memberCount = "memberCount",
  applicantId = "applicantId",
  transferApplicantId = "transferApplicantId",
  planId = "planId",
  startDate = "startDate",
  endDate = "endDate",
  paymentStatus = "paymentStatus",
  paymentDate = "paymentDate",
  paymentDeadline = "paymentDeadline",
  invoiceIssuedDate = "invoiceIssuedDate",
  paymentType = "paymentType",
  paymentMethod = "paymentMethod",
  invoiceAddressee = "invoiceAddressee",
  invoiceAddresseeKana = "invoiceAddresseeKana",
  amount = "amount",
  taxRate = "taxRate",
  tax = "tax",
  subTotal = "subTotal",
  remark = "remark",
  adminId = "adminId",
  createdAt = "createdAt",
  updatedAt = "updatedAt",
}

export type SearchablePaidVersionOrderConnection = {
  __typename: "SearchablePaidVersionOrderConnection"
  items: Array<PaidVersionOrder | null>
  nextToken?: string | null
  total?: number | null
  aggregateItems: Array<SearchableAggregateResult | null>
}

export type AdminNotice = {
  __typename: "AdminNotice"
  id: string
  label: string
  title: string
  content: string
  priority?: AdminNoticePriority | null
  publishMonth: number
  publishDatetime: string
  createdAt: string
  updatedAt: string
}

export type ModelAdminNoticeFilterInput = {
  id?: ModelIDInput | null
  label?: ModelStringInput | null
  title?: ModelStringInput | null
  content?: ModelStringInput | null
  priority?: ModelAdminNoticePriorityInput | null
  publishMonth?: ModelIntInput | null
  publishDatetime?: ModelStringInput | null
  and?: Array<ModelAdminNoticeFilterInput | null> | null
  or?: Array<ModelAdminNoticeFilterInput | null> | null
  not?: ModelAdminNoticeFilterInput | null
}

export type ModelAdminNoticePriorityInput = {
  eq?: AdminNoticePriority | null
  ne?: AdminNoticePriority | null
}

export type ModelAdminNoticeConnection = {
  __typename: "ModelAdminNoticeConnection"
  items: Array<AdminNotice | null>
  nextToken?: string | null
}

export type ModelAccountFilterInput = {
  id?: ModelIDInput | null
  email?: ModelStringInput | null
  lastName?: ModelStringInput | null
  firstName?: ModelStringInput | null
  lastNameKana?: ModelStringInput | null
  firstNameKana?: ModelStringInput | null
  phoneNumber?: ModelStringInput | null
  gender?: ModelGenderInput | null
  birthday?: ModelStringInput | null
  schoolGraduationYear?: ModelIntInput | null
  termsAgreedAt?: ModelStringInput | null
  agreedTermsVersion?: ModelStringInput | null
  matchingTermsAgreedAt?: ModelStringInput | null
  agreedMatchingTermsVersion?: ModelStringInput | null
  signupStatus?: ModelSignupStatusInput | null
  haveOldRa9TeamNotJoin?: ModelBooleanInput | null
  migrateAccountId?: ModelIntInput | null
  and?: Array<ModelAccountFilterInput | null> | null
  or?: Array<ModelAccountFilterInput | null> | null
  not?: ModelAccountFilterInput | null
  accountJobId?: ModelIDInput | null
  accountSchoolId?: ModelIDInput | null
  accountSchoolDepartmentId?: ModelIDInput | null
  accountPrefectureId?: ModelIDInput | null
  accountCityId?: ModelIDInput | null
}

export type ModelGenderInput = {
  eq?: Gender | null
  ne?: Gender | null
}

export type ModelSignupStatusInput = {
  eq?: SignupStatus | null
  ne?: SignupStatus | null
}

export type ModelAccountConnection = {
  __typename: "ModelAccountConnection"
  items: Array<Account | null>
  nextToken?: string | null
}

export type ModelPreAccountFilterInput = {
  email?: ModelStringInput | null
  createdYearMonth?: ModelIntInput | null
  createdDate?: ModelStringInput | null
  and?: Array<ModelPreAccountFilterInput | null> | null
  or?: Array<ModelPreAccountFilterInput | null> | null
  not?: ModelPreAccountFilterInput | null
}

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export type ModelPreAccountConnection = {
  __typename: "ModelPreAccountConnection"
  items: Array<PreAccount | null>
  nextToken?: string | null
}

export type PreAccount = {
  __typename: "PreAccount"
  email: string
  createdYearMonth?: number | null
  createdDate?: string | null
  createdAt: string
  updatedAt: string
}

export type ModelVolatileInvitationIdFilterInput = {
  id?: ModelIDInput | null
  invitationCode?: ModelStringInput | null
  createdYearMonth?: ModelIntInput | null
  createdDate?: ModelStringInput | null
  and?: Array<ModelVolatileInvitationIdFilterInput | null> | null
  or?: Array<ModelVolatileInvitationIdFilterInput | null> | null
  not?: ModelVolatileInvitationIdFilterInput | null
}

export type ModelVolatileInvitationIdConnection = {
  __typename: "ModelVolatileInvitationIdConnection"
  items: Array<VolatileInvitationId | null>
  nextToken?: string | null
}

export type VolatileInvitationId = {
  __typename: "VolatileInvitationId"
  id: string
  invitationCode: string
  createdYearMonth?: number | null
  createdDate?: string | null
  createdAt: string
  updatedAt: string
}

export type ModelInvitationEmailStatusFilterInput = {
  id?: ModelIDInput | null
  email?: ModelStringInput | null
  status?: ModelEmailStatusInput | null
  repicaDeliverId?: ModelStringInput | null
  repicaKeyField?: ModelStringInput | null
  repicaSentDate?: ModelStringInput | null
  errorMessage?: ModelStringInput | null
  createdAt?: ModelStringInput | null
  teamInvitationEmailStatusesId?: ModelIDInput | null
  and?: Array<ModelInvitationEmailStatusFilterInput | null> | null
  or?: Array<ModelInvitationEmailStatusFilterInput | null> | null
  not?: ModelInvitationEmailStatusFilterInput | null
}

export type ModelEmailStatusInput = {
  eq?: EmailStatus | null
  ne?: EmailStatus | null
}

export type ModelTeamNoticeFilterInput = {
  id?: ModelIDInput | null
  noticeType?: ModelStringInput | null
  title?: ModelStringInput | null
  readFlg?: ModelBooleanInput | null
  url?: ModelStringInput | null
  createdAt?: ModelStringInput | null
  createdYearMonth?: ModelIntInput | null
  createdDate?: ModelStringInput | null
  accountTeamNoticesId?: ModelIDInput | null
  and?: Array<ModelTeamNoticeFilterInput | null> | null
  or?: Array<ModelTeamNoticeFilterInput | null> | null
  not?: ModelTeamNoticeFilterInput | null
  teamMemberTeamNoticeId?: ModelIDInput | null
  teamNoticeTeamId?: ModelIDInput | null
}

export type ModelTeamMemberMailFilterInput = {
  id?: ModelIDInput | null
  receivedFlg?: ModelBooleanInput | null
  sentFlg?: ModelBooleanInput | null
  readFlg?: ModelBooleanInput | null
  readAt?: ModelStringInput | null
  favoriteFlgReceived?: ModelBooleanInput | null
  favoriteFlgSent?: ModelBooleanInput | null
  answerRequiredFlg?: ModelBooleanInput | null
  responseDueDatetimeForFilter?: ModelStringInput | null
  answeredFlg?: ModelBooleanInput | null
  answeredAt?: ModelStringInput | null
  attendanceAnswer?: ModelYesNoAnswerInput | null
  scheduleAnswers?: ModelYesNoAnswerListInput | null
  senderId?: ModelIDInput | null
  mailType?: ModelStringInput | null
  title?: ModelStringInput | null
  body?: ModelStringInput | null
  hasAttachment?: ModelBooleanInput | null
  hasThread?: ModelBooleanInput | null
  trashedFlgReceived?: ModelBooleanInput | null
  deletedFlgReceived?: ModelBooleanInput | null
  deletedFlgSent?: ModelBooleanInput | null
  count?: ModelIntInput | null
  teamMemberID?: ModelIDInput | null
  mailID?: ModelIDInput | null
  createdYearMonth?: ModelIntInput | null
  createdDate?: ModelStringInput | null
  and?: Array<ModelTeamMemberMailFilterInput | null> | null
  or?: Array<ModelTeamMemberMailFilterInput | null> | null
  not?: ModelTeamMemberMailFilterInput | null
}

export type ModelThreadMessageFilterInput = {
  id?: ModelIDInput | null
  messageSenderId?: ModelIDInput | null
  messageRecipientAccountId?: ModelIDInput | null
  teamId?: ModelIDInput | null
  mailId?: ModelIDInput | null
  text?: ModelStringInput | null
  unreadFlg?: ModelIntInput | null
  deleteFlg?: ModelBooleanInput | null
  contentUpdatedAt?: ModelStringInput | null
  createdAt?: ModelStringInput | null
  createdYearMonth?: ModelIntInput | null
  createdDate?: ModelStringInput | null
  threadMessagesId?: ModelIDInput | null
  and?: Array<ModelThreadMessageFilterInput | null> | null
  or?: Array<ModelThreadMessageFilterInput | null> | null
  not?: ModelThreadMessageFilterInput | null
}

export type ModelJobMasterFilterInput = {
  id?: ModelStringInput | null
  label?: ModelStringInput | null
  type?: ModelJobTypeInput | null
  displayNumber?: ModelIntInput | null
  displayFlg?: ModelIntInput | null
  and?: Array<ModelJobMasterFilterInput | null> | null
  or?: Array<ModelJobMasterFilterInput | null> | null
  not?: ModelJobMasterFilterInput | null
}

export type ModelJobTypeInput = {
  eq?: JobType | null
  ne?: JobType | null
}

export type ModelSchoolMasterFilterInput = {
  id?: ModelStringInput | null
  label?: ModelStringInput | null
  labelKana?: ModelStringInput | null
  type?: ModelStringInput | null
  code?: ModelStringInput | null
  displayNumber?: ModelIntInput | null
  displayFlg?: ModelIntInput | null
  and?: Array<ModelSchoolMasterFilterInput | null> | null
  or?: Array<ModelSchoolMasterFilterInput | null> | null
  not?: ModelSchoolMasterFilterInput | null
}

export type ModelPrefectureMasterFilterInput = {
  id?: ModelStringInput | null
  label?: ModelStringInput | null
  displayNumber?: ModelIntInput | null
  displayFlg?: ModelIntInput | null
  and?: Array<ModelPrefectureMasterFilterInput | null> | null
  or?: Array<ModelPrefectureMasterFilterInput | null> | null
  not?: ModelPrefectureMasterFilterInput | null
}

export type ModelTeamCategoryMasterFilterInput = {
  id?: ModelStringInput | null
  label?: ModelStringInput | null
  displayNumber?: ModelIntInput | null
  displayFlg?: ModelIntInput | null
  and?: Array<ModelTeamCategoryMasterFilterInput | null> | null
  or?: Array<ModelTeamCategoryMasterFilterInput | null> | null
  not?: ModelTeamCategoryMasterFilterInput | null
}

export type ModelAccountQuestionMasterFilterInput = {
  id?: ModelStringInput | null
  label?: ModelStringInput | null
  displayNumber?: ModelIntInput | null
  displayFlg?: ModelIntInput | null
  and?: Array<ModelAccountQuestionMasterFilterInput | null> | null
  or?: Array<ModelAccountQuestionMasterFilterInput | null> | null
  not?: ModelAccountQuestionMasterFilterInput | null
}

export type TeamMemberPreset = {
  __typename: "TeamMemberPreset"
  teamId: string
  email: string
  memberSeq: number
  nickname: string
  nicknameKana?: string | null
  role: MemberRole
  showEmailFlg?: boolean | null
  phoneNumber?: string | null
  showPhoneNumberFlg?: boolean | null
  belongs?: string | null
  showBelongsFlg?: boolean | null
  grade?: number | null
  groups?: Array<Groups> | null
  createdYearMonth?: number | null
  createdDate?: string | null
  migrateTeamMemberId?: number | null
  createdAt: string
  updatedAt: string
}

export type Groups = {
  __typename: "Groups"
  id: string
  leaderFlg: boolean
}

export type ModelStringKeyConditionInput = {
  eq?: string | null
  le?: string | null
  lt?: string | null
  ge?: string | null
  gt?: string | null
  between?: Array<string | null> | null
  beginsWith?: string | null
}

export type ModelTeamMemberPresetFilterInput = {
  teamId?: ModelIDInput | null
  email?: ModelStringInput | null
  memberSeq?: ModelIntInput | null
  nickname?: ModelStringInput | null
  nicknameKana?: ModelStringInput | null
  role?: ModelMemberRoleInput | null
  showEmailFlg?: ModelBooleanInput | null
  phoneNumber?: ModelStringInput | null
  showPhoneNumberFlg?: ModelBooleanInput | null
  belongs?: ModelStringInput | null
  showBelongsFlg?: ModelBooleanInput | null
  grade?: ModelIntInput | null
  createdYearMonth?: ModelIntInput | null
  createdDate?: ModelStringInput | null
  migrateTeamMemberId?: ModelIntInput | null
  and?: Array<ModelTeamMemberPresetFilterInput | null> | null
  or?: Array<ModelTeamMemberPresetFilterInput | null> | null
  not?: ModelTeamMemberPresetFilterInput | null
}

export type ModelMemberRoleInput = {
  eq?: MemberRole | null
  ne?: MemberRole | null
}

export type ModelTeamMemberPresetConnection = {
  __typename: "ModelTeamMemberPresetConnection"
  items: Array<TeamMemberPreset | null>
  nextToken?: string | null
}

export type ModelMatchingOfferFilterInput = {
  id?: ModelIDInput | null
  purpose?: ModelMatchingOfferPurposeInput | null
  title?: ModelStringInput | null
  body?: ModelStringInput | null
  date?: ModelStringInput | null
  startTime?: ModelStringInput | null
  endTime?: ModelStringInput | null
  place?: ModelStringInput | null
  placeURL?: ModelStringInput | null
  expense?: ModelIntInput | null
  expensePurpose?: ModelStringInput | null
  ageFrom?: ModelIntInput | null
  ageTo?: ModelIntInput | null
  offerCount?: ModelIntInput | null
  publishEndDate?: ModelStringInput | null
  attachmentFileNames?: ModelStringInput | null
  teamId?: ModelStringInput | null
  teamName?: ModelStringInput | null
  deleteFlg?: ModelBooleanInput | null
  and?: Array<ModelMatchingOfferFilterInput | null> | null
  or?: Array<ModelMatchingOfferFilterInput | null> | null
  not?: ModelMatchingOfferFilterInput | null
  teamMatchingOffersId?: ModelIDInput | null
  matchingOfferPrefectureId?: ModelIDInput | null
  matchingOfferCityId?: ModelIDInput | null
  matchingOfferActivityId?: ModelIDInput | null
}

export type ModelMaintenanceFilterInput = {
  maintenanceFlg?: ModelIntInput | null
  content?: ModelStringInput | null
  startAt?: ModelStringInput | null
  endAt?: ModelStringInput | null
  and?: Array<ModelMaintenanceFilterInput | null> | null
  or?: Array<ModelMaintenanceFilterInput | null> | null
  not?: ModelMaintenanceFilterInput | null
}

export type ModelMaintenanceConnection = {
  __typename: "ModelMaintenanceConnection"
  items: Array<Maintenance | null>
  nextToken?: string | null
}

export type Maintenance = {
  __typename: "Maintenance"
  maintenanceFlg: number
  content: string
  startAt: string
  endAt: string
  createdAt: string
  updatedAt: string
}

export type ModelAppVersionFilterInput = {
  version?: ModelStringInput | null
  osType?: ModelOsTypeInput | null
  osVersion?: ModelStringInput | null
  releaseAt?: ModelStringInput | null
  content?: ModelStringInput | null
  and?: Array<ModelAppVersionFilterInput | null> | null
  or?: Array<ModelAppVersionFilterInput | null> | null
  not?: ModelAppVersionFilterInput | null
}

export type ModelOsTypeInput = {
  eq?: OsType | null
  ne?: OsType | null
}

export enum OsType {
  ios = "ios",
  android = "android",
}

export type ModelAppVersionConnection = {
  __typename: "ModelAppVersionConnection"
  items: Array<AppVersion | null>
  nextToken?: string | null
}

export type AppVersion = {
  __typename: "AppVersion"
  version: string
  osType: OsType
  osVersion: string
  releaseAt: string
  content: string
  createdAt: string
  updatedAt: string
}

export type ModelTermsFilterInput = {
  version?: ModelStringInput | null
  type?: ModelTermsTypeInput | null
  validFlg?: ModelIntInput | null
  url?: ModelStringInput | null
  releaseAt?: ModelStringInput | null
  and?: Array<ModelTermsFilterInput | null> | null
  or?: Array<ModelTermsFilterInput | null> | null
  not?: ModelTermsFilterInput | null
}

export type ModelTermsTypeInput = {
  eq?: TermsType | null
  ne?: TermsType | null
}

export type ModelTermsConnection = {
  __typename: "ModelTermsConnection"
  items: Array<Terms | null>
  nextToken?: string | null
}

export type Terms = {
  __typename: "Terms"
  version: string
  type: TermsType
  validFlg: number
  url: string
  releaseAt: string
  createdAt: string
  updatedAt: string
}

export type ModelTempTokenFilterInput = {
  tempToken?: ModelIDInput | null
  accessToken?: ModelStringInput | null
  createdYearMonth?: ModelIntInput | null
  createdDate?: ModelStringInput | null
  and?: Array<ModelTempTokenFilterInput | null> | null
  or?: Array<ModelTempTokenFilterInput | null> | null
  not?: ModelTempTokenFilterInput | null
}

export type ModelTempTokenConnection = {
  __typename: "ModelTempTokenConnection"
  items: Array<TempToken | null>
  nextToken?: string | null
}

export type TempToken = {
  __typename: "TempToken"
  tempToken: string
  accessToken: string
  createdYearMonth?: number | null
  createdDate?: string | null
  createdAt: string
  updatedAt: string
}

export type HolidayCalendar = {
  __typename: "HolidayCalendar"
  id: string
  date: string
  name?: string | null
  content?: string | null
  createdAt: string
  updatedAt: string
}

export type Tax = {
  __typename: "Tax"
  id: string
  taxRate: number
  startDate: string
  endDate: string
  createdAt: string
  updatedAt: string
}

export type ModelTeamCategoryActivityFilterInput = {
  id?: ModelIDInput | null
  teamCategoryMasterID?: ModelStringInput | null
  teamActivityMasterID?: ModelStringInput | null
  and?: Array<ModelTeamCategoryActivityFilterInput | null> | null
  or?: Array<ModelTeamCategoryActivityFilterInput | null> | null
  not?: ModelTeamCategoryActivityFilterInput | null
}

export type ModelTeamFilterInput = {
  id?: ModelIDInput | null
  name?: ModelStringInput | null
  description?: ModelStringInput | null
  requirementForMemberProfile?: ModelStringInput | null
  customQuestions?: ModelTeamCustomQuestionListInput | null
  showMemberListFlg?: ModelBooleanInput | null
  invitationCode?: ModelStringInput | null
  migrationId?: ModelIntInput | null
  paidFunctionEnabled?: ModelBooleanInput | null
  showAdsFlg?: ModelBooleanInput | null
  and?: Array<ModelTeamFilterInput | null> | null
  or?: Array<ModelTeamFilterInput | null> | null
  not?: ModelTeamFilterInput | null
  teamCategoryId?: ModelIDInput | null
  teamActivityId?: ModelIDInput | null
  teamPrefectureId?: ModelIDInput | null
  teamCityId?: ModelIDInput | null
}

export type ModelTeamMemberFilterInput = {
  id?: ModelIDInput | null
  memberSeq?: ModelIntInput | null
  nickname?: ModelStringInput | null
  nicknameKana?: ModelStringInput | null
  role?: ModelStringInput | null
  showEmailFlg?: ModelBooleanInput | null
  phoneNumber?: ModelStringInput | null
  showPhoneNumberFlg?: ModelBooleanInput | null
  belongs?: ModelStringInput | null
  showBelongsFlg?: ModelBooleanInput | null
  grade?: ModelIntInput | null
  teamIdGsi?: ModelIDInput | null
  teamTeamMembersId?: ModelIDInput | null
  mailMigratedFlg?: ModelBooleanInput | null
  deleteFlg?: ModelBooleanInput | null
  accountTeamMembersId?: ModelIDInput | null
  migrateTeamMemberId?: ModelIntInput | null
  and?: Array<ModelTeamMemberFilterInput | null> | null
  or?: Array<ModelTeamMemberFilterInput | null> | null
  not?: ModelTeamMemberFilterInput | null
  teamMemberNotificationSettingId?: ModelIDInput | null
}

export type ModelIntKeyConditionInput = {
  eq?: number | null
  le?: number | null
  lt?: number | null
  ge?: number | null
  gt?: number | null
  between?: Array<number | null> | null
}

export type ModelMailFilterInput = {
  id?: ModelIDInput | null
  teamId?: ModelIDInput | null
  mailType?: ModelStringInput | null
  title?: ModelStringInput | null
  body?: ModelStringInput | null
  attachmentFileNames?: ModelStringInput | null
  startDate?: ModelStringInput | null
  startTime?: ModelStringInput | null
  endDate?: ModelStringInput | null
  endTime?: ModelStringInput | null
  shareManagerFlg?: ModelIntInput | null
  showAnswerFlg?: ModelBooleanInput | null
  responseDueDatetime?: ModelStringInput | null
  remindDate?: ModelStringInput | null
  remindDatetime?: ModelStringInput | null
  remindExecDatetime?: ModelStringInput | null
  sendScheduledAt?: ModelStringInput | null
  sentAt?: ModelStringInput | null
  and?: Array<ModelMailFilterInput | null> | null
  or?: Array<ModelMailFilterInput | null> | null
  not?: ModelMailFilterInput | null
  mailSenderId?: ModelIDInput | null
}

export type ModelMailConnection = {
  __typename: "ModelMailConnection"
  items: Array<Mail | null>
  nextToken?: string | null
}

export type ModelReservedMailFilterInput = {
  id?: ModelIDInput | null
  teamId?: ModelIDInput | null
  mailType?: ModelStringInput | null
  title?: ModelStringInput | null
  body?: ModelStringInput | null
  attachmentFileNames?: ModelStringInput | null
  startDate?: ModelStringInput | null
  startTime?: ModelStringInput | null
  endDate?: ModelStringInput | null
  endTime?: ModelStringInput | null
  shareManagerFlg?: ModelIntInput | null
  showAnswerFlg?: ModelBooleanInput | null
  responseDueDatetime?: ModelStringInput | null
  remindDatetime?: ModelStringInput | null
  sendScheduledDate?: ModelStringInput | null
  sendScheduledAt?: ModelStringInput | null
  and?: Array<ModelReservedMailFilterInput | null> | null
  or?: Array<ModelReservedMailFilterInput | null> | null
  not?: ModelReservedMailFilterInput | null
  teamMemberReservedMailsId?: ModelIDInput | null
}

export type ModelIDKeyConditionInput = {
  eq?: string | null
  le?: string | null
  lt?: string | null
  ge?: string | null
  gt?: string | null
  between?: Array<string | null> | null
  beginsWith?: string | null
}

export type ModelMatchingMessageByApplicationIdMessageReceiverGsiCompositeKeyConditionInput = {
  eq?: ModelMatchingMessageByApplicationIdMessageReceiverGsiCompositeKeyInput | null
  le?: ModelMatchingMessageByApplicationIdMessageReceiverGsiCompositeKeyInput | null
  lt?: ModelMatchingMessageByApplicationIdMessageReceiverGsiCompositeKeyInput | null
  ge?: ModelMatchingMessageByApplicationIdMessageReceiverGsiCompositeKeyInput | null
  gt?: ModelMatchingMessageByApplicationIdMessageReceiverGsiCompositeKeyInput | null
  between?: Array<ModelMatchingMessageByApplicationIdMessageReceiverGsiCompositeKeyInput | null> | null
  beginsWith?: ModelMatchingMessageByApplicationIdMessageReceiverGsiCompositeKeyInput | null
}

export type ModelMatchingMessageByApplicationIdMessageReceiverGsiCompositeKeyInput = {
  deleteFlg?: number | null
  unreadFlg?: number | null
}

export type ModelMatchingMessageFilterInput = {
  id?: ModelIDInput | null
  messageSenderTeamId?: ModelIDInput | null
  text?: ModelStringInput | null
  unreadFlg?: ModelIntInput | null
  deleteFlg?: ModelIntInput | null
  contentUpdatedAt?: ModelStringInput | null
  createdAtForSort?: ModelStringInput | null
  createdAtForSortIncludeDeleted?: ModelStringInput | null
  applicationIdMessageReceiverGsi?: ModelStringInput | null
  matchingApplicationMessagesId?: ModelIDInput | null
  matchingApplicationMessagesIncludeDeletedId?: ModelIDInput | null
  and?: Array<ModelMatchingMessageFilterInput | null> | null
  or?: Array<ModelMatchingMessageFilterInput | null> | null
  not?: ModelMatchingMessageFilterInput | null
}

export type ModelBlockingMatchingTeamFilterInput = {
  id?: ModelIDInput | null
  blockingTeamId?: ModelIDInput | null
  and?: Array<ModelBlockingMatchingTeamFilterInput | null> | null
  or?: Array<ModelBlockingMatchingTeamFilterInput | null> | null
  not?: ModelBlockingMatchingTeamFilterInput | null
  teamBlockingMatchingTeamsId?: ModelIDInput | null
}

export type ModelReportMatchingTeamLogFilterInput = {
  id?: ModelIDInput | null
  reporterAccountId?: ModelIDInput | null
  reporterTeamId?: ModelIDInput | null
  teamId?: ModelIDInput | null
  content?: ModelStringInput | null
  and?: Array<ModelReportMatchingTeamLogFilterInput | null> | null
  or?: Array<ModelReportMatchingTeamLogFilterInput | null> | null
  not?: ModelReportMatchingTeamLogFilterInput | null
}

export type ModelReportMatchingTeamLogConnection = {
  __typename: "ModelReportMatchingTeamLogConnection"
  items: Array<ReportMatchingTeamLog | null>
  nextToken?: string | null
}

export type ReportMatchingTeamLog = {
  __typename: "ReportMatchingTeamLog"
  id: string
  reporterAccountId: string
  reporterTeamId: string
  teamId: string
  content?: string | null
  createdAt: string
  updatedAt: string
}

export type ModelExpiredAttachmentFilterInput = {
  id?: ModelIDInput | null
  expiredDate?: ModelStringInput | null
  s3Path?: ModelStringInput | null
  and?: Array<ModelExpiredAttachmentFilterInput | null> | null
  or?: Array<ModelExpiredAttachmentFilterInput | null> | null
  not?: ModelExpiredAttachmentFilterInput | null
}

export type ModelExpiredAttachmentConnection = {
  __typename: "ModelExpiredAttachmentConnection"
  items: Array<ExpiredAttachment | null>
  nextToken?: string | null
}

export type ExpiredAttachment = {
  __typename: "ExpiredAttachment"
  id: string
  expiredDate: string
  s3Path: string
  createdAt: string
  updatedAt: string
}

export type ModelAccountAdminNoticeFilterInput = {
  id?: ModelIDInput | null
  adminNoticeId?: ModelIDInput | null
  accountId?: ModelIDInput | null
  readFlg?: ModelBooleanInput | null
  and?: Array<ModelAccountAdminNoticeFilterInput | null> | null
  or?: Array<ModelAccountAdminNoticeFilterInput | null> | null
  not?: ModelAccountAdminNoticeFilterInput | null
}

export type ModelAccountAdminNoticeConnection = {
  __typename: "ModelAccountAdminNoticeConnection"
  items: Array<AccountAdminNotice | null>
  nextToken?: string | null
}

export type AccountAdminNotice = {
  __typename: "AccountAdminNotice"
  id: string
  adminNoticeId: string
  accountId: string
  readFlg: boolean
  createdAt: string
  updatedAt: string
}

export type GetAccountGqlQueryVariables = {
  id: string
}

export type GetAccountGqlQuery = {
  getAccount?: {
    __typename: "Account"
    id: string
    email: string
    phoneNumber?: string | null
    lastName: string
    firstName: string
    lastNameKana: string
    firstNameKana: string
    gender?: Gender | null
    birthday?: string | null
    job?: {
      __typename: "JobMaster"
      id: string
      label: string
    } | null
    school?: {
      __typename: "SchoolMaster"
      id: string
      label: string
    } | null
    schoolDepartment?: {
      __typename: "SchoolDepartmentMaster"
      id: string
      label: string
    } | null
    schoolGraduationYear?: number | null
    prefecture?: {
      __typename: "PrefectureMaster"
      id: string
      label: string
    } | null
    city?: {
      __typename: "CityMaster"
      id: string
      label: string
    } | null
    questionAnswers?: {
      __typename: "ModelAccountQuestionAnswerConnection"
      items: Array<{
        __typename: "AccountQuestionAnswer"
        question?: {
          __typename: "AccountQuestionMaster"
          id: string
          label: string
        } | null
        answer?: {
          __typename: "AccountQuestionChoiceMaster"
          id: string
          label: string
        } | null
      } | null>
    } | null
    matchingTermsAgreedAt?: string | null
    agreedMatchingTermsVersion?: string | null
    signupStatus: SignupStatus
    createdAt: string
    updatedAt: string
  } | null
}

export type RegisterAuthCodeGqlMutationVariables = {
  input: RegisterAuthCodeInput
}

export type RegisterAuthCodeGqlMutation = {
  registerAuthCode: {
    __typename: "RegisterAuthCodeResponse"
    message?: string | null
    data?: {
      __typename: "RegisterAuthCodeData"
      auth?: {
        __typename: "InitiateAuthResponse"
        ChallengeName?: string | null
        Session?: string | null
        ChallengeParameters?: string | null
        AuthenticationResult?: {
          __typename: "AuthenticationResultType"
          AccessToken?: string | null
          ExpiresIn?: string | null
          TokenType?: string | null
          RefreshToken?: string | null
          IdToken?: string | null
          NewDeviceMetadata?: string | null
        } | null
      } | null
      user?: {
        __typename: "RegisterAuthCodeUser"
        id?: string | null
        lastName?: string | null
        firstName?: string | null
        lastNameKana?: string | null
        firstNameKana?: string | null
        email?: string | null
        signupStatus?: string | null
      } | null
    } | null
  }
}

export type UpdateNotificationSettingGqlMutationVariables = {
  input: UpdateNotificationSettingInput
}

export type UpdateNotificationSettingGqlMutation = {
  updateNotificationSetting?: {
    __typename: "NotificationSetting"
    id: string
  } | null
}

export type ListTeamNoticeGqlQueryVariables = {
  accountId: string
}

export type ListTeamNoticeGqlQuery = {
  getAccount?: {
    __typename: "Account"
    teamNotices?: {
      __typename: "ModelTeamNoticeConnection"
      items: Array<{
        __typename: "TeamNotice"
        id: string
        noticeType: string
        title: string
        url: string
        readFlg: boolean
        team?: {
          __typename: "Team"
          id: string
          name: string
        } | null
        teamMemberTeamNoticeId?: string | null
        createdAt: string
      } | null>
    } | null
  } | null
}

export type MarkTeamNoticeAsReadGqlMutationVariables = {
  id: string
  readFlg: boolean
}

export type MarkTeamNoticeAsReadGqlMutation = {
  updateTeamNotice?: {
    __typename: "TeamNotice"
    id: string
  } | null
}

export type ListTeamGqlQueryVariables = {
  accountId: string
  notDeleteFlg: boolean
}

export type ListTeamGqlQuery = {
  getAccount?: {
    __typename: "Account"
    teamMembers?: {
      __typename: "ModelTeamMemberConnection"
      items: Array<{
        __typename: "TeamMember"
        id: string
        role: string
        memberSeq: number
        deleteFlg?: boolean | null
        team?: {
          __typename: "Team"
          id: string
          name: string
          invitationCode?: string | null
          createdAt: string
          updatedAt: string
          migrationId?: number | null
        } | null
      } | null>
    } | null
  } | null
}

export type GetTeamGqlQueryVariables = {
  id: string
}

export type GetTeamGqlQuery = {
  getTeam?: {
    __typename: "Team"
    id: string
    name: string
    invitationCode?: string | null
    category?: {
      __typename: "TeamCategoryMaster"
      id: string
      label: string
    } | null
    activity?: {
      __typename: "TeamActivityMaster"
      id: string
      label: string
    } | null
    prefecture?: {
      __typename: "PrefectureMaster"
      id: string
      label: string
    } | null
    city?: {
      __typename: "CityMaster"
      id: string
      label: string
    } | null
    description: string
    requirementForMemberProfile?: string | null
    customQuestions?: Array<TeamCustomQuestion> | null
    showMemberListFlg: boolean
    createdAt: string
    updatedAt: string
    migrationId?: number | null
    paidFunctionEnabled?: boolean | null
    showAdsFlg?: boolean | null
  } | null
}

export type GetInvitationInfoGqlQueryVariables = {
  teamId: string
}

export type GetInvitationInfoGqlQuery = {
  getTeam?: {
    __typename: "Team"
    invitationCode?: string | null
  } | null
}

export type GetTeamByInvitationCodeGqlQueryVariables = {
  accountId: string
  invitationCode: string
}

export type GetTeamByInvitationCodeGqlQuery = {
  getTeamByInvitationCode?: {
    __typename: "ModelTeamConnection"
    items: Array<{
      __typename: "Team"
      id: string
      name: string
      requirementForMemberProfile?: string | null
      customQuestions?: Array<TeamCustomQuestion> | null
    } | null>
  } | null
  getAccount?: {
    __typename: "Account"
    teamMembers?: {
      __typename: "ModelTeamMemberConnection"
      items: Array<{
        __typename: "TeamMember"
        team?: {
          __typename: "Team"
          id: string
        } | null
      } | null>
    } | null
    preTeamMembers?: {
      __typename: "ModelPreTeamMemberConnection"
      items: Array<{
        __typename: "PreTeamMember"
        team?: {
          __typename: "Team"
          id: string
        } | null
      } | null>
    } | null
  } | null
}

export type ListPreTeamMemberGqlQueryVariables = {
  teamId: string
  nextToken?: string | null
}

export type ListPreTeamMemberGqlQuery = {
  getTeam?: {
    __typename: "Team"
    id: string
    name: string
    preTeamMembers?: {
      __typename: "ModelPreTeamMemberConnection"
      items: Array<{
        __typename: "PreTeamMember"
        id: string
        nickname: string
      } | null>
      nextToken?: string | null
    } | null
  } | null
}

export type ListInvitationEmailStatusGqlQueryVariables = {
  teamId: string
  limit: number
  nextToken?: string | null
}

export type ListInvitationEmailStatusGqlQuery = {
  getTeam?: {
    __typename: "Team"
    invitationEmailStatuses?: {
      __typename: "ModelInvitationEmailStatusConnection"
      items: Array<{
        __typename: "InvitationEmailStatus"
        id: string
        email: string
        status: EmailStatus
        createdAt: string
        updatedAt: string
      } | null>
      nextToken?: string | null
    } | null
  } | null
}

export type ListJobMasterGqlQuery = {
  listJobMaster?: {
    __typename: "ModelJobMasterConnection"
    items: Array<{
      __typename: "JobMaster"
      id: string
      label: string
      type: JobType
      displayNumber: number
      displayFlg: number
    } | null>
  } | null
}

export type ListTeamMemberGqlQueryVariables = {
  teamId: string
  notDeleteFlg: boolean
  nextToken?: string | null
}

export type ListTeamMemberGqlQuery = {
  getTeam?: {
    __typename: "Team"
    teamMembers?: {
      __typename: "ModelTeamMemberConnection"
      items: Array<{
        __typename: "TeamMember"
        id: string
        memberSeq: number
        nickname: string
        role: string
        groups?: {
          __typename: "ModelGroupMemberConnection"
          items: Array<{
            __typename: "GroupMember"
            id: string
            leaderFlg?: boolean | null
            group?: {
              __typename: "Group"
              id: string
            } | null
          } | null>
        } | null
      } | null>
      nextToken?: string | null
    } | null
  } | null
}

export type ListTeamMemberCsvDataGqlQueryVariables = {
  teamId: string
  nextToken?: string | null
  notDeleteFlg: boolean
}

export type ListTeamMemberCsvDataGqlQuery = {
  getTeam?: {
    __typename: "Team"
    teamMembers?: {
      __typename: "ModelTeamMemberConnection"
      items: Array<{
        __typename: "TeamMember"
        id: string
        memberSeq: number
        nickname: string
        nicknameKana: string
        role: string
      } | null>
      nextToken?: string | null
    } | null
  } | null
}

export type GetTeamMemberGqlQueryVariables = {
  id: string
}

export type GetTeamMemberGqlQuery = {
  getTeamMember?: {
    __typename: "TeamMember"
    id: string
    memberSeq: number
    nickname: string
    nicknameKana: string
    role: string
    account?: {
      __typename: "Account"
      email: string
    } | null
    showEmailFlg?: boolean | null
    phoneNumber?: string | null
    showPhoneNumberFlg?: boolean | null
    belongs?: string | null
    showBelongsFlg?: boolean | null
    grade?: number | null
    mailMigratedFlg?: boolean | null
  } | null
}

export type GetAccountTeamMemberGqlQueryVariables = {
  accountId: string
  teamId: string
}

export type GetAccountTeamMemberGqlQuery = {
  getAccount?: {
    __typename: "Account"
    teamMembers?: {
      __typename: "ModelTeamMemberConnection"
      items: Array<{
        __typename: "TeamMember"
        team?: {
          __typename: "Team"
          id: string
        } | null
        id: string
        memberSeq: number
        nickname: string
        nicknameKana: string
        role: string
        account?: {
          __typename: "Account"
          email: string
        } | null
        showEmailFlg?: boolean | null
        phoneNumber?: string | null
        showPhoneNumberFlg?: boolean | null
        belongs?: string | null
        showBelongsFlg?: boolean | null
        grade?: number | null
        mailMigratedFlg?: boolean | null
      } | null>
    } | null
  } | null
}

export type ListAccountTeamMemberGqlQueryVariables = {
  accountId: string
  nextToken?: string | null
}

export type ListAccountTeamMemberGqlQuery = {
  getAccount?: {
    __typename: "Account"
    teamMembers?: {
      __typename: "ModelTeamMemberConnection"
      items: Array<{
        __typename: "TeamMember"
        team?: {
          __typename: "Team"
          id: string
        } | null
        id: string
        memberSeq: number
        nickname: string
        nicknameKana: string
        role: string
        account?: {
          __typename: "Account"
          email: string
        } | null
        showEmailFlg?: boolean | null
        phoneNumber?: string | null
        showPhoneNumberFlg?: boolean | null
        belongs?: string | null
        showBelongsFlg?: boolean | null
        grade?: number | null
        mailMigratedFlg?: boolean | null
      } | null>
      nextToken?: string | null
    } | null
  } | null
}

export type GetGroupGqlQueryVariables = {
  id: string
  nextToken?: string | null
}

export type GetGroupGqlQuery = {
  getGroup?: {
    __typename: "Group"
    id: string
    name?: string | null
    groupMembers?: {
      __typename: "ModelGroupMemberConnection"
      items: Array<{
        __typename: "GroupMember"
        leaderFlg?: boolean | null
        teamMember?: {
          __typename: "TeamMember"
          id: string
          nickname: string
          memberSeq: number
          role: string
        } | null
        createdAt: string
        updatedAt: string
      } | null>
      nextToken?: string | null
    } | null
    createdAt: string
    updatedAt: string
  } | null
}

export type ListTeamMemberMailGqlQueryVariables = {
  filter: SearchableTeamMemberMailFilterInput
  count?: number | null
  nextToken?: string | null
}

export type ListTeamMemberMailGqlQuery = {
  searchTeamMemberMails?: {
    __typename: "SearchableTeamMemberMailConnection"
    total?: number | null
    items: Array<{
      __typename: "TeamMemberMail"
      id: string
      readFlg: boolean
      favoriteFlgReceived: boolean
      favoriteFlgSent: boolean
      answeredFlg: boolean
      answerRequiredFlg: boolean
      mail?: {
        __typename: "Mail"
        id: string
        mailType: string
        title: string
        sender?: {
          __typename: "TeamMember"
          id: string
          nickname: string
          deleteFlg?: boolean | null
        } | null
        attachmentFileNames?: Array<string> | null
        sentAt: string
        responseDueDatetime?: string | null
        createdAt: string
        updatedAt: string
      } | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetMailGqlQueryVariables = {
  teamMemberMailId: string
}

export type GetMailGqlQuery = {
  getTeamMemberMail?: {
    __typename: "TeamMemberMail"
    favoriteFlgReceived: boolean
    favoriteFlgSent: boolean
    readFlg: boolean
    attendanceAnswer?: YesNoAnswer | null
    questionnaireAnswers?: Array<{
      __typename: "QuestionnaireAnswers"
      answer: Array<number>
    }> | null
    scheduleAnswers?: Array<YesNoAnswer> | null
    mail?: {
      __typename: "Mail"
      id: string
      teamId: string
      mailType: string
      title: string
      sender?: {
        __typename: "TeamMember"
        id: string
        nickname: string
        deleteFlg?: boolean | null
      } | null
      body: string
      sendTo: {
        __typename: "SendTo"
        allMembersFlg?: boolean | null
        managersFlg?: boolean | null
        groupIds?: Array<string> | null
        memberIds?: Array<string> | null
      }
      attachmentFileNames?: Array<string> | null
      startDate?: string | null
      startTime?: string | null
      endDate?: string | null
      endTime?: string | null
      questionnaires?: Array<{
        __typename: "Questionnaires"
        answerSelectType: AnswerSelectType
        question: string
        choices: Array<string>
      }> | null
      candidateDatetimes?: Array<{
        __typename: "CandidateDatetimes"
        startDate: string
        startTime?: string | null
        endDate?: string | null
        endTime?: string | null
      }> | null
      shareManagerFlg?: number | null
      showAnswerFlg?: boolean | null
      responseDueDatetime?: string | null
      remindDatetime?: string | null
      remindExecDatetime?: string | null
      sentAt: string
      createdAt: string
      updatedAt: string
    } | null
  } | null
}

export type ListMemberGroupGqlQueryVariables = {
  teamId: string
  nextToken?: string | null
}

export type ListMemberGroupGqlQuery = {
  getTeam?: {
    __typename: "Team"
    teamMembers?: {
      __typename: "ModelTeamMemberConnection"
      items: Array<{
        __typename: "TeamMember"
        id: string
        groups?: {
          __typename: "ModelGroupMemberConnection"
          items: Array<{
            __typename: "GroupMember"
            leaderFlg?: boolean | null
            group?: {
              __typename: "Group"
              id: string
              name?: string | null
            } | null
          } | null>
        } | null
      } | null>
      nextToken?: string | null
    } | null
  } | null
}

export type GetReservedMailGqlQueryVariables = {
  id: string
}

export type GetReservedMailGqlQuery = {
  getReservedMail?: {
    __typename: "ReservedMail"
    id: string
    mailType: string
    title: string
    teamMember?: {
      __typename: "TeamMember"
      id: string
      nickname: string
    } | null
    body: string
    sendTo: {
      __typename: "SendTo"
      allMembersFlg?: boolean | null
      managersFlg?: boolean | null
      groupIds?: Array<string> | null
      memberIds?: Array<string> | null
    }
    attachmentFileNames?: Array<string> | null
    startDate?: string | null
    startTime?: string | null
    endDate?: string | null
    endTime?: string | null
    questionnaires?: Array<{
      __typename: "Questionnaires"
      answerSelectType: AnswerSelectType
      question: string
      choices: Array<string>
    }> | null
    candidateDatetimes?: Array<{
      __typename: "CandidateDatetimes"
      startDate: string
      startTime?: string | null
      endDate?: string | null
      endTime?: string | null
    }> | null
    shareManagerFlg?: number | null
    showAnswerFlg?: boolean | null
    responseDueDatetime?: string | null
    remindDatetime?: string | null
    sendScheduledAt: string
    createdAt: string
    updatedAt: string
    teamMemberReservedMailsId?: string | null
  } | null
}

export type UpdateTeamMemberMailGqlMutationVariables = {
  input: UpdateTeamMemberMailInput
}

export type UpdateTeamMemberMailGqlMutation = {
  updateTeamMemberMail?: {
    __typename: "TeamMemberMail"
    id: string
  } | null
}

export type ListReservedMailGqlQueryVariables = {
  teamMemberId: string
}

export type ListReservedMailGqlQuery = {
  getTeamMember?: {
    __typename: "TeamMember"
    id: string
    nickname: string
    reservedMails?: {
      __typename: "ModelReservedMailConnection"
      items: Array<{
        __typename: "ReservedMail"
        id: string
        mailType: string
        title: string
        body: string
        attachmentFileNames?: Array<string> | null
        sendScheduledAt: string
        createdAt: string
        updatedAt: string
      } | null>
    } | null
  } | null
}

export type UpdateReservedMailGqlMutationVariables = {
  input: UpdateReservedMailInput
}

export type UpdateReservedMailGqlMutation = {
  updateReservedMail?: {
    __typename: "ReservedMail"
    id: string
  } | null
}

export type DeleteReservedMailGqlMutationVariables = {
  input: DeleteReservedMailInput
}

export type DeleteReservedMailGqlMutation = {
  deleteReservedMail?: {
    __typename: "ReservedMail"
    id: string
  } | null
}

export type ListDraftGqlQueryVariables = {
  teamMemberId: string
}

export type ListDraftGqlQuery = {
  getTeamMember?: {
    __typename: "TeamMember"
    drafts?: {
      __typename: "ModelDraftConnection"
      items: Array<{
        __typename: "Draft"
        id: string
        mailType: string
        title: string
        body: string
        attachmentFileNames?: Array<string> | null
        updatedAt: string
      } | null>
    } | null
  } | null
}

export type UpdateDraftGqlMutationVariables = {
  input: UpdateDraftInput
}

export type UpdateDraftGqlMutation = {
  updateDraft?: {
    __typename: "Draft"
    id: string
  } | null
}

export type DeleteDraftGqlMutationVariables = {
  input: DeleteDraftInput
}

export type DeleteDraftGqlMutation = {
  deleteDraft?: {
    __typename: "Draft"
    id: string
  } | null
}

export type ListTemplateGqlQueryVariables = {
  teamMemberId: string
}

export type ListTemplateGqlQuery = {
  getTeamMember?: {
    __typename: "TeamMember"
    templates?: {
      __typename: "ModelTemplateConnection"
      items: Array<{
        __typename: "Template"
        id: string
        mailType: string
        title: string
        body: string
        favoriteFlg?: boolean | null
        updatedAt: string
        createdAt: string
      } | null>
    } | null
  } | null
}

export type GetDraftGqlQueryVariables = {
  id: string
}

export type GetDraftGqlQuery = {
  getDraft?: {
    __typename: "Draft"
    id: string
    mailType: string
    title: string
    teamMember?: {
      __typename: "TeamMember"
      id: string
      nickname: string
    } | null
    body: string
    sendTo?: {
      __typename: "SendTo"
      allMembersFlg?: boolean | null
      managersFlg?: boolean | null
      groupIds?: Array<string> | null
      memberIds?: Array<string> | null
    } | null
    attachmentFileNames?: Array<string> | null
    questionnaires?: Array<{
      __typename: "Questionnaires"
      answerSelectType: AnswerSelectType
      question: string
      choices: Array<string>
    }> | null
    candidateDatetimes?: Array<{
      __typename: "CandidateDatetimes"
      startDate: string
      startTime?: string | null
      endDate?: string | null
      endTime?: string | null
    }> | null
    shareManagerFlg?: number | null
    showAnswerFlg?: boolean | null
    responseDueDatetime?: string | null
    remindDatetime?: string | null
    sendScheduledAt?: string | null
    createdAt: string
    updatedAt: string
    teamMemberDraftsId?: string | null
  } | null
}

export type GetTemplateGqlQueryVariables = {
  id: string
}

export type GetTemplateGqlQuery = {
  getTemplate?: {
    __typename: "Template"
    id: string
    mailType: string
    title: string
    teamMember: {
      __typename: "TeamMember"
      id: string
      nickname: string
    }
    body: string
    sendTo: {
      __typename: "SendTo"
      allMembersFlg?: boolean | null
      managersFlg?: boolean | null
      groupIds?: Array<string> | null
      memberIds?: Array<string> | null
    }
    questionnaires?: Array<{
      __typename: "Questionnaires"
      answerSelectType: AnswerSelectType
      question: string
      choices: Array<string>
    }> | null
    candidateDatetimes?: Array<{
      __typename: "CandidateDatetimes"
      startDate: string
      startTime?: string | null
      endDate?: string | null
      endTime?: string | null
    }> | null
    shareManagerFlg?: number | null
    showAnswerFlg?: boolean | null
    favoriteFlg?: boolean | null
    updatedAt: string
  } | null
}

export type UpdateTemplateFavoriteFlgGqlMutationVariables = {
  input: UpdateTemplateInput
}

export type UpdateTemplateFavoriteFlgGqlMutation = {
  updateTemplate?: {
    __typename: "Template"
    id: string
  } | null
}

export type DeleteTemplateGqlMutationVariables = {
  input: DeleteTemplateInput
}

export type DeleteTemplateGqlMutation = {
  deleteTemplate?: {
    __typename: "Template"
    id: string
  } | null
}

export type ListThreadByMailRecipientGqlQueryVariables = {
  mailId: string
  myMemberId: string
}

export type ListThreadByMailRecipientGqlQuery = {
  getMail?: {
    __typename: "Mail"
    threads?: {
      __typename: "ModelThreadConnection"
      items: Array<{
        __typename: "Thread"
        id: string
        mailSender?: {
          __typename: "TeamMember"
          id: string
          nickname: string
          deleteFlg?: boolean | null
        } | null
        mailRecipient?: {
          __typename: "TeamMember"
          id: string
          nickname: string
          deleteFlg?: boolean | null
        } | null
      } | null>
    } | null
  } | null
}

export type ListThreadByMailSenderGqlQueryVariables = {
  mailId: string
  myMemberId: string
}

export type ListThreadByMailSenderGqlQuery = {
  getMail?: {
    __typename: "Mail"
    threads?: {
      __typename: "ModelThreadConnection"
      items: Array<{
        __typename: "Thread"
        id: string
        mailSender?: {
          __typename: "TeamMember"
          id: string
          nickname: string
          deleteFlg?: boolean | null
        } | null
        mailRecipient?: {
          __typename: "TeamMember"
          id: string
          nickname: string
          deleteFlg?: boolean | null
        } | null
      } | null>
    } | null
  } | null
}

export type GetLatestThreadMessageGqlQueryVariables = {
  threadId: string
  notDeleteFlg: boolean
}

export type GetLatestThreadMessageGqlQuery = {
  searchThreadMessages?: {
    __typename: "SearchableThreadMessageConnection"
    items: Array<{
      __typename: "ThreadMessage"
      text?: string | null
      messageSenderId: string
      createdAt: string
      updatedAt: string
    } | null>
  } | null
}

export type GetUnreadThreadMessageCountGqlQueryVariables = {
  filter?: SearchableThreadMessageFilterInput | null
  aggregates?: Array<SearchableThreadMessageAggregationInput | null> | null
}

export type GetUnreadThreadMessageCountGqlQuery = {
  searchThreadMessages?: {
    __typename: "SearchableThreadMessageConnection"
    aggregateItems: Array<{
      __typename: "SearchableAggregateResult"
      name: string
      result:
        | (
            | {
                __typename: "SearchableAggregateScalarResult"
                value: number
              }
            | {
                __typename: "SearchableAggregateBucketResult"
              }
          )
        | null
    } | null>
  } | null
}

export type GetUnreadThreadMessageCountByIdsGqlQueryVariables = {
  filter?: SearchableThreadMessageFilterInput | null
  aggregates?: Array<SearchableThreadMessageAggregationInput | null> | null
}

export type GetUnreadThreadMessageCountByIdsGqlQuery = {
  searchThreadMessages?: {
    __typename: "SearchableThreadMessageConnection"
    aggregateItems: Array<{
      __typename: "SearchableAggregateResult"
      name: string
      result:
        | (
            | {
                __typename: "SearchableAggregateScalarResult"
              }
            | {
                __typename: "SearchableAggregateBucketResult"
                buckets?: Array<{
                  __typename: string
                  doc_count: number
                  key: string
                } | null> | null
              }
          )
        | null
    } | null>
  } | null
}

export type HasUnreadThreadMessageGqlQueryVariables = {
  accountId: string
  teamId: string
  unreadFlg: number
  notDeleteFlg: boolean
}

export type HasUnreadThreadMessageGqlQuery = {
  searchThreadMessages?: {
    __typename: "SearchableThreadMessageConnection"
    items: Array<{
      __typename: "ThreadMessage"
      id: string
      messageRecipientAccountId: string
      messageSenderId: string
    } | null>
  } | null
}

export type ListThreadMessageQueryVariables = {
  threadId: string
  nextToken?: string | null
}

export type ListThreadMessageQuery = {
  getThread?: {
    __typename: "Thread"
    mail?: {
      __typename: "Mail"
      id: string
      title: string
    } | null
    mailSender?: {
      __typename: "TeamMember"
      id: string
      nickname: string
      deleteFlg?: boolean | null
    } | null
    mailRecipient?: {
      __typename: "TeamMember"
      id: string
      nickname: string
      deleteFlg?: boolean | null
    } | null
    messages?: {
      __typename: "ModelThreadMessageConnection"
      items: Array<{
        __typename: "ThreadMessage"
        id: string
        messageSenderId: string
        text?: string | null
        unreadFlg: number
        deleteFlg?: boolean | null
        createdAt: string
        contentUpdatedAt: string
      } | null>
      nextToken?: string | null
    } | null
  } | null
}

export type ListSchoolMasterGqlQueryVariables = {
  type: string
  nextToken?: string | null
}

export type ListSchoolMasterGqlQuery = {
  getSchoolMasterByType?: {
    __typename: "ModelSchoolMasterConnection"
    items: Array<{
      __typename: "SchoolMaster"
      id: string
      label: string
      labelKana: string
      displayNumber: number
      displayFlg: number
    } | null>
    nextToken?: string | null
  } | null
}

export type GetSchoolDepartmentMasterGqlQueryVariables = {
  schoolId: string
}

export type GetSchoolDepartmentMasterGqlQuery = {
  getSchoolMaster?: {
    __typename: "SchoolMaster"
    departments?: {
      __typename: "ModelSchoolDepartmentMasterConnection"
      items: Array<{
        __typename: "SchoolDepartmentMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
      } | null>
    } | null
  } | null
}

export type ListPrefectureMasterGqlQuery = {
  listPrefectureMaster?: {
    __typename: "ModelPrefectureMasterConnection"
    items: Array<{
      __typename: "PrefectureMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
    } | null>
  } | null
}

export type GetPrefectureMasterGqlQueryVariables = {
  prefectureId: string
}

export type GetPrefectureMasterGqlQuery = {
  getPrefectureMaster?: {
    __typename: "PrefectureMaster"
    cities?: {
      __typename: "ModelCityMasterConnection"
      items: Array<{
        __typename: "CityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
      } | null>
    } | null
  } | null
}

export type ListTeamCategoryMasterGqlQuery = {
  listTeamCategoryMaster?: {
    __typename: "ModelTeamCategoryMasterConnection"
    items: Array<{
      __typename: "TeamCategoryMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      activities?: {
        __typename: "ModelTeamCategoryActivityConnection"
        items: Array<{
          __typename: "TeamCategoryActivity"
          teamActivityMaster: {
            __typename: "TeamActivityMaster"
            id: string
            label: string
            displayNumber: number
            displayFlg: number
          }
        } | null>
      } | null
    } | null>
  } | null
}

export type ListAccountQuestionMasterGqlQuery = {
  listAccountQuestionMaster?: {
    __typename: "ModelAccountQuestionMasterConnection"
    items: Array<{
      __typename: "AccountQuestionMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      choices?: {
        __typename: "ModelAccountQuestionChoiceMasterConnection"
        items: Array<{
          __typename: "AccountQuestionChoiceMaster"
          id: string
          label: string
          displayNumber: number
          displayFlg: number
        } | null>
      } | null
    } | null>
  } | null
}

export type GetFirstUnreadTeamNoticeGqlQueryVariables = {
  accountId: string
  true: boolean
}

export type GetFirstUnreadTeamNoticeGqlQuery = {
  getAccount?: {
    __typename: "Account"
    teamNotices?: {
      __typename: "ModelTeamNoticeConnection"
      items: Array<{
        __typename: "TeamNotice"
        id: string
        createdAt: string
      } | null>
    } | null
  } | null
}

export type GetUnreadMailCountGqlQueryVariables = {
  aggregates: Array<SearchableTeamMemberMailAggregationInput>
  filter: SearchableTeamMemberMailFilterInput
}

export type GetUnreadMailCountGqlQuery = {
  searchTeamMemberMails?: {
    __typename: "SearchableTeamMemberMailConnection"
    aggregateItems: Array<{
      __typename: "SearchableAggregateResult"
      name: string
      result:
        | (
            | {
                __typename: "SearchableAggregateScalarResult"
                value: number
              }
            | {
                __typename: "SearchableAggregateBucketResult"
              }
          )
        | null
    } | null>
  } | null
}

export type ListUnreadMailCountGqlQueryVariables = {
  aggregates: Array<SearchableTeamMemberMailAggregationInput>
  filter: SearchableTeamMemberMailFilterInput
}

export type ListUnreadMailCountGqlQuery = {
  searchTeamMemberMails?: {
    __typename: "SearchableTeamMemberMailConnection"
    aggregateItems: Array<{
      __typename: "SearchableAggregateResult"
      name: string
      result:
        | (
            | {
                __typename: "SearchableAggregateScalarResult"
              }
            | {
                __typename: "SearchableAggregateBucketResult"
                buckets?: Array<{
                  __typename: string
                  doc_count: number
                  key: string
                } | null> | null
              }
          )
        | null
    } | null>
  } | null
}

export type GetPreTeamMemberIdsGqlQueryVariables = {
  accountId: string
  notDeleteFlg?: boolean | null
}

export type GetPreTeamMemberIdsGqlQuery = {
  getAccount?: {
    __typename: "Account"
    teamMembers?: {
      __typename: "ModelTeamMemberConnection"
      items: Array<{
        __typename: "TeamMember"
        team?: {
          __typename: "Team"
          id: string
          preTeamMembers?: {
            __typename: "ModelPreTeamMemberConnection"
            items: Array<{
              __typename: "PreTeamMember"
              id: string
            } | null>
          } | null
        } | null
      } | null>
    } | null
  } | null
}

export type ListMatchingOfferGqlQueryVariables = {
  filter?: SearchableMatchingOfferFilterInput | null
  limit: number
  nextToken?: string | null
}

export type ListMatchingOfferGqlQuery = {
  searchMatchingOffers?: {
    __typename: "SearchableMatchingOfferConnection"
    total?: number | null
    items: Array<{
      __typename: "MatchingOffer"
      id: string
      purpose: MatchingOfferPurpose
      title: string
      date?: string | null
      startTime?: string | null
      endTime?: string | null
      prefecture?: {
        __typename: "PrefectureMaster"
        id: string
        label: string
      } | null
      city?: {
        __typename: "CityMaster"
        id: string
        label: string
      } | null
      ageFrom?: number | null
      ageTo?: number | null
      publishEndDate: string
      activity?: {
        __typename: "TeamActivityMaster"
        id: string
        label: string
      } | null
      teamId: string
      teamName: string
      deleteFlg?: boolean | null
      createdAt: string
      updatedAt: string
      teamMatchingOffersId?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetMatchingOfferGqlQueryVariables = {
  id: string
}

export type GetMatchingOfferGqlQuery = {
  getMatchingOffer?: {
    __typename: "MatchingOffer"
    id: string
    purpose: MatchingOfferPurpose
    title: string
    body: string
    date?: string | null
    startTime?: string | null
    endTime?: string | null
    prefecture?: {
      __typename: "PrefectureMaster"
      id: string
      label: string
    } | null
    city?: {
      __typename: "CityMaster"
      id: string
      label: string
    } | null
    place?: string | null
    placeURL?: string | null
    expense?: number | null
    expensePurpose?: string | null
    ageFrom?: number | null
    ageTo?: number | null
    offerCount?: number | null
    publishEndDate: string
    attachmentFileNames?: Array<string> | null
    activity?: {
      __typename: "TeamActivityMaster"
      id: string
      label: string
    } | null
    teamId: string
    teamName: string
    deleteFlg?: boolean | null
    createdAt: string
    updatedAt: string
  } | null
}

export type UpdateMatchingOfferGqlMutationVariables = {
  input: UpdateMatchingOfferInput
}

export type UpdateMatchingOfferGqlMutation = {
  updateMatchingOffer?: {
    __typename: "MatchingOffer"
    id: string
  } | null
}

export type ListMatchingOfferDraftGqlQueryVariables = {
  teamId: string
}

export type ListMatchingOfferDraftGqlQuery = {
  getTeam?: {
    __typename: "Team"
    activity?: {
      __typename: "TeamActivityMaster"
      id: string
      label: string
    } | null
    matchingOfferDrafts?: {
      __typename: "ModelMatchingOfferDraftConnection"
      items: Array<{
        __typename: "MatchingOfferDraft"
        id: string
        purpose?: MatchingOfferPurpose | null
        title: string
        date?: string | null
        startTime?: string | null
        endTime?: string | null
        prefecture?: {
          __typename: "PrefectureMaster"
          id: string
          label: string
        } | null
        city?: {
          __typename: "CityMaster"
          id: string
          label: string
        } | null
        ageFrom?: number | null
        ageTo?: number | null
        publishEndDate?: string | null
        createdAt: string
        updatedAt: string
      } | null>
    } | null
  } | null
}

export type GetMatchingOfferDraftGqlQueryVariables = {
  id: string
}

export type GetMatchingOfferDraftGqlQuery = {
  getMatchingOfferDraft?: {
    __typename: "MatchingOfferDraft"
    id: string
    team?: {
      __typename: "Team"
      id: string
      name: string
      activity?: {
        __typename: "TeamActivityMaster"
        id: string
        label: string
      } | null
    } | null
    purpose?: MatchingOfferPurpose | null
    title: string
    body?: string | null
    date?: string | null
    startTime?: string | null
    endTime?: string | null
    prefecture?: {
      __typename: "PrefectureMaster"
      id: string
      label: string
    } | null
    city?: {
      __typename: "CityMaster"
      id: string
      label: string
    } | null
    place?: string | null
    placeURL?: string | null
    expense?: number | null
    expensePurpose?: string | null
    ageFrom?: number | null
    ageTo?: number | null
    offerCount?: number | null
    publishEndDate?: string | null
    createdAt: string
    updatedAt: string
  } | null
}

export type UpdateMatchingOfferDraftGqlMutationVariables = {
  input: UpdateMatchingOfferDraftInput
}

export type UpdateMatchingOfferDraftGqlMutation = {
  updateMatchingOfferDraft?: {
    __typename: "MatchingOfferDraft"
    id: string
  } | null
}

export type DeleteMatchingOfferDraftGqlMutationVariables = {
  input: DeleteMatchingOfferDraftInput
}

export type DeleteMatchingOfferDraftGqlMutation = {
  deleteMatchingOfferDraft?: {
    __typename: "MatchingOfferDraft"
    id: string
  } | null
}

export type ListFavoriteMatchingOfferGqlQueryVariables = {
  teamMemberId: string
  nextToken?: string | null
}

export type ListFavoriteMatchingOfferGqlQuery = {
  getTeamMember?: {
    __typename: "TeamMember"
    favoriteMatchingOffer?: {
      __typename: "ModelFavoriteMatchingOfferConnection"
      items: Array<{
        __typename: "FavoriteMatchingOffer"
        offer: {
          __typename: "MatchingOffer"
          id: string
          purpose: MatchingOfferPurpose
          title: string
          body: string
          date?: string | null
          startTime?: string | null
          endTime?: string | null
          prefecture?: {
            __typename: "PrefectureMaster"
            id: string
            label: string
          } | null
          city?: {
            __typename: "CityMaster"
            id: string
            label: string
          } | null
          ageFrom?: number | null
          ageTo?: number | null
          publishEndDate: string
          activity?: {
            __typename: "TeamActivityMaster"
            id: string
            label: string
          } | null
          teamId: string
          teamName: string
          deleteFlg?: boolean | null
          createdAt: string
          updatedAt: string
        }
      } | null>
      nextToken?: string | null
    } | null
  } | null
}

export type CreateFavoriteMatchingOfferGqlMutationVariables = {
  input: CreateFavoriteMatchingOfferInput
}

export type CreateFavoriteMatchingOfferGqlMutation = {
  createFavoriteMatchingOffer?: {
    __typename: "FavoriteMatchingOffer"
    id: string
  } | null
}

export type DeleteFavoriteMatchingOfferGqlMutationVariables = {
  input: DeleteFavoriteMatchingOfferInput
}

export type DeleteFavoriteMatchingOfferGqlMutation = {
  deleteFavoriteMatchingOffer?: {
    __typename: "FavoriteMatchingOffer"
    id: string
  } | null
}

export type ListMatchingApplicationGqlQueryVariables = {
  filter: SearchableMatchingApplicationFilterInput
  limit: number
  nextToken?: string | null
  notDeleteFlg: number
}

export type ListMatchingApplicationGqlQuery = {
  searchMatchingApplications?: {
    __typename: "SearchableMatchingApplicationConnection"
    items: Array<{
      __typename: "MatchingApplication"
      id: string
      messages?: {
        __typename: "ModelMatchingMessageConnection"
        items: Array<{
          __typename: "MatchingMessage"
          text?: string | null
          messageSenderTeamId: string
          createdAt: string
        } | null>
      } | null
      offer?: {
        __typename: "MatchingOffer"
        id: string
        purpose: MatchingOfferPurpose
        title: string
        date?: string | null
        startTime?: string | null
        endTime?: string | null
        prefecture?: {
          __typename: "PrefectureMaster"
          id: string
          label: string
        } | null
        city?: {
          __typename: "CityMaster"
          id: string
          label: string
        } | null
        ageFrom?: number | null
        ageTo?: number | null
        publishEndDate: string
        activity?: {
          __typename: "TeamActivityMaster"
          id: string
          label: string
        } | null
        teamId: string
        teamName: string
        deleteFlg?: boolean | null
        createdAt: string
        updatedAt: string
      } | null
    } | null>
    nextToken?: string | null
  } | null
}

export type MultiGetApplicationGqlQueryVariables = {
  input: MultiGetApplicationInput
}

export type MultiGetApplicationGqlQuery = {
  multiGetApplication: {
    __typename: "MultiGetApplicationResponse"
    items: Array<{
      __typename: "MultiGetApplicationItem"
      offerId: string
      applications: Array<{
        __typename: "MultiGetApplicationItemApplication"
        id: string
        messages: Array<{
          __typename: "MultiGetApplicationMessage"
          text: string
          messageSenderTeamId: string
          createdAt: string
        }>
      }>
    }>
  }
}

export type ListMatchingMessageGqlQueryVariables = {
  matchingApplicationId: string
  limit: number
  nextToken?: string | null
}

export type ListMatchingMessageGqlQuery = {
  getMatchingApplication?: {
    __typename: "MatchingApplication"
    messagesIncludeDeleted?: {
      __typename: "ModelMatchingMessageConnection"
      items: Array<{
        __typename: "MatchingMessage"
        id: string
        messageSenderTeamId: string
        text?: string | null
        unreadFlg: number
        deleteFlg: number
        contentUpdatedAt: string
        createdAt: string
      } | null>
    } | null
  } | null
}

export type GetUnreadMatchingMessageCountQueryVariables = {
  applicationIdMessageReceiverGsi: string
  deleteFlg: number
  unreadFlg: number
}

export type GetUnreadMatchingMessageCountQuery = {
  getMatchingMessageByApplicationIdMessageReceiverGsi?: {
    __typename: "ModelMatchingMessageConnection"
    items: Array<{
      __typename: "MatchingMessage"
      id: string
    } | null>
  } | null
}

export type ListBlockingMatchingTeamGqlQueryVariables = {
  teamId: string
  nextToken?: string | null
}

export type ListBlockingMatchingTeamGqlQuery = {
  getTeam?: {
    __typename: "Team"
    blockingMatchingTeams?: {
      __typename: "ModelBlockingMatchingTeamConnection"
      items: Array<{
        __typename: "BlockingMatchingTeam"
        blockingTeamId: string
      } | null>
      nextToken?: string | null
    } | null
  } | null
}

export type ListBlockingMatchingTeamByBlockingTeamIdGqlQueryVariables = {
  teamId: string
  nextToken?: string | null
}

export type ListBlockingMatchingTeamByBlockingTeamIdGqlQuery = {
  getTeam?: {
    __typename: "Team"
    blockingMatchingTeamsByBlockingTeamId?: {
      __typename: "ModelBlockingMatchingTeamConnection"
      items: Array<{
        __typename: "BlockingMatchingTeam"
        teamBlockingMatchingTeamsId?: string | null
      } | null>
      nextToken?: string | null
    } | null
  } | null
}

export type ListTeamMemberMailByAccount2QueryVariables = {
  input: ListTeamMemberMailByAccountInput
}

export type ListTeamMemberMailByAccount2Query = {
  listTeamMemberMailByAccount?: {
    __typename: "ListTeamMemberMailByAccountResponse"
    accountId: string
    teamMemberMailByAccount: Array<{
      __typename: "TeamMemberMailByAccount"
      teamId: string
      teamMemberMail: Array<{
        __typename: "TeamMemberMailItemByAccount"
        id: string
        mail?: {
          __typename: "MailItem"
          attachmentFileNames: Array<string>
          id: string
          createdAt: string
          mailType?: string | null
          responseDueDatetime?: string | null
          sentAt: string
          title?: string | null
          updatedAt: string
          sender?: {
            __typename: "Sender"
            deleteFlg?: boolean | null
            id: string
            nickname?: string | null
          } | null
        } | null
        readFlg?: boolean | null
        answerRequiredFlg?: boolean | null
        answeredFlg?: boolean | null
        favoriteFlgReceived: boolean
        favoriteFlgSent: boolean
      }>
    }>
  } | null
}

export type ListMigratedMailGqlQueryVariables = {
  filter?: SearchableMigratedMailFilterInput | null
  nextToken?: string | null
}

export type ListMigratedMailGqlQuery = {
  searchMigratedMails?: {
    __typename: "SearchableMigratedMailConnection"
    total?: number | null
    items: Array<{
      __typename: "MigratedMail"
      id: string
      title: string
      sender: string
      favoriteFlg: boolean
      sentDatetime: string
    } | null>
    nextToken?: string | null
  } | null
}

export type UpdateMigratedMailGqlMutationVariables = {
  input: UpdateMigratedMailInput
}

export type UpdateMigratedMailGqlMutation = {
  updateMigratedMail?: {
    __typename: "MigratedMail"
    id: string
  } | null
}

export type DeleteMigratedMailGqlMutationVariables = {
  input: DeleteMigratedMailInput
}

export type DeleteMigratedMailGqlMutation = {
  deleteMigratedMail?: {
    __typename: "MigratedMail"
    id: string
  } | null
}

export type GetFullPermissionAccountQueryVariables = {
  input?: GetPermissionAccountInput | null
}

export type GetFullPermissionAccountQuery = {
  getPermissionAccount?: {
    __typename: "GetPermissionAccountResponse"
    teamId: string
    management: {
      __typename: "PermissionAccountManagementType"
      team: {
        __typename: "TeamManagementType"
        canSetting: boolean
        canDelete: boolean
      }
      member: {
        __typename: "MemberManagementType"
        canInviteTeam: boolean
        canApproveMember: boolean
        canRoleSetting: boolean
        canDeleteMember: boolean
        canUpdateMemberInfo: boolean
        canExportCsv: boolean
      }
      group: {
        __typename: "GroupManagementType"
        canCreateGroup: boolean
        canUpdateGroup: boolean
        canUpdateMemberInfo: boolean
        canUpdateGroupLeader: boolean
        canDeleteGroup: boolean
      }
    }
    list: {
      __typename: "PermissionAccountListType"
      canVisibleListMember: boolean
      listGroup: {
        __typename: "AccessType"
        accessAll: boolean
        groupIds: Array<string>
      }
    }
    mail: {
      __typename: "PermissionAccountMailType"
      make: {
        __typename: "AccessType"
        accessAll: boolean
        groupIds: Array<string>
      }
      recv: {
        __typename: "RecvType"
        memberListPublishOff: {
          __typename: "ShowMail"
          showAnswerResultCount: {
            __typename: "AccessType"
            accessAll: boolean
            groupIds: Array<string>
          }
          showAnswerResultListMembers: {
            __typename: "AccessType"
            accessAll: boolean
            groupIds: Array<string>
          }
          showAnsweredCount: {
            __typename: "AccessType"
            accessAll: boolean
            groupIds: Array<string>
          }
          showAnsweredListMembers: {
            __typename: "AccessType"
            accessAll: boolean
            groupIds: Array<string>
          }
          showReadCount: {
            __typename: "AccessType"
            accessAll: boolean
            groupIds: Array<string>
          }
          showReadListMembers: {
            __typename: "AccessType"
            accessAll: boolean
            groupIds: Array<string>
          }
          showRecipientCount: {
            __typename: "AccessType"
            accessAll: boolean
            groupIds: Array<string>
          }
          showRecipientListMembers: {
            __typename: "AccessType"
            accessAll: boolean
            groupIds: Array<string>
          }
        }
        memberListPublishOn: {
          __typename: "ShowMail"
          showAnswerResultCount: {
            __typename: "AccessType"
            accessAll: boolean
            groupIds: Array<string>
          }
          showAnswerResultListMembers: {
            __typename: "AccessType"
            accessAll: boolean
            groupIds: Array<string>
          }
          showAnsweredCount: {
            __typename: "AccessType"
            accessAll: boolean
            groupIds: Array<string>
          }
          showAnsweredListMembers: {
            __typename: "AccessType"
            accessAll: boolean
            groupIds: Array<string>
          }
          showReadCount: {
            __typename: "AccessType"
            accessAll: boolean
            groupIds: Array<string>
          }
          showReadListMembers: {
            __typename: "AccessType"
            accessAll: boolean
            groupIds: Array<string>
          }
          showRecipientCount: {
            __typename: "AccessType"
            accessAll: boolean
            groupIds: Array<string>
          }
          showRecipientListMembers: {
            __typename: "AccessType"
            accessAll: boolean
            groupIds: Array<string>
          }
        }
        send: {
          __typename: "SendRecvType"
          memberListPublishOff: {
            __typename: "ShowMail"
            showAnswerResultCount: {
              __typename: "AccessType"
              accessAll: boolean
              groupIds: Array<string>
            }
            showAnswerResultListMembers: {
              __typename: "AccessType"
              accessAll: boolean
              groupIds: Array<string>
            }
            showAnsweredCount: {
              __typename: "AccessType"
              accessAll: boolean
              groupIds: Array<string>
            }
            showAnsweredListMembers: {
              __typename: "AccessType"
              accessAll: boolean
              groupIds: Array<string>
            }
            showReadCount: {
              __typename: "AccessType"
              accessAll: boolean
              groupIds: Array<string>
            }
            showReadListMembers: {
              __typename: "AccessType"
              accessAll: boolean
              groupIds: Array<string>
            }
            showRecipientCount: {
              __typename: "AccessType"
              accessAll: boolean
              groupIds: Array<string>
            }
            showRecipientListMembers: {
              __typename: "AccessType"
              accessAll: boolean
              groupIds: Array<string>
            }
          }
          memberListPublishOn: {
            __typename: "ShowMail"
            showAnswerResultCount: {
              __typename: "AccessType"
              accessAll: boolean
              groupIds: Array<string>
            }
            showAnswerResultListMembers: {
              __typename: "AccessType"
              accessAll: boolean
              groupIds: Array<string>
            }
            showAnsweredCount: {
              __typename: "AccessType"
              accessAll: boolean
              groupIds: Array<string>
            }
            showAnsweredListMembers: {
              __typename: "AccessType"
              accessAll: boolean
              groupIds: Array<string>
            }
            showReadCount: {
              __typename: "AccessType"
              accessAll: boolean
              groupIds: Array<string>
            }
            showReadListMembers: {
              __typename: "AccessType"
              accessAll: boolean
              groupIds: Array<string>
            }
            showRecipientCount: {
              __typename: "AccessType"
              accessAll: boolean
              groupIds: Array<string>
            }
            showRecipientListMembers: {
              __typename: "AccessType"
              accessAll: boolean
              groupIds: Array<string>
            }
          }
        }
      }
    }
  } | null
}

export type SignUpMutationVariables = {
  input: SignUpInput
}

export type SignUpMutation = {
  signUp: {
    __typename: "SignUpResponse"
    message?: string | null
    data?: {
      __typename: "InitiateAuthResponse"
      ChallengeName?: string | null
      Session?: string | null
      ChallengeParameters?: string | null
      AuthenticationResult?: {
        __typename: "AuthenticationResultType"
        AccessToken?: string | null
        ExpiresIn?: string | null
        TokenType?: string | null
        RefreshToken?: string | null
        IdToken?: string | null
        NewDeviceMetadata?: string | null
      } | null
    } | null
  }
}

export type SignInMutationVariables = {
  input: SignInInput
}

export type SignInMutation = {
  signIn: {
    __typename: "SignInResponse"
    message?: string | null
    data?: {
      __typename: "InitiateAuthResponse"
      ChallengeName?: string | null
      Session?: string | null
      ChallengeParameters?: string | null
      AuthenticationResult?: {
        __typename: "AuthenticationResultType"
        AccessToken?: string | null
        ExpiresIn?: string | null
        TokenType?: string | null
        RefreshToken?: string | null
        IdToken?: string | null
        NewDeviceMetadata?: string | null
      } | null
    } | null
  }
}

export type SignOutMutation = {
  signOut: {
    __typename: "UserAuthSimpleResponse"
    message?: string | null
  }
}

export type RegisterAuthCodeMutationVariables = {
  input: RegisterAuthCodeInput
}

export type RegisterAuthCodeMutation = {
  registerAuthCode: {
    __typename: "RegisterAuthCodeResponse"
    message?: string | null
    data?: {
      __typename: "RegisterAuthCodeData"
      auth?: {
        __typename: "InitiateAuthResponse"
        ChallengeName?: string | null
        Session?: string | null
        ChallengeParameters?: string | null
      } | null
      user?: {
        __typename: "RegisterAuthCodeUser"
        id?: string | null
        lastName?: string | null
        firstName?: string | null
        lastNameKana?: string | null
        firstNameKana?: string | null
        email?: string | null
        signupStatus?: string | null
      } | null
    } | null
  }
}

export type UpdateAuthMethodMutationVariables = {
  input: UpdateAuthMethodInput
}

export type UpdateAuthMethodMutation = {
  updateAuthMethod: {
    __typename: "UserAuthSimpleResponse"
    message?: string | null
  }
}

export type RegisterAuthMethodCodeMutationVariables = {
  input: RegisterAuthMethodCodeInput
}

export type RegisterAuthMethodCodeMutation = {
  registerAuthMethodCode: {
    __typename: "UserAuthSimpleResponse"
    message?: string | null
  }
}

export type DeletePhoneNumberMutation = {
  deletePhoneNumber: {
    __typename: "UserAuthSimpleResponse"
    message?: string | null
  }
}

export type DeleteAccountAuthMutation = {
  deleteAccountAuth: {
    __typename: "UserAuthSimpleResponse"
    message?: string | null
  }
}

export type ConfirmAccessTokenMutationVariables = {
  tokens: ConfirmAuthTokenInput
}

export type ConfirmAccessTokenMutation = {
  confirmAccessToken: {
    __typename: "ConfirmAuthTokenResponse"
    message?: string | null
    data?: {
      __typename: "ConfirmAuthTokenData"
      user?: {
        __typename: "Account"
        id: string
        email: string
        lastName: string
        firstName: string
        lastNameKana: string
        firstNameKana: string
        phoneNumber?: string | null
        gender?: Gender | null
        birthday?: string | null
        schoolGraduationYear?: number | null
        termsAgreedAt?: string | null
        agreedTermsVersion?: string | null
        matchingTermsAgreedAt?: string | null
        agreedMatchingTermsVersion?: string | null
        signupStatus: SignupStatus
        haveOldRa9TeamNotJoin: boolean
        migrateAccountId?: number | null
        createdAt: string
        updatedAt: string
        accountJobId?: string | null
        accountSchoolId?: string | null
        accountSchoolDepartmentId?: string | null
        accountPrefectureId?: string | null
        accountCityId?: string | null
      } | null
      AccessToken?: string | null
      RefreshToken?: string | null
    } | null
  }
}

export type InitAccountMutationVariables = {
  input: InitAccountInput
}

export type InitAccountMutation = {
  initAccount: {
    __typename: "IdResponse"
    id: string
  }
}

export type UpdateAccountMutationVariables = {
  input: UpdateAccountInput
}

export type UpdateAccountMutation = {
  updateAccount: {
    __typename: "IdResponse"
    id: string
  }
}

export type CreateAccountDeviceMutationVariables = {
  input: CreateAccountDeviceInput
}

export type CreateAccountDeviceMutation = {
  createAccountDevice: {
    __typename: "IdResponse"
    id: string
  }
}

export type DeleteAccountDeviceMutationVariables = {
  input: DeleteAccountDeviceInput
}

export type DeleteAccountDeviceMutation = {
  deleteAccountDevice: {
    __typename: "IdResponse"
    id: string
  }
}

export type DeleteAccountMutationVariables = {
  input?: DeleteAccountInput | null
}

export type DeleteAccountMutation = {
  deleteAccount: {
    __typename: "IdResponse"
    id: string
  }
}

export type NotifyAccountEmailUpdatedMutation = {
  notifyAccountEmailUpdated: {
    __typename: "IdResponse"
    id: string
  }
}

export type NotifyAccountPhoneNumberUpdatedMutation = {
  notifyAccountPhoneNumberUpdated: {
    __typename: "IdResponse"
    id: string
  }
}

export type GetTempTokenMutationVariables = {
  input?: GetTempTokenInput | null
}

export type GetTempTokenMutation = {
  getTempToken: {
    __typename: "GetTempTokenResponse"
    tempToken: string
  }
}

export type GetAccessTokenByTempTokenMutationVariables = {
  input?: GetAccessTokenByTempTokenInput | null
}

export type GetAccessTokenByTempTokenMutation = {
  getAccessTokenByTempToken: {
    __typename: "GetAccessTokenByTempTokenResponse"
    accessToken: string
  }
}

export type DeleteAllTeamNoticeMutation = {
  deleteAllTeamNotice: {
    __typename: "IdResponse"
    id: string
  }
}

export type CreateTeamMutationVariables = {
  input: CreateTeamInput
}

export type CreateTeamMutation = {
  createTeam: {
    __typename: "IdResponse"
    id: string
  }
}

export type UpdateTeamMutationVariables = {
  input: UpdateTeamInput
}

export type UpdateTeamMutation = {
  updateTeam: {
    __typename: "IdResponse"
    id: string
  }
}

export type DeleteTeamMutationVariables = {
  input: DeleteTeamInput
}

export type DeleteTeamMutation = {
  deleteTeam: {
    __typename: "IdResponse"
    id: string
  }
}

export type ReportTeamMutationVariables = {
  input: ReportTeamInput
}

export type ReportTeamMutation = {
  reportTeam: {
    __typename: "IdResponse"
    id: string
  }
}

export type GetOrCreateTempTeamIdMutation = {
  getOrCreateTempTeamId: {
    __typename: "IdResponse"
    id: string
  }
}

export type SendJoinRequestMutationVariables = {
  input: SendJoinRequestInput
}

export type SendJoinRequestMutation = {
  sendJoinRequest: {
    __typename: "IdResponse"
    id: string
  }
}

export type ApprovePreTeamMemberMutationVariables = {
  input: ApprovePreTeamMemberInput
}

export type ApprovePreTeamMemberMutation = {
  approvePreTeamMember: {
    __typename: "IdListResponse"
    ids: Array<string>
  }
}

export type RejectPreTeamMemberMutationVariables = {
  input: RejectPreTeamMemberInput
}

export type RejectPreTeamMemberMutation = {
  rejectPreTeamMember: {
    __typename: "IdListResponse"
    ids: Array<string>
  }
}

export type SendInvitationEmailMutationVariables = {
  input: SendInvitationEmailInput
}

export type SendInvitationEmailMutation = {
  sendInvitationEmail: {
    __typename: "IdResponse"
    id: string
  }
}

export type ResendInvitationEmailMutationVariables = {
  input: ResendInvitationEmailInput
}

export type ResendInvitationEmailMutation = {
  resendInvitationEmail: {
    __typename: "IdResponse"
    id: string
  }
}

export type SendMigrationInvitationEmailMutationVariables = {
  input: SendMigrationInvitationEmailInput
}

export type SendMigrationInvitationEmailMutation = {
  sendMigrationInvitationEmail: {
    __typename: "SendMigrationInvitationEmailResponse"
    id: string
    results?: Array<{
      __typename: "SendMigrationInvitationEmailResultType"
      email: string
      status: string
      error?: string | null
      deliverId: string
    }> | null
  }
}

export type UpdateTeamMemberMutationVariables = {
  input: UpdateTeamMemberInput
}

export type UpdateTeamMemberMutation = {
  updateTeamMember: {
    __typename: "IdResponse"
    id: string
  }
}

export type DeleteTeamMemberMutationVariables = {
  input: DeleteTeamMemberInput
}

export type DeleteTeamMemberMutation = {
  deleteTeamMember: {
    __typename: "IdResponse"
    id: string
  }
}

export type MultiDeleteTeamMemberMutationVariables = {
  input: IdListInput
}

export type MultiDeleteTeamMemberMutation = {
  multiDeleteTeamMember: {
    __typename: "IdListResponse"
    ids: Array<string>
  }
}

export type CreateGroupMutationVariables = {
  input: CreateGroupInput
}

export type CreateGroupMutation = {
  createGroup: {
    __typename: "IdResponse"
    id: string
  }
}

export type UpdateGroupMutationVariables = {
  input: UpdateGroupInput
}

export type UpdateGroupMutation = {
  updateGroup: {
    __typename: "IdResponse"
    id: string
  }
}

export type UpdateNameGroupAndGroupMembersMutationVariables = {
  input: UpdateNameGroupAndGroupMembersInput
}

export type UpdateNameGroupAndGroupMembersMutation = {
  updateNameGroupAndGroupMembers: {
    __typename: "IdResponse"
    id: string
  }
}

export type DeleteGroupMutationVariables = {
  input: DeleteGroupInput
}

export type DeleteGroupMutation = {
  deleteGroup: {
    __typename: "IdResponse"
    id: string
  }
}

export type UpdateAttendanceAnswerMutationVariables = {
  input: UpdateAttendanceAnswerInput
}

export type UpdateAttendanceAnswerMutation = {
  updateAttendanceAnswer: {
    __typename: "IdResponse"
    id: string
  }
}

export type UpdateQuestionnaireAnswerMutationVariables = {
  input: UpdateQuestionnaireAnswerInput
}

export type UpdateQuestionnaireAnswerMutation = {
  updateQuestionnaireAnswer: {
    __typename: "IdResponse"
    id: string
  }
}

export type UpdateScheduleAnswerMutationVariables = {
  input: UpdateScheduleAnswerInput
}

export type UpdateScheduleAnswerMutation = {
  updateScheduleAnswer: {
    __typename: "IdResponse"
    id: string
  }
}

export type MarkMailAsReadMutationVariables = {
  input: MarkMailAsReadInput
}

export type MarkMailAsReadMutation = {
  markMailAsRead: {
    __typename: "IdResponse"
    id: string
  }
}

export type CreateMailMutationVariables = {
  input: CreateMailInput
}

export type CreateMailMutation = {
  createMail: {
    __typename: "CreateMailResponse"
    id: string
    type: CreateMailResponseType
  }
}

export type UpdateMailMutationVariables = {
  input: UpdateMailInput
}

export type UpdateMailMutation = {
  updateMail: {
    __typename: "IdResponse"
    id: string
  }
}

export type DeleteMailMutationVariables = {
  input: DeleteMailInput
}

export type DeleteMailMutation = {
  deleteMail: {
    __typename: "IdResponse"
    id: string
  }
}

export type SendReminderMutationVariables = {
  input: SendReminderInput
}

export type SendReminderMutation = {
  sendReminder: {
    __typename: "IdResponse"
    id: string
  }
}

export type GetOrCreateTempMailIdMutationVariables = {
  input: GetOrCreateTempMailIdInput
}

export type GetOrCreateTempMailIdMutation = {
  getOrCreateTempMailId: {
    __typename: "IdResponse"
    id: string
  }
}

export type MultiDeleteSentMailMutationVariables = {
  input: MultiMailInput
}

export type MultiDeleteSentMailMutation = {
  multiDeleteSentMail: {
    __typename: "IdResponse"
    id: string
  }
}

export type MultiTrashReceivedMailMutationVariables = {
  input: MultiMailInput
}

export type MultiTrashReceivedMailMutation = {
  multiTrashReceivedMail: {
    __typename: "IdResponse"
    id: string
  }
}

export type MultiRestoreTrashedMailMutationVariables = {
  input: MultiMailInput
}

export type MultiRestoreTrashedMailMutation = {
  multiRestoreTrashedMail: {
    __typename: "IdResponse"
    id: string
  }
}

export type MultiDeleteTrashedMailMutationVariables = {
  input: MultiMailInput
}

export type MultiDeleteTrashedMailMutation = {
  multiDeleteTrashedMail: {
    __typename: "IdResponse"
    id: string
  }
}

export type CreateDraftMutationVariables = {
  input: CreateDraftInput
}

export type CreateDraftMutation = {
  createDraft: {
    __typename: "IdResponse"
    id: string
  }
}

export type MultiDeleteDraftMutationVariables = {
  input: IdListInput
}

export type MultiDeleteDraftMutation = {
  multiDeleteDraft: {
    __typename: "IdListResponse"
    ids: Array<string>
  }
}

export type CreateTemplateMutationVariables = {
  input: CreateTemplateInput
}

export type CreateTemplateMutation = {
  createTemplate: {
    __typename: "IdResponse"
    id: string
  }
}

export type MultiDeleteTemplateMutationVariables = {
  input: IdListInput
}

export type MultiDeleteTemplateMutation = {
  multiDeleteTemplate: {
    __typename: "IdListResponse"
    ids: Array<string>
  }
}

export type CreateThreadMessageMutationVariables = {
  input: CreateThreadMessageInput
}

export type CreateThreadMessageMutation = {
  createThreadMessage: {
    __typename: "IdResponse"
    id: string
  }
}

export type UpdateThreadMessageMutationVariables = {
  input: UpdateThreadMessageInput
}

export type UpdateThreadMessageMutation = {
  updateThreadMessage: {
    __typename: "IdResponse"
    id: string
  }
}

export type DeleteAccountImageMutation = {
  deleteAccountImage: {
    __typename: "IdResponse"
    id: string
  }
}

export type DeleteTeamImageMutationVariables = {
  input: DeleteTeamImageInput
}

export type DeleteTeamImageMutation = {
  deleteTeamImage: {
    __typename: "IdResponse"
    id: string
  }
}

export type DeleteTeamMemberImageMutationVariables = {
  input: DeleteTeamMemberImageInput
}

export type DeleteTeamMemberImageMutation = {
  deleteTeamMemberImage: {
    __typename: "IdResponse"
    id: string
  }
}

export type DeleteMatchingTeamImageMutationVariables = {
  input: DeleteMatchingTeamImageInput
}

export type DeleteMatchingTeamImageMutation = {
  deleteMatchingTeamImage: {
    __typename: "IdResponse"
    id: string
  }
}

export type DeleteMatchingTeamBgImageMutationVariables = {
  input: DeleteMatchingTeamBgImageInput
}

export type DeleteMatchingTeamBgImageMutation = {
  deleteMatchingTeamBgImage: {
    __typename: "IdResponse"
    id: string
  }
}

export type MigrateTeamMutationVariables = {
  input: MigrateTeamInput
}

export type MigrateTeamMutation = {
  migrateTeam: {
    __typename: "MigrateTeamResponse"
    newTeamId: string
    invitedMemberCount: number
    uninvitedMembers: Array<{
      __typename: "MigrateTeamUninvitedMember"
      memberId: number
      nickname: string
      reason: string
    }>
  }
}

export type MigrateMailMutationVariables = {
  input: MigrateMailInput
}

export type MigrateMailMutation = {
  migrateMail: {
    __typename: "IdResponse"
    id: string
  }
}

export type CreateTeamMemberFromPresetMutationVariables = {
  input: CreateTeamMemberFromPresetInput
}

export type CreateTeamMemberFromPresetMutation = {
  createTeamMemberFromPreset: {
    __typename: "IdResponse"
    id: string
  }
}

export type GetOrCreateTempMatchingOfferIdMutationVariables = {
  input: GetOrCreateTempMatchingOfferIdInput
}

export type GetOrCreateTempMatchingOfferIdMutation = {
  getOrCreateTempMatchingOfferId: {
    __typename: "IdResponse"
    id: string
  }
}

export type CreateMatchingOfferMutationVariables = {
  input: CreateMatchingOfferInput2
}

export type CreateMatchingOfferMutation = {
  createMatchingOffer: {
    __typename: "IdResponse"
    id: string
  }
}

export type CreateMatchingOfferDraftMutationVariables = {
  input: CreateMatchingOfferDraftInput2
}

export type CreateMatchingOfferDraftMutation = {
  createMatchingOfferDraft: {
    __typename: "IdResponse"
    id: string
  }
}

export type CreateMatchingApplicationMutationVariables = {
  input: CreateMatchingApplicationInput3
}

export type CreateMatchingApplicationMutation = {
  createMatchingApplication: {
    __typename: "IdResponse"
    id: string
  }
}

export type CreateMatchingMessageMutationVariables = {
  input: CreateMatchingMessageInput
}

export type CreateMatchingMessageMutation = {
  createMatchingMessage: {
    __typename: "IdResponse"
    id: string
  }
}

export type UpdateMatchingMessageMutationVariables = {
  input: UpdateMatchingMessageInput
}

export type UpdateMatchingMessageMutation = {
  updateMatchingMessage: {
    __typename: "IdResponse"
    id: string
  }
}

export type MarkMatchingMessageAsReadMutationVariables = {
  input: MarkMatchingMessageAsReadInput
}

export type MarkMatchingMessageAsReadMutation = {
  markMatchingMessageAsRead: {
    __typename: "IdResponse"
    id: string
  }
}

export type ReportMatchingTeamMutationVariables = {
  input: ReportMatchingTeamInput
}

export type ReportMatchingTeamMutation = {
  reportMatchingTeam: {
    __typename: "IdResponse"
    id: string
  }
}

export type DeleteMatchingOfferDraftByIdsMutationVariables = {
  input: IdListInput
}

export type DeleteMatchingOfferDraftByIdsMutation = {
  deleteMatchingOfferDraftByIds: {
    __typename: "IdListResponse"
    ids: Array<string>
  }
}

export type CreateInquiryMutationVariables = {
  input: CreateInquiryInput
}

export type CreateInquiryMutation = {
  createInquiry: {
    __typename: "IdResponse"
    id: string
  }
}

export type CreatePaidTeamInquiryMutationVariables = {
  input: CreateInquiryInput
}

export type CreatePaidTeamInquiryMutation = {
  createPaidTeamInquiry: {
    __typename: "IdResponse"
    id: string
  }
}

export type AgreeToTermsMutationVariables = {
  input?: AgreeToTermsInput | null
}

export type AgreeToTermsMutation = {
  agreeToTerms: {
    __typename: "IdResponse"
    id: string
  }
}

export type CreateAccountAdminNoticeMutationVariables = {
  input: CreateAccountAdminNoticeInput
}

export type CreateAccountAdminNoticeMutation = {
  createAccountAdminNotice: {
    __typename: "IdResponse"
    id: string
  }
}

export type CreatePaidVersionOrderMutationVariables = {
  input: CreatePaidVersionOrderInput
}

export type CreatePaidVersionOrderMutation = {
  createPaidVersionOrder: {
    __typename: "IdResponse"
    id: string
  }
}

export type ResendEmailPaidVersionOrderMutationVariables = {
  input: ResendEmailPaidVersionOrderInput
}

export type ResendEmailPaidVersionOrderMutation = {
  resendEmailPaidVersionOrder: {
    __typename: "IdResponse"
    id: string
  }
}

export type UpdatePaidVersionOrderMutationVariables = {
  input: UpdatePaidVersionOrderInput
}

export type UpdatePaidVersionOrderMutation = {
  updatePaidVersionOrder: {
    __typename: "IdResponse"
    id: string
  }
}

export type CancelPaidVersionOrderMutationVariables = {
  input: CancelPaidVersionOrderInput
}

export type CancelPaidVersionOrderMutation = {
  cancelPaidVersionOrder: {
    __typename: "IdResponse"
    id: string
  }
}

export type ChangePlanPaidVersionOrderMutationVariables = {
  input: ChangePlanPaidVersionOrderInput
}

export type ChangePlanPaidVersionOrderMutation = {
  changePlanPaidVersionOrder: {
    __typename: "IdResponse"
    id: string
  }
}

export type ExtendPaidVersionOrderMutationVariables = {
  input: ExtendPaidVersionOrderInput
}

export type ExtendPaidVersionOrderMutation = {
  extendPaidVersionOrder: {
    __typename: "IdResponse"
    id: string
  }
}

export type ApplicantAuthorityTransferMutationVariables = {
  input: ApplicantAuthorityTransferInput
}

export type ApplicantAuthorityTransferMutation = {
  applicantAuthorityTransfer: {
    __typename: "IdResponse"
    id: string
  }
}

export type UpdateNotificationSettingMutationVariables = {
  input: UpdateNotificationSettingInput
  condition?: ModelNotificationSettingConditionInput | null
}

export type UpdateNotificationSettingMutation = {
  updateNotificationSetting?: {
    __typename: "NotificationSetting"
    id: string
    mailReceivedNotice: {
      __typename: "MailReceivedNotice"
      push: boolean
      email: boolean
    }
    threadMessageReceivedNotice: {
      __typename: "ThreadMessageReceivedNotice"
      push: boolean
      email: boolean
    }
    joinRequestNotice: {
      __typename: "JoinRequestNotice"
      push: boolean
      email: boolean
    }
    teamMatchingNotice: {
      __typename: "TeamMatchingNotice"
      email: boolean
    }
    createdAt: string
    updatedAt: string
  } | null
}

export type DeleteTempTeamMutationVariables = {
  input: DeleteTempTeamInput
  condition?: ModelTempTeamConditionInput | null
}

export type DeleteTempTeamMutation = {
  deleteTempTeam?: {
    __typename: "TempTeam"
    accountId: string
    tempTeamId: string
    createdAt: string
    updatedAt: string
  } | null
}

export type _updateTeamMutationVariables = {
  input: UpdateTeamInput
  condition?: ModelTeamConditionInput | null
}

export type _updateTeamMutation = {
  _updateTeam?: {
    __typename: "Team"
    id: string
    name: string
    category?: {
      __typename: "TeamCategoryMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      activities?: {
        __typename: "ModelTeamCategoryActivityConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
    } | null
    activity?: {
      __typename: "TeamActivityMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      categories?: {
        __typename: "ModelTeamCategoryActivityConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
    } | null
    prefecture?: {
      __typename: "PrefectureMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      cities?: {
        __typename: "ModelCityMasterConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
    } | null
    city?: {
      __typename: "CityMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      createdAt: string
      updatedAt: string
      prefectureMasterCitiesId?: string | null
    } | null
    description: string
    requirementForMemberProfile?: string | null
    customQuestions?: Array<TeamCustomQuestion> | null
    showMemberListFlg: boolean
    invitationCode?: string | null
    teamMembers?: {
      __typename: "ModelTeamMemberConnection"
      items: Array<{
        __typename: "TeamMember"
        id: string
        memberSeq: number
        nickname: string
        nicknameKana: string
        role: string
        showEmailFlg?: boolean | null
        phoneNumber?: string | null
        showPhoneNumberFlg?: boolean | null
        belongs?: string | null
        showBelongsFlg?: boolean | null
        grade?: number | null
        teamIdGsi: string
        teamTeamMembersId?: string | null
        mailMigratedFlg?: boolean | null
        deleteFlg?: boolean | null
        accountTeamMembersId: string
        migrateTeamMemberId?: number | null
        createdAt: string
        updatedAt: string
        teamMemberNotificationSettingId?: string | null
      } | null>
      nextToken?: string | null
    } | null
    preTeamMembers?: {
      __typename: "ModelPreTeamMemberConnection"
      items: Array<{
        __typename: "PreTeamMember"
        id: string
        nickname: string
        nicknameKana: string
        showEmailFlg?: boolean | null
        phoneNumber?: string | null
        showPhoneNumberFlg?: boolean | null
        belongs?: string | null
        showBelongsFlg?: boolean | null
        grade?: number | null
        createdAt: string
        updatedAt: string
        accountPreTeamMembersId?: string | null
        teamPreTeamMembersId?: string | null
      } | null>
      nextToken?: string | null
    } | null
    invitationEmailStatuses?: {
      __typename: "ModelInvitationEmailStatusConnection"
      items: Array<{
        __typename: "InvitationEmailStatus"
        id: string
        email: string
        status: EmailStatus
        repicaDeliverId: string
        repicaKeyField: string
        repicaSentDate: string
        errorMessage?: string | null
        createdAt: string
        teamInvitationEmailStatusesId: string
        updatedAt: string
      } | null>
      nextToken?: string | null
    } | null
    groups?: {
      __typename: "ModelGroupConnection"
      items: Array<{
        __typename: "Group"
        id: string
        name?: string | null
        migrationId?: number | null
        createdAt: string
        updatedAt: string
        teamGroupsId?: string | null
      } | null>
      nextToken?: string | null
    } | null
    matchingOffers?: {
      __typename: "ModelMatchingOfferConnection"
      items: Array<{
        __typename: "MatchingOffer"
        id: string
        purpose: MatchingOfferPurpose
        title: string
        body: string
        date?: string | null
        startTime?: string | null
        endTime?: string | null
        place?: string | null
        placeURL?: string | null
        expense?: number | null
        expensePurpose?: string | null
        ageFrom?: number | null
        ageTo?: number | null
        offerCount?: number | null
        publishEndDate: string
        attachmentFileNames?: Array<string> | null
        teamId: string
        teamName: string
        deleteFlg?: boolean | null
        createdAt: string
        updatedAt: string
        teamMatchingOffersId?: string | null
        matchingOfferPrefectureId?: string | null
        matchingOfferCityId?: string | null
        matchingOfferActivityId?: string | null
      } | null>
      nextToken?: string | null
    } | null
    matchingOfferDrafts?: {
      __typename: "ModelMatchingOfferDraftConnection"
      items: Array<{
        __typename: "MatchingOfferDraft"
        id: string
        purpose?: MatchingOfferPurpose | null
        title: string
        body?: string | null
        date?: string | null
        startTime?: string | null
        endTime?: string | null
        place?: string | null
        placeURL?: string | null
        expense?: number | null
        expensePurpose?: string | null
        ageFrom?: number | null
        ageTo?: number | null
        offerCount?: number | null
        publishEndDate?: string | null
        createdAt: string
        updatedAt: string
        teamMatchingOfferDraftsId?: string | null
        matchingOfferDraftPrefectureId?: string | null
        matchingOfferDraftCityId?: string | null
      } | null>
      nextToken?: string | null
    } | null
    matchingApplications?: {
      __typename: "ModelMatchingApplicationConnection"
      items: Array<{
        __typename: "MatchingApplication"
        id: string
        offerTeamName: string
        offerTitle: string
        offerBody: string
        createdAt: string
        updatedAt: string
        teamMatchingApplicationsId?: string | null
        matchingOfferApplicationsId?: string | null
      } | null>
      nextToken?: string | null
    } | null
    blockingMatchingTeams?: {
      __typename: "ModelBlockingMatchingTeamConnection"
      items: Array<{
        __typename: "BlockingMatchingTeam"
        id: string
        blockingTeamId: string
        createdAt: string
        updatedAt: string
        teamBlockingMatchingTeamsId?: string | null
      } | null>
      nextToken?: string | null
    } | null
    paidVersionOrders?: {
      __typename: "ModelPaidVersionOrderConnection"
      items: Array<{
        __typename: "PaidVersionOrder"
        id: string
        orderNumber: string
        originalOrderId?: string | null
        orderStatus: string
        jobId?: string | null
        masterCode?: string | null
        zacStatus?: string | null
        supportedDate?: string | null
        address?: string | null
        orderType: string
        companyName?: string | null
        companyNameKana?: string | null
        corporateRepresentativeName?: string | null
        corporateRepresentativeNameKana?: string | null
        applicantPhone?: string | null
        applicantMail?: string | null
        applicantName?: string | null
        applicantPostcode?: string | null
        applicantPrefecture?: string | null
        applicantAddress?: string | null
        applicantBuildingName?: string | null
        applicantRemark?: string | null
        antiSocialCheck: string
        teamId?: string | null
        migrateTeamId?: number | null
        teamName?: string | null
        memberCount?: number | null
        applicantId?: string | null
        transferApplicantId?: string | null
        planId: string
        startDate: string
        endDate: string
        paymentStatus: string
        paymentDate?: string | null
        paymentDeadline?: string | null
        invoiceIssuedDate?: string | null
        paymentType: string
        paymentMethod: string
        invoiceAddressee?: string | null
        invoiceAddresseeKana?: string | null
        amount: number
        taxRate: number
        tax: number
        subTotal: number
        remark?: string | null
        adminId?: string | null
        createdAt: string
        updatedAt: string
      } | null>
      nextToken?: string | null
    } | null
    blockingMatchingTeamsByBlockingTeamId?: {
      __typename: "ModelBlockingMatchingTeamConnection"
      items: Array<{
        __typename: "BlockingMatchingTeam"
        id: string
        blockingTeamId: string
        createdAt: string
        updatedAt: string
        teamBlockingMatchingTeamsId?: string | null
      } | null>
      nextToken?: string | null
    } | null
    migrationId?: number | null
    paidFunctionEnabled?: boolean | null
    showAdsFlg?: boolean | null
    createdAt: string
    updatedAt: string
    teamCategoryId?: string | null
    teamActivityId?: string | null
    teamPrefectureId?: string | null
    teamCityId?: string | null
  } | null
}

export type UpdateTeamNoticeMutationVariables = {
  input: UpdateTeamNoticeInput
  condition?: ModelTeamNoticeConditionInput | null
}

export type UpdateTeamNoticeMutation = {
  updateTeamNotice?: {
    __typename: "TeamNotice"
    id: string
    noticeType: string
    title: string
    readFlg: boolean
    url: string
    team?: {
      __typename: "Team"
      id: string
      name: string
      category?: {
        __typename: "TeamCategoryMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      activity?: {
        __typename: "TeamActivityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      prefecture?: {
        __typename: "PrefectureMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      city?: {
        __typename: "CityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
        prefectureMasterCitiesId?: string | null
      } | null
      description: string
      requirementForMemberProfile?: string | null
      customQuestions?: Array<TeamCustomQuestion> | null
      showMemberListFlg: boolean
      invitationCode?: string | null
      teamMembers?: {
        __typename: "ModelTeamMemberConnection"
        nextToken?: string | null
      } | null
      preTeamMembers?: {
        __typename: "ModelPreTeamMemberConnection"
        nextToken?: string | null
      } | null
      invitationEmailStatuses?: {
        __typename: "ModelInvitationEmailStatusConnection"
        nextToken?: string | null
      } | null
      groups?: {
        __typename: "ModelGroupConnection"
        nextToken?: string | null
      } | null
      matchingOffers?: {
        __typename: "ModelMatchingOfferConnection"
        nextToken?: string | null
      } | null
      matchingOfferDrafts?: {
        __typename: "ModelMatchingOfferDraftConnection"
        nextToken?: string | null
      } | null
      matchingApplications?: {
        __typename: "ModelMatchingApplicationConnection"
        nextToken?: string | null
      } | null
      blockingMatchingTeams?: {
        __typename: "ModelBlockingMatchingTeamConnection"
        nextToken?: string | null
      } | null
      paidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      blockingMatchingTeamsByBlockingTeamId?: {
        __typename: "ModelBlockingMatchingTeamConnection"
        nextToken?: string | null
      } | null
      migrationId?: number | null
      paidFunctionEnabled?: boolean | null
      showAdsFlg?: boolean | null
      createdAt: string
      updatedAt: string
      teamCategoryId?: string | null
      teamActivityId?: string | null
      teamPrefectureId?: string | null
      teamCityId?: string | null
    } | null
    createdAt: string
    createdYearMonth?: number | null
    createdDate?: string | null
    accountTeamNoticesId: string
    updatedAt: string
    teamMemberTeamNoticeId?: string | null
    teamNoticeTeamId?: string | null
  } | null
}

export type DeleteTempMailMutationVariables = {
  input: DeleteTempMailInput
  condition?: ModelTempMailConditionInput | null
}

export type DeleteTempMailMutation = {
  deleteTempMail?: {
    __typename: "TempMail"
    teamMemberId: string
    tempMailId: string
    createdAt: string
    updatedAt: string
  } | null
}

export type UpdateTeamMemberMailMutationVariables = {
  input: UpdateTeamMemberMailInput
  condition?: ModelTeamMemberMailConditionInput | null
}

export type UpdateTeamMemberMailMutation = {
  updateTeamMemberMail?: {
    __typename: "TeamMemberMail"
    id: string
    receivedFlg: boolean
    sentFlg: boolean
    readFlg: boolean
    readAt?: string | null
    favoriteFlgReceived: boolean
    favoriteFlgSent: boolean
    answerRequiredFlg: boolean
    responseDueDatetimeForFilter: string
    answeredFlg: boolean
    answeredAt?: string | null
    attendanceAnswer?: YesNoAnswer | null
    questionnaireAnswers?: Array<{
      __typename: "QuestionnaireAnswers"
      answer: Array<number>
    }> | null
    scheduleAnswers?: Array<YesNoAnswer> | null
    senderId: string
    mailType: string
    title: string
    body: string
    hasAttachment: boolean
    hasThread: boolean
    trashedFlgReceived: boolean
    deletedFlgReceived: boolean
    deletedFlgSent: boolean
    count: number
    teamMemberID?: string | null
    mailID?: string | null
    teamMember?: {
      __typename: "TeamMember"
      id: string
      memberSeq: number
      nickname: string
      nicknameKana: string
      role: string
      showEmailFlg?: boolean | null
      phoneNumber?: string | null
      showPhoneNumberFlg?: boolean | null
      belongs?: string | null
      showBelongsFlg?: boolean | null
      grade?: number | null
      account?: {
        __typename: "Account"
        id: string
        email: string
        lastName: string
        firstName: string
        lastNameKana: string
        firstNameKana: string
        phoneNumber?: string | null
        gender?: Gender | null
        birthday?: string | null
        schoolGraduationYear?: number | null
        termsAgreedAt?: string | null
        agreedTermsVersion?: string | null
        matchingTermsAgreedAt?: string | null
        agreedMatchingTermsVersion?: string | null
        signupStatus: SignupStatus
        haveOldRa9TeamNotJoin: boolean
        migrateAccountId?: number | null
        createdAt: string
        updatedAt: string
        accountJobId?: string | null
        accountSchoolId?: string | null
        accountSchoolDepartmentId?: string | null
        accountPrefectureId?: string | null
        accountCityId?: string | null
      } | null
      team?: {
        __typename: "Team"
        id: string
        name: string
        description: string
        requirementForMemberProfile?: string | null
        customQuestions?: Array<TeamCustomQuestion> | null
        showMemberListFlg: boolean
        invitationCode?: string | null
        migrationId?: number | null
        paidFunctionEnabled?: boolean | null
        showAdsFlg?: boolean | null
        createdAt: string
        updatedAt: string
        teamCategoryId?: string | null
        teamActivityId?: string | null
        teamPrefectureId?: string | null
        teamCityId?: string | null
      } | null
      teamIdGsi: string
      teamTeamMembersId?: string | null
      notificationSetting?: {
        __typename: "NotificationSetting"
        id: string
        createdAt: string
        updatedAt: string
      } | null
      teamNotice?: {
        __typename: "ModelTeamNoticeConnection"
        nextToken?: string | null
      } | null
      groups?: {
        __typename: "ModelGroupMemberConnection"
        nextToken?: string | null
      } | null
      teamMemberMails?: {
        __typename: "ModelTeamMemberMailConnection"
        nextToken?: string | null
      } | null
      reservedMails?: {
        __typename: "ModelReservedMailConnection"
        nextToken?: string | null
      } | null
      drafts?: {
        __typename: "ModelDraftConnection"
        nextToken?: string | null
      } | null
      templates?: {
        __typename: "ModelTemplateConnection"
        nextToken?: string | null
      } | null
      migratedMails?: {
        __typename: "ModelMigratedMailConnection"
        nextToken?: string | null
      } | null
      mailMigratedFlg?: boolean | null
      favoriteMatchingOffer?: {
        __typename: "ModelFavoriteMatchingOfferConnection"
        nextToken?: string | null
      } | null
      deleteFlg?: boolean | null
      accountTeamMembersId: string
      migrateTeamMemberId?: number | null
      orderHistories?: {
        __typename: "ModelOrderHistoryConnection"
        nextToken?: string | null
      } | null
      paidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      transferPaidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
      teamMemberNotificationSettingId?: string | null
    } | null
    mail?: {
      __typename: "Mail"
      id: string
      teamId: string
      mailType: string
      title: string
      sender?: {
        __typename: "TeamMember"
        id: string
        memberSeq: number
        nickname: string
        nicknameKana: string
        role: string
        showEmailFlg?: boolean | null
        phoneNumber?: string | null
        showPhoneNumberFlg?: boolean | null
        belongs?: string | null
        showBelongsFlg?: boolean | null
        grade?: number | null
        teamIdGsi: string
        teamTeamMembersId?: string | null
        mailMigratedFlg?: boolean | null
        deleteFlg?: boolean | null
        accountTeamMembersId: string
        migrateTeamMemberId?: number | null
        createdAt: string
        updatedAt: string
        teamMemberNotificationSettingId?: string | null
      } | null
      body: string
      sendTo: {
        __typename: "SendTo"
        allMembersFlg?: boolean | null
        managersFlg?: boolean | null
        groupIds?: Array<string> | null
        memberIds?: Array<string> | null
      }
      attachmentFileNames?: Array<string> | null
      startDate?: string | null
      startTime?: string | null
      endDate?: string | null
      endTime?: string | null
      questionnaires?: Array<{
        __typename: "Questionnaires"
        answerSelectType: AnswerSelectType
        question: string
        choices: Array<string>
      }> | null
      candidateDatetimes?: Array<{
        __typename: "CandidateDatetimes"
        startDate: string
        startTime?: string | null
        endDate?: string | null
        endTime?: string | null
      }> | null
      shareManagerFlg?: number | null
      showAnswerFlg?: boolean | null
      responseDueDatetime?: string | null
      remindDate: string
      remindDatetime?: string | null
      remindExecDatetime?: string | null
      sendScheduledAt?: string | null
      sentAt: string
      teamMemberMails?: {
        __typename: "ModelTeamMemberMailConnection"
        nextToken?: string | null
      } | null
      threads?: {
        __typename: "ModelThreadConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
      mailSenderId?: string | null
    } | null
    createdYearMonth?: number | null
    createdDate?: string | null
    createdAt: string
    updatedAt: string
  } | null
}

export type DeleteTeamMemberMailMutationVariables = {
  input: DeleteTeamMemberMailInput
  condition?: ModelTeamMemberMailConditionInput | null
}

export type DeleteTeamMemberMailMutation = {
  deleteTeamMemberMail?: {
    __typename: "TeamMemberMail"
    id: string
    receivedFlg: boolean
    sentFlg: boolean
    readFlg: boolean
    readAt?: string | null
    favoriteFlgReceived: boolean
    favoriteFlgSent: boolean
    answerRequiredFlg: boolean
    responseDueDatetimeForFilter: string
    answeredFlg: boolean
    answeredAt?: string | null
    attendanceAnswer?: YesNoAnswer | null
    questionnaireAnswers?: Array<{
      __typename: "QuestionnaireAnswers"
      answer: Array<number>
    }> | null
    scheduleAnswers?: Array<YesNoAnswer> | null
    senderId: string
    mailType: string
    title: string
    body: string
    hasAttachment: boolean
    hasThread: boolean
    trashedFlgReceived: boolean
    deletedFlgReceived: boolean
    deletedFlgSent: boolean
    count: number
    teamMemberID?: string | null
    mailID?: string | null
    teamMember?: {
      __typename: "TeamMember"
      id: string
      memberSeq: number
      nickname: string
      nicknameKana: string
      role: string
      showEmailFlg?: boolean | null
      phoneNumber?: string | null
      showPhoneNumberFlg?: boolean | null
      belongs?: string | null
      showBelongsFlg?: boolean | null
      grade?: number | null
      account?: {
        __typename: "Account"
        id: string
        email: string
        lastName: string
        firstName: string
        lastNameKana: string
        firstNameKana: string
        phoneNumber?: string | null
        gender?: Gender | null
        birthday?: string | null
        schoolGraduationYear?: number | null
        termsAgreedAt?: string | null
        agreedTermsVersion?: string | null
        matchingTermsAgreedAt?: string | null
        agreedMatchingTermsVersion?: string | null
        signupStatus: SignupStatus
        haveOldRa9TeamNotJoin: boolean
        migrateAccountId?: number | null
        createdAt: string
        updatedAt: string
        accountJobId?: string | null
        accountSchoolId?: string | null
        accountSchoolDepartmentId?: string | null
        accountPrefectureId?: string | null
        accountCityId?: string | null
      } | null
      team?: {
        __typename: "Team"
        id: string
        name: string
        description: string
        requirementForMemberProfile?: string | null
        customQuestions?: Array<TeamCustomQuestion> | null
        showMemberListFlg: boolean
        invitationCode?: string | null
        migrationId?: number | null
        paidFunctionEnabled?: boolean | null
        showAdsFlg?: boolean | null
        createdAt: string
        updatedAt: string
        teamCategoryId?: string | null
        teamActivityId?: string | null
        teamPrefectureId?: string | null
        teamCityId?: string | null
      } | null
      teamIdGsi: string
      teamTeamMembersId?: string | null
      notificationSetting?: {
        __typename: "NotificationSetting"
        id: string
        createdAt: string
        updatedAt: string
      } | null
      teamNotice?: {
        __typename: "ModelTeamNoticeConnection"
        nextToken?: string | null
      } | null
      groups?: {
        __typename: "ModelGroupMemberConnection"
        nextToken?: string | null
      } | null
      teamMemberMails?: {
        __typename: "ModelTeamMemberMailConnection"
        nextToken?: string | null
      } | null
      reservedMails?: {
        __typename: "ModelReservedMailConnection"
        nextToken?: string | null
      } | null
      drafts?: {
        __typename: "ModelDraftConnection"
        nextToken?: string | null
      } | null
      templates?: {
        __typename: "ModelTemplateConnection"
        nextToken?: string | null
      } | null
      migratedMails?: {
        __typename: "ModelMigratedMailConnection"
        nextToken?: string | null
      } | null
      mailMigratedFlg?: boolean | null
      favoriteMatchingOffer?: {
        __typename: "ModelFavoriteMatchingOfferConnection"
        nextToken?: string | null
      } | null
      deleteFlg?: boolean | null
      accountTeamMembersId: string
      migrateTeamMemberId?: number | null
      orderHistories?: {
        __typename: "ModelOrderHistoryConnection"
        nextToken?: string | null
      } | null
      paidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      transferPaidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
      teamMemberNotificationSettingId?: string | null
    } | null
    mail?: {
      __typename: "Mail"
      id: string
      teamId: string
      mailType: string
      title: string
      sender?: {
        __typename: "TeamMember"
        id: string
        memberSeq: number
        nickname: string
        nicknameKana: string
        role: string
        showEmailFlg?: boolean | null
        phoneNumber?: string | null
        showPhoneNumberFlg?: boolean | null
        belongs?: string | null
        showBelongsFlg?: boolean | null
        grade?: number | null
        teamIdGsi: string
        teamTeamMembersId?: string | null
        mailMigratedFlg?: boolean | null
        deleteFlg?: boolean | null
        accountTeamMembersId: string
        migrateTeamMemberId?: number | null
        createdAt: string
        updatedAt: string
        teamMemberNotificationSettingId?: string | null
      } | null
      body: string
      sendTo: {
        __typename: "SendTo"
        allMembersFlg?: boolean | null
        managersFlg?: boolean | null
        groupIds?: Array<string> | null
        memberIds?: Array<string> | null
      }
      attachmentFileNames?: Array<string> | null
      startDate?: string | null
      startTime?: string | null
      endDate?: string | null
      endTime?: string | null
      questionnaires?: Array<{
        __typename: "Questionnaires"
        answerSelectType: AnswerSelectType
        question: string
        choices: Array<string>
      }> | null
      candidateDatetimes?: Array<{
        __typename: "CandidateDatetimes"
        startDate: string
        startTime?: string | null
        endDate?: string | null
        endTime?: string | null
      }> | null
      shareManagerFlg?: number | null
      showAnswerFlg?: boolean | null
      responseDueDatetime?: string | null
      remindDate: string
      remindDatetime?: string | null
      remindExecDatetime?: string | null
      sendScheduledAt?: string | null
      sentAt: string
      teamMemberMails?: {
        __typename: "ModelTeamMemberMailConnection"
        nextToken?: string | null
      } | null
      threads?: {
        __typename: "ModelThreadConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
      mailSenderId?: string | null
    } | null
    createdYearMonth?: number | null
    createdDate?: string | null
    createdAt: string
    updatedAt: string
  } | null
}

export type UpdateReservedMailMutationVariables = {
  input: UpdateReservedMailInput
  condition?: ModelReservedMailConditionInput | null
}

export type UpdateReservedMailMutation = {
  updateReservedMail?: {
    __typename: "ReservedMail"
    id: string
    teamId: string
    mailType: string
    title: string
    teamMember?: {
      __typename: "TeamMember"
      id: string
      memberSeq: number
      nickname: string
      nicknameKana: string
      role: string
      showEmailFlg?: boolean | null
      phoneNumber?: string | null
      showPhoneNumberFlg?: boolean | null
      belongs?: string | null
      showBelongsFlg?: boolean | null
      grade?: number | null
      account?: {
        __typename: "Account"
        id: string
        email: string
        lastName: string
        firstName: string
        lastNameKana: string
        firstNameKana: string
        phoneNumber?: string | null
        gender?: Gender | null
        birthday?: string | null
        schoolGraduationYear?: number | null
        termsAgreedAt?: string | null
        agreedTermsVersion?: string | null
        matchingTermsAgreedAt?: string | null
        agreedMatchingTermsVersion?: string | null
        signupStatus: SignupStatus
        haveOldRa9TeamNotJoin: boolean
        migrateAccountId?: number | null
        createdAt: string
        updatedAt: string
        accountJobId?: string | null
        accountSchoolId?: string | null
        accountSchoolDepartmentId?: string | null
        accountPrefectureId?: string | null
        accountCityId?: string | null
      } | null
      team?: {
        __typename: "Team"
        id: string
        name: string
        description: string
        requirementForMemberProfile?: string | null
        customQuestions?: Array<TeamCustomQuestion> | null
        showMemberListFlg: boolean
        invitationCode?: string | null
        migrationId?: number | null
        paidFunctionEnabled?: boolean | null
        showAdsFlg?: boolean | null
        createdAt: string
        updatedAt: string
        teamCategoryId?: string | null
        teamActivityId?: string | null
        teamPrefectureId?: string | null
        teamCityId?: string | null
      } | null
      teamIdGsi: string
      teamTeamMembersId?: string | null
      notificationSetting?: {
        __typename: "NotificationSetting"
        id: string
        createdAt: string
        updatedAt: string
      } | null
      teamNotice?: {
        __typename: "ModelTeamNoticeConnection"
        nextToken?: string | null
      } | null
      groups?: {
        __typename: "ModelGroupMemberConnection"
        nextToken?: string | null
      } | null
      teamMemberMails?: {
        __typename: "ModelTeamMemberMailConnection"
        nextToken?: string | null
      } | null
      reservedMails?: {
        __typename: "ModelReservedMailConnection"
        nextToken?: string | null
      } | null
      drafts?: {
        __typename: "ModelDraftConnection"
        nextToken?: string | null
      } | null
      templates?: {
        __typename: "ModelTemplateConnection"
        nextToken?: string | null
      } | null
      migratedMails?: {
        __typename: "ModelMigratedMailConnection"
        nextToken?: string | null
      } | null
      mailMigratedFlg?: boolean | null
      favoriteMatchingOffer?: {
        __typename: "ModelFavoriteMatchingOfferConnection"
        nextToken?: string | null
      } | null
      deleteFlg?: boolean | null
      accountTeamMembersId: string
      migrateTeamMemberId?: number | null
      orderHistories?: {
        __typename: "ModelOrderHistoryConnection"
        nextToken?: string | null
      } | null
      paidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      transferPaidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
      teamMemberNotificationSettingId?: string | null
    } | null
    body: string
    sendTo: {
      __typename: "SendTo"
      allMembersFlg?: boolean | null
      managersFlg?: boolean | null
      groupIds?: Array<string> | null
      memberIds?: Array<string> | null
    }
    attachmentFileNames?: Array<string> | null
    startDate?: string | null
    startTime?: string | null
    endDate?: string | null
    endTime?: string | null
    questionnaires?: Array<{
      __typename: "Questionnaires"
      answerSelectType: AnswerSelectType
      question: string
      choices: Array<string>
    }> | null
    candidateDatetimes?: Array<{
      __typename: "CandidateDatetimes"
      startDate: string
      startTime?: string | null
      endDate?: string | null
      endTime?: string | null
    }> | null
    shareManagerFlg?: number | null
    showAnswerFlg?: boolean | null
    responseDueDatetime?: string | null
    remindDatetime?: string | null
    sendScheduledDate: string
    sendScheduledAt: string
    createdAt: string
    updatedAt: string
    teamMemberReservedMailsId?: string | null
  } | null
}

export type DeleteReservedMailMutationVariables = {
  input: DeleteReservedMailInput
  condition?: ModelReservedMailConditionInput | null
}

export type DeleteReservedMailMutation = {
  deleteReservedMail?: {
    __typename: "ReservedMail"
    id: string
    teamId: string
    mailType: string
    title: string
    teamMember?: {
      __typename: "TeamMember"
      id: string
      memberSeq: number
      nickname: string
      nicknameKana: string
      role: string
      showEmailFlg?: boolean | null
      phoneNumber?: string | null
      showPhoneNumberFlg?: boolean | null
      belongs?: string | null
      showBelongsFlg?: boolean | null
      grade?: number | null
      account?: {
        __typename: "Account"
        id: string
        email: string
        lastName: string
        firstName: string
        lastNameKana: string
        firstNameKana: string
        phoneNumber?: string | null
        gender?: Gender | null
        birthday?: string | null
        schoolGraduationYear?: number | null
        termsAgreedAt?: string | null
        agreedTermsVersion?: string | null
        matchingTermsAgreedAt?: string | null
        agreedMatchingTermsVersion?: string | null
        signupStatus: SignupStatus
        haveOldRa9TeamNotJoin: boolean
        migrateAccountId?: number | null
        createdAt: string
        updatedAt: string
        accountJobId?: string | null
        accountSchoolId?: string | null
        accountSchoolDepartmentId?: string | null
        accountPrefectureId?: string | null
        accountCityId?: string | null
      } | null
      team?: {
        __typename: "Team"
        id: string
        name: string
        description: string
        requirementForMemberProfile?: string | null
        customQuestions?: Array<TeamCustomQuestion> | null
        showMemberListFlg: boolean
        invitationCode?: string | null
        migrationId?: number | null
        paidFunctionEnabled?: boolean | null
        showAdsFlg?: boolean | null
        createdAt: string
        updatedAt: string
        teamCategoryId?: string | null
        teamActivityId?: string | null
        teamPrefectureId?: string | null
        teamCityId?: string | null
      } | null
      teamIdGsi: string
      teamTeamMembersId?: string | null
      notificationSetting?: {
        __typename: "NotificationSetting"
        id: string
        createdAt: string
        updatedAt: string
      } | null
      teamNotice?: {
        __typename: "ModelTeamNoticeConnection"
        nextToken?: string | null
      } | null
      groups?: {
        __typename: "ModelGroupMemberConnection"
        nextToken?: string | null
      } | null
      teamMemberMails?: {
        __typename: "ModelTeamMemberMailConnection"
        nextToken?: string | null
      } | null
      reservedMails?: {
        __typename: "ModelReservedMailConnection"
        nextToken?: string | null
      } | null
      drafts?: {
        __typename: "ModelDraftConnection"
        nextToken?: string | null
      } | null
      templates?: {
        __typename: "ModelTemplateConnection"
        nextToken?: string | null
      } | null
      migratedMails?: {
        __typename: "ModelMigratedMailConnection"
        nextToken?: string | null
      } | null
      mailMigratedFlg?: boolean | null
      favoriteMatchingOffer?: {
        __typename: "ModelFavoriteMatchingOfferConnection"
        nextToken?: string | null
      } | null
      deleteFlg?: boolean | null
      accountTeamMembersId: string
      migrateTeamMemberId?: number | null
      orderHistories?: {
        __typename: "ModelOrderHistoryConnection"
        nextToken?: string | null
      } | null
      paidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      transferPaidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
      teamMemberNotificationSettingId?: string | null
    } | null
    body: string
    sendTo: {
      __typename: "SendTo"
      allMembersFlg?: boolean | null
      managersFlg?: boolean | null
      groupIds?: Array<string> | null
      memberIds?: Array<string> | null
    }
    attachmentFileNames?: Array<string> | null
    startDate?: string | null
    startTime?: string | null
    endDate?: string | null
    endTime?: string | null
    questionnaires?: Array<{
      __typename: "Questionnaires"
      answerSelectType: AnswerSelectType
      question: string
      choices: Array<string>
    }> | null
    candidateDatetimes?: Array<{
      __typename: "CandidateDatetimes"
      startDate: string
      startTime?: string | null
      endDate?: string | null
      endTime?: string | null
    }> | null
    shareManagerFlg?: number | null
    showAnswerFlg?: boolean | null
    responseDueDatetime?: string | null
    remindDatetime?: string | null
    sendScheduledDate: string
    sendScheduledAt: string
    createdAt: string
    updatedAt: string
    teamMemberReservedMailsId?: string | null
  } | null
}

export type UpdateDraftMutationVariables = {
  input: UpdateDraftInput
  condition?: ModelDraftConditionInput | null
}

export type UpdateDraftMutation = {
  updateDraft?: {
    __typename: "Draft"
    id: string
    mailType: string
    title: string
    teamMember?: {
      __typename: "TeamMember"
      id: string
      memberSeq: number
      nickname: string
      nicknameKana: string
      role: string
      showEmailFlg?: boolean | null
      phoneNumber?: string | null
      showPhoneNumberFlg?: boolean | null
      belongs?: string | null
      showBelongsFlg?: boolean | null
      grade?: number | null
      account?: {
        __typename: "Account"
        id: string
        email: string
        lastName: string
        firstName: string
        lastNameKana: string
        firstNameKana: string
        phoneNumber?: string | null
        gender?: Gender | null
        birthday?: string | null
        schoolGraduationYear?: number | null
        termsAgreedAt?: string | null
        agreedTermsVersion?: string | null
        matchingTermsAgreedAt?: string | null
        agreedMatchingTermsVersion?: string | null
        signupStatus: SignupStatus
        haveOldRa9TeamNotJoin: boolean
        migrateAccountId?: number | null
        createdAt: string
        updatedAt: string
        accountJobId?: string | null
        accountSchoolId?: string | null
        accountSchoolDepartmentId?: string | null
        accountPrefectureId?: string | null
        accountCityId?: string | null
      } | null
      team?: {
        __typename: "Team"
        id: string
        name: string
        description: string
        requirementForMemberProfile?: string | null
        customQuestions?: Array<TeamCustomQuestion> | null
        showMemberListFlg: boolean
        invitationCode?: string | null
        migrationId?: number | null
        paidFunctionEnabled?: boolean | null
        showAdsFlg?: boolean | null
        createdAt: string
        updatedAt: string
        teamCategoryId?: string | null
        teamActivityId?: string | null
        teamPrefectureId?: string | null
        teamCityId?: string | null
      } | null
      teamIdGsi: string
      teamTeamMembersId?: string | null
      notificationSetting?: {
        __typename: "NotificationSetting"
        id: string
        createdAt: string
        updatedAt: string
      } | null
      teamNotice?: {
        __typename: "ModelTeamNoticeConnection"
        nextToken?: string | null
      } | null
      groups?: {
        __typename: "ModelGroupMemberConnection"
        nextToken?: string | null
      } | null
      teamMemberMails?: {
        __typename: "ModelTeamMemberMailConnection"
        nextToken?: string | null
      } | null
      reservedMails?: {
        __typename: "ModelReservedMailConnection"
        nextToken?: string | null
      } | null
      drafts?: {
        __typename: "ModelDraftConnection"
        nextToken?: string | null
      } | null
      templates?: {
        __typename: "ModelTemplateConnection"
        nextToken?: string | null
      } | null
      migratedMails?: {
        __typename: "ModelMigratedMailConnection"
        nextToken?: string | null
      } | null
      mailMigratedFlg?: boolean | null
      favoriteMatchingOffer?: {
        __typename: "ModelFavoriteMatchingOfferConnection"
        nextToken?: string | null
      } | null
      deleteFlg?: boolean | null
      accountTeamMembersId: string
      migrateTeamMemberId?: number | null
      orderHistories?: {
        __typename: "ModelOrderHistoryConnection"
        nextToken?: string | null
      } | null
      paidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      transferPaidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
      teamMemberNotificationSettingId?: string | null
    } | null
    body: string
    sendTo?: {
      __typename: "SendTo"
      allMembersFlg?: boolean | null
      managersFlg?: boolean | null
      groupIds?: Array<string> | null
      memberIds?: Array<string> | null
    } | null
    attachmentFileNames?: Array<string> | null
    startDate?: string | null
    startTime?: string | null
    endDate?: string | null
    endTime?: string | null
    questionnaires?: Array<{
      __typename: "Questionnaires"
      answerSelectType: AnswerSelectType
      question: string
      choices: Array<string>
    }> | null
    candidateDatetimes?: Array<{
      __typename: "CandidateDatetimes"
      startDate: string
      startTime?: string | null
      endDate?: string | null
      endTime?: string | null
    }> | null
    shareManagerFlg?: number | null
    showAnswerFlg?: boolean | null
    responseDueDatetime?: string | null
    remindDatetime?: string | null
    sendScheduledAt?: string | null
    createdAt: string
    updatedAt: string
    teamMemberDraftsId?: string | null
  } | null
}

export type DeleteDraftMutationVariables = {
  input: DeleteDraftInput
  condition?: ModelDraftConditionInput | null
}

export type DeleteDraftMutation = {
  deleteDraft?: {
    __typename: "Draft"
    id: string
    mailType: string
    title: string
    teamMember?: {
      __typename: "TeamMember"
      id: string
      memberSeq: number
      nickname: string
      nicknameKana: string
      role: string
      showEmailFlg?: boolean | null
      phoneNumber?: string | null
      showPhoneNumberFlg?: boolean | null
      belongs?: string | null
      showBelongsFlg?: boolean | null
      grade?: number | null
      account?: {
        __typename: "Account"
        id: string
        email: string
        lastName: string
        firstName: string
        lastNameKana: string
        firstNameKana: string
        phoneNumber?: string | null
        gender?: Gender | null
        birthday?: string | null
        schoolGraduationYear?: number | null
        termsAgreedAt?: string | null
        agreedTermsVersion?: string | null
        matchingTermsAgreedAt?: string | null
        agreedMatchingTermsVersion?: string | null
        signupStatus: SignupStatus
        haveOldRa9TeamNotJoin: boolean
        migrateAccountId?: number | null
        createdAt: string
        updatedAt: string
        accountJobId?: string | null
        accountSchoolId?: string | null
        accountSchoolDepartmentId?: string | null
        accountPrefectureId?: string | null
        accountCityId?: string | null
      } | null
      team?: {
        __typename: "Team"
        id: string
        name: string
        description: string
        requirementForMemberProfile?: string | null
        customQuestions?: Array<TeamCustomQuestion> | null
        showMemberListFlg: boolean
        invitationCode?: string | null
        migrationId?: number | null
        paidFunctionEnabled?: boolean | null
        showAdsFlg?: boolean | null
        createdAt: string
        updatedAt: string
        teamCategoryId?: string | null
        teamActivityId?: string | null
        teamPrefectureId?: string | null
        teamCityId?: string | null
      } | null
      teamIdGsi: string
      teamTeamMembersId?: string | null
      notificationSetting?: {
        __typename: "NotificationSetting"
        id: string
        createdAt: string
        updatedAt: string
      } | null
      teamNotice?: {
        __typename: "ModelTeamNoticeConnection"
        nextToken?: string | null
      } | null
      groups?: {
        __typename: "ModelGroupMemberConnection"
        nextToken?: string | null
      } | null
      teamMemberMails?: {
        __typename: "ModelTeamMemberMailConnection"
        nextToken?: string | null
      } | null
      reservedMails?: {
        __typename: "ModelReservedMailConnection"
        nextToken?: string | null
      } | null
      drafts?: {
        __typename: "ModelDraftConnection"
        nextToken?: string | null
      } | null
      templates?: {
        __typename: "ModelTemplateConnection"
        nextToken?: string | null
      } | null
      migratedMails?: {
        __typename: "ModelMigratedMailConnection"
        nextToken?: string | null
      } | null
      mailMigratedFlg?: boolean | null
      favoriteMatchingOffer?: {
        __typename: "ModelFavoriteMatchingOfferConnection"
        nextToken?: string | null
      } | null
      deleteFlg?: boolean | null
      accountTeamMembersId: string
      migrateTeamMemberId?: number | null
      orderHistories?: {
        __typename: "ModelOrderHistoryConnection"
        nextToken?: string | null
      } | null
      paidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      transferPaidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
      teamMemberNotificationSettingId?: string | null
    } | null
    body: string
    sendTo?: {
      __typename: "SendTo"
      allMembersFlg?: boolean | null
      managersFlg?: boolean | null
      groupIds?: Array<string> | null
      memberIds?: Array<string> | null
    } | null
    attachmentFileNames?: Array<string> | null
    startDate?: string | null
    startTime?: string | null
    endDate?: string | null
    endTime?: string | null
    questionnaires?: Array<{
      __typename: "Questionnaires"
      answerSelectType: AnswerSelectType
      question: string
      choices: Array<string>
    }> | null
    candidateDatetimes?: Array<{
      __typename: "CandidateDatetimes"
      startDate: string
      startTime?: string | null
      endDate?: string | null
      endTime?: string | null
    }> | null
    shareManagerFlg?: number | null
    showAnswerFlg?: boolean | null
    responseDueDatetime?: string | null
    remindDatetime?: string | null
    sendScheduledAt?: string | null
    createdAt: string
    updatedAt: string
    teamMemberDraftsId?: string | null
  } | null
}

export type UpdateTemplateMutationVariables = {
  input: UpdateTemplateInput
  condition?: ModelTemplateConditionInput | null
}

export type UpdateTemplateMutation = {
  updateTemplate?: {
    __typename: "Template"
    id: string
    mailType: string
    title: string
    teamMember: {
      __typename: "TeamMember"
      id: string
      memberSeq: number
      nickname: string
      nicknameKana: string
      role: string
      showEmailFlg?: boolean | null
      phoneNumber?: string | null
      showPhoneNumberFlg?: boolean | null
      belongs?: string | null
      showBelongsFlg?: boolean | null
      grade?: number | null
      account?: {
        __typename: "Account"
        id: string
        email: string
        lastName: string
        firstName: string
        lastNameKana: string
        firstNameKana: string
        phoneNumber?: string | null
        gender?: Gender | null
        birthday?: string | null
        schoolGraduationYear?: number | null
        termsAgreedAt?: string | null
        agreedTermsVersion?: string | null
        matchingTermsAgreedAt?: string | null
        agreedMatchingTermsVersion?: string | null
        signupStatus: SignupStatus
        haveOldRa9TeamNotJoin: boolean
        migrateAccountId?: number | null
        createdAt: string
        updatedAt: string
        accountJobId?: string | null
        accountSchoolId?: string | null
        accountSchoolDepartmentId?: string | null
        accountPrefectureId?: string | null
        accountCityId?: string | null
      } | null
      team?: {
        __typename: "Team"
        id: string
        name: string
        description: string
        requirementForMemberProfile?: string | null
        customQuestions?: Array<TeamCustomQuestion> | null
        showMemberListFlg: boolean
        invitationCode?: string | null
        migrationId?: number | null
        paidFunctionEnabled?: boolean | null
        showAdsFlg?: boolean | null
        createdAt: string
        updatedAt: string
        teamCategoryId?: string | null
        teamActivityId?: string | null
        teamPrefectureId?: string | null
        teamCityId?: string | null
      } | null
      teamIdGsi: string
      teamTeamMembersId?: string | null
      notificationSetting?: {
        __typename: "NotificationSetting"
        id: string
        createdAt: string
        updatedAt: string
      } | null
      teamNotice?: {
        __typename: "ModelTeamNoticeConnection"
        nextToken?: string | null
      } | null
      groups?: {
        __typename: "ModelGroupMemberConnection"
        nextToken?: string | null
      } | null
      teamMemberMails?: {
        __typename: "ModelTeamMemberMailConnection"
        nextToken?: string | null
      } | null
      reservedMails?: {
        __typename: "ModelReservedMailConnection"
        nextToken?: string | null
      } | null
      drafts?: {
        __typename: "ModelDraftConnection"
        nextToken?: string | null
      } | null
      templates?: {
        __typename: "ModelTemplateConnection"
        nextToken?: string | null
      } | null
      migratedMails?: {
        __typename: "ModelMigratedMailConnection"
        nextToken?: string | null
      } | null
      mailMigratedFlg?: boolean | null
      favoriteMatchingOffer?: {
        __typename: "ModelFavoriteMatchingOfferConnection"
        nextToken?: string | null
      } | null
      deleteFlg?: boolean | null
      accountTeamMembersId: string
      migrateTeamMemberId?: number | null
      orderHistories?: {
        __typename: "ModelOrderHistoryConnection"
        nextToken?: string | null
      } | null
      paidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      transferPaidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
      teamMemberNotificationSettingId?: string | null
    }
    body: string
    sendTo: {
      __typename: "SendTo"
      allMembersFlg?: boolean | null
      managersFlg?: boolean | null
      groupIds?: Array<string> | null
      memberIds?: Array<string> | null
    }
    startDate?: string | null
    startTime?: string | null
    endDate?: string | null
    endTime?: string | null
    questionnaires?: Array<{
      __typename: "Questionnaires"
      answerSelectType: AnswerSelectType
      question: string
      choices: Array<string>
    }> | null
    candidateDatetimes?: Array<{
      __typename: "CandidateDatetimes"
      startDate: string
      startTime?: string | null
      endDate?: string | null
      endTime?: string | null
    }> | null
    shareManagerFlg?: number | null
    showAnswerFlg?: boolean | null
    favoriteFlg?: boolean | null
    createdAt: string
    updatedAt: string
    teamMemberTemplatesId?: string | null
  } | null
}

export type DeleteTemplateMutationVariables = {
  input: DeleteTemplateInput
  condition?: ModelTemplateConditionInput | null
}

export type DeleteTemplateMutation = {
  deleteTemplate?: {
    __typename: "Template"
    id: string
    mailType: string
    title: string
    teamMember: {
      __typename: "TeamMember"
      id: string
      memberSeq: number
      nickname: string
      nicknameKana: string
      role: string
      showEmailFlg?: boolean | null
      phoneNumber?: string | null
      showPhoneNumberFlg?: boolean | null
      belongs?: string | null
      showBelongsFlg?: boolean | null
      grade?: number | null
      account?: {
        __typename: "Account"
        id: string
        email: string
        lastName: string
        firstName: string
        lastNameKana: string
        firstNameKana: string
        phoneNumber?: string | null
        gender?: Gender | null
        birthday?: string | null
        schoolGraduationYear?: number | null
        termsAgreedAt?: string | null
        agreedTermsVersion?: string | null
        matchingTermsAgreedAt?: string | null
        agreedMatchingTermsVersion?: string | null
        signupStatus: SignupStatus
        haveOldRa9TeamNotJoin: boolean
        migrateAccountId?: number | null
        createdAt: string
        updatedAt: string
        accountJobId?: string | null
        accountSchoolId?: string | null
        accountSchoolDepartmentId?: string | null
        accountPrefectureId?: string | null
        accountCityId?: string | null
      } | null
      team?: {
        __typename: "Team"
        id: string
        name: string
        description: string
        requirementForMemberProfile?: string | null
        customQuestions?: Array<TeamCustomQuestion> | null
        showMemberListFlg: boolean
        invitationCode?: string | null
        migrationId?: number | null
        paidFunctionEnabled?: boolean | null
        showAdsFlg?: boolean | null
        createdAt: string
        updatedAt: string
        teamCategoryId?: string | null
        teamActivityId?: string | null
        teamPrefectureId?: string | null
        teamCityId?: string | null
      } | null
      teamIdGsi: string
      teamTeamMembersId?: string | null
      notificationSetting?: {
        __typename: "NotificationSetting"
        id: string
        createdAt: string
        updatedAt: string
      } | null
      teamNotice?: {
        __typename: "ModelTeamNoticeConnection"
        nextToken?: string | null
      } | null
      groups?: {
        __typename: "ModelGroupMemberConnection"
        nextToken?: string | null
      } | null
      teamMemberMails?: {
        __typename: "ModelTeamMemberMailConnection"
        nextToken?: string | null
      } | null
      reservedMails?: {
        __typename: "ModelReservedMailConnection"
        nextToken?: string | null
      } | null
      drafts?: {
        __typename: "ModelDraftConnection"
        nextToken?: string | null
      } | null
      templates?: {
        __typename: "ModelTemplateConnection"
        nextToken?: string | null
      } | null
      migratedMails?: {
        __typename: "ModelMigratedMailConnection"
        nextToken?: string | null
      } | null
      mailMigratedFlg?: boolean | null
      favoriteMatchingOffer?: {
        __typename: "ModelFavoriteMatchingOfferConnection"
        nextToken?: string | null
      } | null
      deleteFlg?: boolean | null
      accountTeamMembersId: string
      migrateTeamMemberId?: number | null
      orderHistories?: {
        __typename: "ModelOrderHistoryConnection"
        nextToken?: string | null
      } | null
      paidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      transferPaidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
      teamMemberNotificationSettingId?: string | null
    }
    body: string
    sendTo: {
      __typename: "SendTo"
      allMembersFlg?: boolean | null
      managersFlg?: boolean | null
      groupIds?: Array<string> | null
      memberIds?: Array<string> | null
    }
    startDate?: string | null
    startTime?: string | null
    endDate?: string | null
    endTime?: string | null
    questionnaires?: Array<{
      __typename: "Questionnaires"
      answerSelectType: AnswerSelectType
      question: string
      choices: Array<string>
    }> | null
    candidateDatetimes?: Array<{
      __typename: "CandidateDatetimes"
      startDate: string
      startTime?: string | null
      endDate?: string | null
      endTime?: string | null
    }> | null
    shareManagerFlg?: number | null
    showAnswerFlg?: boolean | null
    favoriteFlg?: boolean | null
    createdAt: string
    updatedAt: string
    teamMemberTemplatesId?: string | null
  } | null
}

export type UpdateMigratedMailMutationVariables = {
  input: UpdateMigratedMailInput
  condition?: ModelMigratedMailConditionInput | null
}

export type UpdateMigratedMailMutation = {
  updateMigratedMail?: {
    __typename: "MigratedMail"
    id: string
    migrationId: number
    title: string
    body: string
    sender: string
    favoriteFlg: boolean
    shareManagerFlg: boolean
    sentDatetime: string
    createdAt: string
    updatedAt: string
    teamMemberMigratedMailsId?: string | null
  } | null
}

export type DeleteMigratedMailMutationVariables = {
  input: DeleteMigratedMailInput
  condition?: ModelMigratedMailConditionInput | null
}

export type DeleteMigratedMailMutation = {
  deleteMigratedMail?: {
    __typename: "MigratedMail"
    id: string
    migrationId: number
    title: string
    body: string
    sender: string
    favoriteFlg: boolean
    shareManagerFlg: boolean
    sentDatetime: string
    createdAt: string
    updatedAt: string
    teamMemberMigratedMailsId?: string | null
  } | null
}

export type CreateMatchingTeamProfileMutationVariables = {
  input: CreateMatchingTeamProfileInput
  condition?: ModelMatchingTeamProfileConditionInput | null
}

export type CreateMatchingTeamProfileMutation = {
  createMatchingTeamProfile?: {
    __typename: "MatchingTeamProfile"
    id: string
    ageFrom: number
    ageTo: number
    introduction: string
    teamUrl?: string | null
    createdAt: string
    updatedAt: string
  } | null
}

export type UpdateMatchingTeamProfileMutationVariables = {
  input: UpdateMatchingTeamProfileInput
  condition?: ModelMatchingTeamProfileConditionInput | null
}

export type UpdateMatchingTeamProfileMutation = {
  updateMatchingTeamProfile?: {
    __typename: "MatchingTeamProfile"
    id: string
    ageFrom: number
    ageTo: number
    introduction: string
    teamUrl?: string | null
    createdAt: string
    updatedAt: string
  } | null
}

export type UpdateMatchingOfferMutationVariables = {
  input: UpdateMatchingOfferInput
  condition?: ModelMatchingOfferConditionInput | null
}

export type UpdateMatchingOfferMutation = {
  updateMatchingOffer?: {
    __typename: "MatchingOffer"
    id: string
    purpose: MatchingOfferPurpose
    title: string
    body: string
    date?: string | null
    startTime?: string | null
    endTime?: string | null
    prefecture?: {
      __typename: "PrefectureMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      cities?: {
        __typename: "ModelCityMasterConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
    } | null
    city?: {
      __typename: "CityMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      createdAt: string
      updatedAt: string
      prefectureMasterCitiesId?: string | null
    } | null
    place?: string | null
    placeURL?: string | null
    expense?: number | null
    expensePurpose?: string | null
    ageFrom?: number | null
    ageTo?: number | null
    offerCount?: number | null
    publishEndDate: string
    team?: {
      __typename: "Team"
      id: string
      name: string
      category?: {
        __typename: "TeamCategoryMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      activity?: {
        __typename: "TeamActivityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      prefecture?: {
        __typename: "PrefectureMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      city?: {
        __typename: "CityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
        prefectureMasterCitiesId?: string | null
      } | null
      description: string
      requirementForMemberProfile?: string | null
      customQuestions?: Array<TeamCustomQuestion> | null
      showMemberListFlg: boolean
      invitationCode?: string | null
      teamMembers?: {
        __typename: "ModelTeamMemberConnection"
        nextToken?: string | null
      } | null
      preTeamMembers?: {
        __typename: "ModelPreTeamMemberConnection"
        nextToken?: string | null
      } | null
      invitationEmailStatuses?: {
        __typename: "ModelInvitationEmailStatusConnection"
        nextToken?: string | null
      } | null
      groups?: {
        __typename: "ModelGroupConnection"
        nextToken?: string | null
      } | null
      matchingOffers?: {
        __typename: "ModelMatchingOfferConnection"
        nextToken?: string | null
      } | null
      matchingOfferDrafts?: {
        __typename: "ModelMatchingOfferDraftConnection"
        nextToken?: string | null
      } | null
      matchingApplications?: {
        __typename: "ModelMatchingApplicationConnection"
        nextToken?: string | null
      } | null
      blockingMatchingTeams?: {
        __typename: "ModelBlockingMatchingTeamConnection"
        nextToken?: string | null
      } | null
      paidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      blockingMatchingTeamsByBlockingTeamId?: {
        __typename: "ModelBlockingMatchingTeamConnection"
        nextToken?: string | null
      } | null
      migrationId?: number | null
      paidFunctionEnabled?: boolean | null
      showAdsFlg?: boolean | null
      createdAt: string
      updatedAt: string
      teamCategoryId?: string | null
      teamActivityId?: string | null
      teamPrefectureId?: string | null
      teamCityId?: string | null
    } | null
    attachmentFileNames?: Array<string> | null
    activity?: {
      __typename: "TeamActivityMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      categories?: {
        __typename: "ModelTeamCategoryActivityConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
    } | null
    teamId: string
    teamName: string
    applications?: {
      __typename: "ModelMatchingApplicationConnection"
      items: Array<{
        __typename: "MatchingApplication"
        id: string
        offerTeamName: string
        offerTitle: string
        offerBody: string
        createdAt: string
        updatedAt: string
        teamMatchingApplicationsId?: string | null
        matchingOfferApplicationsId?: string | null
      } | null>
      nextToken?: string | null
    } | null
    deleteFlg?: boolean | null
    createdAt: string
    updatedAt: string
    teamMatchingOffersId?: string | null
    matchingOfferPrefectureId?: string | null
    matchingOfferCityId?: string | null
    matchingOfferActivityId?: string | null
  } | null
}

export type DeleteMatchingOfferMutationVariables = {
  input: DeleteMatchingOfferInput
  condition?: ModelMatchingOfferConditionInput | null
}

export type DeleteMatchingOfferMutation = {
  deleteMatchingOffer?: {
    __typename: "MatchingOffer"
    id: string
    purpose: MatchingOfferPurpose
    title: string
    body: string
    date?: string | null
    startTime?: string | null
    endTime?: string | null
    prefecture?: {
      __typename: "PrefectureMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      cities?: {
        __typename: "ModelCityMasterConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
    } | null
    city?: {
      __typename: "CityMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      createdAt: string
      updatedAt: string
      prefectureMasterCitiesId?: string | null
    } | null
    place?: string | null
    placeURL?: string | null
    expense?: number | null
    expensePurpose?: string | null
    ageFrom?: number | null
    ageTo?: number | null
    offerCount?: number | null
    publishEndDate: string
    team?: {
      __typename: "Team"
      id: string
      name: string
      category?: {
        __typename: "TeamCategoryMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      activity?: {
        __typename: "TeamActivityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      prefecture?: {
        __typename: "PrefectureMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      city?: {
        __typename: "CityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
        prefectureMasterCitiesId?: string | null
      } | null
      description: string
      requirementForMemberProfile?: string | null
      customQuestions?: Array<TeamCustomQuestion> | null
      showMemberListFlg: boolean
      invitationCode?: string | null
      teamMembers?: {
        __typename: "ModelTeamMemberConnection"
        nextToken?: string | null
      } | null
      preTeamMembers?: {
        __typename: "ModelPreTeamMemberConnection"
        nextToken?: string | null
      } | null
      invitationEmailStatuses?: {
        __typename: "ModelInvitationEmailStatusConnection"
        nextToken?: string | null
      } | null
      groups?: {
        __typename: "ModelGroupConnection"
        nextToken?: string | null
      } | null
      matchingOffers?: {
        __typename: "ModelMatchingOfferConnection"
        nextToken?: string | null
      } | null
      matchingOfferDrafts?: {
        __typename: "ModelMatchingOfferDraftConnection"
        nextToken?: string | null
      } | null
      matchingApplications?: {
        __typename: "ModelMatchingApplicationConnection"
        nextToken?: string | null
      } | null
      blockingMatchingTeams?: {
        __typename: "ModelBlockingMatchingTeamConnection"
        nextToken?: string | null
      } | null
      paidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      blockingMatchingTeamsByBlockingTeamId?: {
        __typename: "ModelBlockingMatchingTeamConnection"
        nextToken?: string | null
      } | null
      migrationId?: number | null
      paidFunctionEnabled?: boolean | null
      showAdsFlg?: boolean | null
      createdAt: string
      updatedAt: string
      teamCategoryId?: string | null
      teamActivityId?: string | null
      teamPrefectureId?: string | null
      teamCityId?: string | null
    } | null
    attachmentFileNames?: Array<string> | null
    activity?: {
      __typename: "TeamActivityMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      categories?: {
        __typename: "ModelTeamCategoryActivityConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
    } | null
    teamId: string
    teamName: string
    applications?: {
      __typename: "ModelMatchingApplicationConnection"
      items: Array<{
        __typename: "MatchingApplication"
        id: string
        offerTeamName: string
        offerTitle: string
        offerBody: string
        createdAt: string
        updatedAt: string
        teamMatchingApplicationsId?: string | null
        matchingOfferApplicationsId?: string | null
      } | null>
      nextToken?: string | null
    } | null
    deleteFlg?: boolean | null
    createdAt: string
    updatedAt: string
    teamMatchingOffersId?: string | null
    matchingOfferPrefectureId?: string | null
    matchingOfferCityId?: string | null
    matchingOfferActivityId?: string | null
  } | null
}

export type UpdateMatchingOfferDraftMutationVariables = {
  input: UpdateMatchingOfferDraftInput
  condition?: ModelMatchingOfferDraftConditionInput | null
}

export type UpdateMatchingOfferDraftMutation = {
  updateMatchingOfferDraft?: {
    __typename: "MatchingOfferDraft"
    id: string
    purpose?: MatchingOfferPurpose | null
    title: string
    body?: string | null
    date?: string | null
    startTime?: string | null
    endTime?: string | null
    prefecture?: {
      __typename: "PrefectureMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      cities?: {
        __typename: "ModelCityMasterConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
    } | null
    city?: {
      __typename: "CityMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      createdAt: string
      updatedAt: string
      prefectureMasterCitiesId?: string | null
    } | null
    place?: string | null
    placeURL?: string | null
    expense?: number | null
    expensePurpose?: string | null
    ageFrom?: number | null
    ageTo?: number | null
    offerCount?: number | null
    publishEndDate?: string | null
    team?: {
      __typename: "Team"
      id: string
      name: string
      category?: {
        __typename: "TeamCategoryMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      activity?: {
        __typename: "TeamActivityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      prefecture?: {
        __typename: "PrefectureMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      city?: {
        __typename: "CityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
        prefectureMasterCitiesId?: string | null
      } | null
      description: string
      requirementForMemberProfile?: string | null
      customQuestions?: Array<TeamCustomQuestion> | null
      showMemberListFlg: boolean
      invitationCode?: string | null
      teamMembers?: {
        __typename: "ModelTeamMemberConnection"
        nextToken?: string | null
      } | null
      preTeamMembers?: {
        __typename: "ModelPreTeamMemberConnection"
        nextToken?: string | null
      } | null
      invitationEmailStatuses?: {
        __typename: "ModelInvitationEmailStatusConnection"
        nextToken?: string | null
      } | null
      groups?: {
        __typename: "ModelGroupConnection"
        nextToken?: string | null
      } | null
      matchingOffers?: {
        __typename: "ModelMatchingOfferConnection"
        nextToken?: string | null
      } | null
      matchingOfferDrafts?: {
        __typename: "ModelMatchingOfferDraftConnection"
        nextToken?: string | null
      } | null
      matchingApplications?: {
        __typename: "ModelMatchingApplicationConnection"
        nextToken?: string | null
      } | null
      blockingMatchingTeams?: {
        __typename: "ModelBlockingMatchingTeamConnection"
        nextToken?: string | null
      } | null
      paidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      blockingMatchingTeamsByBlockingTeamId?: {
        __typename: "ModelBlockingMatchingTeamConnection"
        nextToken?: string | null
      } | null
      migrationId?: number | null
      paidFunctionEnabled?: boolean | null
      showAdsFlg?: boolean | null
      createdAt: string
      updatedAt: string
      teamCategoryId?: string | null
      teamActivityId?: string | null
      teamPrefectureId?: string | null
      teamCityId?: string | null
    } | null
    createdAt: string
    updatedAt: string
    teamMatchingOfferDraftsId?: string | null
    matchingOfferDraftPrefectureId?: string | null
    matchingOfferDraftCityId?: string | null
  } | null
}

export type DeleteMatchingOfferDraftMutationVariables = {
  input: DeleteMatchingOfferDraftInput
  condition?: ModelMatchingOfferDraftConditionInput | null
}

export type DeleteMatchingOfferDraftMutation = {
  deleteMatchingOfferDraft?: {
    __typename: "MatchingOfferDraft"
    id: string
    purpose?: MatchingOfferPurpose | null
    title: string
    body?: string | null
    date?: string | null
    startTime?: string | null
    endTime?: string | null
    prefecture?: {
      __typename: "PrefectureMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      cities?: {
        __typename: "ModelCityMasterConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
    } | null
    city?: {
      __typename: "CityMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      createdAt: string
      updatedAt: string
      prefectureMasterCitiesId?: string | null
    } | null
    place?: string | null
    placeURL?: string | null
    expense?: number | null
    expensePurpose?: string | null
    ageFrom?: number | null
    ageTo?: number | null
    offerCount?: number | null
    publishEndDate?: string | null
    team?: {
      __typename: "Team"
      id: string
      name: string
      category?: {
        __typename: "TeamCategoryMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      activity?: {
        __typename: "TeamActivityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      prefecture?: {
        __typename: "PrefectureMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      city?: {
        __typename: "CityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
        prefectureMasterCitiesId?: string | null
      } | null
      description: string
      requirementForMemberProfile?: string | null
      customQuestions?: Array<TeamCustomQuestion> | null
      showMemberListFlg: boolean
      invitationCode?: string | null
      teamMembers?: {
        __typename: "ModelTeamMemberConnection"
        nextToken?: string | null
      } | null
      preTeamMembers?: {
        __typename: "ModelPreTeamMemberConnection"
        nextToken?: string | null
      } | null
      invitationEmailStatuses?: {
        __typename: "ModelInvitationEmailStatusConnection"
        nextToken?: string | null
      } | null
      groups?: {
        __typename: "ModelGroupConnection"
        nextToken?: string | null
      } | null
      matchingOffers?: {
        __typename: "ModelMatchingOfferConnection"
        nextToken?: string | null
      } | null
      matchingOfferDrafts?: {
        __typename: "ModelMatchingOfferDraftConnection"
        nextToken?: string | null
      } | null
      matchingApplications?: {
        __typename: "ModelMatchingApplicationConnection"
        nextToken?: string | null
      } | null
      blockingMatchingTeams?: {
        __typename: "ModelBlockingMatchingTeamConnection"
        nextToken?: string | null
      } | null
      paidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      blockingMatchingTeamsByBlockingTeamId?: {
        __typename: "ModelBlockingMatchingTeamConnection"
        nextToken?: string | null
      } | null
      migrationId?: number | null
      paidFunctionEnabled?: boolean | null
      showAdsFlg?: boolean | null
      createdAt: string
      updatedAt: string
      teamCategoryId?: string | null
      teamActivityId?: string | null
      teamPrefectureId?: string | null
      teamCityId?: string | null
    } | null
    createdAt: string
    updatedAt: string
    teamMatchingOfferDraftsId?: string | null
    matchingOfferDraftPrefectureId?: string | null
    matchingOfferDraftCityId?: string | null
  } | null
}

export type DeleteTempMatchingOfferMutationVariables = {
  input: DeleteTempMatchingOfferInput
  condition?: ModelTempMatchingOfferConditionInput | null
}

export type DeleteTempMatchingOfferMutation = {
  deleteTempMatchingOffer?: {
    __typename: "TempMatchingOffer"
    teamMemberId: string
    tempMatchingOfferId: string
    createdAt: string
    updatedAt: string
  } | null
}

export type CreateFavoriteMatchingOfferMutationVariables = {
  input: CreateFavoriteMatchingOfferInput
  condition?: ModelFavoriteMatchingOfferConditionInput | null
}

export type CreateFavoriteMatchingOfferMutation = {
  createFavoriteMatchingOffer?: {
    __typename: "FavoriteMatchingOffer"
    id: string
    offer: {
      __typename: "MatchingOffer"
      id: string
      purpose: MatchingOfferPurpose
      title: string
      body: string
      date?: string | null
      startTime?: string | null
      endTime?: string | null
      prefecture?: {
        __typename: "PrefectureMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      city?: {
        __typename: "CityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
        prefectureMasterCitiesId?: string | null
      } | null
      place?: string | null
      placeURL?: string | null
      expense?: number | null
      expensePurpose?: string | null
      ageFrom?: number | null
      ageTo?: number | null
      offerCount?: number | null
      publishEndDate: string
      team?: {
        __typename: "Team"
        id: string
        name: string
        description: string
        requirementForMemberProfile?: string | null
        customQuestions?: Array<TeamCustomQuestion> | null
        showMemberListFlg: boolean
        invitationCode?: string | null
        migrationId?: number | null
        paidFunctionEnabled?: boolean | null
        showAdsFlg?: boolean | null
        createdAt: string
        updatedAt: string
        teamCategoryId?: string | null
        teamActivityId?: string | null
        teamPrefectureId?: string | null
        teamCityId?: string | null
      } | null
      attachmentFileNames?: Array<string> | null
      activity?: {
        __typename: "TeamActivityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      teamId: string
      teamName: string
      applications?: {
        __typename: "ModelMatchingApplicationConnection"
        nextToken?: string | null
      } | null
      deleteFlg?: boolean | null
      createdAt: string
      updatedAt: string
      teamMatchingOffersId?: string | null
      matchingOfferPrefectureId?: string | null
      matchingOfferCityId?: string | null
      matchingOfferActivityId?: string | null
    }
    createdAt: string
    updatedAt: string
    teamMemberFavoriteMatchingOfferId?: string | null
    favoriteMatchingOfferOfferId: string
  } | null
}

export type DeleteFavoriteMatchingOfferMutationVariables = {
  input: DeleteFavoriteMatchingOfferInput
  condition?: ModelFavoriteMatchingOfferConditionInput | null
}

export type DeleteFavoriteMatchingOfferMutation = {
  deleteFavoriteMatchingOffer?: {
    __typename: "FavoriteMatchingOffer"
    id: string
    offer: {
      __typename: "MatchingOffer"
      id: string
      purpose: MatchingOfferPurpose
      title: string
      body: string
      date?: string | null
      startTime?: string | null
      endTime?: string | null
      prefecture?: {
        __typename: "PrefectureMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      city?: {
        __typename: "CityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
        prefectureMasterCitiesId?: string | null
      } | null
      place?: string | null
      placeURL?: string | null
      expense?: number | null
      expensePurpose?: string | null
      ageFrom?: number | null
      ageTo?: number | null
      offerCount?: number | null
      publishEndDate: string
      team?: {
        __typename: "Team"
        id: string
        name: string
        description: string
        requirementForMemberProfile?: string | null
        customQuestions?: Array<TeamCustomQuestion> | null
        showMemberListFlg: boolean
        invitationCode?: string | null
        migrationId?: number | null
        paidFunctionEnabled?: boolean | null
        showAdsFlg?: boolean | null
        createdAt: string
        updatedAt: string
        teamCategoryId?: string | null
        teamActivityId?: string | null
        teamPrefectureId?: string | null
        teamCityId?: string | null
      } | null
      attachmentFileNames?: Array<string> | null
      activity?: {
        __typename: "TeamActivityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      teamId: string
      teamName: string
      applications?: {
        __typename: "ModelMatchingApplicationConnection"
        nextToken?: string | null
      } | null
      deleteFlg?: boolean | null
      createdAt: string
      updatedAt: string
      teamMatchingOffersId?: string | null
      matchingOfferPrefectureId?: string | null
      matchingOfferCityId?: string | null
      matchingOfferActivityId?: string | null
    }
    createdAt: string
    updatedAt: string
    teamMemberFavoriteMatchingOfferId?: string | null
    favoriteMatchingOfferOfferId: string
  } | null
}

export type UpdateMatchingApplicationMutationVariables = {
  input: UpdateMatchingApplicationInput
  condition?: ModelMatchingApplicationConditionInput | null
}

export type UpdateMatchingApplicationMutation = {
  updateMatchingApplication?: {
    __typename: "MatchingApplication"
    id: string
    applicant?: {
      __typename: "Team"
      id: string
      name: string
      category?: {
        __typename: "TeamCategoryMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      activity?: {
        __typename: "TeamActivityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      prefecture?: {
        __typename: "PrefectureMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      city?: {
        __typename: "CityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
        prefectureMasterCitiesId?: string | null
      } | null
      description: string
      requirementForMemberProfile?: string | null
      customQuestions?: Array<TeamCustomQuestion> | null
      showMemberListFlg: boolean
      invitationCode?: string | null
      teamMembers?: {
        __typename: "ModelTeamMemberConnection"
        nextToken?: string | null
      } | null
      preTeamMembers?: {
        __typename: "ModelPreTeamMemberConnection"
        nextToken?: string | null
      } | null
      invitationEmailStatuses?: {
        __typename: "ModelInvitationEmailStatusConnection"
        nextToken?: string | null
      } | null
      groups?: {
        __typename: "ModelGroupConnection"
        nextToken?: string | null
      } | null
      matchingOffers?: {
        __typename: "ModelMatchingOfferConnection"
        nextToken?: string | null
      } | null
      matchingOfferDrafts?: {
        __typename: "ModelMatchingOfferDraftConnection"
        nextToken?: string | null
      } | null
      matchingApplications?: {
        __typename: "ModelMatchingApplicationConnection"
        nextToken?: string | null
      } | null
      blockingMatchingTeams?: {
        __typename: "ModelBlockingMatchingTeamConnection"
        nextToken?: string | null
      } | null
      paidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      blockingMatchingTeamsByBlockingTeamId?: {
        __typename: "ModelBlockingMatchingTeamConnection"
        nextToken?: string | null
      } | null
      migrationId?: number | null
      paidFunctionEnabled?: boolean | null
      showAdsFlg?: boolean | null
      createdAt: string
      updatedAt: string
      teamCategoryId?: string | null
      teamActivityId?: string | null
      teamPrefectureId?: string | null
      teamCityId?: string | null
    } | null
    offer?: {
      __typename: "MatchingOffer"
      id: string
      purpose: MatchingOfferPurpose
      title: string
      body: string
      date?: string | null
      startTime?: string | null
      endTime?: string | null
      prefecture?: {
        __typename: "PrefectureMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      city?: {
        __typename: "CityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
        prefectureMasterCitiesId?: string | null
      } | null
      place?: string | null
      placeURL?: string | null
      expense?: number | null
      expensePurpose?: string | null
      ageFrom?: number | null
      ageTo?: number | null
      offerCount?: number | null
      publishEndDate: string
      team?: {
        __typename: "Team"
        id: string
        name: string
        description: string
        requirementForMemberProfile?: string | null
        customQuestions?: Array<TeamCustomQuestion> | null
        showMemberListFlg: boolean
        invitationCode?: string | null
        migrationId?: number | null
        paidFunctionEnabled?: boolean | null
        showAdsFlg?: boolean | null
        createdAt: string
        updatedAt: string
        teamCategoryId?: string | null
        teamActivityId?: string | null
        teamPrefectureId?: string | null
        teamCityId?: string | null
      } | null
      attachmentFileNames?: Array<string> | null
      activity?: {
        __typename: "TeamActivityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      teamId: string
      teamName: string
      applications?: {
        __typename: "ModelMatchingApplicationConnection"
        nextToken?: string | null
      } | null
      deleteFlg?: boolean | null
      createdAt: string
      updatedAt: string
      teamMatchingOffersId?: string | null
      matchingOfferPrefectureId?: string | null
      matchingOfferCityId?: string | null
      matchingOfferActivityId?: string | null
    } | null
    messages?: {
      __typename: "ModelMatchingMessageConnection"
      items: Array<{
        __typename: "MatchingMessage"
        id: string
        messageSenderTeamId: string
        text?: string | null
        unreadFlg: number
        deleteFlg: number
        contentUpdatedAt: string
        createdAtForSort: string
        createdAtForSortIncludeDeleted: string
        applicationIdMessageReceiverGsi: string
        matchingApplicationMessagesId: string
        matchingApplicationMessagesIncludeDeletedId: string
        createdAt: string
        updatedAt: string
      } | null>
      nextToken?: string | null
    } | null
    messagesIncludeDeleted?: {
      __typename: "ModelMatchingMessageConnection"
      items: Array<{
        __typename: "MatchingMessage"
        id: string
        messageSenderTeamId: string
        text?: string | null
        unreadFlg: number
        deleteFlg: number
        contentUpdatedAt: string
        createdAtForSort: string
        createdAtForSortIncludeDeleted: string
        applicationIdMessageReceiverGsi: string
        matchingApplicationMessagesId: string
        matchingApplicationMessagesIncludeDeletedId: string
        createdAt: string
        updatedAt: string
      } | null>
      nextToken?: string | null
    } | null
    offerTeamName: string
    offerTitle: string
    offerBody: string
    createdAt: string
    updatedAt: string
    teamMatchingApplicationsId?: string | null
    matchingOfferApplicationsId?: string | null
  } | null
}

export type CreateBlockingMatchingTeamMutationVariables = {
  input: CreateBlockingMatchingTeamInput
  condition?: ModelBlockingMatchingTeamConditionInput | null
}

export type CreateBlockingMatchingTeamMutation = {
  createBlockingMatchingTeam?: {
    __typename: "BlockingMatchingTeam"
    id: string
    blockingTeamId: string
    createdAt: string
    updatedAt: string
    teamBlockingMatchingTeamsId?: string | null
  } | null
}

export type DeleteBlockingMatchingTeamMutationVariables = {
  input: DeleteBlockingMatchingTeamInput
  condition?: ModelBlockingMatchingTeamConditionInput | null
}

export type DeleteBlockingMatchingTeamMutation = {
  deleteBlockingMatchingTeam?: {
    __typename: "BlockingMatchingTeam"
    id: string
    blockingTeamId: string
    createdAt: string
    updatedAt: string
    teamBlockingMatchingTeamsId?: string | null
  } | null
}

export type CreateTeamCategoryActivityMutationVariables = {
  input: CreateTeamCategoryActivityInput
  condition?: ModelTeamCategoryActivityConditionInput | null
}

export type CreateTeamCategoryActivityMutation = {
  createTeamCategoryActivity?: {
    __typename: "TeamCategoryActivity"
    id: string
    teamCategoryMasterID: string
    teamActivityMasterID: string
    teamCategoryMaster: {
      __typename: "TeamCategoryMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      activities?: {
        __typename: "ModelTeamCategoryActivityConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
    }
    teamActivityMaster: {
      __typename: "TeamActivityMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      categories?: {
        __typename: "ModelTeamCategoryActivityConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
    }
    createdAt: string
    updatedAt: string
  } | null
}

export type UpdateTeamCategoryActivityMutationVariables = {
  input: UpdateTeamCategoryActivityInput
  condition?: ModelTeamCategoryActivityConditionInput | null
}

export type UpdateTeamCategoryActivityMutation = {
  updateTeamCategoryActivity?: {
    __typename: "TeamCategoryActivity"
    id: string
    teamCategoryMasterID: string
    teamActivityMasterID: string
    teamCategoryMaster: {
      __typename: "TeamCategoryMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      activities?: {
        __typename: "ModelTeamCategoryActivityConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
    }
    teamActivityMaster: {
      __typename: "TeamActivityMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      categories?: {
        __typename: "ModelTeamCategoryActivityConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
    }
    createdAt: string
    updatedAt: string
  } | null
}

export type DeleteTeamCategoryActivityMutationVariables = {
  input: DeleteTeamCategoryActivityInput
  condition?: ModelTeamCategoryActivityConditionInput | null
}

export type DeleteTeamCategoryActivityMutation = {
  deleteTeamCategoryActivity?: {
    __typename: "TeamCategoryActivity"
    id: string
    teamCategoryMasterID: string
    teamActivityMasterID: string
    teamCategoryMaster: {
      __typename: "TeamCategoryMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      activities?: {
        __typename: "ModelTeamCategoryActivityConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
    }
    teamActivityMaster: {
      __typename: "TeamActivityMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      categories?: {
        __typename: "ModelTeamCategoryActivityConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
    }
    createdAt: string
    updatedAt: string
  } | null
}

export type GetAccountIdQuery = {
  getAccountId?: {
    __typename: "IdResponse"
    id: string
  } | null
}

export type GetAccountInfoQuery = {
  getAccountInfo?: {
    __typename: "AccountInfoResponse"
    id: string
    email: string
    lastName: string
    firstName: string
    lastNameKana: string
    firstNameKana: string
    phoneNumber?: string | null
    gender?: Gender | null
    birthday?: string | null
    accountJobId?: string | null
    accountSchoolId?: string | null
    accountSchoolDepartmentId?: string | null
    schoolGraduationYear?: number | null
    accountPrefectureId?: string | null
    accountCityId?: string | null
    questionAnswers?: Array<{
      __typename: "QuestionAnswer"
      question?: {
        __typename: "Label"
        id?: string | null
        label?: string | null
      } | null
      answer?: {
        __typename: "Label"
        id?: string | null
        label?: string | null
      } | null
    }> | null
    termsAgreedAt?: string | null
    agreedTermsVersion?: string | null
    matchingTermsAgreedAt?: string | null
    agreedMatchingTermsVersion?: string | null
    signupStatus: SignupStatus
    createdAt: string
    updatedAt: string
  } | null
}

export type GetPermissionAccountQueryVariables = {
  input?: GetPermissionAccountInput | null
}

export type GetPermissionAccountQuery = {
  getPermissionAccount?: {
    __typename: "GetPermissionAccountResponse"
    teamId: string
    management: {
      __typename: "PermissionAccountManagementType"
      team: {
        __typename: "TeamManagementType"
        canSetting: boolean
        canDelete: boolean
      }
      member: {
        __typename: "MemberManagementType"
        canInviteTeam: boolean
        canApproveMember: boolean
        canRoleSetting: boolean
        canDeleteMember: boolean
        canUpdateMemberInfo: boolean
        canExportCsv: boolean
      }
      group: {
        __typename: "GroupManagementType"
        canCreateGroup: boolean
        canUpdateGroup: boolean
        canUpdateMemberInfo: boolean
        canUpdateGroupLeader: boolean
        canDeleteGroup: boolean
      }
    }
    list: {
      __typename: "PermissionAccountListType"
      canVisibleListMember: boolean
      listGroup: {
        __typename: "AccessType"
        accessAll: boolean
        groupIds: Array<string>
      }
    }
    mail: {
      __typename: "PermissionAccountMailType"
      make: {
        __typename: "AccessType"
        accessAll: boolean
        groupIds: Array<string>
      }
    }
    order: {
      __typename: "PermissionOrderType"
      showOrder: boolean
      canTransferApplicant: boolean
      canCreateOrder: boolean
      canUpdateOrder: boolean
      canCancelOrder: boolean
      canExtendOrder: boolean
      canChangePlan: boolean
      canCreatePaidTeamInquiry: boolean
    }
  } | null
}

export type CanDeleteAccountQuery = {
  canDeleteAccount?: {
    __typename: "CanDeleteAccountResponse"
    canDelete: boolean
    message: string
  } | null
}

export type ListAllMigratedTeamsQuery = {
  listAllMigratedTeams?: {
    __typename: "ListAllMigratedTeamsResponse"
    migratedTeams?: Array<{
      __typename: "MigratedTeamType"
      id: string
      name: string
      invitationCode: string
      migrationId: number
      joinFlg: boolean
      image: {
        __typename: "GetTeamImageGetUrlResponse"
        url: string
        isDefault: boolean
      }
      role: string
      createdAt: string
      updatedAt: string
    } | null> | null
  } | null
}

export type ListNotJoinMembersQueryVariables = {
  input?: ListNotJoinMembersInput | null
}

export type ListNotJoinMembersQuery = {
  listNotJoinMembers?: {
    __typename: "ListNotJoinMembersResponse"
    notJoinMembers?: Array<{
      __typename: "NotJoinMemberType"
      id: string
      email: string
      nickname: string
      nicknameKana: string
      role: string
      joinFlg: boolean
      image: {
        __typename: "GetTeamImageGetUrlResponse"
        url: string
        isDefault: boolean
      }
    }> | null
    total: number
    page: number
    pageSize: number
  } | null
}

export type ListAdminNoticeQuery = {
  listAdminNotice: {
    __typename: "ListAdminNoticeResponse"
    items: Array<{
      __typename: "ListAdminNoticeResponseItem"
      id: string
      label: string
      title: string
      priority?: AdminNoticePriority | null
      publishDatetime: string
      readFlg?: boolean | null
      createdAt: string
      updatedAt: string
    }>
  }
}

export type GetTeamInvitationMessageQueryVariables = {
  input: GetTeamInvitationMessageInput
}

export type GetTeamInvitationMessageQuery = {
  getTeamInvitationMessage: {
    __typename: "GetTeamInvitationMessageResponse"
    title: string
    message: string
  }
}

export type GetInvitationCodeByVolatileIdQueryVariables = {
  input: GetInvitationCodeByVolatileIdInput
}

export type GetInvitationCodeByVolatileIdQuery = {
  getInvitationCodeByVolatileId: {
    __typename: "GetInvitationCodeByVolatileIdResponse"
    invitationCode: string
  }
}

export type GetTeamMemberCountQueryVariables = {
  input: GetTeamMemberCountInput
}

export type GetTeamMemberCountQuery = {
  getTeamMemberCount: {
    __typename: "GetTeamMemberCountResponse"
    memberCount: number
  }
}

export type ListGroupQueryVariables = {
  input: ListGroupInput
}

export type ListGroupQuery = {
  listGroup: {
    __typename: "ListGroupResponse"
    items: Array<{
      __typename: "ListGroupItemResponse"
      id: string
      name: string
    }>
  }
}

export type GetUnreadReceivedMailExistenceQueryVariables = {
  input: GetUnreadReceivedMailExistenceInput
}

export type GetUnreadReceivedMailExistenceQuery = {
  getUnreadReceivedMailExistence: {
    __typename: "GetUnreadReceivedMailExistenceResponse"
    hasUnreadMail: boolean
  }
}

export type ListReceivedTeamMemberMailQueryVariables = {
  input?: ListReceivedTeamMemberMailInput | null
}

export type ListReceivedTeamMemberMailQuery = {
  listReceivedTeamMemberMail?: {
    __typename: "ListReceivedTeamMemberMailResponse"
    mailType: MailType
    teamMemberMails: Array<{
      __typename: "ListTeamMemberMailItem"
      showReadFlg: boolean
      readFlg?: boolean | null
      readAt?: string | null
      showAnsweredFlg: boolean
      answeredFlg?: boolean | null
      answeredAt?: string | null
      attendanceAnswer?: YesNoAnswer | null
      questionnaireAnswers?: Array<{
        __typename: "QuestionnaireAnswerItem"
        answer: Array<number>
      }> | null
      scheduleAnswers?: Array<YesNoAnswer> | null
      teamMember?: {
        __typename: "TeamMemberItem"
        id: string
        nickname: string
        memberSeq: string
        deleteFlg?: boolean | null
      } | null
    }>
  } | null
}

export type ListReservedTeamMemberMailQueryVariables = {
  input: ListReservedTeamMemberMailInput
}

export type ListReservedTeamMemberMailQuery = {
  listReservedTeamMemberMail?: {
    __typename: "ListReservedTeamMemberMailResponse"
    mailType: MailType
    teamMemberMails: Array<{
      __typename: "ListTeamMemberMailItem"
      showReadFlg: boolean
      readFlg?: boolean | null
      readAt?: string | null
      showAnsweredFlg: boolean
      answeredFlg?: boolean | null
      answeredAt?: string | null
      attendanceAnswer?: YesNoAnswer | null
      questionnaireAnswers?: Array<{
        __typename: "QuestionnaireAnswerItem"
        answer: Array<number>
      }> | null
      scheduleAnswers?: Array<YesNoAnswer> | null
      teamMember?: {
        __typename: "TeamMemberItem"
        id: string
        nickname: string
        memberSeq: string
        deleteFlg?: boolean | null
      } | null
    }>
  } | null
}

export type ListTeamMemberMailByAccountQueryVariables = {
  input: ListTeamMemberMailByAccountInput
}

export type ListTeamMemberMailByAccountQuery = {
  listTeamMemberMailByAccount?: {
    __typename: "ListTeamMemberMailByAccountResponse"
    accountId: string
    teamMemberMailByAccount: Array<{
      __typename: "TeamMemberMailByAccount"
      teamId: string
      teamMemberMail: Array<{
        __typename: "TeamMemberMailItemByAccount"
        id: string
        readFlg?: boolean | null
        favoriteFlgReceived: boolean
        favoriteFlgSent: boolean
        answeredFlg?: boolean | null
        answerRequiredFlg?: boolean | null
      }>
    }>
  } | null
}

export type ListThreadQueryVariables = {
  input: ListThreadInput
}

export type ListThreadQuery = {
  listThread: {
    __typename: "ListThreadResponse"
    items?: Array<{
      __typename: "ListThreadResponseItem"
      member?: {
        __typename: "ListThreadResponseMember"
        id: string
        nickname: string
        deleteFlg?: boolean | null
      } | null
      lastMessage?: {
        __typename: "ListThreadResponseLastMessage"
        text: string
        createdAt?: string | null
      } | null
    }> | null
  }
}

export type GetTeamMemberIdQueryVariables = {
  input: GetTeamMemberIdInput
}

export type GetTeamMemberIdQuery = {
  getTeamMemberId: {
    __typename: "IdResponse"
    id: string
  }
}

export type GetEmptyThreadQueryVariables = {
  input: GetEmptyThreadInput
}

export type GetEmptyThreadQuery = {
  getEmptyThread: {
    __typename: "GetEmptyThreadResponse"
    mail: {
      __typename: "ThreadMail"
      id: string
      title: string
    }
    mailSender: {
      __typename: "ThreadMember"
      id: string
      nickname: string
      deleteFlg?: boolean | null
    }
    mailRecipient: {
      __typename: "ThreadMember"
      id: string
      nickname: string
      deleteFlg?: boolean | null
    }
  }
}

export type GetAccountImageGetUrlQueryVariables = {
  input: GetAccountImageGetUrlInput
}

export type GetAccountImageGetUrlQuery = {
  getAccountImageGetUrl: {
    __typename: "GetAccountImageGetUrlResponse"
    url: string
    isDefault: boolean
  }
}

export type GetAccountImagePutUrlQueryVariables = {
  input: GetAccountImagePutUrlInput
}

export type GetAccountImagePutUrlQuery = {
  getAccountImagePutUrl: {
    __typename: "GetAccountImagePutUrlResponse"
    url: string
    thumbnailUrl: string
  }
}

export type GetTeamImageGetUrlQueryVariables = {
  input: GetTeamImageGetUrlInput
}

export type GetTeamImageGetUrlQuery = {
  getTeamImageGetUrl: {
    __typename: "GetTeamImageGetUrlResponse"
    url: string
    isDefault: boolean
  }
}

export type MultiTeamImageGetUrlQueryVariables = {
  input: MultiTeamImageGetUrlInput
}

export type MultiTeamImageGetUrlQuery = {
  multiTeamImageGetUrl: {
    __typename: "MultiTeamImageGetUrlResponse"
    teamImages: Array<{
      __typename: "TeamImageResponse"
      teamId: string
      thumbnail?: boolean | null
      image?: {
        __typename: "GetTeamImageGetUrlResponse"
        url: string
        isDefault: boolean
      } | null
    }>
  }
}

export type GetTeamImagePutUrlQueryVariables = {
  input: GetTeamImagePutUrlInput
}

export type GetTeamImagePutUrlQuery = {
  getTeamImagePutUrl: {
    __typename: "GetTeamImagePutUrlResponse"
    url: string
    thumbnailUrl: string
  }
}

export type GetTeamMemberImageGetUrlQueryVariables = {
  input: GetTeamMemberImageGetUrlInput
}

export type GetTeamMemberImageGetUrlQuery = {
  getTeamMemberImageGetUrl: {
    __typename: "GetTeamMemberImageGetUrlResponse"
    url: string
    isDefault: boolean
  }
}

export type MultiTeamMemberImageGetUrlQueryVariables = {
  input: MultiTeamMemberImageGetUrlInput
}

export type MultiTeamMemberImageGetUrlQuery = {
  multiTeamMemberImageGetUrl: {
    __typename: "MultiTeamMemberImageGetUrlResponse"
    teamMemberImages: Array<{
      __typename: "TeamMemberImageResponse"
      teamMemberId: string
      thumbnail?: boolean | null
      isPreTeamMember?: boolean | null
      image?: {
        __typename: "GetTeamMemberImageGetUrlResponse"
        url: string
        isDefault: boolean
      } | null
    }>
  }
}

export type GetTeamMemberImagePutUrlQueryVariables = {
  input: GetTeamMemberImagePutUrlInput
}

export type GetTeamMemberImagePutUrlQuery = {
  getTeamMemberImagePutUrl: {
    __typename: "GetTeamMemberImagePutUrlResponse"
    url: string
    thumbnailUrl: string
  }
}

export type GetMailAttachmentGetUrlQueryVariables = {
  input: GetMailAttachmentUrlInput
}

export type GetMailAttachmentGetUrlQuery = {
  getMailAttachmentGetUrl: {
    __typename: "MailAttachmentUrlResponse"
    url: string
  }
}

export type GetMailAttachmentPutUrlQueryVariables = {
  input: GetMailAttachmentUrlInput
}

export type GetMailAttachmentPutUrlQuery = {
  getMailAttachmentPutUrl: {
    __typename: "MailAttachmentUrlResponse"
    url: string
  }
}

export type GetMailAttachmentThumbnailGetUrlQueryVariables = {
  input: GetMailAttachmentUrlInput
}

export type GetMailAttachmentThumbnailGetUrlQuery = {
  getMailAttachmentThumbnailGetUrl: {
    __typename: "MailAttachmentUrlResponse"
    url: string
  }
}

export type GetMailAttachmentThumbnailPutUrlQueryVariables = {
  input: GetMailAttachmentUrlInput
}

export type GetMailAttachmentThumbnailPutUrlQuery = {
  getMailAttachmentThumbnailPutUrl: {
    __typename: "MailAttachmentUrlResponse"
    url: string
  }
}

export type GetMatchingTeamImageGetUrlQueryVariables = {
  input: GetMatchingTeamImageGetUrlInput
}

export type GetMatchingTeamImageGetUrlQuery = {
  getMatchingTeamImageGetUrl: {
    __typename: "GetMatchingTeamImageGetUrlResponse"
    url: string
    isDefault: boolean
  }
}

export type GetMatchingTeamImagePutUrlQueryVariables = {
  input: GetMatchingTeamImagePutUrlInput
}

export type GetMatchingTeamImagePutUrlQuery = {
  getMatchingTeamImagePutUrl: {
    __typename: "GetMatchingTeamImagePutUrlResponse"
    url: string
    thumbnailUrl: string
  }
}

export type GetMatchingTeamBgImageGetUrlQueryVariables = {
  input: GetMatchingTeamBgImageGetUrlInput
}

export type GetMatchingTeamBgImageGetUrlQuery = {
  getMatchingTeamBgImageGetUrl: {
    __typename: "GetMatchingTeamBgImageGetUrlResponse"
    url: string
    isDefault: boolean
  }
}

export type GetMatchingTeamBgImagePutUrlQueryVariables = {
  input: GetMatchingTeamBgImagePutUrlInput
}

export type GetMatchingTeamBgImagePutUrlQuery = {
  getMatchingTeamBgImagePutUrl: {
    __typename: "GetMatchingTeamBgImagePutUrlResponse"
    url: string
  }
}

export type GetMatchingOfferAttachmentGetUrlQueryVariables = {
  input: GetMatchingOfferAttachmentUrlInput
}

export type GetMatchingOfferAttachmentGetUrlQuery = {
  getMatchingOfferAttachmentGetUrl: {
    __typename: "MatchingOfferAttachmentUrlResponse"
    url: string
  }
}

export type GetMatchingOfferAttachmentPutUrlQueryVariables = {
  input: GetMatchingOfferAttachmentUrlInput
}

export type GetMatchingOfferAttachmentPutUrlQuery = {
  getMatchingOfferAttachmentPutUrl: {
    __typename: "MatchingOfferAttachmentUrlResponse"
    url: string
  }
}

export type GetMatchingOfferAttachmentThumbnailGetUrlQueryVariables = {
  input: GetMatchingOfferAttachmentUrlInput
}

export type GetMatchingOfferAttachmentThumbnailGetUrlQuery = {
  getMatchingOfferAttachmentThumbnailGetUrl: {
    __typename: "MatchingOfferAttachmentUrlResponse"
    url: string
  }
}

export type GetMatchingOfferAttachmentThumbnailPutUrlQueryVariables = {
  input: GetMatchingOfferAttachmentUrlInput
}

export type GetMatchingOfferAttachmentThumbnailPutUrlQuery = {
  getMatchingOfferAttachmentThumbnailPutUrl: {
    __typename: "MatchingOfferAttachmentUrlResponse"
    url: string
  }
}

export type GetUnreadMatchingMessageForTeamCountQueryVariables = {
  input: getUnreadMatchingMessageForTeamCountInput
}

export type GetUnreadMatchingMessageForTeamCountQuery = {
  getUnreadMatchingMessageForTeamCount: {
    __typename: "getUnreadMatchingMessageForTeamCountResponse"
    matchingOffer: {
      __typename: "UnreadMatchingMessageCount"
      haveUnreadMessage: boolean
      unreadMatchingApplicationCount?: Array<{
        __typename: "UnreadMatchingApplicationCount"
        id?: string | null
        count?: number | null
      }> | null
    }
    matchingApplication: {
      __typename: "UnreadMatchingMessageCount"
      haveUnreadMessage: boolean
      unreadMatchingApplicationCount?: Array<{
        __typename: "UnreadMatchingApplicationCount"
        id?: string | null
        count?: number | null
      }> | null
    }
  }
}

export type MultiAccountImageGetUrlQueryVariables = {
  input: MultiAccountImageGetUrlInput
}

export type MultiAccountImageGetUrlQuery = {
  multiAccountImageGetUrl: {
    __typename: "MultiAccountImageGetUrlResponse"
    images?: Array<{
      __typename: "ImageAccount"
      accountId: string
      url: string
    }> | null
  }
}

export type GetMatchingTeamProfileQueryVariables = {
  input: GetMatchingTeamProfileInput
}

export type GetMatchingTeamProfileQuery = {
  getMatchingTeamProfile: {
    __typename: "GetMatchingTeamProfileResponse"
    id: string
    ageFrom: number
    ageTo: number
    introduction: string
    teamUrl?: string | null
    name: string
    category: {
      __typename: "GetMatchingTeamProfileMasterType"
      id: string
      label: string
    }
    activity: {
      __typename: "GetMatchingTeamProfileMasterType"
      id: string
      label: string
    }
    prefecture: {
      __typename: "GetMatchingTeamProfileMasterType"
      id: string
      label: string
    }
    city: {
      __typename: "GetMatchingTeamProfileMasterType"
      id: string
      label: string
    }
    isBlocking: boolean
  }
}

export type MultiGetFavoriteOfferIdQueryVariables = {
  input: MultiGetFavoriteOfferIdInput
}

export type MultiGetFavoriteOfferIdQuery = {
  multiGetFavoriteOfferId: {
    __typename: "MultiGetFavoriteOfferIdResponse"
    favoriteOfferIds: Array<string>
  }
}

export type MultiGetApplicationQueryVariables = {
  input: MultiGetApplicationInput
}

export type MultiGetApplicationQuery = {
  multiGetApplication: {
    __typename: "MultiGetApplicationResponse"
    items: Array<{
      __typename: "MultiGetApplicationItem"
      offerId: string
      applications: Array<{
        __typename: "MultiGetApplicationItemApplication"
        id: string
      }>
    }>
  }
}

export type GetLatestTermsQuery = {
  getLatestTerms: {
    __typename: "GetLatestTermsResponse"
    service: {
      __typename: "TermsResponse"
      version: string
      url: string
      releaseAt: string
    }
    matching: {
      __typename: "TermsResponse"
      version: string
      url: string
      releaseAt: string
    }
  }
}

export type MultiGetMigrationStatusQueryVariables = {
  input: MultiGetMigrationStatusInput
}

export type MultiGetMigrationStatusQuery = {
  multiGetMigrationStatus: {
    __typename: "MultiGetMigrationStatusResponse"
    items: Array<{
      __typename: "MultiGetMigrationStatusItem"
      oldRa9Ra9TeamId: number
      teamId?: string | null
      isMigrated?: boolean | null
    }>
  }
}

export type ListNotificationSettingQuery = {
  listNotificationSetting: {
    __typename: "ListNotificationSettingResponse"
    items: Array<{
      __typename: "TeamNotificationSetting"
      id: string
      teamId: string
      teamName?: string | null
      teamCategory?: {
        __typename: "TeamCategory"
        id: string
        label?: string | null
      } | null
      teamProfileImage?: string | null
      role?: string | null
      mailReceivedNotice: {
        __typename: "MailReceivedNotice"
        push: boolean
        email: boolean
      }
      threadMessageReceivedNotice: {
        __typename: "ThreadMessageReceivedNotice"
        push: boolean
        email: boolean
      }
      joinRequestNotice: {
        __typename: "JoinRequestNotice"
        push: boolean
        email: boolean
      }
      teamMatchingNotice: {
        __typename: "TeamMatchingNotice"
        email: boolean
      }
      createdAt: string
      updatedAt: string
    }>
  }
}

export type GetTeamOrderQueryVariables = {
  input: GetTeamOrderInput
}

export type GetTeamOrderQuery = {
  getTeamOrder: {
    __typename: "GetTeamOrderResponse"
    usedOrder?: {
      __typename: "PaidVersionOrderDetail"
      order: {
        __typename: "PaidVersionOrderType"
        id: string
        orderNumber: string
        originalOrderId?: string | null
        orderStatus: string
        jobId?: string | null
        masterCode?: string | null
        zacStatus?: string | null
        supportedDate?: string | null
        address?: string | null
        orderType: string
        companyName?: string | null
        companyNameKana?: string | null
        corporateRepresentativeName?: string | null
        corporateRepresentativeNameKana?: string | null
        applicantPhone?: string | null
        applicantMail?: string | null
        applicantName?: string | null
        applicantPostcode?: string | null
        applicantPrefecture?: string | null
        applicantAddress?: string | null
        applicantBuildingName?: string | null
        applicantRemark?: string | null
        antiSocialCheck: string
        teamId?: string | null
        teamName?: string | null
        applicantId?: string | null
        transferApplicantId?: string | null
        planId: string
        startDate: string
        endDate: string
        paymentStatus: string
        paymentDate?: string | null
        paymentDeadline?: string | null
        invoiceIssuedDate?: string | null
        paymentType: string
        paymentMethod: string
        invoiceAddressee?: string | null
        invoiceAddresseeKana?: string | null
        amount: number
        taxRate: number
        tax: number
        subTotal: number
        remark?: string | null
        adminId?: string | null
        createdAt?: string | null
        updatedAt?: string | null
      }
      plan: {
        __typename: "PlanItem"
        id: string
        planCode: string
        name: string
        type: string
        memberLimit?: number | null
        teamLimit?: number | null
        period: number
        amount: number
        planType: PlanType
      }
    } | null
    preOrder?: {
      __typename: "PaidVersionOrderDetail"
      order: {
        __typename: "PaidVersionOrderType"
        id: string
        orderNumber: string
        originalOrderId?: string | null
        orderStatus: string
        jobId?: string | null
        masterCode?: string | null
        zacStatus?: string | null
        supportedDate?: string | null
        address?: string | null
        orderType: string
        companyName?: string | null
        companyNameKana?: string | null
        corporateRepresentativeName?: string | null
        corporateRepresentativeNameKana?: string | null
        applicantPhone?: string | null
        applicantMail?: string | null
        applicantName?: string | null
        applicantPostcode?: string | null
        applicantPrefecture?: string | null
        applicantAddress?: string | null
        applicantBuildingName?: string | null
        applicantRemark?: string | null
        antiSocialCheck: string
        teamId?: string | null
        teamName?: string | null
        applicantId?: string | null
        transferApplicantId?: string | null
        planId: string
        startDate: string
        endDate: string
        paymentStatus: string
        paymentDate?: string | null
        paymentDeadline?: string | null
        invoiceIssuedDate?: string | null
        paymentType: string
        paymentMethod: string
        invoiceAddressee?: string | null
        invoiceAddresseeKana?: string | null
        amount: number
        taxRate: number
        tax: number
        subTotal: number
        remark?: string | null
        adminId?: string | null
        createdAt?: string | null
        updatedAt?: string | null
      }
      plan: {
        __typename: "PlanItem"
        id: string
        planCode: string
        name: string
        type: string
        memberLimit?: number | null
        teamLimit?: number | null
        period: number
        amount: number
        planType: PlanType
      }
    } | null
  }
}

export type GetNextPaidVersionOrderNumberQuery = {
  getNextPaidVersionOrderNumber: {
    __typename: "GetNextPaidVersionOrderNumberResponse"
    nextNumber: string
  }
}

export type ListPlanQuery = {
  listPlan: {
    __typename: "listPlanResponse"
    items: Array<{
      __typename: "ListPlanItem"
      id: string
      planCode: string
      name: string
      type: string
      memberLimit?: number | null
      teamLimit?: number | null
      period: number
      amount: number
      taxRate: number
      planType: PlanType
    }>
  }
}

export type SearchTeamMemberQueryVariables = {
  input: SearchTeamMemberInput
}

export type SearchTeamMemberQuery = {
  searchTeamMember: {
    __typename: "SearchTeamMemberResponse"
    items: Array<{
      __typename: "TeamMemberType"
      id?: string | null
      memberSeq: number
      nickname: string
      nicknameKana: string
      role: MemberRole
      showEmailFlg?: boolean | null
      phoneNumber?: string | null
      showPhoneNumberFlg?: boolean | null
      belongs?: string | null
      showBelongsFlg?: boolean | null
      grade?: number | null
      teamIdGsi: string
      teamTeamMembersId: string
      teamMemberNotificationSettingId?: string | null
      mailMigratedFlg?: boolean | null
      deleteFlg?: boolean | null
      accountTeamMembersId?: string | null
      createdAt?: string | null
      updatedAt?: string | null
      groups: Array<{
        __typename: "GroupTeamMemberType"
        id: string
        leaderFlg?: boolean | null
        teamMemberID?: string | null
        groupID?: string | null
        createdAt?: string | null
        updatedAt?: string | null
      }>
    }>
    total: number
    limit: number
    page: number
    sortBy: string
    orderBy: OrderBy
  }
}

export type SearchGroupMemberQueryVariables = {
  input: SearchGroupMemberInput
}

export type SearchGroupMemberQuery = {
  searchGroupMember: {
    __typename: "SearchGroupMemberResponse"
    items: Array<{
      __typename: "GroupMemberType"
      id?: string | null
      memberSeq: number
      nickname: string
      nicknameKana: string
      accountTeamMembersId: string
      role: MemberRole
      leaderFlg?: boolean | null
    }>
    total: number
    limit: number
    page: number
    sortBy: string
    orderBy: OrderBy
  }
}

export type SearchInvitationEmailStatusesQueryVariables = {
  filter?: SearchableInvitationEmailStatusFilterInput | null
  sort?: Array<SearchableInvitationEmailStatusSortInput | null> | null
  limit?: number | null
  nextToken?: string | null
  from?: number | null
  aggregates?: Array<SearchableInvitationEmailStatusAggregationInput | null> | null
}

export type SearchInvitationEmailStatusesQuery = {
  searchInvitationEmailStatuses?: {
    __typename: "SearchableInvitationEmailStatusConnection"
    items: Array<{
      __typename: "InvitationEmailStatus"
      id: string
      email: string
      status: EmailStatus
      repicaDeliverId: string
      repicaKeyField: string
      repicaSentDate: string
      errorMessage?: string | null
      createdAt: string
      teamInvitationEmailStatusesId: string
      updatedAt: string
    } | null>
    nextToken?: string | null
    total?: number | null
    aggregateItems: Array<{
      __typename: "SearchableAggregateResult"
      name: string
      result:
        | (
            | {
                __typename: "SearchableAggregateScalarResult"
                value: number
              }
            | {
                __typename: "SearchableAggregateBucketResult"
                buckets?: Array<{
                  __typename: string
                  key: string
                  doc_count: number
                } | null> | null
              }
          )
        | null
    } | null>
  } | null
}

export type SearchTeamMemberMailsQueryVariables = {
  filter?: SearchableTeamMemberMailFilterInput | null
  sort?: Array<SearchableTeamMemberMailSortInput | null> | null
  limit?: number | null
  nextToken?: string | null
  from?: number | null
  aggregates?: Array<SearchableTeamMemberMailAggregationInput | null> | null
}

export type SearchTeamMemberMailsQuery = {
  searchTeamMemberMails?: {
    __typename: "SearchableTeamMemberMailConnection"
    items: Array<{
      __typename: "TeamMemberMail"
      id: string
      receivedFlg: boolean
      sentFlg: boolean
      readFlg: boolean
      readAt?: string | null
      favoriteFlgReceived: boolean
      favoriteFlgSent: boolean
      answerRequiredFlg: boolean
      responseDueDatetimeForFilter: string
      answeredFlg: boolean
      answeredAt?: string | null
      attendanceAnswer?: YesNoAnswer | null
      questionnaireAnswers?: Array<{
        __typename: "QuestionnaireAnswers"
        answer: Array<number>
      }> | null
      scheduleAnswers?: Array<YesNoAnswer> | null
      senderId: string
      mailType: string
      title: string
      body: string
      hasAttachment: boolean
      hasThread: boolean
      trashedFlgReceived: boolean
      deletedFlgReceived: boolean
      deletedFlgSent: boolean
      count: number
      teamMemberID?: string | null
      mailID?: string | null
      teamMember?: {
        __typename: "TeamMember"
        id: string
        memberSeq: number
        nickname: string
        nicknameKana: string
        role: string
        showEmailFlg?: boolean | null
        phoneNumber?: string | null
        showPhoneNumberFlg?: boolean | null
        belongs?: string | null
        showBelongsFlg?: boolean | null
        grade?: number | null
        teamIdGsi: string
        teamTeamMembersId?: string | null
        mailMigratedFlg?: boolean | null
        deleteFlg?: boolean | null
        accountTeamMembersId: string
        migrateTeamMemberId?: number | null
        createdAt: string
        updatedAt: string
        teamMemberNotificationSettingId?: string | null
      } | null
      mail?: {
        __typename: "Mail"
        id: string
        teamId: string
        mailType: string
        title: string
        body: string
        attachmentFileNames?: Array<string> | null
        startDate?: string | null
        startTime?: string | null
        endDate?: string | null
        endTime?: string | null
        shareManagerFlg?: number | null
        showAnswerFlg?: boolean | null
        responseDueDatetime?: string | null
        remindDate: string
        remindDatetime?: string | null
        remindExecDatetime?: string | null
        sendScheduledAt?: string | null
        sentAt: string
        createdAt: string
        updatedAt: string
        mailSenderId?: string | null
      } | null
      createdYearMonth?: number | null
      createdDate?: string | null
      createdAt: string
      updatedAt: string
    } | null>
    nextToken?: string | null
    total?: number | null
    aggregateItems: Array<{
      __typename: "SearchableAggregateResult"
      name: string
      result:
        | (
            | {
                __typename: "SearchableAggregateScalarResult"
                value: number
              }
            | {
                __typename: "SearchableAggregateBucketResult"
                buckets?: Array<{
                  __typename: string
                  key: string
                  doc_count: number
                } | null> | null
              }
          )
        | null
    } | null>
  } | null
}

export type SearchThreadMessagesQueryVariables = {
  filter?: SearchableThreadMessageFilterInput | null
  sort?: Array<SearchableThreadMessageSortInput | null> | null
  limit?: number | null
  nextToken?: string | null
  from?: number | null
  aggregates?: Array<SearchableThreadMessageAggregationInput | null> | null
}

export type SearchThreadMessagesQuery = {
  searchThreadMessages?: {
    __typename: "SearchableThreadMessageConnection"
    items: Array<{
      __typename: "ThreadMessage"
      id: string
      messageSenderId: string
      messageRecipientAccountId: string
      teamId: string
      mailId: string
      text?: string | null
      unreadFlg: number
      deleteFlg?: boolean | null
      contentUpdatedAt: string
      createdAt: string
      createdYearMonth?: number | null
      createdDate?: string | null
      threadMessagesId: string
      updatedAt: string
    } | null>
    nextToken?: string | null
    total?: number | null
    aggregateItems: Array<{
      __typename: "SearchableAggregateResult"
      name: string
      result:
        | (
            | {
                __typename: "SearchableAggregateScalarResult"
                value: number
              }
            | {
                __typename: "SearchableAggregateBucketResult"
                buckets?: Array<{
                  __typename: string
                  key: string
                  doc_count: number
                } | null> | null
              }
          )
        | null
    } | null>
  } | null
}

export type SearchMigratedMailsQueryVariables = {
  filter?: SearchableMigratedMailFilterInput | null
  sort?: Array<SearchableMigratedMailSortInput | null> | null
  limit?: number | null
  nextToken?: string | null
  from?: number | null
  aggregates?: Array<SearchableMigratedMailAggregationInput | null> | null
}

export type SearchMigratedMailsQuery = {
  searchMigratedMails?: {
    __typename: "SearchableMigratedMailConnection"
    items: Array<{
      __typename: "MigratedMail"
      id: string
      migrationId: number
      title: string
      body: string
      sender: string
      favoriteFlg: boolean
      shareManagerFlg: boolean
      sentDatetime: string
      createdAt: string
      updatedAt: string
      teamMemberMigratedMailsId?: string | null
    } | null>
    nextToken?: string | null
    total?: number | null
    aggregateItems: Array<{
      __typename: "SearchableAggregateResult"
      name: string
      result:
        | (
            | {
                __typename: "SearchableAggregateScalarResult"
                value: number
              }
            | {
                __typename: "SearchableAggregateBucketResult"
                buckets?: Array<{
                  __typename: string
                  key: string
                  doc_count: number
                } | null> | null
              }
          )
        | null
    } | null>
  } | null
}

export type SearchMatchingOffersQueryVariables = {
  filter?: SearchableMatchingOfferFilterInput | null
  sort?: Array<SearchableMatchingOfferSortInput | null> | null
  limit?: number | null
  nextToken?: string | null
  from?: number | null
  aggregates?: Array<SearchableMatchingOfferAggregationInput | null> | null
}

export type SearchMatchingOffersQuery = {
  searchMatchingOffers?: {
    __typename: "SearchableMatchingOfferConnection"
    items: Array<{
      __typename: "MatchingOffer"
      id: string
      purpose: MatchingOfferPurpose
      title: string
      body: string
      date?: string | null
      startTime?: string | null
      endTime?: string | null
      prefecture?: {
        __typename: "PrefectureMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      city?: {
        __typename: "CityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
        prefectureMasterCitiesId?: string | null
      } | null
      place?: string | null
      placeURL?: string | null
      expense?: number | null
      expensePurpose?: string | null
      ageFrom?: number | null
      ageTo?: number | null
      offerCount?: number | null
      publishEndDate: string
      team?: {
        __typename: "Team"
        id: string
        name: string
        description: string
        requirementForMemberProfile?: string | null
        customQuestions?: Array<TeamCustomQuestion> | null
        showMemberListFlg: boolean
        invitationCode?: string | null
        migrationId?: number | null
        paidFunctionEnabled?: boolean | null
        showAdsFlg?: boolean | null
        createdAt: string
        updatedAt: string
        teamCategoryId?: string | null
        teamActivityId?: string | null
        teamPrefectureId?: string | null
        teamCityId?: string | null
      } | null
      attachmentFileNames?: Array<string> | null
      activity?: {
        __typename: "TeamActivityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      teamId: string
      teamName: string
      applications?: {
        __typename: "ModelMatchingApplicationConnection"
        nextToken?: string | null
      } | null
      deleteFlg?: boolean | null
      createdAt: string
      updatedAt: string
      teamMatchingOffersId?: string | null
      matchingOfferPrefectureId?: string | null
      matchingOfferCityId?: string | null
      matchingOfferActivityId?: string | null
    } | null>
    nextToken?: string | null
    total?: number | null
    aggregateItems: Array<{
      __typename: "SearchableAggregateResult"
      name: string
      result:
        | (
            | {
                __typename: "SearchableAggregateScalarResult"
                value: number
              }
            | {
                __typename: "SearchableAggregateBucketResult"
                buckets?: Array<{
                  __typename: string
                  key: string
                  doc_count: number
                } | null> | null
              }
          )
        | null
    } | null>
  } | null
}

export type SearchMatchingApplicationsQueryVariables = {
  filter?: SearchableMatchingApplicationFilterInput | null
  sort?: Array<SearchableMatchingApplicationSortInput | null> | null
  limit?: number | null
  nextToken?: string | null
  from?: number | null
  aggregates?: Array<SearchableMatchingApplicationAggregationInput | null> | null
}

export type SearchMatchingApplicationsQuery = {
  searchMatchingApplications?: {
    __typename: "SearchableMatchingApplicationConnection"
    items: Array<{
      __typename: "MatchingApplication"
      id: string
      applicant?: {
        __typename: "Team"
        id: string
        name: string
        description: string
        requirementForMemberProfile?: string | null
        customQuestions?: Array<TeamCustomQuestion> | null
        showMemberListFlg: boolean
        invitationCode?: string | null
        migrationId?: number | null
        paidFunctionEnabled?: boolean | null
        showAdsFlg?: boolean | null
        createdAt: string
        updatedAt: string
        teamCategoryId?: string | null
        teamActivityId?: string | null
        teamPrefectureId?: string | null
        teamCityId?: string | null
      } | null
      offer?: {
        __typename: "MatchingOffer"
        id: string
        purpose: MatchingOfferPurpose
        title: string
        body: string
        date?: string | null
        startTime?: string | null
        endTime?: string | null
        place?: string | null
        placeURL?: string | null
        expense?: number | null
        expensePurpose?: string | null
        ageFrom?: number | null
        ageTo?: number | null
        offerCount?: number | null
        publishEndDate: string
        attachmentFileNames?: Array<string> | null
        teamId: string
        teamName: string
        deleteFlg?: boolean | null
        createdAt: string
        updatedAt: string
        teamMatchingOffersId?: string | null
        matchingOfferPrefectureId?: string | null
        matchingOfferCityId?: string | null
        matchingOfferActivityId?: string | null
      } | null
      messages?: {
        __typename: "ModelMatchingMessageConnection"
        nextToken?: string | null
      } | null
      messagesIncludeDeleted?: {
        __typename: "ModelMatchingMessageConnection"
        nextToken?: string | null
      } | null
      offerTeamName: string
      offerTitle: string
      offerBody: string
      createdAt: string
      updatedAt: string
      teamMatchingApplicationsId?: string | null
      matchingOfferApplicationsId?: string | null
    } | null>
    nextToken?: string | null
    total?: number | null
    aggregateItems: Array<{
      __typename: "SearchableAggregateResult"
      name: string
      result:
        | (
            | {
                __typename: "SearchableAggregateScalarResult"
                value: number
              }
            | {
                __typename: "SearchableAggregateBucketResult"
                buckets?: Array<{
                  __typename: string
                  key: string
                  doc_count: number
                } | null> | null
              }
          )
        | null
    } | null>
  } | null
}

export type SearchPaidVersionOrdersQueryVariables = {
  filter?: SearchablePaidVersionOrderFilterInput | null
  sort?: Array<SearchablePaidVersionOrderSortInput | null> | null
  limit?: number | null
  nextToken?: string | null
  from?: number | null
  aggregates?: Array<SearchablePaidVersionOrderAggregationInput | null> | null
}

export type SearchPaidVersionOrdersQuery = {
  searchPaidVersionOrders?: {
    __typename: "SearchablePaidVersionOrderConnection"
    items: Array<{
      __typename: "PaidVersionOrder"
      id: string
      orderNumber: string
      originalOrderId?: string | null
      orderStatus: string
      jobId?: string | null
      masterCode?: string | null
      zacStatus?: string | null
      supportedDate?: string | null
      address?: string | null
      orderType: string
      companyName?: string | null
      companyNameKana?: string | null
      corporateRepresentativeName?: string | null
      corporateRepresentativeNameKana?: string | null
      applicantPhone?: string | null
      applicantMail?: string | null
      applicantName?: string | null
      applicantPostcode?: string | null
      applicantPrefecture?: string | null
      applicantAddress?: string | null
      applicantBuildingName?: string | null
      applicantRemark?: string | null
      antiSocialCheck: string
      teamId?: string | null
      migrateTeamId?: number | null
      teamName?: string | null
      memberCount?: number | null
      applicantId?: string | null
      transferApplicantId?: string | null
      planId: string
      startDate: string
      endDate: string
      paymentStatus: string
      paymentDate?: string | null
      paymentDeadline?: string | null
      invoiceIssuedDate?: string | null
      paymentType: string
      paymentMethod: string
      invoiceAddressee?: string | null
      invoiceAddresseeKana?: string | null
      amount: number
      taxRate: number
      tax: number
      subTotal: number
      remark?: string | null
      team?: {
        __typename: "Team"
        id: string
        name: string
        description: string
        requirementForMemberProfile?: string | null
        customQuestions?: Array<TeamCustomQuestion> | null
        showMemberListFlg: boolean
        invitationCode?: string | null
        migrationId?: number | null
        paidFunctionEnabled?: boolean | null
        showAdsFlg?: boolean | null
        createdAt: string
        updatedAt: string
        teamCategoryId?: string | null
        teamActivityId?: string | null
        teamPrefectureId?: string | null
        teamCityId?: string | null
      } | null
      plan?: {
        __typename: "PaidVersionPlan"
        id: string
        planCode: string
        name: string
        type: string
        memberLimit?: number | null
        teamLimit?: number | null
        period: number
        amount: number
        planType: PlanType
        createdAt: string
        updatedAt: string
      } | null
      applicant?: {
        __typename: "TeamMember"
        id: string
        memberSeq: number
        nickname: string
        nicknameKana: string
        role: string
        showEmailFlg?: boolean | null
        phoneNumber?: string | null
        showPhoneNumberFlg?: boolean | null
        belongs?: string | null
        showBelongsFlg?: boolean | null
        grade?: number | null
        teamIdGsi: string
        teamTeamMembersId?: string | null
        mailMigratedFlg?: boolean | null
        deleteFlg?: boolean | null
        accountTeamMembersId: string
        migrateTeamMemberId?: number | null
        createdAt: string
        updatedAt: string
        teamMemberNotificationSettingId?: string | null
      } | null
      applicantTransfer?: {
        __typename: "TeamMember"
        id: string
        memberSeq: number
        nickname: string
        nicknameKana: string
        role: string
        showEmailFlg?: boolean | null
        phoneNumber?: string | null
        showPhoneNumberFlg?: boolean | null
        belongs?: string | null
        showBelongsFlg?: boolean | null
        grade?: number | null
        teamIdGsi: string
        teamTeamMembersId?: string | null
        mailMigratedFlg?: boolean | null
        deleteFlg?: boolean | null
        accountTeamMembersId: string
        migrateTeamMemberId?: number | null
        createdAt: string
        updatedAt: string
        teamMemberNotificationSettingId?: string | null
      } | null
      orderHistories?: {
        __typename: "ModelOrderHistoryConnection"
        nextToken?: string | null
      } | null
      transferStatus?: {
        __typename: "ModelTransferStatusConnection"
        nextToken?: string | null
      } | null
      adminId?: string | null
      createdAt: string
      updatedAt: string
    } | null>
    nextToken?: string | null
    total?: number | null
    aggregateItems: Array<{
      __typename: "SearchableAggregateResult"
      name: string
      result:
        | (
            | {
                __typename: "SearchableAggregateScalarResult"
                value: number
              }
            | {
                __typename: "SearchableAggregateBucketResult"
                buckets?: Array<{
                  __typename: string
                  key: string
                  doc_count: number
                } | null> | null
              }
          )
        | null
    } | null>
  } | null
}

export type GetAdminNoticeQueryVariables = {
  id: string
}

export type GetAdminNoticeQuery = {
  getAdminNotice?: {
    __typename: "AdminNotice"
    id: string
    label: string
    title: string
    content: string
    priority?: AdminNoticePriority | null
    publishMonth: number
    publishDatetime: string
    createdAt: string
    updatedAt: string
  } | null
}

export type _listAdminNoticeQueryVariables = {
  filter?: ModelAdminNoticeFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type _listAdminNoticeQuery = {
  _listAdminNotice?: {
    __typename: "ModelAdminNoticeConnection"
    items: Array<{
      __typename: "AdminNotice"
      id: string
      label: string
      title: string
      content: string
      priority?: AdminNoticePriority | null
      publishMonth: number
      publishDatetime: string
      createdAt: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type GetAccountQueryVariables = {
  id: string
}

export type GetAccountQuery = {
  getAccount?: {
    __typename: "Account"
    id: string
    email: string
    lastName: string
    firstName: string
    lastNameKana: string
    firstNameKana: string
    phoneNumber?: string | null
    gender?: Gender | null
    birthday?: string | null
    job?: {
      __typename: "JobMaster"
      id: string
      label: string
      type: JobType
      displayNumber: number
      displayFlg: number
      createdAt: string
      updatedAt: string
    } | null
    school?: {
      __typename: "SchoolMaster"
      id: string
      label: string
      labelKana: string
      type: string
      code?: string | null
      displayNumber: number
      displayFlg: number
      departments?: {
        __typename: "ModelSchoolDepartmentMasterConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
    } | null
    schoolDepartment?: {
      __typename: "SchoolDepartmentMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      createdAt: string
      updatedAt: string
      schoolMasterDepartmentsId?: string | null
    } | null
    schoolGraduationYear?: number | null
    prefecture?: {
      __typename: "PrefectureMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      cities?: {
        __typename: "ModelCityMasterConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
    } | null
    city?: {
      __typename: "CityMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      createdAt: string
      updatedAt: string
      prefectureMasterCitiesId?: string | null
    } | null
    questionAnswers?: {
      __typename: "ModelAccountQuestionAnswerConnection"
      items: Array<{
        __typename: "AccountQuestionAnswer"
        id: string
        createdAt: string
        updatedAt: string
        accountQuestionAnswersId?: string | null
        accountQuestionAnswerQuestionId?: string | null
        accountQuestionAnswerAnswerId?: string | null
      } | null>
      nextToken?: string | null
    } | null
    termsAgreedAt?: string | null
    agreedTermsVersion?: string | null
    matchingTermsAgreedAt?: string | null
    agreedMatchingTermsVersion?: string | null
    signupStatus: SignupStatus
    accountDevices?: {
      __typename: "ModelAccountDeviceConnection"
      items: Array<{
        __typename: "AccountDevice"
        id: string
        accountID?: string | null
        deviceID?: string | null
        createdAt: string
        updatedAt: string
      } | null>
      nextToken?: string | null
    } | null
    teamMembers?: {
      __typename: "ModelTeamMemberConnection"
      items: Array<{
        __typename: "TeamMember"
        id: string
        memberSeq: number
        nickname: string
        nicknameKana: string
        role: string
        showEmailFlg?: boolean | null
        phoneNumber?: string | null
        showPhoneNumberFlg?: boolean | null
        belongs?: string | null
        showBelongsFlg?: boolean | null
        grade?: number | null
        teamIdGsi: string
        teamTeamMembersId?: string | null
        mailMigratedFlg?: boolean | null
        deleteFlg?: boolean | null
        accountTeamMembersId: string
        migrateTeamMemberId?: number | null
        createdAt: string
        updatedAt: string
        teamMemberNotificationSettingId?: string | null
      } | null>
      nextToken?: string | null
    } | null
    preTeamMembers?: {
      __typename: "ModelPreTeamMemberConnection"
      items: Array<{
        __typename: "PreTeamMember"
        id: string
        nickname: string
        nicknameKana: string
        showEmailFlg?: boolean | null
        phoneNumber?: string | null
        showPhoneNumberFlg?: boolean | null
        belongs?: string | null
        showBelongsFlg?: boolean | null
        grade?: number | null
        createdAt: string
        updatedAt: string
        accountPreTeamMembersId?: string | null
        teamPreTeamMembersId?: string | null
      } | null>
      nextToken?: string | null
    } | null
    teamNotices?: {
      __typename: "ModelTeamNoticeConnection"
      items: Array<{
        __typename: "TeamNotice"
        id: string
        noticeType: string
        title: string
        readFlg: boolean
        url: string
        createdAt: string
        createdYearMonth?: number | null
        createdDate?: string | null
        accountTeamNoticesId: string
        updatedAt: string
        teamMemberTeamNoticeId?: string | null
        teamNoticeTeamId?: string | null
      } | null>
      nextToken?: string | null
    } | null
    haveOldRa9TeamNotJoin: boolean
    migrateAccountId?: number | null
    createdAt: string
    updatedAt: string
    accountJobId?: string | null
    accountSchoolId?: string | null
    accountSchoolDepartmentId?: string | null
    accountPrefectureId?: string | null
    accountCityId?: string | null
  } | null
}

export type _listAccountQueryVariables = {
  filter?: ModelAccountFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type _listAccountQuery = {
  _listAccount?: {
    __typename: "ModelAccountConnection"
    items: Array<{
      __typename: "Account"
      id: string
      email: string
      lastName: string
      firstName: string
      lastNameKana: string
      firstNameKana: string
      phoneNumber?: string | null
      gender?: Gender | null
      birthday?: string | null
      job?: {
        __typename: "JobMaster"
        id: string
        label: string
        type: JobType
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      school?: {
        __typename: "SchoolMaster"
        id: string
        label: string
        labelKana: string
        type: string
        code?: string | null
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      schoolDepartment?: {
        __typename: "SchoolDepartmentMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
        schoolMasterDepartmentsId?: string | null
      } | null
      schoolGraduationYear?: number | null
      prefecture?: {
        __typename: "PrefectureMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      city?: {
        __typename: "CityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
        prefectureMasterCitiesId?: string | null
      } | null
      questionAnswers?: {
        __typename: "ModelAccountQuestionAnswerConnection"
        nextToken?: string | null
      } | null
      termsAgreedAt?: string | null
      agreedTermsVersion?: string | null
      matchingTermsAgreedAt?: string | null
      agreedMatchingTermsVersion?: string | null
      signupStatus: SignupStatus
      accountDevices?: {
        __typename: "ModelAccountDeviceConnection"
        nextToken?: string | null
      } | null
      teamMembers?: {
        __typename: "ModelTeamMemberConnection"
        nextToken?: string | null
      } | null
      preTeamMembers?: {
        __typename: "ModelPreTeamMemberConnection"
        nextToken?: string | null
      } | null
      teamNotices?: {
        __typename: "ModelTeamNoticeConnection"
        nextToken?: string | null
      } | null
      haveOldRa9TeamNotJoin: boolean
      migrateAccountId?: number | null
      createdAt: string
      updatedAt: string
      accountJobId?: string | null
      accountSchoolId?: string | null
      accountSchoolDepartmentId?: string | null
      accountPrefectureId?: string | null
      accountCityId?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetNotificationSettingQueryVariables = {
  id: string
}

export type GetNotificationSettingQuery = {
  getNotificationSetting?: {
    __typename: "NotificationSetting"
    id: string
    mailReceivedNotice: {
      __typename: "MailReceivedNotice"
      push: boolean
      email: boolean
    }
    threadMessageReceivedNotice: {
      __typename: "ThreadMessageReceivedNotice"
      push: boolean
      email: boolean
    }
    joinRequestNotice: {
      __typename: "JoinRequestNotice"
      push: boolean
      email: boolean
    }
    teamMatchingNotice: {
      __typename: "TeamMatchingNotice"
      email: boolean
    }
    createdAt: string
    updatedAt: string
  } | null
}

export type _listPreAccountQueryVariables = {
  email?: string | null
  filter?: ModelPreAccountFilterInput | null
  limit?: number | null
  nextToken?: string | null
  sortDirection?: ModelSortDirection | null
}

export type _listPreAccountQuery = {
  _listPreAccount?: {
    __typename: "ModelPreAccountConnection"
    items: Array<{
      __typename: "PreAccount"
      email: string
      createdYearMonth?: number | null
      createdDate?: string | null
      createdAt: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type GetTeamQueryVariables = {
  id: string
}

export type GetTeamQuery = {
  getTeam?: {
    __typename: "Team"
    id: string
    name: string
    category?: {
      __typename: "TeamCategoryMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      activities?: {
        __typename: "ModelTeamCategoryActivityConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
    } | null
    activity?: {
      __typename: "TeamActivityMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      categories?: {
        __typename: "ModelTeamCategoryActivityConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
    } | null
    prefecture?: {
      __typename: "PrefectureMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      cities?: {
        __typename: "ModelCityMasterConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
    } | null
    city?: {
      __typename: "CityMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      createdAt: string
      updatedAt: string
      prefectureMasterCitiesId?: string | null
    } | null
    description: string
    requirementForMemberProfile?: string | null
    customQuestions?: Array<TeamCustomQuestion> | null
    showMemberListFlg: boolean
    invitationCode?: string | null
    teamMembers?: {
      __typename: "ModelTeamMemberConnection"
      items: Array<{
        __typename: "TeamMember"
        id: string
        memberSeq: number
        nickname: string
        nicknameKana: string
        role: string
        showEmailFlg?: boolean | null
        phoneNumber?: string | null
        showPhoneNumberFlg?: boolean | null
        belongs?: string | null
        showBelongsFlg?: boolean | null
        grade?: number | null
        teamIdGsi: string
        teamTeamMembersId?: string | null
        mailMigratedFlg?: boolean | null
        deleteFlg?: boolean | null
        accountTeamMembersId: string
        migrateTeamMemberId?: number | null
        createdAt: string
        updatedAt: string
        teamMemberNotificationSettingId?: string | null
      } | null>
      nextToken?: string | null
    } | null
    preTeamMembers?: {
      __typename: "ModelPreTeamMemberConnection"
      items: Array<{
        __typename: "PreTeamMember"
        id: string
        nickname: string
        nicknameKana: string
        showEmailFlg?: boolean | null
        phoneNumber?: string | null
        showPhoneNumberFlg?: boolean | null
        belongs?: string | null
        showBelongsFlg?: boolean | null
        grade?: number | null
        createdAt: string
        updatedAt: string
        accountPreTeamMembersId?: string | null
        teamPreTeamMembersId?: string | null
      } | null>
      nextToken?: string | null
    } | null
    invitationEmailStatuses?: {
      __typename: "ModelInvitationEmailStatusConnection"
      items: Array<{
        __typename: "InvitationEmailStatus"
        id: string
        email: string
        status: EmailStatus
        repicaDeliverId: string
        repicaKeyField: string
        repicaSentDate: string
        errorMessage?: string | null
        createdAt: string
        teamInvitationEmailStatusesId: string
        updatedAt: string
      } | null>
      nextToken?: string | null
    } | null
    groups?: {
      __typename: "ModelGroupConnection"
      items: Array<{
        __typename: "Group"
        id: string
        name?: string | null
        migrationId?: number | null
        createdAt: string
        updatedAt: string
        teamGroupsId?: string | null
      } | null>
      nextToken?: string | null
    } | null
    matchingOffers?: {
      __typename: "ModelMatchingOfferConnection"
      items: Array<{
        __typename: "MatchingOffer"
        id: string
        purpose: MatchingOfferPurpose
        title: string
        body: string
        date?: string | null
        startTime?: string | null
        endTime?: string | null
        place?: string | null
        placeURL?: string | null
        expense?: number | null
        expensePurpose?: string | null
        ageFrom?: number | null
        ageTo?: number | null
        offerCount?: number | null
        publishEndDate: string
        attachmentFileNames?: Array<string> | null
        teamId: string
        teamName: string
        deleteFlg?: boolean | null
        createdAt: string
        updatedAt: string
        teamMatchingOffersId?: string | null
        matchingOfferPrefectureId?: string | null
        matchingOfferCityId?: string | null
        matchingOfferActivityId?: string | null
      } | null>
      nextToken?: string | null
    } | null
    matchingOfferDrafts?: {
      __typename: "ModelMatchingOfferDraftConnection"
      items: Array<{
        __typename: "MatchingOfferDraft"
        id: string
        purpose?: MatchingOfferPurpose | null
        title: string
        body?: string | null
        date?: string | null
        startTime?: string | null
        endTime?: string | null
        place?: string | null
        placeURL?: string | null
        expense?: number | null
        expensePurpose?: string | null
        ageFrom?: number | null
        ageTo?: number | null
        offerCount?: number | null
        publishEndDate?: string | null
        createdAt: string
        updatedAt: string
        teamMatchingOfferDraftsId?: string | null
        matchingOfferDraftPrefectureId?: string | null
        matchingOfferDraftCityId?: string | null
      } | null>
      nextToken?: string | null
    } | null
    matchingApplications?: {
      __typename: "ModelMatchingApplicationConnection"
      items: Array<{
        __typename: "MatchingApplication"
        id: string
        offerTeamName: string
        offerTitle: string
        offerBody: string
        createdAt: string
        updatedAt: string
        teamMatchingApplicationsId?: string | null
        matchingOfferApplicationsId?: string | null
      } | null>
      nextToken?: string | null
    } | null
    blockingMatchingTeams?: {
      __typename: "ModelBlockingMatchingTeamConnection"
      items: Array<{
        __typename: "BlockingMatchingTeam"
        id: string
        blockingTeamId: string
        createdAt: string
        updatedAt: string
        teamBlockingMatchingTeamsId?: string | null
      } | null>
      nextToken?: string | null
    } | null
    paidVersionOrders?: {
      __typename: "ModelPaidVersionOrderConnection"
      items: Array<{
        __typename: "PaidVersionOrder"
        id: string
        orderNumber: string
        originalOrderId?: string | null
        orderStatus: string
        jobId?: string | null
        masterCode?: string | null
        zacStatus?: string | null
        supportedDate?: string | null
        address?: string | null
        orderType: string
        companyName?: string | null
        companyNameKana?: string | null
        corporateRepresentativeName?: string | null
        corporateRepresentativeNameKana?: string | null
        applicantPhone?: string | null
        applicantMail?: string | null
        applicantName?: string | null
        applicantPostcode?: string | null
        applicantPrefecture?: string | null
        applicantAddress?: string | null
        applicantBuildingName?: string | null
        applicantRemark?: string | null
        antiSocialCheck: string
        teamId?: string | null
        migrateTeamId?: number | null
        teamName?: string | null
        memberCount?: number | null
        applicantId?: string | null
        transferApplicantId?: string | null
        planId: string
        startDate: string
        endDate: string
        paymentStatus: string
        paymentDate?: string | null
        paymentDeadline?: string | null
        invoiceIssuedDate?: string | null
        paymentType: string
        paymentMethod: string
        invoiceAddressee?: string | null
        invoiceAddresseeKana?: string | null
        amount: number
        taxRate: number
        tax: number
        subTotal: number
        remark?: string | null
        adminId?: string | null
        createdAt: string
        updatedAt: string
      } | null>
      nextToken?: string | null
    } | null
    blockingMatchingTeamsByBlockingTeamId?: {
      __typename: "ModelBlockingMatchingTeamConnection"
      items: Array<{
        __typename: "BlockingMatchingTeam"
        id: string
        blockingTeamId: string
        createdAt: string
        updatedAt: string
        teamBlockingMatchingTeamsId?: string | null
      } | null>
      nextToken?: string | null
    } | null
    migrationId?: number | null
    paidFunctionEnabled?: boolean | null
    showAdsFlg?: boolean | null
    createdAt: string
    updatedAt: string
    teamCategoryId?: string | null
    teamActivityId?: string | null
    teamPrefectureId?: string | null
    teamCityId?: string | null
  } | null
}

export type _listVolatileInvitationIdQueryVariables = {
  filter?: ModelVolatileInvitationIdFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type _listVolatileInvitationIdQuery = {
  _listVolatileInvitationId?: {
    __typename: "ModelVolatileInvitationIdConnection"
    items: Array<{
      __typename: "VolatileInvitationId"
      id: string
      invitationCode: string
      createdYearMonth?: number | null
      createdDate?: string | null
      createdAt: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type _listInvitationEmailStatusQueryVariables = {
  filter?: ModelInvitationEmailStatusFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type _listInvitationEmailStatusQuery = {
  _listInvitationEmailStatus?: {
    __typename: "ModelInvitationEmailStatusConnection"
    items: Array<{
      __typename: "InvitationEmailStatus"
      id: string
      email: string
      status: EmailStatus
      repicaDeliverId: string
      repicaKeyField: string
      repicaSentDate: string
      errorMessage?: string | null
      createdAt: string
      teamInvitationEmailStatusesId: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type _listTeamNoticeQueryVariables = {
  filter?: ModelTeamNoticeFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type _listTeamNoticeQuery = {
  _listTeamNotice?: {
    __typename: "ModelTeamNoticeConnection"
    items: Array<{
      __typename: "TeamNotice"
      id: string
      noticeType: string
      title: string
      readFlg: boolean
      url: string
      team?: {
        __typename: "Team"
        id: string
        name: string
        description: string
        requirementForMemberProfile?: string | null
        customQuestions?: Array<TeamCustomQuestion> | null
        showMemberListFlg: boolean
        invitationCode?: string | null
        migrationId?: number | null
        paidFunctionEnabled?: boolean | null
        showAdsFlg?: boolean | null
        createdAt: string
        updatedAt: string
        teamCategoryId?: string | null
        teamActivityId?: string | null
        teamPrefectureId?: string | null
        teamCityId?: string | null
      } | null
      createdAt: string
      createdYearMonth?: number | null
      createdDate?: string | null
      accountTeamNoticesId: string
      updatedAt: string
      teamMemberTeamNoticeId?: string | null
      teamNoticeTeamId?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetTeamMemberQueryVariables = {
  id: string
}

export type GetTeamMemberQuery = {
  getTeamMember?: {
    __typename: "TeamMember"
    id: string
    memberSeq: number
    nickname: string
    nicknameKana: string
    role: string
    showEmailFlg?: boolean | null
    phoneNumber?: string | null
    showPhoneNumberFlg?: boolean | null
    belongs?: string | null
    showBelongsFlg?: boolean | null
    grade?: number | null
    account?: {
      __typename: "Account"
      id: string
      email: string
      lastName: string
      firstName: string
      lastNameKana: string
      firstNameKana: string
      phoneNumber?: string | null
      gender?: Gender | null
      birthday?: string | null
      job?: {
        __typename: "JobMaster"
        id: string
        label: string
        type: JobType
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      school?: {
        __typename: "SchoolMaster"
        id: string
        label: string
        labelKana: string
        type: string
        code?: string | null
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      schoolDepartment?: {
        __typename: "SchoolDepartmentMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
        schoolMasterDepartmentsId?: string | null
      } | null
      schoolGraduationYear?: number | null
      prefecture?: {
        __typename: "PrefectureMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      city?: {
        __typename: "CityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
        prefectureMasterCitiesId?: string | null
      } | null
      questionAnswers?: {
        __typename: "ModelAccountQuestionAnswerConnection"
        nextToken?: string | null
      } | null
      termsAgreedAt?: string | null
      agreedTermsVersion?: string | null
      matchingTermsAgreedAt?: string | null
      agreedMatchingTermsVersion?: string | null
      signupStatus: SignupStatus
      accountDevices?: {
        __typename: "ModelAccountDeviceConnection"
        nextToken?: string | null
      } | null
      teamMembers?: {
        __typename: "ModelTeamMemberConnection"
        nextToken?: string | null
      } | null
      preTeamMembers?: {
        __typename: "ModelPreTeamMemberConnection"
        nextToken?: string | null
      } | null
      teamNotices?: {
        __typename: "ModelTeamNoticeConnection"
        nextToken?: string | null
      } | null
      haveOldRa9TeamNotJoin: boolean
      migrateAccountId?: number | null
      createdAt: string
      updatedAt: string
      accountJobId?: string | null
      accountSchoolId?: string | null
      accountSchoolDepartmentId?: string | null
      accountPrefectureId?: string | null
      accountCityId?: string | null
    } | null
    team?: {
      __typename: "Team"
      id: string
      name: string
      category?: {
        __typename: "TeamCategoryMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      activity?: {
        __typename: "TeamActivityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      prefecture?: {
        __typename: "PrefectureMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      city?: {
        __typename: "CityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
        prefectureMasterCitiesId?: string | null
      } | null
      description: string
      requirementForMemberProfile?: string | null
      customQuestions?: Array<TeamCustomQuestion> | null
      showMemberListFlg: boolean
      invitationCode?: string | null
      teamMembers?: {
        __typename: "ModelTeamMemberConnection"
        nextToken?: string | null
      } | null
      preTeamMembers?: {
        __typename: "ModelPreTeamMemberConnection"
        nextToken?: string | null
      } | null
      invitationEmailStatuses?: {
        __typename: "ModelInvitationEmailStatusConnection"
        nextToken?: string | null
      } | null
      groups?: {
        __typename: "ModelGroupConnection"
        nextToken?: string | null
      } | null
      matchingOffers?: {
        __typename: "ModelMatchingOfferConnection"
        nextToken?: string | null
      } | null
      matchingOfferDrafts?: {
        __typename: "ModelMatchingOfferDraftConnection"
        nextToken?: string | null
      } | null
      matchingApplications?: {
        __typename: "ModelMatchingApplicationConnection"
        nextToken?: string | null
      } | null
      blockingMatchingTeams?: {
        __typename: "ModelBlockingMatchingTeamConnection"
        nextToken?: string | null
      } | null
      paidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      blockingMatchingTeamsByBlockingTeamId?: {
        __typename: "ModelBlockingMatchingTeamConnection"
        nextToken?: string | null
      } | null
      migrationId?: number | null
      paidFunctionEnabled?: boolean | null
      showAdsFlg?: boolean | null
      createdAt: string
      updatedAt: string
      teamCategoryId?: string | null
      teamActivityId?: string | null
      teamPrefectureId?: string | null
      teamCityId?: string | null
    } | null
    teamIdGsi: string
    teamTeamMembersId?: string | null
    notificationSetting?: {
      __typename: "NotificationSetting"
      id: string
      mailReceivedNotice: {
        __typename: "MailReceivedNotice"
        push: boolean
        email: boolean
      }
      threadMessageReceivedNotice: {
        __typename: "ThreadMessageReceivedNotice"
        push: boolean
        email: boolean
      }
      joinRequestNotice: {
        __typename: "JoinRequestNotice"
        push: boolean
        email: boolean
      }
      teamMatchingNotice: {
        __typename: "TeamMatchingNotice"
        email: boolean
      }
      createdAt: string
      updatedAt: string
    } | null
    teamNotice?: {
      __typename: "ModelTeamNoticeConnection"
      items: Array<{
        __typename: "TeamNotice"
        id: string
        noticeType: string
        title: string
        readFlg: boolean
        url: string
        createdAt: string
        createdYearMonth?: number | null
        createdDate?: string | null
        accountTeamNoticesId: string
        updatedAt: string
        teamMemberTeamNoticeId?: string | null
        teamNoticeTeamId?: string | null
      } | null>
      nextToken?: string | null
    } | null
    groups?: {
      __typename: "ModelGroupMemberConnection"
      items: Array<{
        __typename: "GroupMember"
        id: string
        leaderFlg?: boolean | null
        teamMemberID?: string | null
        groupID?: string | null
        createdAt: string
        updatedAt: string
      } | null>
      nextToken?: string | null
    } | null
    teamMemberMails?: {
      __typename: "ModelTeamMemberMailConnection"
      items: Array<{
        __typename: "TeamMemberMail"
        id: string
        receivedFlg: boolean
        sentFlg: boolean
        readFlg: boolean
        readAt?: string | null
        favoriteFlgReceived: boolean
        favoriteFlgSent: boolean
        answerRequiredFlg: boolean
        responseDueDatetimeForFilter: string
        answeredFlg: boolean
        answeredAt?: string | null
        attendanceAnswer?: YesNoAnswer | null
        scheduleAnswers?: Array<YesNoAnswer> | null
        senderId: string
        mailType: string
        title: string
        body: string
        hasAttachment: boolean
        hasThread: boolean
        trashedFlgReceived: boolean
        deletedFlgReceived: boolean
        deletedFlgSent: boolean
        count: number
        teamMemberID?: string | null
        mailID?: string | null
        createdYearMonth?: number | null
        createdDate?: string | null
        createdAt: string
        updatedAt: string
      } | null>
      nextToken?: string | null
    } | null
    reservedMails?: {
      __typename: "ModelReservedMailConnection"
      items: Array<{
        __typename: "ReservedMail"
        id: string
        teamId: string
        mailType: string
        title: string
        body: string
        attachmentFileNames?: Array<string> | null
        startDate?: string | null
        startTime?: string | null
        endDate?: string | null
        endTime?: string | null
        shareManagerFlg?: number | null
        showAnswerFlg?: boolean | null
        responseDueDatetime?: string | null
        remindDatetime?: string | null
        sendScheduledDate: string
        sendScheduledAt: string
        createdAt: string
        updatedAt: string
        teamMemberReservedMailsId?: string | null
      } | null>
      nextToken?: string | null
    } | null
    drafts?: {
      __typename: "ModelDraftConnection"
      items: Array<{
        __typename: "Draft"
        id: string
        mailType: string
        title: string
        body: string
        attachmentFileNames?: Array<string> | null
        startDate?: string | null
        startTime?: string | null
        endDate?: string | null
        endTime?: string | null
        shareManagerFlg?: number | null
        showAnswerFlg?: boolean | null
        responseDueDatetime?: string | null
        remindDatetime?: string | null
        sendScheduledAt?: string | null
        createdAt: string
        updatedAt: string
        teamMemberDraftsId?: string | null
      } | null>
      nextToken?: string | null
    } | null
    templates?: {
      __typename: "ModelTemplateConnection"
      items: Array<{
        __typename: "Template"
        id: string
        mailType: string
        title: string
        body: string
        startDate?: string | null
        startTime?: string | null
        endDate?: string | null
        endTime?: string | null
        shareManagerFlg?: number | null
        showAnswerFlg?: boolean | null
        favoriteFlg?: boolean | null
        createdAt: string
        updatedAt: string
        teamMemberTemplatesId?: string | null
      } | null>
      nextToken?: string | null
    } | null
    migratedMails?: {
      __typename: "ModelMigratedMailConnection"
      items: Array<{
        __typename: "MigratedMail"
        id: string
        migrationId: number
        title: string
        body: string
        sender: string
        favoriteFlg: boolean
        shareManagerFlg: boolean
        sentDatetime: string
        createdAt: string
        updatedAt: string
        teamMemberMigratedMailsId?: string | null
      } | null>
      nextToken?: string | null
    } | null
    mailMigratedFlg?: boolean | null
    favoriteMatchingOffer?: {
      __typename: "ModelFavoriteMatchingOfferConnection"
      items: Array<{
        __typename: "FavoriteMatchingOffer"
        id: string
        createdAt: string
        updatedAt: string
        teamMemberFavoriteMatchingOfferId?: string | null
        favoriteMatchingOfferOfferId: string
      } | null>
      nextToken?: string | null
    } | null
    deleteFlg?: boolean | null
    accountTeamMembersId: string
    migrateTeamMemberId?: number | null
    orderHistories?: {
      __typename: "ModelOrderHistoryConnection"
      items: Array<{
        __typename: "OrderHistory"
        id: string
        type: string
        orderId: string
        teamMemberId?: string | null
        adminId?: string | null
        content: string
        createdAt: string
        updatedAt: string
      } | null>
      nextToken?: string | null
    } | null
    paidVersionOrders?: {
      __typename: "ModelPaidVersionOrderConnection"
      items: Array<{
        __typename: "PaidVersionOrder"
        id: string
        orderNumber: string
        originalOrderId?: string | null
        orderStatus: string
        jobId?: string | null
        masterCode?: string | null
        zacStatus?: string | null
        supportedDate?: string | null
        address?: string | null
        orderType: string
        companyName?: string | null
        companyNameKana?: string | null
        corporateRepresentativeName?: string | null
        corporateRepresentativeNameKana?: string | null
        applicantPhone?: string | null
        applicantMail?: string | null
        applicantName?: string | null
        applicantPostcode?: string | null
        applicantPrefecture?: string | null
        applicantAddress?: string | null
        applicantBuildingName?: string | null
        applicantRemark?: string | null
        antiSocialCheck: string
        teamId?: string | null
        migrateTeamId?: number | null
        teamName?: string | null
        memberCount?: number | null
        applicantId?: string | null
        transferApplicantId?: string | null
        planId: string
        startDate: string
        endDate: string
        paymentStatus: string
        paymentDate?: string | null
        paymentDeadline?: string | null
        invoiceIssuedDate?: string | null
        paymentType: string
        paymentMethod: string
        invoiceAddressee?: string | null
        invoiceAddresseeKana?: string | null
        amount: number
        taxRate: number
        tax: number
        subTotal: number
        remark?: string | null
        adminId?: string | null
        createdAt: string
        updatedAt: string
      } | null>
      nextToken?: string | null
    } | null
    transferPaidVersionOrders?: {
      __typename: "ModelPaidVersionOrderConnection"
      items: Array<{
        __typename: "PaidVersionOrder"
        id: string
        orderNumber: string
        originalOrderId?: string | null
        orderStatus: string
        jobId?: string | null
        masterCode?: string | null
        zacStatus?: string | null
        supportedDate?: string | null
        address?: string | null
        orderType: string
        companyName?: string | null
        companyNameKana?: string | null
        corporateRepresentativeName?: string | null
        corporateRepresentativeNameKana?: string | null
        applicantPhone?: string | null
        applicantMail?: string | null
        applicantName?: string | null
        applicantPostcode?: string | null
        applicantPrefecture?: string | null
        applicantAddress?: string | null
        applicantBuildingName?: string | null
        applicantRemark?: string | null
        antiSocialCheck: string
        teamId?: string | null
        migrateTeamId?: number | null
        teamName?: string | null
        memberCount?: number | null
        applicantId?: string | null
        transferApplicantId?: string | null
        planId: string
        startDate: string
        endDate: string
        paymentStatus: string
        paymentDate?: string | null
        paymentDeadline?: string | null
        invoiceIssuedDate?: string | null
        paymentType: string
        paymentMethod: string
        invoiceAddressee?: string | null
        invoiceAddresseeKana?: string | null
        amount: number
        taxRate: number
        tax: number
        subTotal: number
        remark?: string | null
        adminId?: string | null
        createdAt: string
        updatedAt: string
      } | null>
      nextToken?: string | null
    } | null
    createdAt: string
    updatedAt: string
    teamMemberNotificationSettingId?: string | null
  } | null
}

export type GetGroupQueryVariables = {
  id: string
}

export type GetGroupQuery = {
  getGroup?: {
    __typename: "Group"
    id: string
    name?: string | null
    groupMembers?: {
      __typename: "ModelGroupMemberConnection"
      items: Array<{
        __typename: "GroupMember"
        id: string
        leaderFlg?: boolean | null
        teamMemberID?: string | null
        groupID?: string | null
        createdAt: string
        updatedAt: string
      } | null>
      nextToken?: string | null
    } | null
    migrationId?: number | null
    createdAt: string
    updatedAt: string
    teamGroupsId?: string | null
  } | null
}

export type GetMailQueryVariables = {
  id: string
}

export type GetMailQuery = {
  getMail?: {
    __typename: "Mail"
    id: string
    teamId: string
    mailType: string
    title: string
    sender?: {
      __typename: "TeamMember"
      id: string
      memberSeq: number
      nickname: string
      nicknameKana: string
      role: string
      showEmailFlg?: boolean | null
      phoneNumber?: string | null
      showPhoneNumberFlg?: boolean | null
      belongs?: string | null
      showBelongsFlg?: boolean | null
      grade?: number | null
      account?: {
        __typename: "Account"
        id: string
        email: string
        lastName: string
        firstName: string
        lastNameKana: string
        firstNameKana: string
        phoneNumber?: string | null
        gender?: Gender | null
        birthday?: string | null
        schoolGraduationYear?: number | null
        termsAgreedAt?: string | null
        agreedTermsVersion?: string | null
        matchingTermsAgreedAt?: string | null
        agreedMatchingTermsVersion?: string | null
        signupStatus: SignupStatus
        haveOldRa9TeamNotJoin: boolean
        migrateAccountId?: number | null
        createdAt: string
        updatedAt: string
        accountJobId?: string | null
        accountSchoolId?: string | null
        accountSchoolDepartmentId?: string | null
        accountPrefectureId?: string | null
        accountCityId?: string | null
      } | null
      team?: {
        __typename: "Team"
        id: string
        name: string
        description: string
        requirementForMemberProfile?: string | null
        customQuestions?: Array<TeamCustomQuestion> | null
        showMemberListFlg: boolean
        invitationCode?: string | null
        migrationId?: number | null
        paidFunctionEnabled?: boolean | null
        showAdsFlg?: boolean | null
        createdAt: string
        updatedAt: string
        teamCategoryId?: string | null
        teamActivityId?: string | null
        teamPrefectureId?: string | null
        teamCityId?: string | null
      } | null
      teamIdGsi: string
      teamTeamMembersId?: string | null
      notificationSetting?: {
        __typename: "NotificationSetting"
        id: string
        createdAt: string
        updatedAt: string
      } | null
      teamNotice?: {
        __typename: "ModelTeamNoticeConnection"
        nextToken?: string | null
      } | null
      groups?: {
        __typename: "ModelGroupMemberConnection"
        nextToken?: string | null
      } | null
      teamMemberMails?: {
        __typename: "ModelTeamMemberMailConnection"
        nextToken?: string | null
      } | null
      reservedMails?: {
        __typename: "ModelReservedMailConnection"
        nextToken?: string | null
      } | null
      drafts?: {
        __typename: "ModelDraftConnection"
        nextToken?: string | null
      } | null
      templates?: {
        __typename: "ModelTemplateConnection"
        nextToken?: string | null
      } | null
      migratedMails?: {
        __typename: "ModelMigratedMailConnection"
        nextToken?: string | null
      } | null
      mailMigratedFlg?: boolean | null
      favoriteMatchingOffer?: {
        __typename: "ModelFavoriteMatchingOfferConnection"
        nextToken?: string | null
      } | null
      deleteFlg?: boolean | null
      accountTeamMembersId: string
      migrateTeamMemberId?: number | null
      orderHistories?: {
        __typename: "ModelOrderHistoryConnection"
        nextToken?: string | null
      } | null
      paidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      transferPaidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
      teamMemberNotificationSettingId?: string | null
    } | null
    body: string
    sendTo: {
      __typename: "SendTo"
      allMembersFlg?: boolean | null
      managersFlg?: boolean | null
      groupIds?: Array<string> | null
      memberIds?: Array<string> | null
    }
    attachmentFileNames?: Array<string> | null
    startDate?: string | null
    startTime?: string | null
    endDate?: string | null
    endTime?: string | null
    questionnaires?: Array<{
      __typename: "Questionnaires"
      answerSelectType: AnswerSelectType
      question: string
      choices: Array<string>
    }> | null
    candidateDatetimes?: Array<{
      __typename: "CandidateDatetimes"
      startDate: string
      startTime?: string | null
      endDate?: string | null
      endTime?: string | null
    }> | null
    shareManagerFlg?: number | null
    showAnswerFlg?: boolean | null
    responseDueDatetime?: string | null
    remindDate: string
    remindDatetime?: string | null
    remindExecDatetime?: string | null
    sendScheduledAt?: string | null
    sentAt: string
    teamMemberMails?: {
      __typename: "ModelTeamMemberMailConnection"
      items: Array<{
        __typename: "TeamMemberMail"
        id: string
        receivedFlg: boolean
        sentFlg: boolean
        readFlg: boolean
        readAt?: string | null
        favoriteFlgReceived: boolean
        favoriteFlgSent: boolean
        answerRequiredFlg: boolean
        responseDueDatetimeForFilter: string
        answeredFlg: boolean
        answeredAt?: string | null
        attendanceAnswer?: YesNoAnswer | null
        scheduleAnswers?: Array<YesNoAnswer> | null
        senderId: string
        mailType: string
        title: string
        body: string
        hasAttachment: boolean
        hasThread: boolean
        trashedFlgReceived: boolean
        deletedFlgReceived: boolean
        deletedFlgSent: boolean
        count: number
        teamMemberID?: string | null
        mailID?: string | null
        createdYearMonth?: number | null
        createdDate?: string | null
        createdAt: string
        updatedAt: string
      } | null>
      nextToken?: string | null
    } | null
    threads?: {
      __typename: "ModelThreadConnection"
      items: Array<{
        __typename: "Thread"
        id: string
        threadMailRecipientId?: string | null
        mailThreadsId: string
        createdAt: string
        updatedAt: string
        threadMailSenderId?: string | null
      } | null>
      nextToken?: string | null
    } | null
    createdAt: string
    updatedAt: string
    mailSenderId?: string | null
  } | null
}

export type GetTeamMemberMailQueryVariables = {
  id: string
}

export type GetTeamMemberMailQuery = {
  getTeamMemberMail?: {
    __typename: "TeamMemberMail"
    id: string
    receivedFlg: boolean
    sentFlg: boolean
    readFlg: boolean
    readAt?: string | null
    favoriteFlgReceived: boolean
    favoriteFlgSent: boolean
    answerRequiredFlg: boolean
    responseDueDatetimeForFilter: string
    answeredFlg: boolean
    answeredAt?: string | null
    attendanceAnswer?: YesNoAnswer | null
    questionnaireAnswers?: Array<{
      __typename: "QuestionnaireAnswers"
      answer: Array<number>
    }> | null
    scheduleAnswers?: Array<YesNoAnswer> | null
    senderId: string
    mailType: string
    title: string
    body: string
    hasAttachment: boolean
    hasThread: boolean
    trashedFlgReceived: boolean
    deletedFlgReceived: boolean
    deletedFlgSent: boolean
    count: number
    teamMemberID?: string | null
    mailID?: string | null
    teamMember?: {
      __typename: "TeamMember"
      id: string
      memberSeq: number
      nickname: string
      nicknameKana: string
      role: string
      showEmailFlg?: boolean | null
      phoneNumber?: string | null
      showPhoneNumberFlg?: boolean | null
      belongs?: string | null
      showBelongsFlg?: boolean | null
      grade?: number | null
      account?: {
        __typename: "Account"
        id: string
        email: string
        lastName: string
        firstName: string
        lastNameKana: string
        firstNameKana: string
        phoneNumber?: string | null
        gender?: Gender | null
        birthday?: string | null
        schoolGraduationYear?: number | null
        termsAgreedAt?: string | null
        agreedTermsVersion?: string | null
        matchingTermsAgreedAt?: string | null
        agreedMatchingTermsVersion?: string | null
        signupStatus: SignupStatus
        haveOldRa9TeamNotJoin: boolean
        migrateAccountId?: number | null
        createdAt: string
        updatedAt: string
        accountJobId?: string | null
        accountSchoolId?: string | null
        accountSchoolDepartmentId?: string | null
        accountPrefectureId?: string | null
        accountCityId?: string | null
      } | null
      team?: {
        __typename: "Team"
        id: string
        name: string
        description: string
        requirementForMemberProfile?: string | null
        customQuestions?: Array<TeamCustomQuestion> | null
        showMemberListFlg: boolean
        invitationCode?: string | null
        migrationId?: number | null
        paidFunctionEnabled?: boolean | null
        showAdsFlg?: boolean | null
        createdAt: string
        updatedAt: string
        teamCategoryId?: string | null
        teamActivityId?: string | null
        teamPrefectureId?: string | null
        teamCityId?: string | null
      } | null
      teamIdGsi: string
      teamTeamMembersId?: string | null
      notificationSetting?: {
        __typename: "NotificationSetting"
        id: string
        createdAt: string
        updatedAt: string
      } | null
      teamNotice?: {
        __typename: "ModelTeamNoticeConnection"
        nextToken?: string | null
      } | null
      groups?: {
        __typename: "ModelGroupMemberConnection"
        nextToken?: string | null
      } | null
      teamMemberMails?: {
        __typename: "ModelTeamMemberMailConnection"
        nextToken?: string | null
      } | null
      reservedMails?: {
        __typename: "ModelReservedMailConnection"
        nextToken?: string | null
      } | null
      drafts?: {
        __typename: "ModelDraftConnection"
        nextToken?: string | null
      } | null
      templates?: {
        __typename: "ModelTemplateConnection"
        nextToken?: string | null
      } | null
      migratedMails?: {
        __typename: "ModelMigratedMailConnection"
        nextToken?: string | null
      } | null
      mailMigratedFlg?: boolean | null
      favoriteMatchingOffer?: {
        __typename: "ModelFavoriteMatchingOfferConnection"
        nextToken?: string | null
      } | null
      deleteFlg?: boolean | null
      accountTeamMembersId: string
      migrateTeamMemberId?: number | null
      orderHistories?: {
        __typename: "ModelOrderHistoryConnection"
        nextToken?: string | null
      } | null
      paidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      transferPaidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
      teamMemberNotificationSettingId?: string | null
    } | null
    mail?: {
      __typename: "Mail"
      id: string
      teamId: string
      mailType: string
      title: string
      sender?: {
        __typename: "TeamMember"
        id: string
        memberSeq: number
        nickname: string
        nicknameKana: string
        role: string
        showEmailFlg?: boolean | null
        phoneNumber?: string | null
        showPhoneNumberFlg?: boolean | null
        belongs?: string | null
        showBelongsFlg?: boolean | null
        grade?: number | null
        teamIdGsi: string
        teamTeamMembersId?: string | null
        mailMigratedFlg?: boolean | null
        deleteFlg?: boolean | null
        accountTeamMembersId: string
        migrateTeamMemberId?: number | null
        createdAt: string
        updatedAt: string
        teamMemberNotificationSettingId?: string | null
      } | null
      body: string
      sendTo: {
        __typename: "SendTo"
        allMembersFlg?: boolean | null
        managersFlg?: boolean | null
        groupIds?: Array<string> | null
        memberIds?: Array<string> | null
      }
      attachmentFileNames?: Array<string> | null
      startDate?: string | null
      startTime?: string | null
      endDate?: string | null
      endTime?: string | null
      questionnaires?: Array<{
        __typename: "Questionnaires"
        answerSelectType: AnswerSelectType
        question: string
        choices: Array<string>
      }> | null
      candidateDatetimes?: Array<{
        __typename: "CandidateDatetimes"
        startDate: string
        startTime?: string | null
        endDate?: string | null
        endTime?: string | null
      }> | null
      shareManagerFlg?: number | null
      showAnswerFlg?: boolean | null
      responseDueDatetime?: string | null
      remindDate: string
      remindDatetime?: string | null
      remindExecDatetime?: string | null
      sendScheduledAt?: string | null
      sentAt: string
      teamMemberMails?: {
        __typename: "ModelTeamMemberMailConnection"
        nextToken?: string | null
      } | null
      threads?: {
        __typename: "ModelThreadConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
      mailSenderId?: string | null
    } | null
    createdYearMonth?: number | null
    createdDate?: string | null
    createdAt: string
    updatedAt: string
  } | null
}

export type _listTeamMemberMailQueryVariables = {
  filter?: ModelTeamMemberMailFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type _listTeamMemberMailQuery = {
  _listTeamMemberMail?: {
    __typename: "ModelTeamMemberMailConnection"
    items: Array<{
      __typename: "TeamMemberMail"
      id: string
      receivedFlg: boolean
      sentFlg: boolean
      readFlg: boolean
      readAt?: string | null
      favoriteFlgReceived: boolean
      favoriteFlgSent: boolean
      answerRequiredFlg: boolean
      responseDueDatetimeForFilter: string
      answeredFlg: boolean
      answeredAt?: string | null
      attendanceAnswer?: YesNoAnswer | null
      questionnaireAnswers?: Array<{
        __typename: "QuestionnaireAnswers"
        answer: Array<number>
      }> | null
      scheduleAnswers?: Array<YesNoAnswer> | null
      senderId: string
      mailType: string
      title: string
      body: string
      hasAttachment: boolean
      hasThread: boolean
      trashedFlgReceived: boolean
      deletedFlgReceived: boolean
      deletedFlgSent: boolean
      count: number
      teamMemberID?: string | null
      mailID?: string | null
      teamMember?: {
        __typename: "TeamMember"
        id: string
        memberSeq: number
        nickname: string
        nicknameKana: string
        role: string
        showEmailFlg?: boolean | null
        phoneNumber?: string | null
        showPhoneNumberFlg?: boolean | null
        belongs?: string | null
        showBelongsFlg?: boolean | null
        grade?: number | null
        teamIdGsi: string
        teamTeamMembersId?: string | null
        mailMigratedFlg?: boolean | null
        deleteFlg?: boolean | null
        accountTeamMembersId: string
        migrateTeamMemberId?: number | null
        createdAt: string
        updatedAt: string
        teamMemberNotificationSettingId?: string | null
      } | null
      mail?: {
        __typename: "Mail"
        id: string
        teamId: string
        mailType: string
        title: string
        body: string
        attachmentFileNames?: Array<string> | null
        startDate?: string | null
        startTime?: string | null
        endDate?: string | null
        endTime?: string | null
        shareManagerFlg?: number | null
        showAnswerFlg?: boolean | null
        responseDueDatetime?: string | null
        remindDate: string
        remindDatetime?: string | null
        remindExecDatetime?: string | null
        sendScheduledAt?: string | null
        sentAt: string
        createdAt: string
        updatedAt: string
        mailSenderId?: string | null
      } | null
      createdYearMonth?: number | null
      createdDate?: string | null
      createdAt: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type GetReservedMailQueryVariables = {
  id: string
}

export type GetReservedMailQuery = {
  getReservedMail?: {
    __typename: "ReservedMail"
    id: string
    teamId: string
    mailType: string
    title: string
    teamMember?: {
      __typename: "TeamMember"
      id: string
      memberSeq: number
      nickname: string
      nicknameKana: string
      role: string
      showEmailFlg?: boolean | null
      phoneNumber?: string | null
      showPhoneNumberFlg?: boolean | null
      belongs?: string | null
      showBelongsFlg?: boolean | null
      grade?: number | null
      account?: {
        __typename: "Account"
        id: string
        email: string
        lastName: string
        firstName: string
        lastNameKana: string
        firstNameKana: string
        phoneNumber?: string | null
        gender?: Gender | null
        birthday?: string | null
        schoolGraduationYear?: number | null
        termsAgreedAt?: string | null
        agreedTermsVersion?: string | null
        matchingTermsAgreedAt?: string | null
        agreedMatchingTermsVersion?: string | null
        signupStatus: SignupStatus
        haveOldRa9TeamNotJoin: boolean
        migrateAccountId?: number | null
        createdAt: string
        updatedAt: string
        accountJobId?: string | null
        accountSchoolId?: string | null
        accountSchoolDepartmentId?: string | null
        accountPrefectureId?: string | null
        accountCityId?: string | null
      } | null
      team?: {
        __typename: "Team"
        id: string
        name: string
        description: string
        requirementForMemberProfile?: string | null
        customQuestions?: Array<TeamCustomQuestion> | null
        showMemberListFlg: boolean
        invitationCode?: string | null
        migrationId?: number | null
        paidFunctionEnabled?: boolean | null
        showAdsFlg?: boolean | null
        createdAt: string
        updatedAt: string
        teamCategoryId?: string | null
        teamActivityId?: string | null
        teamPrefectureId?: string | null
        teamCityId?: string | null
      } | null
      teamIdGsi: string
      teamTeamMembersId?: string | null
      notificationSetting?: {
        __typename: "NotificationSetting"
        id: string
        createdAt: string
        updatedAt: string
      } | null
      teamNotice?: {
        __typename: "ModelTeamNoticeConnection"
        nextToken?: string | null
      } | null
      groups?: {
        __typename: "ModelGroupMemberConnection"
        nextToken?: string | null
      } | null
      teamMemberMails?: {
        __typename: "ModelTeamMemberMailConnection"
        nextToken?: string | null
      } | null
      reservedMails?: {
        __typename: "ModelReservedMailConnection"
        nextToken?: string | null
      } | null
      drafts?: {
        __typename: "ModelDraftConnection"
        nextToken?: string | null
      } | null
      templates?: {
        __typename: "ModelTemplateConnection"
        nextToken?: string | null
      } | null
      migratedMails?: {
        __typename: "ModelMigratedMailConnection"
        nextToken?: string | null
      } | null
      mailMigratedFlg?: boolean | null
      favoriteMatchingOffer?: {
        __typename: "ModelFavoriteMatchingOfferConnection"
        nextToken?: string | null
      } | null
      deleteFlg?: boolean | null
      accountTeamMembersId: string
      migrateTeamMemberId?: number | null
      orderHistories?: {
        __typename: "ModelOrderHistoryConnection"
        nextToken?: string | null
      } | null
      paidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      transferPaidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
      teamMemberNotificationSettingId?: string | null
    } | null
    body: string
    sendTo: {
      __typename: "SendTo"
      allMembersFlg?: boolean | null
      managersFlg?: boolean | null
      groupIds?: Array<string> | null
      memberIds?: Array<string> | null
    }
    attachmentFileNames?: Array<string> | null
    startDate?: string | null
    startTime?: string | null
    endDate?: string | null
    endTime?: string | null
    questionnaires?: Array<{
      __typename: "Questionnaires"
      answerSelectType: AnswerSelectType
      question: string
      choices: Array<string>
    }> | null
    candidateDatetimes?: Array<{
      __typename: "CandidateDatetimes"
      startDate: string
      startTime?: string | null
      endDate?: string | null
      endTime?: string | null
    }> | null
    shareManagerFlg?: number | null
    showAnswerFlg?: boolean | null
    responseDueDatetime?: string | null
    remindDatetime?: string | null
    sendScheduledDate: string
    sendScheduledAt: string
    createdAt: string
    updatedAt: string
    teamMemberReservedMailsId?: string | null
  } | null
}

export type GetDraftQueryVariables = {
  id: string
}

export type GetDraftQuery = {
  getDraft?: {
    __typename: "Draft"
    id: string
    mailType: string
    title: string
    teamMember?: {
      __typename: "TeamMember"
      id: string
      memberSeq: number
      nickname: string
      nicknameKana: string
      role: string
      showEmailFlg?: boolean | null
      phoneNumber?: string | null
      showPhoneNumberFlg?: boolean | null
      belongs?: string | null
      showBelongsFlg?: boolean | null
      grade?: number | null
      account?: {
        __typename: "Account"
        id: string
        email: string
        lastName: string
        firstName: string
        lastNameKana: string
        firstNameKana: string
        phoneNumber?: string | null
        gender?: Gender | null
        birthday?: string | null
        schoolGraduationYear?: number | null
        termsAgreedAt?: string | null
        agreedTermsVersion?: string | null
        matchingTermsAgreedAt?: string | null
        agreedMatchingTermsVersion?: string | null
        signupStatus: SignupStatus
        haveOldRa9TeamNotJoin: boolean
        migrateAccountId?: number | null
        createdAt: string
        updatedAt: string
        accountJobId?: string | null
        accountSchoolId?: string | null
        accountSchoolDepartmentId?: string | null
        accountPrefectureId?: string | null
        accountCityId?: string | null
      } | null
      team?: {
        __typename: "Team"
        id: string
        name: string
        description: string
        requirementForMemberProfile?: string | null
        customQuestions?: Array<TeamCustomQuestion> | null
        showMemberListFlg: boolean
        invitationCode?: string | null
        migrationId?: number | null
        paidFunctionEnabled?: boolean | null
        showAdsFlg?: boolean | null
        createdAt: string
        updatedAt: string
        teamCategoryId?: string | null
        teamActivityId?: string | null
        teamPrefectureId?: string | null
        teamCityId?: string | null
      } | null
      teamIdGsi: string
      teamTeamMembersId?: string | null
      notificationSetting?: {
        __typename: "NotificationSetting"
        id: string
        createdAt: string
        updatedAt: string
      } | null
      teamNotice?: {
        __typename: "ModelTeamNoticeConnection"
        nextToken?: string | null
      } | null
      groups?: {
        __typename: "ModelGroupMemberConnection"
        nextToken?: string | null
      } | null
      teamMemberMails?: {
        __typename: "ModelTeamMemberMailConnection"
        nextToken?: string | null
      } | null
      reservedMails?: {
        __typename: "ModelReservedMailConnection"
        nextToken?: string | null
      } | null
      drafts?: {
        __typename: "ModelDraftConnection"
        nextToken?: string | null
      } | null
      templates?: {
        __typename: "ModelTemplateConnection"
        nextToken?: string | null
      } | null
      migratedMails?: {
        __typename: "ModelMigratedMailConnection"
        nextToken?: string | null
      } | null
      mailMigratedFlg?: boolean | null
      favoriteMatchingOffer?: {
        __typename: "ModelFavoriteMatchingOfferConnection"
        nextToken?: string | null
      } | null
      deleteFlg?: boolean | null
      accountTeamMembersId: string
      migrateTeamMemberId?: number | null
      orderHistories?: {
        __typename: "ModelOrderHistoryConnection"
        nextToken?: string | null
      } | null
      paidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      transferPaidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
      teamMemberNotificationSettingId?: string | null
    } | null
    body: string
    sendTo?: {
      __typename: "SendTo"
      allMembersFlg?: boolean | null
      managersFlg?: boolean | null
      groupIds?: Array<string> | null
      memberIds?: Array<string> | null
    } | null
    attachmentFileNames?: Array<string> | null
    startDate?: string | null
    startTime?: string | null
    endDate?: string | null
    endTime?: string | null
    questionnaires?: Array<{
      __typename: "Questionnaires"
      answerSelectType: AnswerSelectType
      question: string
      choices: Array<string>
    }> | null
    candidateDatetimes?: Array<{
      __typename: "CandidateDatetimes"
      startDate: string
      startTime?: string | null
      endDate?: string | null
      endTime?: string | null
    }> | null
    shareManagerFlg?: number | null
    showAnswerFlg?: boolean | null
    responseDueDatetime?: string | null
    remindDatetime?: string | null
    sendScheduledAt?: string | null
    createdAt: string
    updatedAt: string
    teamMemberDraftsId?: string | null
  } | null
}

export type GetTemplateQueryVariables = {
  id: string
}

export type GetTemplateQuery = {
  getTemplate?: {
    __typename: "Template"
    id: string
    mailType: string
    title: string
    teamMember: {
      __typename: "TeamMember"
      id: string
      memberSeq: number
      nickname: string
      nicknameKana: string
      role: string
      showEmailFlg?: boolean | null
      phoneNumber?: string | null
      showPhoneNumberFlg?: boolean | null
      belongs?: string | null
      showBelongsFlg?: boolean | null
      grade?: number | null
      account?: {
        __typename: "Account"
        id: string
        email: string
        lastName: string
        firstName: string
        lastNameKana: string
        firstNameKana: string
        phoneNumber?: string | null
        gender?: Gender | null
        birthday?: string | null
        schoolGraduationYear?: number | null
        termsAgreedAt?: string | null
        agreedTermsVersion?: string | null
        matchingTermsAgreedAt?: string | null
        agreedMatchingTermsVersion?: string | null
        signupStatus: SignupStatus
        haveOldRa9TeamNotJoin: boolean
        migrateAccountId?: number | null
        createdAt: string
        updatedAt: string
        accountJobId?: string | null
        accountSchoolId?: string | null
        accountSchoolDepartmentId?: string | null
        accountPrefectureId?: string | null
        accountCityId?: string | null
      } | null
      team?: {
        __typename: "Team"
        id: string
        name: string
        description: string
        requirementForMemberProfile?: string | null
        customQuestions?: Array<TeamCustomQuestion> | null
        showMemberListFlg: boolean
        invitationCode?: string | null
        migrationId?: number | null
        paidFunctionEnabled?: boolean | null
        showAdsFlg?: boolean | null
        createdAt: string
        updatedAt: string
        teamCategoryId?: string | null
        teamActivityId?: string | null
        teamPrefectureId?: string | null
        teamCityId?: string | null
      } | null
      teamIdGsi: string
      teamTeamMembersId?: string | null
      notificationSetting?: {
        __typename: "NotificationSetting"
        id: string
        createdAt: string
        updatedAt: string
      } | null
      teamNotice?: {
        __typename: "ModelTeamNoticeConnection"
        nextToken?: string | null
      } | null
      groups?: {
        __typename: "ModelGroupMemberConnection"
        nextToken?: string | null
      } | null
      teamMemberMails?: {
        __typename: "ModelTeamMemberMailConnection"
        nextToken?: string | null
      } | null
      reservedMails?: {
        __typename: "ModelReservedMailConnection"
        nextToken?: string | null
      } | null
      drafts?: {
        __typename: "ModelDraftConnection"
        nextToken?: string | null
      } | null
      templates?: {
        __typename: "ModelTemplateConnection"
        nextToken?: string | null
      } | null
      migratedMails?: {
        __typename: "ModelMigratedMailConnection"
        nextToken?: string | null
      } | null
      mailMigratedFlg?: boolean | null
      favoriteMatchingOffer?: {
        __typename: "ModelFavoriteMatchingOfferConnection"
        nextToken?: string | null
      } | null
      deleteFlg?: boolean | null
      accountTeamMembersId: string
      migrateTeamMemberId?: number | null
      orderHistories?: {
        __typename: "ModelOrderHistoryConnection"
        nextToken?: string | null
      } | null
      paidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      transferPaidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
      teamMemberNotificationSettingId?: string | null
    }
    body: string
    sendTo: {
      __typename: "SendTo"
      allMembersFlg?: boolean | null
      managersFlg?: boolean | null
      groupIds?: Array<string> | null
      memberIds?: Array<string> | null
    }
    startDate?: string | null
    startTime?: string | null
    endDate?: string | null
    endTime?: string | null
    questionnaires?: Array<{
      __typename: "Questionnaires"
      answerSelectType: AnswerSelectType
      question: string
      choices: Array<string>
    }> | null
    candidateDatetimes?: Array<{
      __typename: "CandidateDatetimes"
      startDate: string
      startTime?: string | null
      endDate?: string | null
      endTime?: string | null
    }> | null
    shareManagerFlg?: number | null
    showAnswerFlg?: boolean | null
    favoriteFlg?: boolean | null
    createdAt: string
    updatedAt: string
    teamMemberTemplatesId?: string | null
  } | null
}

export type GetThreadQueryVariables = {
  id: string
}

export type GetThreadQuery = {
  getThread?: {
    __typename: "Thread"
    id: string
    mailSender?: {
      __typename: "TeamMember"
      id: string
      memberSeq: number
      nickname: string
      nicknameKana: string
      role: string
      showEmailFlg?: boolean | null
      phoneNumber?: string | null
      showPhoneNumberFlg?: boolean | null
      belongs?: string | null
      showBelongsFlg?: boolean | null
      grade?: number | null
      account?: {
        __typename: "Account"
        id: string
        email: string
        lastName: string
        firstName: string
        lastNameKana: string
        firstNameKana: string
        phoneNumber?: string | null
        gender?: Gender | null
        birthday?: string | null
        schoolGraduationYear?: number | null
        termsAgreedAt?: string | null
        agreedTermsVersion?: string | null
        matchingTermsAgreedAt?: string | null
        agreedMatchingTermsVersion?: string | null
        signupStatus: SignupStatus
        haveOldRa9TeamNotJoin: boolean
        migrateAccountId?: number | null
        createdAt: string
        updatedAt: string
        accountJobId?: string | null
        accountSchoolId?: string | null
        accountSchoolDepartmentId?: string | null
        accountPrefectureId?: string | null
        accountCityId?: string | null
      } | null
      team?: {
        __typename: "Team"
        id: string
        name: string
        description: string
        requirementForMemberProfile?: string | null
        customQuestions?: Array<TeamCustomQuestion> | null
        showMemberListFlg: boolean
        invitationCode?: string | null
        migrationId?: number | null
        paidFunctionEnabled?: boolean | null
        showAdsFlg?: boolean | null
        createdAt: string
        updatedAt: string
        teamCategoryId?: string | null
        teamActivityId?: string | null
        teamPrefectureId?: string | null
        teamCityId?: string | null
      } | null
      teamIdGsi: string
      teamTeamMembersId?: string | null
      notificationSetting?: {
        __typename: "NotificationSetting"
        id: string
        createdAt: string
        updatedAt: string
      } | null
      teamNotice?: {
        __typename: "ModelTeamNoticeConnection"
        nextToken?: string | null
      } | null
      groups?: {
        __typename: "ModelGroupMemberConnection"
        nextToken?: string | null
      } | null
      teamMemberMails?: {
        __typename: "ModelTeamMemberMailConnection"
        nextToken?: string | null
      } | null
      reservedMails?: {
        __typename: "ModelReservedMailConnection"
        nextToken?: string | null
      } | null
      drafts?: {
        __typename: "ModelDraftConnection"
        nextToken?: string | null
      } | null
      templates?: {
        __typename: "ModelTemplateConnection"
        nextToken?: string | null
      } | null
      migratedMails?: {
        __typename: "ModelMigratedMailConnection"
        nextToken?: string | null
      } | null
      mailMigratedFlg?: boolean | null
      favoriteMatchingOffer?: {
        __typename: "ModelFavoriteMatchingOfferConnection"
        nextToken?: string | null
      } | null
      deleteFlg?: boolean | null
      accountTeamMembersId: string
      migrateTeamMemberId?: number | null
      orderHistories?: {
        __typename: "ModelOrderHistoryConnection"
        nextToken?: string | null
      } | null
      paidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      transferPaidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
      teamMemberNotificationSettingId?: string | null
    } | null
    mailRecipient?: {
      __typename: "TeamMember"
      id: string
      memberSeq: number
      nickname: string
      nicknameKana: string
      role: string
      showEmailFlg?: boolean | null
      phoneNumber?: string | null
      showPhoneNumberFlg?: boolean | null
      belongs?: string | null
      showBelongsFlg?: boolean | null
      grade?: number | null
      account?: {
        __typename: "Account"
        id: string
        email: string
        lastName: string
        firstName: string
        lastNameKana: string
        firstNameKana: string
        phoneNumber?: string | null
        gender?: Gender | null
        birthday?: string | null
        schoolGraduationYear?: number | null
        termsAgreedAt?: string | null
        agreedTermsVersion?: string | null
        matchingTermsAgreedAt?: string | null
        agreedMatchingTermsVersion?: string | null
        signupStatus: SignupStatus
        haveOldRa9TeamNotJoin: boolean
        migrateAccountId?: number | null
        createdAt: string
        updatedAt: string
        accountJobId?: string | null
        accountSchoolId?: string | null
        accountSchoolDepartmentId?: string | null
        accountPrefectureId?: string | null
        accountCityId?: string | null
      } | null
      team?: {
        __typename: "Team"
        id: string
        name: string
        description: string
        requirementForMemberProfile?: string | null
        customQuestions?: Array<TeamCustomQuestion> | null
        showMemberListFlg: boolean
        invitationCode?: string | null
        migrationId?: number | null
        paidFunctionEnabled?: boolean | null
        showAdsFlg?: boolean | null
        createdAt: string
        updatedAt: string
        teamCategoryId?: string | null
        teamActivityId?: string | null
        teamPrefectureId?: string | null
        teamCityId?: string | null
      } | null
      teamIdGsi: string
      teamTeamMembersId?: string | null
      notificationSetting?: {
        __typename: "NotificationSetting"
        id: string
        createdAt: string
        updatedAt: string
      } | null
      teamNotice?: {
        __typename: "ModelTeamNoticeConnection"
        nextToken?: string | null
      } | null
      groups?: {
        __typename: "ModelGroupMemberConnection"
        nextToken?: string | null
      } | null
      teamMemberMails?: {
        __typename: "ModelTeamMemberMailConnection"
        nextToken?: string | null
      } | null
      reservedMails?: {
        __typename: "ModelReservedMailConnection"
        nextToken?: string | null
      } | null
      drafts?: {
        __typename: "ModelDraftConnection"
        nextToken?: string | null
      } | null
      templates?: {
        __typename: "ModelTemplateConnection"
        nextToken?: string | null
      } | null
      migratedMails?: {
        __typename: "ModelMigratedMailConnection"
        nextToken?: string | null
      } | null
      mailMigratedFlg?: boolean | null
      favoriteMatchingOffer?: {
        __typename: "ModelFavoriteMatchingOfferConnection"
        nextToken?: string | null
      } | null
      deleteFlg?: boolean | null
      accountTeamMembersId: string
      migrateTeamMemberId?: number | null
      orderHistories?: {
        __typename: "ModelOrderHistoryConnection"
        nextToken?: string | null
      } | null
      paidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      transferPaidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
      teamMemberNotificationSettingId?: string | null
    } | null
    threadMailRecipientId?: string | null
    messages?: {
      __typename: "ModelThreadMessageConnection"
      items: Array<{
        __typename: "ThreadMessage"
        id: string
        messageSenderId: string
        messageRecipientAccountId: string
        teamId: string
        mailId: string
        text?: string | null
        unreadFlg: number
        deleteFlg?: boolean | null
        contentUpdatedAt: string
        createdAt: string
        createdYearMonth?: number | null
        createdDate?: string | null
        threadMessagesId: string
        updatedAt: string
      } | null>
      nextToken?: string | null
    } | null
    mail?: {
      __typename: "Mail"
      id: string
      teamId: string
      mailType: string
      title: string
      sender?: {
        __typename: "TeamMember"
        id: string
        memberSeq: number
        nickname: string
        nicknameKana: string
        role: string
        showEmailFlg?: boolean | null
        phoneNumber?: string | null
        showPhoneNumberFlg?: boolean | null
        belongs?: string | null
        showBelongsFlg?: boolean | null
        grade?: number | null
        teamIdGsi: string
        teamTeamMembersId?: string | null
        mailMigratedFlg?: boolean | null
        deleteFlg?: boolean | null
        accountTeamMembersId: string
        migrateTeamMemberId?: number | null
        createdAt: string
        updatedAt: string
        teamMemberNotificationSettingId?: string | null
      } | null
      body: string
      sendTo: {
        __typename: "SendTo"
        allMembersFlg?: boolean | null
        managersFlg?: boolean | null
        groupIds?: Array<string> | null
        memberIds?: Array<string> | null
      }
      attachmentFileNames?: Array<string> | null
      startDate?: string | null
      startTime?: string | null
      endDate?: string | null
      endTime?: string | null
      questionnaires?: Array<{
        __typename: "Questionnaires"
        answerSelectType: AnswerSelectType
        question: string
        choices: Array<string>
      }> | null
      candidateDatetimes?: Array<{
        __typename: "CandidateDatetimes"
        startDate: string
        startTime?: string | null
        endDate?: string | null
        endTime?: string | null
      }> | null
      shareManagerFlg?: number | null
      showAnswerFlg?: boolean | null
      responseDueDatetime?: string | null
      remindDate: string
      remindDatetime?: string | null
      remindExecDatetime?: string | null
      sendScheduledAt?: string | null
      sentAt: string
      teamMemberMails?: {
        __typename: "ModelTeamMemberMailConnection"
        nextToken?: string | null
      } | null
      threads?: {
        __typename: "ModelThreadConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
      mailSenderId?: string | null
    } | null
    mailThreadsId: string
    createdAt: string
    updatedAt: string
    threadMailSenderId?: string | null
  } | null
}

export type _listThreadMessageQueryVariables = {
  filter?: ModelThreadMessageFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type _listThreadMessageQuery = {
  _listThreadMessage?: {
    __typename: "ModelThreadMessageConnection"
    items: Array<{
      __typename: "ThreadMessage"
      id: string
      messageSenderId: string
      messageRecipientAccountId: string
      teamId: string
      mailId: string
      text?: string | null
      unreadFlg: number
      deleteFlg?: boolean | null
      contentUpdatedAt: string
      createdAt: string
      createdYearMonth?: number | null
      createdDate?: string | null
      threadMessagesId: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type ListJobMasterQueryVariables = {
  filter?: ModelJobMasterFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListJobMasterQuery = {
  listJobMaster?: {
    __typename: "ModelJobMasterConnection"
    items: Array<{
      __typename: "JobMaster"
      id: string
      label: string
      type: JobType
      displayNumber: number
      displayFlg: number
      createdAt: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type GetSchoolMasterQueryVariables = {
  id: string
}

export type GetSchoolMasterQuery = {
  getSchoolMaster?: {
    __typename: "SchoolMaster"
    id: string
    label: string
    labelKana: string
    type: string
    code?: string | null
    displayNumber: number
    displayFlg: number
    departments?: {
      __typename: "ModelSchoolDepartmentMasterConnection"
      items: Array<{
        __typename: "SchoolDepartmentMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
        schoolMasterDepartmentsId?: string | null
      } | null>
      nextToken?: string | null
    } | null
    createdAt: string
    updatedAt: string
  } | null
}

export type ListSchoolMasterQueryVariables = {
  filter?: ModelSchoolMasterFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListSchoolMasterQuery = {
  listSchoolMaster?: {
    __typename: "ModelSchoolMasterConnection"
    items: Array<{
      __typename: "SchoolMaster"
      id: string
      label: string
      labelKana: string
      type: string
      code?: string | null
      displayNumber: number
      displayFlg: number
      departments?: {
        __typename: "ModelSchoolDepartmentMasterConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type GetPrefectureMasterQueryVariables = {
  id: string
}

export type GetPrefectureMasterQuery = {
  getPrefectureMaster?: {
    __typename: "PrefectureMaster"
    id: string
    label: string
    displayNumber: number
    displayFlg: number
    cities?: {
      __typename: "ModelCityMasterConnection"
      items: Array<{
        __typename: "CityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
        prefectureMasterCitiesId?: string | null
      } | null>
      nextToken?: string | null
    } | null
    createdAt: string
    updatedAt: string
  } | null
}

export type ListPrefectureMasterQueryVariables = {
  filter?: ModelPrefectureMasterFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListPrefectureMasterQuery = {
  listPrefectureMaster?: {
    __typename: "ModelPrefectureMasterConnection"
    items: Array<{
      __typename: "PrefectureMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      cities?: {
        __typename: "ModelCityMasterConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type GetTeamCategoryMasterQueryVariables = {
  id: string
}

export type GetTeamCategoryMasterQuery = {
  getTeamCategoryMaster?: {
    __typename: "TeamCategoryMaster"
    id: string
    label: string
    displayNumber: number
    displayFlg: number
    activities?: {
      __typename: "ModelTeamCategoryActivityConnection"
      items: Array<{
        __typename: "TeamCategoryActivity"
        id: string
        teamCategoryMasterID: string
        teamActivityMasterID: string
        createdAt: string
        updatedAt: string
      } | null>
      nextToken?: string | null
    } | null
    createdAt: string
    updatedAt: string
  } | null
}

export type ListTeamCategoryMasterQueryVariables = {
  filter?: ModelTeamCategoryMasterFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListTeamCategoryMasterQuery = {
  listTeamCategoryMaster?: {
    __typename: "ModelTeamCategoryMasterConnection"
    items: Array<{
      __typename: "TeamCategoryMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      activities?: {
        __typename: "ModelTeamCategoryActivityConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type GetAccountQuestionMasterQueryVariables = {
  id: string
}

export type GetAccountQuestionMasterQuery = {
  getAccountQuestionMaster?: {
    __typename: "AccountQuestionMaster"
    id: string
    label: string
    displayNumber: number
    displayFlg: number
    choices?: {
      __typename: "ModelAccountQuestionChoiceMasterConnection"
      items: Array<{
        __typename: "AccountQuestionChoiceMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        isDefault: boolean
        createdAt: string
        updatedAt: string
        accountQuestionMasterChoicesId?: string | null
      } | null>
      nextToken?: string | null
    } | null
    createdAt: string
    updatedAt: string
  } | null
}

export type ListAccountQuestionMasterQueryVariables = {
  filter?: ModelAccountQuestionMasterFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListAccountQuestionMasterQuery = {
  listAccountQuestionMaster?: {
    __typename: "ModelAccountQuestionMasterConnection"
    items: Array<{
      __typename: "AccountQuestionMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      choices?: {
        __typename: "ModelAccountQuestionChoiceMasterConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type GetTeamMemberPresetQueryVariables = {
  teamId: string
  email: string
}

export type GetTeamMemberPresetQuery = {
  getTeamMemberPreset?: {
    __typename: "TeamMemberPreset"
    teamId: string
    email: string
    memberSeq: number
    nickname: string
    nicknameKana?: string | null
    role: MemberRole
    showEmailFlg?: boolean | null
    phoneNumber?: string | null
    showPhoneNumberFlg?: boolean | null
    belongs?: string | null
    showBelongsFlg?: boolean | null
    grade?: number | null
    groups?: Array<{
      __typename: "Groups"
      id: string
      leaderFlg: boolean
    }> | null
    createdYearMonth?: number | null
    createdDate?: string | null
    migrateTeamMemberId?: number | null
    createdAt: string
    updatedAt: string
  } | null
}

export type _listTeamMemberPresetQueryVariables = {
  teamId?: string | null
  email?: ModelStringKeyConditionInput | null
  filter?: ModelTeamMemberPresetFilterInput | null
  limit?: number | null
  nextToken?: string | null
  sortDirection?: ModelSortDirection | null
}

export type _listTeamMemberPresetQuery = {
  _listTeamMemberPreset?: {
    __typename: "ModelTeamMemberPresetConnection"
    items: Array<{
      __typename: "TeamMemberPreset"
      teamId: string
      email: string
      memberSeq: number
      nickname: string
      nicknameKana?: string | null
      role: MemberRole
      showEmailFlg?: boolean | null
      phoneNumber?: string | null
      showPhoneNumberFlg?: boolean | null
      belongs?: string | null
      showBelongsFlg?: boolean | null
      grade?: number | null
      groups?: Array<{
        __typename: "Groups"
        id: string
        leaderFlg: boolean
      }> | null
      createdYearMonth?: number | null
      createdDate?: string | null
      migrateTeamMemberId?: number | null
      createdAt: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type GetMigratedMailQueryVariables = {
  id: string
}

export type GetMigratedMailQuery = {
  getMigratedMail?: {
    __typename: "MigratedMail"
    id: string
    migrationId: number
    title: string
    body: string
    sender: string
    favoriteFlg: boolean
    shareManagerFlg: boolean
    sentDatetime: string
    createdAt: string
    updatedAt: string
    teamMemberMigratedMailsId?: string | null
  } | null
}

export type GetMatchingOfferQueryVariables = {
  id: string
}

export type GetMatchingOfferQuery = {
  getMatchingOffer?: {
    __typename: "MatchingOffer"
    id: string
    purpose: MatchingOfferPurpose
    title: string
    body: string
    date?: string | null
    startTime?: string | null
    endTime?: string | null
    prefecture?: {
      __typename: "PrefectureMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      cities?: {
        __typename: "ModelCityMasterConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
    } | null
    city?: {
      __typename: "CityMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      createdAt: string
      updatedAt: string
      prefectureMasterCitiesId?: string | null
    } | null
    place?: string | null
    placeURL?: string | null
    expense?: number | null
    expensePurpose?: string | null
    ageFrom?: number | null
    ageTo?: number | null
    offerCount?: number | null
    publishEndDate: string
    team?: {
      __typename: "Team"
      id: string
      name: string
      category?: {
        __typename: "TeamCategoryMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      activity?: {
        __typename: "TeamActivityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      prefecture?: {
        __typename: "PrefectureMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      city?: {
        __typename: "CityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
        prefectureMasterCitiesId?: string | null
      } | null
      description: string
      requirementForMemberProfile?: string | null
      customQuestions?: Array<TeamCustomQuestion> | null
      showMemberListFlg: boolean
      invitationCode?: string | null
      teamMembers?: {
        __typename: "ModelTeamMemberConnection"
        nextToken?: string | null
      } | null
      preTeamMembers?: {
        __typename: "ModelPreTeamMemberConnection"
        nextToken?: string | null
      } | null
      invitationEmailStatuses?: {
        __typename: "ModelInvitationEmailStatusConnection"
        nextToken?: string | null
      } | null
      groups?: {
        __typename: "ModelGroupConnection"
        nextToken?: string | null
      } | null
      matchingOffers?: {
        __typename: "ModelMatchingOfferConnection"
        nextToken?: string | null
      } | null
      matchingOfferDrafts?: {
        __typename: "ModelMatchingOfferDraftConnection"
        nextToken?: string | null
      } | null
      matchingApplications?: {
        __typename: "ModelMatchingApplicationConnection"
        nextToken?: string | null
      } | null
      blockingMatchingTeams?: {
        __typename: "ModelBlockingMatchingTeamConnection"
        nextToken?: string | null
      } | null
      paidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      blockingMatchingTeamsByBlockingTeamId?: {
        __typename: "ModelBlockingMatchingTeamConnection"
        nextToken?: string | null
      } | null
      migrationId?: number | null
      paidFunctionEnabled?: boolean | null
      showAdsFlg?: boolean | null
      createdAt: string
      updatedAt: string
      teamCategoryId?: string | null
      teamActivityId?: string | null
      teamPrefectureId?: string | null
      teamCityId?: string | null
    } | null
    attachmentFileNames?: Array<string> | null
    activity?: {
      __typename: "TeamActivityMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      categories?: {
        __typename: "ModelTeamCategoryActivityConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
    } | null
    teamId: string
    teamName: string
    applications?: {
      __typename: "ModelMatchingApplicationConnection"
      items: Array<{
        __typename: "MatchingApplication"
        id: string
        offerTeamName: string
        offerTitle: string
        offerBody: string
        createdAt: string
        updatedAt: string
        teamMatchingApplicationsId?: string | null
        matchingOfferApplicationsId?: string | null
      } | null>
      nextToken?: string | null
    } | null
    deleteFlg?: boolean | null
    createdAt: string
    updatedAt: string
    teamMatchingOffersId?: string | null
    matchingOfferPrefectureId?: string | null
    matchingOfferCityId?: string | null
    matchingOfferActivityId?: string | null
  } | null
}

export type ListMatchingOfferQueryVariables = {
  filter?: ModelMatchingOfferFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListMatchingOfferQuery = {
  listMatchingOffer?: {
    __typename: "ModelMatchingOfferConnection"
    items: Array<{
      __typename: "MatchingOffer"
      id: string
      purpose: MatchingOfferPurpose
      title: string
      body: string
      date?: string | null
      startTime?: string | null
      endTime?: string | null
      prefecture?: {
        __typename: "PrefectureMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      city?: {
        __typename: "CityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
        prefectureMasterCitiesId?: string | null
      } | null
      place?: string | null
      placeURL?: string | null
      expense?: number | null
      expensePurpose?: string | null
      ageFrom?: number | null
      ageTo?: number | null
      offerCount?: number | null
      publishEndDate: string
      team?: {
        __typename: "Team"
        id: string
        name: string
        description: string
        requirementForMemberProfile?: string | null
        customQuestions?: Array<TeamCustomQuestion> | null
        showMemberListFlg: boolean
        invitationCode?: string | null
        migrationId?: number | null
        paidFunctionEnabled?: boolean | null
        showAdsFlg?: boolean | null
        createdAt: string
        updatedAt: string
        teamCategoryId?: string | null
        teamActivityId?: string | null
        teamPrefectureId?: string | null
        teamCityId?: string | null
      } | null
      attachmentFileNames?: Array<string> | null
      activity?: {
        __typename: "TeamActivityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      teamId: string
      teamName: string
      applications?: {
        __typename: "ModelMatchingApplicationConnection"
        nextToken?: string | null
      } | null
      deleteFlg?: boolean | null
      createdAt: string
      updatedAt: string
      teamMatchingOffersId?: string | null
      matchingOfferPrefectureId?: string | null
      matchingOfferCityId?: string | null
      matchingOfferActivityId?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetMatchingOfferDraftQueryVariables = {
  id: string
}

export type GetMatchingOfferDraftQuery = {
  getMatchingOfferDraft?: {
    __typename: "MatchingOfferDraft"
    id: string
    purpose?: MatchingOfferPurpose | null
    title: string
    body?: string | null
    date?: string | null
    startTime?: string | null
    endTime?: string | null
    prefecture?: {
      __typename: "PrefectureMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      cities?: {
        __typename: "ModelCityMasterConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
    } | null
    city?: {
      __typename: "CityMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      createdAt: string
      updatedAt: string
      prefectureMasterCitiesId?: string | null
    } | null
    place?: string | null
    placeURL?: string | null
    expense?: number | null
    expensePurpose?: string | null
    ageFrom?: number | null
    ageTo?: number | null
    offerCount?: number | null
    publishEndDate?: string | null
    team?: {
      __typename: "Team"
      id: string
      name: string
      category?: {
        __typename: "TeamCategoryMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      activity?: {
        __typename: "TeamActivityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      prefecture?: {
        __typename: "PrefectureMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      city?: {
        __typename: "CityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
        prefectureMasterCitiesId?: string | null
      } | null
      description: string
      requirementForMemberProfile?: string | null
      customQuestions?: Array<TeamCustomQuestion> | null
      showMemberListFlg: boolean
      invitationCode?: string | null
      teamMembers?: {
        __typename: "ModelTeamMemberConnection"
        nextToken?: string | null
      } | null
      preTeamMembers?: {
        __typename: "ModelPreTeamMemberConnection"
        nextToken?: string | null
      } | null
      invitationEmailStatuses?: {
        __typename: "ModelInvitationEmailStatusConnection"
        nextToken?: string | null
      } | null
      groups?: {
        __typename: "ModelGroupConnection"
        nextToken?: string | null
      } | null
      matchingOffers?: {
        __typename: "ModelMatchingOfferConnection"
        nextToken?: string | null
      } | null
      matchingOfferDrafts?: {
        __typename: "ModelMatchingOfferDraftConnection"
        nextToken?: string | null
      } | null
      matchingApplications?: {
        __typename: "ModelMatchingApplicationConnection"
        nextToken?: string | null
      } | null
      blockingMatchingTeams?: {
        __typename: "ModelBlockingMatchingTeamConnection"
        nextToken?: string | null
      } | null
      paidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      blockingMatchingTeamsByBlockingTeamId?: {
        __typename: "ModelBlockingMatchingTeamConnection"
        nextToken?: string | null
      } | null
      migrationId?: number | null
      paidFunctionEnabled?: boolean | null
      showAdsFlg?: boolean | null
      createdAt: string
      updatedAt: string
      teamCategoryId?: string | null
      teamActivityId?: string | null
      teamPrefectureId?: string | null
      teamCityId?: string | null
    } | null
    createdAt: string
    updatedAt: string
    teamMatchingOfferDraftsId?: string | null
    matchingOfferDraftPrefectureId?: string | null
    matchingOfferDraftCityId?: string | null
  } | null
}

export type GetMatchingApplicationQueryVariables = {
  id: string
}

export type GetMatchingApplicationQuery = {
  getMatchingApplication?: {
    __typename: "MatchingApplication"
    id: string
    applicant?: {
      __typename: "Team"
      id: string
      name: string
      category?: {
        __typename: "TeamCategoryMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      activity?: {
        __typename: "TeamActivityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      prefecture?: {
        __typename: "PrefectureMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      city?: {
        __typename: "CityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
        prefectureMasterCitiesId?: string | null
      } | null
      description: string
      requirementForMemberProfile?: string | null
      customQuestions?: Array<TeamCustomQuestion> | null
      showMemberListFlg: boolean
      invitationCode?: string | null
      teamMembers?: {
        __typename: "ModelTeamMemberConnection"
        nextToken?: string | null
      } | null
      preTeamMembers?: {
        __typename: "ModelPreTeamMemberConnection"
        nextToken?: string | null
      } | null
      invitationEmailStatuses?: {
        __typename: "ModelInvitationEmailStatusConnection"
        nextToken?: string | null
      } | null
      groups?: {
        __typename: "ModelGroupConnection"
        nextToken?: string | null
      } | null
      matchingOffers?: {
        __typename: "ModelMatchingOfferConnection"
        nextToken?: string | null
      } | null
      matchingOfferDrafts?: {
        __typename: "ModelMatchingOfferDraftConnection"
        nextToken?: string | null
      } | null
      matchingApplications?: {
        __typename: "ModelMatchingApplicationConnection"
        nextToken?: string | null
      } | null
      blockingMatchingTeams?: {
        __typename: "ModelBlockingMatchingTeamConnection"
        nextToken?: string | null
      } | null
      paidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      blockingMatchingTeamsByBlockingTeamId?: {
        __typename: "ModelBlockingMatchingTeamConnection"
        nextToken?: string | null
      } | null
      migrationId?: number | null
      paidFunctionEnabled?: boolean | null
      showAdsFlg?: boolean | null
      createdAt: string
      updatedAt: string
      teamCategoryId?: string | null
      teamActivityId?: string | null
      teamPrefectureId?: string | null
      teamCityId?: string | null
    } | null
    offer?: {
      __typename: "MatchingOffer"
      id: string
      purpose: MatchingOfferPurpose
      title: string
      body: string
      date?: string | null
      startTime?: string | null
      endTime?: string | null
      prefecture?: {
        __typename: "PrefectureMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      city?: {
        __typename: "CityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
        prefectureMasterCitiesId?: string | null
      } | null
      place?: string | null
      placeURL?: string | null
      expense?: number | null
      expensePurpose?: string | null
      ageFrom?: number | null
      ageTo?: number | null
      offerCount?: number | null
      publishEndDate: string
      team?: {
        __typename: "Team"
        id: string
        name: string
        description: string
        requirementForMemberProfile?: string | null
        customQuestions?: Array<TeamCustomQuestion> | null
        showMemberListFlg: boolean
        invitationCode?: string | null
        migrationId?: number | null
        paidFunctionEnabled?: boolean | null
        showAdsFlg?: boolean | null
        createdAt: string
        updatedAt: string
        teamCategoryId?: string | null
        teamActivityId?: string | null
        teamPrefectureId?: string | null
        teamCityId?: string | null
      } | null
      attachmentFileNames?: Array<string> | null
      activity?: {
        __typename: "TeamActivityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      teamId: string
      teamName: string
      applications?: {
        __typename: "ModelMatchingApplicationConnection"
        nextToken?: string | null
      } | null
      deleteFlg?: boolean | null
      createdAt: string
      updatedAt: string
      teamMatchingOffersId?: string | null
      matchingOfferPrefectureId?: string | null
      matchingOfferCityId?: string | null
      matchingOfferActivityId?: string | null
    } | null
    messages?: {
      __typename: "ModelMatchingMessageConnection"
      items: Array<{
        __typename: "MatchingMessage"
        id: string
        messageSenderTeamId: string
        text?: string | null
        unreadFlg: number
        deleteFlg: number
        contentUpdatedAt: string
        createdAtForSort: string
        createdAtForSortIncludeDeleted: string
        applicationIdMessageReceiverGsi: string
        matchingApplicationMessagesId: string
        matchingApplicationMessagesIncludeDeletedId: string
        createdAt: string
        updatedAt: string
      } | null>
      nextToken?: string | null
    } | null
    messagesIncludeDeleted?: {
      __typename: "ModelMatchingMessageConnection"
      items: Array<{
        __typename: "MatchingMessage"
        id: string
        messageSenderTeamId: string
        text?: string | null
        unreadFlg: number
        deleteFlg: number
        contentUpdatedAt: string
        createdAtForSort: string
        createdAtForSortIncludeDeleted: string
        applicationIdMessageReceiverGsi: string
        matchingApplicationMessagesId: string
        matchingApplicationMessagesIncludeDeletedId: string
        createdAt: string
        updatedAt: string
      } | null>
      nextToken?: string | null
    } | null
    offerTeamName: string
    offerTitle: string
    offerBody: string
    createdAt: string
    updatedAt: string
    teamMatchingApplicationsId?: string | null
    matchingOfferApplicationsId?: string | null
  } | null
}

export type _listMaintenanceQueryVariables = {
  maintenanceFlg?: number | null
  startAt?: ModelStringKeyConditionInput | null
  filter?: ModelMaintenanceFilterInput | null
  limit?: number | null
  nextToken?: string | null
  sortDirection?: ModelSortDirection | null
}

export type _listMaintenanceQuery = {
  _listMaintenance?: {
    __typename: "ModelMaintenanceConnection"
    items: Array<{
      __typename: "Maintenance"
      maintenanceFlg: number
      content: string
      startAt: string
      endAt: string
      createdAt: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type _listAppVersionQueryVariables = {
  version?: string | null
  osType?: ModelStringKeyConditionInput | null
  filter?: ModelAppVersionFilterInput | null
  limit?: number | null
  nextToken?: string | null
  sortDirection?: ModelSortDirection | null
}

export type _listAppVersionQuery = {
  _listAppVersion?: {
    __typename: "ModelAppVersionConnection"
    items: Array<{
      __typename: "AppVersion"
      version: string
      osType: OsType
      osVersion: string
      releaseAt: string
      content: string
      createdAt: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type _listTermsQueryVariables = {
  version?: string | null
  type?: ModelStringKeyConditionInput | null
  filter?: ModelTermsFilterInput | null
  limit?: number | null
  nextToken?: string | null
  sortDirection?: ModelSortDirection | null
}

export type _listTermsQuery = {
  _listTerms?: {
    __typename: "ModelTermsConnection"
    items: Array<{
      __typename: "Terms"
      version: string
      type: TermsType
      validFlg: number
      url: string
      releaseAt: string
      createdAt: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type _listTempTokenQueryVariables = {
  tempToken?: string | null
  filter?: ModelTempTokenFilterInput | null
  limit?: number | null
  nextToken?: string | null
  sortDirection?: ModelSortDirection | null
}

export type _listTempTokenQuery = {
  _listTempToken?: {
    __typename: "ModelTempTokenConnection"
    items: Array<{
      __typename: "TempToken"
      tempToken: string
      accessToken: string
      createdYearMonth?: number | null
      createdDate?: string | null
      createdAt: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type GetTransferStatusQueryVariables = {
  id: string
}

export type GetTransferStatusQuery = {
  getTransferStatus?: {
    __typename: "TransferStatus"
    id: string
    orderId: string
    originalApplicantId: string
    newApplicantId: string
    status: string
    order: {
      __typename: "PaidVersionOrder"
      id: string
      orderNumber: string
      originalOrderId?: string | null
      orderStatus: string
      jobId?: string | null
      masterCode?: string | null
      zacStatus?: string | null
      supportedDate?: string | null
      address?: string | null
      orderType: string
      companyName?: string | null
      companyNameKana?: string | null
      corporateRepresentativeName?: string | null
      corporateRepresentativeNameKana?: string | null
      applicantPhone?: string | null
      applicantMail?: string | null
      applicantName?: string | null
      applicantPostcode?: string | null
      applicantPrefecture?: string | null
      applicantAddress?: string | null
      applicantBuildingName?: string | null
      applicantRemark?: string | null
      antiSocialCheck: string
      teamId?: string | null
      migrateTeamId?: number | null
      teamName?: string | null
      memberCount?: number | null
      applicantId?: string | null
      transferApplicantId?: string | null
      planId: string
      startDate: string
      endDate: string
      paymentStatus: string
      paymentDate?: string | null
      paymentDeadline?: string | null
      invoiceIssuedDate?: string | null
      paymentType: string
      paymentMethod: string
      invoiceAddressee?: string | null
      invoiceAddresseeKana?: string | null
      amount: number
      taxRate: number
      tax: number
      subTotal: number
      remark?: string | null
      team?: {
        __typename: "Team"
        id: string
        name: string
        description: string
        requirementForMemberProfile?: string | null
        customQuestions?: Array<TeamCustomQuestion> | null
        showMemberListFlg: boolean
        invitationCode?: string | null
        migrationId?: number | null
        paidFunctionEnabled?: boolean | null
        showAdsFlg?: boolean | null
        createdAt: string
        updatedAt: string
        teamCategoryId?: string | null
        teamActivityId?: string | null
        teamPrefectureId?: string | null
        teamCityId?: string | null
      } | null
      plan?: {
        __typename: "PaidVersionPlan"
        id: string
        planCode: string
        name: string
        type: string
        memberLimit?: number | null
        teamLimit?: number | null
        period: number
        amount: number
        planType: PlanType
        createdAt: string
        updatedAt: string
      } | null
      applicant?: {
        __typename: "TeamMember"
        id: string
        memberSeq: number
        nickname: string
        nicknameKana: string
        role: string
        showEmailFlg?: boolean | null
        phoneNumber?: string | null
        showPhoneNumberFlg?: boolean | null
        belongs?: string | null
        showBelongsFlg?: boolean | null
        grade?: number | null
        teamIdGsi: string
        teamTeamMembersId?: string | null
        mailMigratedFlg?: boolean | null
        deleteFlg?: boolean | null
        accountTeamMembersId: string
        migrateTeamMemberId?: number | null
        createdAt: string
        updatedAt: string
        teamMemberNotificationSettingId?: string | null
      } | null
      applicantTransfer?: {
        __typename: "TeamMember"
        id: string
        memberSeq: number
        nickname: string
        nicknameKana: string
        role: string
        showEmailFlg?: boolean | null
        phoneNumber?: string | null
        showPhoneNumberFlg?: boolean | null
        belongs?: string | null
        showBelongsFlg?: boolean | null
        grade?: number | null
        teamIdGsi: string
        teamTeamMembersId?: string | null
        mailMigratedFlg?: boolean | null
        deleteFlg?: boolean | null
        accountTeamMembersId: string
        migrateTeamMemberId?: number | null
        createdAt: string
        updatedAt: string
        teamMemberNotificationSettingId?: string | null
      } | null
      orderHistories?: {
        __typename: "ModelOrderHistoryConnection"
        nextToken?: string | null
      } | null
      transferStatus?: {
        __typename: "ModelTransferStatusConnection"
        nextToken?: string | null
      } | null
      adminId?: string | null
      createdAt: string
      updatedAt: string
    }
    createdAt: string
    updatedAt: string
  } | null
}

export type GetOrderHistoryQueryVariables = {
  id: string
}

export type GetOrderHistoryQuery = {
  getOrderHistory?: {
    __typename: "OrderHistory"
    id: string
    type: string
    orderId: string
    order: {
      __typename: "PaidVersionOrder"
      id: string
      orderNumber: string
      originalOrderId?: string | null
      orderStatus: string
      jobId?: string | null
      masterCode?: string | null
      zacStatus?: string | null
      supportedDate?: string | null
      address?: string | null
      orderType: string
      companyName?: string | null
      companyNameKana?: string | null
      corporateRepresentativeName?: string | null
      corporateRepresentativeNameKana?: string | null
      applicantPhone?: string | null
      applicantMail?: string | null
      applicantName?: string | null
      applicantPostcode?: string | null
      applicantPrefecture?: string | null
      applicantAddress?: string | null
      applicantBuildingName?: string | null
      applicantRemark?: string | null
      antiSocialCheck: string
      teamId?: string | null
      migrateTeamId?: number | null
      teamName?: string | null
      memberCount?: number | null
      applicantId?: string | null
      transferApplicantId?: string | null
      planId: string
      startDate: string
      endDate: string
      paymentStatus: string
      paymentDate?: string | null
      paymentDeadline?: string | null
      invoiceIssuedDate?: string | null
      paymentType: string
      paymentMethod: string
      invoiceAddressee?: string | null
      invoiceAddresseeKana?: string | null
      amount: number
      taxRate: number
      tax: number
      subTotal: number
      remark?: string | null
      team?: {
        __typename: "Team"
        id: string
        name: string
        description: string
        requirementForMemberProfile?: string | null
        customQuestions?: Array<TeamCustomQuestion> | null
        showMemberListFlg: boolean
        invitationCode?: string | null
        migrationId?: number | null
        paidFunctionEnabled?: boolean | null
        showAdsFlg?: boolean | null
        createdAt: string
        updatedAt: string
        teamCategoryId?: string | null
        teamActivityId?: string | null
        teamPrefectureId?: string | null
        teamCityId?: string | null
      } | null
      plan?: {
        __typename: "PaidVersionPlan"
        id: string
        planCode: string
        name: string
        type: string
        memberLimit?: number | null
        teamLimit?: number | null
        period: number
        amount: number
        planType: PlanType
        createdAt: string
        updatedAt: string
      } | null
      applicant?: {
        __typename: "TeamMember"
        id: string
        memberSeq: number
        nickname: string
        nicknameKana: string
        role: string
        showEmailFlg?: boolean | null
        phoneNumber?: string | null
        showPhoneNumberFlg?: boolean | null
        belongs?: string | null
        showBelongsFlg?: boolean | null
        grade?: number | null
        teamIdGsi: string
        teamTeamMembersId?: string | null
        mailMigratedFlg?: boolean | null
        deleteFlg?: boolean | null
        accountTeamMembersId: string
        migrateTeamMemberId?: number | null
        createdAt: string
        updatedAt: string
        teamMemberNotificationSettingId?: string | null
      } | null
      applicantTransfer?: {
        __typename: "TeamMember"
        id: string
        memberSeq: number
        nickname: string
        nicknameKana: string
        role: string
        showEmailFlg?: boolean | null
        phoneNumber?: string | null
        showPhoneNumberFlg?: boolean | null
        belongs?: string | null
        showBelongsFlg?: boolean | null
        grade?: number | null
        teamIdGsi: string
        teamTeamMembersId?: string | null
        mailMigratedFlg?: boolean | null
        deleteFlg?: boolean | null
        accountTeamMembersId: string
        migrateTeamMemberId?: number | null
        createdAt: string
        updatedAt: string
        teamMemberNotificationSettingId?: string | null
      } | null
      orderHistories?: {
        __typename: "ModelOrderHistoryConnection"
        nextToken?: string | null
      } | null
      transferStatus?: {
        __typename: "ModelTransferStatusConnection"
        nextToken?: string | null
      } | null
      adminId?: string | null
      createdAt: string
      updatedAt: string
    }
    teamMemberId?: string | null
    teamMember?: {
      __typename: "TeamMember"
      id: string
      memberSeq: number
      nickname: string
      nicknameKana: string
      role: string
      showEmailFlg?: boolean | null
      phoneNumber?: string | null
      showPhoneNumberFlg?: boolean | null
      belongs?: string | null
      showBelongsFlg?: boolean | null
      grade?: number | null
      account?: {
        __typename: "Account"
        id: string
        email: string
        lastName: string
        firstName: string
        lastNameKana: string
        firstNameKana: string
        phoneNumber?: string | null
        gender?: Gender | null
        birthday?: string | null
        schoolGraduationYear?: number | null
        termsAgreedAt?: string | null
        agreedTermsVersion?: string | null
        matchingTermsAgreedAt?: string | null
        agreedMatchingTermsVersion?: string | null
        signupStatus: SignupStatus
        haveOldRa9TeamNotJoin: boolean
        migrateAccountId?: number | null
        createdAt: string
        updatedAt: string
        accountJobId?: string | null
        accountSchoolId?: string | null
        accountSchoolDepartmentId?: string | null
        accountPrefectureId?: string | null
        accountCityId?: string | null
      } | null
      team?: {
        __typename: "Team"
        id: string
        name: string
        description: string
        requirementForMemberProfile?: string | null
        customQuestions?: Array<TeamCustomQuestion> | null
        showMemberListFlg: boolean
        invitationCode?: string | null
        migrationId?: number | null
        paidFunctionEnabled?: boolean | null
        showAdsFlg?: boolean | null
        createdAt: string
        updatedAt: string
        teamCategoryId?: string | null
        teamActivityId?: string | null
        teamPrefectureId?: string | null
        teamCityId?: string | null
      } | null
      teamIdGsi: string
      teamTeamMembersId?: string | null
      notificationSetting?: {
        __typename: "NotificationSetting"
        id: string
        createdAt: string
        updatedAt: string
      } | null
      teamNotice?: {
        __typename: "ModelTeamNoticeConnection"
        nextToken?: string | null
      } | null
      groups?: {
        __typename: "ModelGroupMemberConnection"
        nextToken?: string | null
      } | null
      teamMemberMails?: {
        __typename: "ModelTeamMemberMailConnection"
        nextToken?: string | null
      } | null
      reservedMails?: {
        __typename: "ModelReservedMailConnection"
        nextToken?: string | null
      } | null
      drafts?: {
        __typename: "ModelDraftConnection"
        nextToken?: string | null
      } | null
      templates?: {
        __typename: "ModelTemplateConnection"
        nextToken?: string | null
      } | null
      migratedMails?: {
        __typename: "ModelMigratedMailConnection"
        nextToken?: string | null
      } | null
      mailMigratedFlg?: boolean | null
      favoriteMatchingOffer?: {
        __typename: "ModelFavoriteMatchingOfferConnection"
        nextToken?: string | null
      } | null
      deleteFlg?: boolean | null
      accountTeamMembersId: string
      migrateTeamMemberId?: number | null
      orderHistories?: {
        __typename: "ModelOrderHistoryConnection"
        nextToken?: string | null
      } | null
      paidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      transferPaidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
      teamMemberNotificationSettingId?: string | null
    } | null
    adminId?: string | null
    content: string
    createdAt: string
    updatedAt: string
  } | null
}

export type GetPaidVersionOrderQueryVariables = {
  id: string
}

export type GetPaidVersionOrderQuery = {
  getPaidVersionOrder?: {
    __typename: "PaidVersionOrder"
    id: string
    orderNumber: string
    originalOrderId?: string | null
    orderStatus: string
    jobId?: string | null
    masterCode?: string | null
    zacStatus?: string | null
    supportedDate?: string | null
    address?: string | null
    orderType: string
    companyName?: string | null
    companyNameKana?: string | null
    corporateRepresentativeName?: string | null
    corporateRepresentativeNameKana?: string | null
    applicantPhone?: string | null
    applicantMail?: string | null
    applicantName?: string | null
    applicantPostcode?: string | null
    applicantPrefecture?: string | null
    applicantAddress?: string | null
    applicantBuildingName?: string | null
    applicantRemark?: string | null
    antiSocialCheck: string
    teamId?: string | null
    migrateTeamId?: number | null
    teamName?: string | null
    memberCount?: number | null
    applicantId?: string | null
    transferApplicantId?: string | null
    planId: string
    startDate: string
    endDate: string
    paymentStatus: string
    paymentDate?: string | null
    paymentDeadline?: string | null
    invoiceIssuedDate?: string | null
    paymentType: string
    paymentMethod: string
    invoiceAddressee?: string | null
    invoiceAddresseeKana?: string | null
    amount: number
    taxRate: number
    tax: number
    subTotal: number
    remark?: string | null
    team?: {
      __typename: "Team"
      id: string
      name: string
      category?: {
        __typename: "TeamCategoryMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      activity?: {
        __typename: "TeamActivityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      prefecture?: {
        __typename: "PrefectureMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      city?: {
        __typename: "CityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
        prefectureMasterCitiesId?: string | null
      } | null
      description: string
      requirementForMemberProfile?: string | null
      customQuestions?: Array<TeamCustomQuestion> | null
      showMemberListFlg: boolean
      invitationCode?: string | null
      teamMembers?: {
        __typename: "ModelTeamMemberConnection"
        nextToken?: string | null
      } | null
      preTeamMembers?: {
        __typename: "ModelPreTeamMemberConnection"
        nextToken?: string | null
      } | null
      invitationEmailStatuses?: {
        __typename: "ModelInvitationEmailStatusConnection"
        nextToken?: string | null
      } | null
      groups?: {
        __typename: "ModelGroupConnection"
        nextToken?: string | null
      } | null
      matchingOffers?: {
        __typename: "ModelMatchingOfferConnection"
        nextToken?: string | null
      } | null
      matchingOfferDrafts?: {
        __typename: "ModelMatchingOfferDraftConnection"
        nextToken?: string | null
      } | null
      matchingApplications?: {
        __typename: "ModelMatchingApplicationConnection"
        nextToken?: string | null
      } | null
      blockingMatchingTeams?: {
        __typename: "ModelBlockingMatchingTeamConnection"
        nextToken?: string | null
      } | null
      paidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      blockingMatchingTeamsByBlockingTeamId?: {
        __typename: "ModelBlockingMatchingTeamConnection"
        nextToken?: string | null
      } | null
      migrationId?: number | null
      paidFunctionEnabled?: boolean | null
      showAdsFlg?: boolean | null
      createdAt: string
      updatedAt: string
      teamCategoryId?: string | null
      teamActivityId?: string | null
      teamPrefectureId?: string | null
      teamCityId?: string | null
    } | null
    plan?: {
      __typename: "PaidVersionPlan"
      id: string
      planCode: string
      name: string
      type: string
      memberLimit?: number | null
      teamLimit?: number | null
      period: number
      amount: number
      planType: PlanType
      orders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
    } | null
    applicant?: {
      __typename: "TeamMember"
      id: string
      memberSeq: number
      nickname: string
      nicknameKana: string
      role: string
      showEmailFlg?: boolean | null
      phoneNumber?: string | null
      showPhoneNumberFlg?: boolean | null
      belongs?: string | null
      showBelongsFlg?: boolean | null
      grade?: number | null
      account?: {
        __typename: "Account"
        id: string
        email: string
        lastName: string
        firstName: string
        lastNameKana: string
        firstNameKana: string
        phoneNumber?: string | null
        gender?: Gender | null
        birthday?: string | null
        schoolGraduationYear?: number | null
        termsAgreedAt?: string | null
        agreedTermsVersion?: string | null
        matchingTermsAgreedAt?: string | null
        agreedMatchingTermsVersion?: string | null
        signupStatus: SignupStatus
        haveOldRa9TeamNotJoin: boolean
        migrateAccountId?: number | null
        createdAt: string
        updatedAt: string
        accountJobId?: string | null
        accountSchoolId?: string | null
        accountSchoolDepartmentId?: string | null
        accountPrefectureId?: string | null
        accountCityId?: string | null
      } | null
      team?: {
        __typename: "Team"
        id: string
        name: string
        description: string
        requirementForMemberProfile?: string | null
        customQuestions?: Array<TeamCustomQuestion> | null
        showMemberListFlg: boolean
        invitationCode?: string | null
        migrationId?: number | null
        paidFunctionEnabled?: boolean | null
        showAdsFlg?: boolean | null
        createdAt: string
        updatedAt: string
        teamCategoryId?: string | null
        teamActivityId?: string | null
        teamPrefectureId?: string | null
        teamCityId?: string | null
      } | null
      teamIdGsi: string
      teamTeamMembersId?: string | null
      notificationSetting?: {
        __typename: "NotificationSetting"
        id: string
        createdAt: string
        updatedAt: string
      } | null
      teamNotice?: {
        __typename: "ModelTeamNoticeConnection"
        nextToken?: string | null
      } | null
      groups?: {
        __typename: "ModelGroupMemberConnection"
        nextToken?: string | null
      } | null
      teamMemberMails?: {
        __typename: "ModelTeamMemberMailConnection"
        nextToken?: string | null
      } | null
      reservedMails?: {
        __typename: "ModelReservedMailConnection"
        nextToken?: string | null
      } | null
      drafts?: {
        __typename: "ModelDraftConnection"
        nextToken?: string | null
      } | null
      templates?: {
        __typename: "ModelTemplateConnection"
        nextToken?: string | null
      } | null
      migratedMails?: {
        __typename: "ModelMigratedMailConnection"
        nextToken?: string | null
      } | null
      mailMigratedFlg?: boolean | null
      favoriteMatchingOffer?: {
        __typename: "ModelFavoriteMatchingOfferConnection"
        nextToken?: string | null
      } | null
      deleteFlg?: boolean | null
      accountTeamMembersId: string
      migrateTeamMemberId?: number | null
      orderHistories?: {
        __typename: "ModelOrderHistoryConnection"
        nextToken?: string | null
      } | null
      paidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      transferPaidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
      teamMemberNotificationSettingId?: string | null
    } | null
    applicantTransfer?: {
      __typename: "TeamMember"
      id: string
      memberSeq: number
      nickname: string
      nicknameKana: string
      role: string
      showEmailFlg?: boolean | null
      phoneNumber?: string | null
      showPhoneNumberFlg?: boolean | null
      belongs?: string | null
      showBelongsFlg?: boolean | null
      grade?: number | null
      account?: {
        __typename: "Account"
        id: string
        email: string
        lastName: string
        firstName: string
        lastNameKana: string
        firstNameKana: string
        phoneNumber?: string | null
        gender?: Gender | null
        birthday?: string | null
        schoolGraduationYear?: number | null
        termsAgreedAt?: string | null
        agreedTermsVersion?: string | null
        matchingTermsAgreedAt?: string | null
        agreedMatchingTermsVersion?: string | null
        signupStatus: SignupStatus
        haveOldRa9TeamNotJoin: boolean
        migrateAccountId?: number | null
        createdAt: string
        updatedAt: string
        accountJobId?: string | null
        accountSchoolId?: string | null
        accountSchoolDepartmentId?: string | null
        accountPrefectureId?: string | null
        accountCityId?: string | null
      } | null
      team?: {
        __typename: "Team"
        id: string
        name: string
        description: string
        requirementForMemberProfile?: string | null
        customQuestions?: Array<TeamCustomQuestion> | null
        showMemberListFlg: boolean
        invitationCode?: string | null
        migrationId?: number | null
        paidFunctionEnabled?: boolean | null
        showAdsFlg?: boolean | null
        createdAt: string
        updatedAt: string
        teamCategoryId?: string | null
        teamActivityId?: string | null
        teamPrefectureId?: string | null
        teamCityId?: string | null
      } | null
      teamIdGsi: string
      teamTeamMembersId?: string | null
      notificationSetting?: {
        __typename: "NotificationSetting"
        id: string
        createdAt: string
        updatedAt: string
      } | null
      teamNotice?: {
        __typename: "ModelTeamNoticeConnection"
        nextToken?: string | null
      } | null
      groups?: {
        __typename: "ModelGroupMemberConnection"
        nextToken?: string | null
      } | null
      teamMemberMails?: {
        __typename: "ModelTeamMemberMailConnection"
        nextToken?: string | null
      } | null
      reservedMails?: {
        __typename: "ModelReservedMailConnection"
        nextToken?: string | null
      } | null
      drafts?: {
        __typename: "ModelDraftConnection"
        nextToken?: string | null
      } | null
      templates?: {
        __typename: "ModelTemplateConnection"
        nextToken?: string | null
      } | null
      migratedMails?: {
        __typename: "ModelMigratedMailConnection"
        nextToken?: string | null
      } | null
      mailMigratedFlg?: boolean | null
      favoriteMatchingOffer?: {
        __typename: "ModelFavoriteMatchingOfferConnection"
        nextToken?: string | null
      } | null
      deleteFlg?: boolean | null
      accountTeamMembersId: string
      migrateTeamMemberId?: number | null
      orderHistories?: {
        __typename: "ModelOrderHistoryConnection"
        nextToken?: string | null
      } | null
      paidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      transferPaidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
      teamMemberNotificationSettingId?: string | null
    } | null
    orderHistories?: {
      __typename: "ModelOrderHistoryConnection"
      items: Array<{
        __typename: "OrderHistory"
        id: string
        type: string
        orderId: string
        teamMemberId?: string | null
        adminId?: string | null
        content: string
        createdAt: string
        updatedAt: string
      } | null>
      nextToken?: string | null
    } | null
    transferStatus?: {
      __typename: "ModelTransferStatusConnection"
      items: Array<{
        __typename: "TransferStatus"
        id: string
        orderId: string
        originalApplicantId: string
        newApplicantId: string
        status: string
        createdAt: string
        updatedAt: string
      } | null>
      nextToken?: string | null
    } | null
    adminId?: string | null
    createdAt: string
    updatedAt: string
  } | null
}

export type GetHolidayCalendarQueryVariables = {
  id: string
}

export type GetHolidayCalendarQuery = {
  getHolidayCalendar?: {
    __typename: "HolidayCalendar"
    id: string
    date: string
    name?: string | null
    content?: string | null
    createdAt: string
    updatedAt: string
  } | null
}

export type GetTaxQueryVariables = {
  id: string
}

export type GetTaxQuery = {
  getTax?: {
    __typename: "Tax"
    id: string
    taxRate: number
    startDate: string
    endDate: string
    createdAt: string
    updatedAt: string
  } | null
}

export type GetPaidVersionPlanQueryVariables = {
  id: string
}

export type GetPaidVersionPlanQuery = {
  getPaidVersionPlan?: {
    __typename: "PaidVersionPlan"
    id: string
    planCode: string
    name: string
    type: string
    memberLimit?: number | null
    teamLimit?: number | null
    period: number
    amount: number
    planType: PlanType
    orders?: {
      __typename: "ModelPaidVersionOrderConnection"
      items: Array<{
        __typename: "PaidVersionOrder"
        id: string
        orderNumber: string
        originalOrderId?: string | null
        orderStatus: string
        jobId?: string | null
        masterCode?: string | null
        zacStatus?: string | null
        supportedDate?: string | null
        address?: string | null
        orderType: string
        companyName?: string | null
        companyNameKana?: string | null
        corporateRepresentativeName?: string | null
        corporateRepresentativeNameKana?: string | null
        applicantPhone?: string | null
        applicantMail?: string | null
        applicantName?: string | null
        applicantPostcode?: string | null
        applicantPrefecture?: string | null
        applicantAddress?: string | null
        applicantBuildingName?: string | null
        applicantRemark?: string | null
        antiSocialCheck: string
        teamId?: string | null
        migrateTeamId?: number | null
        teamName?: string | null
        memberCount?: number | null
        applicantId?: string | null
        transferApplicantId?: string | null
        planId: string
        startDate: string
        endDate: string
        paymentStatus: string
        paymentDate?: string | null
        paymentDeadline?: string | null
        invoiceIssuedDate?: string | null
        paymentType: string
        paymentMethod: string
        invoiceAddressee?: string | null
        invoiceAddresseeKana?: string | null
        amount: number
        taxRate: number
        tax: number
        subTotal: number
        remark?: string | null
        adminId?: string | null
        createdAt: string
        updatedAt: string
      } | null>
      nextToken?: string | null
    } | null
    createdAt: string
    updatedAt: string
  } | null
}

export type GetTeamCategoryActivityQueryVariables = {
  id: string
}

export type GetTeamCategoryActivityQuery = {
  getTeamCategoryActivity?: {
    __typename: "TeamCategoryActivity"
    id: string
    teamCategoryMasterID: string
    teamActivityMasterID: string
    teamCategoryMaster: {
      __typename: "TeamCategoryMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      activities?: {
        __typename: "ModelTeamCategoryActivityConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
    }
    teamActivityMaster: {
      __typename: "TeamActivityMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      categories?: {
        __typename: "ModelTeamCategoryActivityConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
    }
    createdAt: string
    updatedAt: string
  } | null
}

export type ListTeamCategoryActivitiesQueryVariables = {
  filter?: ModelTeamCategoryActivityFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type ListTeamCategoryActivitiesQuery = {
  listTeamCategoryActivities?: {
    __typename: "ModelTeamCategoryActivityConnection"
    items: Array<{
      __typename: "TeamCategoryActivity"
      id: string
      teamCategoryMasterID: string
      teamActivityMasterID: string
      teamCategoryMaster: {
        __typename: "TeamCategoryMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      }
      teamActivityMaster: {
        __typename: "TeamActivityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      }
      createdAt: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type GetAdminNoticeByPublishMonthQueryVariables = {
  publishMonth: number
  publishDatetime?: ModelStringKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelAdminNoticeFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetAdminNoticeByPublishMonthQuery = {
  getAdminNoticeByPublishMonth?: {
    __typename: "ModelAdminNoticeConnection"
    items: Array<{
      __typename: "AdminNotice"
      id: string
      label: string
      title: string
      content: string
      priority?: AdminNoticePriority | null
      publishMonth: number
      publishDatetime: string
      createdAt: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type GetAccountByEmailQueryVariables = {
  email: string
  sortDirection?: ModelSortDirection | null
  filter?: ModelAccountFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetAccountByEmailQuery = {
  getAccountByEmail?: {
    __typename: "ModelAccountConnection"
    items: Array<{
      __typename: "Account"
      id: string
      email: string
      lastName: string
      firstName: string
      lastNameKana: string
      firstNameKana: string
      phoneNumber?: string | null
      gender?: Gender | null
      birthday?: string | null
      job?: {
        __typename: "JobMaster"
        id: string
        label: string
        type: JobType
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      school?: {
        __typename: "SchoolMaster"
        id: string
        label: string
        labelKana: string
        type: string
        code?: string | null
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      schoolDepartment?: {
        __typename: "SchoolDepartmentMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
        schoolMasterDepartmentsId?: string | null
      } | null
      schoolGraduationYear?: number | null
      prefecture?: {
        __typename: "PrefectureMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      city?: {
        __typename: "CityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
        prefectureMasterCitiesId?: string | null
      } | null
      questionAnswers?: {
        __typename: "ModelAccountQuestionAnswerConnection"
        nextToken?: string | null
      } | null
      termsAgreedAt?: string | null
      agreedTermsVersion?: string | null
      matchingTermsAgreedAt?: string | null
      agreedMatchingTermsVersion?: string | null
      signupStatus: SignupStatus
      accountDevices?: {
        __typename: "ModelAccountDeviceConnection"
        nextToken?: string | null
      } | null
      teamMembers?: {
        __typename: "ModelTeamMemberConnection"
        nextToken?: string | null
      } | null
      preTeamMembers?: {
        __typename: "ModelPreTeamMemberConnection"
        nextToken?: string | null
      } | null
      teamNotices?: {
        __typename: "ModelTeamNoticeConnection"
        nextToken?: string | null
      } | null
      haveOldRa9TeamNotJoin: boolean
      migrateAccountId?: number | null
      createdAt: string
      updatedAt: string
      accountJobId?: string | null
      accountSchoolId?: string | null
      accountSchoolDepartmentId?: string | null
      accountPrefectureId?: string | null
      accountCityId?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetAccountByMigrationAccountIdQueryVariables = {
  migrateAccountId: number
  sortDirection?: ModelSortDirection | null
  filter?: ModelAccountFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetAccountByMigrationAccountIdQuery = {
  getAccountByMigrationAccountId?: {
    __typename: "ModelAccountConnection"
    items: Array<{
      __typename: "Account"
      id: string
      email: string
      lastName: string
      firstName: string
      lastNameKana: string
      firstNameKana: string
      phoneNumber?: string | null
      gender?: Gender | null
      birthday?: string | null
      job?: {
        __typename: "JobMaster"
        id: string
        label: string
        type: JobType
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      school?: {
        __typename: "SchoolMaster"
        id: string
        label: string
        labelKana: string
        type: string
        code?: string | null
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      schoolDepartment?: {
        __typename: "SchoolDepartmentMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
        schoolMasterDepartmentsId?: string | null
      } | null
      schoolGraduationYear?: number | null
      prefecture?: {
        __typename: "PrefectureMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      city?: {
        __typename: "CityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
        prefectureMasterCitiesId?: string | null
      } | null
      questionAnswers?: {
        __typename: "ModelAccountQuestionAnswerConnection"
        nextToken?: string | null
      } | null
      termsAgreedAt?: string | null
      agreedTermsVersion?: string | null
      matchingTermsAgreedAt?: string | null
      agreedMatchingTermsVersion?: string | null
      signupStatus: SignupStatus
      accountDevices?: {
        __typename: "ModelAccountDeviceConnection"
        nextToken?: string | null
      } | null
      teamMembers?: {
        __typename: "ModelTeamMemberConnection"
        nextToken?: string | null
      } | null
      preTeamMembers?: {
        __typename: "ModelPreTeamMemberConnection"
        nextToken?: string | null
      } | null
      teamNotices?: {
        __typename: "ModelTeamNoticeConnection"
        nextToken?: string | null
      } | null
      haveOldRa9TeamNotJoin: boolean
      migrateAccountId?: number | null
      createdAt: string
      updatedAt: string
      accountJobId?: string | null
      accountSchoolId?: string | null
      accountSchoolDepartmentId?: string | null
      accountPrefectureId?: string | null
      accountCityId?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetPreAccountByCreatedYearMonthQueryVariables = {
  createdYearMonth: number
  createdDate?: ModelStringKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelPreAccountFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetPreAccountByCreatedYearMonthQuery = {
  getPreAccountByCreatedYearMonth?: {
    __typename: "ModelPreAccountConnection"
    items: Array<{
      __typename: "PreAccount"
      email: string
      createdYearMonth?: number | null
      createdDate?: string | null
      createdAt: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type GetTeamByInvitationCodeQueryVariables = {
  invitationCode: string
  sortDirection?: ModelSortDirection | null
  filter?: ModelTeamFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetTeamByInvitationCodeQuery = {
  getTeamByInvitationCode?: {
    __typename: "ModelTeamConnection"
    items: Array<{
      __typename: "Team"
      id: string
      name: string
      category?: {
        __typename: "TeamCategoryMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      activity?: {
        __typename: "TeamActivityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      prefecture?: {
        __typename: "PrefectureMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      city?: {
        __typename: "CityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
        prefectureMasterCitiesId?: string | null
      } | null
      description: string
      requirementForMemberProfile?: string | null
      customQuestions?: Array<TeamCustomQuestion> | null
      showMemberListFlg: boolean
      invitationCode?: string | null
      teamMembers?: {
        __typename: "ModelTeamMemberConnection"
        nextToken?: string | null
      } | null
      preTeamMembers?: {
        __typename: "ModelPreTeamMemberConnection"
        nextToken?: string | null
      } | null
      invitationEmailStatuses?: {
        __typename: "ModelInvitationEmailStatusConnection"
        nextToken?: string | null
      } | null
      groups?: {
        __typename: "ModelGroupConnection"
        nextToken?: string | null
      } | null
      matchingOffers?: {
        __typename: "ModelMatchingOfferConnection"
        nextToken?: string | null
      } | null
      matchingOfferDrafts?: {
        __typename: "ModelMatchingOfferDraftConnection"
        nextToken?: string | null
      } | null
      matchingApplications?: {
        __typename: "ModelMatchingApplicationConnection"
        nextToken?: string | null
      } | null
      blockingMatchingTeams?: {
        __typename: "ModelBlockingMatchingTeamConnection"
        nextToken?: string | null
      } | null
      paidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      blockingMatchingTeamsByBlockingTeamId?: {
        __typename: "ModelBlockingMatchingTeamConnection"
        nextToken?: string | null
      } | null
      migrationId?: number | null
      paidFunctionEnabled?: boolean | null
      showAdsFlg?: boolean | null
      createdAt: string
      updatedAt: string
      teamCategoryId?: string | null
      teamActivityId?: string | null
      teamPrefectureId?: string | null
      teamCityId?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetTeamByMigrationIdQueryVariables = {
  migrationId: number
  sortDirection?: ModelSortDirection | null
  filter?: ModelTeamFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetTeamByMigrationIdQuery = {
  getTeamByMigrationId?: {
    __typename: "ModelTeamConnection"
    items: Array<{
      __typename: "Team"
      id: string
      name: string
      category?: {
        __typename: "TeamCategoryMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      activity?: {
        __typename: "TeamActivityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      prefecture?: {
        __typename: "PrefectureMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
      } | null
      city?: {
        __typename: "CityMaster"
        id: string
        label: string
        displayNumber: number
        displayFlg: number
        createdAt: string
        updatedAt: string
        prefectureMasterCitiesId?: string | null
      } | null
      description: string
      requirementForMemberProfile?: string | null
      customQuestions?: Array<TeamCustomQuestion> | null
      showMemberListFlg: boolean
      invitationCode?: string | null
      teamMembers?: {
        __typename: "ModelTeamMemberConnection"
        nextToken?: string | null
      } | null
      preTeamMembers?: {
        __typename: "ModelPreTeamMemberConnection"
        nextToken?: string | null
      } | null
      invitationEmailStatuses?: {
        __typename: "ModelInvitationEmailStatusConnection"
        nextToken?: string | null
      } | null
      groups?: {
        __typename: "ModelGroupConnection"
        nextToken?: string | null
      } | null
      matchingOffers?: {
        __typename: "ModelMatchingOfferConnection"
        nextToken?: string | null
      } | null
      matchingOfferDrafts?: {
        __typename: "ModelMatchingOfferDraftConnection"
        nextToken?: string | null
      } | null
      matchingApplications?: {
        __typename: "ModelMatchingApplicationConnection"
        nextToken?: string | null
      } | null
      blockingMatchingTeams?: {
        __typename: "ModelBlockingMatchingTeamConnection"
        nextToken?: string | null
      } | null
      paidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      blockingMatchingTeamsByBlockingTeamId?: {
        __typename: "ModelBlockingMatchingTeamConnection"
        nextToken?: string | null
      } | null
      migrationId?: number | null
      paidFunctionEnabled?: boolean | null
      showAdsFlg?: boolean | null
      createdAt: string
      updatedAt: string
      teamCategoryId?: string | null
      teamActivityId?: string | null
      teamPrefectureId?: string | null
      teamCityId?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetVolatileInvitationIdByCreatedYearMonthQueryVariables = {
  createdYearMonth: number
  createdDate?: ModelStringKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelVolatileInvitationIdFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetVolatileInvitationIdByCreatedYearMonthQuery = {
  getVolatileInvitationIdByCreatedYearMonth?: {
    __typename: "ModelVolatileInvitationIdConnection"
    items: Array<{
      __typename: "VolatileInvitationId"
      id: string
      invitationCode: string
      createdYearMonth?: number | null
      createdDate?: string | null
      createdAt: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type GetInvitationEmailStatusByStatusQueryVariables = {
  status: EmailStatus
  repicaSentDate?: ModelStringKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelInvitationEmailStatusFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetInvitationEmailStatusByStatusQuery = {
  getInvitationEmailStatusByStatus?: {
    __typename: "ModelInvitationEmailStatusConnection"
    items: Array<{
      __typename: "InvitationEmailStatus"
      id: string
      email: string
      status: EmailStatus
      repicaDeliverId: string
      repicaKeyField: string
      repicaSentDate: string
      errorMessage?: string | null
      createdAt: string
      teamInvitationEmailStatusesId: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type GetInvitationEmailStatusByRepicaKeyFieldQueryVariables = {
  repicaKeyField: string
  sortDirection?: ModelSortDirection | null
  filter?: ModelInvitationEmailStatusFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetInvitationEmailStatusByRepicaKeyFieldQuery = {
  getInvitationEmailStatusByRepicaKeyField?: {
    __typename: "ModelInvitationEmailStatusConnection"
    items: Array<{
      __typename: "InvitationEmailStatus"
      id: string
      email: string
      status: EmailStatus
      repicaDeliverId: string
      repicaKeyField: string
      repicaSentDate: string
      errorMessage?: string | null
      createdAt: string
      teamInvitationEmailStatusesId: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type GetTeamNoticeByCreatedYearMonthQueryVariables = {
  createdYearMonth: number
  createdDate?: ModelStringKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelTeamNoticeFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetTeamNoticeByCreatedYearMonthQuery = {
  getTeamNoticeByCreatedYearMonth?: {
    __typename: "ModelTeamNoticeConnection"
    items: Array<{
      __typename: "TeamNotice"
      id: string
      noticeType: string
      title: string
      readFlg: boolean
      url: string
      team?: {
        __typename: "Team"
        id: string
        name: string
        description: string
        requirementForMemberProfile?: string | null
        customQuestions?: Array<TeamCustomQuestion> | null
        showMemberListFlg: boolean
        invitationCode?: string | null
        migrationId?: number | null
        paidFunctionEnabled?: boolean | null
        showAdsFlg?: boolean | null
        createdAt: string
        updatedAt: string
        teamCategoryId?: string | null
        teamActivityId?: string | null
        teamPrefectureId?: string | null
        teamCityId?: string | null
      } | null
      createdAt: string
      createdYearMonth?: number | null
      createdDate?: string | null
      accountTeamNoticesId: string
      updatedAt: string
      teamMemberTeamNoticeId?: string | null
      teamNoticeTeamId?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetTeamMemberByTeamIdGsiQueryVariables = {
  teamIdGsi: string
  role?: ModelStringKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelTeamMemberFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetTeamMemberByTeamIdGsiQuery = {
  getTeamMemberByTeamIdGsi?: {
    __typename: "ModelTeamMemberConnection"
    items: Array<{
      __typename: "TeamMember"
      id: string
      memberSeq: number
      nickname: string
      nicknameKana: string
      role: string
      showEmailFlg?: boolean | null
      phoneNumber?: string | null
      showPhoneNumberFlg?: boolean | null
      belongs?: string | null
      showBelongsFlg?: boolean | null
      grade?: number | null
      account?: {
        __typename: "Account"
        id: string
        email: string
        lastName: string
        firstName: string
        lastNameKana: string
        firstNameKana: string
        phoneNumber?: string | null
        gender?: Gender | null
        birthday?: string | null
        schoolGraduationYear?: number | null
        termsAgreedAt?: string | null
        agreedTermsVersion?: string | null
        matchingTermsAgreedAt?: string | null
        agreedMatchingTermsVersion?: string | null
        signupStatus: SignupStatus
        haveOldRa9TeamNotJoin: boolean
        migrateAccountId?: number | null
        createdAt: string
        updatedAt: string
        accountJobId?: string | null
        accountSchoolId?: string | null
        accountSchoolDepartmentId?: string | null
        accountPrefectureId?: string | null
        accountCityId?: string | null
      } | null
      team?: {
        __typename: "Team"
        id: string
        name: string
        description: string
        requirementForMemberProfile?: string | null
        customQuestions?: Array<TeamCustomQuestion> | null
        showMemberListFlg: boolean
        invitationCode?: string | null
        migrationId?: number | null
        paidFunctionEnabled?: boolean | null
        showAdsFlg?: boolean | null
        createdAt: string
        updatedAt: string
        teamCategoryId?: string | null
        teamActivityId?: string | null
        teamPrefectureId?: string | null
        teamCityId?: string | null
      } | null
      teamIdGsi: string
      teamTeamMembersId?: string | null
      notificationSetting?: {
        __typename: "NotificationSetting"
        id: string
        createdAt: string
        updatedAt: string
      } | null
      teamNotice?: {
        __typename: "ModelTeamNoticeConnection"
        nextToken?: string | null
      } | null
      groups?: {
        __typename: "ModelGroupMemberConnection"
        nextToken?: string | null
      } | null
      teamMemberMails?: {
        __typename: "ModelTeamMemberMailConnection"
        nextToken?: string | null
      } | null
      reservedMails?: {
        __typename: "ModelReservedMailConnection"
        nextToken?: string | null
      } | null
      drafts?: {
        __typename: "ModelDraftConnection"
        nextToken?: string | null
      } | null
      templates?: {
        __typename: "ModelTemplateConnection"
        nextToken?: string | null
      } | null
      migratedMails?: {
        __typename: "ModelMigratedMailConnection"
        nextToken?: string | null
      } | null
      mailMigratedFlg?: boolean | null
      favoriteMatchingOffer?: {
        __typename: "ModelFavoriteMatchingOfferConnection"
        nextToken?: string | null
      } | null
      deleteFlg?: boolean | null
      accountTeamMembersId: string
      migrateTeamMemberId?: number | null
      orderHistories?: {
        __typename: "ModelOrderHistoryConnection"
        nextToken?: string | null
      } | null
      paidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      transferPaidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
      teamMemberNotificationSettingId?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetTeamMemberByMigrateTeamMemberIdQueryVariables = {
  migrateTeamMemberId: number
  sortDirection?: ModelSortDirection | null
  filter?: ModelTeamMemberFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetTeamMemberByMigrateTeamMemberIdQuery = {
  getTeamMemberByMigrateTeamMemberId?: {
    __typename: "ModelTeamMemberConnection"
    items: Array<{
      __typename: "TeamMember"
      id: string
      memberSeq: number
      nickname: string
      nicknameKana: string
      role: string
      showEmailFlg?: boolean | null
      phoneNumber?: string | null
      showPhoneNumberFlg?: boolean | null
      belongs?: string | null
      showBelongsFlg?: boolean | null
      grade?: number | null
      account?: {
        __typename: "Account"
        id: string
        email: string
        lastName: string
        firstName: string
        lastNameKana: string
        firstNameKana: string
        phoneNumber?: string | null
        gender?: Gender | null
        birthday?: string | null
        schoolGraduationYear?: number | null
        termsAgreedAt?: string | null
        agreedTermsVersion?: string | null
        matchingTermsAgreedAt?: string | null
        agreedMatchingTermsVersion?: string | null
        signupStatus: SignupStatus
        haveOldRa9TeamNotJoin: boolean
        migrateAccountId?: number | null
        createdAt: string
        updatedAt: string
        accountJobId?: string | null
        accountSchoolId?: string | null
        accountSchoolDepartmentId?: string | null
        accountPrefectureId?: string | null
        accountCityId?: string | null
      } | null
      team?: {
        __typename: "Team"
        id: string
        name: string
        description: string
        requirementForMemberProfile?: string | null
        customQuestions?: Array<TeamCustomQuestion> | null
        showMemberListFlg: boolean
        invitationCode?: string | null
        migrationId?: number | null
        paidFunctionEnabled?: boolean | null
        showAdsFlg?: boolean | null
        createdAt: string
        updatedAt: string
        teamCategoryId?: string | null
        teamActivityId?: string | null
        teamPrefectureId?: string | null
        teamCityId?: string | null
      } | null
      teamIdGsi: string
      teamTeamMembersId?: string | null
      notificationSetting?: {
        __typename: "NotificationSetting"
        id: string
        createdAt: string
        updatedAt: string
      } | null
      teamNotice?: {
        __typename: "ModelTeamNoticeConnection"
        nextToken?: string | null
      } | null
      groups?: {
        __typename: "ModelGroupMemberConnection"
        nextToken?: string | null
      } | null
      teamMemberMails?: {
        __typename: "ModelTeamMemberMailConnection"
        nextToken?: string | null
      } | null
      reservedMails?: {
        __typename: "ModelReservedMailConnection"
        nextToken?: string | null
      } | null
      drafts?: {
        __typename: "ModelDraftConnection"
        nextToken?: string | null
      } | null
      templates?: {
        __typename: "ModelTemplateConnection"
        nextToken?: string | null
      } | null
      migratedMails?: {
        __typename: "ModelMigratedMailConnection"
        nextToken?: string | null
      } | null
      mailMigratedFlg?: boolean | null
      favoriteMatchingOffer?: {
        __typename: "ModelFavoriteMatchingOfferConnection"
        nextToken?: string | null
      } | null
      deleteFlg?: boolean | null
      accountTeamMembersId: string
      migrateTeamMemberId?: number | null
      orderHistories?: {
        __typename: "ModelOrderHistoryConnection"
        nextToken?: string | null
      } | null
      paidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      transferPaidVersionOrders?: {
        __typename: "ModelPaidVersionOrderConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
      teamMemberNotificationSettingId?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetMailByTeamIdQueryVariables = {
  teamId: string
  shareManagerFlg?: ModelIntKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelMailFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetMailByTeamIdQuery = {
  getMailByTeamId?: {
    __typename: "ModelMailConnection"
    items: Array<{
      __typename: "Mail"
      id: string
      teamId: string
      mailType: string
      title: string
      sender?: {
        __typename: "TeamMember"
        id: string
        memberSeq: number
        nickname: string
        nicknameKana: string
        role: string
        showEmailFlg?: boolean | null
        phoneNumber?: string | null
        showPhoneNumberFlg?: boolean | null
        belongs?: string | null
        showBelongsFlg?: boolean | null
        grade?: number | null
        teamIdGsi: string
        teamTeamMembersId?: string | null
        mailMigratedFlg?: boolean | null
        deleteFlg?: boolean | null
        accountTeamMembersId: string
        migrateTeamMemberId?: number | null
        createdAt: string
        updatedAt: string
        teamMemberNotificationSettingId?: string | null
      } | null
      body: string
      sendTo: {
        __typename: "SendTo"
        allMembersFlg?: boolean | null
        managersFlg?: boolean | null
        groupIds?: Array<string> | null
        memberIds?: Array<string> | null
      }
      attachmentFileNames?: Array<string> | null
      startDate?: string | null
      startTime?: string | null
      endDate?: string | null
      endTime?: string | null
      questionnaires?: Array<{
        __typename: "Questionnaires"
        answerSelectType: AnswerSelectType
        question: string
        choices: Array<string>
      }> | null
      candidateDatetimes?: Array<{
        __typename: "CandidateDatetimes"
        startDate: string
        startTime?: string | null
        endDate?: string | null
        endTime?: string | null
      }> | null
      shareManagerFlg?: number | null
      showAnswerFlg?: boolean | null
      responseDueDatetime?: string | null
      remindDate: string
      remindDatetime?: string | null
      remindExecDatetime?: string | null
      sendScheduledAt?: string | null
      sentAt: string
      teamMemberMails?: {
        __typename: "ModelTeamMemberMailConnection"
        nextToken?: string | null
      } | null
      threads?: {
        __typename: "ModelThreadConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
      mailSenderId?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetMailByRemindDateQueryVariables = {
  remindDate: string
  sortDirection?: ModelSortDirection | null
  filter?: ModelMailFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetMailByRemindDateQuery = {
  getMailByRemindDate?: {
    __typename: "ModelMailConnection"
    items: Array<{
      __typename: "Mail"
      id: string
      teamId: string
      mailType: string
      title: string
      sender?: {
        __typename: "TeamMember"
        id: string
        memberSeq: number
        nickname: string
        nicknameKana: string
        role: string
        showEmailFlg?: boolean | null
        phoneNumber?: string | null
        showPhoneNumberFlg?: boolean | null
        belongs?: string | null
        showBelongsFlg?: boolean | null
        grade?: number | null
        teamIdGsi: string
        teamTeamMembersId?: string | null
        mailMigratedFlg?: boolean | null
        deleteFlg?: boolean | null
        accountTeamMembersId: string
        migrateTeamMemberId?: number | null
        createdAt: string
        updatedAt: string
        teamMemberNotificationSettingId?: string | null
      } | null
      body: string
      sendTo: {
        __typename: "SendTo"
        allMembersFlg?: boolean | null
        managersFlg?: boolean | null
        groupIds?: Array<string> | null
        memberIds?: Array<string> | null
      }
      attachmentFileNames?: Array<string> | null
      startDate?: string | null
      startTime?: string | null
      endDate?: string | null
      endTime?: string | null
      questionnaires?: Array<{
        __typename: "Questionnaires"
        answerSelectType: AnswerSelectType
        question: string
        choices: Array<string>
      }> | null
      candidateDatetimes?: Array<{
        __typename: "CandidateDatetimes"
        startDate: string
        startTime?: string | null
        endDate?: string | null
        endTime?: string | null
      }> | null
      shareManagerFlg?: number | null
      showAnswerFlg?: boolean | null
      responseDueDatetime?: string | null
      remindDate: string
      remindDatetime?: string | null
      remindExecDatetime?: string | null
      sendScheduledAt?: string | null
      sentAt: string
      teamMemberMails?: {
        __typename: "ModelTeamMemberMailConnection"
        nextToken?: string | null
      } | null
      threads?: {
        __typename: "ModelThreadConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
      mailSenderId?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetTeamMemberMailByCreatedYearMonthQueryVariables = {
  createdYearMonth: number
  createdDate?: ModelStringKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelTeamMemberMailFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetTeamMemberMailByCreatedYearMonthQuery = {
  getTeamMemberMailByCreatedYearMonth?: {
    __typename: "ModelTeamMemberMailConnection"
    items: Array<{
      __typename: "TeamMemberMail"
      id: string
      receivedFlg: boolean
      sentFlg: boolean
      readFlg: boolean
      readAt?: string | null
      favoriteFlgReceived: boolean
      favoriteFlgSent: boolean
      answerRequiredFlg: boolean
      responseDueDatetimeForFilter: string
      answeredFlg: boolean
      answeredAt?: string | null
      attendanceAnswer?: YesNoAnswer | null
      questionnaireAnswers?: Array<{
        __typename: "QuestionnaireAnswers"
        answer: Array<number>
      }> | null
      scheduleAnswers?: Array<YesNoAnswer> | null
      senderId: string
      mailType: string
      title: string
      body: string
      hasAttachment: boolean
      hasThread: boolean
      trashedFlgReceived: boolean
      deletedFlgReceived: boolean
      deletedFlgSent: boolean
      count: number
      teamMemberID?: string | null
      mailID?: string | null
      teamMember?: {
        __typename: "TeamMember"
        id: string
        memberSeq: number
        nickname: string
        nicknameKana: string
        role: string
        showEmailFlg?: boolean | null
        phoneNumber?: string | null
        showPhoneNumberFlg?: boolean | null
        belongs?: string | null
        showBelongsFlg?: boolean | null
        grade?: number | null
        teamIdGsi: string
        teamTeamMembersId?: string | null
        mailMigratedFlg?: boolean | null
        deleteFlg?: boolean | null
        accountTeamMembersId: string
        migrateTeamMemberId?: number | null
        createdAt: string
        updatedAt: string
        teamMemberNotificationSettingId?: string | null
      } | null
      mail?: {
        __typename: "Mail"
        id: string
        teamId: string
        mailType: string
        title: string
        body: string
        attachmentFileNames?: Array<string> | null
        startDate?: string | null
        startTime?: string | null
        endDate?: string | null
        endTime?: string | null
        shareManagerFlg?: number | null
        showAnswerFlg?: boolean | null
        responseDueDatetime?: string | null
        remindDate: string
        remindDatetime?: string | null
        remindExecDatetime?: string | null
        sendScheduledAt?: string | null
        sentAt: string
        createdAt: string
        updatedAt: string
        mailSenderId?: string | null
      } | null
      createdYearMonth?: number | null
      createdDate?: string | null
      createdAt: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type GetReservedMailBySendScheduledDateQueryVariables = {
  sendScheduledDate: string
  sendScheduledAt?: ModelStringKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelReservedMailFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetReservedMailBySendScheduledDateQuery = {
  getReservedMailBySendScheduledDate?: {
    __typename: "ModelReservedMailConnection"
    items: Array<{
      __typename: "ReservedMail"
      id: string
      teamId: string
      mailType: string
      title: string
      teamMember?: {
        __typename: "TeamMember"
        id: string
        memberSeq: number
        nickname: string
        nicknameKana: string
        role: string
        showEmailFlg?: boolean | null
        phoneNumber?: string | null
        showPhoneNumberFlg?: boolean | null
        belongs?: string | null
        showBelongsFlg?: boolean | null
        grade?: number | null
        teamIdGsi: string
        teamTeamMembersId?: string | null
        mailMigratedFlg?: boolean | null
        deleteFlg?: boolean | null
        accountTeamMembersId: string
        migrateTeamMemberId?: number | null
        createdAt: string
        updatedAt: string
        teamMemberNotificationSettingId?: string | null
      } | null
      body: string
      sendTo: {
        __typename: "SendTo"
        allMembersFlg?: boolean | null
        managersFlg?: boolean | null
        groupIds?: Array<string> | null
        memberIds?: Array<string> | null
      }
      attachmentFileNames?: Array<string> | null
      startDate?: string | null
      startTime?: string | null
      endDate?: string | null
      endTime?: string | null
      questionnaires?: Array<{
        __typename: "Questionnaires"
        answerSelectType: AnswerSelectType
        question: string
        choices: Array<string>
      }> | null
      candidateDatetimes?: Array<{
        __typename: "CandidateDatetimes"
        startDate: string
        startTime?: string | null
        endDate?: string | null
        endTime?: string | null
      }> | null
      shareManagerFlg?: number | null
      showAnswerFlg?: boolean | null
      responseDueDatetime?: string | null
      remindDatetime?: string | null
      sendScheduledDate: string
      sendScheduledAt: string
      createdAt: string
      updatedAt: string
      teamMemberReservedMailsId?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetThreadMessageByCreatedYearMonthQueryVariables = {
  createdYearMonth: number
  createdDate?: ModelStringKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelThreadMessageFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetThreadMessageByCreatedYearMonthQuery = {
  getThreadMessageByCreatedYearMonth?: {
    __typename: "ModelThreadMessageConnection"
    items: Array<{
      __typename: "ThreadMessage"
      id: string
      messageSenderId: string
      messageRecipientAccountId: string
      teamId: string
      mailId: string
      text?: string | null
      unreadFlg: number
      deleteFlg?: boolean | null
      contentUpdatedAt: string
      createdAt: string
      createdYearMonth?: number | null
      createdDate?: string | null
      threadMessagesId: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type GetSchoolMasterByTypeQueryVariables = {
  type: string
  sortDirection?: ModelSortDirection | null
  filter?: ModelSchoolMasterFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetSchoolMasterByTypeQuery = {
  getSchoolMasterByType?: {
    __typename: "ModelSchoolMasterConnection"
    items: Array<{
      __typename: "SchoolMaster"
      id: string
      label: string
      labelKana: string
      type: string
      code?: string | null
      displayNumber: number
      displayFlg: number
      departments?: {
        __typename: "ModelSchoolDepartmentMasterConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type GetTeamMemberPresetByEmailQueryVariables = {
  email: string
  teamId?: ModelIDKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelTeamMemberPresetFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetTeamMemberPresetByEmailQuery = {
  getTeamMemberPresetByEmail?: {
    __typename: "ModelTeamMemberPresetConnection"
    items: Array<{
      __typename: "TeamMemberPreset"
      teamId: string
      email: string
      memberSeq: number
      nickname: string
      nicknameKana?: string | null
      role: MemberRole
      showEmailFlg?: boolean | null
      phoneNumber?: string | null
      showPhoneNumberFlg?: boolean | null
      belongs?: string | null
      showBelongsFlg?: boolean | null
      grade?: number | null
      groups?: Array<{
        __typename: "Groups"
        id: string
        leaderFlg: boolean
      }> | null
      createdYearMonth?: number | null
      createdDate?: string | null
      migrateTeamMemberId?: number | null
      createdAt: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type GetTeamMemberPresetByCreatedYearMonthQueryVariables = {
  createdYearMonth: number
  createdDate?: ModelStringKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelTeamMemberPresetFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetTeamMemberPresetByCreatedYearMonthQuery = {
  getTeamMemberPresetByCreatedYearMonth?: {
    __typename: "ModelTeamMemberPresetConnection"
    items: Array<{
      __typename: "TeamMemberPreset"
      teamId: string
      email: string
      memberSeq: number
      nickname: string
      nicknameKana?: string | null
      role: MemberRole
      showEmailFlg?: boolean | null
      phoneNumber?: string | null
      showPhoneNumberFlg?: boolean | null
      belongs?: string | null
      showBelongsFlg?: boolean | null
      grade?: number | null
      groups?: Array<{
        __typename: "Groups"
        id: string
        leaderFlg: boolean
      }> | null
      createdYearMonth?: number | null
      createdDate?: string | null
      migrateTeamMemberId?: number | null
      createdAt: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type GetTeamMemberPresetByMigrateTeamMemberIdQueryVariables = {
  migrateTeamMemberId: number
  sortDirection?: ModelSortDirection | null
  filter?: ModelTeamMemberPresetFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetTeamMemberPresetByMigrateTeamMemberIdQuery = {
  getTeamMemberPresetByMigrateTeamMemberId?: {
    __typename: "ModelTeamMemberPresetConnection"
    items: Array<{
      __typename: "TeamMemberPreset"
      teamId: string
      email: string
      memberSeq: number
      nickname: string
      nicknameKana?: string | null
      role: MemberRole
      showEmailFlg?: boolean | null
      phoneNumber?: string | null
      showPhoneNumberFlg?: boolean | null
      belongs?: string | null
      showBelongsFlg?: boolean | null
      grade?: number | null
      groups?: Array<{
        __typename: "Groups"
        id: string
        leaderFlg: boolean
      }> | null
      createdYearMonth?: number | null
      createdDate?: string | null
      migrateTeamMemberId?: number | null
      createdAt: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type GetMatchingMessageByApplicationIdMessageReceiverGsiQueryVariables = {
  applicationIdMessageReceiverGsi: string
  deleteFlgUnreadFlg?: ModelMatchingMessageByApplicationIdMessageReceiverGsiCompositeKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelMatchingMessageFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetMatchingMessageByApplicationIdMessageReceiverGsiQuery = {
  getMatchingMessageByApplicationIdMessageReceiverGsi?: {
    __typename: "ModelMatchingMessageConnection"
    items: Array<{
      __typename: "MatchingMessage"
      id: string
      messageSenderTeamId: string
      text?: string | null
      unreadFlg: number
      deleteFlg: number
      contentUpdatedAt: string
      createdAtForSort: string
      createdAtForSortIncludeDeleted: string
      applicationIdMessageReceiverGsi: string
      matchingApplicationMessagesId: string
      matchingApplicationMessagesIncludeDeletedId: string
      createdAt: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type GetBlockingMatchingTeamByBlockingTeamIdQueryVariables = {
  blockingTeamId: string
  sortDirection?: ModelSortDirection | null
  filter?: ModelBlockingMatchingTeamFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetBlockingMatchingTeamByBlockingTeamIdQuery = {
  getBlockingMatchingTeamByBlockingTeamId?: {
    __typename: "ModelBlockingMatchingTeamConnection"
    items: Array<{
      __typename: "BlockingMatchingTeam"
      id: string
      blockingTeamId: string
      createdAt: string
      updatedAt: string
      teamBlockingMatchingTeamsId?: string | null
    } | null>
    nextToken?: string | null
  } | null
}

export type GetReportMatchingTeamLogByReporterTeamIdQueryVariables = {
  reporterTeamId: string
  sortDirection?: ModelSortDirection | null
  filter?: ModelReportMatchingTeamLogFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetReportMatchingTeamLogByReporterTeamIdQuery = {
  getReportMatchingTeamLogByReporterTeamId?: {
    __typename: "ModelReportMatchingTeamLogConnection"
    items: Array<{
      __typename: "ReportMatchingTeamLog"
      id: string
      reporterAccountId: string
      reporterTeamId: string
      teamId: string
      content?: string | null
      createdAt: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type GetReportMatchingTeamLogByTeamIdQueryVariables = {
  teamId: string
  sortDirection?: ModelSortDirection | null
  filter?: ModelReportMatchingTeamLogFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetReportMatchingTeamLogByTeamIdQuery = {
  getReportMatchingTeamLogByTeamId?: {
    __typename: "ModelReportMatchingTeamLogConnection"
    items: Array<{
      __typename: "ReportMatchingTeamLog"
      id: string
      reporterAccountId: string
      reporterTeamId: string
      teamId: string
      content?: string | null
      createdAt: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type GetAppVersionByOsTypeQueryVariables = {
  osType: OsType
  releaseAt?: ModelStringKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelAppVersionFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetAppVersionByOsTypeQuery = {
  getAppVersionByOsType?: {
    __typename: "ModelAppVersionConnection"
    items: Array<{
      __typename: "AppVersion"
      version: string
      osType: OsType
      osVersion: string
      releaseAt: string
      content: string
      createdAt: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type GetTermsByValidFlgQueryVariables = {
  validFlg: number
  releaseAt?: ModelStringKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelTermsFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetTermsByValidFlgQuery = {
  getTermsByValidFlg?: {
    __typename: "ModelTermsConnection"
    items: Array<{
      __typename: "Terms"
      version: string
      type: TermsType
      validFlg: number
      url: string
      releaseAt: string
      createdAt: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type GetTempTokenByCreatedYearMonthQueryVariables = {
  createdYearMonth: number
  createdDate?: ModelStringKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelTempTokenFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetTempTokenByCreatedYearMonthQuery = {
  getTempTokenByCreatedYearMonth?: {
    __typename: "ModelTempTokenConnection"
    items: Array<{
      __typename: "TempToken"
      tempToken: string
      accessToken: string
      createdYearMonth?: number | null
      createdDate?: string | null
      createdAt: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type GetS3AttachmentPathByExpiredDateQueryVariables = {
  expiredDate: string
  sortDirection?: ModelSortDirection | null
  filter?: ModelExpiredAttachmentFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetS3AttachmentPathByExpiredDateQuery = {
  getS3AttachmentPathByExpiredDate?: {
    __typename: "ModelExpiredAttachmentConnection"
    items: Array<{
      __typename: "ExpiredAttachment"
      id: string
      expiredDate: string
      s3Path: string
      createdAt: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type GetAccountAdminNoticeByAdminNoticeQueryVariables = {
  adminNoticeId: string
  accountId?: ModelIDKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelAccountAdminNoticeFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetAccountAdminNoticeByAdminNoticeQuery = {
  getAccountAdminNoticeByAdminNotice?: {
    __typename: "ModelAccountAdminNoticeConnection"
    items: Array<{
      __typename: "AccountAdminNotice"
      id: string
      adminNoticeId: string
      accountId: string
      readFlg: boolean
      createdAt: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type GetAccountAdminNoticeByAccountIdQueryVariables = {
  accountId: string
  adminNoticeId?: ModelIDKeyConditionInput | null
  sortDirection?: ModelSortDirection | null
  filter?: ModelAccountAdminNoticeFilterInput | null
  limit?: number | null
  nextToken?: string | null
}

export type GetAccountAdminNoticeByAccountIdQuery = {
  getAccountAdminNoticeByAccountId?: {
    __typename: "ModelAccountAdminNoticeConnection"
    items: Array<{
      __typename: "AccountAdminNotice"
      id: string
      adminNoticeId: string
      accountId: string
      readFlg: boolean
      createdAt: string
      updatedAt: string
    } | null>
    nextToken?: string | null
  } | null
}

export type OnCreateTeamCategoryActivitySubscription = {
  onCreateTeamCategoryActivity?: {
    __typename: "TeamCategoryActivity"
    id: string
    teamCategoryMasterID: string
    teamActivityMasterID: string
    teamCategoryMaster: {
      __typename: "TeamCategoryMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      activities?: {
        __typename: "ModelTeamCategoryActivityConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
    }
    teamActivityMaster: {
      __typename: "TeamActivityMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      categories?: {
        __typename: "ModelTeamCategoryActivityConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
    }
    createdAt: string
    updatedAt: string
  } | null
}

export type OnUpdateTeamCategoryActivitySubscription = {
  onUpdateTeamCategoryActivity?: {
    __typename: "TeamCategoryActivity"
    id: string
    teamCategoryMasterID: string
    teamActivityMasterID: string
    teamCategoryMaster: {
      __typename: "TeamCategoryMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      activities?: {
        __typename: "ModelTeamCategoryActivityConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
    }
    teamActivityMaster: {
      __typename: "TeamActivityMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      categories?: {
        __typename: "ModelTeamCategoryActivityConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
    }
    createdAt: string
    updatedAt: string
  } | null
}

export type OnDeleteTeamCategoryActivitySubscription = {
  onDeleteTeamCategoryActivity?: {
    __typename: "TeamCategoryActivity"
    id: string
    teamCategoryMasterID: string
    teamActivityMasterID: string
    teamCategoryMaster: {
      __typename: "TeamCategoryMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      activities?: {
        __typename: "ModelTeamCategoryActivityConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
    }
    teamActivityMaster: {
      __typename: "TeamActivityMaster"
      id: string
      label: string
      displayNumber: number
      displayFlg: number
      categories?: {
        __typename: "ModelTeamCategoryActivityConnection"
        nextToken?: string | null
      } | null
      createdAt: string
      updatedAt: string
    }
    createdAt: string
    updatedAt: string
  } | null
}
