import { RootStackScreenProps } from "src/types.d"
import { Screens } from "src/constants/Screens"
import { memo } from "react"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { CustomScrollView } from "src/components/parts/CustomScrollView"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { StyleSheet, Text, View } from "react-native"
import { Colors } from "src/constants/Colors"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { MultilineTextInput } from "src/components/parts/MultilineTextInput"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { commonSingleModalOptions } from "src/constants/options/commonSingleModalOptions"
import { useReportOrganizationData } from "src/recoil/hooks/screens/useReportOrganizationData"
import { InputLengthCounter } from "src/components/parts/InputLengthCounter"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"

export type ReportOrganizationParams = {
  id: string
  name: string
}

type Props = RootStackScreenProps<typeof Screens.ReportOrganization>

export const ReportOrganization = memo<Props>(
  ({
    route: {
      params: { id, name },
    },
  }) => {
    const isLargeScreen = useCheckPCScreen()
    const { reportText, setReportText, sendOrganizationReport, isOrganizationReportSending } = useReportOrganizationData({ id })

    return (
      <CustomKeyboardAvoidingView>
        <CustomScrollView>
          <View style={{ justifyContent: "center", alignItems: "center" }}>
            <View style={isLargeScreen ? { width: 600 } : { width: "100%" }}>
              <View style={styles.screenDescriptionContainer}>
                <Text style={styles.screenDescriptionText}>サービス運営会社にこの団体の不適切な連絡を報告します。</Text>
              </View>
              <View style={styles.reportContainer}>
                <View style={styles.itemLabelWrapper}>
                  <ItemLabel label="団体名" />
                </View>
                <View style={styles.organizationNameContainer}>
                  <Text style={styles.organizationNameText}>{name}</Text>
                </View>
                <View style={styles.itemLabelWrapper}>
                  <ItemLabel
                    label="通報内容"
                    RightComponent={<InputLengthCounter text={reportText} maxLength={500} unit={"字以内"} />}
                  />
                </View>
                <MultilineTextInput
                  value={reportText}
                  onChangeText={setReportText}
                  placeholder="通報内容を入力してください"
                  maxLength={500}
                  style={{ height: 240 }}
                />
                <View
                  style={[styles.reportButtonWrapper, isLargeScreen ? { justifyContent: "center", alignItems: "center" } : {}]}
                >
                  <TextButton
                    style={isLargeScreen ? { width: 300 } : {}}
                    title="通報"
                    buttonType={ButtonType.Primary}
                    onPress={sendOrganizationReport}
                    isLoading={isOrganizationReportSending}
                    disabled={isOrganizationReportSending || !reportText}
                  />
                </View>
              </View>
            </View>
          </View>
        </CustomScrollView>
      </CustomKeyboardAvoidingView>
    )
  }
)

export const reportOrganizationOptions: ScreenOptions = {
  ...commonSingleModalOptions,
  title: "通報する",
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white3,
  },
  screenDescriptionContainer: {
    paddingVertical: 26,
    paddingHorizontal: 23,
    borderBottomWidth: 1,
    borderBottomColor: Colors.lightGrey,
  },
  screenDescriptionText: {
    fontSize: 16,
    lineHeight: 24,
    color: Colors.black2,
  },
  reportContainer: {
    paddingBottom: 26,
    paddingHorizontal: 23,
  },
  itemLabelWrapper: {
    marginTop: 20,
    marginBottom: 8,
  },
  organizationNameContainer: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderRadius: 8,
    backgroundColor: Colors.lightGrey,
  },
  organizationNameText: {
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: 24,
  },
  reportButtonWrapper: {
    marginTop: 32,
  },
})
