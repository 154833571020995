import { ContactType } from "src/constants/ContactType"
import { Result } from "src/utils/Result"
import { getMail, GetMailResponse, getMigratedMail, getReservedMail, GetReservedMailResponse } from "src/aws/customAPI"
import { MailType, YesNoAnswer } from "src/aws/API"
import { Receivers } from "src/types/contact/Receivers"
import { EventDateCandidate, getEventDateCandidate } from "src/types/contact/EventDateCandidate"
import { AttendanceType } from "src/constants/AttendanceType"
import { ScheduleAnswerType } from "src/constants/ScheduleAnswerType"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

export type CommonItem = {
  contactId: string
  organizationId: string
  title: string
  body: string
  sender: {
    memberId: string
    name: string
    isDeleted: boolean
  }
  receivers: Receivers
  attachmentFileNames: string[]
  isAttachmentExists: boolean
  isAddToOtherAdminsHistory: boolean
  sentDate?: Date
  reservedDate?: Date
  isFavorite: boolean
  isRead: boolean
  isEdited: boolean
  updatedDate: Date
}

export type NormalItem = {
  contactType: typeof ContactType.Normal
}

export type AttendanceItem = {
  contactType: typeof ContactType.Attendance
  deadlineDate?: Date
  isPublishAnswersToMembers: boolean
  reminderDate?: Date
  myAnswer?: AttendanceType
}

export type SurveyItem = {
  contactType: typeof ContactType.Survey
  deadlineDate?: Date
  surveys: { id: string, question: string; choices: {value: string, id: string}[]; isMultipleSelect: boolean }[]
  isPublishAnswersToMembers: boolean
  reminderDate?: Date
  myAnswer?: { [key in string]: { [key in string]: boolean } }
}

export type ScheduleAdjustmentItem = {
  contactType: typeof ContactType.ScheduleAdjustment
  deadlineDate?: Date
  schedules: { id: string, eventDateCandidate: EventDateCandidate }[]
  isPublishAnswersToMembers: boolean
  reminderDate?: Date
  myAnswer?: ScheduleAnswerType[]
}

// 使用しない
type EventItem = {
  contactType: typeof ContactType.Event
  startDate: Date
  endDate: Date
}

// 使用しない
type SafetyItem = {
  contactType: typeof ContactType.Safety
}

export type Item = CommonItem & (NormalItem | AttendanceItem | SurveyItem | ScheduleAdjustmentItem | EventItem | SafetyItem)

type GetContactDetailRequestParams = {
  accessToken: string
  myMemberId: string
  contactId: string
  isSent: boolean
}

export type GetContactDetailRequestSuccess = Item

type GetContactDetailRequestFailure = {
  message: string
}

export const getContactDetail = async (
  {accessToken, myMemberId, contactId, isSent }: GetContactDetailRequestParams
): Promise<Result<GetContactDetailRequestSuccess, GetContactDetailRequestFailure>> => {
  return execApi(
    () => getMail(accessToken, {
      input: {
        mailId: contactId,
        memberId: myMemberId
      },
      appInfo
    }),
    res => {
      const mail = res.getMail
      return convertContact({ type: isSent ? "outbox" : "inbox", contactId, ...mail })
    },
    {
      identifiers: ["getContactDetail", "getMail"],
      defaultErrorMessage: "連絡の取得に失敗しました。",
      noDataErrorMessage: "連絡の送信が取り消されました。",
    }
  )
}

type GetReservedContactRequestParams = {
  accessToken: string
  contactId: string
}

export const getReservedContact = async (
  {accessToken, contactId}: GetReservedContactRequestParams
): Promise<Result<GetContactDetailRequestSuccess, GetContactDetailRequestFailure>> => {
  return execApi(
    () => getReservedMail(accessToken, {
      id: contactId
    }),
    res => {
      const mail = res.getReservedMail
      return convertContact({type: "reserved", contactId, ...mail})
    },
    {
      identifiers: ["getReservedContact", "getReservedMail"],
      defaultErrorMessage: "連絡の取得に失敗しました。"
    }
  )
}

type ConvertContactParam = {contactId: string} & (({ type: "inbox" | "outbox" } & GetMailResponse["getMail"]) | ({type: "reserved"} & GetReservedMailResponse["getReservedMail"]))

const convertContact = ({contactId, ...m} : ConvertContactParam): Item => {
  const commonItem: CommonItem = {
    contactId: contactId,
    organizationId: m.teamId,
    title: m.title,
    body: m.body,
    sender: {
      memberId: m.sender.id,
      name: m.sender.nickname,
      isDeleted: !!m.sender.deleteFlg,
    },
    receivers: {
      isAllMembers: m.sendTo.allMembersFlg,
      isAllLeaders: m.sendTo.managersFlg,
      groupIds: m.sendTo.groupIds,
      memberIds: m.sendTo.memberIds,
    },
    attachmentFileNames: m.attachmentFileNames,
    isAttachmentExists: m.attachmentFileNames.length > 0,
    isAddToOtherAdminsHistory: m.shareManagerFlg,
    sentDate: m.type !== "reserved" ? m.sentAt : undefined,
    reservedDate: m.type !== "outbox" ? m.sendScheduledAt : undefined,
    isEdited: m.updatedAt > m.createdAt,
    updatedDate: m.updatedAt,
    isFavorite: m.type === "outbox" ? m.favoriteFlgSent : m.favoriteFlgReceived,
    isRead: m.readFlg,
  }

  if (m.mailType === MailType.normal) {
    const item: NormalItem = {
      contactType: "Normal",
    }
    return {...commonItem, ...item}
  } else if (m.mailType === MailType.attendance) {
    const item: AttendanceItem = {
      contactType: "Attendance",
      deadlineDate: m.responseDueDatetime,
      isPublishAnswersToMembers: m.showAnswerFlg,
      reminderDate: m.remindDatetime,
      myAnswer: m.attendanceAnswer ? attendanceTypeMapping[m.attendanceAnswer] : undefined,
    }
    return {...commonItem, ...item}
  } else if (m.mailType === MailType.questionnaire) {
    const item: SurveyItem = {
      contactType: "Survey",
      deadlineDate: m.responseDueDatetime,
      surveys: m.questionnaires?.map((q, i) => ({
        id: String(i),
        question: q.question,
        choices: q.choices.map((c, i) => ({
          value: c,
          id: String(i)
        })),
        isMultipleSelect: q.answerSelectType === "multiple",
      })) || [],
      isPublishAnswersToMembers: m.showAnswerFlg,
      reminderDate: m.remindDatetime,
      myAnswer: m.questionnaireAnswers ? convertSurveyAnswers(m.questionnaireAnswers) : undefined
    }
    return {...commonItem, ...item}
  } else {
    const item: ScheduleAdjustmentItem = {
      contactType: "ScheduleAdjustment",
      deadlineDate: m.responseDueDatetime,
      schedules: m.candidateDatetimes?.map((item, i) => ({
        id: String(i),
        eventDateCandidate: convertEventCandidate(item)
      })) || [],
      isPublishAnswersToMembers: m.showAnswerFlg,
      reminderDate: m.remindDatetime,
      myAnswer: m.scheduleAnswers?.map(s => scheduleAnswerTypeMapping[s])
    }
    return {...commonItem, ...item}
  }
}

const convertEventCandidate = (candidate: {
  startDate: string
  startTime?: string | null
  endDate?: string | null
  endTime?: string | null
}): EventDateCandidate => {
  const result = getEventDateCandidate({
    startDate: new Date(candidate.startDate),
    startTime: candidate.startTime || undefined,
    endDate: candidate.endDate ? new Date(candidate.endDate) : undefined,
    endTime: candidate.endTime || undefined,
  })
  return result || {
    type: "AllDay",
    startDate: new Date(candidate.startDate)
  }
}

const attendanceTypeMapping: { [key in YesNoAnswer]: AttendanceType} = {
  [YesNoAnswer.yes]: AttendanceType.Attend,
  [YesNoAnswer.no]: AttendanceType.NonAttend,
  [YesNoAnswer.tbd]: AttendanceType.Pend,
}

const convertSurveyAnswers = (answers: {answer: number[]}[]): { [key in string]: { [key in string]: boolean } } => {
  return Object.fromEntries(answers.map((answer, i) =>
    [String(i), Object.fromEntries(answer.answer.map(a => [String(a), true]))]
  ))
}

const scheduleAnswerTypeMapping: { [key in YesNoAnswer]: ScheduleAnswerType} = {
  [YesNoAnswer.yes]: ScheduleAnswerType.Attend,
  [YesNoAnswer.no]: ScheduleAnswerType.NonAttend,
  [YesNoAnswer.tbd]: ScheduleAnswerType.Pend,
}


type GetMigrationContactRequestParams = {
  accessToken: string
  contactId: string
  organizationId: string
}

export const getMigrationContact = async (
  {accessToken, contactId, organizationId}: GetMigrationContactRequestParams
) => {
  return execApi(
    () => getMigratedMail(accessToken, {
      id: contactId
    }),
    res => {
      const mail = res.getMigratedMail
      if (mail == null) throw Error("システムエラーが発生しました。")

      return {
        contactId: contactId,
        contactType: ContactType.Normal, // 使用しない
        organizationId: organizationId,
        title: mail.title,
        body: mail.body,
        sender: {
          memberId: "", // 使用しない
          name: mail.sender,
          isDeleted: false,
        },
        receivers: {}, // 使用しない
        attachmentFileNames: [],
        isAttachmentExists: false,
        isAddToOtherAdminsHistory: mail.shareManagerFlg,
        sentDate: new Date(mail.sentDatetime),
        isFavorite: mail.favoriteFlg,
        isRead: true,
        isEdited: false,
        updatedDate: new Date(mail.updatedAt),
      }
    },
    {
      identifiers: ["getMigrationContact", "getMigratedMail"],
      defaultErrorMessage: "連絡の取得に失敗しました。"
    }
  )
}
