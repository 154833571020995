import { ComponentProps, forwardRef, memo } from "react"
import { StyleSheet, TextInput } from "react-native"
import { Colors } from "src/constants/Colors"

type Props = ComponentProps<typeof TextInput> & {
  isError?: boolean
  placeholder?: string
}

export const CustomTextInput = memo(
  forwardRef<TextInput, Props>(({ style, editable = true, isError, placeholder, ...rest }, ref) => {
    return (
      <TextInput
        style={[styles.input, editable ? styles.active : styles.disabled, isError ? styles.error : null, style]}
        ref={ref}
        placeholder={placeholder || "入力してください"}
        placeholderTextColor={"#d3d3d3"}
        editable={editable}
        {...rest}
      />
    )
  })
)

const styles = StyleSheet.create({
  input: {
    paddingHorizontal: 8,
    borderWidth: 2,
    height: 50,
    borderRadius: 10,
    fontSize: 16,
  },
  active: {
    borderColor: Colors.lightGrey,
    backgroundColor: Colors.white3,
    color: Colors.greyshBrown,
  },
  disabled: {
    backgroundColor: "#fcfaf8",
    borderColor: "#f1efeb",
    color: "#dddbd8",
  },
  error: {
    borderColor: "#FF0000",
  },
})
