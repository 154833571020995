import { Result } from "src/utils/Result"
import { updateMail } from "src/aws/customAPI"
import { removeUndefined } from "src/utils/removeUndefined"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type UpdateContactRequestParams = {
  accessToken: string
  contactId: string
  title?: string
  body?: string
}

type UpdateContactSuccess = unknown
type UpdateContactFailure = {
  message: string
}

export const updateContact = (params: UpdateContactRequestParams): Promise<Result<UpdateContactSuccess, UpdateContactFailure>> => {
  const {accessToken, contactId, title, body} = params

  return execApi(
    () => updateMail(accessToken, {
      input: {
        id: contactId,
        ...removeUndefined({
          title: title,
          body: body,
        })
      },
      appInfo
    }),
    _ => undefined,
    {
      identifiers: ["updateContact", "updateMail"]
    }
  )
}