import dayjs from "dayjs"
import "react-native-get-random-values"
import { Fragment, memo, useCallback, useEffect, useMemo, useState } from "react"
import { ActivityIndicator, Image, Linking, Modal, StyleSheet, Text, View } from "react-native"
import { AttendanceRadioButton } from "src/components/parts/AttendanceRadioButton"
import { BottomSheet } from "src/components/parts/BottomSheet"
import { SimpleListBottomSheetInner } from "src/components/parts/bottomSheetInner/SimpleListBottomSheetInner"
import { Button } from "src/components/parts/buttons/Button"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { CircleThumbnail } from "src/components/parts/CircleThumbnail"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { CustomScrollView } from "src/components/parts/CustomScrollView"
import { AttachmentIcon } from "src/components/parts/icons/AttachmentIcon"
import { FavoriteIcon } from "src/components/parts/icons/FavoriteIcon"
import { HamburgerIcon } from "src/components/parts/icons/HamburgerIcon"
import { NoPersonIcon } from "src/components/parts/icons/NoPersonIcon"
import { QuestionWithCircleIcon } from "src/components/parts/icons/QuestionWithCircleIcon"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { TimeLimitLabel } from "src/components/parts/TimeLimitLabel"
import { AttendanceLabel, AttendanceType } from "src/constants/AttendanceType"
import { Colors } from "src/constants/Colors"
import { ContactAttendanceAnswerScreens } from "src/constants/ContactAttendanceAnswerScreens"
import { ContactScheduleAnswerScreens } from "src/constants/ContactScheduleAnswerScreens"
import { ContactSurveyAnswerScreens } from "src/constants/ContactSurveyAnswerScreens"
import { ContactType, ContactTypeLabel } from "src/constants/ContactType"
import { CreateNewContactScreens } from "src/constants/CreateNewContactScreens"
import { commonHeaderOptionsOnNestedScreen } from "src/constants/options/commonHeaderOptions"
import { ScheduleAnswerCSVLabel, ScheduleAnswerLabel, ScheduleAnswerType } from "src/constants/ScheduleAnswerType"
import { Screens } from "src/constants/Screens"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { useResource } from "src/recoil/hooks/resource/useResource"
import { useContactDetailData } from "src/recoil/hooks/screens/useContactDetailData"
import { ContactReplyThreadOverview } from "src/recoil/selectors/contact/contactReplyThreadsSelectorFamily"
import { RootStackScreenProps, ValueOf } from "src/types.d"
import { formatCandidate } from "src/types/contact/EventDateCandidate"
import { wait } from "src/utils/wait"
import { useRefresher } from "src/hooks/useRefresher"
import { inboxContactsRequestIdState } from "src/recoil/atoms/contact/inboxContactsRequestIdState"
import { notNull } from "src/utils/typeguards"
import { useBottomTabBadgeData } from "src/recoil/hooks/bottomTab/useBottomTabBadgeData"
import { useResetRecoilState, useSetRecoilState } from "recoil"
import { selectedMyOrganizationState } from "src/recoil/atoms/organization/selectedMyOrganizationState"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { AttachmentViewer } from "src/components/parts/contactNetworkTab/AttachmentViewer"
import { inboxContactFavoriteFlgsCacheState } from "src/recoil/atoms/contact/contactFavoriteFlgsCacheState"
import { BannerAd } from "src/tags/ads/BannerAd"
import { Analytics } from "src/tags/analytics/Analytics"
import { RootTabs } from "src/constants/RootTabs"
import { ContactNetworkDrawers } from "src/constants/ContactNetworkDrawers"
import { adLoadState, isLoadDoneAd } from "src/recoil/atoms/ads/adState"
import { useRecoilState } from "recoil"
import { defaultThumbnail } from "src/constants/defaultThumbnail"
import { useHomeTabData } from "src/recoil/hooks/bottomTab/useHomeTabData"
import { ClockWithCircleIcon } from "src/components/parts/icons/ClockWithCircleIcon"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { Platform } from "react-native"
import { useCheckAllowAccountPermission } from "src/recoil/hooks/screens/useCheckAllowAccountPermission"
import Hyperlink from "react-native-hyperlink"
import { DownloadSVG } from "src/screens/organization/OrganizationMembers"
import { RemoveIcon } from "src/components/parts/icons/RemoveIcon"
import { ruleURL } from "src/constants/links/rule"
import { UseAnsweredDateTime } from "src/utils/useDateTime"
import { attendanceAnswerCsvDataSelectorFamily } from "src/recoil/selectors/csv/attendanceAnswerCsvDataSelectorFamily"
import { questionnaireAnswerCsvDataSelectorFamily } from "src/recoil/selectors/csv/questionnaireAnswerCsvDataSelectorFamily"
import { scheduleAnswerCsvDataSelectorFamily } from "src/recoil/selectors/csv/scheduleAnswerCsvDataSelectorFamily"
import SelectableText from "src/components/parts/SelectableText"
import WebView from "react-native-webview"

const attendanceQuestionOptions: [
  { value: AttendanceType; label: ValueOf<typeof AttendanceLabel> },
  { value: AttendanceType; label: ValueOf<typeof AttendanceLabel> },
  { value: AttendanceType; label: ValueOf<typeof AttendanceLabel> }
] = [
  { value: AttendanceType.Attend, label: AttendanceLabel[AttendanceType.Attend] },
  { value: AttendanceType.NonAttend, label: AttendanceLabel[AttendanceType.NonAttend] },
  { value: AttendanceType.Pend, label: AttendanceLabel[AttendanceType.Pend] },
]

const scheduleQuestionOptions: [
  { value: ScheduleAnswerType; label: ValueOf<typeof ScheduleAnswerLabel> },
  { value: ScheduleAnswerType; label: ValueOf<typeof ScheduleAnswerLabel> },
  { value: ScheduleAnswerType; label: ValueOf<typeof ScheduleAnswerLabel> }
] = [
  { value: ScheduleAnswerType.Attend, label: ScheduleAnswerLabel[ScheduleAnswerType.Attend] },
  { value: ScheduleAnswerType.NonAttend, label: ScheduleAnswerLabel[ScheduleAnswerType.NonAttend] },
  { value: ScheduleAnswerType.Pend, label: ScheduleAnswerLabel[ScheduleAnswerType.Pend] },
]

export type ContactDetailScreenParams = {
  contactId: string
  organizationId?: string
  viewMode?: "Inbox" | "Outbox" | "Reserved" | "Migration"
}

type Props = RootStackScreenProps<typeof Screens.ContactDetail>

export const checkDate = (date?: Date) => {
  if (!date) return ""
  const weekday = ["日", "月", "火", "水", "木", "金", "土"]
  const nowDay = dayjs()
  const formattedDate = dayjs(date)

  if (formattedDate.isSame(nowDay, "day")) {
    return `今日${formattedDate.format(" HH:mm")}`
  } else if (formattedDate.year() === nowDay.year()) {
    return `${formattedDate.format("MM/DD")} (${weekday[formattedDate.day()]})${formattedDate.format(" HH:mm")}`
  } else {
    return `${formattedDate.format("YYYY/MM/DD")} (${weekday[formattedDate.day()]})${formattedDate.format(" HH:mm")}`
  }
}

export const ContactDetailScreen = memo<Props>(
  ({
    route: {
      params: { contactId: _contactId, organizationId: initialOrganizationId, viewMode: _viewMode },
    },
    navigation,
  }) => {
    const viewMode = _viewMode || "Inbox"
    const isLargeScreen = useCheckPCScreen()

    const { value: selectedMyOrganization } = useAsyncSelector(selectedMyOrganizationSelector)
    const setOrganizationId = useSetRecoilState(selectedMyOrganizationState)

    useEffect(() => {
      if (initialOrganizationId) setOrganizationId(initialOrganizationId)
    }, [initialOrganizationId, setOrganizationId])

    const contactId = useMemo(
      () => (selectedMyOrganization != null ? _contactId : undefined),
      [selectedMyOrganization, _contactId]
    )

    const {
      isLoading,
      value,
      organizationId,
      organizationName,
      myMemberId,
      contactReplyThreads,
      isSender,
      senderImageUrl,
      refreshSenderImage,
      isFavorite,
      receiverText,
      execUpdateContactDetailFavorite,
      execPostAttendance,
      isPostAttendanceExecuting,
      execPostSurveyAnswer,
      isPostSurveyAnswerExecuting,
      execPostScheduleAnswer,
      isPostScheduleAnswerExecuting,
      execDeleteContact,
      isDeleteContactExecuting,
      execPostContactTemplate,
      refreshContactAttendanceAnswerOverview,
      refreshContactSurveyAnswerOverview,
      refreshContactScheduleAnswerOverview,
      refreshContactReplyThread,
      refreshContactReplyThreads,
      resetCreateNewContactScreenData,
      sendReminder,
      isSendingReminder,
      receivedMembers,
      setIsSenderMenuBottomSheetOpen,
      isSenderMenuBottomSheetOpen,
      readMembers,
      unreadMembers,
      answeredMembers,
      unansweredMembers,
      refreshTeamMemberContacts,
      gotoCreateNewContactFromMigration,
      organization,
      _refreshContactReplyThreads,
      _refreshHasUnreadThreadMessage,
    } = useContactDetailData({ contactId, viewMode })

    const {
      allowViewAnswerSummary,
      allowViewRecipient,
      allowViewReadCount,
      allowViewAnsweredListMembers,
      allowViewReadListMember,
      allowViewRecipientList,
    } = useCheckAllowAccountPermission({
      contactId,
      viewMode,
      organizationId: initialOrganizationId || organizationId,
    })

    const { refreshNotifications } = useHomeTabData()
    const [adLoadStateList] = useRecoilState(adLoadState)

    let deadlineDateFormat = ""
    if (
      value &&
      (value.contactType === ContactType.Survey ||
        value.contactType === ContactType.Attendance ||
        value.contactType === ContactType.ScheduleAdjustment)
    ) {
      if (value.deadlineDate) {
        const formattedDate = dayjs(value.deadlineDate)
        const weekday = ["日", "月", "火", "水", "木", "金", "土"]
        deadlineDateFormat = `${formattedDate.format("YYYY/MM/DD")} (${weekday[formattedDate.day()]})${formattedDate.format(
          " HH:mm"
        )}`
      }
    }

    const isDownCSV = isLargeScreen && value
    const { value: answerSurveyCsvData } = useAsyncSelector(
      questionnaireAnswerCsvDataSelectorFamily({
        mailId: isDownCSV && value?.contactType === ContactType.Survey ? contactId : undefined,
      })
    )
    const { value: answerAttendanceCsvData } = useAsyncSelector(
      attendanceAnswerCsvDataSelectorFamily({
        mailId: isDownCSV && value?.contactType === ContactType.Attendance ? contactId : undefined,
      })
    )
    const { value: answerScheduleCsvData } = useAsyncSelector(
      scheduleAnswerCsvDataSelectorFamily({
        mailId: isDownCSV && value?.contactType === ContactType.ScheduleAdjustment ? contactId : undefined,
      })
    )
    const csvData = useMemo(() => {
      if (!isDownCSV) {
        return null
      }
      if (value?.contactType === ContactType.Survey) {
        return answerSurveyCsvData?.items.map((item) => {
          const answeredDateTime = item.answeredAt && UseAnsweredDateTime(new Date(item.answeredAt))
          const memberSeq = "メンバー番号"
          const name = "名前"
          const groups = "所属グループ"
          const answeredAt = "回答時間"
          return {
            [memberSeq]: item.memberSeq,
            [name]: item.nickname,
            [groups]: item.groups.join(","),
            ...Object.fromEntries(answerSurveyCsvData?.questions.map((q, index) => [q, item.answers[index] || "未回答"])),
            [answeredAt]: answeredDateTime,
          }
        })
      } else if (value?.contactType === ContactType.Attendance) {
        return answerAttendanceCsvData?.map((item) => {
          const answeredDateTime = item.answeredAt && UseAnsweredDateTime(new Date(item.answeredAt))
          const memberSeq = "メンバー番号"
          const name = "名前"
          const groups = "所属グループ"
          const answer = "回答"
          const answeredAt = "回答時間"
          return {
            [memberSeq]: item.memberSeq,
            [name]: item.nickname,
            [groups]: item.groups.join(","),
            [answer]:
              (item.attendanceAnswer === "yes" && "○") ||
              (item.attendanceAnswer === "no" && "×") ||
              (item.attendanceAnswer === "tbd" && "△") ||
              (item.attendanceAnswer === null && "未回答"),
            [answeredAt]: answeredDateTime,
          }
        })
      } else if (value?.contactType === ContactType.ScheduleAdjustment) {
        return answerScheduleCsvData?.items.map((item) => {
          const answeredDateTime = item.answeredAt && UseAnsweredDateTime(new Date(item.answeredAt))
          const memberSeq = "メンバー番号"
          const name = "名前"
          const groups = "所属グループ"
          const answeredAt = "回答時間"
          return {
            [memberSeq]: item.memberSeq,
            [name]: item.nickname,
            [groups]: item.groups.join(","),
            ...Object.fromEntries(
              answerScheduleCsvData?.candidates.map((c, index) => [c, ScheduleAnswerCSVLabel[item.answers[index]] || "未回答"])
            ),
            [answeredAt]: answeredDateTime,
          }
        })
      } else {
        return null
      }
    }, [
      answerAttendanceCsvData,
      answerScheduleCsvData?.candidates,
      answerScheduleCsvData?.items,
      answerSurveyCsvData?.items,
      answerSurveyCsvData?.questions,
      isDownCSV,
      value?.contactType,
    ])

    const csv = useMemo(() => {
      if (!csvData || csvData.length === 0) return ""
      const jsonKeys = Object.keys(csvData[0])
      const headerData = jsonKeys.map((key) => `"${key}"`).join(",")
      const rowData = csvData.map((item) => {
        return jsonKeys
          .map((key) => {
            const validKey = key as keyof typeof item
            let value = item[validKey]
            if (value === null || value === undefined) {
              return `""`
            }
            if (typeof value === "string") {
              value = value.replace(/"/g, '""')
            }
            return `"${value}"`
          })
          .join(",")
      })

      return `${headerData}\n${rowData.join("\n")}`
    }, [csvData])

    // 既読/回答更新の際に使用
    const { refreshUnreadContactCount } = useBottomTabBadgeData()
    const refreshInboxContacts = useRefresher(inboxContactsRequestIdState)
    const resetFavoriteFlgsCache = useResetRecoilState(inboxContactFavoriteFlgsCacheState)
    const refreshTeamMemberContactAndThread = useCallback(() => {
      refreshTeamMemberContacts()
      _refreshContactReplyThreads()
      _refreshHasUnreadThreadMessage()
    }, [refreshTeamMemberContacts, _refreshContactReplyThreads, _refreshHasUnreadThreadMessage])
    const lazyRefresh = useCallback(() => {
      return wait(3000).then(() => {
        refreshTeamMemberContacts()
        refreshInboxContacts()
        resetFavoriteFlgsCache()
        refreshUnreadContactCount()
        refreshContactReplyThread()
        refreshContactReplyThreads()
      })
    }, [
      refreshTeamMemberContacts,
      refreshInboxContacts,
      resetFavoriteFlgsCache,
      refreshUnreadContactCount,
      refreshContactReplyThread,
      refreshContactReplyThreads,
    ])

    const [isRefreshed, setIsRefreshed] = useState(false)

    useEffect(() => {
      if (value && !value.isRead && !isRefreshed) {
        setIsRefreshed(true)
        lazyRefresh()
      }
    }, [value, isRefreshed, setIsRefreshed, lazyRefresh])

    useEffect(() => {
      if (value && value.isRead && !isRefreshed) {
        refreshTeamMemberContactAndThread()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactId, value, isRefreshed])

    const [lastSurveyChecks, setLastSurveyChecks] = useState<{ [key in string]: { [key in string]: boolean } }>()
    const [_surveyChecks, setSurveyChecks] = useState<{ [key in string]: { [key in string]: boolean } }>()
    const surveyChecks = useMemo(
      () => _surveyChecks || (value?.contactType === "Survey" ? value.myAnswer || {} : {}),
      [_surveyChecks, value]
    )
    const areAllSurveysChecked = useMemo(() => {
      if (value?.contactType !== ContactType.Survey) return false
      const surveyCheckValues = Object.values(surveyChecks)
      return surveyCheckValues.length === value.surveys.length
        ? surveyCheckValues.every((svy) => Object.values(svy).some((chk) => chk))
        : false
    }, [surveyChecks, value])

    const isAnswerDeadlinePassed = useMemo(
      () =>
        (value?.contactType === "Attendance" ||
          value?.contactType === "Survey" ||
          value?.contactType === "ScheduleAdjustment") &&
        value?.deadlineDate &&
        new Date(value.deadlineDate) < new Date(),
      [value]
    )

    const isSurveyAnswerDisabled = useMemo(() => {
      if (isAnswerDeadlinePassed || !areAllSurveysChecked) return true

      const lastAnswer = lastSurveyChecks || (value?.contactType === "Survey" && value.myAnswer)
      return _surveyChecks == null || JSON.stringify(lastAnswer) === JSON.stringify(_surveyChecks)
    }, [areAllSurveysChecked, lastSurveyChecks, _surveyChecks, value, isAnswerDeadlinePassed])
    const postSurveyAnswer = useCallback(async () => {
      if (isSurveyAnswerDisabled) return
      await execPostSurveyAnswer(
        Object.keys(surveyChecks).map((questionId) => ({
          questionId,
          answerIds: Object.keys(surveyChecks[questionId]).filter((answerId) => surveyChecks[questionId][answerId]),
        }))
      )
      if (organizationId != null) await Analytics.answerToMail(organizationId)
      await refreshNotifications()
      lazyRefresh()
      setLastSurveyChecks(surveyChecks)
    }, [isSurveyAnswerDisabled, surveyChecks, execPostSurveyAnswer, refreshNotifications, lazyRefresh, organizationId])
    const setSurveyChecksHelper = useCallback(
      (questionKey: string, answerKey: string, isMultipleSelect: boolean) => {
        const next = { ...surveyChecks }
        next[questionKey] = {
          ...(isMultipleSelect ? next[questionKey] : {}),
          [answerKey]: isMultipleSelect ? !next[questionKey]?.[answerKey] : true,
        }
        setSurveyChecks(next)
      },
      [surveyChecks]
    )
    const goToContactSurveyAnswerOverview = useCallback(() => {
      if (contactId == null || !(viewMode === "Inbox" || viewMode == "Outbox")) return
      refreshContactSurveyAnswerOverview()
      navigation.navigate(Screens.ContactSurveyAnswerStack, {
        screen: ContactSurveyAnswerScreens.ContactSurveyAnswerOverview,
        params: { contactId, type: viewMode },
      })
    }, [navigation, contactId, refreshContactSurveyAnswerOverview, viewMode])

    const [lastAttendanceAnswer, setLastAttendanceAnswer] = useState<AttendanceType>()
    const [_attendanceAnswer, setAttendanceAnswer] = useState<AttendanceType>()
    const attendanceAnswer = useMemo(
      () => _attendanceAnswer || (value?.contactType === "Attendance" ? value.myAnswer : undefined),
      [_attendanceAnswer, value]
    )
    const isAttendanceAnswerDisabled = useMemo(() => {
      const lastAnswer = lastAttendanceAnswer || (value?.contactType === "Attendance" && value.myAnswer)
      return isAnswerDeadlinePassed || _attendanceAnswer == null || lastAnswer === _attendanceAnswer
    }, [lastAttendanceAnswer, value, _attendanceAnswer, isAnswerDeadlinePassed])
    const postAttendance = useCallback(async () => {
      if (isAttendanceAnswerDisabled || attendanceAnswer == null) {
        return
      }
      await execPostAttendance(attendanceAnswer)
      if (organizationId != null) await Analytics.answerToMail(organizationId)
      await refreshNotifications()

      lazyRefresh()
      setLastAttendanceAnswer(attendanceAnswer)
    }, [
      isAttendanceAnswerDisabled,
      execPostAttendance,
      refreshNotifications,
      attendanceAnswer,
      setLastAttendanceAnswer,
      lazyRefresh,
      organizationId,
    ])
    const setAttendanceAnswerHelper = useCallback((value?: AttendanceType) => {
      setAttendanceAnswer((prev) => (prev === value ? undefined : value))
    }, [])
    const goToContactAttendanceAnswerOverview = useCallback(() => {
      if (contactId == null || !(viewMode === "Inbox" || viewMode == "Outbox")) return
      refreshContactAttendanceAnswerOverview()
      navigation.navigate(Screens.ContactAttendanceAnswerStack, {
        screen: ContactAttendanceAnswerScreens.ContactAttendanceAnswerOverview,
        params: { contactId, type: viewMode },
      })
    }, [navigation, contactId, refreshContactAttendanceAnswerOverview, viewMode])

    const [lastScheduleAnswer, setLastScheduleAnswer] = useState<ScheduleAnswerType[]>()
    const [_scheduleAnswer, setScheduleAnswer] = useState<ScheduleAnswerType[]>()
    const scheduleAnswer = useMemo(
      () => _scheduleAnswer || (value?.contactType === "ScheduleAdjustment" ? value.myAnswer || [] : []),
      [_scheduleAnswer, value]
    )
    const areAllSchedulesAnswered = useMemo(() => {
      if (value?.contactType !== "ScheduleAdjustment") return false
      return scheduleAnswer.filter(notNull).length === value.schedules.length && scheduleAnswer.every((ans) => ans != null)
    }, [scheduleAnswer, value])
    const isScheduleAnswerDisabled = useMemo(() => {
      if (isAnswerDeadlinePassed || !areAllSchedulesAnswered) return true
      const lastAnswer = lastScheduleAnswer || (value?.contactType === "ScheduleAdjustment" && value.myAnswer)
      return _scheduleAnswer == null || JSON.stringify(lastAnswer) === JSON.stringify(_scheduleAnswer)
    }, [areAllSchedulesAnswered, lastScheduleAnswer, value, _scheduleAnswer, isAnswerDeadlinePassed])
    const postScheduleAnswer = useCallback(async () => {
      if (isScheduleAnswerDisabled) {
        return
      }
      await execPostScheduleAnswer(scheduleAnswer.map((ans) => ans ?? ScheduleAnswerType.Pend))
      if (organizationId != null) await Analytics.answerToMail(organizationId)
      await refreshNotifications()

      lazyRefresh()
      setLastScheduleAnswer(scheduleAnswer)
    }, [isScheduleAnswerDisabled, execPostScheduleAnswer, scheduleAnswer, refreshNotifications, lazyRefresh, organizationId])
    const setScheduleAnswerHelper = useCallback(
      (index: number, value: ScheduleAnswerType) => {
        const next = [...scheduleAnswer]
        next[index] = value
        setScheduleAnswer(next)
      },
      [setScheduleAnswer, scheduleAnswer]
    )
    const goToContactScheduleAnswerOverview = useCallback(() => {
      if (contactId == null || !(viewMode === "Inbox" || viewMode == "Outbox")) return
      refreshContactScheduleAnswerOverview()
      navigation.navigate(Screens.ContactScheduleAnswerStack, {
        screen: ContactScheduleAnswerScreens.ContactScheduleAnswerOverview,
        params: { contactId, type: viewMode },
      })
    }, [navigation, contactId, refreshContactScheduleAnswerOverview, viewMode])

    const toggleFavorite = useCallback(async () => {
      await execUpdateContactDetailFavorite(!isFavorite)
    }, [isFavorite, execUpdateContactDetailFavorite])

    const [isOpenReadBottomSheet, setIsOpenReadBottomSheet] = useState(false)
    const [isOpenUnreadBottomSheet, setIsOpenUnreadBottomSheet] = useState(false)
    const [isOpenAnsweredBottomSheet, setIsOpenAnsweredBottomSheet] = useState(false)
    const [isOpenUnansweredBottomSheet, setIsOpenUnansweredBottomSheet] = useState(false)
    const [isLoadingRead, setIsLoadingRead] = useState<boolean>(false)
    const [isLoadingUnread, setIsLoadingUnread] = useState<boolean>(false)
    const [isLoadingAnswer, setIsLoadingAnswer] = useState<boolean>(false)
    const [isLoadingUnanswer, setIsLoadingUnanswer] = useState<boolean>(false)
    const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false)
    const [isOpenReceiversBottomSheet, setIsOpenReceiversBottomSheet] = useState(false)
    const openReceiversBottomSheet = useCallback(() => {
      if (allowViewRecipientList) {
        setTimeout(() => {
          setIsOpenReceiversBottomSheet(true)
        }, 800)
      }
    }, [setIsOpenReceiversBottomSheet, allowViewRecipientList])

    const openReadBottomSheet = useCallback(() => {
      if (!allowViewReadListMember) {
        return
      }
      setIsLoadingRead(true)
      setTimeout(() => {
        setIsOpenReadBottomSheet(true)
        setIsLoadingRead(false)
      }, 800)
    }, [allowViewReadListMember, setIsOpenReadBottomSheet])

    const openUnreadBottomSheet = useCallback(async () => {
      if (!allowViewReadListMember) {
        return
      }
      setIsLoadingUnread(true)
      setTimeout(() => {
        setIsOpenUnreadBottomSheet(true)
        setIsLoadingUnread(false)
      }, 800)
    }, [allowViewReadListMember, setIsOpenUnreadBottomSheet])

    const openAnsweredBottomSheet = useCallback(() => {
      if (!allowViewAnsweredListMembers) {
        return
      }
      setIsLoadingAnswer(true)
      setTimeout(() => {
        setIsOpenAnsweredBottomSheet(true)
        setIsLoadingAnswer(false)
      }, 800)
    }, [allowViewAnsweredListMembers, setIsOpenAnsweredBottomSheet])

    const openUnansweredBottomSheet = useCallback(() => {
      if (!allowViewAnsweredListMembers) {
        return
      }
      setIsLoadingUnanswer(true)
      setTimeout(() => {
        setIsOpenUnansweredBottomSheet(true)
        setIsLoadingUnanswer(false)
      }, 800)
    }, [allowViewAnsweredListMembers, setIsOpenUnansweredBottomSheet])

    const gotoReplyThread = useCallback(
      async (replyThreadId?: string) => {
        if (myMemberId == null) return
        if (organizationId != null) {
          if (isSender) {
            if (replyThreadId) await Analytics.goToThreadByMailSender(organizationId)
          } else {
            if (replyThreadId) {
              await Analytics.goToThreadByMailRecipient(organizationId)
            } else {
              await Analytics.replyToMailSender(organizationId)
            }
          }
        }
        refreshContactReplyThread()
        navigation.navigate(Screens.ContactReplyThread, {
          replyThreadId: replyThreadId || `${contactId}.${myMemberId}`,
          isNew: replyThreadId === undefined,
          contactTitle: value?.title,
          senderName: value?.sender.name,
        })
      },
      [myMemberId, organizationId, refreshContactReplyThread, navigation, contactId, value, isSender]
    )

    const openSenderMenuBottomSheet = useCallback(() => {
      setIsSenderMenuBottomSheetOpen(true)
    }, [setIsSenderMenuBottomSheetOpen])
    useEffect(() => {
      if (viewMode === "Outbox" || viewMode === "Reserved") {
        navigation.setOptions({
          headerRight: () => (
            <Button style={optionStyle.headerRight} onPress={openSenderMenuBottomSheet}>
              <HamburgerIcon fill={isLargeScreen ? Colors.orange : Colors.white3} />
            </Button>
          ),
        })
      }
    }, [viewMode, navigation, openSenderMenuBottomSheet, isLargeScreen])

    const [isMenuLoading, setMenuLoading] = useState(false)
    useEffect(() => {
      if (!isSenderMenuBottomSheetOpen) {
        setMenuLoading(false)
      }
    }, [isSenderMenuBottomSheetOpen])
    useEffect(() => {
      if (!isDeleteContactExecuting) {
        setMenuLoading(false)
      }
    }, [isDeleteContactExecuting])

    // 連絡内容を変更する
    const goToReEditContact = useCallback(() => {
      if (!(viewMode === "Outbox" || viewMode === "Reserved")) return
      setMenuLoading(true)
      resetCreateNewContactScreenData(
        viewMode === "Outbox" ? { mode: "Edit", contactId } : { mode: "EditReserved", reservedId: contactId }
      )
      setIsSenderMenuBottomSheetOpen(false)
      navigation.navigate(Screens.CreateNewContactStack, { screen: CreateNewContactScreens.CreateNewContact })
    }, [navigation, resetCreateNewContactScreenData, contactId, viewMode, setIsSenderMenuBottomSheetOpen])
    const deleteContact = useCallback(() => {
      if (Platform.OS === "web") setIsSenderMenuBottomSheetOpen(false)
      setMenuLoading(true)
      execDeleteContact()
    }, [execDeleteContact, setIsSenderMenuBottomSheetOpen])
    // 件名と本文をコピーして新規作成
    const goToEditContact = useCallback(async () => {
      if (!(viewMode === "Outbox" || viewMode === "Reserved")) return
      setMenuLoading(true)
      if (organizationId != null) await Analytics.goToCreateMail(organizationId)
      resetCreateNewContactScreenData({ mode: "New", ...(viewMode === "Outbox" ? { contactId } : { reservedId: contactId }) })
      setIsSenderMenuBottomSheetOpen(false)
      navigation.navigate(Screens.CreateNewContactStack, { screen: CreateNewContactScreens.CreateNewContact })
    }, [navigation, resetCreateNewContactScreenData, contactId, viewMode, organizationId, setIsSenderMenuBottomSheetOpen])

    const postContactTemplate = useCallback(async () => {
      setMenuLoading(true)
      if (Platform.OS === "web") setIsSenderMenuBottomSheetOpen(false)
      await execPostContactTemplate()
      navigation.replace(Screens.Root, {
        screen: RootTabs.ContactNetworkDrawer,
        params: {
          screen: ContactNetworkDrawers.TemplateScreen,
          params: { organizationId: organizationId },
        },
      })
    }, [execPostContactTemplate, navigation, organizationId, setIsSenderMenuBottomSheetOpen])

    useEffect(() => {
      if (organizationName) {
        navigation.setOptions({
          headerTitle: organizationName,
        })
      }
    }, [navigation, organizationName])

    const [isConfirmDownLoad, setIsConfirmDownLoad] = useState(false)

    const downloadBlob = (content: any, filename: string, contentType: string) => {
      const blob = new Blob(["\uFEFF", content], { type: contentType })
      const url = URL.createObjectURL(blob)
      const pom = document.createElement("a")
      pom.href = url
      pom.setAttribute("download", filename)
      pom.click()
    }

    if ((isLoading && value == null) || isLoadingFile) {
      return (
        <View style={styles.loadingIndicator}>
          <LoadingIndicator />
        </View>
      )
    }

    if (value == null || organizationId == null || contactId == null) {
      return null
    }
    return (
      <CustomKeyboardAvoidingView style={[isLargeScreen ? styles.containerLarge : styles.container]}>
        <CustomScrollView isContactDetail isLargeScreen={isLargeScreen}>
          <View style={[isLargeScreen ? styles.oneColumn : {}]}>
            <View style={styles.main}>
              <View style={styles.topDateContainer}>
                {viewMode !== "Reserved" ? (
                  <View>
                    <Text style={styles.topDateText}>
                      {"送信："}
                      {checkDate(value.sentDate)}
                    </Text>
                    {value.isEdited ? (
                      <Text style={[styles.topDateText, styles.topDateTextEdited]}>
                        {"編集済み："}
                        {checkDate(value.updatedDate)}
                      </Text>
                    ) : null}
                  </View>
                ) : null}
                {viewMode === "Reserved" && value.reservedDate ? (
                  <View style={styles.reservedLabelContainer}>
                    <Text style={styles.reservedLabelText}>
                      {checkDate(value.reservedDate)}
                      {"に送信予定"}
                    </Text>
                  </View>
                ) : null}
                {value.isAttachmentExists ? <AttachmentIcon /> : null}
              </View>
              {viewMode === "Reserved" && value.reservedDate && value.isEdited && (
                <View style={styles.topUpdateDateContainer}>
                  <Text style={[styles.topDateText, styles.topDateTextEdited]}>
                    {"編集済み："}
                    {checkDate(value.updatedDate)}
                  </Text>
                </View>
              )}
              <SelectableText style={styles.titleContainer} limitLine={true}>
                {viewMode !== "Migration" ? (
                  <>
                    <Text style={styles.contactType}>{ContactTypeLabel[value.contactType]}</Text>
                    <Text style={styles.titleDelimiter}> | </Text>
                  </>
                ) : null}
                <Text style={styles.title}>{value.title}</Text>
              </SelectableText>
              <View style={styles.senderContainer}>
                <View style={styles.senderInner}>
                  {!value.sender.isDeleted ? (
                    <>
                      {viewMode !== "Migration" ? (
                        <CircleThumbnail
                          source={senderImageUrl ? { uri: senderImageUrl } : defaultThumbnail}
                          size={33}
                          onError={refreshSenderImage}
                        />
                      ) : null}
                      <Text
                        style={[
                          Platform.OS === "web" ? styles.senderName : { width: "90%" },
                          viewMode === "Migration" && { marginLeft: 0 },
                        ]}
                        numberOfLines={3}
                        ellipsizeMode="tail"
                      >
                        {value.sender.name}
                      </Text>
                    </>
                  ) : (
                    <>
                      {viewMode !== "Migration" ? <NoPersonIcon size={32} /> : null}
                      <Text
                        style={[
                          Platform.OS === "web" ? styles.senderName : { width: "90%" },
                          viewMode === "Migration" && { marginLeft: 0 },
                        ]}
                        numberOfLines={3}
                        ellipsizeMode="tail"
                      >
                        {value.sender.name}（脱退）
                      </Text>
                    </>
                  )}
                </View>
                {viewMode !== "Reserved" ? (
                  <Button onPress={toggleFavorite}>
                    <FavoriteIcon isFavorite={isFavorite} />
                  </Button>
                ) : null}
              </View>
              {viewMode === "Migration" ? null : allowViewRecipient ? (
                <Button style={styles.receiverContainer} onPress={openReceiversBottomSheet}>
                  <Text style={styles.receiverText}>{receiverText}</Text>
                </Button>
              ) : (
                <></>
              )}
              <View style={styles.bodyContainer}>
                <Hyperlink
                  linkStyle={{ color: "#2980b9", fontSize: 16 }}
                  onPress={async (url) => await Linking.openURL(url)}
                  onLongPress={async (text) => {
                    // Do nothing
                  }}
                >
                  <SelectableText style={styles.body}>{value.body}</SelectableText>
                </Hyperlink>
              </View>
            </View>
            {value.sentDate && value.attachmentFileNames.length > 0 ? (
              <View style={{ borderBottomWidth: 1, borderBottomColor: Colors.white2 }}>
                <AttachmentViewer
                  setIsLoadingFile={setIsLoadingFile}
                  organizationId={organizationId}
                  contactId={contactId}
                  attachmentFileNames={value.attachmentFileNames || []}
                />
              </View>
            ) : null}
            {value.contactType === ContactType.Attendance ? (
              viewMode === "Outbox" && allowViewAnswerSummary ? (
                <View style={[isLargeScreen && styles.centerLayout, styles.optionContainer]}>
                  <TextButton
                    buttonType={ButtonType.Secondary}
                    title="回答結果を見る"
                    onPress={goToContactAttendanceAnswerOverview}
                    style={isLargeScreen ? styles.buttonsPC : {}}
                  />
                </View>
              ) : viewMode === "Inbox" ? (
                <>
                  <View style={[styles.optionContainer, { borderBottomWidth: 0 }]}>
                    <View style={styles.surveyItemLabelWrapper}>
                      <ItemLabel label="回答する" />
                      <Text style={styles.deadlineDateFormat}>{deadlineDateFormat}</Text>
                    </View>
                    <View style={styles.surveyTimeLimitLabelWrapper}>
                      <TimeLimitLabel timeLimitDate={value.deadlineDate} />
                    </View>
                    <View style={styles.threeToggleButtonWrapper}>
                      <AttendanceRadioButton<AttendanceType>
                        isLargeScreen={isLargeScreen}
                        value={attendanceAnswer}
                        items={attendanceQuestionOptions}
                        onValueChange={setAttendanceAnswerHelper}
                        disabled={isAnswerDeadlinePassed}
                      />
                    </View>
                  </View>
                  <View style={[!isLargeScreen && styles.optionContainer, styles.optionLarge]}>
                    <TextButton
                      style={[isLargeScreen ? { width: 300 } : { width: "100%", maxWidth: 360 }]}
                      buttonType={ButtonType.Primary}
                      title={lastAttendanceAnswer || value.myAnswer ? "回答内容を変更する" : "回答する"}
                      disabled={isAttendanceAnswerDisabled}
                      isLoading={isPostAttendanceExecuting}
                      onPress={postAttendance}
                    />
                    {allowViewAnswerSummary ? (
                      <View
                        style={
                          isLargeScreen
                            ? styles.secondaryTextButtonWrapperLarge
                            : { ...styles.secondaryTextButtonWrapper, width: "100%", maxWidth: 360 }
                        }
                      >
                        <TextButton
                          style={isLargeScreen ? { width: 300 } : { width: "100%" }}
                          buttonType={ButtonType.Secondary}
                          title="回答結果を見る"
                          onPress={goToContactAttendanceAnswerOverview}
                        />
                      </View>
                    ) : null}
                  </View>
                </>
              ) : null
            ) : value.contactType === ContactType.Survey ? (
              viewMode === "Outbox" && allowViewAnswerSummary ? (
                <View
                  style={
                    isLargeScreen
                      ? {
                          backgroundColor: Colors.white3,
                          display: "flex",
                          alignItems: "center",
                          paddingTop: 20,
                          paddingBottom: 20,
                          borderBottomWidth: 1,
                          borderBottomColor: Colors.white2,
                        }
                      : styles.optionContainer
                  }
                >
                  <TextButton
                    style={isLargeScreen ? { width: 300 } : undefined}
                    buttonType={ButtonType.Secondary}
                    title="回答結果を見る"
                    onPress={goToContactSurveyAnswerOverview}
                  />
                </View>
              ) : viewMode === "Inbox" ? (
                <>
                  {isLargeScreen ? (
                    <>
                      <View style={[styles.optionContainerLarge, { borderBottomWidth: 0 }]}>
                        <View style={styles.surveyItemLabelWrapper}>
                          <ItemLabel label="回答する" />
                          <Text style={styles.surveyItemSubLabel}>全{value.surveys.length}問</Text>
                          <Text style={styles.deadlineDateFormat}>{deadlineDateFormat}</Text>
                        </View>
                        <View style={styles.surveyTimeLimitLabelWrapper}>
                          <TimeLimitLabel timeLimitDate={value.deadlineDate} />
                        </View>
                      </View>
                      <div>
                        {value.surveys.map((survey, index) => (
                          <Fragment key={survey.id}>
                            <View style={styles.questionContainerLarge}>
                              <QuestionWithCircleIcon />
                              <Text style={styles.questionText}>
                                質問{index + 1}: {survey.question}（{survey.isMultipleSelect ? "複数選択" : "単一選択"}）
                              </Text>
                            </View>
                            {survey.choices.map((choice) => (
                              <Button
                                style={[
                                  styles.surveyAnswerButtonCommonLarge,
                                  surveyChecks[survey.id]?.[choice.id]
                                    ? styles.surveyAnswerButtonSelected
                                    : styles.surveyAnswerButtonNeutral,
                                ]}
                                key={choice.id}
                                onPress={() => setSurveyChecksHelper(survey.id, choice.id, survey.isMultipleSelect)}
                                disabled={isAnswerDeadlinePassed}
                              >
                                <Text
                                  style={[
                                    styles.surveyAnswerButtonTextCommon,
                                    surveyChecks[survey.id]?.[choice.id]
                                      ? styles.surveyAnswerButtonTextSelected
                                      : styles.surveyAnswerButtonTextNeutral,
                                  ]}
                                >
                                  {choice.value}
                                </Text>
                              </Button>
                            ))}
                          </Fragment>
                        ))}
                      </div>
                      <View style={styles.optionContainerSurveyLarge}>
                        <TextButton
                          style={{ width: 300 }}
                          buttonType={ButtonType.Primary}
                          title={lastSurveyChecks || value.myAnswer ? "回答内容を変更する" : "回答する"}
                          isLoading={isPostSurveyAnswerExecuting}
                          disabled={isSurveyAnswerDisabled}
                          onPress={postSurveyAnswer}
                        />
                        {allowViewAnswerSummary ? (
                          <View style={styles.secondaryTextButtonWrapperLarge}>
                            <TextButton
                              style={{ width: 300 }}
                              buttonType={ButtonType.Secondary}
                              title="回答結果を見る"
                              onPress={goToContactSurveyAnswerOverview}
                            />
                          </View>
                        ) : null}
                      </View>
                    </>
                  ) : (
                    <>
                      <View style={[styles.optionContainer, { borderBottomWidth: 0 }]}>
                        <View style={styles.surveyItemLabelWrapper}>
                          <ItemLabel label="回答する" />
                          <Text style={styles.surveyItemSubLabel}>全{value.surveys.length}問</Text>
                          <Text style={styles.deadlineDateFormat}>{deadlineDateFormat}</Text>
                        </View>
                        <View style={styles.surveyTimeLimitLabelWrapper}>
                          <TimeLimitLabel timeLimitDate={value.deadlineDate} />
                        </View>
                        {value.surveys.map((survey, index) => (
                          <Fragment key={survey.id}>
                            <View style={styles.questionContainer}>
                              <QuestionWithCircleIcon />
                              <Text style={styles.questionText}>
                                質問{index + 1}: {survey.question}（{survey.isMultipleSelect ? "複数選択" : "単一選択"}）
                              </Text>
                            </View>
                            {survey.choices.map((choice) => (
                              <Button
                                style={[
                                  styles.surveyAnswerButtonCommon,
                                  surveyChecks[survey.id]?.[choice.id]
                                    ? styles.surveyAnswerButtonSelected
                                    : styles.surveyAnswerButtonNeutral,
                                ]}
                                key={choice.id}
                                onPress={() => setSurveyChecksHelper(survey.id, choice.id, survey.isMultipleSelect)}
                                disabled={isAnswerDeadlinePassed}
                              >
                                <Text
                                  style={[
                                    styles.surveyAnswerButtonTextCommon,
                                    surveyChecks[survey.id]?.[choice.id]
                                      ? styles.surveyAnswerButtonTextSelected
                                      : styles.surveyAnswerButtonTextNeutral,
                                  ]}
                                >
                                  {choice.value}
                                </Text>
                              </Button>
                            ))}
                          </Fragment>
                        ))}
                      </View>
                      <View style={styles.optionContainer}>
                        <TextButton
                          buttonType={ButtonType.Primary}
                          title={lastSurveyChecks || value.myAnswer ? "回答内容を変更する" : "回答する"}
                          isLoading={isPostSurveyAnswerExecuting}
                          disabled={isSurveyAnswerDisabled}
                          onPress={postSurveyAnswer}
                        />
                        {allowViewAnswerSummary ? (
                          <View style={styles.secondaryTextButtonWrapper}>
                            <TextButton
                              style={isLargeScreen ? { width: 300 } : undefined}
                              buttonType={ButtonType.Secondary}
                              title="回答結果を見る"
                              onPress={goToContactSurveyAnswerOverview}
                            />
                          </View>
                        ) : null}
                      </View>
                    </>
                  )}
                </>
              ) : null
            ) : value.contactType === ContactType.ScheduleAdjustment ? (
              viewMode === "Outbox" && allowViewAnswerSummary ? (
                <View
                  style={[
                    isLargeScreen ? styles.optionContainerPC : styles.optionContainer,
                    isLargeScreen ? styles.centerLayout : {},
                  ]}
                >
                  <TextButton
                    buttonType={ButtonType.Secondary}
                    title="回答結果を見る"
                    onPress={goToContactScheduleAnswerOverview}
                    style={isLargeScreen ? styles.buttonsPC : {}}
                  />
                </View>
              ) : viewMode === "Inbox" ? (
                <>
                  <View
                    style={
                      isLargeScreen
                        ? {
                            ...styles.optionContainerLarge,
                            ...styles.centerLayout,
                            flexDirection: "column",
                            borderBottomWidth: 0,
                          }
                        : [styles.optionContainer, { borderBottomWidth: 0 }]
                    }
                  >
                    <View
                      style={[
                        styles.surveyItemLabelWrapper,
                        isLargeScreen ? { width: "100%", display: "flex", alignItems: "flex-start" } : {},
                      ]}
                    >
                      <ItemLabel label="回答する" />
                      <Text style={styles.deadlineDateFormat}>{deadlineDateFormat}</Text>
                    </View>
                    <View style={styles.surveyTimeLimitLabelWrapper}>
                      <TimeLimitLabel timeLimitDate={value.deadlineDate} />
                    </View>
                    <View style={isLargeScreen ? { width: "100%", display: "flex", alignItems: "flex-start" } : {}}>
                      {value.schedules.map((schedule, index) => (
                        <Fragment key={index}>
                          <View style={styles.questionContainer}>
                            <ClockWithCircleIcon />
                            <Text style={styles.questionText}>
                              日程{index + 1}: {formatCandidate(schedule.eventDateCandidate)}
                            </Text>
                          </View>
                          <View style={[styles.threeToggleButtonWrapper, isLargeScreen ? { width: 420 } : {}]}>
                            <AttendanceRadioButton<ScheduleAnswerType>
                              value={scheduleAnswer[index]}
                              items={scheduleQuestionOptions}
                              onValueChange={(value) => setScheduleAnswerHelper(index, value)}
                              disabled={isAnswerDeadlinePassed}
                            />
                          </View>
                        </Fragment>
                      ))}
                    </View>
                  </View>
                  <View
                    style={
                      isLargeScreen
                        ? {
                            ...styles.centerLayout,
                            ...styles.optionContainerLarge,
                            flexDirection: "column",
                            alignItems: "center",
                            borderBottomWidth: 1,
                            borderBottomColor: Colors.white2,
                            borderRadius: 0,
                          }
                        : styles.optionContainer
                    }
                  >
                    <TextButton
                      buttonType={ButtonType.Primary}
                      title={lastScheduleAnswer || value.myAnswer ? "回答内容を変更する" : "回答する"}
                      disabled={isScheduleAnswerDisabled}
                      isLoading={isPostScheduleAnswerExecuting}
                      onPress={postScheduleAnswer}
                      style={isLargeScreen ? styles.buttonsPC : {}}
                    />
                    {allowViewAnswerSummary ? (
                      <View style={[styles.secondaryTextButtonWrapper, isLargeScreen ? styles.centerLayout : {}]}>
                        <TextButton
                          buttonType={ButtonType.Secondary}
                          title="回答結果を見る"
                          onPress={goToContactScheduleAnswerOverview}
                          style={isLargeScreen ? styles.buttonsPC : {}}
                        />
                      </View>
                    ) : null}
                  </View>
                </>
              ) : null
            ) : null}
            {viewMode !== "Reserved" && viewMode !== "Migration" ? (
              <>
                {allowViewReadCount ? (
                  <View
                    style={[
                      isLargeScreen && { display: "flex", flexDirection: "row", paddingLeft: 35 },
                      styles.optionContainer,
                    ]}
                  >
                    <ItemLabel
                      label="読了結果"
                      RightComponent={
                        <View style={styles.unreadResultButtonWrapper}>
                          {!isLoadingRead ? (
                            <Button style={styles.unreadResultButtonContainer} onPress={openReadBottomSheet}>
                              <Text style={styles.unreadResultButtonText}>読了</Text>
                              <Text style={styles.unreadResultButtonHighlightText}>
                                {String(readMembers.length).padStart(3, "0")}
                              </Text>
                            </Button>
                          ) : (
                            <Button style={[styles.unreadResultButtonContainer, { width: 91 }]}>
                              <ActivityIndicator />
                            </Button>
                          )}
                          {!isLoadingUnread ? (
                            <Button style={styles.unreadResultButtonContainer} onPress={openUnreadBottomSheet}>
                              <Text style={styles.unreadResultButtonText}>未読</Text>
                              <Text style={styles.unreadResultButtonHighlightText}>
                                {String(unreadMembers.length).padStart(3, "0")}
                              </Text>
                            </Button>
                          ) : (
                            <Button style={[styles.unreadResultButtonContainer, { width: 91 }]}>
                              <ActivityIndicator />
                            </Button>
                          )}
                        </View>
                      }
                    />
                  </View>
                ) : null}
                {(value.contactType === ContactType.Attendance ||
                  value.contactType === ContactType.Survey ||
                  value.contactType === ContactType.ScheduleAdjustment) &&
                allowViewAnswerSummary ? (
                  <View
                    style={[
                      isLargeScreen && { display: "flex", flexDirection: "row", paddingLeft: 35 },
                      styles.optionContainer,
                    ]}
                  >
                    <ItemLabel
                      label="回答状況"
                      RightComponent={
                        <View style={styles.unreadResultButtonWrapper}>
                          {!isLoadingAnswer ? (
                            <Button style={styles.unreadResultButtonContainer} onPress={openAnsweredBottomSheet}>
                              <Text style={styles.unreadResultButtonText}>回答済</Text>
                              <Text style={styles.unreadResultButtonHighlightText}>
                                {String(answeredMembers.length).padStart(3, "0")}
                              </Text>
                            </Button>
                          ) : (
                            <Button style={[styles.unreadResultButtonContainer, { width: 91 }]}>
                              <ActivityIndicator />
                            </Button>
                          )}
                          {!isLoadingUnanswer ? (
                            <Button style={styles.unreadResultButtonContainer} onPress={openUnansweredBottomSheet}>
                              <Text style={styles.unreadResultButtonText}>未回答</Text>
                              <Text style={styles.unreadResultButtonHighlightText}>
                                {String(unansweredMembers.length).padStart(3, "0")}
                              </Text>
                            </Button>
                          ) : (
                            <Button style={[styles.unreadResultButtonContainer, { width: 91 }]}>
                              <ActivityIndicator />
                            </Button>
                          )}
                          {isDownCSV && (
                            <Button
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                paddingHorizontal: 15,
                              }}
                              onPress={() => {
                                setIsConfirmDownLoad(true)
                              }}
                            >
                              <DownloadSVG></DownloadSVG>
                              <Text style={{ color: Colors.orange, fontWeight: "600", marginLeft: 5 }}>CSVダウンロード</Text>
                            </Button>
                          )}
                        </View>
                      }
                    />
                  </View>
                ) : null}
              </>
            ) : null}
            {viewMode === "Outbox" &&
            (value.contactType === "Attendance" ||
              value.contactType === "Survey" ||
              value.contactType === "ScheduleAdjustment") ? (
              <View
                style={[isLargeScreen ? styles.centerLayout : styles.sendReminderContainer, isLargeScreen && { marginTop: 20 }]}
              >
                <TextButton
                  buttonType={ButtonType.Secondary}
                  title="未回答者に催促"
                  style={isLargeScreen ? styles.buttonsPC : {}}
                  onPress={sendReminder}
                  isLoading={isSendingReminder}
                  disabled={value.deadlineDate && value.deadlineDate < new Date()}
                />
              </View>
            ) : null}
            {contactReplyThreads == null ? (
              <View style={styles.loadingIndicator}>
                <LoadingIndicator />
              </View>
            ) : contactReplyThreads.length === 0 ? (
              !(
                viewMode === "Outbox" ||
                viewMode === "Reserved" ||
                viewMode === "Migration" ||
                isSender ||
                value.sender.isDeleted
              ) ? (
                <View style={[isLargeScreen ? styles.viewLarge : styles.viewSp]}>
                  <TextButton
                    style={[isLargeScreen ? styles.optionContainerLarge : styles.optionContainerToRellpy]}
                    buttonType={ButtonType.Secondary}
                    title="この連絡の送信者に返信する"
                    onPress={() => gotoReplyThread()}
                  />
                </View>
              ) : null
            ) : (
              <View style={[styles.repliesContainer, isLargeScreen ? { margin: 35 } : {}]}>
                <View style={styles.repliesItemLabelWrapper}>
                  <ItemLabel label="返信一覧" />
                </View>
                {contactReplyThreads.map((reply) => (
                  <ReplyThreadItem reply={reply} gotoReplyThread={gotoReplyThread} key={reply.replyThreadId} />
                ))}
              </View>
            )}
            {viewMode === "Migration" ? (
              <View
                style={[
                  isLargeScreen ? { width: 300, marginHorizontal: "auto" } : styles.primaryTextButtonContainer,
                  { marginTop: 10 },
                ]}
              >
                <TextButton
                  buttonType={ButtonType.Primary}
                  title="この内容で新規連絡を作成する"
                  onPress={gotoCreateNewContactFromMigration}
                />
              </View>
            ) : null}
            <BottomSheet
              snapPoints={["90%"]}
              isOpen={isOpenReceiversBottomSheet}
              onIsOpenChange={setIsOpenReceiversBottomSheet}
              backgroundColor={Colors.white3}
            >
              <SimpleListBottomSheetInner
                title="メンバー一覧"
                subTitle={`${receivedMembers.length}人`}
                list={receivedMembers.map((member) => ({ value: member.id, label: member.nickname }))}
                isShowAds={!organization?.paidFunctionEnabled || organization?.showAdsFlg}
              />
            </BottomSheet>
            <BottomSheet
              snapPoints={["90%"]}
              isOpen={isOpenReadBottomSheet}
              onIsOpenChange={setIsOpenReadBottomSheet}
              backgroundColor={Colors.white3}
            >
              <SimpleListBottomSheetInner
                title="読了"
                subTitle={`${readMembers.length}人`}
                list={readMembers.map((member) => ({ value: member.id, label: member.nickname, datetime: member.readAt }))}
                isShowAds={!organization?.paidFunctionEnabled || organization?.showAdsFlg}
              />
            </BottomSheet>
            <BottomSheet
              snapPoints={["90%"]}
              isOpen={isOpenUnreadBottomSheet}
              onIsOpenChange={setIsOpenUnreadBottomSheet}
              backgroundColor={Colors.white3}
            >
              <SimpleListBottomSheetInner
                title="未読"
                subTitle={`${unreadMembers.length}人`}
                list={unreadMembers.map((member) => ({ value: member.id, label: member.nickname }))}
                isShowAds={!organization?.paidFunctionEnabled || organization?.showAdsFlg}
              />
            </BottomSheet>
            <BottomSheet
              snapPoints={["90%"]}
              isOpen={isOpenAnsweredBottomSheet}
              onIsOpenChange={setIsOpenAnsweredBottomSheet}
              backgroundColor={Colors.white3}
            >
              <SimpleListBottomSheetInner
                title="回答済"
                subTitle={`${answeredMembers.filter(notNull).length}人`}
                list={answeredMembers
                  .filter(notNull)
                  .map((member) => ({ value: member.id, label: member.nickname, datetime: member.answeredAt }))}
                isShowAds={!organization?.paidFunctionEnabled || organization?.showAdsFlg}
              />
            </BottomSheet>
            <BottomSheet
              snapPoints={["90%"]}
              isOpen={isOpenUnansweredBottomSheet}
              onIsOpenChange={setIsOpenUnansweredBottomSheet}
              backgroundColor={Colors.white3}
            >
              <SimpleListBottomSheetInner
                title="未回答"
                subTitle={`${unansweredMembers.filter(notNull).length}人`}
                list={unansweredMembers.filter(notNull).map((member) => ({ value: member.id, label: member.nickname }))}
                isShowAds={!organization?.paidFunctionEnabled || organization?.showAdsFlg}
              />
            </BottomSheet>
            <BottomSheet
              snapPoints={["50%"]}
              isOpen={isSenderMenuBottomSheetOpen}
              onIsOpenChange={setIsSenderMenuBottomSheetOpen}
              backgroundColor={Colors.white3}
            >
              <Button style={styles.senderMenuBottomSheetRowContainer} onPress={goToReEditContact} disabled={isMenuLoading}>
                <Text style={styles.senderMenuBottomSheetRowText}>連絡内容を編集</Text>
              </Button>
              <View style={styles.senderMenuBottomSheetRowSeparator} />
              <Button style={styles.senderMenuBottomSheetRowContainer} onPress={deleteContact} disabled={isMenuLoading}>
                <Text style={styles.senderMenuBottomSheetRowText}>{`送信${
                  viewMode === "Reserved" ? "予約" : ""
                }の取り消し`}</Text>
              </Button>
              <View style={styles.senderMenuBottomSheetRowSeparator} />
              <Button style={styles.senderMenuBottomSheetRowContainer} onPress={goToEditContact} disabled={isMenuLoading}>
                <Text style={styles.senderMenuBottomSheetRowText}>件名と本文をコピーして新規作成</Text>
              </Button>
              <View style={styles.senderMenuBottomSheetRowSeparator} />
              <Button style={styles.senderMenuBottomSheetRowContainer} onPress={postContactTemplate} disabled={isMenuLoading}>
                <Text style={styles.senderMenuBottomSheetRowText}>この連絡をテンプレートとして保存</Text>
              </Button>
              <View style={styles.senderMenuBottomSheetRowSeparator} />
            </BottomSheet>
            {Platform.OS !== "web" && (!organization?.paidFunctionEnabled || organization?.showAdsFlg) && (
              <View style={!isLoadDoneAd("mail_detail", adLoadStateList, 2) && { height: 0 }}>{BannerAd.MailDetail_2}</View>
            )}
          </View>
          <Modal animationType="fade" visible={isConfirmDownLoad} transparent>
            <View style={styles.secondLayer}></View>
            <View style={styles.backgroundModal}>
              <View style={[styles.mainModal, isLargeScreen ? { width: 500 } : { width: 360 }]}>
                <View style={{ display: "flex", flexDirection: "row", marginBottom: 15 }}>
                  <Button
                    onPress={() => {
                      setIsConfirmDownLoad(false)
                    }}
                    style={{ paddingVertical: 10, paddingHorizontal: 10 }}
                  >
                    <RemoveIcon fill={Colors.orange}></RemoveIcon>
                  </Button>
                  <Text style={styles.titleModal}>確認</Text>
                </View>
                <Text style={styles.content}>
                  {
                    "ダウンロードされる内容には個人情報が含まれますので、取り扱いには十分ご注意下さいますようお願いいたします。\n尚、『らくらく連絡網＋』での個人情報の取り扱いについては利用規約をご確認下さい。"
                  }
                </Text>
                <Button
                  onPress={async () => {
                    await Linking.openURL(ruleURL)
                  }}
                >
                  <Text style={styles.terms}>利用規約</Text>
                </Button>
                <View style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                  <Button
                    onPress={() => {
                      setIsConfirmDownLoad(false)
                    }}
                    style={styles.buttonCustom}
                  >
                    <Text style={[styles.textButton, { color: Colors.orange }]}>キャンセル</Text>
                  </Button>
                  <Button
                    onPress={() => {
                      downloadBlob(csv, "answer_result.csv", "text/csv;charset=utf8bom;")
                      setIsConfirmDownLoad(false)
                    }}
                    style={[styles.buttonCustom, { backgroundColor: Colors.orange }]}
                  >
                    <Text style={[styles.textButton, { color: Colors.white3 }]}>OK</Text>
                  </Button>
                </View>
              </View>
            </View>
          </Modal>
        </CustomScrollView>
      </CustomKeyboardAvoidingView>
    )
  }
)
type ReplyThreadItemProps = {
  reply: ContactReplyThreadOverview
  gotoReplyThread: (replyThreadId?: string) => void
}

const ReplyThreadItem = memo<ReplyThreadItemProps>(({ reply, gotoReplyThread }) => {
  const { resourceUrl, refreshResourceUrl } = useResource(
    !reply.partner.isDeleted
      ? {
          type: "teamMemberImage",
          id: reply.partner.id,
          size: "thumbnail",
        }
      : {
          type: "none",
        }
  )

  return (
    <Button style={styles.replyContainer} onPress={() => gotoReplyThread(reply.replyThreadId)}>
      {!reply.partner.isDeleted ? (
        <Image
          style={styles.replySenderImage}
          source={resourceUrl ? { uri: resourceUrl } : defaultThumbnail}
          onError={refreshResourceUrl}
        />
      ) : (
        <NoPersonIcon size={44} />
      )}
      <View style={styles.replyTextContainer}>
        {reply.unreadMessageCount ? (
          reply.unreadMessageCount < 100 ? (
            <View style={styles.replyUnreadCountContainer}>
              <Text style={styles.replyUnreadCount}>{reply.unreadMessageCount}</Text>
            </View>
          ) : (
            <View style={styles.replyBigUnreadCountContainer}>
              <View style={styles.replyUnreadCountWrapper}>
                <Text style={styles.replyUnreadCount}>{reply.unreadMessageCount}</Text>
              </View>
            </View>
          )
        ) : null}
        <Text style={styles.replySenderName}>
          {reply.partner.name}
          {reply.partner.isDeleted ? "（脱退）" : ""}
        </Text>
        <Text style={styles.replyUpdatedAt}>{checkDate(reply.recentMessage.updatedAt)}</Text>
        <Text style={styles.replyBody}>{reply.recentMessage.text}</Text>
      </View>
    </Button>
  )
})

export const options: ScreenOptions = {
  ...commonHeaderOptionsOnNestedScreen,
  title: "連絡詳細",
}

const largePaddingHorizontal = 35

const optionStyle = StyleSheet.create({
  headerRight: {
    marginRight: 2,
  },
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  centerLayout: {
    display: "flex",
    alignItems: "center",
    paddingBottom: 20,
  },
  startLayout: {
    display: "flex",
    alignItems: "flex-start",
  },
  buttonsPC: {
    maxWidth: 300,
    width: 300,
    fontSize: 18,
    padding: 10,
    borderRadius: 26,
    alignContent: "center",
  },
  oneColumn: {
    maxWidth: 1020,
    marginLeft: "20%",
    marginRight: "20%",
    paddingLeft: 30,
    paddingRight: 30,
  },
  containerLarge: {
    flex: 1,
    backgroundColor: Colors.white3,
    paddingLeft: 10,
    paddingRight: 10,
  },
  viewLarge: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    paddingVertical: 20,
  },
  viewSp: {
    paddingVertical: 22,
    paddingHorizontal: 15,
  },
  loadingIndicator: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  main: {
    backgroundColor: Colors.white3,
  },
  topDateContainer: {
    marginTop: 30,
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: largePaddingHorizontal,
  },
  topUpdateDateContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: largePaddingHorizontal,
  },
  topDateText: {
    fontSize: 12,
    color: Colors.cement,
  },
  topDateTextEdited: {
    marginTop: 2,
  },
  reservedLabelContainer: {
    backgroundColor: Colors.cement,
    paddingVertical: 2,
    paddingHorizontal: 10,
    borderRadius: 16,
  },
  reservedLabelText: {
    fontSize: 12,
    color: Colors.white,
  },
  editedTagContainer: {
    paddingHorizontal: 10,
    borderRadius: 15,
    backgroundColor: Colors.veryLightPink2,
    marginRight: "auto",
    marginTop: 6,
    marginLeft: largePaddingHorizontal,
  },
  editedTagText: {
    fontSize: 13,
    lineHeight: 24,
    color: Colors.red,
  },
  titleContainer: {
    fontSize: 16,
    flexDirection: "row",
    lineHeight: 22,
    marginTop: 6,
    paddingHorizontal: largePaddingHorizontal,
    marginBottom: 4,
  },
  contactType: {
    fontWeight: "bold",
    color: Colors.orange,
  },
  titleDelimiter: {
    color: Colors.cement,
  },
  title: {
    fontWeight: "bold",
    color: Colors.greyshBrown,
  },
  senderContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: largePaddingHorizontal,
    marginTop: 6,
  },
  senderInner: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flexShrink: 1,
  },
  senderName: {
    fontSize: 13,
    color: Colors.greyshBrown,
    marginLeft: 8,
  },
  favoriteButton: {
    marginLeft: 10,
  },
  receiverContainer: {
    marginTop: 14,
    paddingVertical: 13,
    paddingHorizontal: largePaddingHorizontal,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderTopColor: Colors.white2,
    borderBottomColor: Colors.white2,
  },
  receiverText: {
    fontSize: 15,
    fontWeight: "bold",
    color: Colors.orange,
  },
  locationContainer: {
    marginTop: 16,
    paddingHorizontal: largePaddingHorizontal,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  locationName: {
    fontSize: 15,
    fontWeight: "bold",
    marginLeft: 4,
    color: Colors.greyshBrown,
  },
  bodyContainer: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.white2,
    paddingHorizontal: largePaddingHorizontal,
    marginTop: 14,
    paddingBottom: 15,
  },
  body: {
    fontSize: 14,
    lineHeight: 24,
    color: Colors.black,
  },
  displayAllButtonWrapper: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 13,
  },
  optionContainer: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.white2,
    paddingTop: 22,
    paddingBottom: 22,
    paddingHorizontal: 15,
  },
  optionContainerToRellpy: {
    borderColor: Colors.orange,
    borderBottomWidth: 2,
  },
  optionContainerPC: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.white2,
    paddingVertical: 22,
    paddingHorizontal: 15,
  },
  optionLarge: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    paddingVertical: 20,
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: Colors.white2,
  },
  optionContainerLarge: {
    display: "flex",
    flexDirection: "column",
    fontSize: 18,
    paddingVertical: 22,
    paddingHorizontal: 35,
    backgroundColor: Colors.white3,
    borderRadius: 26,
    alignItems: "flex-start",
    alignContent: "center",
  },
  optionContainerSurveyLarge: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    fontSize: 18,
    paddingVertical: 22,
    paddingHorizontal: 35,
    backgroundColor: Colors.white3,
    alignItems: "center",
    alignContent: "center",
    borderBottomWidth: 1,
    borderBottomColor: Colors.white2,
  },
  threeToggleButtonWrapper: {
    marginTop: 13,
  },
  secondaryTextButtonWrapper: {
    marginTop: 15,
  },
  secondaryTextButtonWrapperLarge: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  primaryTextButtonContainer: {
    paddingHorizontal: 15,
  },
  surveyItemLabelWrapper: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  deadlineDateFormat: {
    flexDirection: "row",
    alignItems: "center",
    color: "#ee8327",
    paddingLeft: 10,
    fontSize: 15,
  },
  surveyItemSubLabel: {
    fontSize: 18,
    color: Colors.greyshBrown,
    marginLeft: 15,
  },
  surveyTimeLimitLabelWrapper: {
    marginRight: "auto",
    marginTop: 8,
  },
  questionContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: 21,
  },
  questionContainerLarge: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: 21,
    paddingLeft: 35,
  },
  questionTextLarge: {
    fontSize: 15,
    fontWeight: "bold",
    color: Colors.greyshBrown,
    marginLeft: 10,
    marginRight: 2,
    paddingLeft: 35,
  },
  questionText: {
    fontSize: 15,
    fontWeight: "bold",
    color: Colors.greyshBrown,
    marginLeft: 10,
    marginRight: 2,
  },
  surveyAnswerButtonCommon: {
    borderRadius: 22,
    borderWidth: 1,
    paddingVertical: 11,
    paddingHorizontal: 25,
    alignItems: "flex-start",
    justifyContent: "center",
    marginTop: 10,
  },
  surveyAnswerButtonCommonLarge: {
    borderRadius: 22,
    borderWidth: 1,
    paddingVertical: 11,
    paddingHorizontal: 25,
    alignItems: "flex-start",
    justifyContent: "center",
    marginTop: 10,
    marginLeft: 35,
    width: 300,
  },
  surveyAnswerButtonSelected: {
    borderColor: Colors.orange,
    backgroundColor: Colors.pale,
  },
  surveyAnswerButtonNeutral: {
    borderColor: Colors.silver,
    backgroundColor: Colors.white7,
  },
  surveyAnswerButtonTextCommon: {
    fontSize: 15,
    fontWeight: "bold",
  },
  surveyAnswerButtonTextSelected: {
    color: Colors.black2,
  },
  surveyAnswerButtonTextNeutral: {
    color: Colors.coolGrey,
  },
  unreadButtonContainer: {
    backgroundColor: "rgba(159, 134, 107, 0.1)",
    borderRadius: 6,
    paddingVertical: 5,
    paddingHorizontal: 15,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  unreadButtonNumeratorText: {
    fontSize: 27,
    fontWeight: "bold",
    lineHeight: 28,
    color: Colors.leaf,
    textAlignVertical: "bottom",
  },
  unreadButtonDenominatorText: {
    fontSize: 15,
    fontWeight: "bold",
    lineHeight: 28,
    color: Colors.greyshBrown,
    textAlignVertical: "bottom",
  },
  unreadResultButtonWrapper: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  unreadResultButtonContainer: {
    backgroundColor: "rgba(159, 134, 107, 0.1)",
    borderRadius: 6,
    paddingVertical: 5,
    paddingHorizontal: 15,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 10,
  },
  unreadResultButtonText: {
    fontSize: 15,
    color: Colors.greyshBrown,
  },
  unreadResultButtonHighlightText: {
    fontSize: 15,
    fontWeight: "bold",
    color: Colors.orange,
    marginLeft: 3,
  },
  sendReminderContainer: {
    paddingVertical: 22,
    paddingHorizontal: 15,
  },
  repliesContainer: {
    paddingVertical: 22,
    paddingHorizontal: 15,
    backgroundColor: Colors.duckEggBlue,
  },
  repliesItemLabelWrapper: {
    marginBottom: 7,
  },
  replyContainer: {
    borderRadius: 16,
    marginTop: 9,
    flexDirection: "row",
    padding: 15,
    backgroundColor: Colors.white3,
  },
  replySenderImage: {
    width: 44,
    height: 44,
    borderRadius: 22,
  },
  replyTextContainer: {
    marginLeft: 8,
    flex: 1,
    position: "relative",
  },
  replyUnreadCountContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    width: 24,
    height: 24,
    borderRadius: 12,
    backgroundColor: Colors.brightSkyBlue,
    justifyContent: "center",
    alignItems: "center",
  },
  replyBigUnreadCountContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    borderRadius: 12,
    backgroundColor: Colors.brightSkyBlue,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
  replyUnreadCountWrapper: {
    flexDirection: "row",
    alignItems: "center",
  },
  replyUnreadCount: {
    fontSize: 15,
    fontWeight: "bold",
    color: Colors.white3,
  },
  replySenderName: {
    fontSize: 15,
    fontWeight: "bold",
    lineHeight: 21,
    color: Colors.greyshBrown,
    width: "90%",
  },
  replyUpdatedAt: {
    fontSize: 12,
    lineHeight: 17,
    color: Colors.cement,
  },
  replyBody: {
    fontSize: 14,
    lineHeight: 23,
    color: Colors.black,
    marginTop: 5,
    flexWrap: "wrap",
  },
  senderMenuBottomSheetRowContainer: {
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 13,
  },
  senderMenuBottomSheetRowSeparator: {
    height: 1,
    width: "100%",
    backgroundColor: Colors.lightGrey,
  },
  senderMenuBottomSheetRowText: {
    fontSize: 16,
    color: Colors.greyshBrown,
  },
  backgroundModal: {
    position: "absolute",
    zIndex: 100,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  mainModal: {
    backgroundColor: Colors.white3,
    padding: 24,
    borderRadius: 24,
    shadowOffset: { width: -1, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
  },
  secondLayer: { backgroundColor: "#f0f0f0", opacity: 0.4, width: "100%", height: "100%" },
  textButton: { fontSize: 18, fontWeight: "800", lineHeight: 25.2, textAlign: "center" },
  buttonCustom: { margin: 5, width: 130, borderRadius: 20, padding: 6, borderColor: Colors.orange, borderWidth: 2 },
  titleModal: {
    fontSize: 18,
    fontWeight: "800",
    width: "100%",
    textAlign: "center",
    paddingRight: 33,
  },
  content: {
    fontSize: 14,
    fontWeight: "400",
    width: "100%",
    textAlign: "left",
    paddingHorizontal: 30,
  },
  terms: {
    color: Colors.orange,
    fontWeight: "800",
    marginVertical: 10,
    textDecorationLine: "underline",
    paddingHorizontal: 30,
  },
})
