import { useMemo } from "react"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { permissionSelector } from "src/recoil/selectors/account/permissionSelector"
import { useContactDetailData } from "./useContactDetailData"
import { myProfileSelectorFamily } from "src/recoil/selectors/organization/myProfile/myProfileSelectorFamily"
import { ContactType } from "src/constants/ContactType"

interface Props {
  contactId?: string
  viewMode?: "Inbox" | "Outbox" | "Reserved" | "Migration"
  organizationId?: string
}

export const useCheckAllowAccountPermission = (props: Props) => {
  const { contactId, viewMode, organizationId } = props
  const { value: accountPermission, isLoading: permissionLoading } = useAsyncSelector(permissionSelector)

  const { value } = useContactDetailData({ contactId, viewMode: viewMode ?? "Inbox" })
  const { value: myProfile } = useAsyncSelector(myProfileSelectorFamily(organizationId))

  const allowEditOrganizationDetail = useMemo(() => {
    return accountPermission?.management.team.canSetting ?? false
  }, [accountPermission])

  const allowViewMembers = useMemo(() => {
    return accountPermission?.list.canVisibleListMember ?? false
  }, [accountPermission])

  const allowViewPendingInvitations = useMemo(() => {
    return accountPermission?.management.member.canApproveMember ?? false
  }, [accountPermission])

  const allowCreateGroup = useMemo(() => {
    return accountPermission?.management.group.canCreateGroup ?? false
  }, [accountPermission])

  const allowEditGroup = useMemo(() => {
    return accountPermission?.management.group.canUpdateGroup ?? false
  }, [accountPermission])

  const allowDeleteTeam = useMemo(() => {
    return accountPermission?.management.team.canDelete ?? false
  }, [accountPermission])

  const allowInviteMember = useMemo(() => {
    return accountPermission?.management.member.canInviteTeam ?? false
  }, [accountPermission])

  const allowEditMember = useMemo(() => {
    return accountPermission?.management.member.canUpdateMemberInfo ?? false
  }, [accountPermission])

  const allowDeleteMember = useMemo(() => {
    return accountPermission?.management.member.canDeleteMember ?? false
  }, [accountPermission])

  const allowCreateContact = useMemo(() => {
    if (accountPermission?.mail.make.accessAll) {
      return true
    }
    if (accountPermission?.mail.make.groupIds.length ?? 0 > 0) {
      return true
    }
    return false
  }, [accountPermission])

  const listGroupAccessAll = useMemo(() => {
    return accountPermission?.list.listGroup.accessAll ?? false
  }, [accountPermission])

  const listGroupIds = useMemo(() => {
    return accountPermission?.list.listGroup.groupIds ?? []
  }, [accountPermission])

  const allowExportCsv = useMemo(() => {
    return accountPermission?.management.member.canExportCsv ?? false
  }, [accountPermission])

  const allowRoleSetting = useMemo(() => {
    return accountPermission?.management.member.canRoleSetting ?? false
  }, [accountPermission])

  const allowCreateOrder = useMemo(() => {
    return accountPermission?.order.canCreateOrder ?? false
  }, [accountPermission])

  const allowShowOrder = useMemo(() => {
    return accountPermission?.order.showOrder ?? false
  }, [accountPermission])

  const allowTransferApplicant = useMemo(() => {
    return accountPermission?.order.canTransferApplicant ?? false
  }, [accountPermission])

  const allowViewAnswerSummary = useMemo(() => {
    const isSurveyForm =
      value?.contactType === ContactType.Attendance ||
      value?.contactType === ContactType.Survey ||
      value?.contactType === ContactType.ScheduleAdjustment
    const isSender = myProfile?.memberId === value?.sender.memberId
    if (!isSender && viewMode === "Inbox" && isSurveyForm) {
      const showAnswerResultCount = value?.isPublishAnswersToMembers
        ? accountPermission?.mail.recv?.memberListPublishOn?.showAnswerResultCount
        : accountPermission?.mail.recv?.memberListPublishOff?.showAnswerResultCount

      const isAcceptAll = showAnswerResultCount?.accessAll
      const groupIds = showAnswerResultCount?.groupIds || []
      if (!isAcceptAll && groupIds?.length > 0) {
        if (value?.receivers.isAllMembers || (value?.receivers.memberIds && value?.receivers.memberIds.length > 0)) {
          return true
        }
        return groupIds.some((el) => value.receivers.groupIds?.includes(el))
      }
      return isAcceptAll
    }

    if (!isSender && viewMode === "Outbox" && isSurveyForm) {
      const showAnswerResultCount = value?.isPublishAnswersToMembers
        ? accountPermission?.mail?.recv.send?.memberListPublishOn.showAnswerResultCount
        : accountPermission?.mail?.recv.send?.memberListPublishOff?.showAnswerResultCount

      const isAcceptAll = showAnswerResultCount?.accessAll
      const groupIds = showAnswerResultCount?.groupIds || []
      if (!isAcceptAll && groupIds?.length > 0) {
        if (value?.receivers.isAllMembers || (value?.receivers.memberIds && value?.receivers.memberIds.length > 0)) {
          return true
        }
        return groupIds.some((el) => value.receivers.groupIds?.includes(el))
      }
      return isAcceptAll
    }
    return isSender
  }, [viewMode, value, accountPermission, myProfile])

  const allowViewAnswerMemberList = useMemo(() => {
    const isSurveyForm =
      value?.contactType === ContactType.Attendance ||
      value?.contactType === ContactType.Survey ||
      value?.contactType === ContactType.ScheduleAdjustment
    const isSender = myProfile?.memberId === value?.sender.memberId
    if (!isSender && viewMode === "Inbox" && isSurveyForm) {
      const showAnswerResultListMembers = value?.isPublishAnswersToMembers
        ? accountPermission?.mail.recv.memberListPublishOn?.showAnswerResultListMembers
        : accountPermission?.mail.recv.memberListPublishOff?.showAnswerResultListMembers

      const isAcceptAll = showAnswerResultListMembers?.accessAll
      const groupIds = showAnswerResultListMembers?.groupIds || []
      if (!isAcceptAll && groupIds?.length > 0) {
        if (value?.receivers.isAllMembers || (value?.receivers.memberIds && value?.receivers.memberIds.length > 0)) {
          return true
        }
        return groupIds.some((el) => value.receivers.groupIds?.includes(el))
      }
      return isAcceptAll
    }
    if (!isSender && viewMode === "Outbox" && isSurveyForm) {
      const showAnswerResultListMembers = value?.isPublishAnswersToMembers
        ? accountPermission?.mail?.recv.send?.memberListPublishOn?.showAnswerResultListMembers
        : accountPermission?.mail?.recv.send?.memberListPublishOff?.showAnswerResultListMembers

      const isAcceptAll = showAnswerResultListMembers?.accessAll
      const groupIds = showAnswerResultListMembers?.groupIds || []

      if (!isAcceptAll && groupIds?.length > 0) {
        if (value?.receivers.isAllMembers || (value?.receivers.memberIds && value?.receivers.memberIds.length > 0)) {
          return true
        }
        return groupIds.some((el) => value.receivers.groupIds?.includes(el))
      }
      return isAcceptAll
    }
    return isSender
  }, [viewMode, value, accountPermission, myProfile])

  const allowViewRecipient = useMemo(() => {
    const isSurveyForm =
      value?.contactType === "Attendance" || value?.contactType === "Survey" || value?.contactType === "ScheduleAdjustment"
    const isSender = myProfile?.memberId === value?.sender.memberId
    if (!isSender && viewMode === "Inbox" && isSurveyForm) {
      const showRecipientCount = value?.isPublishAnswersToMembers
        ? accountPermission?.mail.recv.memberListPublishOn?.showRecipientCount
        : accountPermission?.mail.recv.memberListPublishOff?.showRecipientCount

      const isAcceptAll = showRecipientCount?.accessAll
      const groupIds = showRecipientCount?.groupIds || []
      if (!isAcceptAll && groupIds?.length > 0) {
        if (value?.receivers.isAllMembers || (value?.receivers.memberIds && value?.receivers.memberIds.length > 0)) {
          return true
        }
        return groupIds.some((el) => value.receivers.groupIds?.includes(el))
      }
      return isAcceptAll
    }

    if (!isSender && viewMode === "Outbox" && isSurveyForm) {
      const showRecipientCount = value?.isPublishAnswersToMembers
        ? accountPermission?.mail?.recv.send?.memberListPublishOn?.showRecipientCount
        : accountPermission?.mail?.recv.send?.memberListPublishOff?.showRecipientCount

      const isAcceptAll = showRecipientCount?.accessAll
      const groupIds = showRecipientCount?.groupIds || []
      if (!isAcceptAll && groupIds?.length > 0) {
        if (value?.receivers.isAllMembers || (value?.receivers.memberIds && value?.receivers.memberIds.length > 0)) {
          return true
        }
        return groupIds.some((el) => value.receivers.groupIds?.includes(el))
      }
      return isAcceptAll
    }

    if (!isSender && viewMode === "Inbox" && value?.contactType === "Normal") {
      const showRecipientCount = accountPermission?.mail.recv.memberListPublishOn?.showRecipientCount

      const isAcceptAll = showRecipientCount?.accessAll
      const groupIds = showRecipientCount?.groupIds || []
      if (!isAcceptAll && groupIds?.length > 0) {
        if (value?.receivers.isAllMembers || (value?.receivers.memberIds && value?.receivers.memberIds.length > 0)) {
          return true
        }
        return groupIds.some((el) => value.receivers.groupIds?.includes(el))
      }
      return isAcceptAll
    }

    if (!isSender && viewMode === "Outbox" && value?.contactType === "Normal") {
      const showRecipientCount = accountPermission?.mail?.recv.send?.memberListPublishOn?.showRecipientCount

      const isAcceptAll = showRecipientCount?.accessAll
      const groupIds = showRecipientCount?.groupIds || []
      if (!isAcceptAll && groupIds?.length > 0) {
        if (value?.receivers.isAllMembers || (value?.receivers.memberIds && value?.receivers.memberIds.length > 0)) {
          return true
        }
        return groupIds.some((el) => value.receivers.groupIds?.includes(el))
      }
      return isAcceptAll
    }

    return isSender
  }, [viewMode, value, accountPermission, myProfile])

  const allowViewRecipientList = useMemo(() => {
    const isSurveyForm =
      value?.contactType === "Attendance" || value?.contactType === "Survey" || value?.contactType === "ScheduleAdjustment"
    const isSender = myProfile?.memberId === value?.sender.memberId
    if (!isSender && viewMode === "Inbox" && isSurveyForm) {
      const showRecipientListMembers = value?.isPublishAnswersToMembers
        ? accountPermission?.mail.recv.memberListPublishOn?.showRecipientListMembers
        : accountPermission?.mail.recv.memberListPublishOff?.showRecipientListMembers

      const isAcceptAll = showRecipientListMembers?.accessAll
      const groupIds = showRecipientListMembers?.groupIds || []
      if (!isAcceptAll && groupIds?.length > 0) {
        if (value?.receivers.isAllMembers || (value?.receivers.memberIds && value?.receivers.memberIds.length > 0)) {
          return true
        }
        return groupIds.some((el) => value.receivers.groupIds?.includes(el))
      }
      return isAcceptAll
    }

    if (!isSender && viewMode === "Outbox" && isSurveyForm) {
      const showRecipientListMembers = value?.isPublishAnswersToMembers
        ? accountPermission?.mail?.recv.send?.memberListPublishOn?.showRecipientListMembers
        : accountPermission?.mail?.recv.send?.memberListPublishOff?.showRecipientListMembers

      const isAcceptAll = showRecipientListMembers?.accessAll
      const groupIds = showRecipientListMembers?.groupIds || []
      if (!isAcceptAll && groupIds?.length > 0) {
        if (value?.receivers.isAllMembers || (value?.receivers.memberIds && value?.receivers.memberIds.length > 0)) {
          return true
        }
        return groupIds.some((el) => value.receivers.groupIds?.includes(el))
      }
      return isAcceptAll
    }

    if (value?.contactType === "Normal" && !isSender && viewMode === "Outbox") {
      const showRecipientListMembers = accountPermission?.mail?.recv.send?.memberListPublishOn?.showRecipientListMembers
      const isAcceptAll = showRecipientListMembers?.accessAll
      const groupIds = showRecipientListMembers?.groupIds || []
      if (!isAcceptAll && groupIds?.length > 0) {
        if (value?.receivers.isAllMembers || (value?.receivers.memberIds && value?.receivers.memberIds.length > 0)) {
          return true
        }
        return groupIds.some((el) => value.receivers.groupIds?.includes(el))
      }
      return isAcceptAll
    }

    if (value?.contactType === "Normal" && !isSender && viewMode === "Inbox") {
      const showRecipientListMembers = accountPermission?.mail.recv.memberListPublishOn?.showRecipientListMembers
      const isAcceptAll = showRecipientListMembers?.accessAll
      const groupIds = showRecipientListMembers?.groupIds || []
      if (!isAcceptAll && groupIds?.length > 0) {
        if (value?.receivers.isAllMembers || (value?.receivers.memberIds && value?.receivers.memberIds.length > 0)) {
          return true
        }
        return groupIds.some((el) => value.receivers.groupIds?.includes(el))
      }
      return isAcceptAll
    }

    return isSender
  }, [viewMode, value, accountPermission, myProfile])

  const allowViewReadCount = useMemo(() => {
    const isSurveyForm =
      value?.contactType === "Attendance" || value?.contactType === "Survey" || value?.contactType === "ScheduleAdjustment"
    const isSender = myProfile?.memberId === value?.sender.memberId
    if (!isSender && viewMode === "Inbox" && isSurveyForm) {
      const showReadCount = value?.isPublishAnswersToMembers
        ? accountPermission?.mail.recv.memberListPublishOn?.showReadCount
        : accountPermission?.mail.recv.memberListPublishOff?.showReadCount

      const isAcceptAll = showReadCount?.accessAll
      const groupIds = showReadCount?.groupIds || []
      if (!isAcceptAll && groupIds?.length > 0) {
        if (value?.receivers.isAllMembers || (value?.receivers.memberIds && value?.receivers.memberIds.length > 0)) {
          return true
        }
        return groupIds.some((el) => value.receivers.groupIds?.includes(el))
      }
      return isAcceptAll
    }

    if (!isSender && viewMode === "Outbox" && isSurveyForm) {
      const showReadCount = value?.isPublishAnswersToMembers
        ? accountPermission?.mail?.recv.send?.memberListPublishOn?.showReadCount
        : accountPermission?.mail?.recv.send?.memberListPublishOff?.showReadCount

      const isAcceptAll = showReadCount?.accessAll
      const groupIds = showReadCount?.groupIds || []
      if (!isAcceptAll && groupIds?.length > 0) {
        if (value?.receivers.isAllMembers || (value?.receivers.memberIds && value?.receivers.memberIds.length > 0)) {
          return true
        }
        return groupIds.some((el) => value.receivers.groupIds?.includes(el))
      }
      return isAcceptAll
    }

    if (!isSender && viewMode === "Inbox" && value?.contactType === "Normal") {
      const showReadCount = accountPermission?.mail.recv.memberListPublishOn?.showReadCount
      const isAcceptAll = showReadCount?.accessAll
      const groupIds = showReadCount?.groupIds || []
      if (!isAcceptAll && groupIds?.length > 0) {
        if (value?.receivers.isAllMembers || (value?.receivers.memberIds && value?.receivers.memberIds.length > 0)) {
          return true
        }
        return groupIds.some((el) => value.receivers.groupIds?.includes(el))
      }
      return isAcceptAll
    }

    if (!isSender && viewMode === "Outbox" && value?.contactType === "Normal") {
      const showReadCount = accountPermission?.mail?.recv.send?.memberListPublishOn?.showReadCount
      const isAcceptAll = showReadCount?.accessAll
      const groupIds = showReadCount?.groupIds || []
      if (!isAcceptAll && groupIds?.length > 0) {
        if (value?.receivers.isAllMembers || (value?.receivers.memberIds && value?.receivers.memberIds.length > 0)) {
          return true
        }
        return groupIds.some((el) => value.receivers.groupIds?.includes(el))
      }
      return isAcceptAll
    }

    return isSender
  }, [viewMode, value, accountPermission, myProfile])

  const allowViewReadListMember = useMemo(() => {
    const isSurveyForm =
      value?.contactType === "Attendance" || value?.contactType === "Survey" || value?.contactType === "ScheduleAdjustment"
    const isSender = myProfile?.memberId === value?.sender.memberId
    if (!isSender && viewMode === "Inbox" && isSurveyForm) {
      const showReadListMembers = value?.isPublishAnswersToMembers
        ? accountPermission?.mail.recv.memberListPublishOn?.showReadListMembers
        : accountPermission?.mail.recv.memberListPublishOff?.showReadListMembers

      const isAcceptAll = showReadListMembers?.accessAll
      const groupIds = showReadListMembers?.groupIds || []
      if (!isAcceptAll && groupIds?.length > 0) {
        if (value?.receivers.isAllMembers || (value?.receivers.memberIds && value?.receivers.memberIds.length > 0)) {
          return true
        }
        return groupIds.some((el) => value.receivers.groupIds?.includes(el))
      }
      return isAcceptAll
    }

    if (!isSender && viewMode === "Outbox" && isSurveyForm) {
      const showReadListMembers = value?.isPublishAnswersToMembers
        ? accountPermission?.mail?.recv.send?.memberListPublishOn?.showReadListMembers
        : accountPermission?.mail?.recv.send?.memberListPublishOff?.showReadListMembers

      const isAcceptAll = showReadListMembers?.accessAll
      const groupIds = showReadListMembers?.groupIds || []
      if (!isAcceptAll && groupIds?.length > 0) {
        if (value?.receivers.isAllMembers || (value?.receivers.memberIds && value?.receivers.memberIds.length > 0)) {
          return true
        }
        return groupIds.some((el) => value.receivers.groupIds?.includes(el))
      }
      return isAcceptAll
    }

    if (!isSender && viewMode === "Inbox" && value?.contactType === "Normal") {
      const showReadListMembers = accountPermission?.mail.recv.memberListPublishOn?.showReadListMembers
      const isAcceptAll = showReadListMembers?.accessAll
      const groupIds = showReadListMembers?.groupIds || []
      if (!isAcceptAll && groupIds?.length > 0) {
        if (value?.receivers.isAllMembers || (value?.receivers.memberIds && value?.receivers.memberIds.length > 0)) {
          return true
        }
        return groupIds.some((el) => value.receivers.groupIds?.includes(el))
      }
      return isAcceptAll
    }

    if (!isSender && viewMode === "Outbox" && value?.contactType === "Normal") {
      const showReadListMembers = accountPermission?.mail?.recv.send?.memberListPublishOn?.showReadListMembers
      const isAcceptAll = showReadListMembers?.accessAll
      const groupIds = showReadListMembers?.groupIds || []
      if (!isAcceptAll && groupIds?.length > 0) {
        if (value?.receivers.isAllMembers || (value?.receivers.memberIds && value?.receivers.memberIds.length > 0)) {
          return true
        }
        return groupIds.some((el) => value.receivers.groupIds?.includes(el))
      }
      return isAcceptAll
    }

    return isSender
  }, [viewMode, value, accountPermission, myProfile])

  const allowViewAnsweredListMembers = useMemo(() => {
    const isSurveyForm =
      value?.contactType === "Attendance" || value?.contactType === "Survey" || value?.contactType === "ScheduleAdjustment"
    const isSender = myProfile?.memberId === value?.sender.memberId
    if (!isSender && viewMode === "Inbox" && isSurveyForm) {
      const showAnsweredListMembers = value?.isPublishAnswersToMembers
        ? accountPermission?.mail.recv.memberListPublishOn?.showAnsweredListMembers
        : accountPermission?.mail.recv.memberListPublishOff?.showAnsweredListMembers

      const isAcceptAll = showAnsweredListMembers?.accessAll
      const groupIds = showAnsweredListMembers?.groupIds || []
      if (!isAcceptAll && groupIds?.length > 0) {
        if (value?.receivers.isAllMembers || (value?.receivers.memberIds && value?.receivers.memberIds.length > 0)) {
          return true
        }
        return groupIds.some((el) => value.receivers.groupIds?.includes(el))
      }
      return isAcceptAll
    }

    if (!isSender && viewMode === "Outbox" && isSurveyForm) {
      const showAnsweredListMembers = value?.isPublishAnswersToMembers
        ? accountPermission?.mail?.recv.send?.memberListPublishOn?.showAnsweredListMembers
        : accountPermission?.mail?.recv.send?.memberListPublishOff?.showAnsweredListMembers

      const isAcceptAll = showAnsweredListMembers?.accessAll
      const groupIds = showAnsweredListMembers?.groupIds || []
      if (!isAcceptAll && groupIds?.length > 0) {
        if (value?.receivers.isAllMembers || (value?.receivers.memberIds && value?.receivers.memberIds.length > 0)) {
          return true
        }
        return groupIds.some((el) => value.receivers.groupIds?.includes(el))
      }
      return isAcceptAll
    }
    return isSender
  }, [viewMode, value, accountPermission, myProfile])

  return {
    allowEditOrganizationDetail,
    allowViewMembers,
    allowViewPendingInvitations,
    allowCreateGroup,
    allowEditGroup,
    allowDeleteTeam,
    allowInviteMember,
    allowEditMember,
    allowDeleteMember,
    allowCreateContact,
    listGroupAccessAll,
    listGroupIds,
    allowRoleSetting,
    allowExportCsv,
    allowViewAnswerSummary,
    allowViewRecipient,
    allowViewAnswerMemberList,
    allowViewReadCount,
    allowViewAnsweredListMembers,
    allowViewReadListMember,
    allowViewRecipientList,
    allowCreateOrder,
    allowShowOrder,
    allowTransferApplicant,
    permissionLoading,
  }
}
