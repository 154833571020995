import Constants from "expo-constants"

export const oldRa9Ra9Login = (loginId: string, password: string) => {
  const url =  urlOld()
  return fetch(`https://${url}/v1/users/login`, {
    method: "POST",
    mode: "cors",
    body: JSON.stringify({
      login_id: loginId,
      password: password,
    }),
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    }})
}

export const oldRa9Ra9GetTeams = (accessToken: string, userId: number) => {
  const url =  urlOld()
  return fetch(`https://${url}/v1/users/${userId}/teams1?per_page=100`, {
    method: "GET",
    mode: "cors",
    headers: {
      "Authorization": `Bearer ${accessToken}`,
    }})
}

const urlOld = () => {
  let domain = "spitz.ra9.jp"
  if (Constants.expoConfig?.extra?.env == "prd") {
    domain = "ra9.jp"
  }
  if (Constants.expoConfig?.extra?.env == "stg") {
    domain = "rev.ra9.jp"
  }
  return domain;
}

export const getOldAccountInfo = (accessToken: string, userId: number) => {
  const url = urlOld()
  return fetch(`https://${url}/v1/users/${userId}?user_id=&team_id=&member_id=&_=`, {
  method: 'GET',
  mode: 'cors',
  headers: {
    "Authorization": `Bearer ${accessToken}`
  }})
}
export const migrationAsMember = (accessToken: string, userId: number,team_id:number, member_id:number,body:string) => {
  const url = urlOld()
  return fetch(`https://${url}/v1/members/${member_id}/inquire_admin?user_id=${userId}&team_id=${team_id}&member_id=${member_id}`, {
  method: 'POST',
  mode: 'cors',
   body: JSON.stringify({
     body: body
    }),
  headers: {
    "Authorization": `Bearer ${accessToken}`,
    "Content-Type": "application/json;charset=UTF-8",
  }})
}