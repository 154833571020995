import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { teamMembersSelectorFamily } from "src/recoil/selectors/organization/teamMemberSelectorFamily"
import { teamMemberState } from "src/recoil/atoms/organization/teamMemberState"
import { useRecoilState } from "recoil"
import { currentMemberPageState } from "src/recoil/atoms/organization/currentMemberPageState"
import { teamMembersRequestIdState } from "src/recoil/atoms/organization/teamMembersRequestIdState"
import { useRefresher } from "src/hooks/useRefresher"
import { debounce } from "lodash"
import { useWindowDimensions } from "react-native"
import { organizationDetailSelectorFamily } from "src/recoil/selectors/organization/organizationDetailSelectorFamily"
export const useOrganizationMembersData = (organizationId: string, getImage: boolean) => {
  const [currentPage, setCurrentPage] = useRecoilState(currentMemberPageState)
  const prevOrganizationIdRef = useRef(organizationId)
  const [teamMembers, setTeamMembers] = useRecoilState(teamMemberState)
  const [searchText, setSearchText] = useState("")
  const [debouncedSearchText, setDebouncedSearchText] = useState("")
  const refreshMembers = useRefresher(teamMembersRequestIdState)
  const heightScreen = useWindowDimensions().height
  const limitMembers = useMemo(() => (heightScreen - 350 > 610 ? 20 : 10), [heightScreen])
  const { value: organization } = useAsyncSelector(organizationDetailSelectorFamily(organizationId))
  const { value: members, isLoading: isLoadingMembers } = useAsyncSelector(
    teamMembersSelectorFamily({
      organizationId: organizationId,
      getImage: getImage,
      limit: limitMembers,
      page: currentPage,
      searchText: debouncedSearchText,
    })
  )
  useEffect(() => {
    return () => {
      setCurrentPage(1)
      setSearchText("")
      refreshMembers()
    }
  }, [setCurrentPage, setSearchText, refreshMembers])
  const totalMember = useMemo(() => members?.total || 0, [members])
  const maxPages = useMemo(() => Math.ceil(totalMember / limitMembers), [totalMember, limitMembers])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearchHandler = useCallback(
    debounce((nextSearchText) => {
      setDebouncedSearchText(nextSearchText)
      setCurrentPage(1)
    }, 500),
    []
  )
  useEffect(() => {
    if (debouncedSearchText === "") {
      setCurrentPage(1)
    }
    debouncedSearchHandler(searchText)
    return () => debouncedSearchHandler.cancel()
  }, [searchText, debouncedSearchText, debouncedSearchHandler, setCurrentPage])
  useEffect(() => {
    if (members && members.data) {
      if (currentPage === 1) {
        setTeamMembers(members.data)
      } else {
        setTeamMembers((prevMembers) => {
          const newMembers =
            members?.data?.filter((member) => !prevMembers.some((prevMember) => prevMember.id === member.id)) ?? []
          return [...prevMembers, ...newMembers]
        })
      }
    }
  }, [members, members?.data, currentPage, setTeamMembers, debouncedSearchText])

  useEffect(() => {
    if (prevOrganizationIdRef.current !== organizationId) {
      setCurrentPage(1)
      prevOrganizationIdRef.current = organizationId
    }
  }, [organizationId, setTeamMembers, setCurrentPage])
  const getMoreMembers = useCallback(() => {
    const nextPage = currentPage + 1
    if (nextPage <= maxPages) {
      setCurrentPage(nextPage)
    }
  }, [currentPage, maxPages, setCurrentPage])

  return {
    totalMember,
    members,
    teamMembers,
    debouncedSearchText,
    setTeamMembers,
    currentPage,
    maxPages,
    searchText,
    setSearchText,
    isLoadingMembers,
    refreshMembers,
    getMoreMembers,
    organization,
  }
}
