import { useCallback } from "react"
import { useResetRecoilState, useSetRecoilState } from "recoil"
import { bodyState } from "src/recoil/atoms/contact/create/bodyState"
import { contactTypeState } from "src/recoil/atoms/contact/create/contactTypeState"
import { deadlineDateState } from "src/recoil/atoms/contact/create/deadlineDateState"
import { draftIdState } from "src/recoil/atoms/contact/create/draftIdState"
import { eventDateCandidatesState } from "src/recoil/atoms/contact/create/eventDateCandidatesState"
import { isAddToOtherAdminsHistoryState } from "src/recoil/atoms/contact/create/isAddToOtherAdminsHistoryState"
import { isPublishAnswersToMembersState } from "src/recoil/atoms/contact/create/isPublishAnswersToMembersState"
import { isReceiversAllLeadersState } from "src/recoil/atoms/contact/create/isReceiversAllLeadersState"
import { isReceiversAllMembersState } from "src/recoil/atoms/contact/create/isReceiversAllMembersState"
import { questionsState } from "src/recoil/atoms/contact/create/questionsState"
import { reminderDateState } from "src/recoil/atoms/contact/create/reminderDateState"
import { reservationDateState } from "src/recoil/atoms/contact/create/reservationDateState"
import { selectedGroupIdsState } from "src/recoil/atoms/contact/create/selectedGroupIdsState"
import { selectedMemberIdsState } from "src/recoil/atoms/contact/create/selectedMemberIdsState"
import { templateIdState } from "src/recoil/atoms/contact/create/templateIdState"
import { templateRequestIdState } from "src/recoil/atoms/contact/create/templateRequestIdState"
import { draftRequestIdState } from "src/recoil/atoms/contact/draftRequestIdState"
import { titleState } from "src/recoil/atoms/contact/create/titleState"
import { templatesRequestIdState } from "src/recoil/atoms/contact/templatesRequestIdState"
import { useIncrementRecoilState } from "src/recoil/hooks/useIncrementRecoilState"
import { existingContactIdState } from "src/recoil/atoms/contact/create/existingContactIdState"
import { contactDetailRequestIdState } from "src/recoil/atoms/contact/contactDetailRequestIdState"
import { createContactModeState } from "src/recoil/atoms/contact/create/createContactModeState"
import { migrationIdState } from "src/recoil/atoms/contact/create/migrationIdState"
import { reservedIdState } from "src/recoil/atoms/contact/create/reservedIdState"
import { reservedRequestIdState } from "src/recoil/atoms/contact/reservedRequestIdState"
import { migrationRequestIdState } from "src/recoil/atoms/contact/migrationRequestIdState"
import { attachmentFileNamesState } from "src/recoil/atoms/contact/create/attachmentFileNamesState"

type Params = {
  mode: "New" | "Edit" | "EditReserved"
  contactId?: string
  reservedId?: string
  templateId?: string
  draftId?: string
  migrationId?: string
}

export const useResetter = () => {
  const resetTitle = useResetRecoilState(titleState)
  const resetBody = useResetRecoilState(bodyState)
  const resetContactType = useResetRecoilState(contactTypeState)
  const resetDeadlineDate = useResetRecoilState(deadlineDateState)
  const resetEventDateCandidate = useResetRecoilState(eventDateCandidatesState)
  const resetIsAddToOtherAdminsHistory = useResetRecoilState(isAddToOtherAdminsHistoryState)
  const resetIsPublishAnswersToMembers = useResetRecoilState(isPublishAnswersToMembersState)
  const resetIsReceiversAllLeaders = useResetRecoilState(isReceiversAllLeadersState)
  const resetIsReceiversAllMembers = useResetRecoilState(isReceiversAllMembersState)
  const resetAttachmentFileNames = useResetRecoilState(attachmentFileNamesState)
  const resetQuestions = useResetRecoilState(questionsState)
  const resetReminderDate = useResetRecoilState(reminderDateState)
  const resetReservationDate = useResetRecoilState(reservationDateState)
  const resetSelectedGroupIds = useResetRecoilState(selectedGroupIdsState)
  const resetSelectedMemberIds = useResetRecoilState(selectedMemberIdsState)
  const setMode = useSetRecoilState(createContactModeState)
  const setContactId = useSetRecoilState(existingContactIdState)
  const refreshContact = useIncrementRecoilState(contactDetailRequestIdState)
  const setReservedId = useSetRecoilState(reservedIdState)
  const refreshReserved = useIncrementRecoilState(reservedRequestIdState)
  const setTemplateId = useSetRecoilState(templateIdState)
  const refreshTemplates = useIncrementRecoilState(templatesRequestIdState)
  const refreshTemplate = useIncrementRecoilState(templateRequestIdState)
  const setDraftId = useSetRecoilState(draftIdState)
  const refreshDraft = useIncrementRecoilState(draftRequestIdState)
  const setMigrationId = useSetRecoilState(migrationIdState)
  const refreshMigration = useIncrementRecoilState(migrationRequestIdState)

  return useCallback(
    (params?: Params) => {
      const mode = params?.mode || "New"
      const contactId = params?.contactId
      const reservedId = params?.reservedId
      const templateId = params?.templateId
      const draftId = params?.draftId
      const migrationId = params?.migrationId

      refreshContact()
      refreshReserved()
      refreshTemplates()
      refreshTemplate()
      refreshDraft()
      refreshMigration()
      setMode(mode)
      setContactId(contactId)
      setReservedId(reservedId)
      setTemplateId(templateId)
      setDraftId(draftId)
      setMigrationId(migrationId)
      resetBody()
      resetContactType()
      resetDeadlineDate()
      resetEventDateCandidate()
      resetIsAddToOtherAdminsHistory()
      resetIsPublishAnswersToMembers()
      resetIsReceiversAllLeaders()
      resetIsReceiversAllMembers()
      resetAttachmentFileNames()
      resetQuestions()
      resetReminderDate()
      resetReservationDate()
      resetSelectedGroupIds()
      resetSelectedMemberIds()
      resetTitle()
    },
    [
      resetBody,
      resetContactType,
      resetDeadlineDate,
      resetEventDateCandidate,
      resetIsAddToOtherAdminsHistory,
      resetIsPublishAnswersToMembers,
      resetIsReceiversAllLeaders,
      resetIsReceiversAllMembers,
      resetAttachmentFileNames,
      resetQuestions,
      resetReminderDate,
      resetReservationDate,
      resetSelectedGroupIds,
      resetSelectedMemberIds,
      refreshContact,
      refreshReserved,
      refreshTemplates,
      refreshTemplate,
      refreshMigration,
      setMode,
      setContactId,
      setReservedId,
      setTemplateId,
      setDraftId,
      setMigrationId,
      refreshDraft,
      resetTitle,
    ]
  )
}
