import { ComponentProps, memo, ReactNode, useMemo } from "react"
import { KeyboardAvoidingView, Platform, StyleSheet } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view"

type Props = {
  children: ReactNode
  style?: ComponentProps<typeof KeyboardAvoidingView>["style"]
  behavior?: "height" | "position" | "padding" | undefined
  noContainer?: boolean
  useAware?: boolean
}

export const CustomKeyboardAvoidingView = memo<Props>(({ children, style, behavior = "padding", noContainer, useAware }) => {
  const insets = useSafeAreaInsets()
  const overallInsetHeight = useMemo(() => insets.top + insets.bottom, [insets])
  return (
    <>
      {Platform.OS === "web" && useAware ? (
        <KeyboardAwareScrollView
          contentContainerStyle={{ flexGrow: 1 }}
          keyboardShouldPersistTaps={"handled"}
          enableOnAndroid={true}
          extraHeight={180}
          keyboardOpeningTime={0}
          enableResetScrollToCoords={false}
        >
          {children}
        </KeyboardAwareScrollView>
      ) : (
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? behavior : undefined}
          style={[noContainer ? null : styles.container, style]}
          enabled
          keyboardVerticalOffset={Platform.select({
            ios: behavior === "padding" ? overallInsetHeight : undefined,
            android: undefined,
          })}
        >
          {children}
        </KeyboardAvoidingView>
      )}
    </>
  )
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})
