export const getAccount = /* GraphQL */ `
  query GetAccountGql($id: ID!) {
    getAccount(id: $id) {
      id
      email
      phoneNumber
      lastName
      firstName
      lastNameKana
      firstNameKana
      gender
      birthday
      job {
        id
        label
      }
      school {
        id
        label
      }
      schoolDepartment {
        id
        label
      }
      schoolGraduationYear
      prefecture {
        id
        label
      }
      city {
        id
        label
      }
      questionAnswers {
        items {
          question {
            id
            label
          }
          answer {
            id
            label
          }
        }
      }
      matchingTermsAgreedAt
      agreedMatchingTermsVersion
      signupStatus
      createdAt
      updatedAt
    }
  }
`

export const registerAuthCode = /* GraphQL */ `
  mutation RegisterAuthCodeGql($input: RegisterAuthCodeInput!) {
    registerAuthCode(input: $input) {
      message
      data {
        auth {
          ChallengeName
          Session
          ChallengeParameters
          AuthenticationResult {
            AccessToken
            ExpiresIn
            TokenType
            RefreshToken
            IdToken
            NewDeviceMetadata
          }
        }
        user {
          id
          lastName
          firstName
          lastNameKana
          firstNameKana
          email
          signupStatus
        }
      }
    }
  }
`

export const updateNotificationSetting = /* GraphQL */ `
  mutation UpdateNotificationSettingGql($input: UpdateNotificationSettingInput!) {
    updateNotificationSetting(input: $input) {
      id
    }
  }
`

export const listTeamNotice = /* GraphQL */ `
  query ListTeamNoticeGql($accountId: ID!) {
    getAccount(id: $accountId) {
      teamNotices(sortDirection: DESC, limit: 100) {
        items {
          id
          noticeType
          title
          url
          readFlg
          team {
            id
            name
            paidFunctionEnabled
          }
          teamMemberTeamNoticeId
          createdAt
        }
      }
    }
  }
`

export const markTeamNoticeAsRead = /* GraphQL */ `
  mutation MarkTeamNoticeAsReadGql($id: ID!, $readFlg: Boolean!) {
    updateTeamNotice(input: { id: $id, readFlg: $readFlg }) {
      id
    }
  }
`

export const listTeam = /* GraphQL */ `
  query ListTeamGql($accountId: ID!, $notDeleteFlg: Boolean!) {
    getAccount(id: $accountId) {
      haveOldRa9TeamNotJoin
      teamMembers(filter: { deleteFlg: { ne: $notDeleteFlg } }, limit: 300) {
        items {
          id
          role
          memberSeq
          deleteFlg
          team {
            id
            name
            invitationCode
            createdAt
            updatedAt
            migrationId
            paidFunctionEnabled
            showAdsFlg
          }
        }
      }
    }
  }
`

export const getTeam = /* GraphQL */ `
  query GetTeamGql($id: ID!) {
    getTeam(id: $id) {
      id
      name
      invitationCode
      category {
        id
        label
      }
      activity {
        id
        label
      }
      prefecture {
        id
        label
      }
      city {
        id
        label
      }
      description
      requirementForMemberProfile
      customQuestions
      showMemberListFlg
      createdAt
      updatedAt
      migrationId
      paidFunctionEnabled
      showAdsFlg
    }
  }
`

export const getInvitationInfo = /* GraphQL */ `
  query GetInvitationInfoGql($teamId: ID!) {
    getTeam(id: $teamId) {
      invitationCode
    }
  }
`

export const getTeamByInvitationCode = /* GraphQL */ `
  query GetTeamByInvitationCodeGql($accountId: ID!, $invitationCode: String!) {
    getTeamByInvitationCode(invitationCode: $invitationCode) {
      items {
        id
        name
        requirementForMemberProfile
        customQuestions
      }
    }
    getAccount(id: $accountId) {
      teamMembers {
        items {
          team {
            id
          }
        }
      }
      preTeamMembers {
        items {
          team {
            id
          }
        }
      }
    }
  }
`

export const listPreTeamMember = /* GraphQL */ `
  query ListPreTeamMemberGql($teamId: ID!, $nextToken: String) {
    getTeam(id: $teamId) {
      id
      name
      preTeamMembers(limit: 300, nextToken: $nextToken) {
        items {
          id
          nickname
        }
        nextToken
      }
    }
  }
`

export const listInvitationEmailStatus = /* GraphQL */ `
  query ListInvitationEmailStatusGql($teamId: ID!, $limit: Int!, $nextToken: String) {
    getTeam(id: $teamId) {
      invitationEmailStatuses(limit: $limit, nextToken: $nextToken, sortDirection: DESC) {
        items {
          id
          email
          status
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`

export const listJobMaster = /* GraphQL */ `
  query ListJobMasterGql {
    listJobMaster(limit: 300) {
      items {
        id
        label
        type
        displayNumber
        displayFlg
      }
    }
  }
`

export const listTeamMember = /* GraphQL */ `
  query ListTeamMemberGql($teamId: ID!, $notDeleteFlg: Boolean!, $nextToken: String) {
    getTeam(id: $teamId) {
      teamMembers(filter: { deleteFlg: { ne: $notDeleteFlg } }, limit: 300, nextToken: $nextToken) {
        items {
          id
          memberSeq
          nickname
          role
          groups {
            items {
              id
              leaderFlg
              group {
                id
              }
            }
          }
        }
        nextToken
      }
    }
  }
`

export const listTeamMemberCsvData = /* GraphQL */ `
  query ListTeamMemberCsvDataGql($teamId: ID!, $nextToken: String, $notDeleteFlg: Boolean!) {
    getTeam(id: $teamId) {
      teamMembers(filter: { deleteFlg: { ne: $notDeleteFlg } }, limit: 300, nextToken: $nextToken) {
        items {
          id
          memberSeq
          nickname
          nicknameKana
          role
        }
        nextToken
      }
    }
  }
`

export const getTeamMember = /* GraphQL */ `
  query GetTeamMemberGql($id: ID!) {
    getTeamMember(id: $id) {
      id
      memberSeq
      nickname
      nicknameKana
      role
      account {
        email
      }
      showEmailFlg
      phoneNumber
      showPhoneNumberFlg
      belongs
      showBelongsFlg
      grade
      mailMigratedFlg
    }
  }
`

export const getAccountTeamMember = /* GraphQL */ `
  query GetAccountTeamMemberGql($accountId: ID!, $teamId: ID!) {
    getAccount(id: $accountId) {
      teamMembers(teamTeamMembersId: { eq: $teamId }) {
        items {
          team {
            id
          }
          id
          memberSeq
          nickname
          nicknameKana
          role
          account {
            email
          }
          showEmailFlg
          phoneNumber
          showPhoneNumberFlg
          belongs
          showBelongsFlg
          grade
          mailMigratedFlg
        }
      }
    }
  }
`

export const listAccountTeamMember = /* GraphQL */ `
  query ListAccountTeamMemberGql($accountId: ID!, $nextToken: String) {
    getAccount(id: $accountId) {
      teamMembers(nextToken: $nextToken) {
        items {
          team {
            id
          }
          id
          memberSeq
          nickname
          nicknameKana
          role
          account {
            email
          }
          showEmailFlg
          phoneNumber
          showPhoneNumberFlg
          belongs
          showBelongsFlg
          grade
          mailMigratedFlg
        }
        nextToken
      }
    }
  }
`

export const getGroup = /* GraphQL */ `
  query GetGroupGql($id: ID!, $nextToken: String) {
    getGroup(id: $id) {
      id
      name
      groupMembers(limit: 300, nextToken: $nextToken) {
        items {
          leaderFlg
          teamMember {
            id
            nickname
            memberSeq
            role
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`

export const listTeamMemberMail = /* GraphQL */ `
  query ListTeamMemberMailGql($filter: SearchableTeamMemberMailFilterInput!, $count: Int, $nextToken: String) {
    searchTeamMemberMails(filter: $filter, sort: { field: createdAt, direction: desc }, limit: $count, nextToken: $nextToken) {
      total
      items {
        id
        readFlg
        favoriteFlgReceived
        favoriteFlgSent
        answeredFlg
        answerRequiredFlg
        mail {
          id
          mailType
          title
          sender {
            id
            nickname
            deleteFlg
          }
          attachmentFileNames
          sentAt
          responseDueDatetime
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`

export const getMail = /* GraphQL */ `
  query GetMailGql($teamMemberMailId: ID!) {
    getTeamMemberMail(id: $teamMemberMailId) {
      favoriteFlgReceived
      favoriteFlgSent
      readFlg
      attendanceAnswer
      questionnaireAnswers {
        answer
      }
      scheduleAnswers
      mail {
        id
        teamId
        mailType
        title
        sender {
          id
          nickname
          deleteFlg
        }
        body
        sendTo {
          allMembersFlg
          managersFlg
          groupIds
          memberIds
        }
        attachmentFileNames
        startDate
        startTime
        endDate
        endTime
        questionnaires {
          answerSelectType
          question
          choices
        }
        candidateDatetimes {
          startDate
          startTime
          endDate
          endTime
        }
        shareManagerFlg
        showAnswerFlg
        responseDueDatetime
        remindDatetime
        remindExecDatetime
        sentAt
        createdAt
        updatedAt
      }
    }
  }
`

export const listMemberGroup = /* GraphQL */ `
  query ListMemberGroupGql($teamId: ID!, $nextToken: String) {
    getTeam(id: $teamId) {
      teamMembers(limit: 300, nextToken: $nextToken) {
        items {
          id
          groups(limit: 300) {
            items {
              leaderFlg
              group {
                id
                name
              }
            }
          }
        }
        nextToken
      }
    }
  }
`

export const getReservedMail = /* GraphQL */ `
  query GetReservedMailGql($id: ID!) {
    getReservedMail(id: $id) {
      id
      mailType
      title
      teamMember {
        id
        nickname
      }
      body
      sendTo {
        allMembersFlg
        managersFlg
        groupIds
        memberIds
      }
      attachmentFileNames
      startDate
      startTime
      endDate
      endTime
      questionnaires {
        answerSelectType
        question
        choices
      }
      candidateDatetimes {
        startDate
        startTime
        endDate
        endTime
      }
      shareManagerFlg
      showAnswerFlg
      responseDueDatetime
      remindDatetime
      sendScheduledAt
      createdAt
      updatedAt
      teamMemberReservedMailsId
    }
  }
`

export const updateTeamMemberMail = /* GraphQL */ `
  mutation UpdateTeamMemberMailGql($input: UpdateTeamMemberMailInput!) {
    updateTeamMemberMail(input: $input) {
      id
    }
  }
`

export const listReservedMail = /* GraphQL */ `
  query ListReservedMailGql($teamMemberId: ID!) {
    getTeamMember(id: $teamMemberId) {
      id
      nickname
      reservedMails(limit: 300) {
        items {
          id
          mailType
          title
          body
          attachmentFileNames
          sendScheduledAt
          createdAt
          updatedAt
        }
      }
    }
  }
`

export const updateReservedMail = /* GraphQL */ `
  mutation UpdateReservedMailGql($input: UpdateReservedMailInput!) {
    updateReservedMail(input: $input) {
      id
    }
  }
`

export const deleteReservedMail = /* GraphQL */ `
  mutation DeleteReservedMailGql($input: DeleteReservedMailInput!) {
    deleteReservedMail(input: $input) {
      id
    }
  }
`

export const listDraft = /* GraphQL */ `
  query ListDraftGql($teamMemberId: ID!) {
    getTeamMember(id: $teamMemberId) {
      drafts(limit: 300) {
        items {
          id
          mailType
          title
          body
          attachmentFileNames
          updatedAt
        }
      }
    }
  }
`

export const updateDraft = /* GraphQL */ `
  mutation UpdateDraftGql($input: UpdateDraftInput!) {
    updateDraft(input: $input) {
      id
    }
  }
`

export const deleteDraft = /* GraphQL */ `
  mutation DeleteDraftGql($input: DeleteDraftInput!) {
    deleteDraft(input: $input) {
      id
    }
  }
`

export const listTemplate = /* GraphQL */ `
  query ListTemplateGql($teamMemberId: ID!) {
    getTeamMember(id: $teamMemberId) {
      templates(limit: 300) {
        items {
          id
          mailType
          title
          body
          favoriteFlg
          updatedAt
          createdAt
        }
      }
    }
  }
`

export const getDraft = /* GraphQL */ `
  query GetDraftGql($id: ID!) {
    getDraft(id: $id) {
      id
      mailType
      title
      teamMember {
        id
        nickname
      }
      body
      sendTo {
        allMembersFlg
        managersFlg
        groupIds
        memberIds
      }
      attachmentFileNames
      questionnaires {
        answerSelectType
        question
        choices
      }
      candidateDatetimes {
        startDate
        startTime
        endDate
        endTime
      }
      shareManagerFlg
      showAnswerFlg
      responseDueDatetime
      remindDatetime
      sendScheduledAt
      createdAt
      updatedAt
      teamMemberDraftsId
    }
  }
`

export const getTemplate = /* GraphQL */ `
  query GetTemplateGql($id: ID!) {
    getTemplate(id: $id) {
      id
      mailType
      title
      teamMember {
        id
        nickname
      }
      body
      sendTo {
        allMembersFlg
        managersFlg
        groupIds
        memberIds
      }
      questionnaires {
        answerSelectType
        question
        choices
      }
      candidateDatetimes {
        startDate
        startTime
        endDate
        endTime
      }
      shareManagerFlg
      showAnswerFlg
      favoriteFlg
      updatedAt
    }
  }
`

export const updateTemplateFavoriteFlg = /* GraphQL */ `
  mutation UpdateTemplateFavoriteFlgGql($input: UpdateTemplateInput!) {
    updateTemplate(input: $input) {
      id
    }
  }
`

export const deleteTemplate = /* GraphQL */ `
  mutation DeleteTemplateGql($input: DeleteTemplateInput!) {
    deleteTemplate(input: $input) {
      id
    }
  }
`

export const listThreadByMailRecipient = /* GraphQL */ `
  query ListThreadByMailRecipientGql($mailId: ID!, $myMemberId: ID!) {
    getMail(id: $mailId) {
      threads(threadMailRecipientId: { eq: $myMemberId }) {
        items {
          id
          mailSender {
            id
            nickname
            deleteFlg
          }
          mailRecipient {
            id
            nickname
            deleteFlg
          }
        }
      }
    }
  }
`

export const listThreadByMailSender = /* GraphQL */ `
  query ListThreadByMailSenderGql($mailId: ID!, $myMemberId: ID!) {
    getMail(id: $mailId) {
      threads(filter: { threadMailSenderId: { eq: $myMemberId } }, limit: 1000) {
        items {
          id
          mailSender {
            id
            nickname
            deleteFlg
          }
          mailRecipient {
            id
            nickname
            deleteFlg
          }
        }
      }
    }
  }
`

export const getLatestThreadMessage = /* GraphQL */ `
  query GetLatestThreadMessageGql($threadId: ID!, $notDeleteFlg: Boolean!) {
    searchThreadMessages(
      filter: { threadMessagesId: { eq: $threadId }, deleteFlg: { ne: $notDeleteFlg } }
      sort: { field: createdAt, direction: desc }
      limit: 1
    ) {
      items {
        text
        messageSenderId
        createdAt
        updatedAt
      }
    }
  }
`

export const getUnreadThreadMessageCount = /* GraphQL */ `
  query GetUnreadThreadMessageCountGql(
    $filter: SearchableThreadMessageFilterInput
    $aggregates: [SearchableThreadMessageAggregationInput]
  ) {
    searchThreadMessages(aggregates: $aggregates, filter: $filter) {
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
        }
      }
    }
  }
`

export const getUnreadThreadMessageCountByIds = /* GraphQL */ `
  query GetUnreadThreadMessageCountByIdsGql(
    $filter: SearchableThreadMessageFilterInput
    $aggregates: [SearchableThreadMessageAggregationInput]
  ) {
    searchThreadMessages(aggregates: $aggregates, filter: $filter) {
      aggregateItems {
        name
        result {
          ... on SearchableAggregateBucketResult {
            buckets {
              doc_count
              key
            }
          }
        }
      }
    }
  }
`

export const hasUnreadThreadMessage = /* GraphQL */ `
  query HasUnreadThreadMessageGql($accountId: ID!, $teamId: ID!, $unreadFlg: Int!, $notDeleteFlg: Boolean!) {
    searchThreadMessages(
      filter: {
        messageRecipientAccountId: { eq: $accountId }
        teamId: { eq: $teamId }
        unreadFlg: { eq: $unreadFlg }
        deleteFlg: { ne: $notDeleteFlg }
      }
      limit: 1
    ) {
      items {
        id
        messageRecipientAccountId
        messageSenderId
      }
    }
  }
`

export const listThreadMessage = /* GraphQL */ `
  query ListThreadMessage($threadId: ID!, $nextToken: String) {
    getThread(id: $threadId) {
      mail {
        id
        title
      }
      mailSender {
        id
        nickname
        deleteFlg
      }
      mailRecipient {
        id
        nickname
        deleteFlg
      }
      messages(limit: 100, sortDirection: DESC, nextToken: $nextToken) {
        items {
          id
          messageSenderId
          text
          unreadFlg
          deleteFlg
          createdAt
          contentUpdatedAt
        }
        nextToken
      }
    }
  }
`

export const listSchoolMaster = /* GraphQL */ `
  query ListSchoolMasterGql($type: String!, $nextToken: String) {
    getSchoolMasterByType(type: $type, limit: 300, nextToken: $nextToken) {
      items {
        id
        label
        labelKana
        displayNumber
        displayFlg
      }
      nextToken
    }
  }
`

export const listSchoolDepartmentMaster = /* GraphQL */ `
  query GetSchoolDepartmentMasterGql($schoolId: ID!) {
    getSchoolMaster(id: $schoolId) {
      departments(limit: 100) {
        items {
          id
          label
          displayNumber
          displayFlg
        }
      }
    }
  }
`

export const listPrefectureMaster = /* GraphQL */ `
  query ListPrefectureMasterGql {
    listPrefectureMaster(limit: 100) {
      items {
        id
        label
        displayNumber
        displayFlg
      }
    }
  }
`

export const listCityMaster = /* GraphQL */ `
  query GetPrefectureMasterGql($prefectureId: ID!) {
    getPrefectureMaster(id: $prefectureId) {
      cities(limit: 1000) {
        items {
          id
          label
          displayNumber
          displayFlg
        }
      }
    }
  }
`

export const listTeamCategoryMaster = /* GraphQL */ `
  query ListTeamCategoryMasterGql {
    listTeamCategoryMaster(limit: 100) {
      items {
        id
        label
        displayNumber
        displayFlg
        activities(limit: 100) {
          items {
            teamActivityMaster {
              id
              label
              displayNumber
              displayFlg
            }
          }
        }
      }
    }
  }
`

export const listAccountQuestionMaster = /* GraphQL */ `
  query ListAccountQuestionMasterGql {
    listAccountQuestionMaster(limit: 100) {
      items {
        id
        label
        displayNumber
        displayFlg
        choices(limit: 100) {
          items {
            id
            label
            displayNumber
            displayFlg
          }
        }
      }
    }
  }
`

export const getFirstUnreadTeamNotice = /* GraphQL */ `
  query GetFirstUnreadTeamNoticeGql($accountId: ID!, $true: Boolean!) {
    getAccount(id: $accountId) {
      teamNotices(sortDirection: DESC, filter: { readFlg: { ne: $true } }, limit: 1) {
        items {
          id
          createdAt
        }
      }
    }
  }
`

export const getUnreadMailCount = /* GraphQL */ `
  query GetUnreadMailCountGql(
    $aggregates: [SearchableTeamMemberMailAggregationInput!]!
    $filter: SearchableTeamMemberMailFilterInput!
  ) {
    searchTeamMemberMails(aggregates: $aggregates, filter: $filter) {
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
        }
      }
    }
  }
`

export const listUnreadMailCount = /* GraphQL */ `
  query ListUnreadMailCountGql(
    $aggregates: [SearchableTeamMemberMailAggregationInput!]!
    $filter: SearchableTeamMemberMailFilterInput!
  ) {
    searchTeamMemberMails(aggregates: $aggregates, filter: $filter) {
      aggregateItems {
        name
        result {
          ... on SearchableAggregateBucketResult {
            buckets {
              doc_count
              key
            }
          }
        }
      }
    }
  }
`

export const getFirstPreTeamMemberIds = /* GraphQL */ `
  query GetPreTeamMemberIdsGql($accountId: ID!, $notDeleteFlg: Boolean, $role: String) {
    getAccount(id: $accountId) {
      teamMembers(filter: { deleteFlg: { ne: $notDeleteFlg } }, limit: 300) {
        items {
          team {
            id
            teamMembers(filter: { accountTeamMembersId: { eq: $accountId }, role: { eq: $role } }, limit: 3000) {
              items {
                id
              }
            }
            preTeamMembers(limit: 1) {
              items {
                id
              }
            }
          }
        }
      }
    }
  }
`

export const listMatchingOffer = /* GraphQL */ `
  query ListMatchingOfferGql($filter: SearchableMatchingOfferFilterInput, $limit: Int!, $nextToken: String) {
    searchMatchingOffers(filter: $filter, limit: $limit, sort: { field: updatedAt, direction: desc }, nextToken: $nextToken) {
      total
      items {
        id
        purpose
        title
        date
        startTime
        endTime
        prefecture {
          id
          label
        }
        city {
          id
          label
        }
        ageFrom
        ageTo
        publishEndDate
        activity {
          id
          label
        }
        teamId
        teamName
        deleteFlg
        createdAt
        updatedAt
        teamMatchingOffersId
      }
      nextToken
    }
  }
`

export const getMatchingOffer = /* GraphQL */ `
  query GetMatchingOfferGql($id: ID!) {
    getMatchingOffer(id: $id) {
      id
      purpose
      title
      body
      date
      startTime
      endTime
      prefecture {
        id
        label
      }
      city {
        id
        label
      }
      place
      placeURL
      expense
      expensePurpose
      ageFrom
      ageTo
      offerCount
      publishEndDate
      attachmentFileNames
      activity {
        id
        label
      }
      teamId
      teamName
      deleteFlg
      createdAt
      updatedAt
    }
  }
`

export const updateMatchingOffer = /* GraphQL */ `
  mutation UpdateMatchingOfferGql($input: UpdateMatchingOfferInput!) {
    updateMatchingOffer(input: $input) {
      id
    }
  }
`

export const listMatchingOfferDraft = /* GraphQL */ `
  query ListMatchingOfferDraftGql($teamId: ID!) {
    getTeam(id: $teamId) {
      activity {
        id
        label
      }
      matchingOfferDrafts(limit: 100) {
        items {
          id
          purpose
          title
          date
          startTime
          endTime
          prefecture {
            id
            label
          }
          city {
            id
            label
          }
          ageFrom
          ageTo
          publishEndDate
          createdAt
          updatedAt
        }
      }
    }
  }
`

export const getMatchingOfferDraft = /* GraphQL */ `
  query GetMatchingOfferDraftGql($id: ID!) {
    getMatchingOfferDraft(id: $id) {
      id
      team {
        id
        name
        activity {
          id
          label
        }
      }
      purpose
      title
      body
      date
      startTime
      endTime
      prefecture {
        id
        label
      }
      city {
        id
        label
      }
      place
      placeURL
      expense
      expensePurpose
      ageFrom
      ageTo
      offerCount
      publishEndDate
      createdAt
      updatedAt
    }
  }
`

export const updateMatchingOfferDraft = /* GraphQL */ `
  mutation UpdateMatchingOfferDraftGql($input: UpdateMatchingOfferDraftInput!) {
    updateMatchingOfferDraft(input: $input) {
      id
    }
  }
`

export const deleteMatchingOfferDraft = /* GraphQL */ `
  mutation DeleteMatchingOfferDraftGql($input: DeleteMatchingOfferDraftInput!) {
    deleteMatchingOfferDraft(input: $input) {
      id
    }
  }
`

export const listFavoriteMatchingOffer = /* GraphQL */ `
  query ListFavoriteMatchingOfferGql($teamMemberId: ID!, $nextToken: String) {
    getTeamMember(id: $teamMemberId) {
      favoriteMatchingOffer(limit: 30, nextToken: $nextToken) {
        items {
          offer {
            id
            purpose
            title
            body
            date
            startTime
            endTime
            prefecture {
              id
              label
            }
            city {
              id
              label
            }
            ageFrom
            ageTo
            publishEndDate
            activity {
              id
              label
            }
            teamId
            teamName
            deleteFlg
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`

export const createFavoriteMatchingOffer = /* GraphQL */ `
  mutation CreateFavoriteMatchingOfferGql($input: CreateFavoriteMatchingOfferInput!) {
    createFavoriteMatchingOffer(input: $input) {
      id
    }
  }
`

export const deleteFavoriteMatchingOffer = /* GraphQL */ `
  mutation DeleteFavoriteMatchingOfferGql($input: DeleteFavoriteMatchingOfferInput!) {
    deleteFavoriteMatchingOffer(input: $input) {
      id
    }
  }
`

export const listMatchingApplication = /* GraphQL */ `
  query ListMatchingApplicationGql(
    $filter: SearchableMatchingApplicationFilterInput!
    $limit: Int!
    $nextToken: String
    $notDeleteFlg: Int!
  ) {
    searchMatchingApplications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        messages(limit: 1, sortDirection: DESC, filter: { deleteFlg: { ne: $notDeleteFlg } }) {
          items {
            text
            messageSenderTeamId
            createdAt
          }
        }
        offer {
          id
          purpose
          title
          date
          startTime
          endTime
          prefecture {
            id
            label
          }
          city {
            id
            label
          }
          ageFrom
          ageTo
          publishEndDate
          activity {
            id
            label
          }
          teamId
          teamName
          deleteFlg
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`

export const multiGetApplication = /* GraphQL */ `
  query MultiGetApplicationGql($input: MultiGetApplicationInput!) {
    multiGetApplication(input: $input) {
      items {
        offerId
        applications {
          id
          messages {
            text
            messageSenderTeamId
            createdAt
          }
        }
      }
    }
  }
`

export const listMatchingMessage = /* GraphQL */ `
  query ListMatchingMessageGql($matchingApplicationId: ID!, $limit: Int!, $nextToken: String) {
    getMatchingApplication(id: $matchingApplicationId) {
      messagesIncludeDeleted(limit: $limit, sortDirection: DESC, nextToken: $nextToken) {
        items {
          id
          messageSenderTeamId
          text
          unreadFlg
          deleteFlg
          contentUpdatedAt
          createdAt
        }
      }
    }
  }
`

export const getUnreadMatchingMessageCount = /* GraphQL */ `
  query GetUnreadMatchingMessageCount($applicationIdMessageReceiverGsi: String!, $deleteFlg: Int!, $unreadFlg: Int!) {
    getMatchingMessageByApplicationIdMessageReceiverGsi(
      applicationIdMessageReceiverGsi: $applicationIdMessageReceiverGsi
      deleteFlgUnreadFlg: { eq: { deleteFlg: $deleteFlg, unreadFlg: $unreadFlg } }
      limit: 1
    ) {
      items {
        id
      }
    }
  }
`

export const getUnreadMatchingMessageForTeamCount = /* GraphQL */ `
  query GetUnreadMatchingMessageForTeamCount($input: getUnreadMatchingMessageForTeamCountInput!) {
    getUnreadMatchingMessageForTeamCount(input: $input) {
      matchingOffer {
        haveUnreadMessage
        unreadMatchingApplicationCount {
          id
          count
        }
      }
      matchingApplication {
        haveUnreadMessage
        unreadMatchingApplicationCount {
          id
          count
        }
      }
    }
  }
`

export const listBlockingMatchingTeam = /* GraphQL */ `
  query ListBlockingMatchingTeamGql($teamId: ID!, $nextToken: String) {
    getTeam(id: $teamId) {
      blockingMatchingTeams(limit: 100, nextToken: $nextToken) {
        items {
          blockingTeamId
        }
        nextToken
      }
    }
  }
`

export const listOtherTeamsBlockMyTeam = /* GraphQL */ `
  query ListOtherTeamsBlockMyTeamGql($teamId: ID!, $nextToken: String) {
    getTeam(id: $teamId) {
      blockingMatchingTeamsByBlockingTeamId(limit: 100, nextToken: $nextToken) {
        items {
          teamBlockingMatchingTeamsId
        }
        nextToken
      }
    }
  }
`

export const listTeamMemberMailByAccount = /* GraphQL */ `
  query ListTeamMemberMailByAccount2($input: ListTeamMemberMailByAccountInput!) {
    listTeamMemberMailByAccount(input: $input) {
      accountId
      teamMemberMailByAccount {
        teamId
        teamMemberMail {
          id
          mail {
            attachmentFileNames
            id
            createdAt
            mailType
            responseDueDatetime
            sentAt
            title
            updatedAt
            sender {
              deleteFlg
              id
              nickname
            }
          }
          readFlg
          answerRequiredFlg
          answeredFlg
          favoriteFlgReceived
          favoriteFlgSent
        }
      }
    }
  }
`

export const listMigratedMail = /* GraphQL */ `
  query ListMigratedMailGql($filter: SearchableMigratedMailFilterInput, $nextToken: String) {
    searchMigratedMails(filter: $filter, sort: { field: sentDatetime, direction: desc }, limit: 30, nextToken: $nextToken) {
      total
      items {
        id
        title
        sender
        favoriteFlg
        sentDatetime
      }
      nextToken
    }
  }
`

export const updateMigratedMail = /* GraphQL */ `
  mutation UpdateMigratedMailGql($input: UpdateMigratedMailInput!) {
    updateMigratedMail(input: $input) {
      id
    }
  }
`

export const deleteMigratedMail = /* GraphQL */ `
  mutation DeleteMigratedMailGql($input: DeleteMigratedMailInput!) {
    deleteMigratedMail(input: $input) {
      id
    }
  }
`

export const getFullPermissionAccount = /* GraphQL */ `
  query GetFullPermissionAccount($input: GetPermissionAccountInput) {
    getPermissionAccount(input: $input) {
      teamId
      management {
        team {
          canSetting
          canDelete
        }
        member {
          canInviteTeam
          canApproveMember
          canRoleSetting
          canDeleteMember
          canUpdateMemberInfo
          canExportCsv
        }
        group {
          canCreateGroup
          canUpdateGroup
          canUpdateMemberInfo
          canUpdateGroupLeader
          canDeleteGroup
        }
      }
      list {
        canVisibleListMember
        listGroup {
          accessAll
          groupIds
        }
      }
      mail {
        make {
          accessAll
          groupIds
        }
        recv {
          memberListPublishOff {
            showAnswerResultCount {
              accessAll
              groupIds
            }
            showAnswerResultListMembers {
              accessAll
              groupIds
            }
            showAnsweredCount {
              accessAll
              groupIds
            }
            showAnsweredListMembers {
              accessAll
              groupIds
            }
            showReadCount {
              accessAll
              groupIds
            }
            showReadListMembers {
              accessAll
              groupIds
            }
            showRecipientCount {
              accessAll
              groupIds
            }
            showRecipientListMembers {
              accessAll
              groupIds
            }
          }
          memberListPublishOn {
            showAnswerResultCount {
              accessAll
              groupIds
            }
            showAnswerResultListMembers {
              accessAll
              groupIds
            }
            showAnsweredCount {
              accessAll
              groupIds
            }
            showAnsweredListMembers {
              accessAll
              groupIds
            }
            showReadCount {
              accessAll
              groupIds
            }
            showReadListMembers {
              accessAll
              groupIds
            }
            showRecipientCount {
              accessAll
              groupIds
            }
            showRecipientListMembers {
              accessAll
              groupIds
            }
          }
          send {
            memberListPublishOff {
              showAnswerResultCount {
                accessAll
                groupIds
              }
              showAnswerResultListMembers {
                accessAll
                groupIds
              }
              showAnsweredCount {
                accessAll
                groupIds
              }
              showAnsweredListMembers {
                accessAll
                groupIds
              }
              showReadCount {
                accessAll
                groupIds
              }
              showReadListMembers {
                accessAll
                groupIds
              }
              showRecipientCount {
                accessAll
                groupIds
              }
              showRecipientListMembers {
                accessAll
                groupIds
              }
            }
            memberListPublishOn {
              showAnswerResultCount {
                accessAll
                groupIds
              }
              showAnswerResultListMembers {
                accessAll
                groupIds
              }
              showAnsweredCount {
                accessAll
                groupIds
              }
              showAnsweredListMembers {
                accessAll
                groupIds
              }
              showReadCount {
                accessAll
                groupIds
              }
              showReadListMembers {
                accessAll
                groupIds
              }
              showRecipientCount {
                accessAll
                groupIds
              }
              showRecipientListMembers {
                accessAll
                groupIds
              }
            }
          }
        }
      }
      order {
        showOrder
        canTransferApplicant
        canCreateOrder
        canUpdateOrder
        canCancelOrder
        canExtendOrder
        canChangePlan
        canCreatePaidTeamInquiry
      }
    }
  }
`
