/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAccountId = /* GraphQL */ `
  query GetAccountId {
    getAccountId {
      id
    }
  }
`
export const getAccountInfo = /* GraphQL */ `
  query GetAccountInfo {
    getAccountInfo {
      id
      email
      lastName
      firstName
      lastNameKana
      firstNameKana
      phoneNumber
      gender
      birthday
      accountJobId
      accountSchoolId
      accountSchoolDepartmentId
      schoolGraduationYear
      accountPrefectureId
      accountCityId
      questionAnswers {
        question {
          id
          label
        }
        answer {
          id
          label
        }
      }
      termsAgreedAt
      agreedTermsVersion
      matchingTermsAgreedAt
      agreedMatchingTermsVersion
      signupStatus
      createdAt
      updatedAt
    }
  }
`
export const getPermissionAccount = /* GraphQL */ `
  query GetPermissionAccount($input: GetPermissionAccountInput) {
    getPermissionAccount(input: $input) {
      teamId
      management {
        team {
          canSetting
          canDelete
        }
        member {
          canInviteTeam
          canApproveMember
          canRoleSetting
          canDeleteMember
          canUpdateMemberInfo
          canExportCsv
        }
        group {
          canCreateGroup
          canUpdateGroup
          canUpdateMemberInfo
          canUpdateGroupLeader
          canDeleteGroup
        }
      }
      list {
        canVisibleListMember
        listGroup {
          accessAll
          groupIds
        }
      }
      mail {
        make {
          accessAll
          groupIds
        }
      }
      order {
        showOrder
        canTransferApplicant
        canCreateOrder
        canUpdateOrder
        canCancelOrder
        canExtendOrder
        canChangePlan
        canCreatePaidTeamInquiry
      }
    }
  }
`
export const canDeleteAccount = /* GraphQL */ `
  query CanDeleteAccount {
    canDeleteAccount {
      canDelete
      message
    }
  }
`
export const listAllMigratedTeams = /* GraphQL */ `
  query ListAllMigratedTeams {
    listAllMigratedTeams {
      migratedTeams {
        id
        name
        invitationCode
        migrationId
        joinFlg
        image {
          url
          isDefault
        }
        role
        createdAt
        updatedAt
      }
    }
  }
`
export const listNotJoinMembers = /* GraphQL */ `
  query ListNotJoinMembers($input: ListNotJoinMembersInput) {
    listNotJoinMembers(input: $input) {
      notJoinMembers {
        id
        email
        nickname
        nicknameKana
        role
        joinFlg
        image {
          url
          isDefault
        }
      }
      total
      page
      pageSize
    }
  }
`
export const listAdminNotice = /* GraphQL */ `
  query ListAdminNotice {
    listAdminNotice {
      items {
        id
        label
        title
        priority
        publishDatetime
        readFlg
        createdAt
        updatedAt
      }
    }
  }
`
export const getTeamInvitationMessage = /* GraphQL */ `
  query GetTeamInvitationMessage($input: GetTeamInvitationMessageInput!) {
    getTeamInvitationMessage(input: $input) {
      title
      message
    }
  }
`
export const getInvitationCodeByVolatileId = /* GraphQL */ `
  query GetInvitationCodeByVolatileId($input: GetInvitationCodeByVolatileIdInput!) {
    getInvitationCodeByVolatileId(input: $input) {
      invitationCode
    }
  }
`
export const getTeamMemberCount = /* GraphQL */ `
  query GetTeamMemberCount($input: GetTeamMemberCountInput!) {
    getTeamMemberCount(input: $input) {
      memberCount
    }
  }
`
export const listGroup = /* GraphQL */ `
  query ListGroup($input: ListGroupInput!) {
    listGroup(input: $input) {
      items {
        id
        name
      }
    }
  }
`
export const getUnreadReceivedMailExistence = /* GraphQL */ `
  query GetUnreadReceivedMailExistence($input: GetUnreadReceivedMailExistenceInput!) {
    getUnreadReceivedMailExistence(input: $input) {
      hasUnreadMail
    }
  }
`
export const listReceivedTeamMemberMail = /* GraphQL */ `
  query ListReceivedTeamMemberMail($input: ListReceivedTeamMemberMailInput) {
    listReceivedTeamMemberMail(input: $input) {
      mailType
      teamMemberMails {
        showReadFlg
        readFlg
        readAt
        showAnsweredFlg
        answeredFlg
        answeredAt
        attendanceAnswer
        questionnaireAnswers {
          answer
        }
        scheduleAnswers
        teamMember {
          id
          nickname
          memberSeq
          deleteFlg
        }
      }
    }
  }
`
export const listReservedTeamMemberMail = /* GraphQL */ `
  query ListReservedTeamMemberMail($input: ListReservedTeamMemberMailInput!) {
    listReservedTeamMemberMail(input: $input) {
      mailType
      teamMemberMails {
        showReadFlg
        readFlg
        readAt
        showAnsweredFlg
        answeredFlg
        answeredAt
        attendanceAnswer
        questionnaireAnswers {
          answer
        }
        scheduleAnswers
        teamMember {
          id
          nickname
          memberSeq
          deleteFlg
        }
      }
    }
  }
`
export const listTeamMemberMailByAccount = /* GraphQL */ `
  query ListTeamMemberMailByAccount($input: ListTeamMemberMailByAccountInput!) {
    listTeamMemberMailByAccount(input: $input) {
      accountId
      teamMemberMailByAccount {
        teamId
        teamMemberMail {
          id
          readFlg
          favoriteFlgReceived
          favoriteFlgSent
          answeredFlg
          answerRequiredFlg
        }
      }
    }
  }
`
export const listThread = /* GraphQL */ `
  query ListThread($input: ListThreadInput!) {
    listThread(input: $input) {
      items {
        member {
          id
          nickname
          deleteFlg
        }
        lastMessage {
          text
          createdAt
        }
      }
    }
  }
`
export const getTeamMemberId = /* GraphQL */ `
  query GetTeamMemberId($input: GetTeamMemberIdInput!) {
    getTeamMemberId(input: $input) {
      id
    }
  }
`
export const getEmptyThread = /* GraphQL */ `
  query GetEmptyThread($input: GetEmptyThreadInput!) {
    getEmptyThread(input: $input) {
      mail {
        id
        title
      }
      mailSender {
        id
        nickname
        deleteFlg
      }
      mailRecipient {
        id
        nickname
        deleteFlg
      }
    }
  }
`
export const getAccountImageGetUrl = /* GraphQL */ `
  query GetAccountImageGetUrl($input: GetAccountImageGetUrlInput!) {
    getAccountImageGetUrl(input: $input) {
      url
      isDefault
    }
  }
`
export const getAccountImagePutUrl = /* GraphQL */ `
  query GetAccountImagePutUrl($input: GetAccountImagePutUrlInput!) {
    getAccountImagePutUrl(input: $input) {
      url
      thumbnailUrl
    }
  }
`
export const getTeamImageGetUrl = /* GraphQL */ `
  query GetTeamImageGetUrl($input: GetTeamImageGetUrlInput!) {
    getTeamImageGetUrl(input: $input) {
      url
      isDefault
    }
  }
`
export const multiTeamImageGetUrl = /* GraphQL */ `
  query MultiTeamImageGetUrl($input: MultiTeamImageGetUrlInput!) {
    multiTeamImageGetUrl(input: $input) {
      teamImages {
        teamId
        thumbnail
        image {
          url
          isDefault
        }
      }
    }
  }
`
export const getTeamImagePutUrl = /* GraphQL */ `
  query GetTeamImagePutUrl($input: GetTeamImagePutUrlInput!) {
    getTeamImagePutUrl(input: $input) {
      url
      thumbnailUrl
    }
  }
`
export const getTeamMemberImageGetUrl = /* GraphQL */ `
  query GetTeamMemberImageGetUrl($input: GetTeamMemberImageGetUrlInput!) {
    getTeamMemberImageGetUrl(input: $input) {
      url
      isDefault
    }
  }
`
export const multiTeamMemberImageGetUrl = /* GraphQL */ `
  query MultiTeamMemberImageGetUrl($input: MultiTeamMemberImageGetUrlInput!) {
    multiTeamMemberImageGetUrl(input: $input) {
      teamMemberImages {
        teamMemberId
        thumbnail
        isPreTeamMember
        image {
          url
          isDefault
        }
      }
    }
  }
`
export const getTeamMemberImagePutUrl = /* GraphQL */ `
  query GetTeamMemberImagePutUrl($input: GetTeamMemberImagePutUrlInput!) {
    getTeamMemberImagePutUrl(input: $input) {
      url
      thumbnailUrl
    }
  }
`
export const getMailAttachmentGetUrl = /* GraphQL */ `
  query GetMailAttachmentGetUrl($input: GetMailAttachmentUrlInput!) {
    getMailAttachmentGetUrl(input: $input) {
      url
    }
  }
`
export const getMailAttachmentPutUrl = /* GraphQL */ `
  query GetMailAttachmentPutUrl($input: GetMailAttachmentUrlInput!) {
    getMailAttachmentPutUrl(input: $input) {
      url
    }
  }
`
export const getMailAttachmentThumbnailGetUrl = /* GraphQL */ `
  query GetMailAttachmentThumbnailGetUrl($input: GetMailAttachmentUrlInput!) {
    getMailAttachmentThumbnailGetUrl(input: $input) {
      url
    }
  }
`
export const getMailAttachmentThumbnailPutUrl = /* GraphQL */ `
  query GetMailAttachmentThumbnailPutUrl($input: GetMailAttachmentUrlInput!) {
    getMailAttachmentThumbnailPutUrl(input: $input) {
      url
    }
  }
`
export const getMatchingTeamImageGetUrl = /* GraphQL */ `
  query GetMatchingTeamImageGetUrl($input: GetMatchingTeamImageGetUrlInput!) {
    getMatchingTeamImageGetUrl(input: $input) {
      url
      isDefault
    }
  }
`
export const getMatchingTeamImagePutUrl = /* GraphQL */ `
  query GetMatchingTeamImagePutUrl($input: GetMatchingTeamImagePutUrlInput!) {
    getMatchingTeamImagePutUrl(input: $input) {
      url
      thumbnailUrl
    }
  }
`
export const getMatchingTeamBgImageGetUrl = /* GraphQL */ `
  query GetMatchingTeamBgImageGetUrl($input: GetMatchingTeamBgImageGetUrlInput!) {
    getMatchingTeamBgImageGetUrl(input: $input) {
      url
      isDefault
    }
  }
`
export const getMatchingTeamBgImagePutUrl = /* GraphQL */ `
  query GetMatchingTeamBgImagePutUrl($input: GetMatchingTeamBgImagePutUrlInput!) {
    getMatchingTeamBgImagePutUrl(input: $input) {
      url
    }
  }
`
export const getMatchingOfferAttachmentGetUrl = /* GraphQL */ `
  query GetMatchingOfferAttachmentGetUrl($input: GetMatchingOfferAttachmentUrlInput!) {
    getMatchingOfferAttachmentGetUrl(input: $input) {
      url
    }
  }
`
export const getMatchingOfferAttachmentPutUrl = /* GraphQL */ `
  query GetMatchingOfferAttachmentPutUrl($input: GetMatchingOfferAttachmentUrlInput!) {
    getMatchingOfferAttachmentPutUrl(input: $input) {
      url
    }
  }
`
export const getMatchingOfferAttachmentThumbnailGetUrl = /* GraphQL */ `
  query GetMatchingOfferAttachmentThumbnailGetUrl($input: GetMatchingOfferAttachmentUrlInput!) {
    getMatchingOfferAttachmentThumbnailGetUrl(input: $input) {
      url
    }
  }
`
export const getMatchingOfferAttachmentThumbnailPutUrl = /* GraphQL */ `
  query GetMatchingOfferAttachmentThumbnailPutUrl($input: GetMatchingOfferAttachmentUrlInput!) {
    getMatchingOfferAttachmentThumbnailPutUrl(input: $input) {
      url
    }
  }
`
export const getUnreadMatchingMessageForTeamCount = /* GraphQL */ `
  query GetUnreadMatchingMessageForTeamCount($input: getUnreadMatchingMessageForTeamCountInput!) {
    getUnreadMatchingMessageForTeamCount(input: $input) {
      matchingOffer {
        haveUnreadMessage
        unreadMatchingApplicationCount {
          id
          count
        }
      }
      matchingApplication {
        haveUnreadMessage
        unreadMatchingApplicationCount {
          id
          count
        }
      }
    }
  }
`
export const multiAccountImageGetUrl = /* GraphQL */ `
  query MultiAccountImageGetUrl($input: MultiAccountImageGetUrlInput!) {
    multiAccountImageGetUrl(input: $input) {
      images {
        accountId
        url
      }
    }
  }
`
export const getMatchingTeamProfile = /* GraphQL */ `
  query GetMatchingTeamProfile($input: GetMatchingTeamProfileInput!) {
    getMatchingTeamProfile(input: $input) {
      id
      ageFrom
      ageTo
      introduction
      teamUrl
      name
      category {
        id
        label
      }
      activity {
        id
        label
      }
      prefecture {
        id
        label
      }
      city {
        id
        label
      }
      isBlocking
    }
  }
`
export const multiGetFavoriteOfferId = /* GraphQL */ `
  query MultiGetFavoriteOfferId($input: MultiGetFavoriteOfferIdInput!) {
    multiGetFavoriteOfferId(input: $input) {
      favoriteOfferIds
    }
  }
`
export const multiGetApplication = /* GraphQL */ `
  query MultiGetApplication($input: MultiGetApplicationInput!) {
    multiGetApplication(input: $input) {
      items {
        offerId
        applications {
          id
        }
      }
    }
  }
`
export const getLatestTerms = /* GraphQL */ `
  query GetLatestTerms {
    getLatestTerms {
      service {
        version
        url
        releaseAt
      }
      matching {
        version
        url
        releaseAt
      }
    }
  }
`
export const multiGetMigrationStatus = /* GraphQL */ `
  query MultiGetMigrationStatus($input: MultiGetMigrationStatusInput!) {
    multiGetMigrationStatus(input: $input) {
      items {
        oldRa9Ra9TeamId
        teamId
        isMigrated
      }
    }
  }
`
export const listNotificationSetting = /* GraphQL */ `
  query ListNotificationSetting {
    listNotificationSetting {
      items {
        id
        teamId
        teamName
        teamCategory {
          id
          label
        }
        teamProfileImage
        role
        mailReceivedNotice {
          push
          email
        }
        threadMessageReceivedNotice {
          push
          email
        }
        joinRequestNotice {
          push
          email
        }
        teamMatchingNotice {
          email
        }
        createdAt
        updatedAt
      }
    }
  }
`
export const getTeamOrder = /* GraphQL */ `
  query GetTeamOrder($input: GetTeamOrderInput!) {
    getTeamOrder(input: $input) {
      usedOrder {
        order {
          id
          orderNumber
          originalOrderId
          orderStatus
          jobId
          masterCode
          zacStatus
          supportedDate
          address
          orderType
          companyName
          companyNameKana
          corporateRepresentativeName
          corporateRepresentativeNameKana
          applicantPhone
          applicantMail
          applicantName
          applicantPostcode
          applicantPrefecture
          applicantAddress
          applicantBuildingName
          applicantRemark
          antiSocialCheck
          teamId
          teamName
          applicantId
          transferApplicantId
          planId
          startDate
          endDate
          paymentStatus
          paymentDate
          paymentDeadline
          invoiceIssuedDate
          paymentType
          paymentMethod
          invoiceAddressee
          invoiceAddresseeKana
          amount
          taxRate
          tax
          subTotal
          remark
          adminId
          createdAt
          updatedAt
        }
        plan {
          id
          planCode
          name
          type
          memberLimit
          teamLimit
          period
          amount
          planType
        }
      }
      preOrder {
        order {
          id
          orderNumber
          originalOrderId
          orderStatus
          jobId
          masterCode
          zacStatus
          supportedDate
          address
          orderType
          companyName
          companyNameKana
          corporateRepresentativeName
          corporateRepresentativeNameKana
          applicantPhone
          applicantMail
          applicantName
          applicantPostcode
          applicantPrefecture
          applicantAddress
          applicantBuildingName
          applicantRemark
          antiSocialCheck
          teamId
          teamName
          applicantId
          transferApplicantId
          planId
          startDate
          endDate
          paymentStatus
          paymentDate
          paymentDeadline
          invoiceIssuedDate
          paymentType
          paymentMethod
          invoiceAddressee
          invoiceAddresseeKana
          amount
          taxRate
          tax
          subTotal
          remark
          adminId
          createdAt
          updatedAt
        }
        plan {
          id
          planCode
          name
          type
          memberLimit
          teamLimit
          period
          amount
          planType
        }
      }
    }
  }
`
export const getNextPaidVersionOrderNumber = /* GraphQL */ `
  query GetNextPaidVersionOrderNumber {
    getNextPaidVersionOrderNumber {
      nextNumber
    }
  }
`
export const listPlan = /* GraphQL */ `
  query ListPlan {
    listPlan {
      items {
        id
        planCode
        name
        type
        memberLimit
        teamLimit
        period
        amount
        taxRate
        planType
      }
    }
  }
`
export const searchTeamMember = /* GraphQL */ `
  query SearchTeamMember($input: SearchTeamMemberInput!) {
    searchTeamMember(input: $input) {
      items {
        id
        memberSeq
        nickname
        nicknameKana
        role
        showEmailFlg
        phoneNumber
        showPhoneNumberFlg
        belongs
        showBelongsFlg
        grade
        teamIdGsi
        teamTeamMembersId
        teamMemberNotificationSettingId
        mailMigratedFlg
        deleteFlg
        accountTeamMembersId
        createdAt
        updatedAt
        groups {
          id
          leaderFlg
          teamMemberID
          groupID
          createdAt
          updatedAt
        }
      }
      total
      limit
      page
      sortBy
      orderBy
    }
  }
`
export const searchGroupMember = /* GraphQL */ `
  query SearchGroupMember($input: SearchGroupMemberInput!) {
    searchGroupMember(input: $input) {
      items {
        id
        memberSeq
        nickname
        nicknameKana
        accountTeamMembersId
        role
        leaderFlg
      }
      total
      limit
      page
      sortBy
      orderBy
    }
  }
`
export const searchInvitationEmailStatuses = /* GraphQL */ `
  query SearchInvitationEmailStatuses(
    $filter: SearchableInvitationEmailStatusFilterInput
    $sort: [SearchableInvitationEmailStatusSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableInvitationEmailStatusAggregationInput]
  ) {
    searchInvitationEmailStatuses(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        email
        status
        repicaDeliverId
        repicaKeyField
        repicaSentDate
        errorMessage
        createdAt
        teamInvitationEmailStatusesId
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`
export const searchTeamMemberMails = /* GraphQL */ `
  query SearchTeamMemberMails(
    $filter: SearchableTeamMemberMailFilterInput
    $sort: [SearchableTeamMemberMailSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableTeamMemberMailAggregationInput]
  ) {
    searchTeamMemberMails(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        receivedFlg
        sentFlg
        readFlg
        readAt
        favoriteFlgReceived
        favoriteFlgSent
        answerRequiredFlg
        responseDueDatetimeForFilter
        answeredFlg
        answeredAt
        attendanceAnswer
        questionnaireAnswers {
          answer
        }
        scheduleAnswers
        senderId
        mailType
        title
        body
        hasAttachment
        hasThread
        trashedFlgReceived
        deletedFlgReceived
        deletedFlgSent
        count
        teamMemberID
        mailID
        teamMember {
          id
          memberSeq
          nickname
          nicknameKana
          role
          showEmailFlg
          phoneNumber
          showPhoneNumberFlg
          belongs
          showBelongsFlg
          grade
          teamIdGsi
          teamTeamMembersId
          mailMigratedFlg
          deleteFlg
          accountTeamMembersId
          migrateTeamMemberId
          createdAt
          updatedAt
          teamMemberNotificationSettingId
        }
        mail {
          id
          teamId
          mailType
          title
          body
          attachmentFileNames
          startDate
          startTime
          endDate
          endTime
          shareManagerFlg
          showAnswerFlg
          responseDueDatetime
          remindDate
          remindDatetime
          remindExecDatetime
          sendScheduledAt
          sentAt
          createdAt
          updatedAt
          mailSenderId
        }
        createdYearMonth
        createdDate
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`
export const searchThreadMessages = /* GraphQL */ `
  query SearchThreadMessages(
    $filter: SearchableThreadMessageFilterInput
    $sort: [SearchableThreadMessageSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableThreadMessageAggregationInput]
  ) {
    searchThreadMessages(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        messageSenderId
        messageRecipientAccountId
        teamId
        mailId
        text
        unreadFlg
        deleteFlg
        contentUpdatedAt
        createdAt
        createdYearMonth
        createdDate
        threadMessagesId
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`
export const searchMigratedMails = /* GraphQL */ `
  query SearchMigratedMails(
    $filter: SearchableMigratedMailFilterInput
    $sort: [SearchableMigratedMailSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableMigratedMailAggregationInput]
  ) {
    searchMigratedMails(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        migrationId
        title
        body
        sender
        favoriteFlg
        shareManagerFlg
        sentDatetime
        createdAt
        updatedAt
        teamMemberMigratedMailsId
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`
export const searchMatchingOffers = /* GraphQL */ `
  query SearchMatchingOffers(
    $filter: SearchableMatchingOfferFilterInput
    $sort: [SearchableMatchingOfferSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableMatchingOfferAggregationInput]
  ) {
    searchMatchingOffers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        purpose
        title
        body
        date
        startTime
        endTime
        prefecture {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        city {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
          prefectureMasterCitiesId
        }
        place
        placeURL
        expense
        expensePurpose
        ageFrom
        ageTo
        offerCount
        publishEndDate
        team {
          id
          name
          description
          requirementForMemberProfile
          customQuestions
          showMemberListFlg
          invitationCode
          migrationId
          paidFunctionEnabled
          showAdsFlg
          createdAt
          updatedAt
          teamCategoryId
          teamActivityId
          teamPrefectureId
          teamCityId
        }
        attachmentFileNames
        activity {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        teamId
        teamName
        applications {
          nextToken
        }
        deleteFlg
        createdAt
        updatedAt
        teamMatchingOffersId
        matchingOfferPrefectureId
        matchingOfferCityId
        matchingOfferActivityId
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`
export const searchMatchingApplications = /* GraphQL */ `
  query SearchMatchingApplications(
    $filter: SearchableMatchingApplicationFilterInput
    $sort: [SearchableMatchingApplicationSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableMatchingApplicationAggregationInput]
  ) {
    searchMatchingApplications(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        applicant {
          id
          name
          description
          requirementForMemberProfile
          customQuestions
          showMemberListFlg
          invitationCode
          migrationId
          paidFunctionEnabled
          showAdsFlg
          createdAt
          updatedAt
          teamCategoryId
          teamActivityId
          teamPrefectureId
          teamCityId
        }
        offer {
          id
          purpose
          title
          body
          date
          startTime
          endTime
          place
          placeURL
          expense
          expensePurpose
          ageFrom
          ageTo
          offerCount
          publishEndDate
          attachmentFileNames
          teamId
          teamName
          deleteFlg
          createdAt
          updatedAt
          teamMatchingOffersId
          matchingOfferPrefectureId
          matchingOfferCityId
          matchingOfferActivityId
        }
        messages {
          nextToken
        }
        messagesIncludeDeleted {
          nextToken
        }
        offerTeamName
        offerTitle
        offerBody
        createdAt
        updatedAt
        teamMatchingApplicationsId
        matchingOfferApplicationsId
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`
export const searchPaidVersionOrders = /* GraphQL */ `
  query SearchPaidVersionOrders(
    $filter: SearchablePaidVersionOrderFilterInput
    $sort: [SearchablePaidVersionOrderSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchablePaidVersionOrderAggregationInput]
  ) {
    searchPaidVersionOrders(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        orderNumber
        originalOrderId
        orderStatus
        jobId
        masterCode
        zacStatus
        supportedDate
        address
        orderType
        companyName
        companyNameKana
        corporateRepresentativeName
        corporateRepresentativeNameKana
        applicantPhone
        applicantMail
        applicantName
        applicantPostcode
        applicantPrefecture
        applicantAddress
        applicantBuildingName
        applicantRemark
        antiSocialCheck
        teamId
        migrateTeamId
        teamName
        memberCount
        applicantId
        transferApplicantId
        planId
        startDate
        endDate
        adsDisableEndDate
        paymentStatus
        paymentDate
        paymentDeadline
        invoiceIssuedDate
        paymentType
        paymentMethod
        invoiceAddressee
        invoiceAddresseeKana
        amount
        taxRate
        tax
        subTotal
        remark
        team {
          id
          name
          description
          requirementForMemberProfile
          customQuestions
          showMemberListFlg
          invitationCode
          migrationId
          paidFunctionEnabled
          showAdsFlg
          createdAt
          updatedAt
          teamCategoryId
          teamActivityId
          teamPrefectureId
          teamCityId
        }
        plan {
          id
          planCode
          name
          type
          memberLimit
          teamLimit
          period
          amount
          planType
          createdAt
          updatedAt
        }
        applicant {
          id
          memberSeq
          nickname
          nicknameKana
          role
          showEmailFlg
          phoneNumber
          showPhoneNumberFlg
          belongs
          showBelongsFlg
          grade
          teamIdGsi
          teamTeamMembersId
          mailMigratedFlg
          deleteFlg
          accountTeamMembersId
          migrateTeamMemberId
          createdAt
          updatedAt
          teamMemberNotificationSettingId
        }
        applicantTransfer {
          id
          memberSeq
          nickname
          nicknameKana
          role
          showEmailFlg
          phoneNumber
          showPhoneNumberFlg
          belongs
          showBelongsFlg
          grade
          teamIdGsi
          teamTeamMembersId
          mailMigratedFlg
          deleteFlg
          accountTeamMembersId
          migrateTeamMemberId
          createdAt
          updatedAt
          teamMemberNotificationSettingId
        }
        orderHistories {
          nextToken
        }
        transferStatus {
          nextToken
        }
        adminId
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`
export const getAdminNotice = /* GraphQL */ `
  query GetAdminNotice($id: ID!) {
    getAdminNotice(id: $id) {
      id
      label
      title
      content
      priority
      publishMonth
      publishDatetime
      createdAt
      updatedAt
    }
  }
`
export const _listAdminNotice = /* GraphQL */ `
  query _listAdminNotice($filter: ModelAdminNoticeFilterInput, $limit: Int, $nextToken: String) {
    _listAdminNotice(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        title
        content
        priority
        publishMonth
        publishDatetime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      id
      email
      lastName
      firstName
      lastNameKana
      firstNameKana
      phoneNumber
      gender
      birthday
      job {
        id
        label
        type
        displayNumber
        displayFlg
        createdAt
        updatedAt
      }
      school {
        id
        label
        labelKana
        type
        code
        displayNumber
        displayFlg
        departments {
          nextToken
        }
        createdAt
        updatedAt
      }
      schoolDepartment {
        id
        label
        displayNumber
        displayFlg
        createdAt
        updatedAt
        schoolMasterDepartmentsId
      }
      schoolGraduationYear
      prefecture {
        id
        label
        displayNumber
        displayFlg
        cities {
          nextToken
        }
        createdAt
        updatedAt
      }
      city {
        id
        label
        displayNumber
        displayFlg
        createdAt
        updatedAt
        prefectureMasterCitiesId
      }
      questionAnswers {
        items {
          id
          createdAt
          updatedAt
          accountQuestionAnswersId
          accountQuestionAnswerQuestionId
          accountQuestionAnswerAnswerId
        }
        nextToken
      }
      termsAgreedAt
      agreedTermsVersion
      matchingTermsAgreedAt
      agreedMatchingTermsVersion
      signupStatus
      accountDevices {
        items {
          id
          accountID
          deviceID
          createdAt
          updatedAt
        }
        nextToken
      }
      teamMembers {
        items {
          id
          memberSeq
          nickname
          nicknameKana
          role
          showEmailFlg
          phoneNumber
          showPhoneNumberFlg
          belongs
          showBelongsFlg
          grade
          teamIdGsi
          teamTeamMembersId
          mailMigratedFlg
          deleteFlg
          accountTeamMembersId
          migrateTeamMemberId
          createdAt
          updatedAt
          teamMemberNotificationSettingId
        }
        nextToken
      }
      preTeamMembers {
        items {
          id
          nickname
          nicknameKana
          showEmailFlg
          phoneNumber
          showPhoneNumberFlg
          belongs
          showBelongsFlg
          grade
          createdAt
          updatedAt
          accountPreTeamMembersId
          teamPreTeamMembersId
        }
        nextToken
      }
      teamNotices {
        items {
          id
          noticeType
          title
          readFlg
          url
          createdAt
          createdYearMonth
          createdDate
          accountTeamNoticesId
          updatedAt
          teamMemberTeamNoticeId
          teamNoticeTeamId
        }
        nextToken
      }
      haveOldRa9TeamNotJoin
      migrateAccountId
      createdAt
      updatedAt
      accountJobId
      accountSchoolId
      accountSchoolDepartmentId
      accountPrefectureId
      accountCityId
    }
  }
`
export const _listAccount = /* GraphQL */ `
  query _listAccount($filter: ModelAccountFilterInput, $limit: Int, $nextToken: String) {
    _listAccount(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        lastName
        firstName
        lastNameKana
        firstNameKana
        phoneNumber
        gender
        birthday
        job {
          id
          label
          type
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        school {
          id
          label
          labelKana
          type
          code
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        schoolDepartment {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
          schoolMasterDepartmentsId
        }
        schoolGraduationYear
        prefecture {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        city {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
          prefectureMasterCitiesId
        }
        questionAnswers {
          nextToken
        }
        termsAgreedAt
        agreedTermsVersion
        matchingTermsAgreedAt
        agreedMatchingTermsVersion
        signupStatus
        accountDevices {
          nextToken
        }
        teamMembers {
          nextToken
        }
        preTeamMembers {
          nextToken
        }
        teamNotices {
          nextToken
        }
        haveOldRa9TeamNotJoin
        migrateAccountId
        createdAt
        updatedAt
        accountJobId
        accountSchoolId
        accountSchoolDepartmentId
        accountPrefectureId
        accountCityId
      }
      nextToken
    }
  }
`
export const getNotificationSetting = /* GraphQL */ `
  query GetNotificationSetting($id: ID!) {
    getNotificationSetting(id: $id) {
      id
      mailReceivedNotice {
        push
        email
      }
      threadMessageReceivedNotice {
        push
        email
      }
      joinRequestNotice {
        push
        email
      }
      teamMatchingNotice {
        email
      }
      createdAt
      updatedAt
    }
  }
`
export const _listPreAccount = /* GraphQL */ `
  query _listPreAccount(
    $email: String
    $filter: ModelPreAccountFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    _listPreAccount(email: $email, filter: $filter, limit: $limit, nextToken: $nextToken, sortDirection: $sortDirection) {
      items {
        email
        createdYearMonth
        createdDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      name
      category {
        id
        label
        displayNumber
        displayFlg
        activities {
          nextToken
        }
        createdAt
        updatedAt
      }
      activity {
        id
        label
        displayNumber
        displayFlg
        categories {
          nextToken
        }
        createdAt
        updatedAt
      }
      prefecture {
        id
        label
        displayNumber
        displayFlg
        cities {
          nextToken
        }
        createdAt
        updatedAt
      }
      city {
        id
        label
        displayNumber
        displayFlg
        createdAt
        updatedAt
        prefectureMasterCitiesId
      }
      description
      requirementForMemberProfile
      customQuestions
      showMemberListFlg
      invitationCode
      teamMembers {
        items {
          id
          memberSeq
          nickname
          nicknameKana
          role
          showEmailFlg
          phoneNumber
          showPhoneNumberFlg
          belongs
          showBelongsFlg
          grade
          teamIdGsi
          teamTeamMembersId
          mailMigratedFlg
          deleteFlg
          accountTeamMembersId
          migrateTeamMemberId
          createdAt
          updatedAt
          teamMemberNotificationSettingId
        }
        nextToken
      }
      preTeamMembers {
        items {
          id
          nickname
          nicknameKana
          showEmailFlg
          phoneNumber
          showPhoneNumberFlg
          belongs
          showBelongsFlg
          grade
          createdAt
          updatedAt
          accountPreTeamMembersId
          teamPreTeamMembersId
        }
        nextToken
      }
      invitationEmailStatuses {
        items {
          id
          email
          status
          repicaDeliverId
          repicaKeyField
          repicaSentDate
          errorMessage
          createdAt
          teamInvitationEmailStatusesId
          updatedAt
        }
        nextToken
      }
      groups {
        items {
          id
          name
          migrationId
          createdAt
          updatedAt
          teamGroupsId
        }
        nextToken
      }
      matchingOffers {
        items {
          id
          purpose
          title
          body
          date
          startTime
          endTime
          place
          placeURL
          expense
          expensePurpose
          ageFrom
          ageTo
          offerCount
          publishEndDate
          attachmentFileNames
          teamId
          teamName
          deleteFlg
          createdAt
          updatedAt
          teamMatchingOffersId
          matchingOfferPrefectureId
          matchingOfferCityId
          matchingOfferActivityId
        }
        nextToken
      }
      matchingOfferDrafts {
        items {
          id
          purpose
          title
          body
          date
          startTime
          endTime
          place
          placeURL
          expense
          expensePurpose
          ageFrom
          ageTo
          offerCount
          publishEndDate
          createdAt
          updatedAt
          teamMatchingOfferDraftsId
          matchingOfferDraftPrefectureId
          matchingOfferDraftCityId
        }
        nextToken
      }
      matchingApplications {
        items {
          id
          offerTeamName
          offerTitle
          offerBody
          createdAt
          updatedAt
          teamMatchingApplicationsId
          matchingOfferApplicationsId
        }
        nextToken
      }
      blockingMatchingTeams {
        items {
          id
          blockingTeamId
          createdAt
          updatedAt
          teamBlockingMatchingTeamsId
        }
        nextToken
      }
      paidVersionOrders {
        items {
          id
          orderNumber
          originalOrderId
          orderStatus
          jobId
          masterCode
          zacStatus
          supportedDate
          address
          orderType
          companyName
          companyNameKana
          corporateRepresentativeName
          corporateRepresentativeNameKana
          applicantPhone
          applicantMail
          applicantName
          applicantPostcode
          applicantPrefecture
          applicantAddress
          applicantBuildingName
          applicantRemark
          antiSocialCheck
          teamId
          migrateTeamId
          teamName
          memberCount
          applicantId
          transferApplicantId
          planId
          startDate
          endDate
          adsDisableEndDate
          paymentStatus
          paymentDate
          paymentDeadline
          invoiceIssuedDate
          paymentType
          paymentMethod
          invoiceAddressee
          invoiceAddresseeKana
          amount
          taxRate
          tax
          subTotal
          remark
          adminId
          createdAt
          updatedAt
        }
        nextToken
      }
      blockingMatchingTeamsByBlockingTeamId {
        items {
          id
          blockingTeamId
          createdAt
          updatedAt
          teamBlockingMatchingTeamsId
        }
        nextToken
      }
      migrationId
      paidFunctionEnabled
      showAdsFlg
      createdAt
      updatedAt
      teamCategoryId
      teamActivityId
      teamPrefectureId
      teamCityId
    }
  }
`
export const _listVolatileInvitationId = /* GraphQL */ `
  query _listVolatileInvitationId($filter: ModelVolatileInvitationIdFilterInput, $limit: Int, $nextToken: String) {
    _listVolatileInvitationId(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        invitationCode
        createdYearMonth
        createdDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const _listInvitationEmailStatus = /* GraphQL */ `
  query _listInvitationEmailStatus($filter: ModelInvitationEmailStatusFilterInput, $limit: Int, $nextToken: String) {
    _listInvitationEmailStatus(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        status
        repicaDeliverId
        repicaKeyField
        repicaSentDate
        errorMessage
        createdAt
        teamInvitationEmailStatusesId
        updatedAt
      }
      nextToken
    }
  }
`
export const _listTeamNotice = /* GraphQL */ `
  query _listTeamNotice($filter: ModelTeamNoticeFilterInput, $limit: Int, $nextToken: String) {
    _listTeamNotice(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        noticeType
        title
        readFlg
        url
        team {
          id
          name
          description
          requirementForMemberProfile
          customQuestions
          showMemberListFlg
          invitationCode
          migrationId
          paidFunctionEnabled
          showAdsFlg
          createdAt
          updatedAt
          teamCategoryId
          teamActivityId
          teamPrefectureId
          teamCityId
        }
        createdAt
        createdYearMonth
        createdDate
        accountTeamNoticesId
        updatedAt
        teamMemberTeamNoticeId
        teamNoticeTeamId
      }
      nextToken
    }
  }
`
export const getTeamMember = /* GraphQL */ `
  query GetTeamMember($id: ID!) {
    getTeamMember(id: $id) {
      id
      memberSeq
      nickname
      nicknameKana
      role
      showEmailFlg
      phoneNumber
      showPhoneNumberFlg
      belongs
      showBelongsFlg
      grade
      account {
        id
        email
        lastName
        firstName
        lastNameKana
        firstNameKana
        phoneNumber
        gender
        birthday
        job {
          id
          label
          type
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        school {
          id
          label
          labelKana
          type
          code
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        schoolDepartment {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
          schoolMasterDepartmentsId
        }
        schoolGraduationYear
        prefecture {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        city {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
          prefectureMasterCitiesId
        }
        questionAnswers {
          nextToken
        }
        termsAgreedAt
        agreedTermsVersion
        matchingTermsAgreedAt
        agreedMatchingTermsVersion
        signupStatus
        accountDevices {
          nextToken
        }
        teamMembers {
          nextToken
        }
        preTeamMembers {
          nextToken
        }
        teamNotices {
          nextToken
        }
        haveOldRa9TeamNotJoin
        migrateAccountId
        createdAt
        updatedAt
        accountJobId
        accountSchoolId
        accountSchoolDepartmentId
        accountPrefectureId
        accountCityId
      }
      team {
        id
        name
        category {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        activity {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        prefecture {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        city {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
          prefectureMasterCitiesId
        }
        description
        requirementForMemberProfile
        customQuestions
        showMemberListFlg
        invitationCode
        teamMembers {
          nextToken
        }
        preTeamMembers {
          nextToken
        }
        invitationEmailStatuses {
          nextToken
        }
        groups {
          nextToken
        }
        matchingOffers {
          nextToken
        }
        matchingOfferDrafts {
          nextToken
        }
        matchingApplications {
          nextToken
        }
        blockingMatchingTeams {
          nextToken
        }
        paidVersionOrders {
          nextToken
        }
        blockingMatchingTeamsByBlockingTeamId {
          nextToken
        }
        migrationId
        paidFunctionEnabled
        showAdsFlg
        createdAt
        updatedAt
        teamCategoryId
        teamActivityId
        teamPrefectureId
        teamCityId
      }
      teamIdGsi
      teamTeamMembersId
      notificationSetting {
        id
        mailReceivedNotice {
          push
          email
        }
        threadMessageReceivedNotice {
          push
          email
        }
        joinRequestNotice {
          push
          email
        }
        teamMatchingNotice {
          email
        }
        createdAt
        updatedAt
      }
      teamNotice {
        items {
          id
          noticeType
          title
          readFlg
          url
          createdAt
          createdYearMonth
          createdDate
          accountTeamNoticesId
          updatedAt
          teamMemberTeamNoticeId
          teamNoticeTeamId
        }
        nextToken
      }
      groups {
        items {
          id
          leaderFlg
          teamMemberID
          groupID
          createdAt
          updatedAt
        }
        nextToken
      }
      teamMemberMails {
        items {
          id
          receivedFlg
          sentFlg
          readFlg
          readAt
          favoriteFlgReceived
          favoriteFlgSent
          answerRequiredFlg
          responseDueDatetimeForFilter
          answeredFlg
          answeredAt
          attendanceAnswer
          scheduleAnswers
          senderId
          mailType
          title
          body
          hasAttachment
          hasThread
          trashedFlgReceived
          deletedFlgReceived
          deletedFlgSent
          count
          teamMemberID
          mailID
          createdYearMonth
          createdDate
          createdAt
          updatedAt
        }
        nextToken
      }
      reservedMails {
        items {
          id
          teamId
          mailType
          title
          body
          attachmentFileNames
          startDate
          startTime
          endDate
          endTime
          shareManagerFlg
          showAnswerFlg
          responseDueDatetime
          remindDatetime
          sendScheduledDate
          sendScheduledAt
          createdAt
          updatedAt
          teamMemberReservedMailsId
        }
        nextToken
      }
      drafts {
        items {
          id
          mailType
          title
          body
          attachmentFileNames
          startDate
          startTime
          endDate
          endTime
          shareManagerFlg
          showAnswerFlg
          responseDueDatetime
          remindDatetime
          sendScheduledAt
          createdAt
          updatedAt
          teamMemberDraftsId
        }
        nextToken
      }
      templates {
        items {
          id
          mailType
          title
          body
          startDate
          startTime
          endDate
          endTime
          shareManagerFlg
          showAnswerFlg
          favoriteFlg
          createdAt
          updatedAt
          teamMemberTemplatesId
        }
        nextToken
      }
      migratedMails {
        items {
          id
          migrationId
          title
          body
          sender
          favoriteFlg
          shareManagerFlg
          sentDatetime
          createdAt
          updatedAt
          teamMemberMigratedMailsId
        }
        nextToken
      }
      mailMigratedFlg
      favoriteMatchingOffer {
        items {
          id
          createdAt
          updatedAt
          teamMemberFavoriteMatchingOfferId
          favoriteMatchingOfferOfferId
        }
        nextToken
      }
      deleteFlg
      accountTeamMembersId
      migrateTeamMemberId
      orderHistories {
        items {
          id
          type
          orderId
          teamMemberId
          adminId
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      paidVersionOrders {
        items {
          id
          orderNumber
          originalOrderId
          orderStatus
          jobId
          masterCode
          zacStatus
          supportedDate
          address
          orderType
          companyName
          companyNameKana
          corporateRepresentativeName
          corporateRepresentativeNameKana
          applicantPhone
          applicantMail
          applicantName
          applicantPostcode
          applicantPrefecture
          applicantAddress
          applicantBuildingName
          applicantRemark
          antiSocialCheck
          teamId
          migrateTeamId
          teamName
          memberCount
          applicantId
          transferApplicantId
          planId
          startDate
          endDate
          adsDisableEndDate
          paymentStatus
          paymentDate
          paymentDeadline
          invoiceIssuedDate
          paymentType
          paymentMethod
          invoiceAddressee
          invoiceAddresseeKana
          amount
          taxRate
          tax
          subTotal
          remark
          adminId
          createdAt
          updatedAt
        }
        nextToken
      }
      transferPaidVersionOrders {
        items {
          id
          orderNumber
          originalOrderId
          orderStatus
          jobId
          masterCode
          zacStatus
          supportedDate
          address
          orderType
          companyName
          companyNameKana
          corporateRepresentativeName
          corporateRepresentativeNameKana
          applicantPhone
          applicantMail
          applicantName
          applicantPostcode
          applicantPrefecture
          applicantAddress
          applicantBuildingName
          applicantRemark
          antiSocialCheck
          teamId
          migrateTeamId
          teamName
          memberCount
          applicantId
          transferApplicantId
          planId
          startDate
          endDate
          adsDisableEndDate
          paymentStatus
          paymentDate
          paymentDeadline
          invoiceIssuedDate
          paymentType
          paymentMethod
          invoiceAddressee
          invoiceAddresseeKana
          amount
          taxRate
          tax
          subTotal
          remark
          adminId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
      teamMemberNotificationSettingId
    }
  }
`
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      name
      groupMembers {
        items {
          id
          leaderFlg
          teamMemberID
          groupID
          createdAt
          updatedAt
        }
        nextToken
      }
      migrationId
      createdAt
      updatedAt
      teamGroupsId
    }
  }
`
export const getMail = /* GraphQL */ `
  query GetMail($id: ID!) {
    getMail(id: $id) {
      id
      teamId
      mailType
      title
      sender {
        id
        memberSeq
        nickname
        nicknameKana
        role
        showEmailFlg
        phoneNumber
        showPhoneNumberFlg
        belongs
        showBelongsFlg
        grade
        account {
          id
          email
          lastName
          firstName
          lastNameKana
          firstNameKana
          phoneNumber
          gender
          birthday
          schoolGraduationYear
          termsAgreedAt
          agreedTermsVersion
          matchingTermsAgreedAt
          agreedMatchingTermsVersion
          signupStatus
          haveOldRa9TeamNotJoin
          migrateAccountId
          createdAt
          updatedAt
          accountJobId
          accountSchoolId
          accountSchoolDepartmentId
          accountPrefectureId
          accountCityId
        }
        team {
          id
          name
          description
          requirementForMemberProfile
          customQuestions
          showMemberListFlg
          invitationCode
          migrationId
          paidFunctionEnabled
          showAdsFlg
          createdAt
          updatedAt
          teamCategoryId
          teamActivityId
          teamPrefectureId
          teamCityId
        }
        teamIdGsi
        teamTeamMembersId
        notificationSetting {
          id
          createdAt
          updatedAt
        }
        teamNotice {
          nextToken
        }
        groups {
          nextToken
        }
        teamMemberMails {
          nextToken
        }
        reservedMails {
          nextToken
        }
        drafts {
          nextToken
        }
        templates {
          nextToken
        }
        migratedMails {
          nextToken
        }
        mailMigratedFlg
        favoriteMatchingOffer {
          nextToken
        }
        deleteFlg
        accountTeamMembersId
        migrateTeamMemberId
        orderHistories {
          nextToken
        }
        paidVersionOrders {
          nextToken
        }
        transferPaidVersionOrders {
          nextToken
        }
        createdAt
        updatedAt
        teamMemberNotificationSettingId
      }
      body
      sendTo {
        allMembersFlg
        managersFlg
        groupIds
        memberIds
      }
      attachmentFileNames
      startDate
      startTime
      endDate
      endTime
      questionnaires {
        answerSelectType
        question
        choices
      }
      candidateDatetimes {
        startDate
        startTime
        endDate
        endTime
      }
      shareManagerFlg
      showAnswerFlg
      responseDueDatetime
      remindDate
      remindDatetime
      remindExecDatetime
      sendScheduledAt
      sentAt
      teamMemberMails {
        items {
          id
          receivedFlg
          sentFlg
          readFlg
          readAt
          favoriteFlgReceived
          favoriteFlgSent
          answerRequiredFlg
          responseDueDatetimeForFilter
          answeredFlg
          answeredAt
          attendanceAnswer
          scheduleAnswers
          senderId
          mailType
          title
          body
          hasAttachment
          hasThread
          trashedFlgReceived
          deletedFlgReceived
          deletedFlgSent
          count
          teamMemberID
          mailID
          createdYearMonth
          createdDate
          createdAt
          updatedAt
        }
        nextToken
      }
      threads {
        items {
          id
          threadMailRecipientId
          mailThreadsId
          createdAt
          updatedAt
          threadMailSenderId
        }
        nextToken
      }
      createdAt
      updatedAt
      mailSenderId
    }
  }
`
export const getTeamMemberMail = /* GraphQL */ `
  query GetTeamMemberMail($id: ID!) {
    getTeamMemberMail(id: $id) {
      id
      receivedFlg
      sentFlg
      readFlg
      readAt
      favoriteFlgReceived
      favoriteFlgSent
      answerRequiredFlg
      responseDueDatetimeForFilter
      answeredFlg
      answeredAt
      attendanceAnswer
      questionnaireAnswers {
        answer
      }
      scheduleAnswers
      senderId
      mailType
      title
      body
      hasAttachment
      hasThread
      trashedFlgReceived
      deletedFlgReceived
      deletedFlgSent
      count
      teamMemberID
      mailID
      teamMember {
        id
        memberSeq
        nickname
        nicknameKana
        role
        showEmailFlg
        phoneNumber
        showPhoneNumberFlg
        belongs
        showBelongsFlg
        grade
        account {
          id
          email
          lastName
          firstName
          lastNameKana
          firstNameKana
          phoneNumber
          gender
          birthday
          schoolGraduationYear
          termsAgreedAt
          agreedTermsVersion
          matchingTermsAgreedAt
          agreedMatchingTermsVersion
          signupStatus
          haveOldRa9TeamNotJoin
          migrateAccountId
          createdAt
          updatedAt
          accountJobId
          accountSchoolId
          accountSchoolDepartmentId
          accountPrefectureId
          accountCityId
        }
        team {
          id
          name
          description
          requirementForMemberProfile
          customQuestions
          showMemberListFlg
          invitationCode
          migrationId
          paidFunctionEnabled
          showAdsFlg
          createdAt
          updatedAt
          teamCategoryId
          teamActivityId
          teamPrefectureId
          teamCityId
        }
        teamIdGsi
        teamTeamMembersId
        notificationSetting {
          id
          createdAt
          updatedAt
        }
        teamNotice {
          nextToken
        }
        groups {
          nextToken
        }
        teamMemberMails {
          nextToken
        }
        reservedMails {
          nextToken
        }
        drafts {
          nextToken
        }
        templates {
          nextToken
        }
        migratedMails {
          nextToken
        }
        mailMigratedFlg
        favoriteMatchingOffer {
          nextToken
        }
        deleteFlg
        accountTeamMembersId
        migrateTeamMemberId
        orderHistories {
          nextToken
        }
        paidVersionOrders {
          nextToken
        }
        transferPaidVersionOrders {
          nextToken
        }
        createdAt
        updatedAt
        teamMemberNotificationSettingId
      }
      mail {
        id
        teamId
        mailType
        title
        sender {
          id
          memberSeq
          nickname
          nicknameKana
          role
          showEmailFlg
          phoneNumber
          showPhoneNumberFlg
          belongs
          showBelongsFlg
          grade
          teamIdGsi
          teamTeamMembersId
          mailMigratedFlg
          deleteFlg
          accountTeamMembersId
          migrateTeamMemberId
          createdAt
          updatedAt
          teamMemberNotificationSettingId
        }
        body
        sendTo {
          allMembersFlg
          managersFlg
          groupIds
          memberIds
        }
        attachmentFileNames
        startDate
        startTime
        endDate
        endTime
        questionnaires {
          answerSelectType
          question
          choices
        }
        candidateDatetimes {
          startDate
          startTime
          endDate
          endTime
        }
        shareManagerFlg
        showAnswerFlg
        responseDueDatetime
        remindDate
        remindDatetime
        remindExecDatetime
        sendScheduledAt
        sentAt
        teamMemberMails {
          nextToken
        }
        threads {
          nextToken
        }
        createdAt
        updatedAt
        mailSenderId
      }
      createdYearMonth
      createdDate
      createdAt
      updatedAt
    }
  }
`
export const _listTeamMemberMail = /* GraphQL */ `
  query _listTeamMemberMail($filter: ModelTeamMemberMailFilterInput, $limit: Int, $nextToken: String) {
    _listTeamMemberMail(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        receivedFlg
        sentFlg
        readFlg
        readAt
        favoriteFlgReceived
        favoriteFlgSent
        answerRequiredFlg
        responseDueDatetimeForFilter
        answeredFlg
        answeredAt
        attendanceAnswer
        questionnaireAnswers {
          answer
        }
        scheduleAnswers
        senderId
        mailType
        title
        body
        hasAttachment
        hasThread
        trashedFlgReceived
        deletedFlgReceived
        deletedFlgSent
        count
        teamMemberID
        mailID
        teamMember {
          id
          memberSeq
          nickname
          nicknameKana
          role
          showEmailFlg
          phoneNumber
          showPhoneNumberFlg
          belongs
          showBelongsFlg
          grade
          teamIdGsi
          teamTeamMembersId
          mailMigratedFlg
          deleteFlg
          accountTeamMembersId
          migrateTeamMemberId
          createdAt
          updatedAt
          teamMemberNotificationSettingId
        }
        mail {
          id
          teamId
          mailType
          title
          body
          attachmentFileNames
          startDate
          startTime
          endDate
          endTime
          shareManagerFlg
          showAnswerFlg
          responseDueDatetime
          remindDate
          remindDatetime
          remindExecDatetime
          sendScheduledAt
          sentAt
          createdAt
          updatedAt
          mailSenderId
        }
        createdYearMonth
        createdDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getReservedMail = /* GraphQL */ `
  query GetReservedMail($id: ID!) {
    getReservedMail(id: $id) {
      id
      teamId
      mailType
      title
      teamMember {
        id
        memberSeq
        nickname
        nicknameKana
        role
        showEmailFlg
        phoneNumber
        showPhoneNumberFlg
        belongs
        showBelongsFlg
        grade
        account {
          id
          email
          lastName
          firstName
          lastNameKana
          firstNameKana
          phoneNumber
          gender
          birthday
          schoolGraduationYear
          termsAgreedAt
          agreedTermsVersion
          matchingTermsAgreedAt
          agreedMatchingTermsVersion
          signupStatus
          haveOldRa9TeamNotJoin
          migrateAccountId
          createdAt
          updatedAt
          accountJobId
          accountSchoolId
          accountSchoolDepartmentId
          accountPrefectureId
          accountCityId
        }
        team {
          id
          name
          description
          requirementForMemberProfile
          customQuestions
          showMemberListFlg
          invitationCode
          migrationId
          paidFunctionEnabled
          showAdsFlg
          createdAt
          updatedAt
          teamCategoryId
          teamActivityId
          teamPrefectureId
          teamCityId
        }
        teamIdGsi
        teamTeamMembersId
        notificationSetting {
          id
          createdAt
          updatedAt
        }
        teamNotice {
          nextToken
        }
        groups {
          nextToken
        }
        teamMemberMails {
          nextToken
        }
        reservedMails {
          nextToken
        }
        drafts {
          nextToken
        }
        templates {
          nextToken
        }
        migratedMails {
          nextToken
        }
        mailMigratedFlg
        favoriteMatchingOffer {
          nextToken
        }
        deleteFlg
        accountTeamMembersId
        migrateTeamMemberId
        orderHistories {
          nextToken
        }
        paidVersionOrders {
          nextToken
        }
        transferPaidVersionOrders {
          nextToken
        }
        createdAt
        updatedAt
        teamMemberNotificationSettingId
      }
      body
      sendTo {
        allMembersFlg
        managersFlg
        groupIds
        memberIds
      }
      attachmentFileNames
      startDate
      startTime
      endDate
      endTime
      questionnaires {
        answerSelectType
        question
        choices
      }
      candidateDatetimes {
        startDate
        startTime
        endDate
        endTime
      }
      shareManagerFlg
      showAnswerFlg
      responseDueDatetime
      remindDatetime
      sendScheduledDate
      sendScheduledAt
      createdAt
      updatedAt
      teamMemberReservedMailsId
    }
  }
`
export const getDraft = /* GraphQL */ `
  query GetDraft($id: ID!) {
    getDraft(id: $id) {
      id
      mailType
      title
      teamMember {
        id
        memberSeq
        nickname
        nicknameKana
        role
        showEmailFlg
        phoneNumber
        showPhoneNumberFlg
        belongs
        showBelongsFlg
        grade
        account {
          id
          email
          lastName
          firstName
          lastNameKana
          firstNameKana
          phoneNumber
          gender
          birthday
          schoolGraduationYear
          termsAgreedAt
          agreedTermsVersion
          matchingTermsAgreedAt
          agreedMatchingTermsVersion
          signupStatus
          haveOldRa9TeamNotJoin
          migrateAccountId
          createdAt
          updatedAt
          accountJobId
          accountSchoolId
          accountSchoolDepartmentId
          accountPrefectureId
          accountCityId
        }
        team {
          id
          name
          description
          requirementForMemberProfile
          customQuestions
          showMemberListFlg
          invitationCode
          migrationId
          paidFunctionEnabled
          showAdsFlg
          createdAt
          updatedAt
          teamCategoryId
          teamActivityId
          teamPrefectureId
          teamCityId
        }
        teamIdGsi
        teamTeamMembersId
        notificationSetting {
          id
          createdAt
          updatedAt
        }
        teamNotice {
          nextToken
        }
        groups {
          nextToken
        }
        teamMemberMails {
          nextToken
        }
        reservedMails {
          nextToken
        }
        drafts {
          nextToken
        }
        templates {
          nextToken
        }
        migratedMails {
          nextToken
        }
        mailMigratedFlg
        favoriteMatchingOffer {
          nextToken
        }
        deleteFlg
        accountTeamMembersId
        migrateTeamMemberId
        orderHistories {
          nextToken
        }
        paidVersionOrders {
          nextToken
        }
        transferPaidVersionOrders {
          nextToken
        }
        createdAt
        updatedAt
        teamMemberNotificationSettingId
      }
      body
      sendTo {
        allMembersFlg
        managersFlg
        groupIds
        memberIds
      }
      attachmentFileNames
      startDate
      startTime
      endDate
      endTime
      questionnaires {
        answerSelectType
        question
        choices
      }
      candidateDatetimes {
        startDate
        startTime
        endDate
        endTime
      }
      shareManagerFlg
      showAnswerFlg
      responseDueDatetime
      remindDatetime
      sendScheduledAt
      createdAt
      updatedAt
      teamMemberDraftsId
    }
  }
`
export const getTemplate = /* GraphQL */ `
  query GetTemplate($id: ID!) {
    getTemplate(id: $id) {
      id
      mailType
      title
      teamMember {
        id
        memberSeq
        nickname
        nicknameKana
        role
        showEmailFlg
        phoneNumber
        showPhoneNumberFlg
        belongs
        showBelongsFlg
        grade
        account {
          id
          email
          lastName
          firstName
          lastNameKana
          firstNameKana
          phoneNumber
          gender
          birthday
          schoolGraduationYear
          termsAgreedAt
          agreedTermsVersion
          matchingTermsAgreedAt
          agreedMatchingTermsVersion
          signupStatus
          haveOldRa9TeamNotJoin
          migrateAccountId
          createdAt
          updatedAt
          accountJobId
          accountSchoolId
          accountSchoolDepartmentId
          accountPrefectureId
          accountCityId
        }
        team {
          id
          name
          description
          requirementForMemberProfile
          customQuestions
          showMemberListFlg
          invitationCode
          migrationId
          paidFunctionEnabled
          showAdsFlg
          createdAt
          updatedAt
          teamCategoryId
          teamActivityId
          teamPrefectureId
          teamCityId
        }
        teamIdGsi
        teamTeamMembersId
        notificationSetting {
          id
          createdAt
          updatedAt
        }
        teamNotice {
          nextToken
        }
        groups {
          nextToken
        }
        teamMemberMails {
          nextToken
        }
        reservedMails {
          nextToken
        }
        drafts {
          nextToken
        }
        templates {
          nextToken
        }
        migratedMails {
          nextToken
        }
        mailMigratedFlg
        favoriteMatchingOffer {
          nextToken
        }
        deleteFlg
        accountTeamMembersId
        migrateTeamMemberId
        orderHistories {
          nextToken
        }
        paidVersionOrders {
          nextToken
        }
        transferPaidVersionOrders {
          nextToken
        }
        createdAt
        updatedAt
        teamMemberNotificationSettingId
      }
      body
      sendTo {
        allMembersFlg
        managersFlg
        groupIds
        memberIds
      }
      startDate
      startTime
      endDate
      endTime
      questionnaires {
        answerSelectType
        question
        choices
      }
      candidateDatetimes {
        startDate
        startTime
        endDate
        endTime
      }
      shareManagerFlg
      showAnswerFlg
      favoriteFlg
      createdAt
      updatedAt
      teamMemberTemplatesId
    }
  }
`
export const getThread = /* GraphQL */ `
  query GetThread($id: ID!) {
    getThread(id: $id) {
      id
      mailSender {
        id
        memberSeq
        nickname
        nicknameKana
        role
        showEmailFlg
        phoneNumber
        showPhoneNumberFlg
        belongs
        showBelongsFlg
        grade
        account {
          id
          email
          lastName
          firstName
          lastNameKana
          firstNameKana
          phoneNumber
          gender
          birthday
          schoolGraduationYear
          termsAgreedAt
          agreedTermsVersion
          matchingTermsAgreedAt
          agreedMatchingTermsVersion
          signupStatus
          haveOldRa9TeamNotJoin
          migrateAccountId
          createdAt
          updatedAt
          accountJobId
          accountSchoolId
          accountSchoolDepartmentId
          accountPrefectureId
          accountCityId
        }
        team {
          id
          name
          description
          requirementForMemberProfile
          customQuestions
          showMemberListFlg
          invitationCode
          migrationId
          paidFunctionEnabled
          showAdsFlg
          createdAt
          updatedAt
          teamCategoryId
          teamActivityId
          teamPrefectureId
          teamCityId
        }
        teamIdGsi
        teamTeamMembersId
        notificationSetting {
          id
          createdAt
          updatedAt
        }
        teamNotice {
          nextToken
        }
        groups {
          nextToken
        }
        teamMemberMails {
          nextToken
        }
        reservedMails {
          nextToken
        }
        drafts {
          nextToken
        }
        templates {
          nextToken
        }
        migratedMails {
          nextToken
        }
        mailMigratedFlg
        favoriteMatchingOffer {
          nextToken
        }
        deleteFlg
        accountTeamMembersId
        migrateTeamMemberId
        orderHistories {
          nextToken
        }
        paidVersionOrders {
          nextToken
        }
        transferPaidVersionOrders {
          nextToken
        }
        createdAt
        updatedAt
        teamMemberNotificationSettingId
      }
      mailRecipient {
        id
        memberSeq
        nickname
        nicknameKana
        role
        showEmailFlg
        phoneNumber
        showPhoneNumberFlg
        belongs
        showBelongsFlg
        grade
        account {
          id
          email
          lastName
          firstName
          lastNameKana
          firstNameKana
          phoneNumber
          gender
          birthday
          schoolGraduationYear
          termsAgreedAt
          agreedTermsVersion
          matchingTermsAgreedAt
          agreedMatchingTermsVersion
          signupStatus
          haveOldRa9TeamNotJoin
          migrateAccountId
          createdAt
          updatedAt
          accountJobId
          accountSchoolId
          accountSchoolDepartmentId
          accountPrefectureId
          accountCityId
        }
        team {
          id
          name
          description
          requirementForMemberProfile
          customQuestions
          showMemberListFlg
          invitationCode
          migrationId
          paidFunctionEnabled
          showAdsFlg
          createdAt
          updatedAt
          teamCategoryId
          teamActivityId
          teamPrefectureId
          teamCityId
        }
        teamIdGsi
        teamTeamMembersId
        notificationSetting {
          id
          createdAt
          updatedAt
        }
        teamNotice {
          nextToken
        }
        groups {
          nextToken
        }
        teamMemberMails {
          nextToken
        }
        reservedMails {
          nextToken
        }
        drafts {
          nextToken
        }
        templates {
          nextToken
        }
        migratedMails {
          nextToken
        }
        mailMigratedFlg
        favoriteMatchingOffer {
          nextToken
        }
        deleteFlg
        accountTeamMembersId
        migrateTeamMemberId
        orderHistories {
          nextToken
        }
        paidVersionOrders {
          nextToken
        }
        transferPaidVersionOrders {
          nextToken
        }
        createdAt
        updatedAt
        teamMemberNotificationSettingId
      }
      threadMailRecipientId
      messages {
        items {
          id
          messageSenderId
          messageRecipientAccountId
          teamId
          mailId
          text
          unreadFlg
          deleteFlg
          contentUpdatedAt
          createdAt
          createdYearMonth
          createdDate
          threadMessagesId
          updatedAt
        }
        nextToken
      }
      mail {
        id
        teamId
        mailType
        title
        sender {
          id
          memberSeq
          nickname
          nicknameKana
          role
          showEmailFlg
          phoneNumber
          showPhoneNumberFlg
          belongs
          showBelongsFlg
          grade
          teamIdGsi
          teamTeamMembersId
          mailMigratedFlg
          deleteFlg
          accountTeamMembersId
          migrateTeamMemberId
          createdAt
          updatedAt
          teamMemberNotificationSettingId
        }
        body
        sendTo {
          allMembersFlg
          managersFlg
          groupIds
          memberIds
        }
        attachmentFileNames
        startDate
        startTime
        endDate
        endTime
        questionnaires {
          answerSelectType
          question
          choices
        }
        candidateDatetimes {
          startDate
          startTime
          endDate
          endTime
        }
        shareManagerFlg
        showAnswerFlg
        responseDueDatetime
        remindDate
        remindDatetime
        remindExecDatetime
        sendScheduledAt
        sentAt
        teamMemberMails {
          nextToken
        }
        threads {
          nextToken
        }
        createdAt
        updatedAt
        mailSenderId
      }
      mailThreadsId
      createdAt
      updatedAt
      threadMailSenderId
    }
  }
`
export const _listThreadMessage = /* GraphQL */ `
  query _listThreadMessage($filter: ModelThreadMessageFilterInput, $limit: Int, $nextToken: String) {
    _listThreadMessage(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        messageSenderId
        messageRecipientAccountId
        teamId
        mailId
        text
        unreadFlg
        deleteFlg
        contentUpdatedAt
        createdAt
        createdYearMonth
        createdDate
        threadMessagesId
        updatedAt
      }
      nextToken
    }
  }
`
export const listJobMaster = /* GraphQL */ `
  query ListJobMaster($filter: ModelJobMasterFilterInput, $limit: Int, $nextToken: String) {
    listJobMaster(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        type
        displayNumber
        displayFlg
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getSchoolMaster = /* GraphQL */ `
  query GetSchoolMaster($id: ID!) {
    getSchoolMaster(id: $id) {
      id
      label
      labelKana
      type
      code
      displayNumber
      displayFlg
      departments {
        items {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
          schoolMasterDepartmentsId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const listSchoolMaster = /* GraphQL */ `
  query ListSchoolMaster($filter: ModelSchoolMasterFilterInput, $limit: Int, $nextToken: String) {
    listSchoolMaster(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        labelKana
        type
        code
        displayNumber
        displayFlg
        departments {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getPrefectureMaster = /* GraphQL */ `
  query GetPrefectureMaster($id: ID!) {
    getPrefectureMaster(id: $id) {
      id
      label
      displayNumber
      displayFlg
      cities {
        items {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
          prefectureMasterCitiesId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const listPrefectureMaster = /* GraphQL */ `
  query ListPrefectureMaster($filter: ModelPrefectureMasterFilterInput, $limit: Int, $nextToken: String) {
    listPrefectureMaster(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        displayNumber
        displayFlg
        cities {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getTeamCategoryMaster = /* GraphQL */ `
  query GetTeamCategoryMaster($id: ID!) {
    getTeamCategoryMaster(id: $id) {
      id
      label
      displayNumber
      displayFlg
      activities {
        items {
          id
          teamCategoryMasterID
          teamActivityMasterID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const listTeamCategoryMaster = /* GraphQL */ `
  query ListTeamCategoryMaster($filter: ModelTeamCategoryMasterFilterInput, $limit: Int, $nextToken: String) {
    listTeamCategoryMaster(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        displayNumber
        displayFlg
        activities {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getAccountQuestionMaster = /* GraphQL */ `
  query GetAccountQuestionMaster($id: ID!) {
    getAccountQuestionMaster(id: $id) {
      id
      label
      displayNumber
      displayFlg
      choices {
        items {
          id
          label
          displayNumber
          displayFlg
          isDefault
          createdAt
          updatedAt
          accountQuestionMasterChoicesId
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const listAccountQuestionMaster = /* GraphQL */ `
  query ListAccountQuestionMaster($filter: ModelAccountQuestionMasterFilterInput, $limit: Int, $nextToken: String) {
    listAccountQuestionMaster(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        displayNumber
        displayFlg
        choices {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getTeamMemberPreset = /* GraphQL */ `
  query GetTeamMemberPreset($teamId: ID!, $email: String!) {
    getTeamMemberPreset(teamId: $teamId, email: $email) {
      teamId
      email
      memberSeq
      nickname
      nicknameKana
      role
      showEmailFlg
      phoneNumber
      showPhoneNumberFlg
      belongs
      showBelongsFlg
      grade
      groups {
        id
        leaderFlg
      }
      createdYearMonth
      createdDate
      migrateTeamMemberId
      createdAt
      updatedAt
    }
  }
`
export const _listTeamMemberPreset = /* GraphQL */ `
  query _listTeamMemberPreset(
    $teamId: ID
    $email: ModelStringKeyConditionInput
    $filter: ModelTeamMemberPresetFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    _listTeamMemberPreset(
      teamId: $teamId
      email: $email
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        teamId
        email
        memberSeq
        nickname
        nicknameKana
        role
        showEmailFlg
        phoneNumber
        showPhoneNumberFlg
        belongs
        showBelongsFlg
        grade
        groups {
          id
          leaderFlg
        }
        createdYearMonth
        createdDate
        migrateTeamMemberId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getMigratedMail = /* GraphQL */ `
  query GetMigratedMail($id: ID!) {
    getMigratedMail(id: $id) {
      id
      migrationId
      title
      body
      sender
      favoriteFlg
      shareManagerFlg
      sentDatetime
      createdAt
      updatedAt
      teamMemberMigratedMailsId
    }
  }
`
export const getMatchingOffer = /* GraphQL */ `
  query GetMatchingOffer($id: ID!) {
    getMatchingOffer(id: $id) {
      id
      purpose
      title
      body
      date
      startTime
      endTime
      prefecture {
        id
        label
        displayNumber
        displayFlg
        cities {
          nextToken
        }
        createdAt
        updatedAt
      }
      city {
        id
        label
        displayNumber
        displayFlg
        createdAt
        updatedAt
        prefectureMasterCitiesId
      }
      place
      placeURL
      expense
      expensePurpose
      ageFrom
      ageTo
      offerCount
      publishEndDate
      team {
        id
        name
        category {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        activity {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        prefecture {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        city {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
          prefectureMasterCitiesId
        }
        description
        requirementForMemberProfile
        customQuestions
        showMemberListFlg
        invitationCode
        teamMembers {
          nextToken
        }
        preTeamMembers {
          nextToken
        }
        invitationEmailStatuses {
          nextToken
        }
        groups {
          nextToken
        }
        matchingOffers {
          nextToken
        }
        matchingOfferDrafts {
          nextToken
        }
        matchingApplications {
          nextToken
        }
        blockingMatchingTeams {
          nextToken
        }
        paidVersionOrders {
          nextToken
        }
        blockingMatchingTeamsByBlockingTeamId {
          nextToken
        }
        migrationId
        paidFunctionEnabled
        showAdsFlg
        createdAt
        updatedAt
        teamCategoryId
        teamActivityId
        teamPrefectureId
        teamCityId
      }
      attachmentFileNames
      activity {
        id
        label
        displayNumber
        displayFlg
        categories {
          nextToken
        }
        createdAt
        updatedAt
      }
      teamId
      teamName
      applications {
        items {
          id
          offerTeamName
          offerTitle
          offerBody
          createdAt
          updatedAt
          teamMatchingApplicationsId
          matchingOfferApplicationsId
        }
        nextToken
      }
      deleteFlg
      createdAt
      updatedAt
      teamMatchingOffersId
      matchingOfferPrefectureId
      matchingOfferCityId
      matchingOfferActivityId
    }
  }
`
export const listMatchingOffer = /* GraphQL */ `
  query ListMatchingOffer($filter: ModelMatchingOfferFilterInput, $limit: Int, $nextToken: String) {
    listMatchingOffer(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        purpose
        title
        body
        date
        startTime
        endTime
        prefecture {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        city {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
          prefectureMasterCitiesId
        }
        place
        placeURL
        expense
        expensePurpose
        ageFrom
        ageTo
        offerCount
        publishEndDate
        team {
          id
          name
          description
          requirementForMemberProfile
          customQuestions
          showMemberListFlg
          invitationCode
          migrationId
          paidFunctionEnabled
          showAdsFlg
          createdAt
          updatedAt
          teamCategoryId
          teamActivityId
          teamPrefectureId
          teamCityId
        }
        attachmentFileNames
        activity {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        teamId
        teamName
        applications {
          nextToken
        }
        deleteFlg
        createdAt
        updatedAt
        teamMatchingOffersId
        matchingOfferPrefectureId
        matchingOfferCityId
        matchingOfferActivityId
      }
      nextToken
    }
  }
`
export const getMatchingOfferDraft = /* GraphQL */ `
  query GetMatchingOfferDraft($id: ID!) {
    getMatchingOfferDraft(id: $id) {
      id
      purpose
      title
      body
      date
      startTime
      endTime
      prefecture {
        id
        label
        displayNumber
        displayFlg
        cities {
          nextToken
        }
        createdAt
        updatedAt
      }
      city {
        id
        label
        displayNumber
        displayFlg
        createdAt
        updatedAt
        prefectureMasterCitiesId
      }
      place
      placeURL
      expense
      expensePurpose
      ageFrom
      ageTo
      offerCount
      publishEndDate
      team {
        id
        name
        category {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        activity {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        prefecture {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        city {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
          prefectureMasterCitiesId
        }
        description
        requirementForMemberProfile
        customQuestions
        showMemberListFlg
        invitationCode
        teamMembers {
          nextToken
        }
        preTeamMembers {
          nextToken
        }
        invitationEmailStatuses {
          nextToken
        }
        groups {
          nextToken
        }
        matchingOffers {
          nextToken
        }
        matchingOfferDrafts {
          nextToken
        }
        matchingApplications {
          nextToken
        }
        blockingMatchingTeams {
          nextToken
        }
        paidVersionOrders {
          nextToken
        }
        blockingMatchingTeamsByBlockingTeamId {
          nextToken
        }
        migrationId
        paidFunctionEnabled
        showAdsFlg
        createdAt
        updatedAt
        teamCategoryId
        teamActivityId
        teamPrefectureId
        teamCityId
      }
      createdAt
      updatedAt
      teamMatchingOfferDraftsId
      matchingOfferDraftPrefectureId
      matchingOfferDraftCityId
    }
  }
`
export const getMatchingApplication = /* GraphQL */ `
  query GetMatchingApplication($id: ID!) {
    getMatchingApplication(id: $id) {
      id
      applicant {
        id
        name
        category {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        activity {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        prefecture {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        city {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
          prefectureMasterCitiesId
        }
        description
        requirementForMemberProfile
        customQuestions
        showMemberListFlg
        invitationCode
        teamMembers {
          nextToken
        }
        preTeamMembers {
          nextToken
        }
        invitationEmailStatuses {
          nextToken
        }
        groups {
          nextToken
        }
        matchingOffers {
          nextToken
        }
        matchingOfferDrafts {
          nextToken
        }
        matchingApplications {
          nextToken
        }
        blockingMatchingTeams {
          nextToken
        }
        paidVersionOrders {
          nextToken
        }
        blockingMatchingTeamsByBlockingTeamId {
          nextToken
        }
        migrationId
        paidFunctionEnabled
        showAdsFlg
        createdAt
        updatedAt
        teamCategoryId
        teamActivityId
        teamPrefectureId
        teamCityId
      }
      offer {
        id
        purpose
        title
        body
        date
        startTime
        endTime
        prefecture {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        city {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
          prefectureMasterCitiesId
        }
        place
        placeURL
        expense
        expensePurpose
        ageFrom
        ageTo
        offerCount
        publishEndDate
        team {
          id
          name
          description
          requirementForMemberProfile
          customQuestions
          showMemberListFlg
          invitationCode
          migrationId
          paidFunctionEnabled
          showAdsFlg
          createdAt
          updatedAt
          teamCategoryId
          teamActivityId
          teamPrefectureId
          teamCityId
        }
        attachmentFileNames
        activity {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        teamId
        teamName
        applications {
          nextToken
        }
        deleteFlg
        createdAt
        updatedAt
        teamMatchingOffersId
        matchingOfferPrefectureId
        matchingOfferCityId
        matchingOfferActivityId
      }
      messages {
        items {
          id
          messageSenderTeamId
          text
          unreadFlg
          deleteFlg
          contentUpdatedAt
          createdAtForSort
          createdAtForSortIncludeDeleted
          applicationIdMessageReceiverGsi
          matchingApplicationMessagesId
          matchingApplicationMessagesIncludeDeletedId
          createdAt
          updatedAt
        }
        nextToken
      }
      messagesIncludeDeleted {
        items {
          id
          messageSenderTeamId
          text
          unreadFlg
          deleteFlg
          contentUpdatedAt
          createdAtForSort
          createdAtForSortIncludeDeleted
          applicationIdMessageReceiverGsi
          matchingApplicationMessagesId
          matchingApplicationMessagesIncludeDeletedId
          createdAt
          updatedAt
        }
        nextToken
      }
      offerTeamName
      offerTitle
      offerBody
      createdAt
      updatedAt
      teamMatchingApplicationsId
      matchingOfferApplicationsId
    }
  }
`
export const _listMaintenance = /* GraphQL */ `
  query _listMaintenance(
    $maintenanceFlg: Int
    $startAt: ModelStringKeyConditionInput
    $filter: ModelMaintenanceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    _listMaintenance(
      maintenanceFlg: $maintenanceFlg
      startAt: $startAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        maintenanceFlg
        content
        startAt
        endAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const _listAppVersion = /* GraphQL */ `
  query _listAppVersion(
    $version: String
    $osType: ModelStringKeyConditionInput
    $filter: ModelAppVersionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    _listAppVersion(
      version: $version
      osType: $osType
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        version
        osType
        osVersion
        releaseAt
        content
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const _listTerms = /* GraphQL */ `
  query _listTerms(
    $version: String
    $type: ModelStringKeyConditionInput
    $filter: ModelTermsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    _listTerms(
      version: $version
      type: $type
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        version
        type
        validFlg
        url
        releaseAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const _listTempToken = /* GraphQL */ `
  query _listTempToken(
    $tempToken: ID
    $filter: ModelTempTokenFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    _listTempToken(
      tempToken: $tempToken
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        tempToken
        accessToken
        createdYearMonth
        createdDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getTransferStatus = /* GraphQL */ `
  query GetTransferStatus($id: ID!) {
    getTransferStatus(id: $id) {
      id
      orderId
      originalApplicantId
      newApplicantId
      status
      order {
        id
        orderNumber
        originalOrderId
        orderStatus
        jobId
        masterCode
        zacStatus
        supportedDate
        address
        orderType
        companyName
        companyNameKana
        corporateRepresentativeName
        corporateRepresentativeNameKana
        applicantPhone
        applicantMail
        applicantName
        applicantPostcode
        applicantPrefecture
        applicantAddress
        applicantBuildingName
        applicantRemark
        antiSocialCheck
        teamId
        migrateTeamId
        teamName
        memberCount
        applicantId
        transferApplicantId
        planId
        startDate
        endDate
        adsDisableEndDate
        paymentStatus
        paymentDate
        paymentDeadline
        invoiceIssuedDate
        paymentType
        paymentMethod
        invoiceAddressee
        invoiceAddresseeKana
        amount
        taxRate
        tax
        subTotal
        remark
        team {
          id
          name
          description
          requirementForMemberProfile
          customQuestions
          showMemberListFlg
          invitationCode
          migrationId
          paidFunctionEnabled
          showAdsFlg
          createdAt
          updatedAt
          teamCategoryId
          teamActivityId
          teamPrefectureId
          teamCityId
        }
        plan {
          id
          planCode
          name
          type
          memberLimit
          teamLimit
          period
          amount
          planType
          createdAt
          updatedAt
        }
        applicant {
          id
          memberSeq
          nickname
          nicknameKana
          role
          showEmailFlg
          phoneNumber
          showPhoneNumberFlg
          belongs
          showBelongsFlg
          grade
          teamIdGsi
          teamTeamMembersId
          mailMigratedFlg
          deleteFlg
          accountTeamMembersId
          migrateTeamMemberId
          createdAt
          updatedAt
          teamMemberNotificationSettingId
        }
        applicantTransfer {
          id
          memberSeq
          nickname
          nicknameKana
          role
          showEmailFlg
          phoneNumber
          showPhoneNumberFlg
          belongs
          showBelongsFlg
          grade
          teamIdGsi
          teamTeamMembersId
          mailMigratedFlg
          deleteFlg
          accountTeamMembersId
          migrateTeamMemberId
          createdAt
          updatedAt
          teamMemberNotificationSettingId
        }
        orderHistories {
          nextToken
        }
        transferStatus {
          nextToken
        }
        adminId
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const getOrderHistory = /* GraphQL */ `
  query GetOrderHistory($id: ID!) {
    getOrderHistory(id: $id) {
      id
      type
      orderId
      order {
        id
        orderNumber
        originalOrderId
        orderStatus
        jobId
        masterCode
        zacStatus
        supportedDate
        address
        orderType
        companyName
        companyNameKana
        corporateRepresentativeName
        corporateRepresentativeNameKana
        applicantPhone
        applicantMail
        applicantName
        applicantPostcode
        applicantPrefecture
        applicantAddress
        applicantBuildingName
        applicantRemark
        antiSocialCheck
        teamId
        migrateTeamId
        teamName
        memberCount
        applicantId
        transferApplicantId
        planId
        startDate
        endDate
        adsDisableEndDate
        paymentStatus
        paymentDate
        paymentDeadline
        invoiceIssuedDate
        paymentType
        paymentMethod
        invoiceAddressee
        invoiceAddresseeKana
        amount
        taxRate
        tax
        subTotal
        remark
        team {
          id
          name
          description
          requirementForMemberProfile
          customQuestions
          showMemberListFlg
          invitationCode
          migrationId
          paidFunctionEnabled
          showAdsFlg
          createdAt
          updatedAt
          teamCategoryId
          teamActivityId
          teamPrefectureId
          teamCityId
        }
        plan {
          id
          planCode
          name
          type
          memberLimit
          teamLimit
          period
          amount
          planType
          createdAt
          updatedAt
        }
        applicant {
          id
          memberSeq
          nickname
          nicknameKana
          role
          showEmailFlg
          phoneNumber
          showPhoneNumberFlg
          belongs
          showBelongsFlg
          grade
          teamIdGsi
          teamTeamMembersId
          mailMigratedFlg
          deleteFlg
          accountTeamMembersId
          migrateTeamMemberId
          createdAt
          updatedAt
          teamMemberNotificationSettingId
        }
        applicantTransfer {
          id
          memberSeq
          nickname
          nicknameKana
          role
          showEmailFlg
          phoneNumber
          showPhoneNumberFlg
          belongs
          showBelongsFlg
          grade
          teamIdGsi
          teamTeamMembersId
          mailMigratedFlg
          deleteFlg
          accountTeamMembersId
          migrateTeamMemberId
          createdAt
          updatedAt
          teamMemberNotificationSettingId
        }
        orderHistories {
          nextToken
        }
        transferStatus {
          nextToken
        }
        adminId
        createdAt
        updatedAt
      }
      teamMemberId
      teamMember {
        id
        memberSeq
        nickname
        nicknameKana
        role
        showEmailFlg
        phoneNumber
        showPhoneNumberFlg
        belongs
        showBelongsFlg
        grade
        account {
          id
          email
          lastName
          firstName
          lastNameKana
          firstNameKana
          phoneNumber
          gender
          birthday
          schoolGraduationYear
          termsAgreedAt
          agreedTermsVersion
          matchingTermsAgreedAt
          agreedMatchingTermsVersion
          signupStatus
          haveOldRa9TeamNotJoin
          migrateAccountId
          createdAt
          updatedAt
          accountJobId
          accountSchoolId
          accountSchoolDepartmentId
          accountPrefectureId
          accountCityId
        }
        team {
          id
          name
          description
          requirementForMemberProfile
          customQuestions
          showMemberListFlg
          invitationCode
          migrationId
          paidFunctionEnabled
          showAdsFlg
          createdAt
          updatedAt
          teamCategoryId
          teamActivityId
          teamPrefectureId
          teamCityId
        }
        teamIdGsi
        teamTeamMembersId
        notificationSetting {
          id
          createdAt
          updatedAt
        }
        teamNotice {
          nextToken
        }
        groups {
          nextToken
        }
        teamMemberMails {
          nextToken
        }
        reservedMails {
          nextToken
        }
        drafts {
          nextToken
        }
        templates {
          nextToken
        }
        migratedMails {
          nextToken
        }
        mailMigratedFlg
        favoriteMatchingOffer {
          nextToken
        }
        deleteFlg
        accountTeamMembersId
        migrateTeamMemberId
        orderHistories {
          nextToken
        }
        paidVersionOrders {
          nextToken
        }
        transferPaidVersionOrders {
          nextToken
        }
        createdAt
        updatedAt
        teamMemberNotificationSettingId
      }
      adminId
      content
      createdAt
      updatedAt
    }
  }
`
export const getPaidVersionOrder = /* GraphQL */ `
  query GetPaidVersionOrder($id: ID!) {
    getPaidVersionOrder(id: $id) {
      id
      orderNumber
      originalOrderId
      orderStatus
      jobId
      masterCode
      zacStatus
      supportedDate
      address
      orderType
      companyName
      companyNameKana
      corporateRepresentativeName
      corporateRepresentativeNameKana
      applicantPhone
      applicantMail
      applicantName
      applicantPostcode
      applicantPrefecture
      applicantAddress
      applicantBuildingName
      applicantRemark
      antiSocialCheck
      teamId
      migrateTeamId
      teamName
      memberCount
      applicantId
      transferApplicantId
      planId
      startDate
      endDate
      adsDisableEndDate
      paymentStatus
      paymentDate
      paymentDeadline
      invoiceIssuedDate
      paymentType
      paymentMethod
      invoiceAddressee
      invoiceAddresseeKana
      amount
      taxRate
      tax
      subTotal
      remark
      team {
        id
        name
        category {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        activity {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        prefecture {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        city {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
          prefectureMasterCitiesId
        }
        description
        requirementForMemberProfile
        customQuestions
        showMemberListFlg
        invitationCode
        teamMembers {
          nextToken
        }
        preTeamMembers {
          nextToken
        }
        invitationEmailStatuses {
          nextToken
        }
        groups {
          nextToken
        }
        matchingOffers {
          nextToken
        }
        matchingOfferDrafts {
          nextToken
        }
        matchingApplications {
          nextToken
        }
        blockingMatchingTeams {
          nextToken
        }
        paidVersionOrders {
          nextToken
        }
        blockingMatchingTeamsByBlockingTeamId {
          nextToken
        }
        migrationId
        paidFunctionEnabled
        showAdsFlg
        createdAt
        updatedAt
        teamCategoryId
        teamActivityId
        teamPrefectureId
        teamCityId
      }
      plan {
        id
        planCode
        name
        type
        memberLimit
        teamLimit
        period
        amount
        planType
        orders {
          nextToken
        }
        createdAt
        updatedAt
      }
      applicant {
        id
        memberSeq
        nickname
        nicknameKana
        role
        showEmailFlg
        phoneNumber
        showPhoneNumberFlg
        belongs
        showBelongsFlg
        grade
        account {
          id
          email
          lastName
          firstName
          lastNameKana
          firstNameKana
          phoneNumber
          gender
          birthday
          schoolGraduationYear
          termsAgreedAt
          agreedTermsVersion
          matchingTermsAgreedAt
          agreedMatchingTermsVersion
          signupStatus
          haveOldRa9TeamNotJoin
          migrateAccountId
          createdAt
          updatedAt
          accountJobId
          accountSchoolId
          accountSchoolDepartmentId
          accountPrefectureId
          accountCityId
        }
        team {
          id
          name
          description
          requirementForMemberProfile
          customQuestions
          showMemberListFlg
          invitationCode
          migrationId
          paidFunctionEnabled
          showAdsFlg
          createdAt
          updatedAt
          teamCategoryId
          teamActivityId
          teamPrefectureId
          teamCityId
        }
        teamIdGsi
        teamTeamMembersId
        notificationSetting {
          id
          createdAt
          updatedAt
        }
        teamNotice {
          nextToken
        }
        groups {
          nextToken
        }
        teamMemberMails {
          nextToken
        }
        reservedMails {
          nextToken
        }
        drafts {
          nextToken
        }
        templates {
          nextToken
        }
        migratedMails {
          nextToken
        }
        mailMigratedFlg
        favoriteMatchingOffer {
          nextToken
        }
        deleteFlg
        accountTeamMembersId
        migrateTeamMemberId
        orderHistories {
          nextToken
        }
        paidVersionOrders {
          nextToken
        }
        transferPaidVersionOrders {
          nextToken
        }
        createdAt
        updatedAt
        teamMemberNotificationSettingId
      }
      applicantTransfer {
        id
        memberSeq
        nickname
        nicknameKana
        role
        showEmailFlg
        phoneNumber
        showPhoneNumberFlg
        belongs
        showBelongsFlg
        grade
        account {
          id
          email
          lastName
          firstName
          lastNameKana
          firstNameKana
          phoneNumber
          gender
          birthday
          schoolGraduationYear
          termsAgreedAt
          agreedTermsVersion
          matchingTermsAgreedAt
          agreedMatchingTermsVersion
          signupStatus
          haveOldRa9TeamNotJoin
          migrateAccountId
          createdAt
          updatedAt
          accountJobId
          accountSchoolId
          accountSchoolDepartmentId
          accountPrefectureId
          accountCityId
        }
        team {
          id
          name
          description
          requirementForMemberProfile
          customQuestions
          showMemberListFlg
          invitationCode
          migrationId
          paidFunctionEnabled
          showAdsFlg
          createdAt
          updatedAt
          teamCategoryId
          teamActivityId
          teamPrefectureId
          teamCityId
        }
        teamIdGsi
        teamTeamMembersId
        notificationSetting {
          id
          createdAt
          updatedAt
        }
        teamNotice {
          nextToken
        }
        groups {
          nextToken
        }
        teamMemberMails {
          nextToken
        }
        reservedMails {
          nextToken
        }
        drafts {
          nextToken
        }
        templates {
          nextToken
        }
        migratedMails {
          nextToken
        }
        mailMigratedFlg
        favoriteMatchingOffer {
          nextToken
        }
        deleteFlg
        accountTeamMembersId
        migrateTeamMemberId
        orderHistories {
          nextToken
        }
        paidVersionOrders {
          nextToken
        }
        transferPaidVersionOrders {
          nextToken
        }
        createdAt
        updatedAt
        teamMemberNotificationSettingId
      }
      orderHistories {
        items {
          id
          type
          orderId
          teamMemberId
          adminId
          content
          createdAt
          updatedAt
        }
        nextToken
      }
      transferStatus {
        items {
          id
          orderId
          originalApplicantId
          newApplicantId
          status
          createdAt
          updatedAt
        }
        nextToken
      }
      adminId
      createdAt
      updatedAt
    }
  }
`
export const getHolidayCalendar = /* GraphQL */ `
  query GetHolidayCalendar($id: ID!) {
    getHolidayCalendar(id: $id) {
      id
      date
      name
      content
      createdAt
      updatedAt
    }
  }
`
export const getTax = /* GraphQL */ `
  query GetTax($id: ID!) {
    getTax(id: $id) {
      id
      taxRate
      startDate
      endDate
      createdAt
      updatedAt
    }
  }
`
export const getPaidVersionPlan = /* GraphQL */ `
  query GetPaidVersionPlan($id: ID!) {
    getPaidVersionPlan(id: $id) {
      id
      planCode
      name
      type
      memberLimit
      teamLimit
      period
      amount
      planType
      orders {
        items {
          id
          orderNumber
          originalOrderId
          orderStatus
          jobId
          masterCode
          zacStatus
          supportedDate
          address
          orderType
          companyName
          companyNameKana
          corporateRepresentativeName
          corporateRepresentativeNameKana
          applicantPhone
          applicantMail
          applicantName
          applicantPostcode
          applicantPrefecture
          applicantAddress
          applicantBuildingName
          applicantRemark
          antiSocialCheck
          teamId
          migrateTeamId
          teamName
          memberCount
          applicantId
          transferApplicantId
          planId
          startDate
          endDate
          adsDisableEndDate
          paymentStatus
          paymentDate
          paymentDeadline
          invoiceIssuedDate
          paymentType
          paymentMethod
          invoiceAddressee
          invoiceAddresseeKana
          amount
          taxRate
          tax
          subTotal
          remark
          adminId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`
export const getTeamCategoryActivity = /* GraphQL */ `
  query GetTeamCategoryActivity($id: ID!) {
    getTeamCategoryActivity(id: $id) {
      id
      teamCategoryMasterID
      teamActivityMasterID
      teamCategoryMaster {
        id
        label
        displayNumber
        displayFlg
        activities {
          nextToken
        }
        createdAt
        updatedAt
      }
      teamActivityMaster {
        id
        label
        displayNumber
        displayFlg
        categories {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const listTeamCategoryActivities = /* GraphQL */ `
  query ListTeamCategoryActivities($filter: ModelTeamCategoryActivityFilterInput, $limit: Int, $nextToken: String) {
    listTeamCategoryActivities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        teamCategoryMasterID
        teamActivityMasterID
        teamCategoryMaster {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        teamActivityMaster {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getAdminNoticeByPublishMonth = /* GraphQL */ `
  query GetAdminNoticeByPublishMonth(
    $publishMonth: Int!
    $publishDatetime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdminNoticeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAdminNoticeByPublishMonth(
      publishMonth: $publishMonth
      publishDatetime: $publishDatetime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        label
        title
        content
        priority
        publishMonth
        publishDatetime
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getAccountByEmail = /* GraphQL */ `
  query GetAccountByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAccountByEmail(email: $email, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        lastName
        firstName
        lastNameKana
        firstNameKana
        phoneNumber
        gender
        birthday
        job {
          id
          label
          type
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        school {
          id
          label
          labelKana
          type
          code
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        schoolDepartment {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
          schoolMasterDepartmentsId
        }
        schoolGraduationYear
        prefecture {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        city {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
          prefectureMasterCitiesId
        }
        questionAnswers {
          nextToken
        }
        termsAgreedAt
        agreedTermsVersion
        matchingTermsAgreedAt
        agreedMatchingTermsVersion
        signupStatus
        accountDevices {
          nextToken
        }
        teamMembers {
          nextToken
        }
        preTeamMembers {
          nextToken
        }
        teamNotices {
          nextToken
        }
        haveOldRa9TeamNotJoin
        migrateAccountId
        createdAt
        updatedAt
        accountJobId
        accountSchoolId
        accountSchoolDepartmentId
        accountPrefectureId
        accountCityId
      }
      nextToken
    }
  }
`
export const getAccountByMigrationAccountId = /* GraphQL */ `
  query GetAccountByMigrationAccountId(
    $migrateAccountId: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAccountByMigrationAccountId(
      migrateAccountId: $migrateAccountId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        lastName
        firstName
        lastNameKana
        firstNameKana
        phoneNumber
        gender
        birthday
        job {
          id
          label
          type
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        school {
          id
          label
          labelKana
          type
          code
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        schoolDepartment {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
          schoolMasterDepartmentsId
        }
        schoolGraduationYear
        prefecture {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        city {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
          prefectureMasterCitiesId
        }
        questionAnswers {
          nextToken
        }
        termsAgreedAt
        agreedTermsVersion
        matchingTermsAgreedAt
        agreedMatchingTermsVersion
        signupStatus
        accountDevices {
          nextToken
        }
        teamMembers {
          nextToken
        }
        preTeamMembers {
          nextToken
        }
        teamNotices {
          nextToken
        }
        haveOldRa9TeamNotJoin
        migrateAccountId
        createdAt
        updatedAt
        accountJobId
        accountSchoolId
        accountSchoolDepartmentId
        accountPrefectureId
        accountCityId
      }
      nextToken
    }
  }
`
export const getPreAccountByCreatedYearMonth = /* GraphQL */ `
  query GetPreAccountByCreatedYearMonth(
    $createdYearMonth: Int!
    $createdDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPreAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getPreAccountByCreatedYearMonth(
      createdYearMonth: $createdYearMonth
      createdDate: $createdDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        email
        createdYearMonth
        createdDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getTeamByInvitationCode = /* GraphQL */ `
  query GetTeamByInvitationCode(
    $invitationCode: String!
    $sortDirection: ModelSortDirection
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTeamByInvitationCode(
      invitationCode: $invitationCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        category {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        activity {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        prefecture {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        city {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
          prefectureMasterCitiesId
        }
        description
        requirementForMemberProfile
        customQuestions
        showMemberListFlg
        invitationCode
        teamMembers {
          nextToken
        }
        preTeamMembers {
          nextToken
        }
        invitationEmailStatuses {
          nextToken
        }
        groups {
          nextToken
        }
        matchingOffers {
          nextToken
        }
        matchingOfferDrafts {
          nextToken
        }
        matchingApplications {
          nextToken
        }
        blockingMatchingTeams {
          nextToken
        }
        paidVersionOrders {
          nextToken
        }
        blockingMatchingTeamsByBlockingTeamId {
          nextToken
        }
        migrationId
        paidFunctionEnabled
        showAdsFlg
        createdAt
        updatedAt
        teamCategoryId
        teamActivityId
        teamPrefectureId
        teamCityId
      }
      nextToken
    }
  }
`
export const getTeamByMigrationId = /* GraphQL */ `
  query GetTeamByMigrationId(
    $migrationId: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTeamByMigrationId(
      migrationId: $migrationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        category {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        activity {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        prefecture {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        city {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
          prefectureMasterCitiesId
        }
        description
        requirementForMemberProfile
        customQuestions
        showMemberListFlg
        invitationCode
        teamMembers {
          nextToken
        }
        preTeamMembers {
          nextToken
        }
        invitationEmailStatuses {
          nextToken
        }
        groups {
          nextToken
        }
        matchingOffers {
          nextToken
        }
        matchingOfferDrafts {
          nextToken
        }
        matchingApplications {
          nextToken
        }
        blockingMatchingTeams {
          nextToken
        }
        paidVersionOrders {
          nextToken
        }
        blockingMatchingTeamsByBlockingTeamId {
          nextToken
        }
        migrationId
        paidFunctionEnabled
        showAdsFlg
        createdAt
        updatedAt
        teamCategoryId
        teamActivityId
        teamPrefectureId
        teamCityId
      }
      nextToken
    }
  }
`
export const getVolatileInvitationIdByCreatedYearMonth = /* GraphQL */ `
  query GetVolatileInvitationIdByCreatedYearMonth(
    $createdYearMonth: Int!
    $createdDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelVolatileInvitationIdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getVolatileInvitationIdByCreatedYearMonth(
      createdYearMonth: $createdYearMonth
      createdDate: $createdDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        invitationCode
        createdYearMonth
        createdDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getInvitationEmailStatusByStatus = /* GraphQL */ `
  query GetInvitationEmailStatusByStatus(
    $status: EmailStatus!
    $repicaSentDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvitationEmailStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getInvitationEmailStatusByStatus(
      status: $status
      repicaSentDate: $repicaSentDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        status
        repicaDeliverId
        repicaKeyField
        repicaSentDate
        errorMessage
        createdAt
        teamInvitationEmailStatusesId
        updatedAt
      }
      nextToken
    }
  }
`
export const getInvitationEmailStatusByRepicaKeyField = /* GraphQL */ `
  query GetInvitationEmailStatusByRepicaKeyField(
    $repicaKeyField: String!
    $sortDirection: ModelSortDirection
    $filter: ModelInvitationEmailStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getInvitationEmailStatusByRepicaKeyField(
      repicaKeyField: $repicaKeyField
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        status
        repicaDeliverId
        repicaKeyField
        repicaSentDate
        errorMessage
        createdAt
        teamInvitationEmailStatusesId
        updatedAt
      }
      nextToken
    }
  }
`
export const getTeamNoticeByCreatedYearMonth = /* GraphQL */ `
  query GetTeamNoticeByCreatedYearMonth(
    $createdYearMonth: Int!
    $createdDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTeamNoticeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTeamNoticeByCreatedYearMonth(
      createdYearMonth: $createdYearMonth
      createdDate: $createdDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        noticeType
        title
        readFlg
        url
        team {
          id
          name
          description
          requirementForMemberProfile
          customQuestions
          showMemberListFlg
          invitationCode
          migrationId
          paidFunctionEnabled
          showAdsFlg
          createdAt
          updatedAt
          teamCategoryId
          teamActivityId
          teamPrefectureId
          teamCityId
        }
        createdAt
        createdYearMonth
        createdDate
        accountTeamNoticesId
        updatedAt
        teamMemberTeamNoticeId
        teamNoticeTeamId
      }
      nextToken
    }
  }
`
export const getTeamMemberByTeamIdGsi = /* GraphQL */ `
  query GetTeamMemberByTeamIdGsi(
    $teamIdGsi: ID!
    $role: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTeamMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTeamMemberByTeamIdGsi(
      teamIdGsi: $teamIdGsi
      role: $role
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        memberSeq
        nickname
        nicknameKana
        role
        showEmailFlg
        phoneNumber
        showPhoneNumberFlg
        belongs
        showBelongsFlg
        grade
        account {
          id
          email
          lastName
          firstName
          lastNameKana
          firstNameKana
          phoneNumber
          gender
          birthday
          schoolGraduationYear
          termsAgreedAt
          agreedTermsVersion
          matchingTermsAgreedAt
          agreedMatchingTermsVersion
          signupStatus
          haveOldRa9TeamNotJoin
          migrateAccountId
          createdAt
          updatedAt
          accountJobId
          accountSchoolId
          accountSchoolDepartmentId
          accountPrefectureId
          accountCityId
        }
        team {
          id
          name
          description
          requirementForMemberProfile
          customQuestions
          showMemberListFlg
          invitationCode
          migrationId
          paidFunctionEnabled
          showAdsFlg
          createdAt
          updatedAt
          teamCategoryId
          teamActivityId
          teamPrefectureId
          teamCityId
        }
        teamIdGsi
        teamTeamMembersId
        notificationSetting {
          id
          createdAt
          updatedAt
        }
        teamNotice {
          nextToken
        }
        groups {
          nextToken
        }
        teamMemberMails {
          nextToken
        }
        reservedMails {
          nextToken
        }
        drafts {
          nextToken
        }
        templates {
          nextToken
        }
        migratedMails {
          nextToken
        }
        mailMigratedFlg
        favoriteMatchingOffer {
          nextToken
        }
        deleteFlg
        accountTeamMembersId
        migrateTeamMemberId
        orderHistories {
          nextToken
        }
        paidVersionOrders {
          nextToken
        }
        transferPaidVersionOrders {
          nextToken
        }
        createdAt
        updatedAt
        teamMemberNotificationSettingId
      }
      nextToken
    }
  }
`
export const getTeamMemberByMigrateTeamMemberId = /* GraphQL */ `
  query GetTeamMemberByMigrateTeamMemberId(
    $migrateTeamMemberId: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelTeamMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTeamMemberByMigrateTeamMemberId(
      migrateTeamMemberId: $migrateTeamMemberId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        memberSeq
        nickname
        nicknameKana
        role
        showEmailFlg
        phoneNumber
        showPhoneNumberFlg
        belongs
        showBelongsFlg
        grade
        account {
          id
          email
          lastName
          firstName
          lastNameKana
          firstNameKana
          phoneNumber
          gender
          birthday
          schoolGraduationYear
          termsAgreedAt
          agreedTermsVersion
          matchingTermsAgreedAt
          agreedMatchingTermsVersion
          signupStatus
          haveOldRa9TeamNotJoin
          migrateAccountId
          createdAt
          updatedAt
          accountJobId
          accountSchoolId
          accountSchoolDepartmentId
          accountPrefectureId
          accountCityId
        }
        team {
          id
          name
          description
          requirementForMemberProfile
          customQuestions
          showMemberListFlg
          invitationCode
          migrationId
          paidFunctionEnabled
          showAdsFlg
          createdAt
          updatedAt
          teamCategoryId
          teamActivityId
          teamPrefectureId
          teamCityId
        }
        teamIdGsi
        teamTeamMembersId
        notificationSetting {
          id
          createdAt
          updatedAt
        }
        teamNotice {
          nextToken
        }
        groups {
          nextToken
        }
        teamMemberMails {
          nextToken
        }
        reservedMails {
          nextToken
        }
        drafts {
          nextToken
        }
        templates {
          nextToken
        }
        migratedMails {
          nextToken
        }
        mailMigratedFlg
        favoriteMatchingOffer {
          nextToken
        }
        deleteFlg
        accountTeamMembersId
        migrateTeamMemberId
        orderHistories {
          nextToken
        }
        paidVersionOrders {
          nextToken
        }
        transferPaidVersionOrders {
          nextToken
        }
        createdAt
        updatedAt
        teamMemberNotificationSettingId
      }
      nextToken
    }
  }
`
export const getMailByTeamId = /* GraphQL */ `
  query GetMailByTeamId(
    $teamId: ID!
    $shareManagerFlg: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMailByTeamId(
      teamId: $teamId
      shareManagerFlg: $shareManagerFlg
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        teamId
        mailType
        title
        sender {
          id
          memberSeq
          nickname
          nicknameKana
          role
          showEmailFlg
          phoneNumber
          showPhoneNumberFlg
          belongs
          showBelongsFlg
          grade
          teamIdGsi
          teamTeamMembersId
          mailMigratedFlg
          deleteFlg
          accountTeamMembersId
          migrateTeamMemberId
          createdAt
          updatedAt
          teamMemberNotificationSettingId
        }
        body
        sendTo {
          allMembersFlg
          managersFlg
          groupIds
          memberIds
        }
        attachmentFileNames
        startDate
        startTime
        endDate
        endTime
        questionnaires {
          answerSelectType
          question
          choices
        }
        candidateDatetimes {
          startDate
          startTime
          endDate
          endTime
        }
        shareManagerFlg
        showAnswerFlg
        responseDueDatetime
        remindDate
        remindDatetime
        remindExecDatetime
        sendScheduledAt
        sentAt
        teamMemberMails {
          nextToken
        }
        threads {
          nextToken
        }
        createdAt
        updatedAt
        mailSenderId
      }
      nextToken
    }
  }
`
export const getMailByRemindDate = /* GraphQL */ `
  query GetMailByRemindDate(
    $remindDate: AWSDate!
    $sortDirection: ModelSortDirection
    $filter: ModelMailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMailByRemindDate(
      remindDate: $remindDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        teamId
        mailType
        title
        sender {
          id
          memberSeq
          nickname
          nicknameKana
          role
          showEmailFlg
          phoneNumber
          showPhoneNumberFlg
          belongs
          showBelongsFlg
          grade
          teamIdGsi
          teamTeamMembersId
          mailMigratedFlg
          deleteFlg
          accountTeamMembersId
          migrateTeamMemberId
          createdAt
          updatedAt
          teamMemberNotificationSettingId
        }
        body
        sendTo {
          allMembersFlg
          managersFlg
          groupIds
          memberIds
        }
        attachmentFileNames
        startDate
        startTime
        endDate
        endTime
        questionnaires {
          answerSelectType
          question
          choices
        }
        candidateDatetimes {
          startDate
          startTime
          endDate
          endTime
        }
        shareManagerFlg
        showAnswerFlg
        responseDueDatetime
        remindDate
        remindDatetime
        remindExecDatetime
        sendScheduledAt
        sentAt
        teamMemberMails {
          nextToken
        }
        threads {
          nextToken
        }
        createdAt
        updatedAt
        mailSenderId
      }
      nextToken
    }
  }
`
export const getTeamMemberMailByCreatedYearMonth = /* GraphQL */ `
  query GetTeamMemberMailByCreatedYearMonth(
    $createdYearMonth: Int!
    $createdDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTeamMemberMailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTeamMemberMailByCreatedYearMonth(
      createdYearMonth: $createdYearMonth
      createdDate: $createdDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        receivedFlg
        sentFlg
        readFlg
        readAt
        favoriteFlgReceived
        favoriteFlgSent
        answerRequiredFlg
        responseDueDatetimeForFilter
        answeredFlg
        answeredAt
        attendanceAnswer
        questionnaireAnswers {
          answer
        }
        scheduleAnswers
        senderId
        mailType
        title
        body
        hasAttachment
        hasThread
        trashedFlgReceived
        deletedFlgReceived
        deletedFlgSent
        count
        teamMemberID
        mailID
        teamMember {
          id
          memberSeq
          nickname
          nicknameKana
          role
          showEmailFlg
          phoneNumber
          showPhoneNumberFlg
          belongs
          showBelongsFlg
          grade
          teamIdGsi
          teamTeamMembersId
          mailMigratedFlg
          deleteFlg
          accountTeamMembersId
          migrateTeamMemberId
          createdAt
          updatedAt
          teamMemberNotificationSettingId
        }
        mail {
          id
          teamId
          mailType
          title
          body
          attachmentFileNames
          startDate
          startTime
          endDate
          endTime
          shareManagerFlg
          showAnswerFlg
          responseDueDatetime
          remindDate
          remindDatetime
          remindExecDatetime
          sendScheduledAt
          sentAt
          createdAt
          updatedAt
          mailSenderId
        }
        createdYearMonth
        createdDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getReservedMailBySendScheduledDate = /* GraphQL */ `
  query GetReservedMailBySendScheduledDate(
    $sendScheduledDate: AWSDate!
    $sendScheduledAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReservedMailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getReservedMailBySendScheduledDate(
      sendScheduledDate: $sendScheduledDate
      sendScheduledAt: $sendScheduledAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        teamId
        mailType
        title
        teamMember {
          id
          memberSeq
          nickname
          nicknameKana
          role
          showEmailFlg
          phoneNumber
          showPhoneNumberFlg
          belongs
          showBelongsFlg
          grade
          teamIdGsi
          teamTeamMembersId
          mailMigratedFlg
          deleteFlg
          accountTeamMembersId
          migrateTeamMemberId
          createdAt
          updatedAt
          teamMemberNotificationSettingId
        }
        body
        sendTo {
          allMembersFlg
          managersFlg
          groupIds
          memberIds
        }
        attachmentFileNames
        startDate
        startTime
        endDate
        endTime
        questionnaires {
          answerSelectType
          question
          choices
        }
        candidateDatetimes {
          startDate
          startTime
          endDate
          endTime
        }
        shareManagerFlg
        showAnswerFlg
        responseDueDatetime
        remindDatetime
        sendScheduledDate
        sendScheduledAt
        createdAt
        updatedAt
        teamMemberReservedMailsId
      }
      nextToken
    }
  }
`
export const getThreadMessageByCreatedYearMonth = /* GraphQL */ `
  query GetThreadMessageByCreatedYearMonth(
    $createdYearMonth: Int!
    $createdDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelThreadMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getThreadMessageByCreatedYearMonth(
      createdYearMonth: $createdYearMonth
      createdDate: $createdDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        messageSenderId
        messageRecipientAccountId
        teamId
        mailId
        text
        unreadFlg
        deleteFlg
        contentUpdatedAt
        createdAt
        createdYearMonth
        createdDate
        threadMessagesId
        updatedAt
      }
      nextToken
    }
  }
`
export const getSchoolMasterByType = /* GraphQL */ `
  query GetSchoolMasterByType(
    $type: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSchoolMasterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getSchoolMasterByType(type: $type, sortDirection: $sortDirection, filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        labelKana
        type
        code
        displayNumber
        displayFlg
        departments {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getTeamMemberPresetByEmail = /* GraphQL */ `
  query GetTeamMemberPresetByEmail(
    $email: String!
    $teamId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTeamMemberPresetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTeamMemberPresetByEmail(
      email: $email
      teamId: $teamId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        teamId
        email
        memberSeq
        nickname
        nicknameKana
        role
        showEmailFlg
        phoneNumber
        showPhoneNumberFlg
        belongs
        showBelongsFlg
        grade
        groups {
          id
          leaderFlg
        }
        createdYearMonth
        createdDate
        migrateTeamMemberId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getTeamMemberPresetByCreatedYearMonth = /* GraphQL */ `
  query GetTeamMemberPresetByCreatedYearMonth(
    $createdYearMonth: Int!
    $createdDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTeamMemberPresetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTeamMemberPresetByCreatedYearMonth(
      createdYearMonth: $createdYearMonth
      createdDate: $createdDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        teamId
        email
        memberSeq
        nickname
        nicknameKana
        role
        showEmailFlg
        phoneNumber
        showPhoneNumberFlg
        belongs
        showBelongsFlg
        grade
        groups {
          id
          leaderFlg
        }
        createdYearMonth
        createdDate
        migrateTeamMemberId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getTeamMemberPresetByMigrateTeamMemberId = /* GraphQL */ `
  query GetTeamMemberPresetByMigrateTeamMemberId(
    $migrateTeamMemberId: Int!
    $sortDirection: ModelSortDirection
    $filter: ModelTeamMemberPresetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTeamMemberPresetByMigrateTeamMemberId(
      migrateTeamMemberId: $migrateTeamMemberId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        teamId
        email
        memberSeq
        nickname
        nicknameKana
        role
        showEmailFlg
        phoneNumber
        showPhoneNumberFlg
        belongs
        showBelongsFlg
        grade
        groups {
          id
          leaderFlg
        }
        createdYearMonth
        createdDate
        migrateTeamMemberId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getMatchingMessageByApplicationIdMessageReceiverGsi = /* GraphQL */ `
  query GetMatchingMessageByApplicationIdMessageReceiverGsi(
    $applicationIdMessageReceiverGsi: String!
    $deleteFlgUnreadFlg: ModelMatchingMessageByApplicationIdMessageReceiverGsiCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMatchingMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getMatchingMessageByApplicationIdMessageReceiverGsi(
      applicationIdMessageReceiverGsi: $applicationIdMessageReceiverGsi
      deleteFlgUnreadFlg: $deleteFlgUnreadFlg
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        messageSenderTeamId
        text
        unreadFlg
        deleteFlg
        contentUpdatedAt
        createdAtForSort
        createdAtForSortIncludeDeleted
        applicationIdMessageReceiverGsi
        matchingApplicationMessagesId
        matchingApplicationMessagesIncludeDeletedId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getBlockingMatchingTeamByBlockingTeamId = /* GraphQL */ `
  query GetBlockingMatchingTeamByBlockingTeamId(
    $blockingTeamId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBlockingMatchingTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getBlockingMatchingTeamByBlockingTeamId(
      blockingTeamId: $blockingTeamId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        blockingTeamId
        createdAt
        updatedAt
        teamBlockingMatchingTeamsId
      }
      nextToken
    }
  }
`
export const getReportMatchingTeamLogByReporterTeamId = /* GraphQL */ `
  query GetReportMatchingTeamLogByReporterTeamId(
    $reporterTeamId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelReportMatchingTeamLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getReportMatchingTeamLogByReporterTeamId(
      reporterTeamId: $reporterTeamId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        reporterAccountId
        reporterTeamId
        teamId
        content
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getReportMatchingTeamLogByTeamId = /* GraphQL */ `
  query GetReportMatchingTeamLogByTeamId(
    $teamId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelReportMatchingTeamLogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getReportMatchingTeamLogByTeamId(
      teamId: $teamId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        reporterAccountId
        reporterTeamId
        teamId
        content
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getAppVersionByOsType = /* GraphQL */ `
  query GetAppVersionByOsType(
    $osType: OsType!
    $releaseAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAppVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAppVersionByOsType(
      osType: $osType
      releaseAt: $releaseAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        version
        osType
        osVersion
        releaseAt
        content
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getTermsByValidFlg = /* GraphQL */ `
  query GetTermsByValidFlg(
    $validFlg: Int!
    $releaseAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTermsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTermsByValidFlg(
      validFlg: $validFlg
      releaseAt: $releaseAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        version
        type
        validFlg
        url
        releaseAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getTempTokenByCreatedYearMonth = /* GraphQL */ `
  query GetTempTokenByCreatedYearMonth(
    $createdYearMonth: Int!
    $createdDate: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTempTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getTempTokenByCreatedYearMonth(
      createdYearMonth: $createdYearMonth
      createdDate: $createdDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        tempToken
        accessToken
        createdYearMonth
        createdDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getS3AttachmentPathByExpiredDate = /* GraphQL */ `
  query GetS3AttachmentPathByExpiredDate(
    $expiredDate: AWSDate!
    $sortDirection: ModelSortDirection
    $filter: ModelExpiredAttachmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getS3AttachmentPathByExpiredDate(
      expiredDate: $expiredDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        expiredDate
        s3Path
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getAccountAdminNoticeByAdminNotice = /* GraphQL */ `
  query GetAccountAdminNoticeByAdminNotice(
    $adminNoticeId: ID!
    $accountId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccountAdminNoticeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAccountAdminNoticeByAdminNotice(
      adminNoticeId: $adminNoticeId
      accountId: $accountId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        adminNoticeId
        accountId
        readFlg
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
export const getAccountAdminNoticeByAccountId = /* GraphQL */ `
  query GetAccountAdminNoticeByAccountId(
    $accountId: ID!
    $adminNoticeId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAccountAdminNoticeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAccountAdminNoticeByAccountId(
      accountId: $accountId
      adminNoticeId: $adminNoticeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        adminNoticeId
        accountId
        readFlg
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`
