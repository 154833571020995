import { ComponentProps, forwardRef, memo, useCallback, useMemo, useState } from "react"
import { CustomTextInput } from "./CustomTextInput"
import { Platform, TextInput } from "react-native"

type OriginalProps = ComponentProps<typeof CustomTextInput>
type Props = Omit<OriginalProps, "multiline" | "onContentSizeChange"> & {
  minHeight?: number
  placeholder?: string
}

const adjustHeight = 16

export const MultilineTextInput = memo(
  forwardRef<TextInput, Props>(({ style, placeholder, ...otherProps }, ref) => {
    const { maxLength, onChangeText } = otherProps

    const [height, setHeight] = useState<number>()
    const resize = useCallback<Required<OriginalProps>["onContentSizeChange"]>((event) => {
      if (Platform.OS === "web") {
        return
      }
      setHeight(event.nativeEvent.contentSize.height)
    }, [])
    // webではonContentSizeChangeで返ってくる値がおかしいのでdomのscrollHeightの値を見てハンドリングする
    const resizeForWeb = useCallback<Required<OriginalProps>["onChange"]>((event) => {
      if (Platform.OS !== "web") {
        return
      }
      const currentTargetUnknown = event.currentTarget as unknown
      if (typeof currentTargetUnknown !== "object" || currentTargetUnknown == null) {
        return
      }
      const currentTarget = currentTargetUnknown as Record<keyof HTMLTextAreaElement, unknown>
      if (typeof currentTarget.scrollHeight === "number") {
        setHeight(currentTarget.scrollHeight - adjustHeight * 2)
      }
    }, [])
    const calculatedHeight = useMemo(
      () => Math.max(otherProps.minHeight || 160, (height ?? 0) + adjustHeight),
      [height, otherProps.minHeight]
    )

    const handleChange = (text: string) => {
      const processedText = text ? text.substring(0, maxLength ?? text.length) : ""
      if (onChangeText) {
        onChangeText(processedText)
      }
    }
    return (
      <CustomTextInput
        style={[{ height: calculatedHeight, paddingVertical: 8, textAlignVertical: "top" }, style]}
        ref={ref}
        {...otherProps}
        multiline
        onChangeText={handleChange}
        scrollEnabled
        onContentSizeChange={resize}
        onChange={resizeForWeb}
        placeholder={placeholder}
        placeholderTextColor={"#d3d3d3"}
      />
    )
  })
)
