import { useCallback, useState } from "react"
import { useSetRecoilState } from "recoil"
import { wait } from "src/utils/wait"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { sessionState } from "src/recoil/atoms/authorization/sessionState"
import { useAsyncState } from "src/hooks/useAsyncState"
import { removeAccountDevice } from "src/apis/auth/removeAccountDevice"
import { deleteAsync } from "src/storage/secureStorage/deleteAsync"
export const useSignout = () => {
  const [token] = useAsyncState(tokensState)
  const [isProcessingSignout, setIsProcessingSignout] = useState(false)
  const setSession = useSetRecoilState(sessionState)
  const setTokens = useSetRecoilState(tokensState)
  const signout = useCallback(async () => {
    setIsProcessingSignout(true)
    try {
      await wait(500)
      const accessToken = token?.accessToken ?? ""
      await removeAccountDevice(accessToken)
      setSession(undefined)
      setTokens(undefined)
      deleteAsync("applicantInformationData")
      deleteAsync("planData")
    } catch (error) {
      console.error(error)
      return
    } finally {
      setIsProcessingSignout(false)
    }
  }, [setSession, setTokens, token])

  return {
    isProcessingSignout,
    signout,
  }
}
