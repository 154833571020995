import { memo, useCallback, useMemo, useState } from "react"
import { ActivityIndicator, Image, Platform, StyleSheet, Text, View } from "react-native"
import { Colors } from "src/constants/Colors"
import { AttachmentFileType } from "src/constants/AttachmentFileType"
import * as Sharing from "expo-sharing"
import { CustomAlert } from "src/utils/CustomAlert"
import * as FileSystem from "expo-file-system"
import { useResource } from "src/recoil/hooks/resource/useResource"
import { Button } from "src/components/parts/buttons/Button"
import { CustomImageView } from "src/components/parts/CustomImageView"
import { CustomFileView } from "../CustomFileView"
import { ShareIcon } from "src/components/parts/icons/ShareIcon"
import { PlusIcon } from "src/components/parts/icons/PlusIcon"
import * as MediaLibrary from "expo-media-library"
import { v4 as uuidv4 } from "uuid"
import { DocumentIcon } from "src/components/parts/icons/DocumentIcon"
import { ExpiredAttachment } from "../icons/AttachmentIcon"
import { LIMIT_TIME_ERROR, TIME_DIFF_5MIN } from "src/utils/const"
import { fileTypeSet } from "src/constants/fileType"

type Props = {
  setIsLoadingFile?: (isLoading: boolean) => void
  organizationId: string
  offerId: string
  attachmentFileNames: string[]
}

const NATIVE_ERROR_MESSAGE =
  "アクセス許可がないため、ファイルのダウンロードができません。\n端末の設定よりアクセス許可の変更をお願いいたします。"

export const MatchingAttachmentViewer = memo<Props>(({ organizationId, offerId, attachmentFileNames, setIsLoadingFile }) => {
  const [isImageViewOpen, setIsImageViewOpen] = useState(false)
  const [isOpenFile, setIsOpenFile] = useState<boolean>(false)
  const [cacheUri, setCacheUri] = useState<string>("")
  const [fileUrl, setFileUrl] = useState<string>("")
  const [targetImageUrl, setTargetImageUrl] = useState<string>()
  const [targetImageFileName, setTargetImageFileName] = useState<string>()
  const openImageView = useCallback((url: string, fileName: string) => {
    setIsImageViewOpen(true)
    setTargetImageUrl(url)
    setTargetImageFileName(fileName)
  }, [])

  const openFileView = useCallback(async (url: string, cacheUri: string) => {
    setIsOpenFile(true)
    setCacheUri(cacheUri)
    setFileUrl(url)
  }, [])
  const [isShareLoading, setIsShareLoading] = useState(false)
  const [isSaveLoading, setIsSaveLoading] = useState(false)
  const [isSaveFileLoading, setIsSaveFileLoading] = useState<boolean>(false)

  const closeImageView = useCallback(() => {
    if (isShareLoading || isSaveLoading) {
      return
    }
    setIsImageViewOpen(false)
    setTargetImageUrl(undefined)
    setTargetImageFileName(undefined)
  }, [isSaveLoading, isShareLoading])

  const closeFileView = useCallback(() => {
    if (isShareLoading || isSaveFileLoading) {
      return
    }
    setIsOpenFile(false)
    setCacheUri("")
    setFileUrl("")
  }, [isShareLoading, isSaveFileLoading])
  const isShareAvailable = useMemo(async () => {
    const isAvailable = await Sharing.isAvailableAsync()
    return isAvailable
  }, [])

  const openShareSheet = useCallback(
    async (url?: string) => {
      if (!isShareAvailable || url == null) {
        CustomAlert.alert("エラー", NATIVE_ERROR_MESSAGE)
        return
      }

      try {
        const { uri: cacheUri } = await FileSystem.downloadAsync(
          url,
          FileSystem.cacheDirectory + url.substr(url.lastIndexOf("/") + 1)
        )
        await Sharing.shareAsync(cacheUri)
      } catch {
        CustomAlert.alert("エラー", NATIVE_ERROR_MESSAGE)
        return
      }
    },
    [isShareAvailable]
  )

  const saveFileForWeb = useCallback(async (url: string, fileName: string) => {
    const res = await fetch(url, { method: "GET", mode: "cors" })
    const blob = await res.blob()
    const blobUrl = window.URL.createObjectURL(blob)
    const a = document.createElement("a")
    a.download = fileName
    a.href = blobUrl
    a.click()
    a.remove()
    URL.revokeObjectURL(blobUrl)
  }, [])

  const saveFileForMobie = useCallback(async (url: string, fileName: string) => {
    if (Platform.OS === "web") return
    try {
      if (Platform.OS === "ios") {
        const extension = url.split("?")[0].split(".").pop()
        const downloadResult = await FileSystem.downloadAsync(url, FileSystem.cacheDirectory + `temp-${uuidv4()}.${extension}`)
        if (fileTypeSet.docsAndImages.includes(`.${extension}`)) {
          const appDirectory = FileSystem.documentDirectory + "my_files/"
          await FileSystem.makeDirectoryAsync(appDirectory, { intermediates: true })
          const filePath = appDirectory + fileName
          await FileSystem.moveAsync({ from: downloadResult.uri, to: filePath })
          await CustomAlert.alert("完了", "保存しました。")
        } else {
          await CustomAlert.alert("エラー", NATIVE_ERROR_MESSAGE)
        }
      }

      if (Platform.OS === "android") {
        const permissions = await FileSystem.StorageAccessFramework.requestDirectoryPermissionsAsync()

        if (permissions.granted) {
          const extension = url.split("?")[0].split(".").pop()
          const downloadResult = await FileSystem.downloadAsync(
            url,
            FileSystem.cacheDirectory + `temp-${uuidv4()}.${extension}`
          )
          if (fileTypeSet.docsAndImages.includes(`.${extension}`)) {
            const result = await FileSystem.readAsStringAsync(downloadResult.uri, { encoding: "base64" })
            await FileSystem.StorageAccessFramework.createFileAsync(
              permissions.directoryUri,
              fileName,
              downloadResult.mimeType ?? ""
            )
              .then(async (fileUri) => {
                await FileSystem.StorageAccessFramework.writeAsStringAsync(fileUri, result, { encoding: "base64" })
                await CustomAlert.alert("完了", "保存しました。")
              })
              .catch((e) => {
                console.log(e)
                CustomAlert.alert("エラー", NATIVE_ERROR_MESSAGE)
              })
          } else {
            await CustomAlert.alert("エラー", NATIVE_ERROR_MESSAGE)
          }
        }
      }
    } catch (e) {
      await CustomAlert.alert("エラー", NATIVE_ERROR_MESSAGE)
    }
  }, [])

  const saveMediaLibrary = useCallback(
    async (url: string, fileName: string) => {
      try {
        if (Platform.OS === "web") {
          await saveFileForWeb(url, fileName)
        } else {
          const extension = url.split("?")[0].split(".").pop()
          const downloadResult = await FileSystem.downloadAsync(
            url,
            // 参考: https://docs.expo.dev/versions/latest/sdk/filesystem/#filesystemcachedirectory
            FileSystem.cacheDirectory + `temp-${uuidv4()}.${extension}`
          )
          if (fileTypeSet.images.includes(`.${extension}`)) {
            await MediaLibrary.saveToLibraryAsync(downloadResult.uri)
            await CustomAlert.alert("完了", "保存しました。")
          } else if (fileTypeSet.docsAndImages.includes(`.${extension}`)) {
            const appDirectory = FileSystem.documentDirectory + "my_files/"
            await FileSystem.makeDirectoryAsync(appDirectory, { intermediates: true })
            const filePath = appDirectory + fileName
            await FileSystem.moveAsync({ from: downloadResult.uri, to: filePath })
            await CustomAlert.alert("完了", "保存しました。")
          } else {
            await CustomAlert.alert("エラー", NATIVE_ERROR_MESSAGE)
          }
        }
      } catch (e) {
        await CustomAlert.alert("エラー", Platform.OS === "web" ? "エラーが発生しました" : NATIVE_ERROR_MESSAGE)
      }
    },
    [saveFileForWeb]
  )
  const saveFileForAndroid = useCallback(
    async (url: string, fileName: string) => {
      if (Platform.OS === "android" && url) {
        const confirm = await CustomAlert.confirm(`${fileName} をダウンロードしますか？`)
        if (confirm) saveFileForMobie(url, fileName)
      }
    },
    [saveFileForMobie]
  )

  const onAttachmentPressHandler = useCallback(
    async (fileType: AttachmentFileType, url: string, fileName: string) => {
      if (Platform.OS === "web") {
        await saveMediaLibrary(url, fileName)
      } else {
        fileType === AttachmentFileType.Image
          ? await openImageView(url, fileName)
          : Platform.OS === "ios"
          ? await openFileView(url, fileName)
          : saveFileForAndroid(url, fileName)
      }
    },
    [openImageView, saveMediaLibrary, openFileView, saveFileForAndroid]
  )

  const FileViewerFooterComponent = useCallback(
    () => (
      <View style={styles.imageViewFooterContainer}>
        {Platform.OS !== "web" ? (
          <Button
            style={styles.imageViewFooterButton}
            onPress={() => {
              !isSaveFileLoading && setIsShareLoading(true)
              !isSaveFileLoading && !isShareLoading && openShareSheet(fileUrl).finally(() => setIsShareLoading(false))
            }}
          >
            {isShareLoading ? (
              <ActivityIndicator size="small" color={Colors.white3} />
            ) : (
              <ShareIcon size={20} fill={Colors.white3} />
            )}
          </Button>
        ) : null}
        {Platform.OS !== "ios" ? (
          <Button
            style={styles.imageViewFooterButton}
            onPress={async () => {
              if (isSaveFileLoading) {
                return
              }
              !isShareLoading && setIsSaveFileLoading(true)
              !isShareLoading && !isSaveFileLoading && fileUrl && cacheUri
              saveFileForMobie(fileUrl, cacheUri).finally(() => setIsSaveFileLoading(false))
            }}
          >
            {isSaveFileLoading ? (
              <ActivityIndicator size="small" color={Colors.white3} />
            ) : (
              <PlusIcon size={20} fillColor={Colors.white3} />
            )}
          </Button>
        ) : null}
      </View>
    ),
    [
      isSaveFileLoading,
      isShareLoading,
      openShareSheet,
      setIsShareLoading,
      fileUrl,
      cacheUri,
      saveFileForMobie,
      setIsSaveFileLoading,
    ]
  )

  const ImageViewerFooterComponent = useCallback(
    () => (
      <View style={styles.imageViewFooterContainer}>
        {Platform.OS !== "web" ? (
          <Button
            style={styles.imageViewFooterButton}
            onPress={() => {
              !isSaveLoading && setIsShareLoading(true)
              !isSaveLoading && !isShareLoading && openShareSheet(targetImageUrl).finally(() => setIsShareLoading(false))
            }}
          >
            {isShareLoading ? (
              <ActivityIndicator size="small" color={Colors.white3} />
            ) : (
              <ShareIcon size={20} fill={Colors.white3} />
            )}
          </Button>
        ) : null}
        <Button
          style={styles.imageViewFooterButton}
          onPress={() => {
            !isShareLoading && setIsSaveLoading(true)
            !isShareLoading &&
              !isSaveLoading &&
              targetImageUrl &&
              targetImageFileName &&
              saveMediaLibrary(targetImageUrl, targetImageFileName).finally(() => setIsSaveLoading(false))
          }}
        >
          {isSaveLoading ? (
            <ActivityIndicator size="small" color={Colors.white3} />
          ) : (
            <PlusIcon size={20} fillColor={Colors.white3} />
          )}
        </Button>
      </View>
    ),
    [isShareLoading, isSaveLoading, openShareSheet, targetImageUrl, targetImageFileName, saveMediaLibrary]
  )

  let isExpired = false
  const total = attachmentFileNames.length
  if (total > 0) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { resourceUrl: resourceGetUrl } = useResource({
      type: "matchingOfferAttachmentFile",
      teamId: organizationId,
      offerId,
      index: total - 1,
      fileName: attachmentFileNames[total - 1],
    })
    isExpired = resourceGetUrl == LIMIT_TIME_ERROR
  }

  return (
    <>
      {isExpired && (
        <View style={{ paddingTop: 25 }}>
          <Text style={styles.textExpired}>ダウンロード可能期間が過ぎているため、ファイルのダウンロードはできません。</Text>
          <Text style={styles.textExpired}>※ 添付ファイルをダウンロードできる期間は送信日から30日以内になります。</Text>
        </View>
      )}
      <View style={styles.attachmentContainer}>
        {attachmentFileNames.map((fileName, index) => (
          <AttachmentItem
            teamId={organizationId}
            offerId={offerId}
            index={index}
            isExpired={isExpired}
            fileName={fileName}
            onAttachmentPressHandler={onAttachmentPressHandler}
            key={index}
          />
        ))}
      </View>
      <CustomImageView
        imageUri={targetImageUrl}
        visible={isImageViewOpen}
        onRequestClose={closeImageView}
        FooterComponent={ImageViewerFooterComponent}
      />
      <CustomFileView
        fileUri={fileUrl}
        visible={isOpenFile}
        onRequestClose={closeFileView}
        FooterComponent={FileViewerFooterComponent}
      />
    </>
  )
})

type AttachmentItemProps = {
  teamId: string
  offerId: string
  index: number
  isExpired: boolean
  fileName: string
  onAttachmentPressHandler: (fileType: AttachmentFileType, url: string, fileName: string) => void
}

const AttachmentItem = memo<AttachmentItemProps>(
  ({ teamId, offerId, index, isExpired, fileName, onAttachmentPressHandler }) => {
    const [lastRefreshTime, setLastRefreshTime] = useState<number>(new Date().getTime())

    const { resourceUrl } = useResource({
      type: "matchingOfferAttachmentFile",
      teamId,
      offerId,
      index,
      fileName,
    })

    const extension = (resourceUrl || "").split("?")[0].split(".").pop()
    const fileType =
      extension && ["jpg", "jpeg", "png", "gif", "JPG", "PNG", "JPEG", "GIF"].includes(extension)
        ? AttachmentFileType.Image
        : AttachmentFileType.Other

    const { refreshResourceUrl: refreshFile } = useResource(
      fileType === AttachmentFileType.Other
        ? {
            type: "matchingOfferAttachmentFile",
            teamId: teamId,
            fileName: fileName,
            index: index,
            offerId: offerId,
          }
        : {
            type: "none",
          }
    )

    const { resourceUrl: thumbnailUrl, refreshResourceUrl: refreshThumbnail } = useResource(
      fileType === AttachmentFileType.Image
        ? {
            type: "matchingOfferAttachmentThumbnail",
            teamId: teamId,
            fileName: fileName,
            index: index,
            offerId: offerId,
          }
        : { type: "none" }
    )

    console.log("thumbnailUrl: ", thumbnailUrl)

    return (
      <>
        {!isExpired ? (
          <Button
            style={styles.attachmentButtonContainer}
            key={index}
            onPress={async () => {
              if (resourceUrl) {
                const nowTime = new Date().getTime()
                if (!!lastRefreshTime && nowTime - lastRefreshTime > TIME_DIFF_5MIN) {
                  await refreshFile()
                  await refreshThumbnail()
                  setLastRefreshTime(nowTime)
                }
                onAttachmentPressHandler(fileType, resourceUrl, fileName)
              }
            }}
          >
            <View style={styles.attachmentItem}>
              {thumbnailUrl ? (
                <Image
                  resizeMode="cover"
                  source={{ uri: thumbnailUrl }}
                  style={styles.attachmentThumbnailContainer}
                  onError={refreshThumbnail}
                />
              ) : (
                <View style={styles.attachmentThumbnailContainer}>
                  <DocumentIcon />
                </View>
              )}
              <Text style={styles.attachmentLabel}>{fileName}</Text>
            </View>
          </Button>
        ) : (
          <Button style={styles.attachmentButtonContainer} key={index} disabled>
            <View style={styles.attachmentItem}>
              <ExpiredAttachment></ExpiredAttachment>
              <Text style={styles.attachmentLabel}>{fileName}</Text>
            </View>
          </Button>
        )}
      </>
    )
  }
)
const largePaddingHorizontal = 35

const styles = StyleSheet.create({
  attachmentContainer: {
    flexDirection: "column",
    paddingHorizontal: largePaddingHorizontal,
    paddingVertical: 20,
  },
  attachmentButtonContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 4,
  },
  attachmentItem: {
    height: 50,
    backgroundColor: Colors.reddishGrey,
    borderRadius: 10,
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    overflow: "hidden",
  },
  attachmentThumbnailContainer: {
    width: 50,
    height: 50,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f2caa0",
  },
  attachmentLabel: {
    fontSize: 15,
    color: Colors.greyshBrown,
    marginLeft: 16,
  },
  attachmentThumbnail: {
    width: 98,
    height: 98,
    borderRadius: 4,
    backgroundColor: "#f2caa0",
    overflow: "hidden",
    justifyContent: "center",
    alignItems: "center",
  },
  textExpired: {
    paddingHorizontal: 35,
    paddingTop: 10,
    color: Colors.red,
  },
  attachmentThumbnailFileName: {
    width: 98,
    marginVertical: 4,
    fontSize: 12,
  },
  imageViewFooterContainer: {
    width: "100%",
    height: 100,
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    paddingHorizontal: 26,
    paddingBottom: 30,
  },
  imageViewFooterButton: {
    marginLeft: 16,
  },
})
