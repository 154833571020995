import { useCallback, useEffect, useMemo, useState } from "react"
import { postContactAsDraft } from "src/apis/contact/postContactAsDraft"
import { postNewContact } from "src/apis/contact/postNewContact"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { useAsyncState } from "src/hooks/useAsyncState"
import { useFetcher } from "src/hooks/useFetcher"
import { bodyState } from "src/recoil/atoms/contact/create/bodyState"
import { contactTypeState } from "src/recoil/atoms/contact/create/contactTypeState"
import { deadlineDateState } from "src/recoil/atoms/contact/create/deadlineDateState"
import { eventDateCandidatesState } from "src/recoil/atoms/contact/create/eventDateCandidatesState"
import { isAddToOtherAdminsHistoryState } from "src/recoil/atoms/contact/create/isAddToOtherAdminsHistoryState"
import { isPublishAnswersToMembersState } from "src/recoil/atoms/contact/create/isPublishAnswersToMembersState"
import { questionsState } from "src/recoil/atoms/contact/create/questionsState"
import { reminderDateState } from "src/recoil/atoms/contact/create/reminderDateState"
import { reservationDateState } from "src/recoil/atoms/contact/create/reservationDateState"
import { templateIdState } from "src/recoil/atoms/contact/create/templateIdState"
import { titleState } from "src/recoil/atoms/contact/create/titleState"
import { partialRequestBodySelector } from "src/recoil/selectors/contact/create/partialRequestBodySelector"
import { receiversSelector } from "src/recoil/selectors/contact/create/receiversSelector"
import { requestBodySelector } from "src/recoil/selectors/contact/create/requestBodySelector"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { TemplateModel } from "src/types/contact/TemplateModel"
import { useAuthorizationData } from "../authorization/useAuthorizationData"
import { CustomAlert } from "src/utils/CustomAlert"
import { useRefresher } from "src/hooks/useRefresher"
import { inboxContactsRequestIdState } from "src/recoil/atoms/contact/inboxContactsRequestIdState"
import { getTempMailId } from "src/apis/contact/getTempMailId"
import { Result } from "src/utils/Result"
import { AttachmentItemProps } from "src/components/parts/contactNetworkTab/ContactDetailEditor"
import { useResetter } from "./contact/create/useResetter"
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil"
import { createContactModeState } from "src/recoil/atoms/contact/create/createContactModeState"
import { existingContactIdState } from "src/recoil/atoms/contact/create/existingContactIdState"
import { updateContact as updateContactApi } from "src/apis/contact/updateContact"
import { contactDetailRequestIdState } from "src/recoil/atoms/contact/contactDetailRequestIdState"
import { draftIdState } from "src/recoil/atoms/contact/create/draftIdState"
import { updateDraft } from "src/apis/contact/updateDraft"
import { draftContactsRequestIdState } from "src/recoil/atoms/contact/draftContactsRequestIdState"
import { outboxContactsRequestIdState } from "src/recoil/atoms/contact/outboxContactsRequestIdState"
import { getEventDateCandidate } from "src/types/contact/EventDateCandidate"
import { useResetNewContactOptionState } from "src/recoil/hooks/contact/useResetNewContactOption"
import { ContactType } from "src/constants/ContactType"
import { reservedIdState } from "src/recoil/atoms/contact/create/reservedIdState"
import { updateReservedContact } from "src/apis/contact/updateReservedContact"
import { attachmentFileNamesState } from "src/recoil/atoms/contact/create/attachmentFileNamesState"
import {
  inboxContactFavoriteFlgsCacheState,
  outboxContactFavoriteFlgsCacheState,
} from "src/recoil/atoms/contact/contactFavoriteFlgsCacheState"
import { wait } from "src/utils/wait"
import { Analytics } from "src/tags/analytics/Analytics"
import { isReceiversAllMembersState } from "src/recoil/atoms/contact/create/isReceiversAllMembersState"
import { isReceiversAllLeadersState } from "src/recoil/atoms/contact/create/isReceiversAllLeadersState"
import { selectedGroupIdsState } from "src/recoil/atoms/contact/create/selectedGroupIdsState"
import { selectedMemberIdsState } from "src/recoil/atoms/contact/create/selectedMemberIdsState"
import { sendDateState } from "src/recoil/atoms/contact/create/sendDateState"
import { ERROR_017_MESSAGE } from "src/utils/const"
type Params = {
  goBack: () => void
  goSubmittedContact: (label: string, message?: string) => void
}

export const useCreateNewContactData = ({ goBack, goSubmittedContact }: Params) => {
  const contactId = useRecoilValue(existingContactIdState)
  const reservedId = useRecoilValue(reservedIdState)
  const { value: selectedOrganization } = useAsyncSelector(selectedMyOrganizationSelector)
  const organizationId = useMemo(() => selectedOrganization?.id, [selectedOrganization])
  const organizationName = useMemo(() => selectedOrganization?.title, [selectedOrganization])
  const organizationThumbnailURL = useMemo(() => selectedOrganization?.uriImage, [selectedOrganization])
  const mode = useRecoilValue(createContactModeState)
  const [templateId] = useAsyncState(templateIdState)
  const [draftId, setDraftId] = useAsyncState(draftIdState)
  const resetAllUserInput = useResetter()
  const selectTemplate = useCallback(
    (id?: TemplateModel["id"]) => {
      resetAllUserInput({ mode: "New", templateId: id })
    },
    [resetAllUserInput]
  )
  const resetNewContactOption = useResetNewContactOptionState()
  const [contactType, setContactType] = useAsyncState(contactTypeState)
  const onContactTypeChange = useCallback(
    (contactType: ContactType) => {
      resetNewContactOption()
      setContactType(contactType)
    },
    [setContactType, resetNewContactOption]
  )
  const { value: receivers } = useAsyncSelector(receiversSelector)

  const setIsReceiversAllMembers = useSetRecoilState(isReceiversAllMembersState)
  const setIsReceiversAllLeaders = useSetRecoilState(isReceiversAllLeadersState)
  const setSelectedGroupIds = useSetRecoilState(selectedGroupIdsState)
  const setSelectedMemberIds = useSetRecoilState(selectedMemberIdsState)
  const [sendDate, setSendDate] = useAsyncState(sendDateState)

  const resetReceiverType = useCallback(() => {
    setIsReceiversAllMembers(undefined)
    setIsReceiversAllLeaders(undefined)
    setSelectedGroupIds(undefined)
    setSelectedMemberIds(undefined)
  }, [setIsReceiversAllMembers, setIsReceiversAllLeaders, setSelectedGroupIds, setSelectedMemberIds])

  const [title, setTitle] = useAsyncState(titleState)
  const [body, setBody] = useAsyncState(bodyState)
  const [isAddToOtherAdminsHistory, setIsAddToOtherAdminsHistory] = useAsyncState(isAddToOtherAdminsHistoryState)
  const toggleIsAddToOtherAdminsHistory = useCallback(
    () => setIsAddToOtherAdminsHistory((f) => !f),
    [setIsAddToOtherAdminsHistory]
  )
  const [isPublishAnswersToMembers, setIsPublishAnswersToMembers] = useAsyncState(isPublishAnswersToMembersState)
  const toggleIsPublishAnswersToMembers = useCallback(
    () => setIsPublishAnswersToMembers((f) => !f),
    [setIsPublishAnswersToMembers]
  )
  const [deadlineDate, setDeadlineDate] = useAsyncState(deadlineDateState)
  const [reminderDate, setReminderDate] = useAsyncState(reminderDateState)
  const [reservationDate, setReservationDate] = useAsyncState(reservationDateState)
  const [questions, setQuestions] = useAsyncState(questionsState)

  const { value: requestBody } = useAsyncSelector(requestBodySelector)
  const { value: partialRequestBody } = useAsyncSelector(partialRequestBodySelector)

  const { accessToken } = useAuthorizationData()

  const refreshContacts = useRefresher(inboxContactsRequestIdState)
  const resetFavoriteFlgsCache = useResetRecoilState(inboxContactFavoriteFlgsCacheState)
  const refreshOutbox = useRefresher(outboxContactsRequestIdState)
  const resetFavoriteFlgsCacheOutbox = useResetRecoilState(outboxContactFavoriteFlgsCacheState)
  const refreshContactDetail = useRefresher(contactDetailRequestIdState)
  const refreshDrafts = useRefresher(draftContactsRequestIdState)

  const [tempMailId, setTempMailId] = useState<string>()
  const { value: attachmentFileNames } = useAsyncSelector(attachmentFileNamesState)

  const [attachmentItemProps, setAttachmentItemProps] = useState<Readonly<AttachmentItemProps[]>>([])
  const [attachmentUploaders, setAttachmentUploaders] = useState<
    Readonly<(() => Promise<Result<undefined, { message: string }>>)[]>
  >([])
  const [attachmentThumbnailUploaders, setAttachmentThumbnailUploaders] = useState<
    Readonly<(() => Promise<Result<undefined, { message: string }>>)[]>
  >([])
  const [isCreateDraft, setIsCreateDraft] = useState(false)

  const { fetch: saveAsDraft, isFetching: isSavingAsDraft } = useFetcher(
    useCallback(async () => {
      if (
        partialRequestBody == null ||
        !partialRequestBody.isOk ||
        accessToken == null ||
        organizationId == null ||
        tempMailId == null
      ) {
        return
      }
      if (!draftId && !isCreateDraft) {
        if ((title && title.length > 0) || (body && body.length > 0)) {
          setIsCreateDraft(true)
          let mailId = tempMailId ?? ""
          if (!tempMailId) {
            const res = await getTempMailId({ accessToken, organizationId })
            if (res.isOk) {
              mailId = res.content
              setTempMailId(mailId)
            }
          }
          await wait(0.5)
          const result = await postContactAsDraft({
            accessToken,
            organizationId,
            tempMailId,
            ...partialRequestBody.content,
          })
          if (result.isOk && result.content) {
            setDraftId(result.content)
          } else if (!result.isOk && result.error.message !== ERROR_017_MESSAGE) {
            await CustomAlert.alert("エラー", result.error.message)
          } else if (!result.isOk && result.error.message === ERROR_017_MESSAGE) {
            await CustomAlert.alert(
              "エラー",
              "下書きの保存上限数（10件）\nに達しているため、保存できません。そのまま連絡を送信してください。"
            )
          }
        }
      } else if (draftId) {
        const result = await updateDraft({
          accessToken,
          draftId,
          ...partialRequestBody.content,
        })
        if (!result.isOk) {
          await CustomAlert.alert("エラー", result.error.message)
        }
      }
    }, [partialRequestBody, accessToken, organizationId, tempMailId, draftId, setDraftId, body, isCreateDraft, title])
  )

  const getDateValidationMessage = useCallback(() => {
    const now = new Date()
    if (deadlineDate && deadlineDate <= now) {
      return "現在時刻よりも前の日時が設定されています。"
    } else if (reservationDate) {
      if (reservationDate <= now) {
        return "送信予約に現在時刻よりも前の日時が設定されています。"
      } else if (reminderDate && reservationDate >= reminderDate && deadlineDate && reservationDate >= deadlineDate) {
        return "送信予約日時が催促日時・回答期限以降に設定されています。"
      } else if (reminderDate && reservationDate >= reminderDate) {
        return "送信予約日時が催促日時以降に設定されています。"
      } else if (deadlineDate && reservationDate >= deadlineDate) {
        return "送信予約日時が回答期限以降に設定されています。"
      }
    } else if (reminderDate && reminderDate <= now) {
      return "現在時刻よりも前の日時が設定されています。"
    } else if (reminderDate && deadlineDate && reminderDate >= deadlineDate) {
      return "催促日時が回答期限以降に設定されています。"
    } else {
      return undefined
    }
  }, [deadlineDate, reminderDate, reservationDate])

  const { fetch: sendContact, isFetching: isSendingContact } = useFetcher(
    useCallback(async () => {
      if (
        requestBody == null ||
        requestBody.length === 0 ||
        !requestBody[0].isOk ||
        accessToken == null ||
        selectedOrganization == null ||
        tempMailId == null
      ) {
        return
      }
      const dateValidationMessage = getDateValidationMessage()
      if (dateValidationMessage) {
        await CustomAlert.alert(dateValidationMessage)
        return
      }

      if (requestBody[0].content.contactType === ContactType.ScheduleAdjustment) {
        const nowDay = new Date()
        const filters = requestBody[0].content.eventDateCandidates?.filter((t) => t.startDate < nowDay)
        if (filters.length !== 0) {
          await CustomAlert.alert("エラー", "日程調整は現在時刻よりも前の日時が設定されています。")
          return
        }
      }

      const uploadAttachmentsResult = await Promise.all([
        ...attachmentUploaders.map((x) => x()),
        ...attachmentThumbnailUploaders.map((x) => x()),
      ])
      if (uploadAttachmentsResult.some((res) => !res.isOk)) {
        await CustomAlert.alert("エラー", "連絡の送信に失敗しました。")
        return
      }
      const result = await postNewContact({
        accessToken,
        ...requestBody[0].content,
        organizationId: selectedOrganization.id,
        tempMailId,
        attachmentFileNames: attachmentItemProps.map((p) => p.fileName),
      })
      const [title, message] =
        reservationDate == null
          ? ["送信しました。", undefined]
          : [
              "送信予約しました。",
              "※予定設定日時になりましたら送信されます。送信には1〜2分かかることがございます。\n※システムやネットワークの混雑等により、やむを得ず連絡の到着が遅れることがございます。",
            ]
      if (result.isOk) {
        if (organizationId != null) await Analytics.sendMail(organizationId)
        wait(3000).then((_) => {
          refreshContacts()
          resetFavoriteFlgsCache()
          refreshOutbox()
          resetFavoriteFlgsCacheOutbox()
          refreshDrafts()
        })
        goSubmittedContact(title, message)
      } else {
        await CustomAlert.alert("エラー", result.error.message)
      }
    }, [
      requestBody,
      accessToken,
      selectedOrganization,
      refreshContacts,
      resetFavoriteFlgsCache,
      refreshOutbox,
      resetFavoriteFlgsCacheOutbox,
      refreshDrafts,
      tempMailId,
      attachmentItemProps,
      attachmentUploaders,
      attachmentThumbnailUploaders,
      goSubmittedContact,
      getDateValidationMessage,
      reservationDate,
      organizationId,
    ])
  )

  const { fetch: updateContact, isFetching: isUpdatingContact } = useFetcher(
    useCallback(async () => {
      if (requestBody == null || requestBody.length === 0 || !requestBody[0].isOk || accessToken == null) {
        return
      }
      let result: Result<unknown, { message: string }>
      if (mode === "Edit" && contactId != null) {
        result = await updateContactApi({
          accessToken,
          contactId,
          title: requestBody[0].content.title,
          body: requestBody[0].content.body,
        })
      } else if (mode === "EditReserved" && reservedId != null) {
        const dateValidationMessage = getDateValidationMessage()
        if (dateValidationMessage) {
          await CustomAlert.alert("エラー", dateValidationMessage)
          return
        }

        result = await updateReservedContact({
          accessToken,
          reservedContactId: reservedId,
          ...requestBody[0].content,
        })
      } else {
        return
      }

      if (result.isOk) {
        wait(3000).then((_) => {
          refreshContacts()
          resetFavoriteFlgsCache()
          refreshOutbox()
          resetFavoriteFlgsCacheOutbox()
          refreshContactDetail()
        })
        goSubmittedContact(mode === "Edit" ? "編集しました。" : "送信予約を編集しました。")
      } else {
        await CustomAlert.alert("エラー", result.error.message)
      }
    }, [
      mode,
      contactId,
      reservedId,
      requestBody,
      accessToken,
      refreshContacts,
      resetFavoriteFlgsCache,
      refreshOutbox,
      resetFavoriteFlgsCacheOutbox,
      refreshContactDetail,
      goSubmittedContact,
      getDateValidationMessage,
    ])
  )

  const [eventDateCandidates, setEventDateCandidates] = useAsyncState(eventDateCandidatesState)

  const isCreateNewContactDisabled = useMemo(() => {
    if (requestBody == null) return true
    if (requestBody.length === 0) return true
    if (requestBody[0].isOk !== true || isSendingContact || isSavingAsDraft) return true

    if (contactType === "Survey") {
      return !(
        questions &&
        questions.length > 0 &&
        questions.every((q) => q.title && q.choices.length > 0 && q.choices.every((c) => c))
      )
    } else if (contactType === "ScheduleAdjustment") {
      return !(
        eventDateCandidates &&
        eventDateCandidates.length > 0 &&
        eventDateCandidates.every((e) => getEventDateCandidate(e) != null)
      )
    } else {
      return false
    }
  }, [requestBody, isSendingContact, isSavingAsDraft, contactType, questions, eventDateCandidates])
  const isSaveAsDraftDisabled = useMemo(
    () =>
      partialRequestBody?.isOk !== true ||
      !partialRequestBody.content.title ||
      !partialRequestBody.content.body ||
      isSendingContact ||
      isSavingAsDraft,
    [partialRequestBody, isSendingContact, isSavingAsDraft]
  )

  useEffect(() => {
    if (accessToken == null || organizationId == null) return
    if (draftId) {
      setTempMailId(draftId)
    } else {
      getTempMailId({ accessToken, organizationId }).then((res) => {
        if (res.isOk) {
          setTempMailId(res.content)
        } else {
          CustomAlert.alert("エラー", "連絡作成情報の取得に失敗しました。")
          goBack()
        }
      })
    }
  }, [accessToken, organizationId, setTempMailId, goBack, tempMailId, draftId])

  return {
    mode,
    contactId,
    reservedId,
    organizationId,
    organizationName,
    organizationThumbnailURL,
    templateId,
    selectTemplate,
    contactType,
    onContactTypeChange,
    receivers,
    title,
    setTitle,
    body,
    sendDate,
    setBody,
    isAddToOtherAdminsHistory,
    toggleIsAddToOtherAdminsHistory,
    isPublishAnswersToMembers,
    toggleIsPublishAnswersToMembers,
    deadlineDate,
    setDeadlineDate,
    reminderDate,
    setReminderDate,
    reservationDate,
    setReservationDate,
    saveAsDraft,
    isSavingAsDraft,
    sendContact,
    updateContact,
    isSendingContact,
    isUpdatingContact,
    questions,
    setQuestions,
    eventDateCandidates,
    isCreateNewContactDisabled,
    isSaveAsDraftDisabled,
    tempMailId,
    attachmentFileNames,
    attachmentItemProps,
    setAttachmentItemProps,
    setAttachmentUploaders,
    setAttachmentThumbnailUploaders,
    setEventDateCandidates,
    resetReceiverType,
  }
}
