import { ComponentProps, memo, useMemo } from "react"
import { StyleSheet, Text } from "react-native"
import { Colors } from "src/constants/Colors"

import type { ValueOf } from "src/types.d"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { Button } from "src/components/parts/buttons/Button"

export const ButtonType = Object.freeze({
  Primary: "Primary",
  Secondary: "Secondary",
  Blank: "Blank",
  Unprioritized: "Unprioritized",
  Card: "Card",
} as const)

export type ButtonType = ValueOf<typeof ButtonType>

export const ButtonSize = Object.freeze({
  M: "M",
  S: "S",
} as const)

export type ButtonSize = ValueOf<typeof ButtonSize>

type Props = ComponentProps<typeof Button> & {
  buttonType: ButtonType
  buttonSize?: ButtonSize
  title: string
  onPress: () => void
  disabled?: boolean
  isLoading?: boolean
  styleTitle?: any
}

export const TextButton = memo<Props>(
  ({ buttonType, buttonSize = ButtonSize.M, title, onPress, style, styleTitle, disabled, isLoading, ...rest }) => {
    const styles = useMemo(() => {
      switch (buttonType) {
        case ButtonType.Primary:
          return primaryStyles
        case ButtonType.Secondary:
          return secondaryStyles
        case ButtonType.Blank:
          return blankStyles
        case ButtonType.Unprioritized:
          return unprioritizedStyles
        case ButtonType.Card:
          return cardStyles
      }
    }, [buttonType])

    const buttonSizeStyle = useMemo(() => {
      switch (buttonSize) {
        case ButtonSize.M:
          return commonStyles.m
        case ButtonSize.S:
          return commonStyles.s
      }
    }, [buttonSize])

    const buttonTextStyle = useMemo(() => {
      switch (buttonSize) {
        case ButtonSize.M:
          return commonStyles.textM
        case ButtonSize.S:
          return commonStyles.textS
      }
    }, [buttonSize])

    const isDisabled = useMemo(() => disabled || isLoading, [disabled, isLoading])

    return (
      <Button
        onPress={onPress}
        buttonType={buttonType}
        style={[
          commonStyles.button,
          buttonSizeStyle,
          title.length > 8 ? { paddingHorizontal: 16 } : {},
          disabled ? styles.disabledButton : styles.button,
          style,
        ]}
        disabled={isDisabled}
        {...rest}
      >
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <Text style={[disabled ? styles.disabledLabel : styles.text, buttonTextStyle, styleTitle]}>{title}</Text>
        )}
      </Button>
    )
  }
)

const commonStyles = StyleSheet.create({
  button: {
    justifyContent: "center",
    alignItems: "center",
  },
  m: {
    height: 50,
    borderRadius: 25,
    paddingHorizontal: 24,
  },
  s: {
    height: 32,
    borderRadius: 16,
    paddingHorizontal: 16,
  },
  textM: {
    fontSize: 18,
    fontWeight: "700",
  },
  textS: {
    fontSize: 14,
    fontWeight: "500",
  },
})

const primaryStyles = StyleSheet.create({
  button: {
    backgroundColor: Colors.orange,
  },
  disabledButton: {
    backgroundColor: "rgba(244, 171, 131, 0.3)",
  },
  text: {
    color: "#fff",
  },
  disabledLabel: {
    color: Colors.white,
  },
})

const secondaryStyles = StyleSheet.create({
  button: {
    backgroundColor: Colors.white3,
    borderWidth: 2,
    borderColor: Colors.orange,
  },
  disabledButton: {
    backgroundColor: Colors.white2,
    borderWidth: 1,
    borderColor: Colors.pale,
  },
  text: {
    color: Colors.orange,
  },
  disabledLabel: {
    color: Colors.beige,
  },
})

const blankStyles = StyleSheet.create({
  button: {},
  text: {
    color: Colors.orange,
  },
  disabledButton: {},
  disabledLabel: {
    color: Colors.orange,
  },
})

const unprioritizedStyles = StyleSheet.create({
  button: {
    borderRadius: 10,
    backgroundColor: Colors.reddishGrey,
  },
  text: {
    color: Colors.orange,
  },
  disabledButton: {},
  disabledLabel: {},
})

const cardStyles = StyleSheet.create({
  button: {
    backgroundColor: Colors.white3,
    borderRadius: 10,
    alignItems: "flex-start",
  },
  text: {},
  disabledButton: {},
  disabledLabel: {},
})
