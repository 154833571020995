import { memo, useEffect, useCallback, useState, useRef } from "react"
import { Dimensions, Image, StyleSheet, Text, View, Animated, Linking } from "react-native"
import { Button } from "src/components/parts/buttons/Button"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { RakurakuKeyVisual } from "src/components/parts/icons/RakurakuKeyVisual"
import { Colors } from "src/constants/Colors"
import { Screens } from "src/constants/Screens"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { RootStackScreenProps } from "src/types.d"
import { requestTrackingPermissionsAsync } from "expo-tracking-transparency"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { useSetRecoilState } from "recoil"
import { completedSignUpFlagState } from "src/recoil/atoms/account/completedSignUpFlagState"
import { ScrollView } from "react-native-gesture-handler"
import * as LinkExpo from "expo-linking"
import ArrowIcon from "../../components/parts/icons/ArrowIcon"
import GooglePlay from "src/assets/images/google-play-badge-1.png"
import Logo from "src/assets/images/Logo.png"
import Banner from "src/assets/images/banner.png"
import BannerMobile from "src/assets/images/bannerMobile.png"
import Feature1 from "src/assets/images/feature1.png"
import Feature2 from "src/assets/images/feature2.png"
import Feature3 from "src/assets/images/feature3.png"
import Banner2 from "src/assets/images/banner2.png"
import AppStore from "src/assets/images/appstore.png"
import Web from "src/assets/images/web.png"
import Post from "src/assets/images/post.png"
import { paidVersionURL } from "src/constants/links/paidVersion"

export const AuthorizationTop = memo<RootStackScreenProps<typeof Screens.AuthorizationTop>>(({ navigation }) => {
  const [urlState, setUrlState] = useState(window.location.href)
  const [openMenu, setOpenMenu] = useState(false)
  const [yLocation, setYLocation] = useState(0)
  const isLargeScreen = useCheckPCScreen()
  const viewHeight = Dimensions.get("window").height
  const [boxWidth, setBoxWidth] = useState(0)
  const [contentHeight, setContentHeight] = useState(0)

  const showBtnFix = () => {
    if (isLargeScreen) {
      return yLocation + viewHeight >= contentHeight - 237
    } else {
      return yLocation + viewHeight >= contentHeight - 423
    }
  }

  const showFloatingHandle = () => {
    if (isLargeScreen) return false
    return yLocation >= 70
  }

  const heightMenu = useState(new Animated.Value(0))[0]

  const handleOpenMenu = () => {
    Animated.timing(heightMenu, {
      toValue: openMenu ? 0 : 158,
      duration: 500,
      useNativeDriver: true,
    }).start()
  }
  const scrollViewRef = useRef<ScrollView>(null)
  const handleScrollToTop = () => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollTo({ x: 0, y: 0, animated: true })
    }
  }

  const handleLayout = (event: any) => {
    const { width } = event.nativeEvent.layout
    setBoxWidth(width)
  }

  const toggleMenu = () => {
    setOpenMenu(!openMenu)
    handleOpenMenu()
  }

  //Native
  const { top, bottom } = useSafeAreaInsets()
  const setCompletedSignUpState = useSetRecoilState(completedSignUpFlagState)
  const gotoLogin = useCallback(() => {
    setCompletedSignUpState(false)
    navigation.navigate(Screens.LoginEmail)
  }, [navigation, setCompletedSignUpState])
  const gotoSignup = useCallback(async () => {
    navigation.navigate(Screens.SignupEmail)
  }, [navigation])
  useEffect(() => {
    ;(async () => requestTrackingPermissionsAsync())()
  }, [])

  return (
    <>
      {urlState.includes("Login") ? (
        <View style={[styles.containerNative, { paddingTop: top }]}>
          <View style={styles.title}>
            <RakurakuKeyVisual />
          </View>
          <View style={styles.actions}>
            <View style={styles.arcContainer}>
              <View style={styles.arc} />
            </View>
            <View style={isLargeScreen ? styles.containerPC : { flexDirection: "row", width: "100%" }}>
              <View style={[isLargeScreen ? styles.buttonsPC : styles.buttons, { paddingBottom: bottom + 32 }]}>
                <View style={[styles.buttonContainer]}>
                  <Text
                    style={[{ fontWeight: "700", fontSize: 16, marginBottom: 16 }]}
                  >{`初めてご利用する方/\nらくらく連絡網（旧サービス）から移行する方`}</Text>
                  <TextButton title="新規登録" buttonType={ButtonType.Secondary} onPress={gotoSignup} />
                </View>
                <View style={[styles.buttonContainer]}>
                  <Text style={[{ fontWeight: "700", fontSize: 16, marginBottom: 8 }]}>{`すでにアカウントをお持ちの方`}</Text>
                  <Text style={[{ fontSize: 14, marginBottom: 16 }]}>{`※らくらく連絡網のアカウントではありません。`}</Text>
                  <TextButton title="ログイン" buttonType={ButtonType.Primary} onPress={gotoLogin} />
                </View>
              </View>
            </View>
          </View>
        </View>
      ) : (
        <View style={[styles.container]}>
          {/* Header */}
          <View style={[styles.headerContainer]}>
            <View style={[styles.headerContentContainer, isLargeScreen ? {} : { height: 62 }]}>
              <View style={[{ flex: 1, height: 36 }]}>
                <Button
                  style={[
                    styles.logoLink,
                    isLargeScreen ? {} : { width: "50%", justifyContent: "center", maxWidth: 232, maxHeight: 31 },
                  ]}
                  onPress={() => {
                    location.reload()
                  }}
                >
                  <Image
                    source={Logo}
                    style={[styles.imageLogo, isLargeScreen ? {} : { height: undefined, width: "100%", aspectRatio: 6.97 }]}
                  />
                </Button>
              </View>
              {isLargeScreen ? (
                <View style={[{ display: "flex", width: "50%" }]}>
                  <View style={[styles.navLinkContainer]}>
                    <Text
                      selectable={false}
                      onPress={() => {
                        LinkExpo.openURL("https://www.ra9plus.jp/lp/migration/index.html")
                      }}
                      style={[styles.navLink]}
                    >
                      らくらく連絡網からの移行
                    </Text>
                    <Text
                      selectable={false}
                      onPress={() => {
                        LinkExpo.openURL("https://www.ra9plus.jp/guide/")
                      }}
                      style={[styles.navLink]}
                    >
                      ご利用ガイド
                    </Text>
                    <Text
                      selectable={false}
                      onPress={() => {
                        LinkExpo.openURL("https://www.ra9plus.jp/post/")
                      }}
                      style={[styles.navLink]}
                    >
                      お知らせ
                    </Text>
                    <Button
                      onPress={() => {
                        window.location.href = window.location.href + "?Login"
                      }}
                      style={[styles.buttonNav]}
                    >
                      <Text style={{ color: Colors.white3, fontSize: 15, fontWeight: "500", fontFamily: "Noto Sans JP" }}>
                        新規登録/ログイン
                      </Text>
                    </Button>
                  </View>
                </View>
              ) : (
                <>
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Button style={[styles.menuContainer]} onPress={toggleMenu}>
                      <View style={[styles.menuItem]}></View>
                      <View style={[styles.menuItem]}></View>
                      <View style={[styles.menuItem]}></View>
                      <Text
                        style={[
                          { fontSize: 12.5, fontWeight: "800", position: "absolute" },
                          openMenu
                            ? { color: Colors.activeStep, bottom: -2, left: 10 }
                            : { color: Colors.textColor, bottom: -2, left: 12 },
                        ]}
                      >
                        {openMenu ? "CLOSE" : "MENU"}
                      </Text>
                    </Button>
                  </View>
                </>
              )}
            </View>
          </View>
          {/* Banner */}
          <ScrollView
            style={[{ height: viewHeight }]}
            ref={scrollViewRef}
            onScroll={(e) => {
              setYLocation(e.nativeEvent.contentOffset.y)
            }}
            scrollEventThrottle={1}
            onContentSizeChange={(contentWidth, contentHeight) => setContentHeight(contentHeight)}
          >
            <View style={[styles.bannerContainer, isLargeScreen ? {} : { marginBottom: 30 }]}>
              <View
                onLayout={handleLayout}
                style={[
                  styles.bannerContentContainer,
                  isLargeScreen
                    ? {}
                    : {
                        left: "50%",
                        top: "50%",
                        alignItems: "center",
                        transform: [{ translateX: -(boxWidth * 0.5) }],
                        width: "100%",
                        paddingHorizontal: 8,
                      },
                  isLargeScreen ? {} : styles.bannerContentContainerMobile,
                ]}
              >
                <Text
                  style={[
                    styles.bannerContent,
                    isLargeScreen ? {} : { textAlign: "center", fontSize: 26, fontWeight: "800", width: "100%" },
                    { fontFamily: "Noto Sans JP" },
                  ]}
                >{`「らくらく連絡網＋」で\nグループコミュニケーションを\n簡単・便利・安全に！`}</Text>
                <Button
                  onPress={() => {
                    Linking.openURL(`${window.location.href}?Login`)
                  }}
                  style={[styles.bannerButton, isLargeScreen ? { padding: 16 } : { paddingVertical: 8 }]}
                >
                  <Text
                    style={[
                      {
                        color: Colors.white3,
                        width: 264,
                        fontSize: 20,
                        lineHeight: 22,
                        fontWeight: "700",
                        textAlign: "center",
                        fontFamily: "Noto Sans JP",
                      },
                      isLargeScreen ? {} : { width: 296 },
                    ]}
                  >
                    無料ではじめる
                  </Text>
                </Button>
              </View>
              {isLargeScreen ? (
                <Image style={[styles.bannerImage]} source={Banner} />
              ) : (
                <Image
                  style={[{ resizeMode: "cover", width: "100%", height: undefined, aspectRatio: 1.016 }]}
                  source={BannerMobile}
                />
              )}
            </View>
            {/* Features */}
            <View style={[styles.features, isLargeScreen ? {} : { marginBottom: 12 }]}>
              <View
                style={[
                  styles.featuresContainer,
                  isLargeScreen ? { justifyContent: "space-between" } : { flexDirection: "column" },
                  isLargeScreen ? {} : styles.bannerContentContainerMobile,
                ]}
              >
                <View style={[styles.featureItem, isLargeScreen ? { flex: 1 } : { width: "100%", height: "auto" }]}>
                  <Image
                    style={[
                      styles.featureItemImg,
                      isLargeScreen ? { height: "70%" } : { width: "100%", height: undefined, aspectRatio: 1.48 },
                    ]}
                    source={Feature1}
                  />
                  <View style={[styles.featureItemContent, isLargeScreen ? {} : { paddingHorizontal: 8 }]}>
                    <Text style={[styles.featureItemTitle]}>団体管理を簡単に！</Text>
                    <Text style={[styles.featureItemText, isLargeScreen ? {} : { width: "100%" }]}>
                      {isLargeScreen
                        ? `出欠確認、日程調整、アンケート機能などによ\nり団体での各種管理を簡単にします。`
                        : "出欠確認、日程調整、アンケート機能などにより団体での各種管理を簡単にします。"}
                    </Text>
                  </View>
                </View>
                <View style={[styles.featureItem, isLargeScreen ? { flex: 1 } : { width: "100%", height: "auto" }]}>
                  <Image
                    style={[
                      styles.featureItemImg,
                      isLargeScreen ? { height: "70%" } : { width: "100%", height: undefined, aspectRatio: 1.48 },
                    ]}
                    source={Feature2}
                  />
                  <View style={[styles.featureItemContent, isLargeScreen ? {} : { paddingHorizontal: 8 }]}>
                    <Text style={[styles.featureItemTitle]}>誰が読んでいないかすぐわかる！</Text>
                    <Text style={[styles.featureItemText, isLargeScreen ? {} : { width: "100%" }]}>
                      {isLargeScreen
                        ? `全ての連絡において、各メンバーの読了を確認\nできるので、連絡確認の手間が省けます。`
                        : "全ての連絡において、各メンバーの読了を確認できるので、連絡確認の手間が省けます。"}
                    </Text>
                  </View>
                </View>
                <View style={[styles.featureItem, isLargeScreen ? { flex: 1 } : { width: "100%", height: "auto" }]}>
                  <Image
                    style={[
                      styles.featureItemImg,
                      isLargeScreen ? { height: "70%" } : { width: "100%", height: undefined, aspectRatio: 1.48 },
                    ]}
                    source={Feature3}
                  />
                  <View style={[styles.featureItemContent, isLargeScreen ? {} : { paddingHorizontal: 8 }]}>
                    <Text style={[styles.featureItemTitle]}>団体連絡専用！</Text>
                    <Text style={[styles.featureItemText, isLargeScreen ? {} : { width: "100%" }]}>
                      {isLargeScreen
                        ? `連絡先を交換する必要がないので、誰もが安心\n安全に利用できます。`
                        : "連絡先を交換する必要がないので、誰もが安心安全に利用できます。"}
                    </Text>
                  </View>
                </View>
              </View>
            </View>
            {/* Banner 2 */}
            <View
              style={[styles.banner2Container, isLargeScreen ? {} : { height: undefined, width: "100%", aspectRatio: 3.51 }]}
            >
              <Image
                source={Banner2}
                style={{ width: "100%", height: "100%", resizeMode: "cover", opacity: 0.87 }}
                blurRadius={3}
              />
            </View>
            {/* Download app */}
            <View style={[styles.download]}>
              <View style={[styles.downloadContainer]}>
                <Text style={[styles.downloadTitle, isLargeScreen ? {} : { paddingTop: 30 }]}>
                  アプリもしくはWEB版で利用可能！
                </Text>
                <View style={[styles.downloadContent, isLargeScreen ? {} : { flexDirection: "column" }]}>
                  <View style={[styles.downloadAppContainer]}>
                    <Text style={[styles.downloadSubTitle]}>アプリはこちら</Text>
                    <View style={[styles.downloadAppContent]}>
                      <Button
                        onPress={() => {
                          Linking.openURL("https://apps.apple.com/jp/app/id1626790356")
                        }}
                      >
                        <Image source={AppStore} style={{ width: 132, height: 44 }} />
                      </Button>
                      <Button
                        onPress={() => {
                          Linking.openURL("https://play.google.com/store/apps/details?id=jp.ra9plus")
                        }}
                      >
                        <Image source={GooglePlay} style={{ width: 148, height: 44 }} />
                      </Button>
                    </View>
                  </View>
                  <View style={[styles.downloadAppContainer, isLargeScreen ? {} : { marginTop: 16 }]}>
                    <Text style={[styles.downloadSubTitle]}>WEB版はこちら</Text>
                    <View style={[styles.downloadAppContent]}>
                      <Button
                        onPress={() => {
                          Linking.openURL(`${window.location.href}?Login`)
                        }}
                      >
                        <Image source={Web} style={{ width: 132, height: 44 }} />
                      </Button>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            {/* Migration */}
            <View style={[styles.migrationContainer, isLargeScreen ? {} : { paddingHorizontal: 8 }]}>
              <Text style={[styles.migrationTitle]}>「らくらく連絡網」からの移行</Text>
              <View style={[styles.migrationContentContainer, isLargeScreen ? {} : { flexDirection: "column" }]}>
                <View style={[styles.migrationImage, isLargeScreen ? {} : { width: "100%" }]}>
                  <Image
                    source={Post}
                    style={[
                      isLargeScreen
                        ? { width: "100%", height: "100%" }
                        : { width: "100%", height: undefined, aspectRatio: 1.5 },
                    ]}
                  />
                </View>
                <View style={[styles.migrationContent, isLargeScreen ? {} : { width: "100%", paddingLeft: 0, paddingTop: 15 }]}>
                  <Text style={[styles.migrationSubTitle, isLargeScreen ? {} : { fontSize: 15, marginBottom: 35 }]}>
                    らくらく連絡網から登録情報をカンタンに移行可能！
                  </Text>
                  <Text style={[styles.migrationText]}>
                    団体情報（活動内容など）や、団体内個人設定情報（メールアドレス、権限など）をそのまま移行できるので、再設定の必要がなく、スムーズにご利用を再開できます。
                  </Text>
                </View>
              </View>
            </View>
            {/* News */}
            <View style={[styles.news]}>
              <View style={[styles.newsContainer]}>
                <Button
                  style={[styles.newsTitle]}
                  onPress={() => {
                    Linking.openURL("https://www.ra9plus.jp/post/")
                  }}
                >
                  <Text style={[{ fontSize: 18, fontWeight: "700", color: Colors.activeStep, fontFamily: "Noto Sans JP" }]}>
                    お知らせ一覧はこちら
                  </Text>
                </Button>
              </View>
            </View>
            {/* Spacing */}
            <View style={[styles.spacing, isLargeScreen ? {} : { height: 88 }]}>
              {showBtnFix() && (
                <Button
                  style={[
                    styles.scrollToTopButton,
                    isLargeScreen ? {} : { height: 34, width: 34, bottom: 20, right: 8, paddingBottom: 5 },
                  ]}
                  onPress={handleScrollToTop}
                >
                  <ArrowIcon width={22} height={isLargeScreen ? 22 : 12} />
                </Button>
              )}
            </View>
            {/* Footer */}
            <View style={[styles.footer, isLargeScreen ? {} : { paddingBottom: 70 }]}>
              <View style={[styles.footerNavContainer, isLargeScreen ? {} : { flexDirection: "column", alignItems: "center" }]}>
                <Button
                  onPress={() => {
                    LinkExpo.openURL("https://www.eole.co.jp/corporate/overview.html")
                  }}
                >
                  <Text style={{ fontSize: 15, fontFamily: "Noto Sans JP" }}>会社概要</Text>
                </Button>
                <Button
                  onPress={() => {
                    LinkExpo.openURL("https://www.ra9plus.jp/rule/")
                  }}
                >
                  <Text style={{ fontSize: 15, fontFamily: "Noto Sans JP" }}>利用規約</Text>
                </Button>
                <Button
                  onPress={() => {
                    LinkExpo.openURL("https://www.ra9plus.jp/privacypolicy/")
                  }}
                >
                  <Text style={{ fontSize: 15, fontFamily: "Noto Sans JP" }}>個人情報の取り扱いについて</Text>
                </Button>
                <Button
                  onPress={() => {
                    LinkExpo.openURL(paidVersionURL)
                  }}
                >
                  <Text style={{ fontSize: 15, fontFamily: "Noto Sans JP" }}>特定商取引法に基づく表示</Text>
                </Button>
                <Button
                  onPress={() => {
                    LinkExpo.openURL("https://www.ra9plus.jp/guide/")
                  }}
                >
                  <Text style={{ fontSize: 15, fontFamily: "Noto Sans JP" }}>ご利用ガイド</Text>
                </Button>
                <Button
                  onPress={() => {
                    LinkExpo.openURL("https://www.ra9plus.jp/contact/")
                  }}
                >
                  <Text style={{ fontSize: 15, fontFamily: "Noto Sans JP" }}>お問い合わせ</Text>
                </Button>
              </View>
              <Button
                style={[styles.footerLogo]}
                onPress={() => {
                  location.reload()
                }}
              >
                <Image source={Logo} style={{ width: "100%", height: "100%" }} />
              </Button>
              <Text style={{ fontSize: 14, fontFamily: "Noto Sans JP" }}>© 2024 らくらく連絡網＋ All Rights Reserved.</Text>
            </View>
          </ScrollView>
          {/* Modal & Floating Footer */}
          <Animated.View style={[styles.menuModal, { height: heightMenu }]}>
            <Button
              style={[styles.menuModalItem, { marginTop: 16 }]}
              onPress={() => {
                LinkExpo.openURL("https://www.ra9plus.jp/lp/migration/index.html")
              }}
            >
              <Text style={{ fontFamily: "Noto Sans JP" }}>らくらく連絡網からの移行</Text>
            </Button>
            <Button
              style={[styles.menuModalItem]}
              onPress={() => {
                LinkExpo.openURL("https://www.ra9plus.jp/guide/")
              }}
            >
              <Text style={{ fontFamily: "Noto Sans JP" }}>ご利用ガイド</Text>
            </Button>
            <Button
              style={[styles.menuModalItem]}
              onPress={() => {
                LinkExpo.openURL("https://www.ra9plus.jp/post/")
              }}
            >
              <Text style={{ fontFamily: "Noto Sans JP" }}>お知らせ</Text>
            </Button>
            <Button
              style={[styles.menuModalItem, { marginBottom: 16 }]}
              onPress={() => {
                window.location.href = window.location.href + "?Login"
              }}
            >
              <Text style={{ fontFamily: "Noto Sans JP" }}>新規登録/ログイン</Text>
            </Button>
          </Animated.View>

          {showFloatingHandle() && (
            <View
              style={[
                styles.floatingFooter,
                {
                  shadowColor: "rgba(0,0,0,0.16)",
                  shadowOffset: { width: 0, height: -2 },
                  shadowOpacity: 0.5,
                  shadowRadius: 5,
                },
              ]}
            >
              <Button
                onPress={() => {
                  window.location.href = window.location.href + "?Login"
                }}
                style={[styles.buttonNav, { marginBottom: 6 }]}
              >
                <Text style={{ color: Colors.white3, fontSize: 15, fontWeight: "500", fontFamily: "Noto Sans JP" }}>
                  新規登録/ログイン
                </Text>
              </Button>
            </View>
          )}

          {!showBtnFix() && (
            <Button
              style={[
                styles.scrollToTopButton,
                isLargeScreen ? { right: 32 } : { height: 34, width: 34, bottom: 20, right: 8, paddingBottom: 5 },
              ]}
              onPress={handleScrollToTop}
            >
              <ArrowIcon width={22} height={isLargeScreen ? 22 : 12} />
            </Button>
          )}
        </View>
      )}
    </>
  )
})

export const authorizationTopOptions: ScreenOptions = {
  headerShown: false,
  title: !window.location.href.includes("Login")
    ? "「らくらく連絡網＋」でグループコミュニケーションを簡単・便利・安全に！ - らくらく連絡網＋"
    : "らくらく連絡網＋",
}

const viewWidth = Dimensions.get("window").width
const spacingPadding = Math.min((viewWidth / 750) * 16, 16)
const gap = Math.min((viewWidth / 750) * 24, 24)
const bannerHeight = Math.min((viewWidth / 750) * 340, 340)
const translateYValue = (-0.5 * bannerHeight) / 2
const fontSize = Math.min((viewWidth / 375) * 15, 15)
const marginTop = Math.min((viewWidth / 750) * 56, 56)
const marginBottom = Math.min((viewWidth / 750) * 40, 40)
const paddingBottom = Math.min((viewWidth / 750) * 80, 80)

const circleSize = Dimensions.get("window").width * 3
const halfCircleSize = circleSize / 2

const styles = StyleSheet.create({
  //PC Web
  container: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    position: "relative",
    overflow: "hidden",
  },

  headerContainer: {
    display: "flex",
    borderBottomWidth: 1,
    borderBottomColor: Colors.borderColor2,
    width: "100%",
    position: "absolute",
    top: 0,
    zIndex: 2,
    padding: spacingPadding,
    backgroundColor: Colors.white3,
  },
  headerContentContainer: {
    display: "flex",
    alignItems: "center",
    gap: gap,
    width: "100%",
    maxWidth: 1160,
    marginLeft: "auto",
    marginRight: "auto",
    flexDirection: "row",
  },
  logoLink: {
    display: "flex",
    textDecorationLine: "none",
    textDecorationColor: "none",
    backgroundColor: "transparent",
    width: "50%",
    height: "100%",
  },
  imageLogo: {
    width: 216,
    maxHeight: 31,
    height: "100%",
    marginRight: "auto",
    textAlignVertical: "bottom",
    resizeMode: "cover",
  },
  navLinkContainer: {
    display: "flex",
    gap: gap,
    alignItems: "center",
    flexDirection: "row",
  },
  navLink: {
    color: Colors.textColor,
    fontSize: fontSize,
    fontWeight: "500",
    fontFamily: "Noto Sans JP",
  },
  buttonNav: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    backgroundColor: Colors.activeStep,
    borderRadius: 4,
  },
  menuContainer: {
    width: 62,
    height: 48,
    marginLeft: "auto",
    alignItems: "center",
    padding: 8,
    gap: 5.5,
    position: "relative",
  },
  menuItem: {
    width: "75%",
    height: 4.3,
    backgroundColor: Colors.black2,
  },

  bannerContainer: {
    width: "100%",
    marginBottom: 56,
    position: "relative",
    marginTop: 70,
  },
  bannerImage: {
    resizeMode: "cover",
    width: "100%",
    height: bannerHeight,
  },
  bannerContentContainer: {
    position: "absolute",
    top: "50%",
    left: "59%",
    zIndex: 1,
    transform: [{ translateY: translateYValue }],
    display: "flex",
    gap: 24,
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  bannerContentContainerMobile: {
    display: "flex",
    gap: 12,
  },
  bannerContent: {
    color: Colors.textColor,
    fontSize: 26,
    fontWeight: "700",
    lineHeight: 39,
  },
  bannerButton: {
    backgroundColor: Colors.activeStep,
    borderRadius: 4,
  },

  features: {
    width: "100%",
    marginBottom: 32,
  },
  featuresContainer: {
    width: "100%",
    maxWidth: 1160,
    marginHorizontal: "auto",
    display: "flex",
    flexDirection: "row",
    gap: 28,
  },
  featureItem: {
    display: "flex",
    height: 357,
    backgroundColor: Colors.white8,
  },
  featureItemImg: {
    width: "100%",
  },
  featureItemContent: {
    height: "30%",
    width: "100%",
    paddingHorizontal: 24,
    paddingVertical: 16,
    display: "flex",
    alignItems: "center",
  },
  featureItemTitle: {
    marginBottom: 8,
    fontSize: 16,
    fontWeight: "700",
    textAlign: "center",
    fontFamily: "Noto Sans JP",
  },
  featureItemText: {
    fontSize: 15,
    fontWeight: "400",
    textAlign: "center",
    fontFamily: "Noto Sans JP",
  },

  banner2Container: {
    width: "100%",
    height: 214,
    overflow: "hidden",
  },

  download: {
    width: "100%",
    paddingBottom: 40,
    display: "flex",
    backgroundColor: Colors.white9,
    alignItems: "center",
  },
  downloadContainer: {
    width: "100%",
    maxWidth: 1160,
    display: "flex",
    alignItems: "center",
  },
  downloadTitle: {
    paddingTop: 56,
    paddingBottom: 20,
    fontSize: 18,
    fontWeight: "800",
    color: Colors.activeStep,
    lineHeight: 22.5,
    fontFamily: "Noto Sans JP",
  },
  downloadContent: {
    display: "flex",
    flexDirection: "row",
  },
  downloadAppContainer: {
    display: "flex",
    alignItems: "center",
    marginHorizontal: 16,
  },
  downloadAppContent: {
    display: "flex",
    flexDirection: "row",
    gap: 8,
  },
  downloadSubTitle: {
    fontSize: 15,
    color: Colors.textColor,
    fontWeight: "500",
    marginBottom: 5,
    fontFamily: "Noto Sans JP",
  },

  migrationContainer: {
    width: "100%",
    paddingHorizontal: 16,
    paddingBottom: paddingBottom,
    alignItems: "center",
  },
  migrationTitle: {
    fontSize: 18,
    textAlign: "center",
    fontWeight: "700",
    marginTop: marginTop,
    marginBottom: marginBottom,
    fontFamily: "Noto Sans JP",
  },
  migrationContentContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    maxWidth: 1160,
    minHeight: 412,
  },
  migrationImage: {
    width: "50%",
    height: "auto",
    marginBottom: 15,
  },
  migrationContent: {
    width: "50%",
    paddingLeft: 40,
  },
  migrationSubTitle: {
    marginBottom: 60,
    fontWeight: "700",
    fontSize: 16,
    textAlign: "center",
    width: "100%",
    fontFamily: "Noto Sans JP",
  },
  migrationText: {
    width: "100%",
    fontSize: 15,
    fontWeight: "500",
    color: Colors.textColor,
    fontFamily: "Noto Sans JP",
  },

  news: {
    width: "100%",
    backgroundColor: Colors.white8,
    alignItems: "center",
  },
  newsContainer: {
    width: "100%",
    maxWidth: 1160,
    alignItems: "center",
  },
  newsTitle: {
    width: "100%",
    textAlign: "center",
    marginTop: 56,
    marginBottom: 56,
  },

  spacing: {
    width: "100%",
    height: 168,
    position: "relative",
  },
  scrollToTopButton: {
    position: "absolute",
    width: 48,
    height: 48,
    right: 16,
    bottom: 40,
    borderRadius: 50,
    backgroundColor: Colors.white9,
    shadowColor: "rgba(0, 0, 0, 0.16)",
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowRadius: 6,
    shadowOpacity: 1,
    alignItems: "center",
    justifyContent: "center",
    zIndex: 9999,
  },

  footer: {
    width: "100%",
    paddingHorizontal: 16,
    paddingTop: 30,
    backgroundColor: Colors.white9,
    alignItems: "center",
    paddingBottom: 30,
  },
  footerContainer: {
    width: "100%",
    maxWidth: 1160,
    alignItems: "center",
  },
  footerNavContainer: {
    flexDirection: "row",
    gap: 20,
    marginBottom: 20,
  },
  footerLogo: {
    width: 216,
    height: 31,
    marginBottom: 20,
  },

  floatingFooter: {
    width: "100%",
    height: 70,
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    elevation: 4,
    bottom: 0,
    left: 0,
    right: 0,
  },

  menuModal: {
    position: "absolute",
    width: viewWidth,
    backgroundColor: Colors.white9,
    zIndex: 9,
    top: 60 + spacingPadding,
    flexGrow: 1,
    overflow: "hidden",
  },
  menuModalItem: {
    width: "100%",
    alignItems: "center",
    margin: 8,
  },

  // Native Web
  containerNative: {
    flex: 1,
  },
  containerPC: {
    display: "flex",
    alignItems: "center",
    width: "60%",
    maxWidth: 600,
    marginLeft: "20%",
    marginRight: "20%",
    paddingLeft: 30,
    paddingRight: 30,
  },
  title: {
    backgroundColor: Colors.white3,
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  image: {
    minHeight: 90,
    width: "53%",
    resizeMode: "contain",
  },
  actions: {
    position: "relative",
    backgroundColor: Colors.beige,
    justifyContent: "center",
    alignItems: "center",
  },
  arcContainer: {
    position: "absolute",
    top: -50,
    left: 0,
    backgroundColor: Colors.white3,
    width: "100%",
    height: 50,
    overflow: "hidden",
    alignItems: "center",
  },
  arc: {
    backgroundColor: Colors.white,
    width: circleSize,
    height: circleSize,
    borderRadius: halfCircleSize,
  },
  buttons: {
    width: "100%",
  },
  buttonsPC: {
    width: "100%",
    fontSize: 18,
    padding: 10,
    borderRadius: 26,
    alignContent: "center",
  },
  tailAction: {
    marginBottom: 16,
  },
  description: {
    textAlign: "center",
    marginBottom: 48,
    fontSize: 14,
    lineHeight: 20,
  },
  buttonContainer: {
    width: "100%",
    padding: 24,
  },
})
