import { PropsWithChildren } from "react"
import { StyleProp, Text, TextStyle } from "react-native"

type OwnProps = {
  style: StyleProp<TextStyle>
  limitLine?: boolean
}

const SelectableText = ({ style, limitLine, children }: PropsWithChildren<OwnProps>) => {
  if (limitLine) {
    return (
      <Text numberOfLines={3} selectable={true} ellipsizeMode="tail" style={style}>
        {children}
      </Text>
    )
  }
  return (
    <Text selectable={true} style={style}>
      {children}
    </Text>
  )
}

export default SelectableText
