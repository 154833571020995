import type { DraftModel } from "src/types/contact/DraftModel"
import { Result } from "src/utils/Result"
import { getDraft as getDraftApi } from "src/aws/customAPI"
import { AnswerSelectType, MailType } from "src/aws/API"
import { ContactType } from "src/constants/ContactType"
import { EventDateCandidate, getEventDateCandidate } from "src/types/contact/EventDateCandidate"
import { AnswerType } from "src/constants/AnswerType"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type GetDraftRequestParams = {
  accessToken: string
  draftId: string
}

type GetDraftSuccess = DraftModel

type GetDraftFailure = {
  message: string
}

export const getDraft = async (params: GetDraftRequestParams): Promise<Result<GetDraftSuccess, GetDraftFailure>> => {
  const { accessToken, draftId } = params

  return execApi(
    () => getDraftApi(accessToken, {
      id: draftId,
      appInfo
    }),
    res => {
      const d = res.getDraft
      return {
        id: d.id,
        contactType: mailTypeMapping[d.mailType],
        isReceiversAllMembers: d.sendTo.allMembersFlg,
        isReceiversAllLeaders: d.sendTo.managersFlg,
        memberIds: d.sendTo.memberIds,
        groupIds: d.sendTo.groupIds,
        attachmentFileNames: d.attachmentFileNames,
        title: d.title,
        body: d.body,
        isAddToOtherAdminsHistory: d.shareManagerFlg >= 1,
        isPublishAnswersToMembers: d.showAnswerFlg,
        eventDateCandidates: d.candidateDatetimes?.map(item => convertEventCandidate(item)),
        questions: d.questionnaires?.map((q, i) => ({
          title: q.question,
          answerType: answerTypeMapping[q.answerSelectType],
          choices: q.choices,
        })),
        deadlineDate: d.responseDueDatetime ? new Date(d.responseDueDatetime) : undefined,
        reminderDate: d.remindDatetime ? new Date(d.remindDatetime) : undefined,
        reservationDate: d.sendScheduledAt ? new Date(d.sendScheduledAt) : undefined,
      }
    },
    {
      identifiers: ["getDraft", "getDraftApi"]
    }
  )
}

const mailTypeMapping: {[key in MailType]: ContactType} = {
  [MailType.normal]: ContactType.Normal,
  [MailType.attendance]: ContactType.Attendance,
  [MailType.questionnaire]: ContactType.Survey,
  [MailType.schedule]: ContactType.ScheduleAdjustment,
}

const convertEventCandidate = (candidate: {
  startDate: string
  startTime?: string | null
  endDate?: string | null
  endTime?: string | null
}): EventDateCandidate => {
  const result = getEventDateCandidate({
    startDate: new Date(candidate.startDate),
    startTime: candidate.startTime || undefined,
    endDate: candidate.endDate ? new Date(candidate.endDate) : undefined,
    endTime: candidate.endTime || undefined,
  })
  return result || {
    type: "AllDay",
    startDate: new Date(candidate.startDate)
  }
}

const answerTypeMapping: {[key in AnswerSelectType]: AnswerType} = {
  [AnswerSelectType.single]: "Single",
  [AnswerSelectType.multiple]: "Multiple",
}
