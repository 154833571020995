import { memo, useCallback, useMemo, useState } from "react"
import { v4 as uuid } from "uuid"
import { Image, ScrollView, StyleSheet, Text, View } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { Button } from "src/components/parts/buttons/Button"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { CustomSelect } from "src/components/parts/CustomSelect"
import { CustomTextInput } from "src/components/parts/CustomTextInput"
import { EditIcon } from "src/components/parts/icons/EditIcon"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { MultilineTextInput } from "src/components/parts/MultilineTextInput"
import { Colors } from "src/constants/Colors"
import { Screens } from "src/constants/Screens"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { useAsyncState } from "src/hooks/useAsyncState"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { useRefresher } from "src/hooks/useRefresher"
import { RemoveIcon } from "src/components/parts/icons/RemoveIcon"
import { imageSize, useResource } from "src/recoil/hooks/resource/useResource"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { InputLengthCounter } from "src/components/parts/InputLengthCounter"
import { defaultTeam } from "src/constants/defaultTeam"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { CircleThumbnail } from "src/components/parts/CircleThumbnail"
import { SmallButton } from "src/components/parts/buttons/SmallButton"
import { ageOptions } from "src/constants/matchingSearchDate"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { commonSingleModalOptions } from "src/constants/options/commonSingleModalOptions"
import { RootStackScreenProps } from "src/types.d"
import { MatchingProfileModel } from "src/types/matching/matchingProfileModel"
import { useFetcher } from "src/hooks/useFetcher"
import { ValidationErrorMessage } from "src/components/parts/ValidationErrorMessage"
import { initOrUpdateProfile } from "src/apis/matching/initOrUpdateProfile"
import { CustomAlert } from "src/utils/CustomAlert"
import { matchingOtherProfileRequestIdState } from "src/recoil/atoms/matching/matchingOtherProfileRequestIdState"
import { MyOrganization } from "src/queries/getMyOrganizations"
import { matchingIcon } from "src/constants/matchingIcon"
import { LocalResource } from "src/types/resource/LocalResource"

export const isValidUrl = (value: string) => {
  const pattern = new RegExp(
    "^((http|https):\\/\\/)" + //プロトコルパターン
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + //ドメインパターン
      "((\\d{1,3}\\.){3}\\d{1,3}))" + //IPアドレスパターン
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // ポート番号またはパス
      "(\\?[;&a-z\\d%_.~+=-]*)?" + //クエリストリング
      "(\\#[-a-z\\d_]*)?$", //フラグ情報
    "i"
  )
  return pattern.test(value)
}

export type CreateOrEditMatchingProfileParams = {
  matchingProfile: MatchingProfileModel | undefined
  id: string
  selectedMyTeam: MyOrganization | undefined
}

type Props = RootStackScreenProps<typeof Screens.CreateOrEditMatchingProfile>

export const CreateOrEditMatchingProfile = memo<Props>(({ navigation, route }) => {
  const isLargeScreen = useCheckPCScreen()
  const insets = useSafeAreaInsets()
  const teamId = useMemo(() => route.params.id, [route.params.id])
  const selectedMyTeam = useMemo(() => route.params.selectedMyTeam, [route.params.selectedMyTeam])
  const matchingProfile = useMemo(() => route.params.matchingProfile, [route.params.matchingProfile])
  const isEdit = matchingProfile !== undefined
  const refreshMatchingOtherProfile = useRefresher(matchingOtherProfileRequestIdState)

  const {
    resourceUrl: imageUrl,
    localResource: localImage,
    pickLocalImage,
    prepareDeleteResource: prepareDeleteImage,
    deleteResourceFlg: deleteImageFlg,
    refreshResourceUrl: refreshImageUrl,
  } = useResource(
    isEdit
      ? {
          type: "matchingTeamBgImage",
          teamId: teamId || "",
          size: "normal",
        }
      : {
          type: "none",
        }
  )
  const localImageUri = useMemo(() => localImage?.uri, [localImage])
  const { resourceUrl: defaultImageUrl, refreshResourceUrl: refreshDefaultImageUrl } = useResource({
    type: "teamImage",
    id: "DEFAULT",
    size: "normal",
  })

  const {
    resourceUrl: teamImageUrl,
    localResource: localTeamImage,
    pickLocalImage: pickLocalTeamImage,
    refreshResourceUrl: refreshTeamImageUrl,
  } = useResource(
    isEdit
      ? {
          type: "matchingTeamImage",
          teamId: teamId || "",
          size: "normal",
        }
      : {
          type: "none",
        }
  )
  const localTeamImageUri = useMemo(() => localTeamImage?.uri, [localTeamImage])

  const displayedImage = useMemo(
    () => (deleteImageFlg ? defaultImageUrl : localImageUri || imageUrl || defaultImageUrl),
    [deleteImageFlg, defaultImageUrl, localImageUri, imageUrl]
  )
  const showDeleteImageButton = useMemo(() => {
    return !deleteImageFlg && (localImageUri != null || (imageUrl != null && !imageUrl.toLocaleLowerCase().includes("default")))
  }, [deleteImageFlg, localImageUri, imageUrl])
  const refreshDisplayedImageUrl = useCallback(async () => {
    if (deleteImageFlg || (localImageUri == null && imageUrl)) {
      await refreshDefaultImageUrl()
    } else {
      await refreshImageUrl()
    }
  }, [deleteImageFlg, localImageUri, imageUrl, refreshDefaultImageUrl, refreshImageUrl])

  const [introduction, setIntroduction] = useState(matchingProfile?.introduction)
  const [teamUrl, setTeamUrl] = useState(matchingProfile?.teamUrl)
  const [ageFrom, setAgeFrom] = useState(matchingProfile?.ageFrom)
  const [ageTo, setAgeTo] = useState(matchingProfile?.ageTo)
  const onChangeAgeFrom = useCallback((id?: string) => {
    const next = ageOptions?.find((a) => a.value === id)
    if (next) {
      setAgeFrom(Number(next?.label))
    }
  }, [])

  const onChangeAgeTo = useCallback((id?: string) => {
    const next = ageOptions?.find((a) => a.value === id)
    if (next) {
      setAgeTo(Number(next?.label))
    }
  }, [])

  const teamUrlError = useMemo(() => (teamUrl ? (isValidUrl(teamUrl) ? "" : "正しいURLを入力してください") : ""), [teamUrl])
  const areRequiredFieldsFilled = useMemo(() => !!(introduction && ageFrom && ageTo), [introduction, ageFrom, ageTo])
  const isSubmitDisabled = useMemo(
    () => !areRequiredFieldsFilled || teamUrlError !== "",
    [areRequiredFieldsFilled, teamUrlError]
  )

  const filteredAgeOptions = (isFrom: boolean) => {
    if (isFrom) {
      return ageOptions.filter((ageOpt) => (ageTo ? Number(ageOpt.label) <= ageTo : true))
    } else {
      return ageOptions.filter((ageOpt) => (ageFrom ? Number(ageOpt.label) >= ageFrom : true))
    }
  }

  const { value: selectedOrganization } = useAsyncSelector(selectedMyOrganizationSelector)
  const organizationThumbnailURL = useMemo(() => selectedOrganization?.uriImage, [selectedOrganization])

  const goBack = useCallback(() => {
    navigation.goBack()
  }, [navigation])

  const requiredProfile = useMemo<MatchingProfileModel | undefined>(() => {
    if (teamId === undefined || introduction === undefined || ageFrom === undefined || ageTo === undefined) return
    return {
      id: teamId,
      ageFrom,
      ageTo,
      introduction,
      teamUrl: teamUrl ?? undefined,
    }
  }, [teamId, introduction, ageFrom, ageTo, teamUrl])

  function getFileName(localFile?: LocalResource) {
    if (localFile == undefined) return ".jpeg"
    if (localFile?.fileName) {
      return localFile?.fileName
    }
    return (uuid() + "." + localFile.extension).toString()
  }

  const [tokens] = useAsyncState(tokensState)
  const accessToken = useMemo(() => tokens?.accessToken, [tokens])
  const { fetch: save, isFetching: isSaving } = useFetcher(async (params: MatchingProfileModel) => {
    if (teamId == null || accessToken == null) return

    let fileDataBG = undefined
    let fileDataAva = undefined
    const localBgImageName = getFileName(localImage)
    const localIconName = getFileName(localTeamImage)
    if (localImage) {
      const blob = await (await fetch(localImageUri ?? "")).blob()
      fileDataBG = new File([blob], localBgImageName, { type: localImage?.type ?? "" })
    }
    if (localTeamImage) {
      const blob = await (await fetch(localTeamImageUri ?? "")).blob()
      fileDataAva = new File([blob], localIconName, {
        type: localTeamImage?.type ?? "",
      })
    }

    const [initOrUpdateProfileResult] = await Promise.all([
      initOrUpdateProfile({
        accessToken,
        id: params.id,
        ageFrom: params.ageFrom,
        ageTo: params.ageTo,
        introduction: params.introduction,
        teamUrl: params.teamUrl ?? undefined,
        mode: isEdit ? "edit" : "create",
        localBgImageName: localBgImageName,
        bgImageData: fileDataBG,
        deleteBgImageResourceFlg: deleteImageFlg,
        localIconName: localIconName,
        iconData: fileDataAva,
      }),
    ])

    if (!initOrUpdateProfileResult.isOk) {
      await CustomAlert.alert("エラー", initOrUpdateProfileResult.error.message)
      return
    }

    refreshMatchingOtherProfile()
    await refreshImageUrl()
    await refreshTeamImageUrl()
    goBack()
  })
  const onSubmit = useCallback(() => requiredProfile && save(requiredProfile), [save, requiredProfile])

  return (
    <CustomKeyboardAvoidingView>
      <ScrollView style={{ backgroundColor: Colors.white }}>
        <View style={[styles.warningContainer, isLargeScreen ? styles.oneColumn : {}]}>
          <Text style={styles.warning}>
            {"このプロフィール情報は団体マッチング専用です。\n団体マッチングを利用する他の団体に公開されます。"}
          </Text>
        </View>
        <View style={{ justifyContent: "center", alignItems: "center" }}>
          <View style={isLargeScreen ? { width: 600, paddingTop: 16 } : {}}>
            <View style={styles.requiredItemNote}>
              <Text style={styles.requiredItemNoteText}>
                <Text style={styles.requiredItemNoteAsterisk}>※</Text>は必須項目
              </Text>
            </View>
            <View style={[styles.keyvisualContainer, isLargeScreen ? { borderRadius: 16 } : {}]}>
              <Image
                style={[styles.keyvisual, isLargeScreen ? { borderRadius: 16 } : {}]}
                source={
                  displayedImage
                    ? { uri: displayedImage, cache: "force-cache" }
                    : organizationThumbnailURL
                    ? { uri: organizationThumbnailURL }
                    : defaultTeam
                }
                resizeMode="cover"
                onError={refreshDisplayedImageUrl}
              />
              <View style={styles.registerImageButtonContainer}>
                <Button
                  style={styles.registerImageButton}
                  onPress={() => pickLocalImage({ maxSizeInMB: imageSize.team.maxSizeInMB })}
                  disabled={isSaving}
                >
                  <EditIcon />
                  <Text style={styles.registerImageButtonText}>写真を登録</Text>
                </Button>
                {showDeleteImageButton ? (
                  <Button style={styles.registerImageButton} onPress={prepareDeleteImage} disabled={isSaving}>
                    <RemoveIcon />
                    <Text style={styles.deleteImageButtonText}>写真を削除</Text>
                  </Button>
                ) : null}
              </View>
            </View>

            <View style={styles.thumbnailContainer}>
              <CircleThumbnail
                source={localTeamImageUri || teamImageUrl ? { uri: localTeamImageUri || teamImageUrl } : matchingIcon}
                onError={refreshImageUrl}
              />
              <View style={styles.buttonContainer}>
                <SmallButton
                  width={200}
                  onPress={() => pickLocalTeamImage({ maxSizeInMB: imageSize.account.maxSizeInMB })}
                  disabled={isSaving}
                >
                  <EditIcon />
                  <Text style={styles.editThumbnailText}>アイコン画像を登録</Text>
                </SmallButton>
              </View>
            </View>

            <Text style={styles.noteText}>
              {"団体名・カテゴリ・活動エリアを変更したい場合は、団体設定で変更してください。"}
            </Text>

            <View style={styles.items}>
              <View>
                <Text style={styles.sectionTitle}>{selectedMyTeam?.title}</Text>
                <Text style={[styles.description, { paddingBottom: 0 }]}>
                  {"スポーツ系サークル・チーム　野球\n埼玉県飯能市"}
                </Text>
              </View>

              <View style={styles.itemLabelWrapper}>
                <ItemLabel label="年齢層" required />
              </View>
              <View
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  paddingTop: 16,
                }}
              >
                <View style={{ width: "42%" }}>
                  <CustomSelect
                    options={filteredAgeOptions(true)}
                    onChange={onChangeAgeFrom}
                    value={ageFrom?.toString()}
                    placeholder={""}
                  />
                </View>
                <Text style={styles.text}> ～ </Text>
                <View style={{ width: "42%" }}>
                  <CustomSelect
                    options={filteredAgeOptions(false)}
                    onChange={onChangeAgeTo}
                    value={ageTo?.toString()}
                    placeholder={""}
                  />
                </View>
                <Text style={styles.text}> 歳</Text>
              </View>
              <View style={styles.itemLabelWrapper}>
                <ItemLabel
                  label="団体の紹介文"
                  required
                  RightComponent={<InputLengthCounter text={introduction} maxLength={200} unit={"字以内"} />}
                />
              </View>
              <View style={styles.item}>
                <MultilineTextInput
                  style={[styles.textInput, { height: 240 }]}
                  placeholder="入力してください"
                  value={introduction ?? ""}
                  onChangeText={setIntroduction}
                  editable={!isSaving}
                  maxLength={200}
                />
              </View>

              <View style={styles.itemLabelWrapper}>
                <ItemLabel
                  label="団体のホームページ"
                  RightComponent={<InputLengthCounter text={teamUrl} maxLength={200} unit={"字以内"} />}
                />
              </View>
              <View style={styles.item}>
                <CustomTextInput
                  style={styles.textInput}
                  placeholder="URLを入力してください"
                  value={teamUrl ?? ""}
                  onChangeText={setTeamUrl}
                  editable={!isSaving}
                  maxLength={200}
                  isError={teamUrlError !== ""}
                />
                {teamUrlError !== "" && <ValidationErrorMessage style={styles.validationErrorMessage} message={teamUrlError} />}
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
      {isLargeScreen ? (
        <View
          style={[
            styles.actionContainer,
            { paddingBottom: insets.bottom + 10, flexDirection: "row", justifyContent: "center", alignItems: "center" },
          ]}
        >
          <View style={{ flexDirection: "row", width: 600 }}>
            <View style={styles.buttonPCContainer}>
              <TextButton
                buttonType={ButtonType.Secondary}
                title={`キャンセル`}
                onPress={goBack}
                style={styles.buttonPCContainer}
                disabled={isSaving}
              />
            </View>
            <View style={styles.buttonPCContainer}>
              <TextButton
                buttonType={ButtonType.Primary}
                title="保存"
                onPress={onSubmit}
                style={styles.buttonPCContainer}
                disabled={isSubmitDisabled}
                isLoading={isSaving}
              />
            </View>
          </View>
        </View>
      ) : (
        <View style={[styles.actionContainer, { paddingBottom: insets.bottom + 10 }]}>
          <TextButton
            buttonType={ButtonType.Primary}
            title="保存"
            onPress={onSubmit}
            disabled={isSubmitDisabled}
            isLoading={isSaving}
          />
          <TextButton
            buttonType={ButtonType.Secondary}
            title={`キャンセル`}
            onPress={goBack}
            style={{ marginTop: 16 }}
            disabled={isSaving}
          />
        </View>
      )}
    </CustomKeyboardAvoidingView>
  )
})

export const options: ScreenOptions = {
  ...commonSingleModalOptions,
  title: "マッチング用プロフィール",
}

const styles = StyleSheet.create({
  keyvisualContainer: {
    position: "relative",
    height: 213,
    backgroundColor: Colors.white2,
  },
  keyvisual: {
    width: "100%",
    height: "100%",
  },
  registerImageButtonContainer: {
    position: "absolute",
    bottom: 12,
    right: 16,
  },
  registerImageButton: {
    backgroundColor: "rgba(255, 255, 255, 0.96)",
    paddingVertical: 13,
    paddingHorizontal: 19,
    borderRadius: 6,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 8,
  },
  registerImageButtonText: {
    color: Colors.orange,
    fontSize: 13,
    fontWeight: "bold",
    paddingLeft: 8,
  },
  deleteImageButtonText: {
    color: "#3C3C3C",
    marginLeft: 8,
    fontSize: 13,
  },
  items: {
    padding: 15,
  },
  item: {
    paddingTop: 16,
  },
  itemLabelWrapper: {
    marginTop: 20,
  },
  optionSetting: {
    marginTop: 20,
    fontSize: 18,
    fontWeight: "bold",
    color: Colors.greyshBrown,
  },
  actionContainer: {
    borderTopWidth: 1,
    borderTopColor: Colors.white2,
    paddingHorizontal: 24,
    paddingVertical: 12,
  },
  category: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderRadius: 6,
    borderWidth: 2,
    borderColor: Colors.lightGrey,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  horizontal: {
    alignItems: "center",
    flexDirection: "row",
  },
  radioLabel: {
    paddingLeft: 9,
    fontSize: 15,
    color: Colors.greyshBrown,
    fontWeight: "bold",
  },
  textInput: {
    fontSize: 16,
    textAlignVertical: "top",
    paddingVertical: 13,
    paddingHorizontal: 16,
    backgroundColor: Colors.white3,
    borderWidth: 2,
    borderColor: Colors.lightGrey,
    borderRadius: 10,
  },
  warningContainer: {
    padding: 16,
    marginTop: 4,
    borderBottomWidth: 1,
    borderBottomColor: Colors.lightGrey,
  },
  oneColumn: {
    width: "100%",
  },
  warning: {
    color: Colors.warmGrey3,
    fontWeight: "400",
    fontSize: 14,
    textAlign: "center",
  },
  requiredItemNote: {
    paddingTop: 12,
  },
  requiredItemNoteText: {
    fontWeight: "400",
    fontSize: 14,
    paddingHorizontal: 15,
    paddingBottom: 10,
  },
  requiredItemNoteAsterisk: {
    color: Colors.red,
  },
  thumbnailContainer: {
    alignItems: "center",
    paddingTop: 24,
  },
  buttonContainer: {
    paddingTop: 24,
  },
  buttonPCContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 5,
    flexDirection: "row",
  },
  editThumbnailText: {
    color: Colors.orange,
    fontSize: 14,
    fontWeight: "bold",
    paddingLeft: 8,
  },
  noteText: {
    color: Colors.turtleGreen,
    fontSize: 12,
    paddingTop: 24,
    paddingBottom: 8,
    paddingHorizontal: 16,
  },
  contentsContainer: {
    padding: 16,
    borderTopWidth: 1,
    borderTopColor: Colors.lightGrey,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: "bold",
    color: Colors.greyshBrown,
    marginLeft: 0,
    marginRight: 0,
  },
  description: {
    fontSize: 14,
    lineHeight: 21,
    color: Colors.black,
    marginVertical: 8,
    marginLeft: 0,
    paddingVertical: 8,
  },
  text: {
    fontFamily: "Hiragino Kaku Gothic Pro",
    fontWeight: "600",
    fontSize: 18,
    lineHeight: 25,
  },
  validationErrorMessage: {
    marginTop: 5,
  },
})
