import type { CreateNewContactRequestParams } from "src/types/contact/CreateNewContactRequestParams"
import { Result } from "src/utils/Result"
import { createMail } from "src/aws/customAPI"
import { AnswerSelectType, MailType, QuestionnaireInput } from "src/aws/API"
import { ContactType } from "src/constants/ContactType"
import { Question } from "src/constants/Questions"
import { AnswerType } from "src/constants/AnswerType"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"
import { convertDateTimeCandidate } from "src/utils/convertDateTimeCandidate"

type PostNewContactRequestParams = {
  accessToken: string
  organizationId: string
  tempMailId: string
  attachmentFileNames: string[]
} & CreateNewContactRequestParams

type PostNewContactSuccess = unknown
type PostNewContactFailure = {
  message: string
}

export const postNewContact = (params: PostNewContactRequestParams): Promise<Result<PostNewContactSuccess, PostNewContactFailure>> => {
  return execApi(
    () => createMail(params.accessToken, {
      input: {
        teamId: params.organizationId,
        tempMailId: params.tempMailId,
        mailType: mailTypeMapping[params.contactType],
        title: params.title,
        body: params.body,
        sendTo: {
          allMembersFlg: params.receivers.isAllMembers,
          managersFlg: params.receivers.isAllLeaders,
          groupIds: params.receivers.groupIds,
          memberIds: params.receivers.memberIds,
        },
        attachmentFileNames: params.attachmentFileNames,
        questionnaires: params.contactType === "Survey" ? params.questions.map(convertQuestionnaire) : undefined,
        candidateDatetimes: params.contactType === "ScheduleAdjustment" ? params.eventDateCandidates.map(convertDateTimeCandidate) : undefined,
        shareManagerFlg: params.isAddToOtherAdminsHistory,
        showAnswerFlg: params.contactType !== "Normal" ? params.isPublishAnswersToMembers : undefined,
        responseDueDatetime: params.contactType !== "Normal" ? params.deadlineDate?.toISOString() : undefined,
        remindDatetime: params.contactType !== "Normal" ? params.reminderDate?.toISOString() : undefined,
        sendScheduledAt: params.reservationDate?.toISOString(),
      },
      appInfo
    }),
    _ => undefined,
    {
      identifiers: ["postNewContact", "createMail"],
      defaultErrorMessage: "連絡の保存に失敗しました。"
    }
  )
}

const mailTypeMapping: {[key in ContactType]: MailType} = {
  Normal: MailType.normal,
  Attendance: MailType.attendance,
  Survey: MailType.questionnaire,
  ScheduleAdjustment: MailType.schedule,
  // 以下、使用しない
  Event: MailType.normal,
  Safety: MailType.normal
}

const answerTypeMapping: {[key in AnswerType]: AnswerSelectType} = {
  Single: AnswerSelectType.single,
  Multiple: AnswerSelectType.multiple,
}

const convertQuestionnaire = (question: Question): QuestionnaireInput => ({
  answerSelectType: answerTypeMapping[question.answerType],
  question: question.title,
  choices: question.choices,
})

