import { Amplify } from "aws-amplify"
import dayjs from "dayjs"
import ja from "dayjs/locale/ja"
import "expo-dev-client"
import { StatusBar } from "expo-status-bar"
import { memo } from "react"
import { StyleSheet, View } from "react-native"
import "react-native-gesture-handler"
import { GestureHandlerRootView } from "react-native-gesture-handler"
import { SafeAreaProvider } from "react-native-safe-area-context"
import { RecoilRoot } from "recoil"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import useCachedResources from "src/hooks/useCachedResources"
import useColorScheme from "src/hooks/useColorScheme"
import { Navigation } from "src/navigation"
import { crashlyticsWrap } from "src/tags/crashlytics"
import { adWrap } from "src/tags/ads"
import * as Notifications from "expo-notifications"
import { TermsModal } from "src/components/parts/TermsModal"
import { useResetAppBadgeIconEffect } from "src/hooks/useResetAppBadgeIconEffect"

const config = require("src/aws/aws-exports") // eslint-disable-line
Amplify.configure(config.default)

dayjs.locale(ja)

const RecoilManagedApp = memo(() => {
  const isLoadingComplete = useCachedResources()
  const colorScheme = useColorScheme()
  crashlyticsWrap.log("mounted App")
  adWrap.initialize()

  Notifications.setNotificationHandler({
    handleNotification: async () => ({
      shouldShowAlert: true,
      shouldPlaySound: false,
      shouldSetBadge: false,
    }),
  })

  useResetAppBadgeIconEffect()

  if (!isLoadingComplete) {
    return (
      <View style={[styles.fullHeight, styles.centering]}>
        <LoadingIndicator />
      </View>
    )
  } else {
    return (
      <GestureHandlerRootView style={styles.fullHeight}>
        <SafeAreaProvider>
          <Navigation colorScheme={colorScheme} />
          <StatusBar />
          <TermsModal />
        </SafeAreaProvider>
      </GestureHandlerRootView>
    )
  }
})

export default function App() {
  return (
    <RecoilRoot>
      <RecoilManagedApp />
    </RecoilRoot>
  )
}

const styles = StyleSheet.create({
  centering: {
    justifyContent: "center",
    alignItems: "center",
  },
  fullHeight: {
    flex: 1,
  },
})
